const styles = {
  modal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "460px",
    bgcolor: 'background.paper',
    borderRadius: '0.3rem',
    boxShadow: 24,
    padding: "20px",
  },
  modalTitle: {
    color: '#154A8A',
    fontWeight: 'bold',
    fontSize: '18px'
  },
  modalDescription: {
    fontSize: '12px'
  },
  gridTitle: {
    color: '#154A8A',
    fontWeight: 'bold',
    fontSize: '16px'
  },
  closeButton: {
    padding: "0px",
    color: '#154A8A',
  },
  listContainer: {
    maxHeight: '500px',
    overflow: 'auto'
  },
  availableFieldsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px'
  },
  checkBox: {
    padding: '0px',
    paddingRight: '3px'
  },
  checkBoxLabel: {
    color: '#4B5185'
  },
  fieldCheckBox: {
    padding: '3px',
    paddingVertical: '10px'
  },
  warningBox: {
    marginTop: '10px',
    border: '1px solid #FAE29F',
    width: '100%', 
    height: '32px',
    p: 0,
    fontSize: '11px',
    '& .MuiAlert-icon': {
      marginRight: '10px',
      marginLeft: '10px',
      color: '#F4CA64',

    }
  }
};

export default styles;