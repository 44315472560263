import { ChangeEvent, FC, FocusEvent, forwardRef, useContext, useEffect, useState } from 'react';
import { Modal, Grid, Typography, IconButton, Button, Box, TextField, MenuItem, AlertColor, Select, Backdrop, Paper, Autocomplete, Checkbox, FormControlLabel } from '@mui/material';
import NumberFormat, { InputAttributes } from 'react-number-format';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import { Form, Formik, Field, FormikHelpers, FormikProps } from 'formik';
import styles from './styles';
import clientSchema from '../../../schemas/clientSchema';
import { API_DOMAIN, GET, POST, REPORT_FREQ_WEEKLY, REPORT_FREQ_QUARTERLY, CONTAINS_NUMBER_REGEX, CONTAINS_CHARACTER_REGEX, PERMISSIONS, DEFAULT_CHILD_MODAL_TEXT } from '../../../utility/constants';
import Toaster from '../../toaster';
import Prompt from '../../modals/prompt';
import ConfirmModal from '../../modals/confirm-modal';
import { ICustomProps } from '../../../interfaces';
import { IAccountingSystems, IClientFormikValues, IClientInfo, IDues, ILineOfBusinesses, INaicsCodes, IReportFrequencies } from '../../../interfaces/clientListInterface';
import { useNavigate } from 'react-router-dom';
import { getCurrencies, getResponseMessage, checkUserPermissions, getLocalStorageItem, getParentClientHasDefault } from '../../../utility/helper';
import { ClientContext } from '../../../context/clientContext';
import { ICurrency } from '../../../interfaces/multiCurrencyInterface';
import axiosInstance from '../../../service/axiosInstance';
import DisabledComponentsContainer from '../../common/disabled-components-container';
import { borrowerAPI } from '../../../service/api';
import { AuthContext } from '../../../context/authContext';
import HelperTextComponent from '../../common/helper-text-component';
import { SelectedClientContext } from '../../../context/selectedClientContext';

interface Props {
  onDismiss: (isAdded: boolean) => void;
}

/**
 * This reference formats a text field to a currency format.
 */
const CurrencyFormat = forwardRef<
  NumberFormat<InputAttributes>,
  ICustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      type='tel'
      decimalScale={2}
      fixedDecimalScale={true}
      prefix='$'
    />
  );
});

/**
 * This reference formats a text field to a phone format.
 */
const PhoneFormat = forwardRef<
  NumberFormat<InputAttributes>,
  ICustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      type='tel'
      allowEmptyFormatting
    />
  );
});

/**
 * This reference formats a text field to a whole number format.
 */
const WholeNumberFormat = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.value, name: other.name, }, });
      }}
      type='tel'
      decimalScale={0}
      allowNegative={false}
      isAllowed={values => values.floatValue === undefined || values.floatValue <= 9999999999}
    />
  );
});

/**
 * This reference formats a text field to the external FCN format.
 */
const ExternalFCNFormat = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.value, name: other.name, }, });
      }}
      type='tel'
      decimalScale={0}
      allowNegative={false}
    />
  );
});


const initialValues: IClientFormikValues = {
  showDueDate: true,
  borrowerName: '',
  lineLimit: '',
  lineOfBusiness: '',
  reportingFrequency: '',
  naicsCode: '',
  clientLoanNumber: '',
  crmName: '',
  dueDate: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  clientContactName: '',
  clientContactEmail: '',
  clientContactPhoneNumber: '',
  accountingSystem: '',
  currencyId: '150',
  parentClient: false,
  default: false,
  externalClientId: '',
  externalParentId: '',
  externalFCN: '',
  ecid: ''
};

/**
 * Component for showing the contents of the add new client modal.
 * @param props The props for the AddNewClient component in the clients page. See the Props interface for more information.
 */
const AddNewClient: FC<Props> = (props) => {
  const { onDismiss }                                       = props;
  const { state }                                           = useContext(AuthContext);
  const { setSelectedClient }                               = useContext(SelectedClientContext);                              
  const {
    canAddClient,
    modalProps,
    setModalProps,
  }                                                         = useContext(ClientContext);
  const [dirty, setDirty]                                   = useState<boolean>(false);
  const [openModal, setOpenModal]                           = useState<boolean>(false);
  const [open, setOpen]                                     = useState(false);
  const [naicsCodeResult, setNaicsCodeResult]               = useState<INaicsCodes[]>([]);
  const [lineOfBusinessResult, setLineOfBusinessResult]     = useState<ILineOfBusinesses[]>([]);
  const [reportFrequencyResult, setReportFrequencyResult]   = useState<IReportFrequencies[]>([]);
  const [accountingSystemResult, setAccountingSystemResult] = useState<IAccountingSystems[]>([]);
  const [toasterOpen, setToasterOpen]                       = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]                 = useState<string>('');
  const [toasterSeverity, setToasterSeverity]               = useState<AlertColor>('success');
  const [dues, setDues]                                     = useState<IDues[]>([]);
  const [enableDues, setEnableDues]                         = useState<boolean>(true);
  const [getClientName, setGetClientName]                   = useState('');
  const [clientInfo, setClientInfo]                         = useState<IClientInfo[]>([]);
  const [
    recentlyAddedClientRecordId, 
    setRecentlyAddedClientRecordId
  ]                                                         = useState<number>(-1);
  const [isoList, setIsoList]                               = useState<ICurrency[]>([]);

  const navigate = useNavigate();

  /**
   * This useEffect hook calls the getDropDownValues upon page load.
   */
  useEffect(() => {
    getDropDownValues();
  }, [open]);

  /**
   * This useEffect hook navigates the user to the client settings page of the recently created client.
   */
  useEffect(() => {
    if (dirty || recentlyAddedClientRecordId === -1) { return; }
    navigate(`/clients/${recentlyAddedClientRecordId}/settings`,
    {state:{toast:true,getClientName}});
    setRecentlyAddedClientRecordId(-1);
  }, [dirty, recentlyAddedClientRecordId]);

  /**
   * This function retrieves the dropdown values for the accounting systems, 
   * NAICS codes, line of business, report frequencies, and currency dropdowns.
   */
  const getDropDownValues = async () => {
    await getAccountingSystems();
    await getNAICSCodes();
    await getLineOfBusinesses();
    await getReportFrequencies();
    await getClients();
    await fetchCurrency();
  };

  /**
   * This function calls the getCurrencies function in order to retrieve the list of available currencies.
   */
  const fetchCurrency = async () => {
    const res = await getCurrencies();

    if(res){
      setIsoList(res.currencies);
    }
  }

  /**
   * This function calls the API endpoints for retrieving the paged active clients.
   */
  const getClients = async () => {
    let requestParams;
    const canViewAssignedClientsOnly = await checkUserPermissions(state.uid, PERMISSIONS.VIEW_ASSIGNED_CLIENT, state.token);
    if (canViewAssignedClientsOnly) {
      requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 9999, sortBy: 'borrowerName,ASC', parentClient: true, crmName: `${state.firstName} ${state.lastName}`}
    } else {
      requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 9999, sortBy: 'borrowerName,ASC', parentClient: true}
    }
    try {
      const response = await axiosInstance.request({
        url: borrowerAPI.FIND_BY_CRITERIA,
        method: GET,
        params: requestParams
      })
      setClientInfo(response.data.content);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * This function sets the values for the reporting frequency dropdown.
   */
  const getReportFrequencies = async () => {
    try {
      const response = await axiosInstance.request({
        url: '../../../../mock-api/report-frequencies.json',
        method: GET,
        baseURL: ''
      })

      setReportFrequencyResult(response.data);
    } catch (error) {
      console.log(error);
      setReportFrequencyResult([]);
    }
  };

  /**
   * This function sets the values for the line of business dropdown.
   */
  const getLineOfBusinesses = async () => {
    try {
      const response = await axiosInstance.request({
        url: '../../../../mock-api/line-of-business.json',
        method: GET,
        baseURL: ''
      })
  
      setLineOfBusinessResult(response.data);
    } catch (error) {
      console.log(error);
      setLineOfBusinessResult([]);
    }
  };

  /**
   * This function sets the values for the NAICS Codes dropdown.
   */
  const getNAICSCodes = async () => {
    try {
      const response = await axiosInstance.request({
        url: '../../../../mock-api/naics_codes.json',
        method: GET,
        baseURL: ''
      })
      
      setNaicsCodeResult(response.data);
    } catch (error) {
      console.log(error);
      setNaicsCodeResult([]);
    }
  };

  /**
   * This function sets the values for the accounting systems dropdown.
   */
  const getAccountingSystems = async () => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/accountingSystems?pageSize=15`,
        method: GET
      })
  
      setAccountingSystemResult(response.data.content);
    } catch (error) {
      console.log(error);
      setAccountingSystemResult([]);
    } 
  };

  /**
   * This function opens the add new client modal.
   */
  const handleOpen = () => setOpen(true);

  /**
   * This function closes the add new client modal and calls the onDismiss function if a client is added.
   * @param isAdded A boolean value which is true if the client is added. The default value is the value of the dirty variable.
   */
  const handleClose = (isAdded: boolean = dirty) => {
    isAdded && onDismiss(isAdded);
    setOpen(false);
  };

  /**
   * This function checks if the add new client form is dirty.
   * If it is, it opens the confirm navigation modal first.
   */
  const dirtyChecker = () => {
    if (dirty === true) {
      setOpenModal(true);
    } else {
      setOpen(false)
    }
  }

  /**
   * This function is called when the user chooses to discard his/her changes in the confirm navigation modal.
   */
  const discardChanges = () => {
    setOpen(false);
    setOpenModal(false);
    setDirty(false);
  }

  /**
   * This function is called when the user chooses to keep editing in the confirm navigation modal.
   */
  const keepEditing = () => {
    setOpenModal(false);
    setOpen(true);
  }

  /**
   * This function is called whenever the reporting frequency is changed. 
   * The Due dropdown is shown whenever the reporting frequency is weekly, quarterly, or monthly.
   * @param value the new reporting frequency.
   */
  const handleReportFreqChange = (value: string) => {
    if (value === 'Weekly Borrowing Base') {
      setDues(REPORT_FREQ_WEEKLY);
      setEnableDues(true);
    } else if (value === 'Quarterly Borrowing Base') {
      setDues(REPORT_FREQ_QUARTERLY);
      setEnableDues(true);
    } else if (value === 'Monthly Borrowing Base') {
      const monthlyDues: IDues[] = [];
      for (let i = 1; i <= 31; i++) {
        monthlyDues.push({
          id: i,
          name: i.toString(),
        });
      }
      setDues(monthlyDues);
      setEnableDues(true);
    } else {
      setDues([]);
      setEnableDues(false);
    }
  };

  /**
   * This function handles the saving of the details of the new client.
   * @param values The values of the new client.
   * @param action The variable containing the formik helpers for the form. 
   */
  const handleSubmit = async (values: IClientFormikValues, action: FormikHelpers<IClientFormikValues>) => {
    const { setSubmitting } = action;
    const dropdownAcctSys = document.getElementById(
      'cboAccountingSystem'
    );
    
    let payload = {
      showDueDate: values.showDueDate,
      borrowerName: values.borrowerName.trim(),
      lineLimit: values.lineLimit,
      lineOfBusiness: values.lineOfBusiness,
      reportingFrequency: values.reportingFrequency,
      naicsCode: values.naicsCode
        ? parseInt(values.naicsCode)
        : null,
      clientLoanNumber: values.clientLoanNumber,
      crmName: values.crmName,
      dueDate: values.dueDate,
      address1: values.address1,
      address2: values.address2 ? values.address2 : '',
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
      country: values.country,
      clientContactName: values.clientContactName,
      clientContactEmail: values.clientContactEmail,
      clientContactPhoneNumber: values.clientContactPhoneNumber,
      currencyId: parseInt(values.currencyId),
      parentClient: values.parentClient,
      parentClientFk: values.parentClientFk,
      visible: true,
      default: values.default,
      externalClientId: values.externalClientId,
      externalParentId: values.externalParentId,
      externalFCN: values.externalFCN,
      ecid: values.ecid
    };

    //default payload is no accountingSystem, if user supply it, it will add to the payload
    if(!isNaN(parseInt(values.accountingSystem))) {
      Object.assign(payload,payload,{accountingSystem :{
        recordId: parseInt(values.accountingSystem),
        accountingSystemName: dropdownAcctSys?.innerHTML
      }})
    }
    //end of modifying payload
    
    const canAdd = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.ADD_CLIENT);
    if (canAdd) {
      try {
        const response = await axiosInstance.request({
          url: `${API_DOMAIN}/borrowers`,
          method: POST,
          data: payload
        })

        setToasterOpen(true);
        setToasterMessage(getResponseMessage(response.status, 'Client', response.data.borrowerName));
        setToasterSeverity('success');
        setSubmitting(false);
        handleClose(true);
        setDirty(false);
        setSelectedClient(null);
        setRecentlyAddedClientRecordId(response.data.recordId);
        setGetClientName(response.data.borrowerName);
      } catch (e: any) {
        console.log("ADD CLIENT ERROR: ", e);
        setToasterOpen(true);
        if (e.response.status !== 409) {
          setToasterMessage(`Failed to save ${values.borrowerName.trim()}. Please check your inputs.`);
        } else {
          setToasterMessage(`Client name already exists, try a different one.`);
        }
        setToasterSeverity('error');
        setSubmitting(false);
      }
    } else {
      setToasterOpen(true);
      setToasterMessage(
        'You do not have the necessary permissions for this action.'
      );
      setToasterSeverity('error');
      setSubmitting(false);
    }
  }

  /**
   * This function sets the dirty status of the form.
   * @param node The formik reference object containing the dirty status.
   */
  const formikRef = (node : any) => {
    try {
      if (node !== null) {
        setDirty(node.dirty);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * This sorts the accounting systems alphabetically.
   */
  const sortedAccountingSystem: IAccountingSystems[] = [
    ...accountingSystemResult,
  ].sort((a: IAccountingSystems, b: IAccountingSystems) => {
    if (a.accountingSystemName && b.accountingSystemName) {
      const nameA = a.accountingSystemName.toUpperCase();
      const nameB = b.accountingSystemName.toUpperCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  });

  /**
   * This sorts the line of businesses alphabetically.
   */
  const sortedLineOfBusiness: ILineOfBusinesses[] = [
    ...lineOfBusinessResult,
  ].sort((a: ILineOfBusinesses, b: ILineOfBusinesses) => {
    if (a.lineOfBusinessName && b.lineOfBusinessName) {
      const nameA = a.lineOfBusinessName.toUpperCase();
      const nameB = b.lineOfBusinessName.toUpperCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  });

  /**
   * This function is called whenever the reporting frequency dropdown value is changed.
   * @param formik The formik object of the form.
   */
  const handleFormikReportFreqChange = (formik: FormikProps<IClientFormikValues>) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { values, setFieldValue, handleChange } = formik;
    
    const reportingFrequency: string = e.target.value;
    reportingFrequency !== values.reportingFrequency && setFieldValue('dueDate', '');

    if ( reportingFrequency === 'Daily Borrowing Base' || reportingFrequency === 'No Borrowing Base') {
      values.showDueDate = false;
    } else {
      values.showDueDate = true;
    }

    handleReportFreqChange(reportingFrequency);
    handleChange(e);
  }

  /**
   * This function is valled whenever the values of the fields are called and integrates the regex filter for the new value inputted.
   * @param formik The formik object of the form.
   * @param name The name of the field.
   */
  const handleChangeFormikWithRegex = (formik: FormikProps<IClientFormikValues>, name: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { handleChange } = formik;

    const stringFields = ['city', 'state', 'country', 'clientContactName'];
    const regexToBeUsed = stringFields.includes(name) ? CONTAINS_CHARACTER_REGEX : CONTAINS_NUMBER_REGEX;

    (regexToBeUsed.test(e?.target?.value as unknown as string) || e?.target?.value === '') && handleChange(e);
  }

  /**
   * This function retrieves the formik props for the field.
   * @param formik The formik object of the form.
   * @param name The name of the field.
   * @returns The props for the formik field.
   */
  const getFormikProps = (formik: FormikProps<IClientFormikValues>, name: string) => {
    const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } = formik;

    const fieldsToTrim = ['borrowerName', 'address1', 'address2', 'city', 'state', 'country', 'clientContactName', 'clientContactEmail'];
    const fieldsWithCustomValues = ['lineLimit', 'currencyId'];
    const fieldsWithCustomChange = ['reportingFrequency', 'clientLoanNumber', 'currencyId', 'city', 'state', 'zipCode', 'country', 'clientContactName'];
    
    const onBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      if (fieldsToTrim.includes(name)) {
        setFieldValue(name, values[name].trim());
        setTimeout(() => {
          setFieldTouched(name, true);
        }, 0);
      } 
      handleBlur(e);
    }

    return ({
      name,
      value: fieldsWithCustomValues.includes(name) ? undefined : values[name],
      onChange: fieldsWithCustomChange.includes(name) ? undefined : handleChange,
      error: touched[name] && Boolean(errors[name]),
      helperText: touched[name] ? (
        <HelperTextComponent text={errors[name]} />
      ) : ( '' ),
      onBlur: onBlur,
    })
  }

  /**
   * This function returns a boolean value dictating if the save button should be disabled or not.
   * @param formik The formik entity
   * @returns True if the form is not valid and is not dirty, otherwise, false.
   */
  const isSaveButtonDisabled = (formik: FormikProps<IClientFormikValues>) => !(formik.isValid && dirty);

  /**
   * This function handles the changing of the value of the checkbox that dictates whether the client is a parent or not.
   * @param checked The value of the checkbox
   * @param formik The formik entity
   */
  const handleParentClientCheckBox = (checked: boolean, formik: FormikProps<IClientFormikValues>) => {
    formik.setFieldValue('parentClient', checked);
  }

  /**
   * This function handles the changing of the parent client field
   * @param value The value of the parent client field
   * @param formik The formik entity
   */
  const handleChangeParentClientField = (value: any, formik: FormikProps<IClientFormikValues>) => {
    formik.setFieldValue('default', value?.parentHasDefault ? false : true);
    formik.setFieldValue('parentClientFk', value?.recordId ?? null)
  }

  return (
    <div>
      <Prompt when={dirty} isEditing={setDirty}/>
      {/* Confirm modal when there are unsaved changes */}
      <ConfirmModal
        open={openModal}
        onClose={discardChanges}
        onConfirm={keepEditing}
        onButtonClose={keepEditing}
        promptChecker={true}
        title={`Confirm Navigation`}
        description={
          `You have unsaved changes. Are you sure you want to leave this page?`
        }
        yesButtonText='Keep Editing'
        noButtonText='Discard Changes'
      />
      {
        canAddClient ? (
          <Button variant='contained' color='primary' onClick={handleOpen} aria-label='add-new-client' data-testid='add-new-client-button'>
            <Typography variant='button' sx={styles.modalOpenButton}>
              + Add New Client
            </Typography>
          </Button>
        ) : (
          <Box sx={styles.placeholderBox}/>
        )
      }    
      <Backdrop
        open={open}
        onClick={dirtyChecker} 
      />
      <Draggable
        handle='#draggable-dialog-title'
        cancel={'[class*="MuiDialogContent-root"]'}
        bounds={{ top: -100, left: -400, right: 400, bottom: 100 }}
      >
        <Modal
          open={open}
          onClose={dirtyChecker}
          aria-label='modal-modal-title'
          disableEscapeKeyDown
          BackdropProps={{invisible:true}}
        >
          <Grid container sx={styles.modal}>
            <Grid item xs={12} marginBottom={0}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                sx={styles.modalTitleCursor}
                id='draggable-dialog-title'
              >
                 <Typography tabIndex={0} id='modal-modal-title' variant='h6' component='h2' sx={styles.modalTitle}>
                  Add Client
                </Typography>
                
                <IconButton onClick={dirtyChecker}  aria-label='close icon' sx={styles.closeIcon} data-testid='close-icon'>
                  <CloseIcon/>
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent='flex-start'
                alignItems='center'
                sx={styles.modalTitleCursor}
                id='draggable-dialog-title'
              >
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Formik
                enableReinitialize
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={clientSchema}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form>
                    <Grid
                      container
                      sx={styles.mainSetting}
                    >
                      <Grid xs={12} sx={styles.modalInstructionsRequired}>
                        <Typography display='inline' sx={styles.noteText}>Note: </Typography>
                        Please fill out all required fields below. 
                        <Typography display='inline' sx={styles.red}> * </Typography>
                        indicates a required field.
                      </Grid>
                      {/* column 1 = Client Name .... */}
                      <Grid container xs={12} md={5} sx={{justifyContent: 'center'}}>
                        <Grid item direction='column' sx={styles.gridColumnParent}>
                          {/* Parent Client Checkbox */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={{...styles.modalFieldLabelName}}>
                                Parent Client
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Checkbox 
                                inputProps={{'aria-label': 'parent-client-checkbox'}}
                                onChange={(event, checked) => handleParentClientCheckBox(checked, formik)} 
                                disabled={formik.values.parentClientFk !== null && formik.values.parentClientFk !== undefined} 
                                style={styles.checkBox}
                              />
                            </Grid>
                          </Grid>
                          {/*Parent Client*/}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={{...styles.modalFieldLabelName}}>
                                Parent Name
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Autocomplete
                                disabled={formik.values.parentClient}
                                size='small'
                                options={clientInfo}
                                ListboxProps={{style: styles.autocompleteHeight }}
                                getOptionLabel={(client) => `${client.borrowerName}`}
                                noOptionsText={'No available Client'}
                                onChange={(e, value) => handleChangeParentClientField(value, formik)}
                                onBlur={formik.handleBlur}
                                renderOption={(prop, option: IClientInfo) => {
                                  return (
                                    <Box component='li' {...prop} key={option.recordId} sx={styles.dropdownOptionsFontSize}>
                                      {option.borrowerName}
                                    </Box>
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name='parentClientFk'
                                    inputProps={{...params.inputProps, 'data-testid': 'parent-client', 'aria-label':'Parent Client Field'}}
                                    placeholder={formik.values.parentClient ? '' : 'Please Select'}
                                    sx={{...styles.autocompleteField}}
                                    variant="outlined"
                                  />
                                )}
                                componentsProps={{
                                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                                }
                              />
                            </Grid>
                          </Grid>
                          {/*Client Name*/}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                Client Name<span style={styles.asterisk}>*</span>
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='txtClientName'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'borrowerName')}
                                sx={styles.field}
                                inputProps={{ 'data-testid': 'client-name',
                                              'aria-label' : 'Client Name'}}
                              />
                            </Grid>
                          </Grid>
                          {/* Default Client CheckBox */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeightClients, ...styles.paddingBottom}}
                          >
                            <Grid item xs={12} sx={styles.checkBoxGrid}>
                                <FormControlLabel 
                                  control={
                                    <Checkbox
                                      disabled={(!getParentClientHasDefault(clientInfo, formik) || (formik.values.parentClient) || (formik.values.parentClientFk === null || formik.values.parentClientFk === undefined))} 
                                      size='small'
                                      checked={ formik.values.default}
                                      inputProps={{'aria-label': 'default-client-checkbox'}}
                                      onChange={(event, checked) => {
                                        // check if parent client already has default child
                                        if (getParentClientHasDefault(clientInfo, formik) && checked) {
                                          setModalProps({
                                            open: true,
                                            description: DEFAULT_CHILD_MODAL_TEXT.description,
                                            title: DEFAULT_CHILD_MODAL_TEXT.title,
                                            confirmText: DEFAULT_CHILD_MODAL_TEXT.confirmText,
                                            handleConfirm: () => {
                                              formik.setFieldValue('default', checked);
                                            }
                                          });

                                          return;
                                        }

                                        formik.setFieldValue('default', checked);
                                      }}
                                      style={styles.checkBox}
                                    />
                                    } 
                                  label="Set as default" 
                                  sx={styles.checkBoxLabel}
                                />
                              </Grid>
                          </Grid>
                          {/* Accounting system */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} id='AccountingSystem' sx={styles.modalFieldLabelName}>
                                Accounting System
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Field
                                as={Select}
                                id='cboAccountingSystem'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'accountingSystem')}
                                sx={{
                                  ...styles.w20,
                                  ...styles.field,
                                  ...(formik.values.accountingSystem === '' && styles.defaultValueColor)
                                }}
                                inputProps={{displayEmpty:true, 'aria-labelledby' : 'AccountingSystem'}}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                              > 
                                {formik.values.accountingSystem === '' &&  
                                  <MenuItem value='' sx={styles.hidden} disabled>
                                    Please Select
                                  </MenuItem>
                                }
                                {sortedAccountingSystem.map((act) => (
                                  <MenuItem key={act.recordId} value={act.recordId} sx={styles.dropdownOptionsFontSize}>
                                    {act.accountingSystemName}
                                  </MenuItem>
                                ))}
                              </Field>
                            </Grid>
                          </Grid>
                          {/* Line Limit */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                Line Limit<span style={styles.asterisk}>*</span>
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                id='txtLineLimit'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'lineLimit')}
                                sx={{ ...styles.field, ...styles.textfieldCurrency }}
                                InputProps={{ 
                                  inputComponent: CurrencyFormat as any,
                                }}
                                inputProps={{
                                  'data-testid': 'line-limit',
                                  'aria-label': 'Line Limit',
                                }}
                              />
                            </Grid>
                          </Grid>
                          {/* Line of business */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} id='LineBusiness' sx={styles.modalFieldLabelName}>
                                Line of Business
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                select
                                id='cboLineOfBusiness'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'lineOfBusiness')}
                                sx={{
                                  ...styles.w20,
                                  ...styles.field,
                                  ...(formik.values.lineOfBusiness === '' && styles.placeholder)
                                }}
                                inputProps={{displayEmpty:true, 'aria-labelledby' : 'LineBusiness'}}
                              >
                                {formik.values.lineOfBusiness === '' &&  
                                  <MenuItem value='' sx={{...styles.hidden}} disabled>
                                    Please Select
                                  </MenuItem>
                                }
                                {sortedLineOfBusiness.map((biz) => (
                                  <MenuItem key={biz.id} value={biz.lineOfBusinessName} sx={styles.dropdownOptionsFontSize}>
                                    {biz.lineOfBusinessName}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                          {/* Report Frequency */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} id='ReportFrequency' sx={styles.modalFieldLabelName}>
                                Report Frequency
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                select
                                id='cboReportFrequency'
                                aria-label='reporting-frequency-textfield'
                                data-testid='reporting-frequency-textfield'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'reportingFrequency')}
                                onChange={handleFormikReportFreqChange(formik)}
                                sx={{
                                  ...styles.w20,
                                  ...styles.field,
                                  ...(formik.values.reportingFrequency === '' && styles.placeholder)
                                }}
                                inputProps={{'data-testid': 'reporting-frequency', displayEmpty:true, 'aria-labelledby':'ReportFrequency'}}
                              >
                                {formik.values.reportingFrequency === '' &&  
                                  <MenuItem value='' sx={{...styles.hidden}} disabled aria-label=''>
                                    Please Select
                                  </MenuItem>
                                }
                                {reportFrequencyResult.map((freq) => (
                                  <MenuItem key={freq.id} value={freq.reportFrequencyName} sx={styles.dropdownOptionsFontSize}>
                                    {freq.reportFrequencyName}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                          {/* NAICS code */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} id='NaicsCode' sx={styles.modalFieldLabelName}>
                                NAICS Code
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Autocomplete
                                size='small'
                                options={naicsCodeResult}
                                ListboxProps={{style: styles.autocompleteHeight }}
                                getOptionLabel={(naics) => `${naics.code} - ${naics.title}`}
                                noOptionsText={'No available NAICS Code'}
                                onChange={(e, value) => formik.setFieldValue('naicsCode', value?.code ?? null)}
                                onBlur={formik.handleBlur}
                                renderOption={(prop, option: INaicsCodes) => {
                                  return (
                                    <Box component='li' {...prop} key={option.code} sx={styles.dropdownOptionsFontSize}>
                                      {option.title}
                                    </Box>
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{...params.inputProps, 'data-testid': 'naics-code', 'aria-label':'NAICS Code Field'}}
                                    {...getFormikProps(formik, 'naicsCode')}
                                    placeholder={'Please Select'}
                                    sx={{...styles.autocompleteField}}
                                    variant="outlined"
                                  />
                                )}
                                componentsProps={{
                                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0},
                                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}
                                }}
                              />
                            </Grid>
                          </Grid>
                          {/* Client Loan Number */}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                Client Loan Number
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                id='txtLoanNumber'
                                variant='outlined'
                                size='small'
                                fullWidth
                                type='number'
                                {...getFormikProps(formik, 'clientLoanNumber')}
                                onChange={handleChangeFormikWithRegex(formik, 'clientLoanNumber')}
                                sx={{ ...styles.field, ...styles.textfieldNumber }}
                                InputProps={{inputComponent: WholeNumberFormat as any}}
                                inputProps={{'aria-label' : 'Client Loan Number'}}
                              />
                            </Grid>
                          </Grid>
                          {(enableDues && formik.values.reportingFrequency !== '') && (
                            <Grid
                              container
                              item
                              direction='row'
                              alignItems='center'
                              columnSpacing={1}
                              sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                            >
                              <Grid item xs={4}>
                                <Typography sx={styles.modalFieldLabelName}>
                                  Due
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Field
                                  as={Select}
                                  id='cboDue'
                                  size='small'
                                  fullWidth
                                  {...getFormikProps(formik, 'dueDate')}
                                  sx={{
                                    ...styles.w20,
                                    ...styles.field,
                                    ...(formik.values.dueDate === '' && styles.placeholder),
                                  }}
                                  MenuProps={{style: { maxHeight: 200 } }}
                                  inputProps={{displayEmpty:true}}
                                >
                                  {formik.values.dueDate === '' &&  
                                    <MenuItem value='' sx={{...styles.hidden}} disabled>
                                      Please Select
                                    </MenuItem>
                                  }
                                  {dues.map((due) => (
                                    <MenuItem key={due.id} value={due.name}>
                                      {due.name}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </Grid>
                            </Grid>
                          )}
                          {/*External Client ID*/}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                External Client ID<span style={styles.asterisk}>*</span>
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='externalClientId'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'externalClientId')}
                                sx={styles.field}
                                inputProps={{ 'data-testid': 'external-client-id',
                                              'aria-label' : 'External Client ID'}}
                              />
                            </Grid>
                          </Grid>
                          {/*External Parent ID*/}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                External Parent ID
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='externalParentId'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'externalParentId')}
                                sx={styles.field}
                                inputProps={{ 'data-testid': 'external-parent-id',
                                              'aria-label' : 'External Parent ID'}}
                              />
                            </Grid>
                          </Grid>
                          {/*External FCN*/}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                External FCN
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='externalFCN'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'externalFCN')}
                                sx={styles.field}
                                inputProps={{ 'data-testid': 'external-fcn',
                                              'aria-label' : 'External FCN'}}
                                InputProps={{inputComponent: ExternalFCNFormat as any}}
                              />
                            </Grid>
                          </Grid>
                          {/*ECID*/}
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                ECID
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='ecid'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'ecid')}
                                sx={styles.field}
                                inputProps={{ 'data-testid': 'ecid',
                                              'aria-label' : 'ECID'}}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* column 2 = address 1 .... */}
                      <Grid container xs={12} md={7} sx={{justifyContent: 'center'}}>
                        <Grid item direction='column' sx={styles.gridColumnParent}>
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                          </Grid>
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                Currency<span style={styles.asterisk}>*</span>
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Autocomplete
                                size='small'
                                options={isoList}
                                defaultValue={{
                                  "recordId": 150,
                                  "currencyName": "United States Dollar",
                                  "symbol": "$",
                                  "currencyCode": "USD"
                                }}
                                ListboxProps={{style: styles.autocompleteHeight }}
                                getOptionLabel={(currency) => `${currency.currencyCode} - ${currency.currencyName}`}
                                noOptionsText={'No available currencies'}
                                onChange={(e, value) => {
                                  formik.setFieldValue(
                                    'currencyId',
                                    value?.recordId ?? null
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                renderOption={(prop, option: ICurrency) => {
                                  return (
                                    <Box component='li' {...prop} key={option.recordId} sx={styles.dropdownOptionsFontSize}>
                                      {option.currencyCode} - {option.currencyName}
                                    </Box>
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{...params.inputProps, 'aria-label':'Currency Code Field'}}
                                    {...getFormikProps(formik, 'currencyId')}
                                    placeholder={'Please Select'}
                                    sx={{...styles.autocompleteField, ...styles.autocompleteField2}}
                                    variant="outlined"
                                  />
                                )}
                                componentsProps={{
                                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                Address 1
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='txtAddress1'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'address1')}
                                sx={styles.field2}
                                inputProps={{ 'data-testid': 'address1',
                                              'aria-label' : 'Address 1'}}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                Address 2
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='txtAddress2'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'address2')}
                                sx={styles.field2}
                                inputProps={{'aria-label' : 'Address 2'}}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                City
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='txtCity'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'city')}
                                onChange={handleChangeFormikWithRegex(formik, 'city')}
                                sx={styles.field2}
                                inputProps={{ 'data-testid': 'city',
                                              'aria-label' : 'City'}}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Grid
                                container
                                direction='row'
                                alignItems='center'
                                columnSpacing={1}
                              >
                                <Grid item xs={12}>
                                  <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                    State
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={8} sx={styles.stateFields}>
                              <TextField
                                id='txtState'
                                variant='outlined'
                                size='small'
                                {...getFormikProps(formik, 'state')}
                                onChange={handleChangeFormikWithRegex(formik, 'state')}
                                sx={{ ...styles.field, ...styles.smallField}}
                                inputProps={{ 'data-testid': 'state',
                                              'aria-label' : 'State'}}
                              />
                              <Typography tabIndex={0} sx={{...styles.modalFieldLabelName,
                              ...{paddingLeft: '17.224px', paddingRight: '14px'}
                              }}>
                                Zip Code
                              </Typography>
                              <TextField
                                id='txtZipCode'
                                variant='outlined'
                                size='small'
                                {...getFormikProps(formik, 'zipCode')}
                                onChange={handleChangeFormikWithRegex(formik, 'zipCode')}
                                sx={{
                                  ...styles.field,
                                  ...styles.smallField,
                                }}
                                inputProps={{ 'data-testid': 'zip-code',
                                              'aria-label' : 'Zip Code'}}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                Country
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='txtCountry'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'country')}
                                onChange={handleChangeFormikWithRegex(formik, 'country')}
                                sx={styles.field2}
                                inputProps={{ 'data-testid': 'country',
                                              'aria-label' : 'Country'}}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                Contact Name
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='txtContactName'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'clientContactName')}
                                onChange={handleChangeFormikWithRegex(formik, 'clientContactName')}
                                sx={styles.field2}
                                inputProps={{'aria-label' : 'Contact Name'}}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                Email
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='txtEmail'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'clientContactEmail')}
                                sx={styles.field2}
                                inputProps={{ 'data-testid': 'email',
                                              'aria-label' : 'Email'}}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            direction='row'
                            alignItems='center'
                            columnSpacing={1}
                            sx={{...styles.rowMaxHeight, ...styles.paddingBottom}}
                          >
                            <Grid item xs={4}>
                              <Typography tabIndex={0} sx={styles.modalFieldLabelName}>
                                Phone
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                id='txtPhone'
                                variant='outlined'
                                size='small'
                                fullWidth
                                {...getFormikProps(formik, 'clientContactPhoneNumber')}
                                sx={styles.field2}
                                InputProps={{
                                  inputComponent: PhoneFormat as any,
                                }}
                                inputProps={{ 'data-testid': 'phone',
                                              'aria-label' : 'Phone'}}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box
                      sx={styles.buttonBoxContainer}
                      id='draggable-dialog-title'
                    >
                      <Button
                        variant='outlined'
                        sx={styles.cancelButton}
                        onClick={dirtyChecker}
                      >
                        Cancel
                      </Button>
                      <DisabledComponentsContainer isDisabled={isSaveButtonDisabled(formik)}>
                        <Button
                          disabled={isSaveButtonDisabled(formik)}
                          aria-label={isSaveButtonDisabled(formik) ? 'Save button disabled' : 'Save'}
                          variant='contained'
                          color='primary'
                          type='submit'
                          sx={styles.saveButton}
                        >
                          Save
                        </Button>
                      </DisabledComponentsContainer>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Modal>
      </Draggable>          
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setToasterOpen(false)}
      />
    </div>
  );
};

export default AddNewClient;
