import { DragIndicator, IndeterminateCheckBoxOutlined, } from '@mui/icons-material';
import { Box, Checkbox, IconButton, Tooltip, Typography, TableBody, TableRow, TableCell } from '@mui/material';
import { useState, DragEvent, useEffect, useRef } from 'react'
import { DraggableListItem } from '../../../interfaces/draggableList';
import styles from './styles';
import { formatCurrency, formatDate } from '../../../utility/helper';
import { AMERICAN_DATE_FORMAT } from '../../../utility/constants';
import NewTag from '../../../components/common/new-tag';

interface DraggableListProps {
  list: DraggableListItem[],
  fullList?: DraggableListItem[],
  multiple?: boolean,
  name?: string,
  tempId?: number,
  inParent?: boolean,
  inEditViewCustomer?: boolean,
  selectedAll?: boolean,
  onItemDragOver: (list: DraggableListItem[]) => void
  onItemChecked?: (list: DraggableListItem[]) => void
  onItemDrop?:(list: DraggableListItem[], from: string, to: string, tempId?: number) => void
  onItemRemove?:(list: DraggableListItem[], from: string, to: string) => void
  onItemDragStart?: (items: string[]) => void,
  children?: React.ReactNode;
}

const DraggableTable = (props: DraggableListProps) => {

  const { list, fullList, multiple, tempId, name, inParent, inEditViewCustomer, selectedAll, onItemDragOver, onItemChecked, onItemDrop, onItemRemove, onItemDragStart } = props;

  const [draggedItem, setDraggedItem] = useState<DraggableListItem | null>(null);
  const [draggedItems, setDraggedItems] = useState<DraggableListItem[]>([]);
  const [isDragging, setIsDragging]   = useState<boolean>(false);
  const [lastDraggedValue,setLastDraggedValue] = useState<boolean>(false);
  const ghostRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (name) {
      list.forEach(item => {
        item.listName = name;
        return item;
      });
    }
  }, [])

  const onDragStart = (event: DragEvent<HTMLButtonElement>, index: number) => {
    if (!event.currentTarget.parentElement || !event.currentTarget.parentNode?.parentElement) return
    if (multiple) {
      const selectedList = selectedAll ? fullList!.filter(item => item.checked) : list.filter(item => item.checked);
      const finalList = selectedList.length
                          ? selectedList
                          : [list[index]]
      setDraggedItems([...finalList]);
      event.dataTransfer.setData("text/plain", JSON.stringify(finalList));

      if(ghostRef.current !== null){
        event.dataTransfer.setDragImage(ghostRef.current, -20, 25);
      }
    } else {
      setDraggedItem(list[index]);
      event.dataTransfer.setData("text/plain", JSON.stringify(list[index]));
    }

    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", event.currentTarget.parentElement.outerHTML);

    if (onItemDragStart) onItemDragStart([])
  };

  const onDragOver = (item: DraggableListItem , index: number) => {
    if (!draggedItem && !multiple) return;
    if (!draggedItems?.length && multiple) return;
    
    if (item?.listName != name) return;
    
    let items = [...list].filter((item) => 
      multiple 
        ? item !== draggedItems.find(dItem => dItem === item) 
        : item !== draggedItem
    );

    if (draggedItem && !multiple) {
      const draggedOverItem = list[index];
      if (draggedItem === draggedOverItem) {
        return;
      }
      items.splice(index, 0, draggedItem);
    } else if (draggedItems.length && multiple) {
      if (draggedItems.find(dItem => dItem === list[index])) {
        return;
      }
      draggedItems.forEach((dItem) => {
        items.splice(index, 0, dItem)
      })
    }

    onItemDragOver([...items]);
  };

  const onDrop = (event: DragEvent<HTMLTableRowElement>) => {
    if (!onItemDrop) return;
    const eventList = event.dataTransfer.getData("text/plain"); // dropped `list` in string format (JSON.stringify(...))
    if(eventList === '') return;
    const formattedList = JSON.parse(eventList);
    const tranferredListName = getTransferredListName(formattedList);
   
    onItemDrop([...formattedList], tranferredListName ?? '', name ?? '', tempId);

    event.dataTransfer.clearData();
  }

  const getTransferredListName = (list: DraggableListItem[] | DraggableListItem) => {
    if (multiple) {
      if (Array.isArray(list) && list.length) return list[0].listName
    } else if (!Array.isArray(list) && typeof list === "object") return list.listName
  }

  const checkItem = (index: number) => {
    if (!onItemChecked) return
    list[index].checked = !list[index].checked
    onItemChecked([...list])
  }

  const resetDragState = () => {
    setIsDragging(false);
    setDraggedItem(null);
    setDraggedItems([]);
  }

  const isOdd = (index: number) => index%2!==0;

  const getTooltip = (item: DraggableListItem) => {
    let message: string;
    if (multiple) {
      message = '';
    } else {
      message = item.checked ? 'Uncheck to hide the column' : 'Check to display the column';
    }

    return message;
  }

  const handleItemRemove = (item: DraggableListItem) => () => {
    if (onItemRemove) onItemRemove([item], item.listName ?? '', 'orphan');
  }

  const getDraggingTooltip = () => {
    if (!isDragging && props.multiple) {
      if (props.inParent) return 'Drag and drop to remove from this parent';
      else return 'Drag and drop to associate with a parent';
    } else {
      return 'Drag and drop to reorder';
    }
  }

  /**
   * This component generates a tooltip if the custName is truncated.
   * 
   * @param item The name of the customer.`
   * @returns A Typhography component, with tooltip if the custName is truncated.
   */
  const getCustName = (item: DraggableListItem) => {
    if (item.name.length >= 37) {
      return (
        <Tooltip title={`${item.name}`}>
          <Box>
            <Typography tabIndex={0} id={`${item.recordId}`} sx={styles.custName}>
              {item.name}
            </Typography>
            {props.multiple &&
              <Typography tabIndex={0} sx={{fontSize: '12px', color: '#707070'}}>
                {`${item.isVendor ? 'Vendor ID' : 'Customer ID'}: ${item.srcId}`}
              </Typography>
            }
          </Box>
        </Tooltip>
      );
    } else {
      return (
        <>
        <Typography tabIndex={0} id={`${item.recordId}`} sx={styles.custName}>
          {item.name}
        </Typography>
        {props.multiple &&
          <Typography tabIndex={0} sx={{fontSize: '12px', color: '#707070'}}>
            {`${item.isVendor ? 'Vendor ID' : 'Customer ID'}: ${item.srcId}`}
          </Typography>
        }
        </>
      );
    }
  };


  return (
    <>
      <TableBody
        onDragOver={(event) => event.preventDefault()} // prevent ghost image to bounce back
      >
        {list.map((item, index) => (
          <TableRow
            tabIndex={-1}
            sx={
              !multiple
                ? styles.listItem
                : inEditViewCustomer
                  ? {
                    bgcolor: item.isVendor ? 'background.paper' : '#F7F7F7'
                  }
                  : {
                    bgcolor: isOdd(index) ? 'background.paper' : '#F7F7F7'
                  }
              }
            key={item.recordId}
            onDragOver={() => { !item.fixed && onDragOver(item, index)}}
            onDrop={(event) => {
              onDrop(event);
              event.preventDefault();
            }}
            data-testid={`draggable-list-item-${index}`}
          >
            <TableCell sx={styles.tableDataDragCell}>
              <Box sx={{ display: 'flex' }}>
                {
                  (!item.fixed) ?
                  <Tooltip
                    title={getDraggingTooltip()} 
                    PopperProps={{sx: isDragging ? styles.noTooltip : null}}
                  >
                    <IconButton
                      draggable
                      edge="end"
                      aria-label="Drag and drop icon"
                      onDragStart={(event) => {
                        setIsDragging(true);
                        setLastDraggedValue(list[index].checked);
                        onDragStart(event, index);
                        list[index].checked = true;
                      }}
                      onDragEnd={() => {
                        if(lastDraggedValue) return;
                        list[index].checked = !list[index].checked;
                        resetDragState();
                      }}
                      sx={{px: '0px', width: '28px' }} 
                    >
                      <DragIndicator/>
                    </IconButton> 
                  </Tooltip>
                  : <Box width={'28px'}></Box>
                }
              {multiple && inParent ?
              <Box sx={{width: '1.1rem'}}></Box>
              :<Tooltip title={getTooltip(item)}>
                <Checkbox
                  aria-label='Checkbox'
                  edge="end"
                  checked={item.checked}
                  tabIndex={0}
                  disableRipple
                  onClick={() => checkItem(index)}
                  disabled={item.disabled}
                  data-testid={`draggable-list-checkbox-${index}`}
                  inputProps={{ 'aria-label' : item.name }}
                />
              </Tooltip>
              }
              </Box>
            </TableCell>
            <TableCell sx={styles.tableDataNameCell}>
              { (item.isNew && (!inParent || inEditViewCustomer)) && (
                <NewTag />
              )}
              <Box sx={styles.tableDataNameBox}>
                {getCustName(item)}
              </Box>
            </TableCell>
            <TableCell sx={styles.tableDataCell}>
              <Typography tabIndex={0} sx={styles.tableDataTypography}>
                {item.amount ? formatCurrency(item.amount) : `-`}
              </Typography>
            </TableCell>
            {multiple && !inParent && (
              <TableCell sx={styles.tableDataCell}>
                <Typography tabIndex={0} sx={styles.tableDataTypography}>
                  {formatDate(item.createdAt!, AMERICAN_DATE_FORMAT)}
                </Typography>
              </TableCell>
            )}
            {multiple && inParent && (!inEditViewCustomer || item.isVendor) &&
              <TableCell sx={styles.tableDataCell}>
                <IconButton
                aria-label='Remove icon'
                data-testid={`remove-button`}
                sx={{color: '#154A8A'}}
                onClick={handleItemRemove(item)}
                >
                  <IndeterminateCheckBoxOutlined/>
                </IconButton> 
              </TableCell>
            }
            {multiple && inParent && (!item.isVendor && inEditViewCustomer) && (
              <TableCell sx={styles.tableDataCell} />
            )}
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={100} sx={styles.tableDataCell}>
            { props.children }
          </TableCell>
        </TableRow>
      </TableBody>
    {/* ghost image */}
    {
    props.multiple &&
    <Box ref={ghostRef} style={{display: 'fixed', position: 'absolute', top: '-200px', left: '-200px', maxWidth: '200px', zIndex: '20'}}>
      {list.filter(o => o.checked).splice(0,4).map((item, idx) => <>
        <Box
          sx={{backgroundColor: '#154A8A', borderRadius: '20px', m: '4px', px: '8px', py: '4px', color: '#fff', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
        >{draggedItems.length > 3 && idx === 3 ? `+${draggedItems.length - 3} more customer` : item.name}</Box>
      </>
      )}
    </Box>
    }
    </>
  )
}

export default DraggableTable;
