import React, { createContext, Dispatch, useEffect, useMemo, useReducer } from "react";
import { AuthActions, setLoginToken } from "../actions/authActions";
import authReducer, { AuthState, initialState } from '../reducer/authReducer';
import { setLocalStorageItem } from "../utility/helper";
import axiosInstance from "../service/axiosInstance";
import api, { authAPI } from "../service/api";
import { GET, POST } from "../utility/constants";
import { IAccessResponseAPI, IUserAPI } from "../interfaces/rolesPermissionInterface";

export interface AuthContextInterface {
  state     : AuthState;
  dispatch  : Dispatch<AuthActions>;
}

export const AuthContext = createContext<AuthContextInterface>({
  state     : initialState,
  dispatch  : () => undefined
});

export interface LayoutProps  { 
  children: React.ReactNode;
}

const AuthContextProvider = (props: LayoutProps) => {
  const children = props.children;

  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    if (state.token && state.uid) {
      checkTokenValidity(state.token);
    }
  }, [state.token, state.uid]);

  const value = useMemo(() => ({state, dispatch}), [state, dispatch]);

  const checkTokenValidity = async (token : string) => {
    const response = await axiosInstance.request({
      url: authAPI.CHECK_TOKEN_VALIDITY,
      method: POST,
      headers: {
        token: token
      }
    })

    const accessResponse : IAccessResponseAPI = response.data;
    setLocalStorageItem('token', accessResponse.loginToken.accessToken);
    setLocalStorageItem('uid', accessResponse.user.id);
    setLocalStorageItem('email', accessResponse.user.email);
    setLocalStorageItem('firstName', accessResponse.user.firstName);
    setLocalStorageItem('lastName', accessResponse.user.lastName);
  }

  return (
    <AuthContext.Provider value={value}>
      {
        children
      }
    </AuthContext.Provider>
  )
}

export default AuthContextProvider;