import { createContext, useMemo, useState } from 'react';
import { IARCollateral, IInventoryCollateral, IInventoryValuations, IInventoryIneligibles, IInvBorrowingBase, IOtherCollateralReport, IClient } from '../interfaces/reportsInterface';
import { LayoutProps } from './authContext';

export interface IBorrowingBaseReportContext {
  selectedClient: IClient | null;
  setSelectedClient: (client: IClient | null) => void;
  isReportVisible: boolean;
  setIsReportVisible: (isReportVisible: boolean) => void;
  arCollaterals: Array<IARCollateral> | null;
  setARCollaterals: (arCollaterals: Array<IARCollateral> | null) => void;
  inventoryCollaterals: Array<IInventoryCollateral> | null;
  setInventoryCollaterals: (inventoryCollaterals: Array<IInventoryCollateral> | null) => void;
  inventoryValuations: IInventoryValuations | null;
  setInventoryValuations: (inventoryValuations: IInventoryValuations | null) => void;
  inventoryIneligibles: IInventoryIneligibles | null;
  setInventoryIneligibles: (inventoryIneligibles: IInventoryIneligibles | null) => void;
  otherCollateralReport: IOtherCollateralReport | null;
  setOtherCollateralReport: (otherCollateralReport: IOtherCollateralReport | null) => void;
  invBorrowingBaseList: Array<IInvBorrowingBase>;
  setInvBorrowingBaseList: (invBorrowingBaseList: Array<IInvBorrowingBase>) => void;
}

export const BorrowingBaseReportContext = createContext<IBorrowingBaseReportContext | null>(null);

const BorrowingBaseReportProvider = (props: LayoutProps) => {
  const { children } = props;

  const [selectedClient, setSelectedClient] = useState<IClient | null>(null);
  const [isReportVisible, setIsReportVisible] = useState(true);
  const [arCollaterals, setARCollaterals] = useState<Array<IARCollateral> | null>(null);
  const [inventoryCollaterals, setInventoryCollaterals] = useState<Array<IInventoryCollateral> | null>(null);
  const [inventoryValuations, setInventoryValuations] = useState<IInventoryValuations | null>(null);
  const [inventoryIneligibles, setInventoryIneligibles] = useState<IInventoryIneligibles | null>(null);
  const [otherCollateralReport, setOtherCollateralReport] = useState<IOtherCollateralReport | null>(null);
  const [invBorrowingBaseList, setInvBorrowingBaseList] = useState<Array<IInvBorrowingBase>>([]);

  const value = useMemo(() => ({
    selectedClient,
    setSelectedClient,
    isReportVisible,
    setIsReportVisible,
    arCollaterals,
    setARCollaterals,
    inventoryCollaterals,
    setInventoryCollaterals,
    inventoryValuations,
    setInventoryValuations,
    inventoryIneligibles,
    setInventoryIneligibles,
    otherCollateralReport,
    setOtherCollateralReport,
    invBorrowingBaseList,
    setInvBorrowingBaseList,
  }), [selectedClient,
    setSelectedClient,
    isReportVisible,
    setIsReportVisible,
    arCollaterals,
    setARCollaterals,
    inventoryCollaterals,
    setInventoryCollaterals,
    inventoryValuations,
    setInventoryValuations,
    inventoryIneligibles,
    setInventoryIneligibles,
    otherCollateralReport,
    setOtherCollateralReport,
    invBorrowingBaseList,
    setInvBorrowingBaseList])

  return (
    <BorrowingBaseReportContext.Provider
      value={value}>
      {children}
    </BorrowingBaseReportContext.Provider>
  );
};

export default BorrowingBaseReportProvider;
