import { useContext, useState, useCallback, FC, Dispatch, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, IconButton, ListItem, List, Typography, ListItemText, Button } from '@mui/material';
import { ExpandMore, CloseSharp, CheckCircleRounded, ExpandLess} from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import colors from '../../../../utility/colors';
import CalculateIneligiblesButton from '../../../../pages/client-setting/calculate-ineligibles-button';
import { SelectedClientContext } from '../../../../context/selectedClientContext';
import { FileImportProcessState } from '../../../../reducer/fileImportProcessReducer';
import { FileImportLoaderProps } from '..';
import { ICalculationMapping, IUploadedFile } from '../../../../interfaces/fileimport';
import styles from './styles';
import { FileImportProcess, setFileImportProcessLoader } from '../../../../actions/fileImportProcessActions';
import { IARCollateral } from '../../../../interfaces';
import { getDefaultCollateral } from '../../../client-settings/tabs/tab-panel-contents/accounts-receivable';
import { useStompClient, useSubscription } from 'react-stomp-hooks';
import { IProcessingAPIs, IProcessingRequests } from '../../../../pages/file-import/stepper/stepper-content/import-tab';
import { fileImportAPI, processingAPIs } from '../../../../service/api';
import axiosInstance from '../../../../service/axiosInstance';
import { API_DOMAIN, BATCH_SIZE, CONCURRENCY_OF_STAGING_REQUESTS, DELETE, GET, POST, PUT, STATUS_CODES, fieldsPerDocumentType } from '../../../../utility/constants';
import { checkIfBlankString, cleanPayload, customAsyncStagingRequest, excludeRows, formatDate, stringCurrencyToNumber } from '../../../../utility/helper';
import { getInitialPayloadByDocType, handleUserDefinedFields } from '../../../../pages/file-import/stepper/stepper-content/datamap-tab/file-row/mapping-modal/field-mapping';
import { amtHeaders, dateHeaders } from '../../../../pages/file-import/stepper/stepper-content/datamap-tab';

export interface SubscribingComponentProps extends FileImportLoaderProps {
  state: FileImportProcessState;
  dispatch: Dispatch<FileImportProcess>;
}

const importTabURLRegex = /^https?:\/\/[^/]+\/upload-files\?step=2&clientId=\d+/;
const fileImportPageURLRegex = /^https?:\/\/[^/]+\/file-import/;

/**
 * A component for subscribing to events.
 * @param props The props for configuring event subscriptions.
 * @returns A React component that handles event subscriptions.
 */
const SubscribingComponent: FC<SubscribingComponentProps> = (props) => {
  const { dispatch, state, content, newDocuments } = props;
  
  const navigate                                        = useNavigate();
  const { selectedClient, setSelectedClient, clients }  = useContext(SelectedClientContext);
  const [isAccordionExpanded, setIsAccordionExpanded]   = useState<boolean>(true);
  const [defaultCollateral, setDefaultCollateral]       = useState<IARCollateral | null>(null);
  const [documents, setDocuments]                       = useState<IUploadedFile[]>([]);
  const [started, setStarted]                           = useState<IUploadedFile[]>([]);
  const [isSubscribed, setIsSubscribed]                 = useState<boolean>(false);
  const [lastMessage, setLastMessage]                   = useState<string>('');
  const stompClient                                     = useStompClient();

  /**
   * This memoized value returns a boolean value.
   * True if the client is either a Parent Client or a Child Client.
   * False if it does not have any relationship.
   */
  const hasUpcRelationship = useMemo(() => 
    Boolean(selectedClient?.parentClient) ||
    Boolean(selectedClient?.parentClientFk)
  , [selectedClient]);

  /**
   * This memoized value indicates whether any of the imported documents is currently loading.
   */
  const hasLoadingFiles = useMemo(() => documents.some(document => document.isLoading), [documents]);

  /**
   * This effect updates the 'importDocuments' state whenever 'documents' change.
   */
  useEffect(() => {
    const existingRecordIds = documents.map(doc => doc.recordId);
    const newDocs = newDocuments.filter(doc => !existingRecordIds.includes(doc.recordId));
    if (newDocs.length > 0) setDocuments([...documents, ...newDocs]);
  }, [documents, newDocuments])

  /**
   * This useEffect hook fetches the default collateral data when the selected client changes.
   * It updates the `defaultCollateral` state with the fetched collateral details, ensuring that the component has the latest collateral information related to the selected client.
   */
  useEffect(() => {
    const fetchCollateral = async () => {
      if (selectedClient?.recordId) {
        const defaultCollateral = await getDefaultCollateral(selectedClient?.recordId);
        setDefaultCollateral(defaultCollateral);
      }
    };

    fetchCollateral();
  }, [selectedClient?.recordId]);

  /**
   * This useEffect hook checks if all documents for display have finished loading.
   * If all documents are loaded, it navigates to the `/file-import` page.
   */
  useEffect(() => {
    if (documents.length) {
      const allFilesFinished = documents.every(document => !document.isLoading);

      if (allFilesFinished) {
        const href = window.location.href;
        const inImport = importTabURLRegex.exec(href);
        const inLanding = fileImportPageURLRegex.exec(href);
        if (inImport || inLanding) navigate(`/file-import`);
      }
    }
  }, [documents]);

  /**
   * This useEffect is used to subscribe to a topic of the websocket.
   */
  useEffect(() => {
    const subscription = stompClient?.subscribe('/topic/process', (message) => {
      if (message?.body === lastMessage) return;
      setLastMessage(message?.body);
    });

    if (!isSubscribed && subscription?.id) setIsSubscribed(true);
    return () => subscription?.unsubscribe();
  }, [stompClient])

  /**
   * This useEffect starts the importing of file only when the websocket is subscribed.
   */
  useEffect(() => {
    if (isSubscribed) {
      const startedRecordIds = started.map(doc => doc.recordId);
      const newRecordsToImport = documents.filter(doc => !startedRecordIds.includes(doc.recordId));
      if (newRecordsToImport.length > 0) {
        importFile(newRecordsToImport);
        setStarted([...started, ...newRecordsToImport]);
      }
    }
  }, [isSubscribed, started, documents])

  /**
   * This useEffect checks the last message from the websocket subscription.
   */
  useEffect(() => {
    checkLastMessage(lastMessage);
  }, [lastMessage, documents])

  /**
   * This function imports files that have completed data mapping.
   * @param uploadedFiles The list of uploaded files.
   */
  const importFile = async (uploadedFiles: IUploadedFile[]) => {
    const toImport: IUploadedFile[] = [...uploadedFiles]
      .filter(document => document.mapped)
      .sort((a, b) => (a.recordId ?? 0) - (b.recordId ?? 0));

    await startProcessDocuments(toImport);
  };

  /**
   * This function process a list of uploaded files.
   * @param toImport The list of uploaded files to process.
   * @returns True if processing is completed.
   */
  const startProcessDocuments = async (toImport: IUploadedFile[]) => {
    for (const document of toImport) {
      if (document.origMappingChanged) {
        const isRestagedAndApplied: boolean = await restageAndApplyMappings(document);
        if (!isRestagedAndApplied) {
          document.processed = false;
          continue;
        };
      }

      // INVARIANCE: corrupted document types are now handled by restaging thus
      // documentType when the process comes here will always be a string
      const apis: IProcessingAPIs  = processingAPIs[document.documentType as string];

      const apiRequests = [
        {
          url: apis.STAGE,
          method: POST,
          params: {
            documentId: document.recordId,
            isStillMapping: false,
            batchSize: BATCH_SIZE,
            forUpcCustomers: hasUpcRelationship,
            parentClientId: selectedClient?.parentClientFk
          },
           // no need to pass mapped since data is already present in the staging tables
          data: []
        },
        {
          url: apis.PROCESS,
          method: POST,
          params: { ...getProcessParams(document), batchSize: BATCH_SIZE }
        }
      ];

      try {
        const statusCode: number = await startRequests(apiRequests);
        if (statusCode !== 201) throw new Error();
      } catch (error) {
        console.log('DOCUMENT PROCESSING ERROR: ', error);
        document.processed = false;
        document.isLoading = false;

        // Removing setting up of for loader because it will be handled by forDocumentDisplay
        const newDisplay = documents.map(file => {
          if (file.recordId === document.recordId) {
            return ({
              ...file,
              isLoading: document.isLoading,
              processed: document.processed,
            });
          }
          return file;
        });
        setDocuments(newDisplay);
      }
    };
  };

  /**
   * This function initiates a series of API requests sequentially and returns the status code of the last completed request.
   * @param apiRequests An array of API request objects to be executed sequentially.
   * @returns The HTTP status code of the last completed API request.
   */
  const startRequests = async (apiRequests: IProcessingRequests[]) => {
    let statusCode: number = 0;

    for (const apiRequest of apiRequests) {
      try {
        const response = await axiosInstance.request(apiRequest);
        statusCode = response.status;
      } catch (error) {
        break;
      }
    }

    return statusCode;
  }

  /**
   * This function determines and returns the processing parameters based on the document type.
   * @param document The uploaded document for which processing parameters are needed.
   * @returns An object containing processing parameters based on the document type.
   */
  const getProcessParams = (document: IUploadedFile) => {
    if (document.documentType === 'AR Aging' || document.documentType === 'AP Aging' || document.documentType === 'GL Transaction') {
      return {
        documentId: document.recordId,
        bbPeriodId: document.bbPeriodFk,
        arCollateralId: document.arCollateralFk,
        shouldReplace: document.shouldReplace,
        forUpcCustomers: hasUpcRelationship,
        parentClientId: selectedClient?.parentClientFk
      }
    } else {
      return {
        documentId: document.recordId,
        forUpcCustomers: hasUpcRelationship
      }
    }
  }

  /**
   * Restages and applies mappings for a given document to ensure data integrity.
   * Data integrity issues can occur when the user discarded changes on checking documents for errors/warnings.
   * 
   * @param document The document to restage and apply mappings to.
   * @returns A Promise that resolves to true if restaging and applying mappings was successful, false otherwise.
   */
  const restageAndApplyMappings = async (document: IUploadedFile) => {
    const isStaged: boolean = await restageRecords(document);
    if (!isStaged) return false;

    const isGLTransaction = document.documentType === 'GL Transaction';
    if (isGLTransaction) {
      const calcMappings: ICalculationMapping[] | undefined = await getCalculationMappings(document);
      if (!calcMappings) return false;

      const applied: IUploadedFile | undefined = await applyCalculationMappings(document, calcMappings);
      if (!applied) return false;
    }

    return true;
  };

  /**
   * Restages a record or a batch of records.
   * @param uploaded The uploaded file object containing the values that need to be restaged.
   * @returns A promise that resolves to the uploaded file object with the updated `isParsing` property.
   */
  const restageRecords = async (uploaded: IUploadedFile) => {
    const apis: IProcessingAPIs  = processingAPIs[uploaded.documentType as string];

    const cleanedPrevious: IUploadedFile | undefined = await cleanStaged(uploaded, apis);
    if (cleanedPrevious) {
      const filtered = excludeRows(uploaded.values as string[][], uploaded.excludedRowsEndRowNum);
      const dataMapped: any[] = applyDataMapping(uploaded, filtered);
      if (dataMapped.length === 0) {
        return false;
      };

      const stagedStatusCode: number | undefined = await stageDocument(uploaded, dataMapped);
      if (stagedStatusCode !== 201) {
        return false;
      };

      return true;
    }

    return false;
  };

  /**
   * This function cleans the previous staged data for the provided uploaded file using the specified APIs.
   * @param uploadedFile The uploaded file object.
   * @param apis An object containing processing APIs for the document type.
   * @returns The updated uploaded file object if the cleaning operation is successful, or undefined if an error occurs.
   */
  const cleanStaged = async (uploadedFile: IUploadedFile, apis: IProcessingAPIs) => {
    try {
      const response = await axiosInstance.request({
        url: apis.CLEAN,
        method: DELETE,
        params: { documentId: uploadedFile.recordId, isStillMapping: true, batchSize: BATCH_SIZE }
      });
      if (response.status === 204) {
        return uploadedFile;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log('CLEANING PREVIOUS STAGED: ', error);
    }
  };

  /**
   * This function fetches the initial calculation mappings for an uploaded file.
   * @param uploadedFile The uploaded file for which to fetch initial calculation mappings.
   * @returns An array of initial calculation mappings or undefined if an error occurs.
   */
  const getCalculationMappings = async (uploadedFile: IUploadedFile) => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.calculationMapping.GET_INITIAL_CALCULATION_MAPPING,
        method: GET,
        params: { documentId: uploadedFile.recordId }
      });
      const initialMappings: ICalculationMapping[] = response.data;
      return initialMappings;
    } catch (error) {
      console.log('GET CALCULATION MAPPINGS ERROR: ', error);
    }
  };

  /**
   * This function applies updated calculation mappings to an uploaded file.
   * @param uploadedFile The uploaded file to which the calculation mappings will be applied.
   * @param updatedMappings An array of updated calculation mappings.
   * @returns The updated uploaded file with applied calculation mappings or undefined if an error occurs.
   */
  const applyCalculationMappings = async (uploadedFile: IUploadedFile, updatedMappings: ICalculationMapping[]) => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.calculationMapping.APPLY_CALCULATION_MAPPING,
        method: POST,
        params: { documentId: uploadedFile.recordId },
        data: updatedMappings
      });
      if (response.status === STATUS_CODES.CREATED) {
        uploadedFile.calculationMappings = updatedMappings;
        return uploadedFile;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log('ERROR APPLYING CALCULATION MAPPINGS: ', error);
    }
  };

  /**
   * This function applies data mapping to the values extracted from an uploaded file and constructs a list of payloads.
   * @param uploaded The uploaded file for which to apply data mapping.
   * @returns An array of payloads resulting from data mapping.
   */
  const applyDataMapping = (uploaded: IUploadedFile, values: string[][]) => {
    const requestList: any[] = [];
  
    if (values.length) {
      const fields = fieldsPerDocumentType[uploaded.documentType as string];
  
      values.forEach((values: string[], valIndex: number) => {
        if (valIndex !== 0) {
          const payload: any = handleHeaderMapping(uploaded, values, fields);
          const cleaned = cleanPayload(payload);
          const isPayloadEmpty: boolean = Object.keys(cleaned).length === 0;

          if (!isPayloadEmpty) {
            const addedIds = {...cleaned, ...getInitialPayloadByDocType(uploaded)};
            requestList.push(addedIds);
          }
        }
      })
    }
  
    return requestList;
  };

  /**
   * This function handles the mapping of headers to their corresponding values in an uploaded file.
   * It constructs a payload based on the header mapping and file values.
   * @param uploaded The uploaded file for which to handle header mapping.
   * @param values The values extracted from the uploaded file.
   * @param fields The mapping fields for the document type.
   * @returns The constructed payload with header mapping.
   */
  const handleHeaderMapping = (uploaded: IUploadedFile, values: string[], fields: { name: string; value: string; }[]) => {
    const payload: any = {};
    uploaded.dataMappingTableFields?.forEach((header: string | null, headerIndex: number) => {
      const value: string = values[headerIndex];

      if (header && !checkIfBlankString(value)) {
        if (dateHeaders.includes(header)) {
          payload[header] = formatDate(value.trim(), 'YYYY-MM-DD');
        } else if (amtHeaders.includes(header)) {
          payload[header] = handleAmountMapping(value);
        } else if (header === 'glTrxAmt2') {
          const amount2 = handleAmountMapping(value);
          payload[header] = uploaded.dataMappingChangeAmt2Sign ? -amount2 : amount2
        } else {
          const dataType = state.userDefinedFields?.find(field => header === field.fieldName)?.fieldType ?? '';
          handleUserDefinedFields(uploaded.documentType as string, fields, header, payload, value, dataType);
        }
      }
    })
  
    return payload;
  };

  /**
   * This function handles the amount mapping.
   * @param value The value of a document.
   * @returns A parsed amount of the value.
   */
  const handleAmountMapping = (value: string) => {
    const amount = stringCurrencyToNumber(value.trim());
    if (isNaN(amount)) return 0.00;
    else return amount;
  };

  /**
   * This function asynchronously stages an uploaded document for further processing. It sends data in batches to the staging API.
   * @param uploaded The uploaded file to stage for processing.
   * @param dataMapped The data mapped from the uploaded file.
   * @returns A promise that resolves to the HTTP status code of the staging operation.
   */
  const stageDocument = async (uploaded: IUploadedFile, dataMapped: any[]) => {
    const docType = uploaded.documentType as string;
    const apis: IProcessingAPIs  = processingAPIs[docType];

    const stagingRequest =
      {
        url: apis.STAGE,
        method: POST,
        params: {
          documentId: uploaded.recordId,
          isStillMapping: true,
          batchSize: BATCH_SIZE,
          forUpcCustomers: hasUpcRelationship,
          parentClientId: selectedClient?.parentClientFk
        },
      };

    const apiRequests = [
      {...stagingRequest},
      {
        url: apis.CLEAN,
        method: DELETE,
        params: { documentId: uploaded.recordId, isStillMapping: true, batchSize: BATCH_SIZE }
      }
    ];

    try {
      // Last argument determines concurrency of requests
      let statusCode: number =
        await customAsyncStagingRequest(
          apiRequests,
          dataMapped,
          BATCH_SIZE,
          CONCURRENCY_OF_STAGING_REQUESTS
        ) 
      return statusCode;
    } catch (error) {
      console.log('STAGING UPLOADED ERROR: ', error);
      uploaded.values = undefined;
      uploaded.defaultMapping = undefined;
      uploaded.isParsing = false;

      const cleanStaging = apiRequests[1];
      try {
        await axiosInstance.request(cleanStaging);
      } catch (error) {
        console.log('FAILED CLEANING THE STAGED RECORDS: ', error);
      }
    }
  };

  /**
   * This function checks the last message from the websocket client.
   * @param lastMessage The last message from the websocket client about the progress of the processing.
   */
  const checkLastMessage = async (lastMessage: string) => {
    if (lastMessage.includes('data processing completed')) {
      setLastMessage(''); // resetting to empty string to prevent doubling of requests
      const documentId = parseInt(lastMessage.split("=")[1].trim());
      const document = documents.find(doc => doc.recordId === documentId && !doc.processed);
      if (!document) return;

      // continue processing
      await continueProcessDocument(document);
    }

    if (lastMessage.includes('data processing failed')) {
      setLastMessage(''); // resetting to empty string to prevent doubling of requests
      const documentId = parseInt(lastMessage.split("=")[1].trim());
      const document = documents.find(doc => doc.recordId === documentId);
      if (!document) return;

      document.processed = false;
      document.isLoading = false;

      const newDisplay = documents.map(file => {
        if (file.recordId === document.recordId) {
          return ({
            ...file,
            isLoading: document.isLoading,
            processed: document.processed,
          });
        }
        return file;
      });

      setDocuments(newDisplay);
    }
  }

  /**
   * Continues processing the provided document by updating its processed status, data mapping status, and cleaning staged records.
   * If an error occurs during processing, logs the error and sets the processed status of the document to false.
   * Finally, updates the display state with the updated document details.
   * @param document The document to continue processing
   */
  const continueProcessDocument = async (document: IUploadedFile) => {
    try {
      const postProcessStatus: number | undefined = await updateProcessedFile(document);
      if (postProcessStatus !== 200) {
        throw new Error();
      }

      document.processed = true;
      document.dataMappingStatus = 'Applied';

      await cleanStagedRecords(document);
    } catch (error) {
      console.log('DOCUMENT PROCESSING ERROR: ', error);
      document.processed = false;
    } finally {
      document.isLoading = false;
    }

    const newDisplay = documents.map(file => {
      if (file.recordId === document.recordId) {
        return ({
          ...file,
          isLoading: document.isLoading,
          processed: document.processed,
        });
      }
      return file;
    });
    setDocuments(newDisplay);
  }

  /**
   * This function sends a request to update a processed document after post-processing.
   * @param document The processed document to be updated.
   */
  const updateProcessedFile = async (document : IUploadedFile) => {
    try {
      const postProcessReponse = await axiosInstance.request({
        url: `${API_DOMAIN}/document/postProcess`,
        method: PUT,
        params: { documentId: document.recordId }
      });
      return postProcessReponse.status;
    } catch (error) {
      console.log('POST PROCESSED UPDATE ERROR: ', error)
    }
  };

  /**
   * Cleans staged records associated with the provided document by making a DELETE request to the corresponding processing API.
   * @param document The document for which staged records need to be cleaned
   * @returns The status code of the clean staging response, or undefined if an error occurs
   */
  const cleanStagedRecords = async (document : IUploadedFile) => {
    try {
      const apis: IProcessingAPIs  = processingAPIs[document.documentType as string];
      const cleanStagingResponse = await axiosInstance.request({
        url: apis.CLEAN,
        method: DELETE,
        params: {
          documentId: document.recordId,
          isStillMapping: false,
          batchSize: BATCH_SIZE
        }
      });
      return cleanStagingResponse.status;
    } catch (error) {
      console.log('CLEANING STAGED ERROR: ', error);
    }
  }

  /**
   * This function hides the file import process loader by dispatching an action to set the 'show' property to false.
   */
  const hideLoader = () => {
    dispatch(setFileImportProcessLoader({
      ...state,
      show: false
    }));
  }

  /**
   * This callback function navigates to the 'ineligible-settings' page and hides the loader.
   */
  const handleNavigateIneligibleSettings = useCallback(() => {
    navigate('/ineligible-settings');
    hideLoader();
  }, []);

  /**
   * This callback function navigates to the 'customer-settings' page and hides the loader.
   */
  const handleNavigateCustomerSettings = useCallback(() => {
    if (selectedClient?.parentClientFk) {
      const parentClient = clients?.find(client => client.recordId === selectedClient?.parentClientFk);
      setSelectedClient(parentClient ?? null);
      navigate(`/clients/${selectedClient?.parentClientFk}/settings/0/customers`);
    } else {
      navigate(`/clients/${selectedClient?.recordId}/settings/${defaultCollateral?.recordId}/customers`);
    }
    hideLoader();
  }, [selectedClient?.recordId, defaultCollateral?.recordId]);

  /**
   * Gets the status icon for an uploaded file item based on its properties.
   * @param item The uploaded file item to determine the status icon for.
   * @returns The status icon element.
   */
  const getStatusIcon = (item: IUploadedFile) => {
    if (item.isLoading) {
      return (
        <CircularProgress
          variant='indeterminate'
          disableShrink
          sx={{...styles.spinner, ...styles.iconLeftSpacing}}
          size={20}
          thickness={5}
        />
      );
    } else if (item.processed) {
      return (<CheckCircleRounded htmlColor={colors.SUCCESS} sx={styles.iconLeftSpacing}/>);
    } else {
      return (<ErrorIcon htmlColor={colors.ERROR} sx={styles.iconLeftSpacing}/>);
    }
  }

  return <Box>
    <Box sx={styles.header}>
      <Typography tabIndex={0}>{hasLoadingFiles ? 'Importing Files' : 'Files Imported'}</Typography>
      <Box>
        <IconButton aria-label='Expand icon' sx={styles.action} onClick={() => setIsAccordionExpanded(!isAccordionExpanded)}>
          {
            !isAccordionExpanded ? <ExpandMore htmlColor={colors.WHITE} /> : <ExpandLess htmlColor={colors.WHITE} />
          }
        </IconButton>
        <IconButton aria-label='Close icon' sx={styles.action} onClick={(event) => {
          event.stopPropagation();
          hideLoader();
        }}>
          <CloseSharp htmlColor={colors.WHITE} />
        </IconButton>
      </Box>
    </Box>
    
    <Accordion
        defaultExpanded={true}
        expanded={isAccordionExpanded}
        sx={styles.contentWrapper}
    >
      <AccordionSummary
        aria-controls="panelcl-content"
        id="panelcl-header"
        hidden
        sx={styles.accordionSummary}
      >
      { hasLoadingFiles ?
          <Box sx={{...styles.content}}>
            <Button variant='text' sx={{...styles.smallFont, p: 0, display:'none'}}>CANCEL</Button>
          </Box>
        :
          state.selectedClientId && <Box sx={styles.content}>
            <Typography tabIndex={-1} sx={styles.smallFont}>{content}</Typography>
          </Box>
      }
      </AccordionSummary>

      <AccordionDetails sx={styles.accordionDetails}>
        <Box>
          <Box sx={{...styles.content}}>
            {state.selectedClientId ?
            <List dense={true} sx={{width: 1}}>
              {documents.map((item) => 
                <ListItem
                  key={item.recordId}
                  secondaryAction={getStatusIcon(item)}
                >
                  <ListItemText tabIndex={0}>{item.filename}</ListItemText>
                </ListItem>
              )}
            </List>
            : <ListItem
            secondaryAction={
              hasLoadingFiles ? 
              <CircularProgress
                tabIndex={0}
                variant="indeterminate"
                disableShrink
                sx={{...styles.spinner, ...styles.iconLeftSpacing}}
                size={20}
                thickness={5}
              />
            : <CheckCircleRounded tabIndex={0} htmlColor={colors.SUCCESS} sx={styles.iconLeftSpacing}/>
            }
          >
            <ListItemText tabIndex={0}>{content}</ListItemText>
          </ListItem>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
    {
      !hasLoadingFiles && (
        <Box sx={styles.rightButtonsContainer}>
          <CalculateIneligiblesButton
            calculateButtonStyles={styles.calculateIneligiblesButton}
            hideFileImportLoader={hideLoader}
          />
          <Button variant='contained' sx={styles.goToIneligibleSettingsButton} onClick={handleNavigateIneligibleSettings}>
            Go to Ineligible Settings
          </Button>
          <Button variant='contained' sx={styles.goToIneligibleSettingsButton} onClick={handleNavigateCustomerSettings}>
            Go to Customer Settings
          </Button>
        </Box>
      )
    }
  </Box>;
}

export default SubscribingComponent;