import { useContext, useState } from 'react';
import { Field } from 'formik';
import { Box, InputAdornment, TextField } from '@mui/material';
import { NON_EXISTING_PERCENT } from '../../../../../utility/constants';
import { IIneligibleRuleOverride, IIneligibleSettingsContext } from '../../../../../interfaces/ineligibleSettingInterface';
import { IneligibleSettingsContext } from '../../../../../context/ineligibleSettingsContext';
import { getDisplayedError, PercentFormat } from '../../../ineligible-setting-details';
import { OverrideSettingsProps } from '../..';
import styles from '../../styles';

/**
 * Component responsible for rendering and handling the Concentration settings for an override.
 * @param props The props for the component.
 * @returns The rendered ConcentrationSettings component.
 */
const ConcentrationSettings = ({idx, formik}: OverrideSettingsProps) => {
  const { selectedIneligibleIndex: ineligibleIndex } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const [isSelected, setIsSelected] = useState(false);

  /**
   * This function gets the selected concentration percentage for the current override.
   * @returns The selected concentration percentage or NON_EXISTING_PERCENT if not set.
   */
  const getSelectedIneligibleRuleOverrideConcentrationPercent = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRuleOverrides as IIneligibleRuleOverride[])[idx].concentrationPct ?? NON_EXISTING_PERCENT;

  /**
   * This function gets the Formik field name for the concentration percentage.
   * @returns The Formik field name for the concentration percentage.
   */
  const getFormikFieldNameForIneligibleRuleOverrideConcentrationPercent = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].concentrationPct`;

  /**
   * This function gets the input adornment based on whether the field is selected or the value is non-existing.
   * @returns The input adornment (percentage sign) or null.
   */
  const getInputAdornment = () => {
    if (isSelected) { return null; }
    if (getSelectedIneligibleRuleOverrideConcentrationPercent() === NON_EXISTING_PERCENT) {
      return <InputAdornment position='end'>%</InputAdornment>;
    }
    return null;
  };

  /**
   * This function handles the change of the concentration value.
   * @param event The input change or focus event.
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      formik.setFieldValue(getFormikFieldNameForIneligibleRuleOverrideConcentrationPercent(), NON_EXISTING_PERCENT);
      formik.setFieldError(getFormikFieldNameForIneligibleRuleOverrideConcentrationPercent(), `Concentration is required`);
      return;
    }
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleOverrideConcentrationPercent(), parseFloat(event.target.value));
    formik.setFieldError(getFormikFieldNameForIneligibleRuleOverrideConcentrationPercent(), undefined);
  };

  return (
    <Box sx={styles.specialSettingsContainer}>
      <Field
        as={TextField}
        label='Concentration'
        name={getFormikFieldNameForIneligibleRuleOverrideConcentrationPercent()}
        value={getSelectedIneligibleRuleOverrideConcentrationPercent() === NON_EXISTING_PERCENT ? '' : getSelectedIneligibleRuleOverrideConcentrationPercent()}
        size='small'
        type='number'
        sx={{ ...styles.textField, ...styles.numberAlignment }}
        fullWidth
        InputProps={{ endAdornment: getInputAdornment(), inputComponent: PercentFormat as any }}
        onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => { setIsSelected(false); }}
        onFocus={(_event: React.FocusEvent<HTMLInputElement>) => setIsSelected(true)}
        {...getDisplayedError(formik, getFormikFieldNameForIneligibleRuleOverrideConcentrationPercent())}
      />
    </Box>
  );
}

export default ConcentrationSettings;
