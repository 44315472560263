import { Box, Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import styles from './styles';

interface IIneligibleSettingsTopErrorMessageProps {
  isDisplayed: boolean;
}

/**
 * Component displays an error message at the top of the Ineligible Settings form.
 * @param {boolean} isDisplayed - Indicates whether the error message should be displayed.
 */
const IneligibleSettingsTopErrorMessage: React.FC<IIneligibleSettingsTopErrorMessageProps> = (props) => {
  return (
    <Box sx={{ ...(props.isDisplayed ? styles.container : styles.invisible ) }}>
      <ErrorIcon sx={styles.icon} />
      <Typography sx={styles.message}>Please complete the required fields below</Typography>
    </Box>
  );
};

export default IneligibleSettingsTopErrorMessage;
