const styles = {
  messageContainer: {
    backgroundColor: '#fbfbfb',
    padding: '1rem',
    borderRadius: '8px',
  },
  messageText: {
    textAlign: 'center',
    fontSize: '14px',
  },
}

export default styles;
