const styles = {
  appBar: {
    padding: '0.3rem',
    '& .MuiToolbar-root': {
      paddingLeft: 0,
    },
  },
  logo: { 
    height: '64px',
    width: '200px',
    maxHeight: '64px',
    display: 'block',
    objectPosition: '50% 50%',
    'object-fit': 'contain'
  },
  avatarHolder: {
    backgroundColor: '#154A8A',
  },
  webDrawerToggleButton: {
    display: { 
      xs: 'none', 
      sm: 'block' 
    },
  },
  mobileDrawerToggleButton: {
    display: {
      xs: 'block',
      sm: 'none'
    },
  },
  box: {
    flexGrow: 1,
    display: 'flex',
  },
  divider: {
    borderRight: '1px solid #E5E2E2',
    height: '2rem',
    marginRight: '1rem',
  },
  loanWatchLogo: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem',
  },
  loanWatchSubtitle: {
    fontSize: '0.7rem',
    fontWeight: 'bold',
    color: 'text.secondary',
    marginRight: '0.5rem',
  },
  iconButton: {
    p: '0.5rem',
    borderRadius: '25rem',
  },
  menuButton: {
    display: 'flex',
    gap: '0.8rem',
  },
  menu: {
    mt: '3rem',
  },
  settings: {
    flexGrow: 0,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default styles;
