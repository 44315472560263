const styles = {
  textFieldPadding: {
    '.MuiOutlinedInput-root': {
      paddingRight: '18px !important' 
    }
  },
  popper: {
    zIndex: 1300
  },
  datePickerContainer: {
    border: 1,
    p: 1,
    bgcolor:
    'background.paper'
  },
  dropdown: {
    fontSize: '14px',
    backgroundColor: 'white',
    '.MuiInputBase-root' : {
      padding: '0.1rem',
    },
    '&&& .MuiOutlinedInput-root': {
      paddingY: '0.1rem',
      paddingLeft: '0.3rem',
      paddingRight: '1rem'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      fontSize: '14px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        fontSize: '14px'
      },
      padding: '0.1rem',
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  datePickerIcon: {
    fontSize: '14px',
    padding: '0.3rem',
  },
}
export default styles;