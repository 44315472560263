import colors from "../../../utility/colors";

const styles = {
  title: {
    fontWeight: 'bold',
    color: '#010953',
    fontSize: '18px',
    ml: '24px',
  },
  breadCrumbBox: {
    paddingRight: '24px',
    borderRadius: '0.3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  breadCrumbText: {
    fontSize: '12px',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  gridStyle: {
    minWidth: 'fit-content',
  },
  calculateSettings: {
    width: '50%',
  },
  headerCard: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FEFEFE',
    padding: 3,
    marginTop: '1rem',
    marginBottom: '1rem',
    marginLeft: '1.4rem',
    marginRight: '1.4rem'
  },
  titleCard: {
    backgroundColor: '#FEFEFE',
    py: 2.5
  },
  numericTextField: {
    textAlign: 'right',
  },
  clientComboBox: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
  },
  labelStyle: {
    display: 'block',
    fontSize: '14px',
    marginBottom: 1,
  },
  clientDropdownBox: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  clientLabel: {
    display: 'block',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    marginRight: '16px'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clientDropdown: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  clientBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownText: {
    fontSize:'14px'
  },
  totalAmountContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  labelTotalAmount: {
    fontSize: '14px',
    fontWeight: '600',
    marginRight: '5px',
  },
  valueTotalAmount: {
    fontSize: '14px',
  },
  labelChipContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  parentChipContainer: {
    width: '100px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  parentIdentifierChip: {
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"P"',
    },
    '& .MuiChip-label:hover:after': {
      content: '"Parent"',
    }
  },
  hidden: {
    display: 'none',
  }
};

export default styles;
