const styles = {
  backgroundBox: {
    backgroundColor: '#F2F7F8',
  },
  breadCrumbBox: {
    paddingRight: '24px',
    borderRadius: '0.3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  card: {
    height: '4rem',
  },
  cardButtonCommon: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    textAlign: 'left',
    overflow: 'hidden',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#154A8A',
    height: '100%',
    paddingLeft: '5.2rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '3.5% 50%',
    backgroundSize: '4rem',
  },
  clientDropdown: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  clientBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    color: '#010953',
    fontSize: '18px',
    ml: '24px',
  },
  titleContainer: {
    backgroundColor: '#FEFEFE',
    py: 2.5
  },
  invisible: {
    visibility: 'hidden',
  },
  hideButton: {
    display: 'none'
  },
};

export default styles;
