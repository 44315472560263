import colors from '../../../../../utility/colors';

const styles = {
    boxColor: {
      backgroundColor: '#F2F7F8',
    },
    groupedList: {
      '&&&& .MuiListSubheader-root.MuiAutocomplete-groupLabel': {
        color        : 'black',
        fontSize     : '16px',
        fontWeght    : 'bold'
      },
    },
    comboBox: {
      fontSize: '14px',
      backgroundColor: 'white',
      width: '17rem',
      marginTop: '0.5rem',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E0E0E0',
      },
    
      '& .MuiOutlinedInput-root': {
        color: '#000',
        padding: '15px',
        fontSize: '14px',
        height: '36.6px',
        backgroundColor: 'white',
        '& .MuiOutlinedInput-input': {
          width: '100%',
          padding: '0px',
        },
      },
      '& .Mui-disabled': {
        backgroundColor: '#F7F7F7',
      },
    },
    title: {
      fontSize       : '18px',
      fontWeight     : 'bold',
      color          : '#010953',
    },
    subtitleContainer: {
      display        : 'flex',
      justifyContent : 'space-between',
    },
    subtitle: {
      fontWeight     : 'bold',
      color          : '#154A8A',
      fontSize       : '16px',
      alignSelf      : 'end',
    },
    titleContainer: {
      backgroundColor: '#fff',
      paddingY       : '24.2px',
      marginY        : '1rem',
      paddingX       : '28px',
    },
    searchField: {
      backgroundColor: 'white',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E0E0E0',
      },
      width          : '100%',
    },
    comboBoxStyle: {
      display        : 'flex',
      placeContent   : 'space-between',
      alignItems     : 'center',
    },
    dropFileIconBox: {
      textAlign      : 'center',
      fontSize       : '18px',
      color          : '#010953',
      paddingTop     : '3rem',
      paddingBottom  : '1rem', 
    },
    dropFileContainer: {
      border         : 'dashed',
      margin         : 'auto',
      marginY        : '3rem',
      borderColor    : '#707070',
      borderRadius   : '20px',
      borderWidth    : 'medium',
    },
    dropFile: {
      textAlign      : 'center',
      fontSize       : '18px',
      color          : '#010953',
    },
    dropFileIcon: {
      fontSize       : '50px',
      paddingY       : '5px'
    },
    dropFileButton: {
      textAlign      : 'center',
      paddingY       : '1rem',
    },
    errorMessage: {
      textAlign      : 'center',
      fontSize       : '14px',
      color          : colors.ERROR,
    },
    errorActionButton: {
      mx: 1,
    },
    searchStyle: {
      alignSelf      : 'center',
      marginTop      : '1.89rem',
      marginLeft     : '0.5rem',
      flexBasis      : 'auto',
    },
    modalOpenButton: {
      py             : '2px',
      textTransform  : 'none',
      px             : '3rem',
    },
    filterGridContainer: {
      backgroundColor: 'white',
      marginY        : '30px',
      paddingBottom  : '1rem'
    },
    filterChildBoxMargin: {
      margin         : '1rem',
      position:"relative"
    },
    instructionContainer: {
      backgroundColor: 'white',
      height         : 'auto',
    },
    instructionHeader: {
      fontSize       : '18px',
      fontWeight     : 'bold',
      color          : '#010953',
    },
    uploadedFilesTitle: {
      fontSize       : '18px',
      fontWeight     : 'bold',
      padding        : '5px'
    },
    dropdownTitle: {
      fontSize       : '14px',
      paddingBottom  : '12px',
      '&::after':{
        content:'"*"',
        color: '#AE2633',
      },
    },
    uploadedFilesBg: {
      background     : '#F5F5F5',
    },
    uploadedFilesContainer: {
      margin: '2.5rem'
    },
    uploadedFiles: {
      display        : 'flex',
      flexDirection  : 'column',
      overflowY      : 'auto',
      height         : 'inherit',
      alignItems     : 'center'
    },
    uploadedFileItem: {
      display        : 'flex',
      flexDirection  : 'row',
      justifyContent : 'space-between',
      paddingY       : '8px',
      paddingX       : '24px',
      marginY        : '8px',
      width          : '30vw'
    },
    uploadedFileCompleted: {
      fontSize       : '12px',
      display        : 'flex',
      flexDirection  : 'row',
      alignItems     : 'center',
      color          : '#155724',
      paddingRight   : '10px',
      paddingTop     : '5px',
    },
    statusText: {
      fontSize       : '12px',
    },
    uploadedFileError: {
      fontSize       : '12px',
      display        : 'flex',
      flexDirection  : 'row',
      alignItems     : 'center',
      color          : `${colors.ERROR}`,
      paddingRight   : '10px',
      paddingTop     : '5px',
    },
    statusIcon: {
      fontSize       : '12px',
      flexDirection  : 'column',
    },
    fileExtensions: {
      marginTop      : '5px',
      fontSize       : '12px',
      color          : '#707070'
    },
    uploadedFilesEmptyState: {
      display        : 'flex',
      flexDirection  : 'column',
      alignItems     : 'center',
      justifyContent : 'center',
    },
    uploadedFileName: {
      fontSize       : '15px',
      color          : '#010953',
      display        : 'flex',
      alignItems     : 'flex-end',
    },
    fileSize: {
      fontSize       : '10px',
      color          : '#707070',
      marginLeft     : '5px',
      marginBottom   : '1px',
    },
    uploadedFileDetails: {
      fontSize       : '10px',
      color          : '#707070',
    },
    uploadCompleted: {
      fontSize       : '13px',
      color          : '#3EB96C',
      paddingX       : '25px',
    },

    deleteIcon: {
      color : '#154A8A',
    },

    modalBackground: { 
      bgcolor: '#FFFFFF',
      borderRadius: '0.2rem',
      left: '50%',
      position: 'absolute',
      boxShadow: 15,
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },

    headingContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      m: 0,
      pb: '17.5px',
      pl: '21px',
      pr: '21px',
      pt: '24px',
    },

    headingTitle: {
      color: '#1B1E21',
      fontSize: '18px',
      fontWeight: 600,
    },

    divider: {
      bgcolor: '#EBEBEB',
      ml: '13.5px',
      mr: '19.5px',
      opacity: 0.5,
      width: '500px',
    },

    descriptionContainer: {
      display: 'flex',
      gap: '20px',
      margin: '30px 21px 30px 21px',
      width: '450px',
      justifyContent: 'flex-start'
    },

    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      mb: '21px',
      mr: '24.5px',
      mt: '15.5px',
    },

    cancelButton: {
      height: '31px',
      mr: 1,
      textTransform: 'capitalize',
      minWidth: '82px',
      borderColor: colors.PRIMARY,
      color: colors.PRIMARY,
    },
    
    primaryButton: {
      height: '31px',
    textTransform: 'capitalize',
    minWidth: '82px',
    backgroundColor: colors.ERROR,
    '&:hover': {
      backgroundColor: colors.ERROR_DARK,
    },
    },

    closeButton: {
      cursor: 'pointer',
    },

    browseFileButton: {
      textTransform: 'none'
    },

    fileActionRadioGroup: {
      marginLeft: '1.5rem',
      marginTop: '-1.5rem'
    },

    noSelectedRadioButton: {
      display: 'none'
    },

    filenameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  };
  
export default styles;