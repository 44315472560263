import { IAccountsReceivableSetting } from "../../../components/client-settings/tabs/tab-panel-contents/accounts-receivable";
import { IARBorrowerInput, IARCollateral, IBBPeriod, IClient } from "../../../interfaces";
import { IAPAgingReport, IAPAgingReportVendorDetail, IUPCAPAgingReportVendorDetail } from "../../../interfaces/apAgingReportInterface";
import { IRate } from "../../../interfaces/multiCurrencyInterface";
import { multiCurrencyAPI, reportsAPI } from "../../../service/api";
import axiosInstance from "../../../service/axiosInstance";
import requests from "../../../service/requests";
import { API_DOMAIN, GET, NON_EXISTING, NON_EXISTING_PERCENT, POST } from "../../../utility/constants";
import { getAvailableUpcDatesForCalcForAPAging, getBBPeriodWithTransactionsRequestByARCollateralId } from "../../../utility/helper";

export const getARSettings = async (selectedClient: IClient) => {
  const arCollateralsResponse = await axiosInstance.request({
    url: `${API_DOMAIN}/ar/collaterals/search/findActiveAndDefaultCollateralsFirst`,
    method: GET,
    params: { borrowerId: selectedClient.recordId, pageNo: 0, pageSize: 9999 }
  });
  const arCollaterals = arCollateralsResponse.data.content as IARCollateral[];
  const getARCollateralInputRequests: { url: string, method: string}[] = arCollaterals.map((collateral: IARCollateral) => ({
    url: `${API_DOMAIN}/ar/borrowerInputs/search/findByARCollateralId`,
    method: GET,
    params: { arCollateralId: collateral.recordId, pageNo: 0, pageSize: 1, sortBy: 'recordId,DESC', isCurrent: true }
  }));
  const borrowerInputResponses = await requests(getARCollateralInputRequests.map((config) => axiosInstance.request(config)));
  const arCollateralInputs = borrowerInputResponses.reduce((previousARSettings, currentBorrowerInputResponse, index) => {
    const borrowerInput = currentBorrowerInputResponse.data.content[0] as IARBorrowerInput;
    if (borrowerInput === undefined) { return previousARSettings; }
    const currentARSetting = {
      arCollateral: arCollaterals[index],
      arBorrowerInput: {
        ...borrowerInput,
        rateOfAdvance: borrowerInput.rateOfAdvance * 100,
        ...(borrowerInput.arSubLimit !== undefined
          ? { arSubLimit: borrowerInput.amountLimitType === 'Percentage' ? borrowerInput.arSubLimit * 100 : borrowerInput.arSubLimit }
          : { arSubLimit: NON_EXISTING_PERCENT })
      }
    };
    return [ ...previousARSettings, currentARSetting];
  }, [] as IAccountsReceivableSetting[]);
  return arCollateralInputs;
};

export const getUPCARSettings = async (selectedClient: IClient, defaultChildClient: IClient) => {
  const arCollateralsResponse = await axiosInstance.request({
    url: `${API_DOMAIN}/ar/collaterals/search/findAllActiveByParentClientID`,
    method: GET,
    params: { parentClientFk: selectedClient.recordId }
  });
  const arCollaterals = arCollateralsResponse.data as IARCollateral[];
  const getARCollateralInputRequests: { url: string, method: string}[] = arCollaterals.map((collateral: IARCollateral) => ({
    url: `${API_DOMAIN}/ar/borrowerInputs/search/findByARCollateralId`,
    method: GET,
    params: { arCollateralId: collateral.recordId, pageNo: 0, pageSize: 1, sortBy: 'recordId,DESC', isCurrent: true }
  }));
  const borrowerInputResponses = await requests(getARCollateralInputRequests.map((config) => axiosInstance.request(config)));
  const arCollateralInputs = borrowerInputResponses.reduce((previousARSettings, currentBorrowerInputResponse, index) => {
    const borrowerInput = currentBorrowerInputResponse.data.content[0] as IARBorrowerInput;
    if (borrowerInput === undefined) { return previousARSettings; }
    const currentARSetting = {
      arCollateral: arCollaterals[index],
      arBorrowerInput: {
        ...borrowerInput,
        rateOfAdvance: borrowerInput.rateOfAdvance * 100,
        ...(borrowerInput.arSubLimit !== undefined
          ? { arSubLimit: borrowerInput.amountLimitType === 'Percentage' ? borrowerInput.arSubLimit * 100 : borrowerInput.arSubLimit }
          : { arSubLimit: NON_EXISTING_PERCENT })
      }
    };
    return [ ...previousARSettings, currentARSetting];
  }, [] as IAccountsReceivableSetting[]);
  const defaultARCollateralIndexForUPC = arCollateralInputs.findIndex(arCollateralInput => (
    arCollateralInput.arCollateral.borrowerId === defaultChildClient.recordId && arCollateralInput.arCollateral.default
  ));
  arCollateralInputs.splice(0, 0, arCollateralInputs.splice(defaultARCollateralIndexForUPC, 1)[0]);
  return arCollateralInputs;
};

export const getAsOfDates = async (selectedClient: IClient, selectedCollateral: IARCollateral) => {
  const response = await axiosInstance.request({
    url   : `${API_DOMAIN}/bbPeriods/search/findByBorrowerId`,
    method: GET,
    params: {borrowerId: selectedClient.recordId, pageNo: 0, pageSize: 99999, isDefault: true}
  });
  const asOfDates: Required<IBBPeriod>[] = response.data.content;
  const bbPeriodsWithTransactionsResponse = await getBBPeriodWithTransactionsRequestByARCollateralId(selectedCollateral.recordId);
  const bbPeriodsWithTransactions: number[] = bbPeriodsWithTransactionsResponse.data;
  return asOfDates.filter(asOfDate => bbPeriodsWithTransactions.includes(asOfDate.recordId));
};

export const getUPCAsOfDates = async (selectedClient: Required<IClient>) => {
  const response = await getAvailableUpcDatesForCalcForAPAging(selectedClient.recordId);
  if (response === undefined) { return []; }
  return response.map(date => ({
    borrowerId: NON_EXISTING,
    fiscalPeriod: NON_EXISTING,
    fiscalYear: NON_EXISTING,
    periodName: '',
    endDate: date.recordId.toString()
  })) as IBBPeriod[];
};

export const checkHasInvoiceDates = async (selectedCollateral: IARCollateral, selectedAsOfDate: IBBPeriod) => {
  try {
    const response = await axiosInstance.request({
      url: `${API_DOMAIN}/ar/apTransactions/hasInvoiceDates`,
      method: 'GET',
      params: { arCollateralId: selectedCollateral.recordId, bbPeriodId: selectedAsOfDate.recordId }
    });
    return response.data as boolean;
  } catch (error) {
    console.log('CHECK INVOICE DATES ERROR: ', error);
    return true;
  }
};

export const checkHasInvoiceDatesForUPC = async (selectedParentClient: IClient, selectedAsOfDate: IBBPeriod) => {
  try {
    const formattedDate = selectedAsOfDate.endDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
    const response = await axiosInstance.request({
      url: `${API_DOMAIN}/ar/apTransactions/hasUPCInvoiceDates`,
      method: 'GET',
      params: { parentBorrowerId: selectedParentClient.recordId, endDate: formattedDate }
    });
    return response.data as boolean;
  } catch (error) {
    console.log('CHECK UPC INVOICE DATES ERROR: ', error);
    return true;
  }
};

export const checkHasDueDates = async (selectedCollateral: IARCollateral, selectedAsOfDate: IBBPeriod) => {
  try {
    const response = await axiosInstance.request({
      url: `${API_DOMAIN}/ar/apTransactions/hasDueDates`,
      method: 'GET',
      params: { arCollateralId: selectedCollateral.recordId, bbPeriodId: selectedAsOfDate.recordId }
    });
    return response.data as boolean;
  } catch (error) {
    console.log('CHECK DUE DATES ERROR: ', error);
    return true;
  }
};

export const checkHasDueDatesForUPC = async (selectedParentClient: IClient, selectedAsOfDate: IBBPeriod) => {
  try {
    const formattedDate = selectedAsOfDate.endDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
    const response = await axiosInstance.request({
      url: `${API_DOMAIN}/ar/apTransactions/hasUPCDueDates`,
      method: 'GET',
      params: { parentBorrowerId: selectedParentClient.recordId, endDate: formattedDate }
    });
    return response.data as boolean;
  } catch (error) {
    console.log('CHECK UPC DUE DATES ERROR: ', error);
    return true;
  }
};

export const getExchangeRates = async (selectedClient: IClient) => {
  try {
    const rates = (
      await axiosInstance.request({
        url: multiCurrencyAPI.GET_ALL_RATES,
        method: GET,
        params: { borrowerId: selectedClient.recordId, fromCurrencyId: selectedClient.currencyId },
      })
    ).data.content as IRate[];
    return rates;
  } catch (error) {
    console.log('CHECK RATE ERROR: ', error);
    return [];
  }
};

export const calculateAPAging = async (selectedClient: IClient, selectedCollateral: IARCollateral, selectedBBPeriod: IBBPeriod) => {
  await axiosInstance.request({
    url: reportsAPI.apAgingReport.CALCULATE,
    method: POST,
    params: { borrowerId: selectedClient.recordId, arCollateralId: selectedCollateral.recordId, bbPeriodId: selectedBBPeriod.recordId }
  });
};

export const calculateUPCAPAging = async (selectedClient: IClient, selectedBBPeriod: IBBPeriod) => {
  const formattedDate = selectedBBPeriod.endDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
  await axiosInstance.request({
    url: reportsAPI.apAgingReport.CALCULATE_UPC,
    method: POST,
    params: { parentBorrowerId: selectedClient.recordId, endDate: formattedDate }
  });
};

export const getAPAgingBuckets = async (selectedClient: IClient, selectedCollateral: IARCollateral, selectedBBPeriod: IBBPeriod, bucketType: string) => {
  const response = await axiosInstance.request({
    url: reportsAPI.apAgingReport.GET_BUCKETS,
    method: GET,
    params: { borrowerId: selectedClient.recordId, arCollateralId: selectedCollateral.recordId, bbPeriodId: selectedBBPeriod.recordId, bucketType }
  });
  return response.data as string[];
};

export const getUPCAPAgingBuckets = async (selectedClient: IClient, selectedBBPeriod: IBBPeriod, bucketType: string) => {
  const formattedDate = selectedBBPeriod.endDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
  const response = await axiosInstance.request({
    url: reportsAPI.apAgingReport.GET_UPC_BUCKETS,
    method: GET,
    params: { parentBorrowerId: selectedClient.recordId, endDate: formattedDate, bucketType }
  });
  return response.data as string[];
};

export const getAPAgingReport = async (selectedClient: IClient, selectedCollateral: IARCollateral, selectedBBPeriod: IBBPeriod, bucketType: string, sortParams: string) => {
  const response = await axiosInstance.request({
    url: reportsAPI.apAgingReport.MAIN_ENDPOINT,
    method: GET,
    params: { borrowerId: selectedClient.recordId, arCollateralId: selectedCollateral.recordId, bbPeriodId: selectedBBPeriod.recordId, bucketType, sortBy: sortParams }
  });
  return response.data.content as IAPAgingReport[];
};

export const getUPCAPAgingReport = async (selectedClient: IClient, selectedBBPeriod: IBBPeriod, bucketType: string, sortParams: string) => {
  const formattedDate = selectedBBPeriod.endDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
  const response = await axiosInstance.request({
    url: reportsAPI.apAgingReport.MAIN_UPC_ENDPOINT,
    method: GET,
    params: { parentBorrowerId: selectedClient.recordId, endDate: formattedDate, bucketType, sortBy: sortParams }
  });
  return response.data.content as IAPAgingReport[];
};

export const getAPAgingReportVendorDetails = async (selectedClient: IClient, selectedCollateral: IARCollateral, selectedBBPeriod: IBBPeriod, selectedVendorID: number) => {
  const response = await axiosInstance.request({
    url: reportsAPI.apAgingReport.GET_DETAILS,
    method: GET,
    params: {
      borrowerId: selectedClient.recordId, arCollateralId: selectedCollateral.recordId,
      bbPeriodId: selectedBBPeriod.recordId, arVendorId: selectedVendorID,
    }
  });
  return response.data.content as IAPAgingReportVendorDetail[];
};

export const getUPCAPAgingReportVendorDetails = async (selectedClient: IClient, selectedBBPeriod: IBBPeriod, selectedCollateralID: number, selectedVendorID: number) => {
  const formattedDate = selectedBBPeriod.endDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
  const response = await axiosInstance.request({
    url: reportsAPI.apAgingReport.GET_UPC_DETAILS,
    method: GET,
    params: {
      parentBorrowerId: selectedClient.recordId, endDate: formattedDate, arCollateralId: selectedCollateralID, arVendorId: selectedVendorID,
    }
  });
  return response.data.content as IUPCAPAgingReportVendorDetail[];
};