import { Box, Button, CircularProgress, ClickAwayListener, Container, Grid, InputAdornment, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography } from '@mui/material';
import styles from './styles';
import { KeyboardArrowDown, KeyboardArrowUp, Label } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DatePickerInput from '../file-import/stepper/stepper-content/upload-tab/datepicker-input';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyledTableCell, StyledTableRow } from '../../components/clientList/table/tabs/tab-panel-contents/styled-components';
import { getLabelDisplayedRows, getLabelRowsPerPage } from '../../components/client-settings/tabs/tab-panel-contents/pagination';
import axiosInstance from '../../service/axiosInstance';
import { auditTrailAPI } from '../../service/api';
import { AMERICAN_DATE_8HR_FORMAT, GET, POST } from '../../utility/constants';
import { IAuditTrail, IDateRange } from '../../interfaces/auditTrailInterface';
import { SkeletonRow } from '../../components/skeleton';
import _ from 'lodash';
import { formatDate, formatDateToLocal } from '../../utility/helper';
import FilterSearch from '../../components/common/filter-search';
import { useIsFirstRender } from '../../utility/hooks';
import MenuButton from '../../components/common/menu-button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

interface ISorting {
  sortKey:       string;
  sortDirection: string;
}

const AuditTrailPage = () => {
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [selectedDates, setSelectedDates] = useState<IDateRange>({
    startDate: null,
    endDate: null
  });
  const [openDateRange, setOpenDateRange] = useState<boolean>(false);
  const [openStartDate, setOpenStartDate] = useState<boolean>(false);
  const [openEndDate, setOpenEndDate] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [categorySearchTerm, setCategorySearchTerm] = useState<string>('');
  const [operationSearchTerm, setOperationSearchTerm] = useState<string>('');

  const [page, setPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortProps, setSortProps] = useState<ISorting>({sortKey: 'timestamp', sortDirection: 'DESC'})

  const [auditTrailList, setAuditTrailList] = useState<IAuditTrail[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const isFirstRender = useIsFirstRender();

  /**
   * This useEffect hook calls the getAuditTrailRecords upon page load.
   */
  useEffect(() => {
    if (isFirstRender) {
      getAuditTrailRecords();
    }
  }, []);

  /**
   * This useEffect hook calls the getAuditTrailRecords on change.
   */
  useEffect(() => {
    if (!isFirstRender) {
      getAuditTrailRecords();
    }
  }, [page, rowsPerPage, sortProps, searchTerm, operationSearchTerm, categorySearchTerm]);

  useEffect(() => {
    if (((selectedDates.startDate && selectedDates.endDate) || (!selectedDates.startDate && !selectedDates.endDate)) && !isFirstRender) {
      getAuditTrailRecords();
    }
  }, [selectedDates.startDate, selectedDates.endDate]);

  /**
   * Callback function to handle a page change event.
   */
  const handleChangePage = useCallback((_event: unknown, newPage: number) => setPage(newPage), []);

  /**
   * A callback function for handling a change in the number of rows per page in a paginated data view.
   */
  const handleChangeRowsPerPage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  /**
   * This debounce is used to ensure that the onChange props is called when the new searchTerm is set.
   */
  const triggerOnChange = useMemo(() => _.debounce((searchTerm: string) => {
    onChange(searchTerm);
  }, 700), []);

  const onChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setPage(0);
  }

  /**
   * This function sets the new state for the search value.
   * @param e The event generated when user inputs something in the search field.
   */
  const searchOnChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.target.value);
    triggerOnChange(e.target.value);
  };

  /**
   * This function is used for retrieving paged Audit Trail records.
   */
  const getAuditTrailRecords = async () => {
    let requestParams;
    requestParams = { 
      pageNo: page, 
      pageSize: rowsPerPage, 
      searchTerm, 
      operationSearchTerm, 
      categorySearchTerm,
      sortBy: `${sortProps.sortKey},${sortProps.sortDirection}`
    };
    if (selectedDates.startDate && selectedDates.endDate) {
      requestParams = {
        ...requestParams,
        startDate: formatDate(selectedDates.startDate.toISOString(), 'YYYY-MM-DD'),
        endDate: formatDate(selectedDates.endDate.toISOString(), 'YYYY-MM-DD')
      };
    }
    try {
      setIsLoading(true);
      const response = await axiosInstance.request({
        url: auditTrailAPI.GET_BY_DATE_AND_SEARCH_TERM,
        method: GET,
        params: requestParams
      })
      setAuditTrailList(response.data.content);
      setTotalElements(response.data.totalElements);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const setDatePickerOpen = (type: string, value: boolean) => {
    if (type === 'startDate') {
      setOpenStartDate(value);
    } else {
      setOpenEndDate(value);     
    }
  }

  const selectDate = (type: string, value: Date[]) => {
    if (type === 'startDate') {
      if (!selectedDates.startDate && value.length === 0) return;
      setSelectedDates((prev) => {return {...prev, startDate: value[0]}});
      setOpenStartDate(false);
    } else {
      if (!selectedDates.endDate && value.length === 0) return;
      setSelectedDates((prev) => {return {...prev, endDate: value[0]}});
      setOpenEndDate(false);
    }
  };

  /**
   * This function determine if the sort direction will display desc or asc
   * 
   * @param title The field that being sorted.
   * @returns An IconComponent props.
   */
  const getIconComponent = (title: string) => {
    if (sortProps.sortKey === title) {
      return;
    } else {
      return { IconComponent: handleIcon };
    }
  };

  /**
   * This function return either default sort icon or an arrow icon following the sort direction of the field that is being sorted.
   */
  const handleIcon = useCallback(() => <UnfoldMoreIcon sx={styles.iconDefaultSort}/>, []);

  /**
   * This function handle changes on the sorting functionality of the exchange rate table.
   * 
   * @param title The field that being sorted.
   */
  const handleSort = (title: string) => {
    if(sortProps.sortKey === title){
      const newSortDirection = sortProps.sortDirection === 'DESC' ? 'ASC' : 'DESC';
      setSortProps({...sortProps, sortDirection: newSortDirection});
    }else{
      setSortProps({sortKey: title, sortDirection: 'ASC'})
    }
    setPage(0);
  };

  const getLoadingContent = () => (
      <TableBody>
        <SkeletonRow numColumns={6} />
        <SkeletonRow numColumns={6}/>
        <SkeletonRow numColumns={6}/>
        <SkeletonRow numColumns={6}/>
        <SkeletonRow numColumns={6}/>
      </TableBody>
  );

  const handleSelect = async (filetype) => {
    if (!selectedDates.startDate || !selectedDates.endDate) return;
    try {
      const requestParams = { 
        pageNo: page, 
        searchTerm, 
        operationSearchTerm, 
        categorySearchTerm,
        sortBy: `${sortProps.sortKey},${sortProps.sortDirection}`,
        startDate: formatDate(selectedDates.startDate.toISOString(), 'YYYY-MM-DD'),
        endDate: formatDate(selectedDates.endDate.toISOString(), 'YYYY-MM-DD')
      };
      setIsExporting(true);
      const exportResponse = await axiosInstance.request({
        url: auditTrailAPI.EXPORT_TO_EXCEL,
        method: POST,
        responseType: 'blob',
        params: requestParams,
        headers: { 'X-Timezone' : Intl.DateTimeFormat().resolvedOptions().timeZone }
      })
      let exportData = exportResponse.data;
      const url = window.URL.createObjectURL(new Blob([exportData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `audit-trail-${formatDate(selectedDates.startDate.toISOString() ?? '', 'MM/DD/YYYY')}-${formatDate(selectedDates.endDate.toISOString() ?? '', 'MM/DD/YYYY')}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    }
    catch (error) {
      console.log('EXPORT ERROR : ', error);
    } finally {
      setIsExporting(false);
    }
  };

  const getExportButton = () => {
    return (
      <MenuButton
        label="Export"
        options={[
          {label: 'Excel', handleSelect: () => handleSelect('Excel')}
        ]}
        buttonProps={{
          endIcon: isExporting ? <CircularProgress size={15} /> : <FileDownloadOutlinedIcon />,
          size: 'medium',
          variant: 'outlined',
          disabled: isLoading || isExporting || auditTrailList.length < 1 || (!selectedDates.startDate || !selectedDates.endDate),
          'aria-label': 'Export icon',
          sx: styles.headerButtons
        }}
      />
    );
  };

  return (
    <Box>
      <Box sx={{...styles.boxContainer1}}>
				<Typography tabIndex={0} variant='h6' component='h3' sx={styles.title}>
          Audit Trail
				</Typography>
			</Box>
      <Container maxWidth='xl'>
        <Grid container sx={styles.filterGridContainer}>
          <Grid item xs={10} md={2}>
            <Box>
              <Button 
                aria-label={'date-range-button'}
                sx={styles.dateRangeBtn} 
                ref={anchorRef} 
                onClick={() => setOpenDateRange((prev) => !prev)}
                endIcon={openDateRange ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              >
                Select Date Range
              </Button>
              <Popper
                aria-label='date-range-popper'
                open={openDateRange}
                anchorEl={anchorRef.current}
                placement="bottom-start"
              >
                <ClickAwayListener 
                  onClickAway={() => setOpenDateRange(false)}
                >
                  <Paper>
                    <Box padding={1.5} aria-label='date-from-container'>
                      <Typography fontSize={14} display={'block'} paddingBottom={1}>
                          From
                      </Typography>
                      <DatePickerInput
                        width='12rem'
                        popperOffset={[-10, 20]}
                        defaultValue={selectedDates?.startDate ? [selectedDates?.startDate]: []}
                        onSelectDate={(value) => selectDate('startDate', value)}
                        limitTags={1}
                        isNotCalculatedDate={() => false}
                        isNotCalculatedYear={() => false}
                        asOfDateCreator={true}
                        isOpen={openStartDate}
                        setIsOpen={(value) => setDatePickerOpen('startDate', value)}
                      />
                    </Box>
                    <Box padding={1.5} aria-label='date-to-container'>
                      <Typography fontSize={14} display={'block'} paddingBottom={1}>
                          To
                      </Typography>
                      <DatePickerInput
                        width='12rem'
                        popperOffset={[-10, 20]}
                        defaultValue={selectedDates?.endDate ? [selectedDates?.endDate]: []}
                        onSelectDate={(value) => selectDate('endDate', value)}
                        limitTags={1}
                        isNotCalculatedDate={() => false}
                        isNotCalculatedYear={() => false}
                        asOfDateCreator={true}
                        isOpen={openEndDate}
                        setIsOpen={(value) => setDatePickerOpen('endDate', value)}
                      />
                    </Box>
                  </Paper>

                </ClickAwayListener>
              </Popper>
            </Box> 
          </Grid>
          <Grid item xs={10} md={3} sx={styles.searchStyle}>
            <TextField 
              id={`input-with-id`}
              inputProps={{'data-testid':`search-field`}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' tabIndex={0} aria-label='Search icon'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              aria-label='Search Audit Trail'
              onChange={searchOnChange}
              value={searchValue}
              placeholder='Search'
              size='small'        
              sx={styles.searchField}
            />
          </Grid>
          <Grid item xs={10} md={7}>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip title={(!selectedDates.startDate || !selectedDates.endDate) ? "Please select a date range first" : ""}>
                <Box>{ getExportButton() }</Box>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        <Box sx={styles.outmostContainer}>
          <TableContainer sx={styles.tableContainer}>            
            <Table sx={styles.table}>
              <TableHead>
                <TableRow sx={styles.tableHeadRow}>
                  <TableCell sx={{ ...styles.tableHeadCell }}>
                    <TableSortLabel
                      aria-label={'timestamp-sort'}
                      active={sortProps.sortKey === 'timestamp'}
                      direction={sortProps.sortDirection === 'DESC' ? 'desc' : 'asc'}
                      onClick={() => handleSort('timestamp')}
                      {...getIconComponent('timestamp')}
                    >
                        Timestamp
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ ...styles.tableHeadCell }}>
                    <TableSortLabel
                      aria-label={'user-sort'}
                      active={sortProps.sortKey === 'user'}
                      direction={sortProps.sortDirection === 'DESC' ? 'desc' : 'asc'}
                      {...getIconComponent('user')}
                    >
                        User
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ ...styles.tableHeadCell }}>
                    Event
                    <FilterSearch
                      id={'category'}
                      defaultOrder='asc'
                      setOrder={(order) => {
                        setSortProps({sortKey: 'category', sortDirection: order.toString()})
                        setPage(0);
                      }}
                      setOrderBy={() => null}
                      onSearch={setCategorySearchTerm}
                    />
                  </TableCell>
                  <TableCell sx={{ ...styles.tableHeadCell }}>
                    Action
                    <FilterSearch
                      id={'operation'}
                      defaultOrder='asc'
                      setOrder={(order) => {
                        setSortProps({sortKey: 'operation', sortDirection: order.toString()})
                        setPage(0);
                      }}
                      setOrderBy={() => null}
                      onSearch={setOperationSearchTerm}
                    />
                  </TableCell>
                  <TableCell sx={{ ...styles.tableHeadCell }}>
                      Previous Value
                  </TableCell>
                  <TableCell sx={{ ...styles.tableHeadCell }}>
                      Updated Value
                  </TableCell>
                </TableRow>
              </TableHead>
              {
                isLoading 
                    ? getLoadingContent() 
                    : 
                      <TableBody>
                      { 
                        auditTrailList.map(record => (
                            <StyledTableRow>
                              <StyledTableCell tabIndex={0} key={record.logId}>
                                {formatDateToLocal(record?.timestamp, AMERICAN_DATE_8HR_FORMAT)}
                              </StyledTableCell>
                              <StyledTableCell tabIndex={0}>
                                admin@loanwatch.io {/* replace with dynamic user {record?.createdBy} */}
                              </StyledTableCell>
                              <StyledTableCell tabIndex={0}>
                                {record?.category}
                              </StyledTableCell>
                              <StyledTableCell tabIndex={0}>
                                {record?.operation}:<b>{record?.field}</b>
                              </StyledTableCell>
                              <StyledTableCell tabIndex={0}>
                                {record?.oldValue}
                              </StyledTableCell>
                              <StyledTableCell tabIndex={0}>
                                {record?.newValue}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                      }
                      </TableBody>
              }
            </Table>
          </TableContainer>
        </Box>
        <Box sx={styles.tablePaginationContainer}>
          <TablePagination
            component='div'
            count={totalElements}
            rowsPerPageOptions={[5, 10, 25, 100]}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={getLabelDisplayedRows()}
            labelRowsPerPage={getLabelRowsPerPage()}
          />
        </Box>
      </Container>
    </Box>
  )
}

export default AuditTrailPage;