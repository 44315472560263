import colors from "../../../utility/colors";
import BorrowingBaseReportIcon from '../../../assets/images/BorrowingBaseReportIcon.svg';

const styles = {
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '25rem',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#fff',
    borderRadius: '0.3rem',
    boxShadow: '0 0 24px -8px rgba(0,0,0,0.75)',
    padding: '1.5rem',
  },
  modalContainerExpanded: {
    position: 'absolute',
    top: '50%',
    left: '40%',
    width: '60vw',
    transform: 'translate(-35%, -50%)',
    bgcolor: '#fff',
    borderRadius: '0.3rem',
    boxShadow: '0 0 24px -8px rgba(0,0,0,0.75)',
    padding: '1.5rem',
  },
  card: {
    height: '4rem',
    display: 'flex',
    placeContent: 'center'
  },
  cardButton: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    textAlign: 'left',
    overflow: 'hidden',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#154A8A',
    height: '100%',
    paddingLeft: '5.2rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '3.5% 50%',
    backgroundSize: '4rem',
    backgroundImage: `url(${BorrowingBaseReportIcon})`,
  },
  periodContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px'
  },
  periodInput: { 
    '.MuiInputBase-input': {
      padding: '8px 14px',
      caretColor: 'transparent'
    },
    svg: {
      color: colors.PRIMARY
    }
  },
  groupHeaderTitle: {
    fontWeight: 600
  },
  collateralContainer: {
    maxHeight: '50vh',
    overflowY: 'auto'
  },
  disabledCheckbox: {
    '.Mui-disabled': {
      cursor: 'not-allowed'
    }
  },
  headerText: {
    fontSize: '16px', 
    fontWeight: 'bold'
  },
  bodyText: {
    fontSize: '14px'
  }
}

export default styles;