import { Box, Typography, CircularProgress, Button, useTheme, useMediaQuery, FormControlLabel, Checkbox, AlertColor } from '@mui/material';
import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { IExportFileType } from '../../../../interfaces';
import { API_DOMAIN, ARIA_LABELS, NO_PERMISSION_MSG, PERMISSIONS, PROMPT } from '../../../../utility/constants';
import { checkFileNameIfExisting, checkUserPermissions, exportReport, getFileName } from '../../../../utility/helper';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MenuButton from '../../../../components/common/menu-button';
import styles from './styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IReportView, ISortingProps } from '..';
import { IOption } from '../../../../interfaces/comboBox';
import ConfirmModal from '../../../../components/modals/confirm-modal';
import { AuthContext } from '../../../../context/authContext';
import Toaster from '../../../../components/toaster';
import { IRate } from '../../../../interfaces/multiCurrencyInterface';
import { ExportReportContext } from '../../../../context/exportReportContext';
import { IExportReportWithPayload } from '../../../../interfaces/exportReportInterface';

export interface IHeaderProps extends ISortingProps {
  setExporting          : Dispatch<SetStateAction<boolean>>,
  isLoading             : boolean,
  exportDisabled        : boolean,
  selectedClient        : IOption | null,
  selectedBBPeriod      : IOption | null,
  selectedARCollateral  : IOption | null,
  viewBy                : IReportView,
  currencyId            : number
  rateId?               : number,
  isUltimateParent?     : boolean,
  allExchangeRates?     : IRate[],
  rfvDisabled?          : boolean,
  isChildCustomerOnly?  : boolean,
}

/**
 * This function generates the URL needed to export a report.
 *
 * @param exportAs The file type to export as.
 * @returns URL for exporting
 */
const getExportURL = (exportAs: IExportFileType, isUltimateParent: boolean) => {
  if (isUltimateParent) {
    return `${API_DOMAIN}/ar/ineligibleReports/upc/export${exportAs}`;
  } else {
    return `${API_DOMAIN}/ar/ineligibleReports/export${exportAs}`;
  }
}

/**
 * Component for showing the header for the AR Ineligible report.
 * @param props The props for the ReportHeader.
 * @returns The rendered ReportHeader component.
 */
const ReportHeader: FC<IHeaderProps> = (props) => {
  const [searchParams]                        = useSearchParams();
  const fromSettings                          = searchParams.get('fromSettings');
  const borrowerId                            = searchParams.get('clientId') ?? '0';
  const navigate                              = useNavigate();
  const theme                                 = useTheme();
  const belowMediumBreakpoint                 = useMediaQuery(theme.breakpoints.down('md'));
  const {state}                               = useContext(AuthContext)
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [fileType, setFileType]               = useState<IExportFileType>('Excel');
  const [includeSettings, setIncludeSettings] = useState<boolean>(false);
  const [canExport, setCanExport]             = useState<boolean>(false);
  const [isToasterOpen, setIsToasterOpen]     = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]   = useState<string>('');
  const [toasterSeverity, setToasterSeverity] = useState<AlertColor>('success');
  const { setShow,
          exports,
          setExports }                        = useContext(ExportReportContext);

  /**
   * This useEffect hook gets the user's permissions on component mount.
   */
  useEffect(() => {
    getPermission()
  }, [])

  /**
   * This function checks if the user has the necessary permissions to export the report.
   */
  const getPermission = async () => {
    const isPermitted = await checkUserPermissions(state.uid, PERMISSIONS.EXPORT_INELIGIBLE_REPORT)
    if(isPermitted){
      setCanExport(isPermitted)
    }
  }

  /**
   * This function checks the user's permissions before executing another function.
   * 
   * @param func The function to execute if the user has permissions.
   * @param args Arguments for the `func`.
   */
  const checkPermission = (func: Function, ...args: any[]) => {
    (async () => {
      try {
        const isPermitted = await checkUserPermissions(state.uid, PERMISSIONS.EXPORT_INELIGIBLE_REPORT)
        if (isPermitted) {
          func(...args)
        } else {
          setToasterMessage(NO_PERMISSION_MSG);
          setToasterSeverity('error');
          setIsToasterOpen(true);
        }
      } catch (error) {
        console.log('CHECK PERMISSION EXPORT: ', error);
      }
    })();
  }

  /**
   * This callback manages navigation based on certain conditions.
   */
  const handleNavigate = useCallback(() => {
    if (fromSettings) navigate(`/clients/${borrowerId}/settings`);
    else navigate('/reports');
  }, [fromSettings, borrowerId]);

  /**
   * This function manages the selection of file types for exporting.
   * @param fileType The chosen file type.
   */
  const handleSelect = (fileType: IExportFileType) => {
    setFileType(fileType);
    setOpenExportModal(true);
  };

  /**
   * This function handles the change in checkbox state.
   */
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIncludeSettings(event.target.checked);
  };

  /**
   * This function compiles the payload data for the export request.
   */
  const getPayloadData = (filename: string) => {
    return {
      borrowerId: props.selectedClient?.recordId,
      arCollateralId: props.selectedARCollateral?.recordId,
      bbPeriodId: props.selectedBBPeriod?.recordId,
      order: props.viewBy === 'Ineligible' ? props.order : props.custViewOrder,
      orderBy: props.viewBy === 'Ineligible' ? props.orderBy : props.custViewOrderBy,
      viewBy: props.viewBy,
      includeSettings,
      currencyId: props.currencyId,
      rateId: props.rateId,
      endDate: `${props.isUltimateParent ? props.selectedBBPeriod?.recordId : props.selectedBBPeriod?.endDate}`,
      exchangeRates: props.allExchangeRates,
      filename,
      rfvDisabled: props.rfvDisabled,
      isChildCustomerOnly: props.isChildCustomerOnly,
    }
  };

  /**
   * This function handles the export confirmation action, triggering the report export.
   */
  const handleConfirm = (fileType: IExportFileType) => {
    const reportName = `ARIneligible - ${props.viewBy}`;

    const fileName: string = getFileName(
      fileType,
      reportName,
      props.selectedBBPeriod?.label,
      props.selectedClient?.label,
      props.selectedARCollateral?.label
    );

    if (props.isUltimateParent) {
      const fileNameToExport: string = checkFileNameIfExisting(exports, fileName, reportName);

      const additionalPayload = {
        setExporting: props.setExporting,
        apiURL: getExportURL(fileType, Boolean(props.isUltimateParent)),
        payloadData: getPayloadData(fileNameToExport)
      }
      updateExports(fileNameToExport, fileName, additionalPayload);
    } else {
      exportReport(
        props.setExporting,
        getExportURL(fileType, Boolean(props.isUltimateParent)),
        getPayloadData(fileName),
        fileName
      )
    }
  };

  const updateExports = (filename: string, originalFileName: string, additionalPayload: any) => {
    const fileToExport: IExportReportWithPayload = {
      filename,
      status: 'loading',
      originalFileName,
      ...additionalPayload,
    }
    setExports([...exports, fileToExport]);
    setShow(true);
  }

  /**
   * This function gets the options for the Export menu.
   * @returns An array of options for the Export menu.
   */
  const getOptions = () => {
    const initial = [
      {label: 'Excel', handleSelect: () => checkPermission(handleSelect, 'Excel')},
    ];

    if (!props.isUltimateParent) {
      initial.push({label: 'PDF', handleSelect: () => checkPermission(handleSelect,'PDF')}); 
    }

    return initial;
  }

  return (
    <>
      <Box sx={{...styles.titleContainer, ...styles.headerContainer}}>
        <Typography tabIndex={0} variant='h6' component='h3' sx={styles.title} data-testid='report-header'>AR Ineligible</Typography>
        <Box sx={styles.headerActionWrapper}>
          <Button
            size='medium'
            variant='outlined'
            aria-label='Customize the Report'
            sx={{...styles.headerButtons, visibility: 'hidden'}}
          >
            Customize Report
          </Button>
          {canExport &&
          <MenuButton 
            label='Export'
            options={getOptions()}
            buttonProps={{
              endIcon: props.isLoading ? <CircularProgress size={15} /> : <FileDownloadOutlinedIcon />,
              size: 'medium',
              variant: 'outlined',
              disabled: props.isLoading || props.exportDisabled,
              'aria-label': 'Export icon',
              sx: styles.headerButtons,
            }}
          />
          }
          <Button
            size='medium'
            variant='outlined'
            aria-label='Go back icon'
            data-testid='go-back-button'
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={handleNavigate}
            sx={styles.headerButtons}
          >
            {belowMediumBreakpoint ? null : 'Go back'}
          </Button>
        </Box>
        <ConfirmModal
          open={openExportModal}
          onClose={() => setOpenExportModal(false)}
          onConfirm={() => handleConfirm(fileType)}
          title={`${PROMPT.EXPORT_PROMPT.title} ${fileType}`}
          description={PROMPT.EXPORT_PROMPT.description}
          children={
            <Box sx={styles.childrenContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    defaultChecked
                    checked={includeSettings}
                    onChange={handleCheckboxChange}
                    inputProps={{ 'aria-label': ARIA_LABELS.CHECKBOX.INCLUDE_SETTINGS }}
                  />
                }
                label={<Typography>Include Settings</Typography>}
              />
            </Box>
          }
        />
      </Box>
      <Toaster
        open={isToasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setIsToasterOpen(false)}
      />
    </>
  );
};

export default ReportHeader;