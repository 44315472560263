import * as Yup from 'yup';
import { IInventoryInput } from '../interfaces';
import { NON_EXISTING_PERCENT } from '../utility/constants';

const inventorySettingsSchema = Yup.object({
  inventoryInput: Yup.object({
    invCollateralName: Yup.string().required('Inventory Collateral Name is required').trim()
                          .test({
                            name: 'invCollateralName', message: 'Collateral Name must be unique',
                            test() {
                              const { from } = this as Yup.TestContext & { from: { value: IInventoryInput | { existingInvCollateralNames: string[] } }[]; };
                              const { value: inventoryInput } = from[0] as { value: IInventoryInput };
                              
                                const { value: { existingInvCollateralNames } } = from[1] as { value: { existingInvCollateralNames: string[] } };
                              return !existingInvCollateralNames.includes(inventoryInput.invCollateralName);
                            }
                          }),
    advanceRate: Yup.number()
      .notOneOf([NON_EXISTING_PERCENT], 'Advance Rate is required')
      .required('Advance Rate is required'),
  })
});

export default inventorySettingsSchema;
