import { useContext, useState } from 'react';
import { TableCell, Radio, SxProps, Tooltip, IconButton, TableRow, Theme } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HistoryIcon from '@mui/icons-material/History';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IAccountsReceivableSetting } from '..';
import { NON_EXISTING_PERCENT } from '../../../../../../utility/constants';
import ConfirmModal from '../../../../../modals/confirm-modal';
import AccountReceivableSettingModal from '../modal';
import styles from '../styles';
import { ClientSettingsContext, IClientSettingsContext } from '../../../../../../context/clientSettingsContext';
import { ICurrency } from '../../../../../../interfaces/multiCurrencyInterface';
import { SelectedClientContext } from '../../../../../../context/selectedClientContext';

interface IAccountsReceivableSettingRowProps {
  arSetting: IAccountsReceivableSetting;
  existingArCollateralNames: string[];
  isoList: ICurrency[]
  onARSettingChangeDefault: (arSetting: IAccountsReceivableSetting) => void;
  onARSettingDelete: (arSetting: IAccountsReceivableSetting) => void;
  onARSettingArchive: (arSetting: IAccountsReceivableSetting) => void;
  onARSettingRestore: (arSetting: IAccountsReceivableSetting) => void;
  onARSettingSave: (arSetting: IAccountsReceivableSetting) => void;
}

/**
 * Render the row component for AR Collateral Table
 */
const AccountsReceivableSettingRow: React.FC<IAccountsReceivableSettingRowProps> = (props) => {
  const { selectedClient, clients }                      = useContext(SelectedClientContext);
  const { canArchiveDeleteAr, canChangeDefaultAr }      = useContext(ClientSettingsContext) as IClientSettingsContext;
  const [isARSettingModalOpen, setIsARSettingModalOpen] = useState(false);
  const [actionConfirmModal, setActionConfirmModal]     = useState({ isOpen: false, title: '', description: '', yesButtonText: '', isDeleting: false });

  /**
   * This function sets the props for setActionConfirmModal variable for archiving an AR Collateral.
   */
  const handleArchive = () => {
    setActionConfirmModal({
      isOpen: true, title: 'Archive',
      description: 'You are about to archive this item. Are you sure?',
      yesButtonText: 'Archive', isDeleting: false
    });
  };

  /**
   * This function sets the props for setActionConfirmModal variable for deleting an AR Collateral.
   */
  const handleDelete = () => {
    setActionConfirmModal({
      isOpen: true, title: 'Delete',
      description: 'You are about to delete this item. Are you sure?',
      yesButtonText: 'Delete', isDeleting: true
    });
  };

   /**
   * This function sets the props for setActionConfirmModal variable for restoring an AR Collateral.
   */
  const handleRestore = () => {
    setActionConfirmModal({
      isOpen: true, title: 'Restore',
      description: 'You are about to restore this item. Are you sure?',
      yesButtonText: 'Restore', isDeleting: false
    });
  };

  /**
   * This function converts a number into a formatted string.
   * @param numberToConvert a number field from AR Collateral data.
   * @param type determine if the number should be converted as a currency or percentage.
   * @returns a formatted string.
   */
  const renderTableCellTextContent = (numberToConvert: number | undefined, type: 'currency' | 'percentage') => {
    if (numberToConvert === undefined || numberToConvert === NON_EXISTING_PERCENT) { return undefined; }
    if (type === 'currency') {
      const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
      return formatter.format(numberToConvert);
    }
    return `${(numberToConvert).toFixed(2)}%`;
  };

  /**
   * This function triggers the function property given for this component to handle either deletion, archiving or restoring an AR Collateral
   */
  const handleActionModalConfirm  = () => {
    switch (actionConfirmModal.title) {
      case 'Delete': props.onARSettingDelete(props.arSetting); break;
      case 'Archive': props.onARSettingArchive(props.arSetting); break;
      case 'Restore': props.onARSettingRestore(props.arSetting); break;
    }
  };

  /**
   * This function format the currency field from AR Collateral data
   * @returns a formatted string or the currency of the AR Collateral
   */
  const getCurrencyDisplay = (): string => {
    const parsed   = parseInt(props.arSetting.arBorrowerInput.currency)
    if(parsed){
      const currency = props.isoList.find(currency => currency.recordId === parsed)
      return `${currency?.currencyCode} - ${currency?.currencyName}`
    }
    return props.arSetting.arBorrowerInput.currency
  }

  /**
   * This function render a cell that contains a radio button
   * @returns a JSX.Element or Null
   */
  const renderTableCellForDefaultRadioButton = () => {
    if (props.arSetting.arCollateral.archive) { return null; }
    return (
      <TableCell
        component='th'
        scope='row'
        sx={{ ...styles.tableCell, ...styles.centerAlignedText }}
      >
        <Radio
          checked={props.arSetting.arCollateral.default}
          onChange={() => props.onARSettingChangeDefault(props.arSetting)}
          value={props.arSetting.arCollateral.recordId}
          name={`is-ar-record-id-${props.arSetting.arCollateral.recordId}-default`}
          inputProps={{ 'aria-label': 'Set Accounts Receivable as Default' }}
          size='small'
          disabled={!canChangeDefaultAr}
        />
      </TableCell>
    );
  };

  /**
   * This function render a cell that contains data from AR Collateral
   * @returns a JSX.Element
   */
  const renderTableCell = (innerContent: any, tableCellStyle?: SxProps<Theme>) => {
    const onClickHandler = () => setIsARSettingModalOpen(true);
    if (!innerContent) {
      return (
        <TableCell
          className='emptyCell'
          tabIndex={0} 
          component='th'
          onClick={onClickHandler}
          scope='row'
          sx={{ ...styles.tableCell, ...(tableCellStyle !== undefined && tableCellStyle) }}
        >
          -
        </TableCell>
      );
    }
    return (
      <TableCell
        tabIndex={0} 
        component='th'
        onClick={onClickHandler}
        scope='row'
        sx={{ ...styles.tableCell, ...(tableCellStyle !== undefined && tableCellStyle) }}
      >
        { innerContent }
      </TableCell>
    );
  };

  /**
   * This function renders a JSX.Element containing buttons for specific tab Active/Archive 
   * @returns a JSX.Element
   */
  const getActionButtons = () => {
    const isARSettingActive = !props.arSetting.arCollateral.archive;
    const isARSettingDeletable = props.arSetting.arCollateral.canDelete === 1;
    const ViewCollateralActionButton = (
      <Tooltip title='View the collateral'>
        <IconButton
          aria-label={'View icon'}
          onClick={() => setIsARSettingModalOpen(true)}
          sx={styles.viewActionIconButton}
        >
          <PreviewIcon sx={styles.actionIcon} />
        </IconButton>
      </Tooltip>
    );
    if (canArchiveDeleteAr) {
      if (isARSettingActive && isARSettingDeletable) {
        return (
          <TableCell  sx={{ ...styles.tableCell, ...styles.centerAlignedText, }}>
            { ViewCollateralActionButton }
            <Tooltip title='Delete the collateral'>
              <IconButton
                disabled={selectedClient?.parentClient === true}
                aria-label='Delete icon'
                onClick={handleDelete}
                sx={styles.actionIconButton}
              >
                <DeleteOutlineOutlinedIcon sx={{...styles.actionIcon, ...(selectedClient?.parentClient === true && styles.disabledIcon)}} />
              </IconButton>
            </Tooltip>
          </TableCell>
        );
      }
      if (isARSettingActive) {
        return (
          <TableCell  sx={{ ...styles.tableCell, ...styles.centerAlignedText, }}>
            { ViewCollateralActionButton }
            <Tooltip title='Archive the collateral'>
              <IconButton
                disabled={selectedClient?.parentClient === true}
                aria-label='Archive icon'
                onClick={handleArchive}
                sx={styles.actionIconButton}
              >
                <Inventory2OutlinedIcon sx={{...styles.actionIcon, ...(selectedClient?.parentClient === true && styles.disabledIcon)}} />
              </IconButton>
            </Tooltip>
          </TableCell>
        );
      }
      return (
        <TableCell  sx={{ ...styles.tableCell, ...styles.centerAlignedText, }}>
          <Tooltip title='Restore the collateral'>
            <IconButton
              disabled={selectedClient?.parentClient === true}
              aria-label='Restore icon'
              onClick={handleRestore}
              sx={styles.actionIconButton}
            >
              <HistoryIcon sx={{...styles.actionIcon, ...(selectedClient?.parentClient === true && styles.disabledIcon)}} />
            </IconButton>
          </Tooltip>
        </TableCell>
      );
    } else if (isARSettingActive) {
      return (
        <TableCell  sx={{ ...styles.tableCell, ...styles.centerAlignedText, }}>
          { ViewCollateralActionButton }
        </TableCell>
      )
    } else {
      return (
        <TableCell  sx={{ ...styles.tableCell, ...styles.centerAlignedText, }} />
      )
    }
  };

  return (
    <>
      <TableRow key={`row-${props.arSetting.arCollateral.arCollateralName}`} sx={styles.tableRow}>
        { selectedClient?.parentClient !== true && renderTableCellForDefaultRadioButton() }
        { renderTableCell(props.arSetting.arBorrowerInput.arCollateralName) }
        { selectedClient?.parentClient === true && renderTableCell(clients.find(client => client.recordId === props.arSetting.arCollateral.borrowerId)?.borrowerName) }
        { renderTableCell(props.arSetting.arCollateral.collateralId) }
        { renderTableCell(renderTableCellTextContent(props.arSetting.arBorrowerInput.rateOfAdvance, 'percentage'), styles.rightAlignedText) }
        { renderTableCell(
          renderTableCellTextContent(
            props.arSetting.arBorrowerInput.arSubLimit,
            props.arSetting.arBorrowerInput.amountLimitType === 'Percentage' ? 'percentage' : 'currency'
          ),
          styles.rightAlignedText
        ) }
        { renderTableCell(props.arSetting.arBorrowerInput.countryName) }
        { renderTableCell(getCurrencyDisplay())}
        { getActionButtons() }
      </TableRow>
      <AccountReceivableSettingModal
        key={`modal-${props.arSetting.arBorrowerInput.arCollateralName}`}
        arSetting={props.arSetting}
        isModalOpen={isARSettingModalOpen}
        isEditable={props.arSetting.arCollateral.archive ? 'not editable' : 'editable'}
        existingArCollateralNames={props.existingArCollateralNames}
        onCloseModal={() => setIsARSettingModalOpen(false)}
        onARSettingDelete={handleDelete}
        onARSettingArchive={handleArchive}
        onARSettingRestore={handleRestore}
        onARSettingSave={(arSetting) => props.onARSettingSave(arSetting)}
        isoList={props.isoList}
      />
      <ConfirmModal
        open={actionConfirmModal.isOpen}
        onClose={() => setActionConfirmModal({ ...actionConfirmModal, isOpen: false })}
        onConfirm={() => handleActionModalConfirm()}
        onButtonClose={() => setActionConfirmModal({ ...actionConfirmModal, isOpen: false })}
        alignment='left'
        title={actionConfirmModal.title}
        description={actionConfirmModal.description}
        yesButtonText={actionConfirmModal.yesButtonText}
        noButtonText='Cancel'
        errorButton= {actionConfirmModal.isDeleting}
       />
    </>
  );
};

export default AccountsReceivableSettingRow;
