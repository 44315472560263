import colors from "../../../../../../../../utility/colors";

const styles = {
	amountTableCell: {
    paddingRight: '42px',
    width: '20%',
  },
  arrow: {
    fontSize: '0.8rem',
    marginTop: '0.6rem'
  },
  arrowUp: {
    transform: 'rotate(180deg)',
  },
  arrowCell: {
    width: '1%',
    padding: 0,
  },
	custIdTableCell: {
    width: '14%',
    paddingLeft: 0
  },
  custNameTableCell: {
    width: '29%',
  },
  custNameContainer: {
    width: '16rem'
  },
  custNameWithParent: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  detailsDateContainer: {
    display: 'flex',
    width: '100%',
    gap: '0.1rem'
  },
  detailsTableDates: {
    width: '25%',
    padding: 0,
    border: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  },
  detailsDueDays: {
    paddingLeft: '0.5rem'
  },
  detailsTableBody: {
    border: 0,
    paddingY: '0.1rem',
  },
  leftColSpacer: {
    width: '10%',
    border: 'none',
    padding: 0,
  },
  bottomSpacer: {
    width: '100%',
    padding: 0,
    margin: 0
  },
  parentIdentifierChip: {
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px'
  },
  rightColSpacer: {
    width: '26%',
    border: 'none',
    padding: 0,
  },
  tableCell: {
    fontSize: '0.875rem',
    paddingTop: '0.1rem',
    paddingBottom: '0.1rem',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  collapseCell: {
    padding: 0
  },
  collapseClosed: {
    border: 'none'
  }
}
export default styles;