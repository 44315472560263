const styles = {
  tableSize: {
    minWidth: 500,
  },
  expandingCellOpen: {
    paddingTop: 0,
    paddingBottom: 0,
    border: 0,
  },
  boxHeight: {
    height: '1rem',
  },
  actionCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  customizeColumnsIcon: {
    color: '#0075AD',
    marginLeft: '20px',
    p: 0
  },
  kebab: {
    color: '#154A8A',
    transform: 'rotate(90deg)'
  },
  menuItemText: {
    fontSize: '14px'
  },
  rightAlign: {
    textAlign: 'left'
  },
  previousPageIcon: {
    '&:not(.Mui-disabled)': {
      color: '#154A8A', 
    },
  },
  nextPageIcon: {
    '&:not(.Mui-disabled)': {
      color: '#154A8A', 
    },
  }
};

export default styles;
