import { FC, useContext, useEffect } from 'react';
import NavigationWarningProvider from '../../context/navigationWarningContext';
import InventoryWorksheetBreadcrumbs from '../../components/inventory-worksheet/breadcrumbs';
import InventoryWorksheetHeaderTitle from '../../components/inventory-worksheet/header-title';
import InventoryWorksheetTopContainer from '../../components/inventory-worksheet/top-container';
import InventoryWorksheetTable from '../../components/inventory-worksheet/table';
import { Box } from '@mui/material';
import { checkUserPermissions, getLocalStorageItem } from '../../utility/helper';
import { PERMISSIONS } from '../../utility/constants';
import styles from './styles';
import { IInventoryWorksheetContext } from '../../interfaces/inventoryWorksheetInterface';
import InventoryWorksheetProvider, { InventoryWorksheetContext } from '../../context/inventoryWorksheetContext';

export interface IInventoryWorksheetProps {
  path: 'clients' | 'reports';
}

const InventoryWorksheetPage: FC<IInventoryWorksheetProps> = (props) => {
  const {canViewReport, setCanViewReport,
         canViewInventoryWorksheet, setCanViewInventoryWorksheet} = useContext(InventoryWorksheetContext) as IInventoryWorksheetContext;

  useEffect(() => {
    getPermission();
  }, []);

  const getPermission = async () => {
    const canViewReport = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_INVENTORY_REPORT);
    const canViewInvWorksheet = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_INVENTORY_WORKSHEET);
    setCanViewReport(canViewReport ?? false);
    setCanViewInventoryWorksheet(canViewInvWorksheet ?? false);
  };

  return (
    <Box sx={{...(((!canViewReport && props.path === 'reports') || (!canViewInventoryWorksheet && props.path === 'clients')) && styles.hidden)}}>
      <InventoryWorksheetBreadcrumbs path={props.path} />
      <InventoryWorksheetHeaderTitle path={props.path} />
      <InventoryWorksheetTopContainer path={props.path} />
      <InventoryWorksheetTable path={props.path}/>
    </Box>
  );
}

const InventoryWorksheet: FC<IInventoryWorksheetProps> = (props) => {
  return (
    <NavigationWarningProvider>
      <InventoryWorksheetProvider>
        <InventoryWorksheetPage path={props.path} />
      </InventoryWorksheetProvider>
    </NavigationWarningProvider>
  )
}
export default InventoryWorksheet;