import { ActionType, AuthActions } from '../actions/authActions';
import { getLocalStorageItem, } from '../utility/helper';

export interface AuthState {
  token: string;
  refreshToken: string;
  uid:  string;
  email: string;
  firstName: string;
  lastName: string;
}

export const initialState: AuthState = {
  token : getLocalStorageItem('token'),
  refreshToken: getLocalStorageItem('refreshToken'),
  uid: getLocalStorageItem('uid'),
  email: getLocalStorageItem('email') ? getLocalStorageItem('email') : '',
  firstName: getLocalStorageItem('firstName') ? getLocalStorageItem('firstName') : '',
  lastName: getLocalStorageItem('lastName') ? getLocalStorageItem('lastName') : ''
};

const authReducer = (state: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case ActionType.LOGIN:
      return {
        ...state,
        token : action.payload.token,
        refreshToken : action.payload.refreshToken,
        uid: action.payload.uid,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName
      };
    case ActionType.LOGOUT:
      return {
        ...state,
        token : '',
        refreshToken: '',
        uid: ''
      };
    default:
      return state;
  }
};

export default authReducer;