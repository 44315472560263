import { arCustomersAPI, arVendorsAPI } from "../../service/api";
import request from "../../service/request";
import { DELETE, PUT } from "../../utility/constants";

export const deleteCustomerById = (arCustomerId: number) => request({
  url: `${arCustomersAPI.MAIN_ENDPOINT}/${arCustomerId}`,
  method: DELETE,
});

export const deleteCustomers = (arCustomerIds: number[]) => request({
  url: `${arCustomersAPI.DELETE_CUSTOMERS}`,
  method: DELETE,
  data: arCustomerIds,
});

export const archiveCustomers = (arCustomerIds: number[], isArchived: boolean) => request({
  url: `${arCustomersAPI.ARCHIVE_CUSTOMERS}`,
  method: PUT,
  params: {
    isArchived: isArchived,
  },
  data: arCustomerIds,
});

export const deleteVendorById = (arVendorId: number) => request({
  url: `${arVendorsAPI.MAIN_ENDPOINT}/${arVendorId}`,
  method: DELETE,
});

export const deleteVendors = (arVendorIds: number[]) => request({
  url: `${arVendorsAPI.DELETE_VENDORS}`,
  method: DELETE,
  data: arVendorIds,
});

export const archiveVendors = (arVendorIds: number[], isArchive: boolean) => request({
  url: `${arVendorsAPI.ARCHIVE_VENDORS}`,
  method: PUT,
  params: {
    isArchive: isArchive,
  },
  data: arVendorIds,
});