import * as Yup from 'yup';

const mobileNoRegEx = /\d{10}/;

const clientSchema = Yup.object({
    showDueDate: Yup.boolean(),
    borrowerName: Yup.string()
        .required('Client Name is required')
        .strict(true),
    lineLimit: Yup.string()
        .required('Line Limit is required'),
    lineOfBusiness: Yup.string(),
    reportingFrequency: Yup.string(),
    clientLoanNumber: Yup.number(),
    crmName: Yup.string()
        .strict(true),
    currencyId: Yup.string()
    .nullable()
    .required('Currency is required'),
    dueDate: Yup.string()
        .when('showDueDate', {
            is: true,
            then: Yup.string(),
        }),
    address1: Yup.string()
        .strict(true),
    address2: Yup.string()
        .strict(true),
    city: Yup.string()
        .strict(true),
    state: Yup.string()
        .strict(true),
    zipCode: Yup.string()
        .strict(true),
    country: Yup.string()
        .strict(true),
    clientContactName: Yup.string()
        .strict(true),
    clientContactEmail: Yup.string()
        .email('Invalid email format'),
    clientContactPhoneNumber: Yup.string()
        .matches(mobileNoRegEx,"Invalid phone number"),
    accountingSystem: Yup.string(),
    externalClientId: Yup.string().required('External Client ID is required'),
    externalFCN: Yup.string().max(20, "External FCN must not exceed 20 characters."),
    ecid: Yup.string().max(20, "ECID must not exceed 20 characters."),
})

export default clientSchema;