import colors from '../../../utility/colors';

const styles = {
  paperStyle: {
    display       : 'flex',
    flexDirection : 'column',
    margin        : '0.5rem',
    width         : '350px',
    minHeight     : '75vh',
    left          : '50%',
    transition    : 'width 0.25s ease-in-out',
    height        : 'fit-content',
  },
  closedPaperStyle: {
    display       : 'inline-block',
    margin        : '0.5rem',
    overflow      : 'visible',
    width         : '30px',
    minHeight     : '75vh',
    left          : '50%',
    transition    : 'width 0.25s ease-in-out',
  },
  icon: {
    p: '0.25rem',
    m: '24px',
    color: colors.PRIMARY,
  },
  overflowIcon: {
    p: '0.25rem',
    ml: '12px',
    mt: '24px',
    color: colors.PRIMARY,
    backgroundColor: colors.WHITE,
    boxShadow: 2,
  },
  settingsLabel: {
    m: '1rem',
    fontWeight: 'bold',
    fontSize: '16px',
    color: colors.PRIMARY,
    verticalAlign: 'center',
  },
  settingsButton: {
    margin: '0.5rem',
    backgroundColor: colors.PRIMARY_DARK,
  },
  titleBox: {
    display: 'flex', 
    flexDirection: 'row' ,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  typographyBox: {
    ml: '24px',
    mt: '24px',
  },
  ineligiblesHeadingContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: colors.PRIMARY,
    marginRight: '5px'
  },
  subtitleText: {
    fontSize: '14px',
    width: '180px',
  },
  hideInactiveIneligiblesButton: {
    color: '#0075AD',
    fontSize: '12px',
  },
}

export default styles;