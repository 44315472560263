import { BankLogoState } from "../reducer/bankLogoReducer";

export enum ActionType {
  UPDATE_LOGO
}

export interface UpdateLogo {
  type: ActionType.UPDATE_LOGO;
  payload: string;
}

export const setNewLogoDataUrl = (payload: BankLogoState): UpdateLogo => {
  return {
      type: ActionType.UPDATE_LOGO,
      payload: payload.logoDataUrl
  }
}

export type BankLogoActions = UpdateLogo;