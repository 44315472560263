import React from 'react';
import { 
    Skeleton,
    TableCell,
    TableHead,
    TableRow, 
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { generateSkeletonRows } from '../../utility/helper';

interface SkeletonRowProps {
  numColumns?: number; 
}

export const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F7F7F7',
      color: 'inherit',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '0.9rem',
    },
    border: 0,
    color: 'inherit',
}));

export const SkeletonRow = (props: SkeletonRowProps) => {
  return (
    <>
      <TableRow>
        { generateSkeletonRows(props.numColumns ?? 0, props.numColumns ?? 0).map((row, index) => (
          <StyledTableCell key={row.id}>
            <Skeleton variant='rectangular' height={20}/>
          </StyledTableCell>
        ))}
      </TableRow>
    </>
  );
};

export const SkeletonTableHead = (props: any) => {
  return (
    <>
      <TableHead data-testid="skeleton-table">
        <TableRow>
          <StyledTableCell><Skeleton variant='rectangular' height={40}/></StyledTableCell>
          <StyledTableCell align='left'> <Skeleton variant='rectangular' height={40}/></StyledTableCell>
          <StyledTableCell align='left'> <Skeleton variant='rectangular' height={40}/> </StyledTableCell>
          <StyledTableCell align='left'> <Skeleton variant='rectangular'height={40}/> </StyledTableCell>
          <StyledTableCell align='center'> <Skeleton variant='rectangular' height={40}/> </StyledTableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

// default prop values
SkeletonRow.defaultProps = {
  numColumns: 5,
};