import { FC } from 'react';
import ClientTableOptions from '../../components/clientList/table/table-options';
import ClientProvider from '../../context/clientContext';

/**
 * Component for showing the contents of the clients page.
 */
const Clients: FC = () => {
  return (
    <ClientProvider>
      <ClientTableOptions />
    </ClientProvider>
  );
};

export default Clients;
