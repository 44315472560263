const styles = {
  tableForClientTotal: {
    paddingRight: '16px'
  },
  totalAmount: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '1rem',
    paddingRight: '0.5rem',
    backgroundColor: 'none',
    marginBottom: '50px',
  },
  clientTotalAmount: {
    paddingLeft: '2.5rem'
  },
  tableFooterText: {
    backgroundColor: '#F2F7F8',
    color: '#000',
    paddingTop: '1.4rem',
    paddingBottom: '0.8rem',
    fontSize: '15px',
    paddingX: '0.1rem',
    fontWeight: 'bold',
  },
  widthActionButton: {
    minWidth: '2rem',
    maxWidth: '2rem',
  },
  widthVendorId: {
    minWidth: '10rem',
    maxWidth: '10rem',
  },
  widthVendorName: {
    minWidth: '10rem',
    maxWidth: '10rem',
  },
  widthBuckets: {
    minWidth: '5rem',
    maxWidth: '5rem',
  },
  tableCellNumber: {
    paddingRight: '1rem',
  },
};

export default styles;
