import { useContext, useEffect } from 'react';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { Box, Button, Typography } from '@mui/material';
import { IUPCIneligibleSettingAPI, IFormikValuesForUPCRuleAndOverridesPerSetting, IIneligibleCondition, IIneligibleRuleConditionGroup, IIneligibleRuleOverride, IIneligibleSettingsContext, IUPCIneligibleSettingDetail, UPCIneligibleRuleAPI, IIneligibleSettingsPermissions } from '../../../../interfaces/ineligibleSettingInterface';
import { NON_EXISTING, NON_EXISTING_PERCENT, NOT_APPLICABLE, PERMISSIONS } from '../../../../utility/constants';
import { getPermissionsOfUser, isObjectsEqual, timeout } from '../../../../utility/helper';
import { AuthContext } from '../../../../context/authContext';
import { SelectedClientContext } from '../../../../context/selectedClientContext';
import { IneligibleSettingsContext } from '../../../../context/ineligibleSettingsContext';
import { validateIneligibleSettings } from '../../../../schemas/ineligibleSettingsSchema';
import Toaster from '../../../toaster';
import { InvalidIneligibleSettingsMessageModal } from '../../modals';
import styles from '../styles';
import NoDataPlaceholder from '../../../common/no-data-placeholder';
import DisabledComponentsContainer from '../../../common/disabled-components-container';
import { IClient } from '../../../../interfaces';
import UPCCollateralHeader from '../../collateral-header/upc-collateral-header';
import IneligibleList from '../../ineligible-settings-list';
import IneligibleSettingsDetails, { getIneligibleRuleOverrideDetails } from '../../ineligible-setting-details';
import { getIneligibleRuleOverrides, getUPCIneligibleRule, getUPCIneligibleSettings, postUPCIneligibleRule, putIneligibleOverrides, putUPCIneligibleRule, putUPCIneligibleSetting } from '../../../../api/ineligible-settings';
import { handleCreateOverrideAndConnections, handleDeleteCondition, handleDeleteConditionGroup, handleDeleteOverride, handleUpdatesForIneligibleRuleConditionGroups } from '..';

/**
 * This function generates a default ineligible rule object based on an ineligible setting.
 * @param ineligibleSetting The ineligible setting.
 * @returns A default ineligible rule object.
 */
export const getDefaultIneligibleRule = (ineligibleSetting: IUPCIneligibleSettingAPI, hasPaymentTerms: boolean) => {
  return {
    ineligibleRuleId: NON_EXISTING,
    ineligibleSettingId: ineligibleSetting.ineligibleSettingId,
    includeCredit: true,
    capAr: ineligibleSetting?.code === 'CN',
    arGtZero: false,
    childCustomerOnly: false,
    dpid: NON_EXISTING,
    dpdd: hasPaymentTerms ? NON_EXISTING : NOT_APPLICABLE,
    crossAgePct: NON_EXISTING_PERCENT,
    concentrationPct: NON_EXISTING_PERCENT,
    creditLimit: NON_EXISTING,
  };
};

/**
 * This asynchronous function validates a Formik form and returns errors related to ineligible settings.
 * It checks for errors in ineligible rules and their overrides, including condition groups and conditions.
 * @param formik FormikProps for the form.
 * @returns True if any errors are found, false otherwise.
 */
export const getErrors = async (formik: FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>) => {
  const errors = await formik.validateForm();
  const settingErrors = errors.ineligibleSettingDetails as FormikErrors<IUPCIneligibleSettingDetail>[] ?? [];
  const hasError = settingErrors.some(setting => {
    const hasIneligibleRuleErrors = setting?.ineligibleRule !== undefined && Object.keys(setting.ineligibleRule).length > 0;
    if (hasIneligibleRuleErrors) { return true; }
    let hasIneligibleRuleOverrideErrors = false;
    if (setting?.ineligibleRuleOverrides !== undefined && setting.ineligibleRuleOverrides.length > 0) {
      const overrideErrors = setting.ineligibleRuleOverrides as unknown as FormikErrors<IIneligibleRuleOverride>[] ?? [];
      hasIneligibleRuleOverrideErrors = overrideErrors.some(overrideError => {
        const filteredOverrideKeys = Object.keys(overrideError ?? {}).filter(key => key !== 'ineligibleRuleConditionGroup');
        if (filteredOverrideKeys.length > 0) { return true; }
        const conditionGroupErrors = overrideError?.ineligibleRuleConditionGroup as FormikErrors<IIneligibleRuleConditionGroup>[] ?? [];
        return conditionGroupErrors.some(conditionGroupError => {
          const conditionErrors = conditionGroupError?.ineligibleRuleCondition as FormikErrors<IIneligibleCondition>[] ?? [];
          return conditionErrors.some(conditionError => Object.keys(conditionError ?? {}).length > 0);
        });
      });
    }
    return hasIneligibleRuleOverrideErrors;
  });
  return hasError;
};

/**
 * Component represents the IneligibleList main form container.
 * @returns The IneligibleSettingsFormContainer component.
 */
const IneligibleSettingsUPCFormContainer = () => {
  const { selectedClient }                                      = useContext(SelectedClientContext);
  const { state: authState }                                    = useContext(AuthContext);
  const {
    selectedIneligibleIndex, setSelectedIneligibleIndex,
    ineligibleSettingDetails, setIneligibleSettingDetails,
    fetchedIneligibleSettingDetails, setFetchedIneligibleSettingDetails,
    setAsOfDates, setMostRecentlyUpdatedAsOfDate,
    hasPaymentTerms, setHasPaymentTerms,
    setCurrency, setFieldList,
    setFieldTables, setFieldValues, setUserDefinedFieldList,
    setParentCustomers,
    toaster, setToaster,
    isFormikDirty, setIsFormikDirty,
    isInvalidIneligibleSettingsMessageModalOpen,
    setIsInvalidIneligibleSettingsMessageModalOpen,
    isIneligibleDetailsLoading, setIsIneligibleDetailsLoading,
    permissions, setPermissions
  }                                                                 = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  /**
   * This effect gets all ineligible settings related data when selected client is changed.
   */
  useEffect(() => {
    if (selectedClient?.recordId === undefined) { return; }
    const nonNullSelectedClient = selectedClient as Required<IClient>;
    getAllValuesForIneligibleSettings(nonNullSelectedClient);
  }, [selectedClient?.recordId]);

  /**
   * This effect gets permissions when auth state is changed.f
   */
  useEffect(() => {
    getPermissions();
  }, [authState]);

  /**
   * This function retrieves user permissions and updates state variables based on the user's permissions.
   */
  const getPermissions = async () => {
    if (permissions !== 'UNLOADED') { return; }
    const fetchedPermissions = await getPermissionsOfUser(authState.uid, authState.token);
    setPermissions({
      canViewIneligibleSettings: fetchedPermissions.includes(PERMISSIONS.VIEW_INELIGIBLE_SETTINGS),
      canReorderList: fetchedPermissions.includes(PERMISSIONS.REORDER_INELIGIBLE_LIST),
      canActivateDeactivateIneligible: fetchedPermissions.includes(PERMISSIONS.ACTIVATE_DEACTIVATE_INELIGIBLE),
      canUpdateAdditionalSettings: fetchedPermissions.includes(PERMISSIONS.UPDATE_ADDITIONAL_SETTINGS),
      canCalculateIneligibles: fetchedPermissions.includes(PERMISSIONS.INELIGIBLE_SETTINGS_CALCULATE_INELIGIBLE),
      canAddCriteriaAndSettings: fetchedPermissions.includes(PERMISSIONS.ADD_CRITERIA_AND_SETTINGS),
      canUpdateCriteriaAndSettings: fetchedPermissions.includes(PERMISSIONS.UPDATE_CRITERIA_AND_SETTINGS),
      canDeleteCriteriaAndSettings: fetchedPermissions.includes(PERMISSIONS.DELETE_CRITERIA_AND_SETTINGS),
    });
  };

  /**
   * This function fetches data for all ineligible settings and updates state variables accordingly.
   *
   * @param selectedClient The selected client.
   */
  const getAllValuesForIneligibleSettings = async (selectedClient: Required<IClient>) => {
    setIsIneligibleDetailsLoading(true);
    setIneligibleSettingDetails([]);
    setFetchedIneligibleSettingDetails([]);

    /* ineligible settings */
    const ineligibleSettings = await getUPCIneligibleSettings(selectedClient);

    const selectedIneligibleIndex = ineligibleSettings.findIndex(setting => !setting.disabled);

    /* rule, override related */
    const fetchedIneligibleSettingDetails = await Promise.all(getMapOfRequestsPerIneligibleSettingDetail(selectedClient, ineligibleSettings, selectedIneligibleIndex));

    /* set state values from api responses */
    ineligibleSettings.length > 0 && setSelectedIneligibleIndex(selectedIneligibleIndex);
    setIneligibleSettingDetails(fetchedIneligibleSettingDetails);
    setFetchedIneligibleSettingDetails(fetchedIneligibleSettingDetails);

    setFieldTables('UNLOADED');
    setFieldList('UNLOADED');
    setUserDefinedFieldList('UNLOADED');
    setFieldValues({});

    setAsOfDates('UNLOADED');
    setMostRecentlyUpdatedAsOfDate('UNLOADED');
    setHasPaymentTerms('UNLOADED');
    setCurrency('UNLOADED');
    setParentCustomers('UNLOADED');
    setIsIneligibleDetailsLoading(false);
  };

  const getMapOfRequestsPerIneligibleSettingDetail = (selectedClient: Required<IClient>, ineligibleSettings: IUPCIneligibleSettingAPI[], selectedIneligibleIndex: number) => ineligibleSettings.map(async (ineligibleSetting, index) => {
    const isIneligibleEnabledAndRequiringRule = !ineligibleSetting.disabled && ['DLQ', 'XA', 'AC', 'CN', 'CL', 'RFV'].includes(ineligibleSetting.code);
    if (selectedIneligibleIndex !== index && !isIneligibleEnabledAndRequiringRule) { return { ineligibleSetting }; }
    const isIneligibleDevoidOfOverrides = ['AC', 'CONTRA', 'RFV'].includes(ineligibleSetting.code);
    const ineligibleRule = await getUPCIneligibleRule(selectedClient, ineligibleSetting);
    if (ineligibleRule === null) {
      const defaultIneligibleRule = getDefaultIneligibleRule(ineligibleSetting, hasPaymentTerms as boolean);
      return {
        ineligibleSetting,
        ineligibleRule: defaultIneligibleRule,
        ...(!isIneligibleDevoidOfOverrides && {ineligibleRuleOverrides: []})
      };
    }
    if (selectedIneligibleIndex !== index || isIneligibleDevoidOfOverrides) { return { ineligibleSetting, ineligibleRule }; }
    const ineligibleRuleOverrides = await getIneligibleRuleOverrides(ineligibleRule);
    const ruleOverrideDetails = await getIneligibleRuleOverrideDetails(ineligibleRuleOverrides);
    return {
      ineligibleSetting,
      ineligibleRule: ineligibleRule,
      ineligibleRuleOverrides: ruleOverrideDetails
    };
  });

  /**
   * This function handles saving changes to ineligible settings, including creating, updating, and deleting rules and overrides.
   * @param values The form values representing the changes.
   * @param setSubmitting A function to set the submitting state.
   */
  const handleSave = async (values: IFormikValuesForUPCRuleAndOverridesPerSetting, setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      setIsIneligibleDetailsLoading(true);
      setSubmitting(true);
      if (!hasCriteriaPermissions()) { return; }
      const savedChangesOnIneligibleSettingDetails = await Promise.all(values.ineligibleSettingDetails.map(async ineligibleSettingDetail => {
        const { ineligibleSetting, ineligibleRule } = ineligibleSettingDetail;
        const {
          ineligibleSetting: fetchedIneligibleSetting,
          ineligibleRule: fetchedIneligibleRule
        } = fetchedIneligibleSettingDetails.find(detail => detail.ineligibleSetting.code === ineligibleSetting.code) as IUPCIneligibleSettingDetail;

        const savedSetting = !isObjectsEqual(ineligibleSetting, fetchedIneligibleSetting)
          ? ( await putUPCIneligibleSetting(ineligibleSetting) )
          : ineligibleSetting;

        const isIneligibleRuleUnloaded = ineligibleRule === undefined;
        if (savedSetting.disabled || isIneligibleRuleUnloaded) { return ineligibleSettingDetail; }

        await handleDeleteForIneligibleRuleOverrides(ineligibleSettingDetail);

        const isIneligibleRuleNonExisting = ineligibleRule.ineligibleRuleId === NON_EXISTING;
        if (isIneligibleRuleNonExisting) {
          const {
            ineligibleRule: createdIneligibleRule,
            ineligibleRuleOverrides: createdIneligibleRuleOverrides
          } = await handleCreateNewIneligibleRule(ineligibleSettingDetail);
          return {
            ineligibleSetting: savedSetting, ineligibleRule: createdIneligibleRule,
            ...(createdIneligibleRuleOverrides !== undefined && { ineligibleRuleOverrides: createdIneligibleRuleOverrides} )
          };
        }

        const [ savedRule, ...savedOverrides ] = await Promise.all([
          !isObjectsEqual(ineligibleRule, fetchedIneligibleRule) ? ( await putUPCIneligibleRule(ineligibleRule, ineligibleSetting) ) : ineligibleRule,
          ...handleUpdatesForIneligibleRuleOverrides(ineligibleSettingDetail, fetchedIneligibleRule as UPCIneligibleRuleAPI)
        ]);
        const isSettingDevoidOfOverrides = ['AC', 'RFV', 'CONTRA'].includes(ineligibleSetting.code);
        return { ineligibleSetting: savedSetting, ineligibleRule: savedRule, ...(!isSettingDevoidOfOverrides && { ineligibleRuleOverrides: savedOverrides }) };
      }));
      setIneligibleSettingDetails(savedChangesOnIneligibleSettingDetails);
      setFetchedIneligibleSettingDetails(savedChangesOnIneligibleSettingDetails);
    }
    catch (error) { console.log('SAVING INELIGIBLES ERROR: ', error); }
    finally {
      setSubmitting(false);
      await timeout(1); /* to prevent loading state being immediately finished, even though other states are not yet rendered */
      setIsIneligibleDetailsLoading(false);
    }
  };

  /**
   * This function checks and retrieves criteria-related permissions, displaying a message if permissions are lacking.
   */
  const hasCriteriaPermissions = () => {
    const loadedPermissions = permissions as IIneligibleSettingsPermissions;
    let messages: string[] = [];
    if (!loadedPermissions.canAddCriteriaAndSettings) { messages.push('adding'); }
    if (!loadedPermissions.canUpdateCriteriaAndSettings) { messages.push('updating'); }
    if (!loadedPermissions.canDeleteCriteriaAndSettings) { messages.push('deleting'); }

    if (messages.length === 0) { return true; }
    const combinedMessages = messages.reduce((combinedMessage, currentMessage) => `${combinedMessage}/${currentMessage}`);
    setToaster({
      isOpen: true, severity: 'error',
      message: `You do not have the necessary permissions for ${combinedMessages} criteria.`
    });
    return false;
  };

  /**
   * This function handles creating a new ineligible rule when it is new (all values inside are also new).
   * @param selectedCollateral The selected collateral.
   * @param ineligibleSettingDetail Details of the ineligible setting.
   * @param ineligibleSetting The ineligible setting API data.
   */
  const handleCreateNewIneligibleRule = async (ineligibleSettingDetail: IUPCIneligibleSettingDetail) => { // api requests when ineligible rule is new (means all values inside are also new)
    const { ineligibleSetting, ineligibleRule, ineligibleRuleOverrides } = ineligibleSettingDetail;
    const rule = await postUPCIneligibleRule(ineligibleRule as UPCIneligibleRuleAPI, ineligibleSetting);
    if (ineligibleRuleOverrides === undefined || ineligibleRuleOverrides.length === 0) { return { ineligibleRule: rule }; }

    const savedOverrides = await Promise.all(ineligibleRuleOverrides.map(override => handleCreateOverrideAndConnections(rule, override)));
    return { ineligibleRule: rule, ineligibleRuleOverrides: savedOverrides };
  };

  /**
   * This function handles updates for ineligible rule overrides.
   * @param ineligibleSettingDetail Details of the ineligible setting.
   * @returns An array of promises for updating rule overrides.
   */
  const handleUpdatesForIneligibleRuleOverrides = (ineligibleSettingDetail: IUPCIneligibleSettingDetail, fetchedRule: UPCIneligibleRuleAPI) => {
    if (ineligibleSettingDetail.ineligibleRuleOverrides === undefined) { return []; }
    const { ineligibleRuleOverrides: fetchedIneligibleRuleOverrides } = fetchedIneligibleSettingDetails.find(detail => detail.ineligibleSetting.code === ineligibleSettingDetail.ineligibleSetting.code) as IUPCIneligibleSettingDetail;
    const loadedFetchedIneligibleRuleOverrides = fetchedIneligibleRuleOverrides as IIneligibleRuleOverride[] ;

    return ineligibleSettingDetail.ineligibleRuleOverrides.map(async overrideFormData => { // api requests for updating (post and put), put requests does not need an await after as response id will not change
      const IS_OVERRIDE_NON_EXISTING = overrideFormData.ineligibleRuleOverrideId === NON_EXISTING;
      if (IS_OVERRIDE_NON_EXISTING) { return handleCreateOverrideAndConnections(fetchedRule, overrideFormData); }

      const fetchedOverride = loadedFetchedIneligibleRuleOverrides.find(override => override.ineligibleRuleOverrideId === overrideFormData.ineligibleRuleOverrideId) as IIneligibleRuleOverride;
      const [ savedOverride, ...savedConditionGroups ] = await Promise.all([
        !isObjectsEqual(overrideFormData, fetchedOverride) ? ( await putIneligibleOverrides(overrideFormData) ) : overrideFormData,
        ...handleUpdatesForIneligibleRuleConditionGroups(overrideFormData, fetchedOverride)
      ]);
      return { ...savedOverride, ineligibleRuleConditionGroup: savedConditionGroups };
    });
  };

  const handleDeleteForIneligibleRuleOverrides = async (formikSetting: IUPCIneligibleSettingDetail) => {
    const ineligibleSettingDetail = fetchedIneligibleSettingDetails.find(
        detail => detail.ineligibleSetting.ineligibleSettingId === formikSetting.ineligibleSetting.ineligibleSettingId
    ) as IUPCIneligibleSettingDetail;

    if (ineligibleSettingDetail.ineligibleRuleOverrides === undefined) { return []; }

    const handleDeleteOverrideAndConnections = async (override: IIneligibleRuleOverride) => {
      await Promise.all(override.ineligibleRuleConditionGroup.map(conditionGroup => handleDeleteConditionGroupAndConnections(override, conditionGroup)));
      return handleDeleteOverride(override, formikSetting);
    };

    const handleDeleteConditionGroupAndConnections = async (override: IIneligibleRuleOverride, conditionGroup: IIneligibleRuleConditionGroup) => {
      await Promise.all(conditionGroup.ineligibleRuleCondition.map(condition => handleDeleteCondition(override, conditionGroup, condition, formikSetting)));
      return handleDeleteConditionGroup(override, conditionGroup, formikSetting);
    };

    return Promise.all(ineligibleSettingDetail.ineligibleRuleOverrides.map(override => handleDeleteOverrideAndConnections(override)));
  };

  /**
   * This function checks if the ineligible settings are empty.
   * @param formik The Formik props.
   * @returns True if ineligible settings are empty, otherwise false.
   */
  const isIneligibleSettingsEmpty = (formik: FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>) => {
    if (isIneligibleDetailsLoading) { return false; } /* do not show empty state while loading */
    return selectedClient === null || formik.values.ineligibleSettingDetails.length === 0;
  };

  const hasUnsavedChanges = (formikDetails: IUPCIneligibleSettingDetail[], fetchedDetails: IUPCIneligibleSettingDetail[]) => {
    return formikDetails.some((formikDetail) => {
      const fetchedDetail = fetchedDetails.find(fetchedDetail => fetchedDetail.ineligibleSetting.code === formikDetail.ineligibleSetting.code);
      return !isObjectsEqual(formikDetail, fetchedDetail ?? {});
    });
  };

  const isIneligibleRuleAndOverrideLoading = (formik: FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>) => {
    if (isIneligibleDetailsLoading) { return true; }
    if (selectedIneligibleIndex === NON_EXISTING) { return false; }
    const isRuleLoading = formik.values.ineligibleSettingDetails[selectedIneligibleIndex].ineligibleRule === undefined;
    const isOverrideLoading = formik.values.ineligibleSettingDetails[selectedIneligibleIndex].ineligibleRuleOverrides === undefined;
    const isIneligibleDevoidOfOverrides = ['AC', 'CONTRA', 'RFV'].includes(formik.values.ineligibleSettingDetails[selectedIneligibleIndex].ineligibleSetting.code);
    if (isIneligibleDevoidOfOverrides) { return isRuleLoading; }
    if (isRuleLoading || isOverrideLoading) { return true; }
    const isDelinquentLoading = formik.values.ineligibleSettingDetails[selectedIneligibleIndex].ineligibleSetting.code === 'DLQ' && hasPaymentTerms === 'UNLOADED';
    return isDelinquentLoading;
  };

  /**
   * This function handles changes to the Formik reference.
   * @param node The Formik node.
   */
  const formikRef = (node: FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>) => {
    if (node === null || isIneligibleDetailsLoading) { return; }
    const hasUnsavedChangesOnFormik = hasUnsavedChanges(node.values.ineligibleSettingDetails, fetchedIneligibleSettingDetails as IUPCIneligibleSettingDetail[]);
    if (hasUnsavedChangesOnFormik === isFormikDirty) { return; }
    setIsFormikDirty(hasUnsavedChangesOnFormik);
  };

  return (
    <Box sx={{...(!(permissions as IIneligibleSettingsPermissions).canViewIneligibleSettings && styles.hidden)}}>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        innerRef={formikRef}
        validate={({ineligibleSettingDetails}) => validateIneligibleSettings({ ineligibleSettingDetails })}
        initialValues={{ ineligibleSettingDetails } as IFormikValuesForUPCRuleAndOverridesPerSetting}
        onReset={(_values, formikHelpers) => { formikHelpers.setErrors({}); setIneligibleSettingDetails(fetchedIneligibleSettingDetails); }}
        onSubmit={(values, { setSubmitting }) => handleSave(values, setSubmitting)}
      >
        {(formik) => (
          <>
            <Box sx={styles.titleContainer}>
              <Typography tabIndex={0} variant='h6' component='h3' sx={styles.title}>
                Ineligible Settings
              </Typography>
              <Box sx={styles.rowBox}>
                { isFormikDirty ? (
                  <Button
                    disabled={isIneligibleDetailsLoading}
                    variant='outlined'
                    sx={styles.cancelButton}
                    onClick={async () => {
                      setIsIneligibleDetailsLoading(true);
                      await timeout(1); /* to render loading state, causing a rerender (solution for cases not rendering immediately) */
                      formik.resetForm();
                      ineligibleSettingDetails.length > 0 && setSelectedIneligibleIndex(ineligibleSettingDetails.findIndex(detail => !detail.ineligibleSetting.disabled));
                      setIsIneligibleDetailsLoading(false);
                    }}
                  >
                    Cancel
                  </Button>
                ) : null }
                <DisabledComponentsContainer isDisabled={(!isFormikDirty || formik.isSubmitting || formik.isValidating || isIneligibleRuleAndOverrideLoading(formik))}>
                  <Button
                    disabled={(!isFormikDirty || formik.isSubmitting || formik.isValidating || isIneligibleRuleAndOverrideLoading(formik))}
                    aria-label={(!isFormikDirty || formik.isSubmitting || formik.isValidating || isIneligibleRuleAndOverrideLoading(formik)) ? 'Save button disabled' : 'Save'}
                    color='primary'
                    variant='contained'
                    sx={styles.saveButton}
                    onClick={async () => {
                      const hasError = await getErrors(formik);
                      if (hasError) { setIsInvalidIneligibleSettingsMessageModalOpen(true); return; }
                      await formik.submitForm();
                      setToaster({ isOpen: true, severity: 'success', message: 'Ineligible Settings have been successfully saved.' });
                    }}
                  >
                    Save
                  </Button>
                </DisabledComponentsContainer>
              </Box>
            </Box>
            <UPCCollateralHeader formik={formik} />
            <Box sx={styles.contentStyle}>
              {
                (isIneligibleSettingsEmpty(formik))
                  ? <NoDataPlaceholder messageContainerStyle={styles.noDataPlaceHolderContainer} messageTextStyle={styles.noDataPlaceHolderText} />
                  : (
                    <>
                      <IneligibleList formik={formik} />
                      <IneligibleSettingsDetails formik={formik} />
                      <InvalidIneligibleSettingsMessageModal
                        isOpen={isInvalidIneligibleSettingsMessageModalOpen}
                        handleClose={() => setIsInvalidIneligibleSettingsMessageModalOpen(false)}
                      />
                    </>
                  )
              }
            </Box>
            <Toaster
              open={toaster.isOpen}
              message={toaster.message}
              severity={toaster.severity}
              onCloseChange={() => setToaster({ isOpen: false, message: '', severity: 'success' })}
            />
          </>
        )}
      </Formik>
    </Box>
  );
};

export default IneligibleSettingsUPCFormContainer;
