import { useState, useEffect, forwardRef, useContext } from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { Field, FieldArray, Form, Formik, FormikErrors, FormikProps, FormikTouched, getIn } from 'formik';
import { AlertColor, Box, Button, FormLabel, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HistoryIcon from '@mui/icons-material/History';
import { API_DOMAIN, GET, NO_PERMISSION_MSG, PERMISSIONS, POST, PUT } from '../../../utility/constants';
import { checkUserPermissions, formatDate, getPermissionsOfUser, isObjectsEqual, trimOnBlur } from '../../../utility/helper';
import { usePrompt } from '../../../utility/prompt';
import request from '../../../service/request';
import requests from '../../../service/requests';
import loanBalancesSchema from '../../../schemas/loanBalancesSchema';
import { INavigationWarningContext, NavigationWarningContext } from '../../../context/navigationWarningContext';
import { LoanBalancesContext } from '../../../context/loanBalancesContext';
import { IFormikValue, ILoanAPIContent, ILoanBalance, ILoanBalancesContext, INumberFormatProps, IResetFormForLoanBalances, IArchiveModal } from '../../../interfaces/loanBalancesInterface';
import Toaster from '../../toaster';
import ConfirmModal from '../../modals/confirm-modal';
import styles from './styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SkeletonRow, SkeletonTableHead } from '../../skeleton';
import NoDataPlaceholder from '../../common/no-data-placeholder';
import { AuthContext } from '../../../context/authContext';
import HelperTextComponent from '../../common/helper-text-component';
import DisabledComponentsContainer from '../../common/disabled-components-container';

const CurrencyFormat = forwardRef<NumberFormat<InputAttributes>, INumberFormatProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      type='tel'
      decimalScale={2}
      fixedDecimalScale={true}
      prefix='$'
    />
  );
});

const LoanBalanceTable: React.FC = () => {
  const TABS: { label: 'Active' | 'Archived'}[]      = [ { label: 'Active' }, { label: 'Archived' } ];
  const HEADERS                                     = ['GL Account Name', 'GL Account Number', 'As of Date', 'Amount', 'Action'];
  const DEFAULT_PAGE                                = 0;
  const DEFAULT_ROWS_PER_PAGE                       = 9999;

  const { isDirty, setIsDirty }                     = useContext(NavigationWarningContext) as INavigationWarningContext;
  const { selectedClient, setTotalLoanBalances,
          setViewLoanBalances,
          addLoanBalances, setAddLoanBalances,
          updateLoanBalances, setUpdateLoanBalances,
          deleteArchiveLoanBalances, setDeleteArchiveLoanBalances
        }                                           = useContext(LoanBalancesContext) as ILoanBalancesContext;
  const { state }                                   = useContext(AuthContext)

  const [selectedTabIndex, setSelectedTabIndex]     = useState<number>(TABS.findIndex(tab => tab.label === 'Active'));
  const [tabIndexToNavigate, setTabIndexToNavigate] = useState<number>(TABS.findIndex(tab => tab.label === 'Active'));
  const [loanBalances, setLoanBalances]             = useState<ILoanBalance[]>([]);
  const [isLoading, setIsLoading]                   = useState<boolean>(false);
  const [allowAddingRow, setAllowAddingRow]         = useState<boolean>(true);
  const [isToasterOpen, setIsToasterOpen]           = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]         = useState<string>('');
  const [toasterSeverity, setToasterSeverity]       = useState<AlertColor>('success');
  const [deleteModal, setDeleteModal]               = useState({ isOpen: false, isNew: false, deleteRecordId: '', deleteName: '', deleteIndex: -1 });
  const [archiveModal, setArchiveModal]             = useState<IArchiveModal>({ isOpen: false, title: '', description: '', confirmationText: '', selectedLoanBalance: null });
  const [
    isConfirmNavigationModalOpen,
    setIsConfirmNavigationModalOpen
  ]                                                 = useState<boolean>(false);
  const [formikValues, setFormikValues]             = useState<IFormikValue | null>(null);
  const [isFormikResetting, setIsFormikResetting]   = useState<boolean>(false);

  useEffect(() => {
    getPermission();
    if (isLoading) { return; }
    !isFormikResetting && setIsFormikResetting(true);
    fetchLoanBalances();
  }, [selectedClient, selectedTabIndex]);

  useEffect(() => {
    if (formikValues === null || formikValues.loanBalances === undefined) { return; }
    const { savedFormikValues, emptyFormikValues } = getSavedAndEmptyFormikValues(formikValues.loanBalances);
    calcTotalAmount(savedFormikValues);
    emptyFormikValues.length > 0 ? setAllowAddingRow(false) : setAllowAddingRow(true);
  }, [formikValues]);

  const getPermission = async () => {
    const permissions = await getPermissionsOfUser(state.uid)
    setViewLoanBalances(permissions.includes(PERMISSIONS.VIEW_LOAN_BALANCES))
    setAddLoanBalances(permissions.includes(PERMISSIONS.ADD_LOAN_BALANCES))
    setUpdateLoanBalances(permissions.includes(PERMISSIONS.UPDATE_LOAN_BALANCES))
    setDeleteArchiveLoanBalances(permissions.includes(PERMISSIONS.DELETE_ARCHIVE_LOAN_BALANCES))
  }

  const checkPermission = async (func: Function, permission: string, ...args: any[]) => {
    const isPermitted = await checkUserPermissions(state.uid, permission)
    if(isPermitted){
      func(...args)
      return
    }
    setToasterMessage(NO_PERMISSION_MSG)
    setToasterSeverity('error')
    setIsToasterOpen(true)
  }

  const fetchLoanBalances = () => {
    if (selectedClient.recordId === undefined || selectedClient.recordId === -1) {
      setLoanBalances([]);
      return;
    }
    setIsLoading(true);
    const isArchived = selectedTabIndex === TABS.findIndex(tab => tab.label === 'Archived');
    request({
      url: `${API_DOMAIN}/loans/search/findIsArchived?borrowerId=${selectedClient.recordId}&pageNo=${DEFAULT_PAGE}&pageSize=${DEFAULT_ROWS_PER_PAGE}&sortBy=recordId,ASC&isArchived=${isArchived}`,
      method: GET,
    })
      .then((response) => {
        const data = response.data.content.filter((item : any) => item.current === true);
        const loanBalances: ILoanBalance[] = data.map((row: ILoanAPIContent) => {
          const fetchedLoanBalance = {
            borrowerFk: row.borrowerFk,
            recordId: row.recordId,
            glAccountName: row.loanName ?? '',
            glAccountNumber: row.glAccountNumber ?? '',
            asOfDate: formatDate(row.asOfDate, 'YYYY-MM-DD'),
            amount: row.amount.toFixed(2),
            current: true,
            canDelete: row.canDelete === 1
          };
          return fetchedLoanBalance;
        });

        setIsDirty(false);
        setLoanBalances(_ => loanBalances);
      })
      .catch((error) => console.log('LOAN BALANCES GET ERROR: ', error))
      .finally(() => setIsLoading(false));
  };

  const deleteLoanBalance = (recordId: string, isFetchingAfter?: boolean) => {
    const [deletedLoanBalance] = loanBalances.filter(loanBalance => loanBalance.recordId === recordId);
    if (deletedLoanBalance === undefined) { return; }
    const deleteConfig = {
      url: `${API_DOMAIN}/loans/${deletedLoanBalance.recordId}`,
      method: PUT,
      data: {
        borrowerFk: deletedLoanBalance.borrowerFk,
        recordId: deletedLoanBalance.recordId,
        ...((deletedLoanBalance.glAccountName !== "" && deletedLoanBalance.glAccountName !== undefined) && { loanName: deletedLoanBalance.glAccountName }),
        ...((deletedLoanBalance.glAccountNumber !== "" && deletedLoanBalance.glAccountNumber !== undefined) && { glAccountNumber: deletedLoanBalance.glAccountNumber }),
        asOfDate: formatDate(deletedLoanBalance.asOfDate, 'MM/DD/YYYY'),
        amount: parseFloat(deletedLoanBalance.amount),
        current: false,
      },
    };

    request(deleteConfig)
      .then((_response) => {
        const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
        setToasterMessage(`${itemName} has been deleted`);
        setToasterSeverity('success');
        setIsToasterOpen(true); // display delete toaster
        if (isFetchingAfter) { fetchLoanBalances(); }
      })
      .catch((error) => console.log('LOAN BALANCES DELETE ERROR: ', error));
  };

  const archiveLoanBalance = () => {
    closeArchiveModal();
    const LOAN_BALANCE_TO_ARCHIVE = archiveModal.selectedLoanBalance;
    if (LOAN_BALANCE_TO_ARCHIVE === null) { return; }
    request({
      url: `${API_DOMAIN}/loans/${LOAN_BALANCE_TO_ARCHIVE.recordId}/archive`,
      method: PUT
    })
    .then((response) => {
      setToasterMessage(`${LOAN_BALANCE_TO_ARCHIVE.glAccountName} has been archived.`);
      setToasterSeverity('success');
      fetchLoanBalances();
    })
    .catch((error) => {
      setToasterMessage(`Failed to archive ${LOAN_BALANCE_TO_ARCHIVE.glAccountName}. Please check your inputs!`);
      setToasterSeverity('error');
      console.log('ARCHIVE LOAN BALANCE ERROR: ', error);
    })
    .finally(() => setIsToasterOpen(true));
  };

  const restoreLoanBalance = () => {
    closeArchiveModal();
    const LOAN_BALANCE_TO_RESTORE = archiveModal.selectedLoanBalance;
    if (LOAN_BALANCE_TO_RESTORE === null) { return; }
    request({
      url: `${API_DOMAIN}/loans/${LOAN_BALANCE_TO_RESTORE.recordId}/archive?isArchived=false`,
      method: PUT
    })
    .then((response) => {
      setToasterMessage(`${LOAN_BALANCE_TO_RESTORE.glAccountName} has been restored.`);
      setToasterSeverity('success');
      fetchLoanBalances();
    })
    .catch((error) => {
      setToasterMessage(`Failed to restore ${LOAN_BALANCE_TO_RESTORE.glAccountName}. Please check your inputs!`);
      setToasterSeverity('error');
      console.log('RESTORE LOAN BALANCE ERROR: ', error);
    })
    .finally(() => setIsToasterOpen(true));
  };

  const calcTotalAmount = (loanBalances: ILoanBalance[]) => {
    let total = 0;
    for (const item of loanBalances) {
      if (item.amount === undefined || item.amount === '') { continue; }
      total += parseFloat(item.amount);
    }
    setTotalLoanBalances(total);
  };

  const handleTabsChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (isDirty) {
      setIsConfirmNavigationModalOpen(true);
      setTabIndexToNavigate(newValue);
      return;
    }
    setLoanBalances([]);
    setSelectedTabIndex(newValue);
  };

  const handleDelete = (values: IFormikValue, index: number) => {
    const isLoanBalanceToDeleteNew = values.loanBalances[index].recordId === undefined;
    if (isLoanBalanceToDeleteNew) {
      setDeleteModal({
        isOpen: true,
        isNew: isLoanBalanceToDeleteNew,
        deleteRecordId: '',
        deleteName: values.loanBalances[index].glAccountName,
        deleteIndex: index,
      });
      return;
    }
    setDeleteModal({
      isOpen: true,
      isNew: isLoanBalanceToDeleteNew,
      deleteRecordId: values.loanBalances[index].recordId,
      deleteName: values.loanBalances[index].glAccountName,
      deleteIndex: index,
    });
  };

  const handleConfirmDelete = (formikValues: ILoanBalance[], remove: (index: number) => void) => {
    if (deleteModal.isNew) {
      remove(deleteModal.deleteIndex);
      const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
      setToasterMessage(`${itemName} has been deleted`);
      setToasterSeverity('success');
      setIsToasterOpen(true);
    }

    const { hasUnsavedChanges, presentFormikValues } = getPresentFormikValues(formikValues);
    if (hasUnsavedChanges && deleteModal.isNew) {
      setFormikValues({ loanBalances: presentFormikValues });
      return;
    }
    if (hasUnsavedChanges && !deleteModal.isNew) {
      deleteLoanBalance(deleteModal.deleteRecordId);
      setFormikValues({ loanBalances: presentFormikValues });;
      return;
    }
    if (!hasUnsavedChanges && !deleteModal.isNew) {
      deleteLoanBalance(deleteModal.deleteRecordId, true);
      setFormikValues({ loanBalances: presentFormikValues });
      return;
    }
    setLoanBalances(presentFormikValues);
  };

  const handleArchive = (values: ILoanBalance[], index: number) => {
    setArchiveModal({
      isOpen: true,
      title: `Archive ${values[index].glAccountName}`,
      description: 'Are you sure you want to archive this item?',
      confirmationText: 'Archive',
      selectedLoanBalance: values[index],
    });
  };

  const handleRestore = (values: ILoanBalance[], index: number) => {
    setArchiveModal({
      isOpen: true,
      title: `Restore ${values[index].glAccountName}`,
      description: 'Are you sure you want to restore this item?',
      confirmationText: 'Restore',
      selectedLoanBalance: values[index],
    });
  };

  const handleConfirmArchive = () => {
    if (archiveModal.confirmationText === 'Archive') { return archiveLoanBalance();  }
    if (archiveModal.confirmationText === 'Restore') { return restoreLoanBalance(); }
  };

  const handleCancel = (resetForm: IResetFormForLoanBalances) => {
    if (formikValues === null) { return; }
    const { savedFormikValues } = getSavedAndEmptyFormikValues(formikValues.loanBalances);
    setIsDirty(false);
    resetForm({ values: { loanBalances: savedFormikValues } });
    setLoanBalances(savedFormikValues);
    setAllowAddingRow(true);
  };

  const handleSave = async (loanBalancesToSave: ILoanBalance[], setSubmitting: (isSubmitting: boolean) => void) => {
    const isPermittedAdd = await checkUserPermissions(state.uid, PERMISSIONS.ADD_LOAN_BALANCES)
    const isPermittedUpdate = await checkUserPermissions(state.uid, PERMISSIONS.UPDATE_LOAN_BALANCES)

    const permitted = handlePermission(isPermittedAdd, isPermittedUpdate);
    if (!permitted) return;

    setSubmitting(true);

    const loanBalancesToEdit = loanBalancesToSave
      .filter((loanBalance) => {
        const isLoanBalanceNotNew = loanBalance.hasOwnProperty('recordId');
        return isLoanBalanceNotNew;
      })
      .filter((loanBalance) => {
        const [originalLoanBalance] = loanBalances.filter(currentLoanBalance => currentLoanBalance.recordId === loanBalance.recordId);
        if (originalLoanBalance === undefined) { return false; }
        const isLoanBalanceEdited = !isObjectsEqual(loanBalance, originalLoanBalance);
        return isLoanBalanceEdited;
      });

    // 1st part of update request (hide values to update)
    const editRequestPutConfigs = loanBalancesToEdit.map((loanBalance) => {
      return {
        url: `${API_DOMAIN}/loans/${loanBalance.recordId}`,
        method: PUT,
        data: {
          borrowerFk: loanBalance.borrowerFk,
          recordId: parseInt(loanBalance.recordId),
          ...((loanBalance.glAccountName !== "" && loanBalance.glAccountName !== undefined) && { loanName: loanBalance.glAccountName }),
          ...((loanBalance.glAccountNumber !== "" && loanBalance.glAccountNumber !== undefined) && { glAccountNumber: loanBalance.glAccountNumber }),
          asOfDate: formatDate(loanBalance.asOfDate, 'MM/DD/YYYY'),
          amount: parseFloat(loanBalance.amount),
          current: false,
        },
      };
    });

    // part 2 of update request (set current to true for updated values)
    const editRequestPostConfigs = loanBalancesToEdit.map((loanBalance) => {
      return {
        url: `${API_DOMAIN}/loans`,
        method: POST,
        data: {
          borrowerFk: loanBalance.borrowerFk,
          ...((loanBalance.glAccountName !== "" && loanBalance.glAccountName !== undefined) && { loanName: loanBalance.glAccountName }),
          ...((loanBalance.glAccountNumber !== "" && loanBalance.glAccountNumber !== undefined) && { glAccountNumber: loanBalance.glAccountNumber }),
          asOfDate: formatDate(loanBalance.asOfDate, 'MM/DD/YYYY'),
          amount: parseFloat(loanBalance.amount),
          current: true,
        },
      };
    });

    // add new loan balance
    const postRequestConfigs = loanBalancesToSave
      .filter(loanBalance => !loanBalance.hasOwnProperty('recordId'))
      .map(loanBalance => {
        return {
          url: `${API_DOMAIN}/loans`,
          method: POST,
          data: {
            borrowerFk: selectedClient.recordId,
            ...((loanBalance.glAccountName !== "" && loanBalance.glAccountName !== undefined) && { loanName: loanBalance.glAccountName }),
            ...((loanBalance.glAccountNumber !== "" && loanBalance.glAccountNumber !== undefined) && { glAccountNumber: loanBalance.glAccountNumber }),
            asOfDate: formatDate(loanBalance.asOfDate, 'MM/DD/YYYY'),
            amount: parseFloat(loanBalance.amount),
            current: true,
          },
        };
      });

    const updatedToasterMessage = getUpdateToasterMsg(isPermittedAdd? postRequestConfigs: [], isPermittedUpdate ? editRequestPostConfigs: []);
    requests(editRequestPutConfigs.map(requestConfig => request(requestConfig)))
      .then((_response) => {
        requests([...(isPermittedUpdate? editRequestPostConfigs: []), ...(isPermittedAdd? postRequestConfigs: [])].map((requestConfig) => request(requestConfig)))
          .then((_response) => {
            setIsDirty(false);
            fetchLoanBalances();
            setToasterMessage(updatedToasterMessage);
            setToasterSeverity('success');
            setIsToasterOpen(true);
            setSubmitting(false);
          })
          .catch((error) => console.log('LOAN BALANCES POST ERROR: ', error));
      })
      .catch((error) => console.log('LOAN BALANCES PUT ERROR: ', error));
  };

  const handlePermission = (isPermittedAdd?: boolean, isPermittedUpdate?: boolean) => {
    if(!isPermittedAdd && !isPermittedUpdate){
      setToasterMessage(NO_PERMISSION_MSG)
      setToasterSeverity('error')
      setIsToasterOpen(true);
      return false;
    };
    return true;
  }

  const handleDiscardChanges = () => {
    setIsDirty(false);
    setLoanBalances([]);
    setIsFormikResetting(true);
    setSelectedTabIndex(tabIndexToNavigate);
    setIsConfirmNavigationModalOpen(false);
  };

  const getTabProps = (index: number) => {
    return {
      id: `ineligible-adjustments-tab-${index}`,
      'aria-controls': `ineligible-adjustments-tabpanel-${index}`,
    };
  };

  const getDisplayedError = (errors: FormikErrors<IFormikValue>, touched: FormikTouched<IFormikValue>, name: string, index: number) => {
    const fieldError = getIn(errors, `loanBalances[${index}].${name}`);
    const isFieldTouched = getIn(touched, `loanBalances[${index}].${name}`);

    if (fieldError && isFieldTouched) { return { error: true, helperText: <HelperTextComponent text={fieldError} />  }; }

    return null;
  };

  const addNewRow = (push: any) => {
    const newLoanBalance = { glAccountName: '', glAccountNumber:'', asOfDate: '', amount: '' };
    push(newLoanBalance);
    setAllowAddingRow(false);
  };

  const closeArchiveModal = () => setArchiveModal(archiveModal => ({ ...archiveModal, isOpen: false }));

  const getUpdateToasterMsg = (addItems: any, updateItems: any) => {
    const addLength = addItems.length;
    const updateLength = updateItems.length;
    if (addLength > 0 && updateLength > 0) {
      return 'Changes in Loan Balances have been saved';
    }
    if (addLength > 0 && updateLength <= 0) {
      const phrase = addLength > 1 ? 'Items have been' : addItems[0].data.loanName? `${addItems[0].data.loanName} has been` : "Entry has been";
      return `${phrase} added`;
    }
    if (addLength <= 0 && updateLength > 0) {
      const phrase = updateLength > 1 ? 'Items have been' : updateItems[0].data.loanName? `${updateItems[0].data.loanName} has been` : "Entry has been";
      return `${phrase} updated`;
    }
    return '';
  };

  const getPresentFormikValues = (formikValues: ILoanBalance[]) => {
    let hasUnsavedChanges = false;
    const presentFormikValues = formikValues.filter((currentValue: ILoanBalance, currentIndex: number) => {
      if (currentIndex === deleteModal.deleteIndex) { return false; }
      if (currentValue.recordId === deleteModal.deleteRecordId) { return false; }

      const hasNewRecord = currentValue.recordId === undefined;
      const hasUpdatedRecord = loanBalances.some(loanBalance => {
        if (loanBalance.recordId !== currentValue.recordId) { return false; }
        return !isObjectsEqual(currentValue, loanBalance);
      });
      if (hasNewRecord || hasUpdatedRecord) { hasUnsavedChanges = true; }

      return true;
    });

    return { hasUnsavedChanges, presentFormikValues };
  };

  const getSavedAndEmptyFormikValues = (formikValuesForLoanBalances: ILoanBalance[]) => {
    const [savedFormikValues, emptyFormikValues] = formikValuesForLoanBalances.reduce((separatedFormikValues: ILoanBalance[][], loanBalance: ILoanBalance) => {
      let currentSavedFormikValues = [...separatedFormikValues[0]];
      let currentEmptyFormikValues = [...separatedFormikValues[1]];

      const isLoanBalanceSaved = loanBalance.recordId !== undefined && loanBalance.recordId !== '';
      if (isLoanBalanceSaved) {
        const [savedLoanBalance] = loanBalances.filter(originalLoanBalance => originalLoanBalance.recordId === loanBalance.recordId);
        savedLoanBalance !== undefined && currentSavedFormikValues.push(savedLoanBalance);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      const isLoanBalanceEmpty = (
        (loanBalance.glAccountName === undefined || loanBalance.glAccountName === '') &&
        (loanBalance.glAccountNumber === undefined || loanBalance.glAccountNumber === '') &&
        (loanBalance.asOfDate === undefined || loanBalance.asOfDate === '') &&
        (loanBalance.amount === undefined || loanBalance.amount === '')
      );
      if (isLoanBalanceEmpty) {
        currentEmptyFormikValues.push(loanBalance);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
    }, [[], []]);

    return { savedFormikValues, emptyFormikValues };
  };
  const isEmptyVerbiageForArchivedDisplayed = () => selectedTabIndex === TABS.findIndex(tab => tab.label === 'Archived') ? loanBalances.length === 0 : false;

  const formikRef = (node: FormikProps<IFormikValue>) => {
    if (node === null || isLoading) { return; }
    setFormikValues(node.values);
    !isObjectsEqual(formikValues, node.values) && setIsDirty(node.dirty);
    if (!isFormikResetting) { return; }
    node.resetForm();
    setIsFormikResetting(false);
  };
  
  const renderButton = (loanBalance: ILoanBalance, formik: FormikProps<{loanBalances: ILoanBalance[]}>, index: number) => {
    if (!deleteArchiveLoanBalances) {
      return null;
    } 
    else if (selectedTabIndex === 0) {
      if (loanBalance.canDelete !== false) {
        return (
          <Tooltip title='Delete the item'>
            <IconButton
              onClick={() => checkPermission(handleDelete, PERMISSIONS.DELETE_ARCHIVE_LOAN_BALANCES, formik.values, index)}
              sx={styles.actionButton}
              color='primary'
              aria-label='Delete icon'
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title='Archive the item'>
            <IconButton
              onClick={() => checkPermission(handleArchive, PERMISSIONS.DELETE_ARCHIVE_LOAN_BALANCES, formik.values.loanBalances, index)}
              sx={styles.actionButton}
              color='primary'
              aria-label='Archive icon'
            >
              <Inventory2OutlinedIcon />
            </IconButton>
          </Tooltip>
        );
      }
    } else {
      return (
        <Tooltip title='Restore the item'>
          <IconButton
            onClick={() => checkPermission(handleRestore, PERMISSIONS.DELETE_ARCHIVE_LOAN_BALANCES, formik.values.loanBalances, index)}
            sx={styles.actionButton}
            color='primary'
            aria-label='Restore icon'
          >
            <HistoryIcon />
          </IconButton>
        </Tooltip>
      );
    }
  }

  const getTabIndex = (isFormikField: boolean, index: number) => {
    if (isFieldDisabled(index)) return 0;
    else if (isFormikField) return null;
    else return undefined;
  }

  const isFieldDisabled = (index: number) => {
    return selectedTabIndex === TABS.findIndex(tab => tab.label === 'Archived') || (formikValues?.loanBalances[index]?.recordId === undefined ? !addLoanBalances : !updateLoanBalances)
  }

  const getActionButtons = (formik: FormikProps<IFormikValue>) => {
    if (addLoanBalances || updateLoanBalances || deleteArchiveLoanBalances) {
      return (
        <Box sx={{...styles.buttonsContainer, ...(selectedTabIndex === TABS.findIndex(tab => tab.label === 'Archived') && styles.hidden)}}>
          {formik.dirty ? (
            <Button
              onClick={() => handleCancel(formik.resetForm)}
              variant='outlined'
              sx={styles.cancelButton}
            >
              <Typography variant='body2' component='p'>
                Cancel
              </Typography>
            </Button>
          ) : null}
          <DisabledComponentsContainer isDisabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}>
            <Button
              disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}
              aria-label={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading ? 'Save button disabled' : 'Save'}
              variant='contained'
              sx={styles.saveButton}
              type='submit'
            >
              <Typography variant='body2' component='p'>
                Save
              </Typography>
            </Button>
          </DisabledComponentsContainer>
        </Box>
      )
    }
  }

  usePrompt('You have unsaved changes. Are you sure you want to leave this page?', isDirty);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{ loanBalances }}
      validationSchema={loanBalancesSchema}
      onSubmit={(values, { setSubmitting }) => handleSave(values.loanBalances, setSubmitting)}
    >
      {
        formik => (
          <Form onSubmit={formik.handleSubmit}>
            <Box sx={styles.outmostContainer}>
              <Box sx={styles.blockBox}>
                <Tabs
                  sx={styles.buttonTabs}
                  value={selectedTabIndex}
                  onChange={handleTabsChange}
                  aria-label='loan balances tabs'
                >
                  { TABS.map((tab, index) => <Tab tabIndex={0} label={tab.label} key={tab.label} {...getTabProps(index)} /> ) }
                </Tabs>
              </Box>
              {isLoading && (
                <Table data-testid='loan-balance-loader' sx={styles.table}>
                  <SkeletonTableHead />
                  <TableBody>
                    <SkeletonRow/>
                    <SkeletonRow/>
                    <SkeletonRow/>
                    <SkeletonRow/>
                    <SkeletonRow/>
                  </TableBody>
                </Table>
              )}
              {(!isLoading && isEmptyVerbiageForArchivedDisplayed()) && <NoDataPlaceholder messageText='There are no archives. Please add an archive to start' />}
              <TableContainer sx={styles.tableContainer} hidden={isLoading || isEmptyVerbiageForArchivedDisplayed()}>
                <Table sx={styles.table}>
                  <TableHead>
                    <TableRow sx={styles.tableHeadRow}>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForGLAccountName }}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='gl-account-name'
                          sx={styles.tableHeaderText}
                        >
                          GL Account Name
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForGLAccountNumber }}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='gl-account-number'
                          sx={styles.tableHeaderText}
                        >
                          GL Account Number
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForAsOfDate, ...styles.centerAlignedText }}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='as-of-date'
                          sx={styles.tableHeaderText}
                        >
                          As of Date<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForAmount, ...styles.rightAlignedText }}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='amount'
                          sx={styles.tableHeaderText}
                        >
                          Amount<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForAction, ...styles.centerAlignedText }}>
                        <FormLabel tabIndex={0} sx={styles.tableHeaderText}>
                          Action
                        </FormLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <FieldArray name='loanBalances'>
                    {({ push, remove }) => (
                      <TableBody sx={styles.tableBody}>
                        {formik.values.loanBalances.map((loanBalance, index) => {
                          return (
                            <TableRow sx={styles.tableRow} key={loanBalance.recordId}>
                              <TableCell sx={styles.tableCell}>
                                <Field
                                  disabled={isFieldDisabled(index)}
                                  tabIndex={getTabIndex(true, index)}
                                  id='gl-account-name'
                                  aria-label={`GL Account Name ${formik.values.loanBalances[index].glAccountName}`}
                                  name={`loanBalances[${index}].glAccountName`}
                                  as={TextField}
                                  sx={styles.textField}
                                  variant='outlined'
                                  onChange={(event: any) => { formik.handleChange(event); }}
                                  onBlur={(event:any) => {
                                    trimOnBlur(event, formik, `loanBalances[${index}].glAccountName`, formik.values.loanBalances[index].glAccountName)
                                 }}
                                  inputProps={{ 'aria-label': 'GL Account Name' }}
                                  {...getDisplayedError(formik.errors, formik.touched, 'glAccountName' , index)}
                                />
                              </TableCell>
                              <TableCell sx={styles.tableCell}>
                                <Field
                                  disabled={isFieldDisabled(index)}
                                  tabIndex={getTabIndex(true, index)}
                                  id='gl-account-number'
                                  aria-label={`GL Account Number ${formik.values.loanBalances[index].glAccountNumber}`}
                                  name={`loanBalances[${index}].glAccountNumber`}
                                  as={TextField}
                                  sx={styles.textField}
                                  variant='outlined'
                                  onChange={(event: any) => { formik.handleChange(event); }}
                                  onBlur={(event:any) => {
                                    trimOnBlur(event, formik, `loanBalances[${index}].glAccountNumber`, formik.values.loanBalances[index].glAccountNumber)
                                  }}
                                  inputProps={{ 'aria-label': 'GL Account Number' }}
                                  {...getDisplayedError(formik.errors, formik.touched, 'glAccountNumber', index)}
                                />
                              </TableCell>
                              <TableCell sx={styles.tableCell}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    onChange={() => {}} // change callback is moved to onAccept
                                    onAccept={(newDate: Date | null) => {
                                      if (!newDate) return;
                                        formik.setFieldValue(`loanBalances[${index}].asOfDate`, newDate.toISOString());
                                    }}
                                    value={formik.values.loanBalances[index].asOfDate ? new Date(formik.values.loanBalances[index].asOfDate) : null}
                                    disabled={isFieldDisabled(index)}
                                    renderInput={params => (
                                      <TextField
                                        id='as-of-date'
                                        aria-label={`As of Date ${formik.values.loanBalances[index].asOfDate}`}
                                        tabIndex={getTabIndex(false, index) as (number | undefined)}
                                        name={`loanBalances[${index}].asOfDate`}
                                        onBlur={(event) => {
                                          const value = event.target.value;
                                          formik.setFieldValue(
                                            `loanBalances[${index}].asOfDate`,
                                            value ? new Date(value).toISOString() : ''
                                          );
                                          formik.setFieldTouched(`loanBalances[${index}].asOfDate`, true);
                                        }}
                                        variant='outlined'
                                        inputProps={{
                                          sx: styles.centerAlignedText,
                                          'aria-label': 'Calendar icon',
                                          'data-date': loanBalance.asOfDate !== '' ? formatDate(loanBalance.asOfDate, 'MM/DD/YYYY') : ''
                                        }}
                                        onDrop={(event) => event.preventDefault()}
                                        {...params}
                                        {...getDisplayedError(formik.errors, formik.touched, 'asOfDate', index)}
                                        sx={{...styles.textField, ...styles.textFieldForAsOfDate}}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </TableCell>
                              <TableCell sx={styles.tableCell}>
                                <Field
                                  disabled={isFieldDisabled(index)}
                                  tabIndex={getTabIndex(true, index)}
                                  id='amount'
                                  aria-label={`Amount ${formik.values.loanBalances[index].amount}`}
                                  name={`loanBalances[${index}].amount`}
                                  as={TextField}
                                  sx={styles.textField}
                                  variant='outlined'
                                  onChange={(event: any) => { formik.handleChange(event); }}
                                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => formik.handleBlur(event)}
                                  InputProps={{ inputComponent: CurrencyFormat as any }}
                                  inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Amount' }}
                                  {...getDisplayedError(formik.errors, formik.touched, 'amount', index)}
                                />
                              </TableCell>
                              <TableCell sx={{ ...styles.actionTableCell, ...styles.centerAlignedText }}>
                                {renderButton(loanBalance, formik, index)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {/* rows that adds new form rows */}
                        { (allowAddingRow && selectedTabIndex === TABS.findIndex(tab => tab.label === 'Active') && addLoanBalances ) &&
                          <TableRow sx={styles.tableRow} key={formik.values.loanBalances.length} data-testid='row-adder'>
                            {HEADERS.map((header, index) => (
                              <TableCell sx={styles.tableCell} key={header}>
                                {index !== HEADERS.findIndex(header => header === 'Action') ? (
                                  <TextField
                                    sx={styles.textField}
                                    value=''
                                    onClick={() => addNewRow(push)}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { addNewRow(push) } }}
                                    inputProps={{ 'aria-label': 'Press enter to add new entry' }}
                                  />
                                ) : null}
                              </TableCell>
                            ))}
                          </TableRow>
                        }
                        <ConfirmModal
                          open={deleteModal.isOpen}
                          onClose={() => { setDeleteModal({ ...deleteModal, isOpen: false }); }}
                          onConfirm={() => { handleConfirmDelete(formik.values.loanBalances, remove); }}
                          title={`Delete ${deleteModal.deleteName}`}
                          description='Are you sure you want to delete this item?'
                          errorButton
                          yesButtonText='Delete'
                          noButtonText='Cancel'
                        />
                      </TableBody>
                    )}
                  </FieldArray>
                </Table>
              </TableContainer>
              {getActionButtons(formik)}
            </Box>
            <Toaster
              open={isToasterOpen}
              message={toasterMessage}
              severity={toasterSeverity}
              onCloseChange={() => setIsToasterOpen(false)}
            />
            <ConfirmModal
              title={archiveModal.title}
              description={archiveModal.description}
              open={archiveModal.isOpen}
              alignment='left'
              onClose={closeArchiveModal}
              onConfirm={handleConfirmArchive}
              errorButton={false}
              noButtonText={'Cancel'}
              yesButtonText={archiveModal.confirmationText}
            />
            <ConfirmModal
              open={isConfirmNavigationModalOpen}
              onConfirm={() => setIsConfirmNavigationModalOpen(false)}
              onClose={handleDiscardChanges}
              onButtonClose={() => setIsConfirmNavigationModalOpen(false)}
              promptChecker={true}
              title='Confirm Navigation'
              description='You have unsaved changes. Are you sure you want to leave this page?'
              yesButtonText='Keep Editing'
              noButtonText='Discard Changes'
              confirmOnly
            />
          </Form>
        )
      }
    </Formik>
  );
};

export default LoanBalanceTable;
