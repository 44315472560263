import colors from "../../../../../../../../utility/colors";

const styles = {
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.45rem',
  },
  buttonCancel: {
    width: '5.125rem',
    fontSize: '14px',
  },
  buttonSave: {
    marginLeft: '0.5rem',
    fontSize: '14px',
  },
  buttonBack: {
    color: colors.PRIMARY_LIGHT,
    padding: 0,
    fontSize: '14px',
    cursor: 'pointer',
  },
  calculationMappingContainer: {
    width: '100%',
    height: '100%'
  },
  containerTable: {
    maxHeight: '29.5rem',
    width: '100%',
    paddingLeft: '3px',
    display: 'flex',
    overflow: 'auto'
  },
  table: {
    'tr:nth-of-type(even)': {
      backgroundColor: '#F7F7F7'
    },
  },
  tableCell: {
    border: 0,
    padding: '0.5rem'
  },
  rowHeader: {
    boxShadow: '0px 3px 6px #00000029'
  },
  cellHeader: {
    backgroundColor: '#F7F7F7',
    borderBottom: 0,
    fontSize: '14px'
  },
  cellType: {
    width: '35%',
    paddingLeft: '8rem'
  },
  cellAmt: {
    width: '35%'
  },
  cellCategory: {
    width: '30%',
    paddingRight: '8rem'
  },
  dropdownList: {
    fontSize: '0.875rem',
    width: '100%',
  },
  dropdown: {
    width: '100%',
    backgroundColor: `${colors.WHITE}`,
    '& .MuiOutlinedInput-root': {
      fontSize: '0.875rem',
    },
  },
  emptyContainer: {
    padding: '1rem',
    borderRadius: '3px',
    border: '1px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  verbiage: {
    fontSize: '14px',
    textAlign: 'center'
  },
  saveAndBackContainer: {
    display: 'flex'
  }
};
export default styles;