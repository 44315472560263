
export enum ActionType {
  TOGGLE_WEB_OPEN,
  SET_WEB_OPEN,
  TOGGLE_HARD_OPEN,
  TOGGLE_MOBILE_OPEN,
}

export interface ToggleWebAction {
  type: ActionType.TOGGLE_WEB_OPEN;
}

export interface SetWebAction {
  type: ActionType.SET_WEB_OPEN;
  payload: boolean,
}

export interface ToggleHardAction {
  type: ActionType.TOGGLE_HARD_OPEN
}

export interface ToggleMobileAction {
  type: ActionType.TOGGLE_MOBILE_OPEN
}

export const toggleWebOpen = (): ToggleWebAction => {
  return {
      type: ActionType.TOGGLE_WEB_OPEN
  }
}

export const setWebOpen = (newWebOpen: boolean): SetWebAction => {
  return {
    type: ActionType.SET_WEB_OPEN,
    payload: newWebOpen,
  }
}

export const toggleHardOpen = (): ToggleHardAction => {
  return {
    type: ActionType.TOGGLE_HARD_OPEN,
  }
}

export const toggleMobileOpen = (): ToggleMobileAction => {
  return {
    type: ActionType.TOGGLE_MOBILE_OPEN,
  }
}

export type DrawerActions = ToggleWebAction | SetWebAction | ToggleHardAction | ToggleMobileAction;