const styles = {
  tableContainer: {
    paddingX: '3px',
    marginBottom: '1rem'
  },
  tableHeader: {
    boxShadow: '0px 3px 6px #00000029',
  },
  tableTextHeader: {
    backgroundColor: '#EAEAEA',
    paddingY: '1rem',
    height: '1rem',
  },
  tableTextHeaderTypography: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  tableTextHeaderLeftAction: {
    textAlign: 'right',
    paddingRight: 0,
    width: '20%'
  },
  tableTextHeaderCategory: {
    textAlign: 'left',
    paddingLeft: 0,
    width: '30%'
  },
  tableTextHeaderAmount: {
    textAlign: 'right',
    width: '30%',
    paddingRight: 0
  },
  tableTextHeaderRightAction: {
    textAlign: 'left',
    width: '20%',
  },
  tableRowSpacer: {
    height: '1rem',
  },
  tableTextBody: {
    fontSize: '14px',
    border: 0,
    paddingY: '0.1rem'
  },
  tableTextBodyLeftAction: {
    textAlign: 'right',
    paddingRight: 0
  },
  tableTextBodyCategory: {
    textAlign: 'left',
    fontWeight: 'bold',
    paddingLeft: 0
  },
  tableTextBodyAmount: {
    textAlign: 'right',
    paddingLeft: '10rem',
    paddingRight: 0
  },
  tableTextBodyRightAction: {
    paddingLeft: '0.1rem'
  },
  amtTextFieldInput: {
    fontSize: '14px',
    textAlign: 'right',
    paddingY: '0.5rem'
  },
  editButton: {
    padding: '0.2rem',
  },
  editIcon: {
    fontSize: '1rem'
  },
}
export default styles;