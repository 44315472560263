const styles = {
  accordion: {
    display: 'block',
    position: 'relative',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  accordionSummaryFontSize: {
    fontSize: '1.5rem',
    color: '#154A8A'
  },
  accordionSummary: {
    backgroundColor: '#F2F7F8',
    display: 'flex',
    borderBottom: '2px solid #E9ECEF',
    alignItems: 'flex-end',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
      ariaLabel: 'Collapse icon'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      marginLeft: 1,
    },
    paddingX: '0.5rem',
  },
  accordionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  accordionDetails: {
    backgroundColor: '#F2F7F8',
  },
};

export default styles;
