import { CalendarMonth } from '@mui/icons-material';
import { Box, Chip, ClickAwayListener, IconButton, Popper, Tooltip } from '@mui/material';
import Autocomplete, { AutocompleteRenderGetTagProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { MouseEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { formatDate } from '../../../../../../utility/helper';
import DatePickerMultiple, { DatePickerMultipleProps } from '../../../../../../components/datepicker-multiple';
import styles from './styles';

interface DatePickerInputProps extends DatePickerMultipleProps {
  width       : string,
  popperOffset: number[],
  limitTags?  : number,
  placeholder?: string,
  tooltip?    : string,
  isOpen      : boolean,
  setIsOpen   : (isOpen : boolean) => void;
}

const DatePickerInput = (props: DatePickerInputProps) => {
  const { setIsOpen, defaultValue, onSelectDate, width, popperOffset, limitTags, isNotCalculatedDate, isNotCalculatedYear, asOfDateCreator, placeholder, tooltip } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const calendarButtonRef       = useRef(null);

  useEffect(() => {
    setIsOpen(anchorEl !== null);
  }, [anchorEl, defaultValue]);

  const onChange = useCallback((_: SyntheticEvent<Element, Event>, values: readonly Date[]) => {
    onSelectDate([...values])
    toggleCalendar();
  }, [calendarButtonRef, anchorEl]);

  const renderTags = useCallback((value: readonly Date[], getTagProps: AutocompleteRenderGetTagProps) => value.map((option: Date, index: number) => (
    <Chip size='small' label={formatDate(option.toISOString(), 'MM/DD/YYYY')} {...getTagProps({ index })} />
  )), [])

  const renderInput = useCallback((params: AutocompleteRenderInputParams) => (
    <TextField
      aria-label="Compare Dates Input"
      {...params}
      placeholder={defaultValue.length ? '' : placeholder}
      inputProps={{ ...params.inputProps, onClick: (event) => event.stopPropagation()}}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <Tooltip title={tooltip ?? 'Select As of Datee'}>
            <IconButton
              aria-label="Compare Dates Input"
              ref={calendarButtonRef}
              color="primary"
              edge="end"
              sx={styles.datePickerIcon}
              onClick={handleClick}
            >
              <CalendarMonth />
            </IconButton>
          </Tooltip>
        )
      }}
    />
  ), [defaultValue, calendarButtonRef, anchorEl])

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
    setIsOpen(false);
  }, []);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const toggleCalendar = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id  = open ? 'simple-popper' : undefined;

  return (
    <Box>
      <Autocomplete
        multiple
        limitTags={limitTags}
        open={false}
        autoComplete={false}
        inputValue=""
        id="as-of-date-picker"
        options={defaultValue}
        value={defaultValue}
        onChange={onChange}
        sx={{ width: width, ...styles.dropdown}}
        renderTags={renderTags}
        renderInput={renderInput}
      />
      <Popper 
        sx={styles.popper} 
        id={id} 
        open={open}
        anchorEl={anchorEl}
        placement="right-start"
        modifiers={[{ name: 'offset', options: {offset: popperOffset} }]}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box sx={styles.datePickerContainer}>
            <DatePickerMultiple defaultValue={defaultValue}
              onSelectDate={onSelectDate}
              isNotCalculatedDate={isNotCalculatedDate}
              isNotCalculatedYear={isNotCalculatedYear}
              asOfDateCreator={asOfDateCreator}
            />
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  )
}

DatePickerInput.defaultProps = {
  placeholder   : 'Select As of Date',
};

export default DatePickerInput;