// yup-extended.ts
import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';
import _ from 'lodash';

yup.addMethod(yup.array, 'uniqueProperty', function(propertyPath, message) {
  return this.test('test-unique-property', '', function(list) {
    const errors: any[] = [];

    list?.forEach((item, index) => {
      if (item.isMasked) return true;
      
      const propertyValue = _.get(item, propertyPath);

      if (propertyValue && _.filter(list, [propertyPath, propertyValue]).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${propertyPath}`,
            message,
          })
        );
      }
    });

    if (!_.isEmpty(errors)) {
      throw new yup.ValidationError(errors);
    }

    return true;
  });
});

//Use only for string fields
yup.addMethod(yup.array, 'uniquePropertyDisregardCase', function (field, message) {
  return this.test('unique', message, function (array : any) {
    if (!Array.isArray(array)) {
      throw new Error('Value must be an array.');
    }
    const uniqueData = Array.from(
      new Set(array.map((row : string) => {
        return row[field]?.toLowerCase()
      })),
    );
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex(
      (row, i) => row[field]?.toLowerCase() !== uniqueData[i],
    );
    if (index === -1 || array[index][field] === '') {
      return true;
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    // validPhone(errorMessage?: string, required?: boolean): StringSchema<TType, TContext>;
    // validZipcode(errorMessage?: string): StringSchema<TType, TContext>;
  }

  interface ArraySchema<T> {
    uniqueProperty(propertyPath: string, message?: any): ArraySchema<T>;
    uniquePropertyDisregardCase(field: any, message: any): ArraySchema<T>;
  }
}

export default yup;