import { ExpandMore, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Accordion, AccordionSummary, ListItem, Box, Tooltip, IconButton, AccordionDetails, Chip } from "@mui/material"
import { Dispatch, FC, SetStateAction, useContext } from "react"
import ScrollableSection from "../scrollable-section"
import styles from "../styles"
import { IParentCustomer, IToasterProps } from "../.."
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../../context/customerSettingsContext"
import PreviewIcon from '@mui/icons-material/Preview';
import { IARCustomer, IARVendor } from "../../../../interfaces"
import { formatDate } from "../../../../utility/helper"
import NewTag from "../../../../components/common/new-tag"

export interface IProps extends IToasterProps {
  idx: number;
  parent: IParentCustomer;
  orphans?: IParentCustomer;
  arCustomerList: IARCustomer[];
  arVendorList: IARVendor[];
  filteredParentCustomers?: IParentCustomer[];
  setOrphan?: Dispatch<SetStateAction<IParentCustomer | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setItem: Dispatch<SetStateAction<IParentCustomer | undefined>>;
  setFilteredParentCustomers: Dispatch<SetStateAction<IParentCustomer[] | undefined>>;
  setParentCustomers: Dispatch<SetStateAction<IParentCustomer[] | undefined>>;
}

const CustomerAccordion: FC<IProps> = (props) => {
  const { canViewParentInformation }                          = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  /**
   * This function checks if the component should have a dark or light background color.
   * 
   * @param index The index of the component
   * @returns A boolean that determine if the component's index is odd or not.
   */
  const isOdd = (index: number) => index%2!==0;

  /**
   * This function optimizes the waiting time for a parent with large number of children to expand.
   * 
   * @param parent The Parent object to be expand.
   * @returns A transition timout props.
   */
  const getTimeout = (parent: IParentCustomer) => ({
    enter: parent.children?.length! > 50 ? 1000 : 250,
    exit: parent.children?.length! > 50 ? 500 : 250,
  });
  
  /**
   * This function set the specific parent state to expanded.
   * 
   * @param parentId The ID of the parent to be expanded.
   */
  const handleExpand = (parentId: number) => {
    if(props.parent.recordId === -1 && props.orphans && props.setOrphan){
      props.setOrphan({...props.orphans, isExpanded: !props.orphans.isExpanded})
      return
    }

    props.setFilteredParentCustomers(
      props.filteredParentCustomers?.map(item => {
        if (item.recordId === parentId && !Object.is(item.isExpanded, !item.isExpanded)) {
          return { ...item, isExpanded: !item.isExpanded };
        }
        return item;
      })
    );
  };

  return (
    <Accordion
      key={props.parent.recordId}
      expanded={props.parent.isExpanded}
      onChange={() => handleExpand(props.parent.recordId ?? 0)}
      elevation={0}
      disableGutters
      sx={{ bgcolor: isOdd(props.idx) ? 'background.paper' : '#F7F7F7', position: 'static' }}
      TransitionProps={{
        timeout: getTimeout(props.parent)
      }}
    >
      <AccordionSummary
        expandIcon={
        <>
        <Box sx={styles.newTagBox}>
          { props.parent.isNew && (
            <NewTag expanding />
          )}
        </Box>
        { props.parent.isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </>
      }
        sx={styles.accordionSummary}
      >
        <ListItem
          dense
          disablePadding
        >
          <Box sx={{...styles.halfWidth}}>
            {`${props.parent.custName} (${(props.parent.children?.length ?? 0) + (props.parent.vendors?.length ?? 0)})`}
            {(props.parent?.isCustParent || props.parent?.isUpcParent) && <Chip size='small' sx={styles.parentIdentifierChip} />}
          </Box>
          <Box sx={styles.halfWidth}>
            {props.parent.custSrcId ? `${props.parent.custSrcId}` : '-'}
          </Box>
          <Box sx={styles.halfWidth}>
            {props.parent.createdAt ? `${formatDate(props.parent.createdAt, 'MM/DD/YYYY')}` : '-'}
          </Box>
          <Box sx={{ ...styles.smallWidth }}>
            {props.parent.recordId !== -1 &&
              <Tooltip title='View Parent Details'>
                <IconButton tabIndex={-1} size='small' onClick={(e) => {
                  e.stopPropagation()
                  props.setItem(props.parent)
                  props.setOpen(true)
                }}>
                  <PreviewIcon sx={styles.iconAction} />
                </IconButton>
              </Tooltip>
            }
          </Box>
        </ListItem>
      </AccordionSummary>
      <AccordionDetails sx={styles.noPadding}>
        <Box sx={styles.overflowBox}>
          <ListItem sx={styles.listItem}>
            <Box sx={styles.headerSpacerBox} />
            <Box tabIndex={0} sx={{ ...styles.halfWidth, ...styles.paddingLeft }}>
              Accounts Name
            </Box>
            <Box tabIndex={0} sx={styles.halfWidth}>
              Accounts ID
            </Box>
            <Box tabIndex={0} sx={styles.halfWidth}>
              Date Created
            </Box>
            <Box tabIndex={0} sx={{ ...styles.smallWidth, ...(!canViewParentInformation && styles.invisible) }}>
              
            </Box>
          </ListItem>
          <ScrollableSection
            parent={props.parent}
            filteredParentCustomer={props.filteredParentCustomers} 
            arCustomerList={props.arCustomerList}
            arVendorList={props.arVendorList}
            setParentCustomers={props.setParentCustomers} 
            setFilteredParentCustomers={props.setFilteredParentCustomers} 
            setIsToasterOpen={props.setIsToasterOpen}
            isToasterOpen={props.isToasterOpen}
            setToasterMessage={props.setToasterMessage}
            toasterMessage={props.toasterMessage}
            setToasterSeverity={props.setToasterSeverity}    
            toasterSeverity={props.toasterSeverity}/>
        </Box>
      </AccordionDetails>
    </Accordion>
  )

}

export default CustomerAccordion
