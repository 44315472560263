import { Autocomplete, Box, Button, IconButton, TextField, Typography, Grid } from "@mui/material"
import { FieldArray, Form, Formik, FormikErrors, FormikTouched, getIn } from "formik"
import { ICollateral, ICondition, IRuleGroup, IFormikValue, IFormikValues, IRuleGroupAPI, ICollateralAPI, IRuleAPI, IRuleField, ICharacter } from "../../../../interfaces/collateralRuleInterface";
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react"
import { API_DOMAIN, DELETE, GET, POST, PUT, collateralRuleOperationList } from "../../../../utility/constants";
import { IARCollateral } from "../../../../interfaces";
import { getDocumentAttributesByCriteria } from "../../../../utility/helper";
import axiosInstance from "../../../../service/axiosInstance";
import AddOverrideButton from "./add-override-button";
import CloseIcon from '@mui/icons-material/Close';
import CollateralSVG from '../../../../assets/images/setupcollateral_loanwatch.svg';
import ConditionRow from "./condition-row";
import ConfirmModal from '../../../modals/confirm-modal';
import PopUpModal from "../../../modals/pop-up-modal";
import Prompt from "../../../modals/prompt";
import customRuleSchema from "../../../../schemas/customRulesSchema"
import styles from "./styles"
import dayjs from "dayjs";
import { IDocumentAttributePayload } from "../../../../interfaces/dataMap";
import { FileImportContext, IFileImportContext } from "../../../../context/fileImportContext";
import { IFileRowProps } from "../../../../pages/file-import/stepper/stepper-content/datamap-tab/file-row";
import { IDocument, IUploadedFile } from "../../../../interfaces/fileimport";
import DisabledComponentsContainer from "../../../common/disabled-components-container";

export interface ICollateralRuleModalProps extends IFileRowProps {
  showCollateralRule    : boolean,
  setShowCollateralRule : Dispatch<SetStateAction<boolean>>,
}

/**
 * This function parses a user-defined document attribute payload into a standardized format.
 * @param userDefinedField The user-defined document attribute payload to parse.
 * @returns An object containing standardized document attribute properties.
 */
export const parseDocumentAttribute = (userDefinedField: IDocumentAttributePayload) => {
  return {
    recordId: userDefinedField.documentAttributeId,
    documentTypeFk: userDefinedField.documentTypeId,
    tableField: userDefinedField.fieldName,
    description: userDefinedField.fieldName,
    fieldType: userDefinedField.fieldType,
  };
};

const tempCollateral: ICollateral = {
  id: -1,
  label: ''
}

const logicalOperatorMap = {
  'AND': true,
  'OR': false,
};

const tempInitialRule: IRuleGroup[] = []

/**
 * The component for the Collateral Rule Modal of the Data Map Step.
 * @param props The props for the Collateral Rule Modal of the Data Map Step.
 * @returns A component for the Collateral Rule Modal of the Data Map Step.
 */
const CollateralRuleModal: FC<ICollateralRuleModalProps> = (props) => {
  const {
    uploadedFile,
    showCollateralRule, setShowCollateralRule,
    showToaster
  }                                                              = props;
  const {
    selectedClient,
    uploadedFiles, setUploadedFiles,
  }                                                              = useContext(FileImportContext) as IFileImportContext;
  const [ARCollaterals, setARCollaterals]                        = useState<ICharacter[]>([]);
  const [ruleFields, setRuleFields]                              = useState<IRuleField[]>([]);
  const [_overrideARCollateral, setOverrideARCollateral]         = useState<ICharacter | null>(null);
  const [_defaultARCollateral, setDefaultARCollateral]           = useState<ICharacter | null>(null);
  const [defaultCollateral, setDefaultCollateral]                = useState<ICollateral>({ id: uploadedFile?.arCollateralFk ?? 0, label: uploadedFile.arCollateralName ?? ''});
  const [customRules, setCustomRules]                            = useState<IRuleGroup[]>(tempInitialRule);
  const [isDirty, setIsDirty]                                    = useState<boolean>(false);
  const [openModal, setOpenModal]                                = useState<boolean>(false);
  const [ruleGroupsToBeDeleted, setRuleGroupsToBeDeleted]        = useState<IRuleGroup[]>([]);
  const [rulesToBeDeleted, setRulesToBeDeleted]                  = useState<ICondition[]>([]);

  /**
   * This effect handles fetching collateral rule fields and building custom rules based on certain conditions.
   */
  useEffect(() => {
    const fetchStuff = async() => {
      if(showCollateralRule){
        const ruleFields = await getAllCollateralRuleFields();
        getARCollaterals();
        buildCustomRules(ruleFields);
       }
    }
    fetchStuff();
  }, [uploadedFile, showCollateralRule])

  const initialExpression: ICondition = {
    id: -1,
    fieldName:  '',
    operator:   '',
    value:      [],
  };
  const initialRule : IRuleGroup = {
    id: -1,
    expression: [initialExpression],
    collateral: tempCollateral,
    order: 1
  };

  /**
   * This function retrieves all collateral rule fields, including user-defined fields, and returns them as an array.
   * @returns A Promise that resolves to an array containing all collateral rule fields.
   */
  const getAllCollateralRuleFields = async () => {
    try {
      const docTypeId = uploadedFile.documentType === 'AR Aging' ? 1 : 2;
      const [ruleFieldResponse, userDefinedFieldResponse] = await Promise.all([
        axiosInstance.request({
          url: `${API_DOMAIN}/collateralRuleField`,
          method: GET,
        }),
        getDocumentAttributesByCriteria(selectedClient?.recordId ?? 0, docTypeId),
      ]);
      const responseData = [
        ...ruleFieldResponse.data.content,
        ...userDefinedFieldResponse.data.map(parseDocumentAttribute),
      ];
      setRuleFields(responseData);
      return responseData;
    } catch (error) {
      console.log('GET ALL COLLATERAL RULE FIELDS ERROR:',error);
      return [];
    }
  }

  /**
   * This function retrieves active AR collaterals for the selected client and updates state variables accordingly.
   * @returns Early return if the selected client record id is undefined.
   */
  const getARCollaterals = async () => {
		if (selectedClient?.recordId === undefined) { return; }
    const collaterals = await getActiveCollaterals(selectedClient.recordId);
      
			setARCollaterals(collaterals.map((arCollateral: IARCollateral) => {
				return { label: arCollateral.arCollateralName, id: arCollateral.recordId }
			}))
      const defaultCollateral = collaterals.filter((arCollateral : IARCollateral) => arCollateral.recordId === uploadedFile.arCollateralFk);
      setDefaultCollateral({
        label: defaultCollateral[0]?.arCollateralName,
        id: defaultCollateral[0]?.recordId
      });
	};

  /**
   * This function retrieves active AR collaterals for a specified borrower and returns them as an array.
   * @param borrowerId The ID of the borrower for whom to retrieve collaterals.
   * @returns A Promise that resolves to an array containing active AR collaterals.
   */
  const getActiveCollaterals = async (borrowerId: number) => {
    const activeCollaterals = await axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals/search/findIsArchived`,
      method: GET,
      params: { borrowerId: borrowerId, pageNo: 0, pageSize: 9999, sortBy: 'arCollateralName,ASC', isDefault: true, isArchived: false }
    }).then(response => response.data.content as IARCollateral[]);
    const mappedCollaterals = await Promise.all(activeCollaterals.map(async (collateral: IARCollateral) => {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/ar/borrowerInputs/search/findByARCollateralId`,
        method: GET,
        params: { arCollateralId: collateral.recordId, pageNo: 0, pageSize: 9999, isCurrent: true }
      });
      if (response.data.content.length === 0){ return null; }
      return collateral;
    }));
    return mappedCollaterals.filter(collateral => collateral !== null) as IARCollateral[];
  };

  /**
   * This function retrieves a specific AR collateral by its ID.
   * @param arCollateralId The ID of the AR collateral to retrieve.
   * @returns A Promise that resolves to the AR collateral data or null if not found.
   */
  const getCollateralById = async (arCollateralId : number) => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/ar/collaterals/${arCollateralId}`,
        method: GET,
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * This function retrieves the field name description based on the field and document type.
   * @param field The field for which to retrieve the description.
   * @param ruleFields An array of rule fields.
   * @returns The description of the field name, or an empty string if not found.
   */
  const getFieldName = (field : string, ruleFields: IRuleField[]) => {
    let fieldName = ''; 
    switch(uploadedFile.documentType) {
      case "Customer List":
        fieldName = ruleFields.filter(ruleField => ruleField.documentTypeFk === 2 && ruleField.tableField === field)[0]?.description;
        break;
      case "AR Aging":
        fieldName = ruleFields.filter(ruleField => ruleField.documentTypeFk === 1 && ruleField.tableField === field)[0]?.description;
        break;
    }
    return fieldName;
  }

  /**
   * This function builds custom rules based on the provided rule fields and collateral rule groups.
   * @param ruleFields An array of rule fields.
   * @returns Early return if the collateral rule groups array is empty.
   */
  const buildCustomRules = async (ruleFields: IRuleField[]) => {  
    try {
      const collateralRuleGroups : IRuleGroupAPI[] = await getAllCollateralRuleGroups();
      if (!collateralRuleGroups?.length) {
        setCustomRules(tempInitialRule);
        return;
      };
      const ruleGroups : IRuleGroup[] = await Promise.all(collateralRuleGroups.map(async ruleGroup => {
        const collateral : ICollateralAPI = await getCollateralById(ruleGroup.arCollateralFk);
        const rules : IRuleAPI[] = await getAllCollateralRulesByRuleGroupId(ruleGroup.recordId)
        const expressions = rules.map(rule => {
          const operator = collateralRuleOperationList.filter(operation => operation.id === rule.operation)
          return {
            id: rule.recordId,
            fieldName: getFieldName(rule.field, ruleFields),
            operator: operator[0].label,
            value: rule.fieldValues,
            secondOperator: logicalOperatorMap[rule.logicalOperator]
          }
        })
        return {
          id: ruleGroup.recordId,
          expression: expressions.length > 0 ? expressions : [initialExpression],
          collateral: {
            id: collateral.recordId,
            label: collateral.arCollateralName
          },
          order: ruleGroup.order
        }
      }))
      // sort custom rules by order
      setCustomRules([...ruleGroups].sort((a, b) => a.order - b.order));
    } catch (error) {
      console.log("BUILD CUSTOM RULES ERROR: ", error)
    }
  }

  /**
   * This function creates a new collateral rule group and associates it with an AR collateral, a document, and a borrower.
   * @param arCollateralName The name of the AR collateral to associate with the rule group.
   * @param index The index of the rule group.
   * @returns A Promise that resolves to the created rule group data or null if an error occurs.
   */
  const createCollateralRuleGroup = async (arCollateralName : string, index: number) => {
    try {
      const arCollateral = ARCollaterals.find(collateral => {
        return collateral.label === arCollateralName;
      })
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/collateralRuleGroup`,
        method: POST,
        data: {
          arCollateralFk: arCollateral?.id,
          documentFk: uploadedFile.recordId,
          borrowerFk: uploadedFile.borrowerFk,
          order: index + 1
        }
      });
      (response.status === 201) && showToaster('success', 'Criteria Changes have been saved.');
      return response.data;
    } catch (error) {
      console.log('CREATE COLLATERAL RULE GROUP ERROR: ', error);
      showToaster('error', 'Failed to save criteria. Please try again.');
    }
  };

  /**
   * This function updates a collateral rule group with the specified AR collateral name, rule group ID, and index.
   * @param arCollateralName The name of the AR collateral to associate with the rule group.
   * @param ruleGroupId The ID of the rule group to update.
   * @param index The index for the updated rule group.
   * @returns A Promise that resolves to the updated rule group data or null if an error occurs.
   */
  const updateCollateralRuleGroup = async (arCollateralName : string, ruleGroupId : number, index: number) => {
    try {
      const arCollateral = ARCollaterals.find(collateral => {
        return collateral.label === arCollateralName;
      })
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/collateralRuleGroup/${ruleGroupId}`,
        method: PUT,
        data: {
          recordId: ruleGroupId,
          arCollateralFk: arCollateral?.id,
          documentFk: uploadedFile.recordId,
          borrowerFk: uploadedFile.borrowerFk,
        }
      });
      (response.status === 200) && showToaster('success', 'Criteria Changes have been saved.');
      (response.status === 200) && setShowCollateralRule(false);
      return response.data;
    } catch (error) {
      console.log('UPDATE COLLATERAL RULE GROUP ERROR: ', error);
      showToaster('error', 'Failed to save criteria. Please try again.');
    }
  };

  /**
   * This function deletes a collateral rule group with the specified rule group ID.
   * @param ruleGroupId The ID of the rule group to delete.
   */
  const deleteCollateralRuleGroup = async (ruleGroupId : number) => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/collateralRuleGroup/${ruleGroupId}`,
        method: DELETE
      });
      (response.status === 200) && setRuleGroupsToBeDeleted([]);
      (response.status === 200) && showToaster('success', 'Criteria Changes have been saved.');
    } catch (error) {
      console.log('DELETE COLLATERAL RULE GROUP ERROR: ', error);
      showToaster('error', 'Failed to save criteria. Please try again.');
    }
  };

  /**
   * This function retrieves all collateral rule groups associated with the currently uploaded document.
   * @returns A Promise that resolves to an array of collateral rule groups.
   */
  const getAllCollateralRuleGroups = async () => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/collateralRuleGroup/search/findByDocumentId`,
        method: GET,
        params: {documentId: uploadedFile.recordId}
      });
      return response.data.content;
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * This function retrieves all collateral rules associated with a specific collateral rule group.
   * @param ruleGroupId The ID of the collateral rule group for which to retrieve rules.
   * @returns A Promise that resolves to an array of collateral rules.
   */
  const getAllCollateralRulesByRuleGroupId = async (ruleGroupId : number) => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/collateralRule/search/findByCollateralRuleGroupId`,
        method: GET,
        params: {collateralRuleGroupId: ruleGroupId}
      });
      return response.data.content;
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * This function creates a new collateral rule using the provided partial collateral rule data.
   * @param collateralRule Partial data for the new collateral rule.
   */
  const createCollateralRule = async (collateralRule : Partial<IRuleAPI>) => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/collateralRule`,
        method: POST,
        data: collateralRule
      });
      (response.status === 201) && showToaster('success', 'Criteria Changes have been saved.');
    } catch (error) {
      console.log('CREATE COLLATERAL RULE ERROR: ', error);
      showToaster('error', 'Failed to save criteria. Please try again.');
    }
  };

  /**
   * This function updates an existing collateral rule using the provided partial collateral rule data.
   * @param collateralRule Partial data for the collateral rule to update.
   */
  const updateCollateralRule = async (collateralRule : Partial<IRuleAPI>) => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/collateralRule/` + collateralRule.recordId,
        method: PUT,
        data: collateralRule
      });
      (response.status === 200) && showToaster('success', 'Criteria Changes have been saved.');
    } catch (error) {
      console.log('UPDATE COLLATERAL RULE ERROR: ', error);
      showToaster('error', 'Failed to save criteria. Please try again.');
    }
  };

  /**
   * This function deletes a collateral rule with the specified collateral rule ID.
   * @param collateralRuleId The ID of the collateral rule to delete.
   */
  const deleteCollateralRule = async (collateralRuleId : number) => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/collateralRule/` + collateralRuleId,
        method: DELETE
      });
      if (response.status === 200) {
        setRulesToBeDeleted([]);
        showToaster('success', 'Criteria Changes have been saved.');
      }
    } catch (error) {
      console.log('DELETE COLLATERAL RULE ERROR: ', error);
      showToaster('error', 'Failed to save criteria. Please try again.');
    }
  };

  /**
   * This function updates a document with the specified data and AR collateral ID.
   * @param document The document to update.
   * @param arCollateralId The ID of the AR collateral to associate with the document.
   * @returns A Promise that resolves to the updated document data or null if an error occurs.
   */
  const updateDocument = async (document : IUploadedFile, arCollateralId : number) => {
    try {
      let data : IUploadedFile = {
        ...document,
        arCollateralFk: arCollateralId
      }
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/document/` + document.recordId,
        method: PUT,
        data: data
      });
      return response.data;
    } catch (e) {
      console.log(e)
    }
  };

  /**
   * This function determines the data type of a field based on its name and values.
   * @param fieldName The name of the field. 
   * @param values An array of values associated with the field.
   * @returns The data type of the field ('number', 'date', or 'string').
   */
  const getFieldType = (fieldName : string, values : string[]) => {
    let type;
    switch (fieldName) {
      case 'Credit Limit':
      case 'Amount':
        type = 'number';
        break;
      case 'Invoice Date':
      case 'Due Date':
        if (dayjs(values[0]).isValid()) {
          type = 'date';
        } else {
          type = 'string';
        }
        break;
      default:
        type = 'string';
        break;
    }
    return type;
  }

  /**
   * This function retrieves the ID of a collateral rule operation based on its label.
   * @param operatorLabel The label of the collateral rule operation.
   * @returns The ID of the collateral rule operation or null if not found.
   */
  const getOperation = (operatorLabel : string) => {
    const operation = collateralRuleOperationList.filter(operation => operation.label === operatorLabel);
    return operation[0].id;
  }

  /**
   * This function determines the table associated with a field based on the field's name and the document type.
   * @param field The name of the field.
   * @returns The name of the table associated with the field. 
   */
  const getFieldTable = (field : string) => {
    let fieldTable; 
    switch(uploadedFile.documentType) {
      case "Customer List":
        fieldTable = "ARCustomer";
        break;
      case "AR Aging":
        fieldTable = "ARTransaction";
        break;
    }
    return fieldTable;
  }

  /**
   * This function retrieves the table field associated with a field name based on the document type.
   * @param fieldName The name of the field.
   * @returns The table field associated with the field name.
   */
  const getField = (fieldName : string) => {
    let field = ''; 
    switch(uploadedFile.documentType) {
      case "Customer List":
        field = ruleFields.filter(field => field.documentTypeFk === 2 && field.description === fieldName)[0].tableField;
        break;
      case "AR Aging":
        field = ruleFields.filter(field => field.documentTypeFk === 1 && field.description === fieldName)[0].tableField;
        break;
    }
    return field;
  }

  /**
   * This function retrieves the logical operator based on the provided expression's secondOperator value.
   * @param expression The expression containing the secondOperator value.
   * @returns The logical operator ('AND', 'OR') or undefined if secondOperator is not defined.
   */
  const getLogicalOperator = (expression: ICondition) => {
    let operator: string | undefined;
    if (expression.secondOperator === undefined) {
      operator = undefined;
    } else {
      operator = expression.secondOperator ? 'AND' : 'OR';
    }

    return operator;
  }

  /**
   * This function handles the submission of form values, including creating, updating, and deleting collateral rules and rule groups.
   * @param values The form values containing custom rules and other data.
   */
  const handleSubmit = async (values : IFormikValues, setSubmitting: (value: boolean) => void) => {
    await Promise.all(values.customRules.map(async (rule,index) => {
      if (rule.id === -1) {
        const createdGroup = await createCollateralRuleGroup(rule.collateral.label, index);
        rule.expression.forEach((expression, index) => {
          const field = getField(expression.fieldName);
          const rule = {
            collateralRuleGroupFk: createdGroup.recordId,
            arCollateralFk: createdGroup.arCollateralFk,
            fieldTable: getFieldTable(field),
            fieldType: getFieldType(expression.fieldName, expression.value),
            field: field,
            operation: getOperation(expression.operator),
            fieldValues: expression.value,
            logicalOperator: getLogicalOperator(expression),
            order: index + 1,
          }
          createCollateralRule(rule);
        })
      } else {
        const updatedGroup = await updateCollateralRuleGroup(rule.collateral.label, rule.id,index);
        rule.expression.forEach((expression, index) => {
          const field = getField(expression.fieldName);
          const rule = {
            collateralRuleGroupFk: updatedGroup.recordId,
            arCollateralFk: updatedGroup.arCollateralFk,
            fieldTable: getFieldTable(field),
            fieldType: getFieldType(expression.fieldName, expression.value),
            field: field,
            operation: getOperation(expression.operator),
            fieldValues: expression.value,
            logicalOperator: getLogicalOperator(expression),
            order: index + 1,
          }
          if (expression.id !== -1) {
            const updatedRule = {
              recordId: expression.id,
              ...rule
            }

            updateCollateralRule(updatedRule)
          } else {
            createCollateralRule(rule);
          }
        })
      }
    }));
    ruleGroupsToBeDeleted.forEach(ruleGroup => {
      ruleGroup.expression.forEach(rule => {
        rule.id !== -1 && deleteCollateralRule(rule.id);
      });
      deleteCollateralRuleGroup(ruleGroup.id);
    })
    rulesToBeDeleted.forEach(rule => {
      deleteCollateralRule(rule.id);
    })
       
    await updateDocumentCollateral(values);
    await buildCustomRules(ruleFields);
    setSubmitting(false);
    setShowCollateralRule(false);
  };

  /**
   * This function updates the AR collateral association for the uploaded document and updates related data.
   * @param values The form values containing the default collateral information.
   */
  const updateDocumentCollateral = async (values: IFormikValues) => {
    const updatedDocument: IDocument = await updateDocument(uploadedFile, values.defaultCollateral.id);
    updatedDocument && setDefaultCollateral(values.defaultCollateral);
    
    const updated = uploadedFiles.map(uploaded => {
      if (uploaded.recordId === updatedDocument.recordId) {
        const matchedCollateral = ARCollaterals.find(collateral => collateral.id === updatedDocument.arCollateralFk);
        uploaded.arCollateralFk = matchedCollateral?.id ?? 0;
        uploaded.arCollateralName = matchedCollateral?.label;
        return uploaded;
      }
      return uploaded;
    });
    setUploadedFiles(updated);
  }

  /**
   * This function retrieves and formats the error message and helper text for a form field based on Formik errors and touched status.
   * @param errors The Formik errors object.
   * @param touched The Formik touched object.
   * @param name The name of the field to retrieve errors for.
   * @param index The index of the field in the customRules array.
   * @param index2 Optional. The index of the field within an expression if applicable.
   * @returns An object containing the error and helperText properties, or null if no error is found.
   */
  const getDisplayedError = (errors: FormikErrors<IFormikValue>, touched: FormikTouched<IFormikValue>, name: string, index: number) => {

    const fieldError =  getIn(errors, `customRules[${index}].${name}.label`);
    const isFieldTouched = getIn(touched, `customRules[${index}].${name}.label`);;

    if (fieldError && isFieldTouched) { return { error: true, helperText: fieldError }}
    
    return null
  };

  /**
   * This function is a callback for the Formik ref attribute. It updates the state with the dirty status of the Formik form.
   * @param node The Formik node or instance.
   */
  const formikRef = (node : any) => {
    if (node !== null) {
      setIsDirty(node.dirty)
    }
  };

  /**
   * This function discards changes made in the form by resetting various states and closing the collateral rule modal.
   */
  const discardChanges = () => {
    setShowCollateralRule(false);
    setCustomRules(tempInitialRule);
    setOpenModal(false);
    setIsDirty(false);
  };

  /**
   * This function allows the user to continue editing by closing a modal and showing the collateral rule form.
   */
  const keepEditing = () => {
    setOpenModal(false);
    setShowCollateralRule(true);
  };

  /**
   * This function checks if there are unsaved changes (dirty state) and takes appropriate actions, such as showing a modal or resetting the collateral rule form.
   */
  const dirtyChecker = () => {
    if (isDirty === true) {
      setOpenModal(true);
    } else {
      setShowCollateralRule(false);
      setCustomRules(tempInitialRule);
    }
  };

  return (
    <PopUpModal
      open={showCollateralRule}
      onClose={dirtyChecker}
      isComponentEditable={false}
      isEditableTrue={true}
      title1='Setup Collateral'
      width='1330px'
    >
      <Box sx={styles.mainContainer}>
        <Formik
          enableReinitialize
          validateOnBlur
          validateOnChange
          innerRef={formikRef}
          initialValues={{customRules, defaultCollateral}}
          validationSchema={customRuleSchema}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        >{(formik) => (
            <Form>
                {/* default collateral, else statement */}
                <Grid container sx={styles.defaultCollateral}>
                  <Box sx={{...styles.displayImage, backgroundImage: `url(${CollateralSVG})`}}/>
                  <Box sx={styles.infoText}>
                  </Box>
                  <Box sx={styles.defaultCollateralInputBox}>
                    <Box>
                      <Typography fontWeight='bold'>Default Settings</Typography>
                    </Box>
                    <Autocomplete
                      disablePortal
                      ListboxProps={{style: styles.defaultSettingsListBox}}
                      id={'defaultCollateral'}
                      blurOnSelect
                      onBlur={formik.handleBlur}
                      value={formik.values.defaultCollateral}
                      onChange={(e,value) => {
                        formik.setFieldValue('defaultCollateral', value);
                        setDefaultARCollateral(value);
                        setTimeout(() => {
                          formik.setFieldTouched('defaultCollateral', true)
                        }, 0);
                      }}
                      options={ARCollaterals}
                      noOptionsText={'No Collateral available'}
                      renderOption={(prop, option) => {
                        return (
                          <Box component='li' {...prop} key={option.id} sx={styles.defaultSettingsItem}>
                            {option.label}
                          </Box>
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          inputProps={{ ...params.inputProps, 'aria-label': 'Default Settings Dropdown', 'data-testid': 'default-collateral'}}
                          error={formik.touched.defaultCollateral?.label && Boolean(formik.errors.defaultCollateral?.label)}
                          helperText={formik.touched.defaultCollateral?.label && formik.errors.defaultCollateral?.label}
                          placeholder="Collateral"
                          variant="outlined"
                          sx={{...styles.autocompleteField}}
                        />
                      )}
                      componentsProps={{
                        popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                        clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}
                      }}
                    />
                  </Box>
                </Grid>
                {/* overides */}
                <FieldArray name='customRules'>
                  {(customRulesHelpers) => (
                    <Box>
                      {formik.values.customRules?.map((customRule, idx) =>
                        <Box key={customRule.id}>
                          <Box sx={styles.customRuleBox}> 
                            <Box >
                              {idx === 0 &&
                                <Grid container sx={styles.overrideHeader}>
                                  <Grid xs={9}>
                                    <Typography tabIndex={0} fontWeight={'bold'}>Override Criteria</Typography>
                                  </Grid>
                                  <Grid xs={3}>
                                    <Typography tabIndex={0} fontWeight={'bold'}>Override Settings</Typography>
                                  </Grid>
                                </Grid>
                              }
                              <Grid container sx={styles.removeIconGrid}>
                                <IconButton
                                  aria-label="Close icon"
                                  sx={styles.removeIconButton}
                                  onClick={() => {
                                    customRulesHelpers.remove(idx);
                                    (customRule.id !== -1) && setRuleGroupsToBeDeleted([...ruleGroupsToBeDeleted, customRule]);
                                  }}
                                >
                                  <CloseIcon fontSize='small' sx={styles.removeIconButton}/>
                                </IconButton>
                              </Grid>
                            </Box>
                            {/* map expressions on current custom rule */}
                            <FieldArray name={`customRules[${idx}].expression`}>
                              {(expressionHelpers) => (
                              <Grid container sx={styles.expressionParentBox}>
                                <Grid xs={9} sx={styles.expressionBox}>
                                {formik.values.customRules[idx].expression.map((expression, idx2) =>
                                  <ConditionRow
                                    key={expression.id}
                                    idx={idx}
                                    idx2={idx2}
                                    initialExpression={initialExpression}
                                    expressionHelpers={expressionHelpers}
                                    expression={expression}
                                    formik={formik}
                                    arCollateral={formik.values.customRules[idx].collateral}
                                    rulesToBeDeleted={rulesToBeDeleted}
                                    setRulesToBeDeleted={setRulesToBeDeleted}
                                    {...props}
                                  />
                                )}
                                </Grid>
                                {/* return collateral */}
                                <Grid xs={3} sx={styles.overrideSettings}>
                                    <Autocomplete
                                      disablePortal
                                      ListboxProps={{style: {maxHeight: 200}}}
                                      id={`customRules[${idx}].collateral`}
                                      blurOnSelect
                                      value={formik.values.customRules[idx].collateral}
                                      onBlur={formik.handleBlur}
                                      onChange={(e,value) => {
                                        formik.setFieldValue(`customRules[${idx}].collateral`, value)
                                        setOverrideARCollateral(value);
                                      }}
                                      options={ARCollaterals}
                                      noOptionsText={'No Collateral available'}
                                      renderOption={(prop, option) => {
                                        return (
                                          <Box component='li' {...prop} key={option.id} sx={styles.overrideCollateralDropdown}>
                                            {option.label}
                                          </Box>
                                        );
                                      }}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          {...getDisplayedError(formik.errors, formik.touched, 'collateral', idx)}
                                          inputProps={{ ...params.inputProps, 'aria-label': 'Collateral Dropdown', 'data-testid': `override-collateral-${idx}` }}
                                          placeholder="Collateral"
                                          variant="outlined"
                                          sx={{...styles.autocompleteField}}
                                          
                                        />
                                      )}
                                      componentsProps={{
                                        popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                                        clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}
                                      }}
                                    />
                                </Grid>
                              </Grid>
                              )}
                            </FieldArray>
                          </Box>
                        </Box>
                      )}  
                      <AddOverrideButton
                       customRulesHelpers={customRulesHelpers}
                       initialRule={initialRule}
                      />
                    </Box>
                  )}
                </FieldArray>
                {/* action buttons */}
                <Grid container sx={styles.actionButtonBox}>
                  <Box sx={styles.actionContainer}>
                    <Button
                      variant='outlined'
                      sx={styles.cancelButton}
                      onClick={() => {
                        setShowCollateralRule(false);
                        setCustomRules(tempInitialRule);
                      }}
                      data-testid='cancel-button'
                    >
                      Cancel
                    </Button>
                    <DisabledComponentsContainer isDisabled={!(formik.isValid && formik.dirty)}>
                      <Button
                        disabled={!(formik.isValid && formik.dirty)}
                        aria-label={!(formik.isValid && formik.dirty) ? 'Save changes button disabled' : 'Save changes'}
                        variant='contained'
                        sx={styles.saveButton}
                        type='submit'             
                        data-testid='save-button'
                      >
                        Save Changes
                      </Button>
                    </DisabledComponentsContainer>
                  </Box>
                </Grid>
            </Form>
        )}
        </Formik>

        <Prompt when={isDirty} isEditing={setIsDirty}/>
        {/* Confirm modal when there are unsaved changes */}
        <ConfirmModal
          open={openModal}
          onClose={discardChanges}
          onConfirm={keepEditing}
          onButtonClose={keepEditing}
          promptChecker={true}
          title={`Confirm Navigation`}
          description={
            `You have unsaved changes. Are you sure you want to leave this page?`
          }
          yesButtonText='Keep Editing'
          noButtonText='Discard Changes'
        />
      </Box>
    </PopUpModal>
  )
}

export default CollateralRuleModal