import yup from './index';

const otherLoanTypes = [
  'Purchase Order',
  'Secured Cash',
  'Stock Portfolio Assignment',
];

const otherLoanSettingsSchema = yup.object().shape({
  otherLoans: yup
  .array()
  .of(yup.object().shape({
    collateralName: yup.string().trim().required('Collateral is required'),
    type: yup.string()
      .oneOf(otherLoanTypes, 'Select a valid collateral type')
      .required('Type is required'),
    advanceRate: yup.number()
      .required('Advance Rate is required')
      .typeError('Advance Rate must be valid')
      .min(0, 'Cannot be below 0')
      .max(100, 'Cannot exceed 100'),
  }))
  .uniqueProperty('collateralName', 'Collateral must be unique')
})

export default otherLoanSettingsSchema;
