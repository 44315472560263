import React from 'react';
import { Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import styles from './styles';

interface ICustomizeReportDrawerProps {
  isOpen: boolean;
  onCloseChange: () => void;
}

const CustomizeReportDrawer: React.FC<ICustomizeReportDrawerProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState(props.isOpen);

  /**
   * This method is responsible for closing a possibly open element or component.
   */
  const handleClose = () => {
    setIsOpen(false);
    props.onCloseChange();
  };

  /**
   * This useEffect hook syncs the 'isOpen' local state with the 'isOpen' prop.
   */
  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={handleClose}
    >
      <Box sx={styles.mainContainer}>
        <Box>
          <Box sx={styles.headingContainer}>
            <Typography sx={styles.heading}>Customize Report</Typography>
            <IconButton onClick={handleClose} >
              <CloseRoundedIcon sx={styles.closeIcon} />
            </IconButton>
          </Box>
          <FormControl>
            <FormLabel id='number-format-radio-buttons-group-label' sx={styles.groupLabel}>Number Format</FormLabel>
            <RadioGroup
              aria-labelledby='number-format-radio-buttons-group-label'
              name='number-format-radio-buttons-group'
              sx={styles.radioGroup}
            >
              <FormControlLabel value='omitZeroes' control={<Radio sx={styles.radioButton} />} label='Omit 000’s' sx={styles.label} />
              <FormControlLabel value='withoutCents' control={<Radio sx={styles.radioButton} />} label='Without cents' sx={styles.label} />
              <FormControlLabel value='exceptZeroAmount' control={<Radio sx={styles.radioButton} />} label='Except zero amount' sx={styles.label} />
            </RadioGroup>
            <FormLabel id='negative-numbers-format-radio-buttons-group-label' sx={styles.groupLabel}>Negative Numbers</FormLabel>
            <RadioGroup
              aria-labelledby='negative-numbers-format-radio-buttons-group-label'
              name='negative-numbers-format-radio-buttons-group'
            >
              <FormControlLabel value='prependDash' control={<Radio sx={styles.radioButton} />} label='-100' sx={styles.label} />
              <FormControlLabel value='parenthesis' control={<Radio sx={styles.radioButton} />} label='(100)' sx={styles.label} />
              <FormControlLabel value='appendDash' control={<Radio sx={styles.radioButton} />} label='100-' sx={styles.label} />
            </RadioGroup>
            <FormControlLabel
              control={<Checkbox sx={styles.checkbox} />}
              label='Show it in Red'
              sx={{ ...styles.label, ...styles.checkboxContainer }} />
          </FormControl>
        </Box>
        <Box sx={styles.buttonsContainer}>
          <Button variant='text' size='small' sx={styles.clearSelectionButton}>Clear Selection</Button>
          <Box>
            <Button variant='outlined' size='small' onClick={handleClose} >Cancel</Button>
            <Button variant='contained' size='small' sx={styles.saveButton}>Save</Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CustomizeReportDrawer;
