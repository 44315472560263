import React, { useContext } from 'react';
import { Box, Container, Grid, Tab, Tabs } from '@mui/material';
import { NavigationWarningContext, INavigationWarningContext } from '../../../context/navigationWarningContext';
import { ITabData, TabPanelProps } from '../../../interfaces/ineligibleAdjustmentsInterface';
import AccountsReceivablePanel from './tab-panel-contents/accounts-receivable';
import InventoryPanel from './tab-panel-contents/inventory';
import ConfirmModal from '../../modals/confirm-modal';
import styles from './styles';

const tabData: ITabData[] = [
  { label: 'AR', component: <AccountsReceivablePanel /> },
  { label: 'Inventory', component: <InventoryPanel /> },
];

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`ineligible-adjustments-tabpanel-${index}`}
      aria-labelledby={`ineligible-adjustments-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={styles.tabPanelBox}>{children}</Box>}
    </div>
  );
};

const tabProps = (index: number) => {
  return {
    id: `ineligible-adjustments-tab-${index}`,
    'aria-controls': `ineligible-adjustments-tabpanel-${index}`,
  };
};

const IneligibleAdjustmentsTabs: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [newTabIndex, setNewTabIndex] = React.useState(0);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const { isDirty, setIsDirty } = useContext(NavigationWarningContext) as INavigationWarningContext;

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setNewTabIndex(newValue);
    if(isDirty){
      setShowPrompt(true);
      return;
    }
    setTabIndex(newValue)
  };

  const switchTabCallback = () => {
    setTabIndex(newTabIndex);
    setIsDirty(false);
    setShowPrompt(false);
  };

  return (
    <Container maxWidth='xl'>
      <Grid container alignItems='center' gap={3}>
        <Box sx={styles.tabBox}>
          <Box>
            <Tabs
              sx={styles.buttonTabs}
              value={tabIndex}
              onChange={handleChange}
              aria-label='ineligible adjustments tab'
            >
              {tabData.map((tab, index) => <Tab tabIndex={0} label={tab.label} key={tab.label} {...tabProps(index)} /> )}
            </Tabs>
          </Box>
          {tabData.map((tab, index) => (
            <TabPanel value={tabIndex} key={tab.label} index={index}>
              {tab.component}
            </TabPanel>
          ))}
        </Box>
      </Grid>
      <ConfirmModal
        open={showPrompt}
        onClose={() => { switchTabCallback(); }}
        onConfirm={() => { setShowPrompt(false); }}
        onButtonClose={() => { setShowPrompt(false); }}
        promptChecker
        title={`Confirm Navigation`}
        description={`You have unsaved changes. Are you sure you want to leave this page?`}
        yesButtonText={`Keep Editing`}
        noButtonText={`Discard Changes`}
        confirmOnly
      />
    </Container>
  );
};

export default IneligibleAdjustmentsTabs;
