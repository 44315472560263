import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { LayoutProps } from './authContext';
import { IOption } from '../interfaces/comboBox';
import { IUploadedFile } from '../interfaces/fileimport';
import axiosInstance from '../service/axiosInstance';
import { fileImportAPI } from '../service/api';
import { GET } from '../utility/constants';
import { getDocumentAttributesByBorrowerId, getFileExtension } from '../utility/helper';
import { IDocumentAttribute } from '../interfaces/dataMap';
import { useLocation } from 'react-router-dom';

export interface IFileImportContext {
  selectedClient        : IOption | null;
  setSelectedClient     : Dispatch<SetStateAction<IOption | null>>;
  clientInput           : string;
  setClientInput        : Dispatch<SetStateAction<string>>;
  uploadedFiles         : IUploadedFile[];
  setUploadedFiles      : Dispatch<SetStateAction<IUploadedFile[]>>;
  userDefinedFields     : IDocumentAttribute[];
  setUserDefinedFields  : Dispatch<SetStateAction<IDocumentAttribute[]>>;
  recentUploadedFiles   : IUploadedFile[];
  isPageLoading         : boolean;
  setIsPageLoading      : Dispatch<SetStateAction<boolean>>;
  hasLoadingUploaded    : boolean;
  hasLoadingRecent      : boolean;
  isInLandingPage       : boolean;
}

export const FileImportContext = createContext<IFileImportContext | null>(null);

const FileImportProvider = (props: LayoutProps) => {
  const { children } = props;
  const location                                      = useLocation();
  const [selectedClient, setSelectedClient]           = useState<IOption | null>(null);
  const [clientInput, setClientInput]                 = useState<string>('');
  const [uploadedFiles, setUploadedFiles]             = useState<IUploadedFile[]>([]);
  const [isPageLoading, setIsPageLoading]             = useState<boolean>(false);
  const [userDefinedFields, setUserDefinedFields]     = useState<IDocumentAttribute[]>([]);
  const isInLandingPage                               = useMemo(() => location.pathname.includes('/file-import'), [location]);

  useEffect(() => {
    if (!selectedClient) return;

    (async () => {
      setIsPageLoading(true);
      await getUploadedFiles(selectedClient.recordId);
      await getUserDefined(selectedClient.recordId);
      setIsPageLoading(false);
    })();
  }, [selectedClient]);

  const getUserDefined = async (borrowerId: number) => {
    const udf  = await getDocumentAttributesByBorrowerId(borrowerId);
    setUserDefinedFields(udf.data);
  }

  const getUploadedFiles = async (borrowerId: number) => {
    try {
      const response = await axiosInstance.request({
        url: isInLandingPage ? fileImportAPI.uploadedFile.GET_PROCESSED_FILES_BY_BORROWER_ID : fileImportAPI.uploadedFile.GET_UNPROCESSED_FILES_BY_BORROWER_ID,
        method: GET,
        params: { borrowerId, sortBy: 'recordId,DESC' },
      })
      const uploadedFiles: IUploadedFile[] = response.data.content;
      const loading = uploadedFiles.map(uploaded => {
        uploaded.isLoading = true;
        uploaded.isParsing = true;
        uploaded.isUploaded = true;

        const fileExtension = getFileExtension(uploaded.filename);
        uploaded.isExcelFile = (fileExtension === 'xlsx' || fileExtension === 'xls');
        return uploaded;
      });
      setUploadedFiles(loading);
    } catch (error) {
      console.log('GETTING UNPROCESSED DOCUMENTS ERROR: ', error);
      setUploadedFiles([]);
    }
  };

  const recentUploadedFiles = useMemo(() => {
    return uploadedFiles.filter(file => file.isNewlyUploaded)
  }, [uploadedFiles])

  const hasLoadingUploaded = useMemo(() => {
    return uploadedFiles.some(file => Boolean(file.isParsing) || Boolean(file.isOnDeletion));
  }, [uploadedFiles]);

  const hasLoadingRecent = useMemo(() => {
    return recentUploadedFiles.some(file => Boolean(file.isLoading) || Boolean(file.isOnDeletion));
  }, [uploadedFiles])

  const value = useMemo(() => ({
    selectedClient, setSelectedClient,
    clientInput, setClientInput,
    uploadedFiles, setUploadedFiles,
    userDefinedFields, setUserDefinedFields,
    recentUploadedFiles,
    isPageLoading, setIsPageLoading,
    hasLoadingUploaded,
    hasLoadingRecent,
    isInLandingPage
  }), [
    selectedClient, setSelectedClient,
    clientInput, setClientInput,
    uploadedFiles, setUploadedFiles,
    userDefinedFields, setUserDefinedFields,
    recentUploadedFiles,
    isPageLoading, setIsPageLoading,
    hasLoadingUploaded,
    hasLoadingRecent,
    isInLandingPage
  ]);

  return (
    <FileImportContext.Provider value={value}>
      {children}
    </FileImportContext.Provider>
  );
};

export default FileImportProvider;