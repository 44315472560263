import colors from "../../../utility/colors";

const styles = {
  labelCommon: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  labelClient: {
    marginRight: '1rem'
  },
  dropdownCommon: {
    fontSize: '14px',
    backgroundColor: 'white',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  dropdownOthers: {
    marginTop: '0.5rem'
  },
  parentChipContainer: {
    width: '100px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputParentChipContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  parentIdentifierChip: {
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"P"',
    },
    '& .MuiChip-label:hover:after': {
      content: '"Parent"',
    }
  },
  labelChipContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  hidden: {
    display: 'none',
  }
}
export default styles;