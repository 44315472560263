const styles = {
  mainContainer: {
    width: '100%'
  },
  actionButtonBox:{
    gap: '1rem',
    justifyContent: 'end',
    width: '100%',
  },
  actionContainer: {
    display: 'flex'
  },
  autocompleteField: {
    backgroundColor: 'white',
    fontSize: '14px',
    borderRadius: '5px',
    '&&&& .MuiAutocomplete-input': {
      padding: '0px 0px 8px 0px',
    },
    '&&&& .MuiAutocomplete-input::placeholder': {
      color: '#949494',
      opacity: '100%'
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      padding: '5px 9px 0px 9px'
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center',
      position: 'absolute',
      top: '-4px',
      left: '-4px',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#949494',
    },
    '&&&& .MuiAutocomplete-endAdornment': {
      right: '7px'
    },
    '& .MuiFormHelperText-root':{
      position: 'absolute',
      top: '30px'
    }
  },
  datePicker: {
    'input[type="date"]::-webkit-datetime-edit-text,input[type="date"]::-webkit-datetime-edit-month-field,input[type="date"]::-webkit-datetime-edit-day-field,input[type="date"]::-webkit-datetime-edit-year-field': {
      color: '#888'
    },
    'input[type="date"].MuiInputBase-inputAdornedEnd::-webkit-datetime-edit-text,input[type="date"].MuiInputBase-inputAdornedEnd::-webkit-datetime-edit-month-field,input[type="date"].MuiInputBase-inputAdornedEnd::-webkit-datetime-edit-day-field,input[type="date"].MuiInputBase-inputAdornedEnd::-webkit-datetime-edit-year-field': {
      color: 'black'
    },
  },
  autocompleteValueField: {
    backgroundColor: 'white',
    fontSize: '13px',
    borderRadius: '5px',
    paddingBottom: '3px',
    '&&&& .MuiAutocomplete-root': {
      paddingRight: '0px',
    },
    '&&&& .MuiOutlinedInput-root':{
      minHeight: '24px',
      maxHeight: '320px',
      paddingLeft: '5px',
      paddingRight: 0,
      display: 'flex',
      alignItems: 'baseline',
    },
    '&&&& .MuiAutocomplete-input': {
      padding: '1px 0 5px 12px',
    },
    '&&&& .MuiAutocomplete-input::placeholder': {
      color: '#949494',
      opacity: '100%',
    },
    '& .MuiAutocomplete-tag': {
      maxWidth: '158px',
      marginY: 0,
      marginLeft: '3px',
      marginRight: 0,
    },
    '& .Mui-focused .MuiAutocomplete-tag': {
      maxWidth: '222px',
      marginY: '3px',
      marginLeft: '3px',
      marginRight: 0,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '13px',
      padding: '5px 9px 0px 9px',
      overflowY: 'auto',
      overflowX: 'visible',
      position: 'initial',
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center',
      position: 'absolute',
      top: '-10px',
      left: '-4px',
    },
    '& .MuiInputLabel-shrink': {
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center',
      position: 'absolute',
      top: '3px',
      left: '-4px',
    },
    '&&&& .MuiAutocomplete-endAdornment': {
      display: 'none',
      fontSize: '13px',
    },
    '& .MuiFormHelperText-root':{
      position: 'absolute',
      top: '30px'
    },
  },
  cancelButton: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  customRuleBox:{
    borderRadius: '4px',
    boxShadow: '0px 1px 5px 2px #E0E0E0',
    mb: '1rem',
    width: '100%',
  },
  customSwitch: {
    padding: '2px 0px',
    height: 34,
    width: 67,
    '& .Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: '#010953',
        opacity: '100',
        '&:before':{
          color: 'white',
          content: '"AND"'
        },
        '&:after':{
          content: '""'
        }
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: 'white',
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#010953',
      mt: '2.5px',
      height: 12,
      width: 12,
    },
    '& .MuiSwitch-track': {
      backgroundColor: "#fff",
      border: '1px solid #010953',
      borderRadius: '4px',
      opacity: '100%',
      '&:before, &:after': {
        fontSize: '14px',
        position: 'absolute',
        top: '52%',
        transform: 'translateY(-50%)',
      },
      '&:before': {
        content: "''",
        left: 12,
      },
      '&:after': {
        content: "'OR'",
        color: '#010953',
        right: 12,
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      '& + .MuiSwitch-track': {
        opacity: 1
      },
      transform: 'translateX(40px)',
    }
  },
  defaultCollateral:{
    backgroundColor: '#D0DBE8',
    borderRadius: '4px',
    boxShadow: '0 3px 3px -2px #E0E0E0',
    flexWrap: 'nowrap',
    my: '1rem',
    padding: '20px',
  },
  defaultSettingsListBox: {
    maxHeight: 200
  },
  defaultSettingsItem: {
    fontSize: '14px'
  },
  defaultCollateralInputBox:{
    boxSizing: 'border-box',
    padding: '10px',
    width: '25%',
  },
  displayImage: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '150% 150%',
    width: '10%',
  },
  expressionBox:{
    backgroundColor: '#D0DBE8',
    borderRadius: '4px',
    padding: '13px 10px 13px 10px'
  },
  expressionFieldBox:{
    py: '11px',
    pl: '8px',
    alignItems: 'end',
    columnGap: '1px',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: '100%',
  },
  expressionParentBox:{
    alignItems: 'end',
    columnGap: '5px',
    flexWrap: 'nowrap',
    padding: '10px 10px',
  },
  infoText:{
    boxSizing: 'border-box',
    color: '#010953',
    paddingX: '10px',
    width: '65%',
  },
  overrideHeader:{
    backgroundColor: '#F5F5F5',
    columnGap: '5px',
    flexWrap: 'nowrap',
    padding: '10px',
  },
  overrideSettings:{
    backgroundColor: '#D0DBE8',
    borderRadius: '4px',
    padding: '24px 10px',
    height: '81px',
  },
  overrideCollateralDropdown: {
    fontSize: '14px'
  },
  removeIconGrid:{
    justifyContent: 'end',
    padding: '5px 10px 0px 0px',
  },
  removeIconButton: {
    padding: '0px'
  },
  removeIcon: {
    color: '#154A8A'
  },
  saveButton: {
    height: '32px',
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },
  addButton: {
    height: '32px',
    width: '67px',
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },
  addOverrideButtonContainer: {
    width: '100%',
    marginBottom: '0.5rem',
    position: 'relative'
  },
  addOverrideButtonDivider: {
    width: '100%',
    bottom: '50%',
    backgroundColor: '#010953'
  },
  addOverrideButton: {
    backgroundColor: '#D0DBE8'
  },
  uploadListButton: {
    width: '100%',
    justifyContent: 'flex-start',
    paddingLeft: '10px',
    paddingY: '5px',
    mt: '5px',
    backgroundColor: '#010953',
    '&:hover' : {
      color: '#010953',
    },
    color: 'white',
    
  },
  uploadListButtonText: {
    paddingX: '5px',
    fontWeight: '500'
  },
  overrideConditionText: {
    fontSize: '14px',
  },
  overrideSwitchOperatorContainer: {
    alignItems: 'center',
    flexWrap: 'nowrap'
  },
  overrideRemoveIcon: {
    color: '#154a8a',
    padding: '2px 0px 0px 5px'
  },
  chip: {
    '&&&.MuiChip-sizeSmall': {
      maxWidth:'90px'
    },
  },
  autoCompleteChipTag:{
    textOverflow: 'ellipsis',
    overflow:'hidden',
    whiteSpace: 'nowrap',
    backgroundColor: '#D0DBE8',
  },
  accordionDetailsPadding: {
    paddingX: 0
  }
}

export default styles