const styles = {
  gridContainer: {
    color: '#212529',
    px: '23.85px',
    mb: '50px',
  },

  gridHeading: {
    backgroundColor: '#E9ECEF',
    fontSize: '16px',
    mt: '18px',
    mb: '16px',
    p: '10px',
    pl: '20px',
    boxShadow: '0px 3px 6px #00000029',
  },

  gridHeadingForAvailableInventory: {
    my: 0,
  },

  gridHeadingForOthers: {
    mt: 0,
    mb: '16px',
  },

  gridTable: {
    display: 'flex',
  },

  gridText: {
    fontSize: '14px',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  gridButton: {
    backgroundColor: '#CFDAE7',
    color: '#010953',
    textTransform: 'capitalize',
    ml: '4rem',
    ':hover': {
      backgroundColor: '#0075AD',
      color: '#ffffff',
    },
  },

  gridTextRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  gridTextRowLeft: {
    justifyContent: 'flex-start',
  },
  
  gridTextLeft: {
    marginLeft: '4rem',
  },

  gridButtonRow: {
    display: 'flex',
    justifyContent: 'flex-start',
  },

  gridDivider: {
    bgcolor: '#989393',
    height: '0.8px',
    border: 'none',
    my: '15px',
  },

  gridDividerForAvailableInventory: {
    my: '10px',
  },

  tighterGridDivider: {
    mt: '5px',
  },

  tighterGridHeading: {
    mt: '5px',
  },

  gridDividerLoop: {
    bgcolor: '#E9ECEF',
    height: '8px',
    border: 'none',
    my: '8px',
  },

  tableHead: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-start'
  },

  tableHeadCell: {
    width: 480,
    height: 21,
    padding: '0.3rem',
    overflowWrap: 'break-word',
    color: '#212529',
    '&:first-of-type': {
      visibility: 'hidden',
    },
    '&:hover': {
      cursor: 'default',
    },
  },

  tableHeadCellCommon: {
    '&:first-of-type': {
      visibility: 'visible',
    },
  },

  tableCell: {
    color: '#212529',
    height: 21,
    width: 150,
    padding: '0.3rem',
    '&:first-of-type div': {
      flexBasis: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:hover': {
        cursor: 'default',
      },
    },
  },

  tableCellCommon: {
    width: 248,
    textAlign: 'right',
  },

  tableCellLeftAlign: {
    width: 300,
    textAlign: 'left',
    paddingLeft: '5rem',
  },

  tableBody: {
    overflowX: 'auto',
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-start',
  },

  tableBodyCommon: {
    '.MuiTableCell-root:first-of-type': {
      borderBottom: 0,
    },
  },

  labelDate: {
    marginTop:-1, 
    borderBottom: '1.25px solid #989393', 
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`, 
    fontSize: '0.875rem'
  },

  linkButton: {
    padding: '2px',
    color: '#154A8A',
    marginRight: '1rem',
  },

  linkIcon: {
    '&.MuiSvgIcon-root': {
      fontSize: '15px',
    },
  },

  hidden: {
    display: 'none',
  },
};

export default styles;
