import colors from "../../../../utility/colors";

const styles = {
  compareTable: {
    mt: '2rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    maxWidth: '70vw'
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableHeadCell: {
    inlineSize: 291,
    color: '#212529',
    fontSize: '1rem',
    '&:first-of-type': {
      visibility: 'hidden',
    },
    '&:last-of-type': {
      borderTop: `1px solid ${colors.NEUTRAL}`,
      borderBottom: `1px solid ${colors.NEUTRAL}`,
      paddingBottom: '0.5rem',
      marginTop: '0.5rem'
    },
    '.MuiTableCell-root': {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    overflowWrap: 'break-word',
    height: '21px',
    textAlign: 'left',
  },
  tableCell: {
    color: '#212529',
    minWidth: 120,
    '&:first-of-type div': {
      flexBasis: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    height: '21px',
  },
  tableHead: {
    '.MuiTableCell-root:first-of-type': {
      marginBottom: '1rem',
    },
    '.MuiTableCell-root:last-of-type': {
      borderTop: `1px solid ${colors.NEUTRAL}`,
      borderBottom: `1px solid ${colors.NEUTRAL}`,
      paddingBottom: '0.5rem',
      marginTop: '0.5rem',
      fontWeight: 'bold',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    background: '#F2F7F8',
    paddingBottom: '33px',
    width: '20rem',
    overflow: 'hidden',
  },
  tableBody: {
    '.MuiTableCell-root:first-of-type': {
      marginBottom: '1rem',
      fontWeight: 'bold',
    },
    '.MuiTableCell-root:last-of-type': {
      borderTop: `1px solid ${colors.NEUTRAL}`,
      borderBottom: `1px solid ${colors.NEUTRAL}`,
      paddingBottom: '0.5rem',
      marginTop: '0.5rem',
      fontWeight: 'bold',
    },
    marginLeft: 0,
    display: 'flex',
    overflowX: 'auto',
    paddingBottom: '16.5px',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  linkButton: {
    padding: '2px',
    color: '#154A8A',
    paddingRight: '24px',
  },
  linkIcon: {
    '&.MuiSvgIcon-root': {
      fontSize: '15px',
    },
  },
  hidden: {
    display: 'none',
  },
};
export default styles;
  