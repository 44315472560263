import { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Grid, Typography, TextField } from '@mui/material';
import { GET, API_DOMAIN, PERMISSIONS } from '../../../../../utility/constants';
import { checkUserPermissions, getLocalStorageItem, isObjectsEqual } from '../../../../../utility/helper';
import request from '../../../../../service/request';
import { SelectedClientContext } from '../../../../../context/selectedClientContext';
import { IneligibleAdjustmentsContext as IAContext, IIneligibleAdjustmentsContext as IAContextInterface } from '../../../../../context/ineligibleAdjustmentsContext';
import { NavigationWarningContext, INavigationWarningContext } from '../../../../../context/navigationWarningContext';
import { IARResponse, IClient, IInventoryResponse, ICollateral } from '../../../../../interfaces/ineligibleAdjustmentsInterface';
import ConfirmModal from '../../../../modals/confirm-modal';
import styles from './styles';
import { getActiveCollaterals } from '../../../../client-settings/tabs/tab-panel-contents/accounts-receivable';
import { borrowerAPI } from '../../../../../service/api';

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const currencyFormat = (amountString: string) => {
  const amount = parseFloat(amountString);
  const formatted = formatter.format(amount);
  return formatted;
};

const IneligibleAdjustmentsPanelHeader = (props: { ineligibleType: string; }) => {
  const { borrowerId: borrowerIdParam }                       = useParams();
  const navigate                                              = useNavigate();
  const {
    selectedClient: selectedClientContext,
    setSelectedClient: setSelectedClientContext
  }                                                           = useContext(SelectedClientContext);
  const {
    setClients, selectedClient, setSelectedClient,
    selectedCollateral, setSelectedCollateral, totalAmount,
    setCurrentArCollateral,
    currentInvCollateral, setCurrentInvCollateral,
    lastSelectedClient, setLastSelectedClient,
    showPrompt, setShowPrompt
  }                                                           = useContext(IAContext) as IAContextInterface;
  const { isDirty, setIsDirty }                               = useContext(NavigationWarningContext) as INavigationWarningContext;
  const [collaterals, setCollaterals]                         = useState<ICollateral[]>([]);
  const [defaultCollateral, setDefaultCollateral]             = useState<ICollateral | null>(null);
  const [lastSelectedCollateral, setLastSelectedCollateral]   = useState<ICollateral>(selectedCollateral); // for storing last selected collateral for confirm navigation modal
  
  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    const isClientUninitialized = selectedClient.recordId === -1 || selectedClient.recordId === undefined;
    if(isClientUninitialized){ return; }
    getCollaterals();

    const isClientAlreadySetAsParam = borrowerIdParam === selectedClient.recordId.toString();
    if (isClientAlreadySetAsParam) { return; }
    navigate(`/clients/${selectedClient.recordId}/settings/ineligible-adjustments`);
  }, [selectedClient]);

  useEffect(() => {
    if (selectedClientContext === null || selectedClientContext === undefined || selectedClientContext.recordId === undefined || selectedClientContext.borrowerName === undefined) { return; }
    setSelectedClient({ recordId: selectedClientContext.recordId, borrowerName: selectedClientContext.borrowerName, parentClient: selectedClientContext.parentClient, parentClientFk: selectedClientContext.parentClientFk });
  }, [selectedClientContext]);

  const getClients = async () => {
    let requestParams;
    const canViewAssignedClientsOnly = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_ASSIGNED_CLIENT);
    if (canViewAssignedClientsOnly) {
      requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 99999, sortBy: 'borrowerName,ASC', crmName: `${getLocalStorageItem('firstName')} ${getLocalStorageItem('lastName')}`}
    } else {
      requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 99999, sortBy: 'borrowerName,ASC'}
    }
    request({
      url: borrowerAPI.FIND_BY_CRITERIA,
      method: GET,
      params: requestParams
    })
      .then((response) => {
        let selectedClientIndex = 0;
        const mappedClients: Array<IClient> = response.data.content.map((client: IClient, index: number) => {
          const currentClient = { recordId: client.recordId, borrowerName: client.borrowerName, parentClient: client.parentClient, parentClientFk: client.parentClientFk };
          if(borrowerIdParam && client.recordId === parseInt(borrowerIdParam)){ selectedClientIndex = index }
          return currentClient;
        });
        setClients(mappedClients);
        const selectedMappedClient = mappedClients[selectedClientIndex];
        setSelectedClientContext(selectedMappedClient);
        setLastSelectedClient(selectedMappedClient);
    })
    .catch(error => console.log('INELIGIBLE ADJUSMENTS GET CLIENTS ERROR: ', error));
  };

  const getCollaterals = () => {
    switch (props.ineligibleType) {
      case 'AR':
        getARCollaterals();
        return;
      case 'Inventory':
        getInventoryCollaterals();
        return;
    }
  };

  const getARCollaterals = async () => {
    const currentBorrowerId = selectedClient.recordId;
    let selectedCollateralIndex = 0;
    let hasDefaultCollateralSet = false;
    const collaterals = await getActiveCollaterals(currentBorrowerId);
    const mappedCollaterals = collaterals.map((collateral: IARResponse, index: number) => {
      const currentCollateral = { recordId: collateral.recordId, collateralName: collateral.arCollateralName, default: collateral.default };
      if (!hasDefaultCollateralSet && currentCollateral.default === true) {
        selectedCollateralIndex = index;
        setDefaultCollateral(currentCollateral);
        hasDefaultCollateralSet = true;
      }
      return currentCollateral;
    });
    setCollaterals(mappedCollaterals);
    const selectedMappedCollateral = mappedCollaterals[selectedCollateralIndex] ?? { recordId: -1, collateralName: '' };
    !isObjectsEqual(selectedCollateral, selectedMappedCollateral) && setSelectedCollateral(selectedMappedCollateral);
    setLastSelectedCollateral(selectedMappedCollateral);
    setCurrentArCollateral(selectedMappedCollateral);
  };
  
  const getInventoryCollaterals = () => {
    const currentBorrowerId = selectedClient.recordId;
    request({
      url: `${API_DOMAIN}/inv/inputs/search/findIsArchived?pageNo=0&pageSize=9999&sortBy=invCollateralName,ASC&borrowerId=${currentBorrowerId}&isArchived=false`,
      method: GET,
    })
      .then((response) => {
        let selectedCollateralIndex = 0;
        let hasDefaultCollateralSet = false;
        const mappedCollaterals = response.data.content.map((collateral: IInventoryResponse, index: number) => {
          const currentCollateral = { recordId: collateral.invCollateralFk, collateralName: collateral.invCollateralName, default: collateral.default };
          if (currentCollateral.recordId && currentCollateral.recordId === currentInvCollateral.recordId) { selectedCollateralIndex = index; } // check if recordId is null before comparing
          if (!hasDefaultCollateralSet && currentCollateral.default === true) {
            setDefaultCollateral(currentCollateral);
            hasDefaultCollateralSet = true;
          }
          return currentCollateral;
        });
        setCollaterals(mappedCollaterals);
        const selectedMappedCollateral = mappedCollaterals[selectedCollateralIndex] ?? { recordId: -1, collateralName: '' };
        !isObjectsEqual(selectedCollateral, selectedMappedCollateral) && setSelectedCollateral(selectedMappedCollateral);
        setLastSelectedCollateral(selectedMappedCollateral);
        setCurrentInvCollateral(selectedMappedCollateral);
      })
      .catch(error => console.log('INELIGIBLE ADJUSTMENTS GET INV COLLATERALS ERROR: ', error));
  };

  const handleCollateralChange = (_event: any, newValue: ICollateral | null) => {
    if (newValue === null) { return; }
    setLastSelectedCollateral(newValue);
    if (isDirty) {
      setShowPrompt(true);
      return;
    }
    !isObjectsEqual(selectedCollateral, newValue) && setSelectedCollateral(newValue);

    if (props.ineligibleType === 'AR') {
      setCurrentArCollateral(newValue);
    } else {
      setCurrentInvCollateral(newValue);
    }
  };

  const handleDiscardChanges = () => {
    setSelectedClientContext(lastSelectedClient);
    setSelectedCollateral(lastSelectedCollateral);
    setIsDirty(false);
    setShowPrompt(false);
  };

  return (
    <Box sx={styles.panelHeaderBox} py={2} gap={2}>
      <Grid container spacing={2} sx={styles.comboBoxGrid}>
        <Grid item xs={12} md={6} gap={2}>
          <Typography tabIndex={0} variant='body2' sx={styles.labelStyle} component='label' htmlFor='combo-box-collateral' marginBottom={1}>
            Collateral Type
          </Typography>
          <Autocomplete
            id='combo-box-collateral'
            aria-label='Collateral Type Dropdown'
            disablePortal
            options={collaterals}
            getOptionLabel={collateralOption => collateralOption.collateralName}
            isOptionEqualToValue={(option, value) => option.recordId === value.recordId}
            renderOption={(prop, option: ICollateral) => (
              <Box component='li' {...prop} key={option.recordId} sx={styles.collateralNameDropdownText}>
                {option.collateralName}
              </Box>
            )}
            onChange={handleCollateralChange}
            renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, 'aria-label': 'Collateral Type Dropdown' }} />}
            size='small'
            fullWidth
            sx={styles.clientComboBox}
            value={selectedCollateral}
            defaultValue={defaultCollateral}
            componentsProps={{
              popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
              clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
            }
          />
        </Grid>
      </Grid>
      <Box sx={styles.totalAmountContainer}>
        <Typography tabIndex={0} sx={styles.labelTotalAmount}>
          Total Amount:
        </Typography>
        <Typography tabIndex={0} sx={styles.valueTotalAmount}>
          {currencyFormat(totalAmount.toString())}
        </Typography>
      </Box>
      <ConfirmModal
        open={showPrompt}
        onClose={() => { handleDiscardChanges(); }}
        onConfirm={() => { setShowPrompt(false); }}
        onButtonClose={() => { setShowPrompt(false); }}
        promptChecker
        title={`Confirm Navigation`}
        description={`You have unsaved changes. Are you sure you want to leave this page?`}
        yesButtonText={`Keep Editing`}
        noButtonText={`Discard Changes`}
        confirmOnly
      />
    </Box>
  );
};

export default IneligibleAdjustmentsPanelHeader;
