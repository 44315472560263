import { FC, useContext, useMemo, useState } from 'react'
import { IDataMapTabProps, getOptionalFields, getRequiredFields } from '..';
import { Box, Chip, CircularProgress, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { DocumentIssueTypes, IDocumentIssue, IUploadedFile } from '../../../../../../interfaces/fileimport';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import styles from '../styles';
import { formatDateToLocal } from '../../../../../../utility/helper';
import { AMERICAN_DATE_8HR_FORMAT, DELETE, PROMPT } from '../../../../../../utility/constants';
import DataMapIcon from '../../../../../../assets/images/DataMapIcon.svg';
import SetupMultipleCollateral from '../../../../../../assets/images/SetupMultipleCollateral.svg';
import SetupMultipleCollateralDisabled from '../../../../../../assets/images/SetupMultipleCollateralDisabled.svg';
import ConfirmModal from '../../../../../../components/modals/confirm-modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FileImportContext, IFileImportContext } from '../../../../../../context/fileImportContext';
import axiosInstance from '../../../../../../service/axiosInstance';
import { fileImportAPI } from '../../../../../../service/api';
import CollateralRuleModal from '../../../../../../components/file-import/modals/collateral-rule-modal';
import WarningModal from '../../../../../../components/file-import/modals/warning-modal';
import MappingModal from './mapping-modal';

export interface IFileRowProps extends IDataMapTabProps {
  uploadedFile: IUploadedFile;
}

const setupMultipleNotImplemented = ['Vendor List', 'AP Aging', 'GL Transaction'];

/**
 * Component for the File Row of the Data Map Step.
 * @param props The props for the File Row component of the Data Map Step.
 * @returns A component for the File Row of the Data Map Step.
 */
const FileRow: FC<IFileRowProps> = (props) => {
  const  { uploadedFile, showToaster }              = props;
  const { uploadedFiles, setUploadedFiles,
          selectedClient }                          = useContext(FileImportContext) as IFileImportContext;
  const [warningModalOpen, setWarningModalOpen]     = useState<boolean>(false);
  const [dataMapModalOpen, setDataMapModalOpen]     = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen]       = useState<boolean>(false);
  const [showCollateralRule, setShowCollateralRule] = useState<boolean>(false);
  const isUltimateParent                            = useMemo(() => Boolean(selectedClient?.parentClient), [selectedClient]);

  /**
   * This memoized object includes required and optional fields based on the document type of the uploaded file.
   * It is created using the useMemo hook to avoid unnecessary recalculations.
   */
  const optionalAndRequiredFields = useMemo(() => {
    const requiredFields = getRequiredFields(uploadedFile.documentType ?? '');
    const optionalFields = getOptionalFields(uploadedFile.documentType ?? '');
    return { requiredFields, optionalFields };
  }, [uploadedFile])

  /**
   * This memoized boolean value is based on various conditions, including the data mapping status,
   * parsing status, deletion status, and document type of the uploaded file.
   */
  const setupMultipleDisabled = useMemo(() =>
    isUltimateParent || 
    setupMultipleNotImplemented.includes(uploadedFile.documentType ?? '') ||
    !uploadedFile.mapped ||
    Boolean(uploadedFile.isParsing) ||
    Boolean(uploadedFile.isOnDeletion)
  , [uploadedFiles])

  /**
   * This memoized array of errors is based on the presence of document issues of type 'error' in the uploaded file.
   */
  const documentErrors: IDocumentIssue[] | undefined = useMemo(() =>
    uploadedFile.documentIssues?.filter(issue => issue.issueType === 'error')
  , [uploadedFile.documentIssues]);

  /**
   * This function returns an alert icon based on the presence of document issues and their types in the uploaded file.
   * @param uploadedFile The uploaded file for which to get the alert icon.
   * @returns The alert icon component based on the document issues.
   */
  const getAlertIcon = (uploadedFile: IUploadedFile) => {
    if (uploadedFile.documentIssues?.length) {
      if (documentErrors?.length) {
        return (
          <IconButton onClick={handleWarningClick} sx={styles.warningIconButton}>
            <WarningAmberRoundedIcon fontSize='small' color='error'/>
          </IconButton>
        ) 
      } else {
        return (
          <IconButton onClick={handleWarningClick} sx={styles.warningIconButton}>
            <WarningAmberRoundedIcon fontSize='small' color='warning'/>
          </IconButton>
        )
      }
    } else {
      return (
        <Box sx={styles.warningIconSpacer}></Box>
      )
    }
  };

  /**
   * This function sets the state to open the warning modal when a warning icon is clicked.
   */
  const handleWarningClick = () => {
    setWarningModalOpen(true);
  }

  /**
   * This function sets the state to open the data mapping modal when triggered by a click event.
   */
  const handleDataMapClick = () => {
    setDataMapModalOpen(true);
  };

  /**
   * Get the tooltip text for the "Setup Multiple Collaterals" button based on the file's properties.
   * @param uploadedFile The uploaded file object.
   * @returns The tooltip text.
   */
  const getSetupMultipleCollateralToolTip = (uploadedFile: IUploadedFile) => {
    if (isUltimateParent) {
      return 'Setup multiple collaterals for Ultimate Parent is not applicable.'
    } else if (setupMultipleNotImplemented.includes(uploadedFile.documentType ?? '')) {
      return `Setup multiple collaterals for ${uploadedFile.documentType} is not implemented yet.`
    } else if (!uploadedFile.mapped) {
      return 'Complete data mapping first to setup multiple collaterals'
    } else {
      return 'Setup Multiple Collaterals'
    }
  };

  /**
   * Handle the click event for setting up multiple collaterals.
   */
  const handleSetupMultipleCollateralClick = () => {
    setShowCollateralRule(true);
  };

  /**
   * Handle the click event for deleting an uploaded file.
   */
  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
  };

  /**
   * Delete an uploaded file and update the state accordingly.
   * @param uploadedFile The uploaded file to delete.
   */
  const deleteUploadedFile = async (uploadedFile: IUploadedFile) => {
		try {
      setDeleteModalOpen(false);
      uploadedFile.isOnDeletion = true;
      const onDeletion = uploadedFiles.map((original: IUploadedFile) => {
        if (original.recordId === uploadedFile.recordId) return uploadedFile;
        else return original;
      });
      setUploadedFiles(onDeletion);

			const response = await axiosInstance.request({
				url: `${fileImportAPI.document.MAIN_ENDPOINT}/${uploadedFile.recordId}`,
				method: DELETE,
			});
			  
      if (response.status === 200) {
        uploadedFile.isOnDeletion = false;
        const filteredUploaded = uploadedFiles.filter(uploaded => uploaded.recordId !== uploadedFile.recordId);
        setUploadedFiles(filteredUploaded);
        showToaster('success', `${uploadedFile.filename} successfully deleted!`);
      } else {
        uploadedFile.isOnDeletion = false;
        console.log('FAILED TO DELETE UPLOADED FILE: STATUS NOT 200');
        showToaster('error', `Failed to delete ${uploadedFile.filename}!`);
      }
    } catch (error) {
      console.log('DELETE UPLOADED FILE ERROR: ', error);
      showToaster('error', `Failed to delete ${uploadedFile.filename}!`);
    } finally {
      uploadedFile.isOnDeletion = false;
    }
	}

  /**
   * Get the issue type and issue messages for an uploaded file.
   * @param uploaded The uploaded file.
   * @returns An object containing the issue type and an array of issue messages.
   */
  const getIssueTypeAndMessage = (uploaded: IUploadedFile) => {
    let issueType: DocumentIssueTypes = 'error';
    let issuesByType: IDocumentIssue[] = [];
    
    if (documentErrors?.length) {
      issuesByType = documentErrors;
    } else {
      issueType = 'warning';
      const filtered = uploaded.documentIssues?.filter(doc => doc.issueType === 'warning');
      if (filtered) issuesByType = filtered;
    }
    
    const issueMessages = issuesByType.map(issue => issue.issueMessage ?? '');
    return {issueType, issueMessages}
  }
  
  return (
    <>
    <TableRow key={uploadedFile.recordId} sx={styles.tableRow} aria-label={`${uploadedFile.filename} row`}>
      <TableCell tabIndex={0} scope='row' sx={{...styles.tableCell, ...styles.fileNameBody}}>
        {getAlertIcon(uploadedFile)}
        {uploadedFile.filename.length > 25 ?
        <Tooltip title={uploadedFile.filename}><Box>{`${uploadedFile.filename.slice(0, 25)}...`}</Box></Tooltip>
        : uploadedFile.filename}
        {uploadedFile.isNewlyUploaded && <Chip tabIndex={0} label="New" size="small" sx={styles.chip}/>}
      </TableCell>
      <TableCell tabIndex={0} scope='row' sx={{...styles.tableCell}}>
        {uploadedFile.documentType}
      </TableCell>
      <TableCell tabIndex={0} scope='row' sx={{...styles.tableCell}}>
        {uploadedFile.arCollateralName}
      </TableCell>
      <TableCell tabIndex={0} scope='row' sx={{...styles.tableCell}}>
        {uploadedFile.endDate}
      </TableCell>
      <TableCell tabIndex={0} scope='row' sx={{...styles.tableCell}}>
        {formatDateToLocal(uploadedFile.createdAt ?? '', AMERICAN_DATE_8HR_FORMAT, AMERICAN_DATE_8HR_FORMAT)}
      </TableCell >
      <TableCell tabIndex={0} scope='row' sx={{...styles.tableCell}}>
        {uploadedFile.mapped ? 
        <Chip label={'Completed'} sx={{...styles.completed}}/> : 
        <Chip label={'Not Started'} sx={{...styles.notStarted}}/>}
      </TableCell >
      <TableCell scope='row' sx={{...styles.tableCell, ...styles.centerAlignedText}}>
        {uploadedFile.isParsing ? <CircularProgress size={15} /> :
        <Tooltip title="Data Map">
          <span>
            <IconButton
              onClick={handleDataMapClick}
              disabled={Boolean(uploadedFile.isOnDeletion)}
            >
              <img height={22} src={DataMapIcon} alt="data-map-icon" aria-label='Data Map icon' />
            </IconButton>
          </span>
        </Tooltip>}
        <Tooltip title={getSetupMultipleCollateralToolTip(uploadedFile)}>
          <span>
            <IconButton
              onClick={handleSetupMultipleCollateralClick}
              disabled={setupMultipleDisabled}
              sx={styles.iconButton}
            >
              <img
                height={22}
                src={setupMultipleDisabled ? SetupMultipleCollateralDisabled : SetupMultipleCollateral}
                alt="setup-multiple-collaterals-icon"
                aria-label='Setup Multiple Collaterals icon'
              />
            </IconButton>
          </span>
        </Tooltip>
        {uploadedFile.isOnDeletion ? <CircularProgress size={15} /> :
        <Tooltip title="Delete File">
          <span>
            <IconButton
              aria-label='Delete icon'
              size='small'
              sx={styles.actionsIcon}
              disabled={Boolean(uploadedFile.isParsing)}
              onClick={handleDeleteClick}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>}
      </TableCell>
    </TableRow>
    <MappingModal
      open={dataMapModalOpen}
      handleClose={() => setDataMapModalOpen(false)}
      requiredFields={optionalAndRequiredFields.requiredFields}
      optionalFields={optionalAndRequiredFields.optionalFields}
      {...props}
    />
    <CollateralRuleModal
      setShowCollateralRule={setShowCollateralRule}
      showCollateralRule={showCollateralRule}
      {...props}
    />
    <WarningModal
      open={warningModalOpen}
      onClose={() => setWarningModalOpen(false)}
      {...getIssueTypeAndMessage(uploadedFile)}
      displayOnly
    />
    <ConfirmModal
      title={`${PROMPT.DELETE_PROMPT.title} ${uploadedFile.filename}`}
      description={PROMPT.DELETE_PROMPT.description}
      open={deleteModalOpen}
      errorButton
      noButtonText={PROMPT.DELETE_PROMPT.cancel}
      yesButtonText={PROMPT.DELETE_PROMPT.delete}
      onClose={() => setDeleteModalOpen(false)}
      onConfirm={async () => await deleteUploadedFile(uploadedFile)}
    />
    </>
  )
}

export default FileRow;