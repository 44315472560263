import { createContext, useMemo, useState } from 'react';
import { LayoutProps } from './authContext';

export interface IClientSettingsContext {
  isDirty: boolean,
  setIsDirty: (value : boolean) => void,
  showPrompt: boolean,
  setShowPrompt: (value : boolean) => void,
  hasClient: boolean,
  setHasClient: (value: boolean) => void

  // permission States
  viewOtherLoan: boolean,
  setViewOtherLoan: (value : boolean) => void,
  addOtherLoan: boolean,
  setAddOtherLoan: (value : boolean) => void,
  updateOtherLoan: boolean,
  setUpdateOtherLoan: (value : boolean) => void,
  deleteArchiveOtherLoan: boolean,
  setDeleteArchiveOtherLoan: (value : boolean) => void,

  canViewAr: boolean,
  setCanViewAr:(value: boolean) => void,
  canChangeDefaultAr: boolean,
  setCanChangeDefaultAr:(value: boolean) => void,
  canAddAr: boolean,
  setCanAddAr:(value: boolean) => void,
  canUpdateAr: boolean,
  setCanUpdateAr:(value: boolean) => void,
  canArchiveDeleteAr: boolean,
  setCanArchiveDeleteAr:(value: boolean) => void,
  canViewCustomerSettings: boolean,
  setCanViewCustomerSettings:(value: boolean) => void,

  canViewInv: boolean,
  setCanViewInv:(value: boolean) => void,
  canChangeDefaultInv: boolean,
  setCanChangeDefaultInv:(value: boolean) => void,
  canAddInv: boolean,
  setCanAddInv:(value: boolean) => void,
  canUpdateInv: boolean,
  setCanUpdateInv:(value: boolean) => void,
  canArchiveDeleteInv: boolean,
  setCanArchiveDeleteInv:(value: boolean) => void,
  canViewInventoryWorksheet: boolean,
  setCanViewInventoryWorksheet:(value: boolean) => void,
  canViewInventoryIneligibles: boolean,
  setCanViewInventoryIneligibles:(value: boolean) => void,
  arCollateralCount: number,
  setARCollateralCount:(value: number) => void,
  inventoryCount: number,
  setInventoryCount:(value: number) => void,
  otherLoansCount: number,
  setOtherLoansCount:(value: number) => void,
}

export const ClientSettingsContext = createContext<IClientSettingsContext | null>(null);

const ClientSettingsProvider = (props: LayoutProps) => { 
  const { children } = props;

  const [ isDirty, setIsDirty ] = useState<boolean>(false);
  const [ hasClient, setHasClient ] = useState<boolean>(false);
  const [ showPrompt, setShowPrompt] = useState<boolean>(false);
  const [ canViewAr, setCanViewAr] = useState<boolean>(false);
  const [ canChangeDefaultAr, setCanChangeDefaultAr] = useState<boolean>(false);
  const [ canAddAr, setCanAddAr] = useState<boolean>(false);
  const [ canUpdateAr, setCanUpdateAr] = useState<boolean>(false);
  const [ canArchiveDeleteAr, setCanArchiveDeleteAr] = useState<boolean>(false);
  const [ canViewInv, setCanViewInv] = useState<boolean>(true);
  const [ canChangeDefaultInv, setCanChangeDefaultInv] = useState<boolean>(false);
  const [ canAddInv, setCanAddInv] = useState<boolean>(false);
  const [ canUpdateInv, setCanUpdateInv] = useState<boolean>(false);
  const [ canArchiveDeleteInv, setCanArchiveDeleteInv] = useState<boolean>(false);
  const [ viewOtherLoan, setViewOtherLoan] = useState<boolean>(true);
  const [ addOtherLoan, setAddOtherLoan] = useState<boolean>(false);
  const [ updateOtherLoan, setUpdateOtherLoan] = useState<boolean>(false);
  const [ deleteArchiveOtherLoan, setDeleteArchiveOtherLoan] = useState<boolean>(false);
  const [ canViewCustomerSettings, setCanViewCustomerSettings] = useState<boolean>(false);
  const [ canViewInventoryIneligibles, setCanViewInventoryIneligibles] = useState<boolean>(false);
  const [ canViewInventoryWorksheet, setCanViewInventoryWorksheet] = useState<boolean>(false);
  const [ arCollateralCount, setARCollateralCount] = useState(0);
  const [ inventoryCount, setInventoryCount] = useState(0);
  const [ otherLoansCount, setOtherLoansCount] = useState(0);

  const value = useMemo(() => ({ 
    isDirty,
    setIsDirty,
    showPrompt,
    setShowPrompt,
    hasClient,
    setHasClient,
    viewOtherLoan,
    setViewOtherLoan,
    addOtherLoan,
    setAddOtherLoan,
    updateOtherLoan,
    setUpdateOtherLoan,
    deleteArchiveOtherLoan,
    setDeleteArchiveOtherLoan,
    canViewAr,
    setCanViewAr,
    canChangeDefaultAr,
    setCanChangeDefaultAr,
    canAddAr,
    setCanAddAr,
    canUpdateAr,
    setCanUpdateAr,
    canArchiveDeleteAr,
    setCanArchiveDeleteAr,
    canViewInv,
    setCanViewInv,
    canChangeDefaultInv,
    setCanChangeDefaultInv,
    canAddInv,
    setCanAddInv,
    canUpdateInv,
    setCanUpdateInv,
    canArchiveDeleteInv,
    setCanArchiveDeleteInv,
    canViewCustomerSettings,
    setCanViewCustomerSettings,
    canViewInventoryIneligibles,
    setCanViewInventoryIneligibles,
    canViewInventoryWorksheet,
    setCanViewInventoryWorksheet,
    arCollateralCount,
    setARCollateralCount,
    inventoryCount,
    setInventoryCount,
    otherLoansCount,
    setOtherLoansCount
   }), [
    isDirty,
    setIsDirty,
    showPrompt,
    setShowPrompt,
    hasClient,
    setHasClient,
    viewOtherLoan,
    setViewOtherLoan,
    addOtherLoan,
    setAddOtherLoan,
    updateOtherLoan,
    setUpdateOtherLoan,
    deleteArchiveOtherLoan,
    setDeleteArchiveOtherLoan,
    canViewAr,
    setCanViewAr,
    canChangeDefaultAr,
    setCanChangeDefaultAr,
    canAddAr,
    setCanAddAr,
    canUpdateAr,
    setCanUpdateAr,
    canArchiveDeleteAr,
    setCanArchiveDeleteAr,
    canViewInv,
    setCanViewInv,
    canChangeDefaultInv,
    setCanChangeDefaultInv,
    canAddInv,
    setCanAddInv,
    canUpdateInv,
    setCanUpdateInv,
    canArchiveDeleteInv,
    setCanArchiveDeleteInv,
    canViewCustomerSettings,
    setCanViewCustomerSettings,
    canViewInventoryIneligibles,
    setCanViewInventoryIneligibles,
    canViewInventoryWorksheet,
    setCanViewInventoryWorksheet,
    arCollateralCount,
    setARCollateralCount,
    inventoryCount,
    setInventoryCount,
    otherLoansCount,
    setOtherLoansCount,
   ])

  return (
    <ClientSettingsContext.Provider
      value={value}
    >
      {children}
    </ClientSettingsContext.Provider>
  );
};

export default ClientSettingsProvider;