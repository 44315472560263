import { Theme } from '@mui/material/styles/createTheme';

const styles = {
  loadingBackdrop: {
    color: '#fff',
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1
  },

  loaderIcon: {
    width: '300px',
    height: 'auto',
    opacity: '0.75',
  },
};

export default styles;
