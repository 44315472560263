const styles = {
  titleContainer: {
    backgroundColor: '#FEFEFE',
    py: '11px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight     : 'bold',
    color          : '#010953',
    fontSize       : '18px',
    ml             : '24px',
  },
  headerActionWrapper: {
    px: '1.5rem',
    display: 'flex',
    gap: '0.5rem'
  },
  headerButtons: {
    height: '2rem',
    whiteSpace: 'nowrap'
  },
};

export default styles;