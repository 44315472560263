import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div>
      <h3>Page Not Found</h3>
      <nav
        style={{
          padding: "1rem",
        }}
      >
        <Link
          style={{ display: "block", margin: "1rem 0" }}
          to={`/`}
        >
          Go Home
        </Link>
      </nav>
    </div>
  )
}

export default ErrorPage;