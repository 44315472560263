import * as Yup from 'yup';

const alphabetsRegex = /^[a-zA-Z\s]+$/;
const numberRegex = /^[0-9\s]+$/;
const mobileNoRegex = /\d{10}/;

const bankProfileSchema = Yup.object({
  bankName: Yup.string()
    .required('Bank Name is required')
    .trim(),
  bankPhone: Yup.string()
    .required('Primary Contact is required')
    .matches(mobileNoRegex, 'Invalid primary phone number'),
  bankCountry: Yup.string()
    .required('Country is required')
    .trim()
    .matches(alphabetsRegex, 'Country must consist of alphabetic characters only'),
  bankAddress1: Yup.string()
    .required('Address 1 is required')
    .trim(),
  bankCity: Yup.string()
    .required('City is required')
    .trim()
    .matches(alphabetsRegex, 'City must consist of alphabetic characters only'),
  bankState: Yup.string()
    .required('State is required')
    .trim()
    .max(2,'State must contain of 2 maximum characters')
    .matches(alphabetsRegex, 'State must consist of alphabetic characters only'),
  bankPostalCode: Yup.string()
    .matches(numberRegex, 'Zip Code must consist of numeric characters only')
    .trim()
    .required('Zip Code is required'),
});

export default bankProfileSchema;