const styles = {
  listItem: {
    bgcolor: 'background.paper',
    marginY: 1,
    border: 'solid',
    borderWidth: '1px',
    borderColor: '#E5E2E2',
  },

  noTooltip: {
    '&&&& .MuiTooltip-tooltip': {
      backgroundColor: 'transparent'
    }
  },
  widthBox: {
    width: '1.1rem'
  },
  checkBox: {
    minWidth: 0, 
    pr: '1rem'
  },
  custName: {
    fontSize:'14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '23ch'
  }
}

export default styles;