const styles = {
  custName: {
    fontSize: '0.9rem',
    color: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '30ch'
  },
  description: {
    paddingLeft: '6rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconAction: {

  },
  tableCell: {
    fontSize: '0.9rem',
    border: 0,
    color: 'inherit',
    height: '2.5rem',
    '&:nth-child(2)': {
      pl: '0.5rem',
    },
    '&:last-child': {
      paddingRight: '3rem',
    },
  },
  tableCellNewTag: {
    fontSize: '0.9rem',
    border: 0,
    color: 'inherit',
    height: '2.5rem',
    p: 0,
    textDecoration: 'none',
    cursor: 'auto'
  },
  tableCellCollapse: {
    padding: 0,
    border: 0,
  },
  tableRow: {
    backgroundColor: '#F7F7F7',
    '&:hover': {
      backgroundColor: 'rgba(235,235,235,0.5)',
      color: '#0075AD',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    '&:nth-of-type(2n)': {
      backgroundColor: 'inherit',
    },
  },
  buttonGroup: {
    alignSelf: 'flex-end',
    display: 'flex',
    gap: '1rem',
  },
  cancelButton: {
    textTransform: 'none',
    height: '2rem',
  },
  checkBox: {
    marginRight: '10.8rem',
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-0.5rem',
    marginRight: '8rem',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '0.5rem',
    marginRight: '8rem',
  },
  label: {
    fontSize: '0.875rem',
    marginRight: '1rem',
    whiteSpace: 'nowrap',
    marginTop: '0.5rem',
    '& .MuiFormLabel-asterisk': {
      color: '#db3131',
    },
    color: '#000',
  },
  labelCheckbox: {
    fontSize: '0.875rem',
    marginRight: '0.35rem',
    whiteSpace: 'nowrap',
    marginTop: '0.65rem',
  },
  saveButton: {
    textTransform: 'none',
    height: '2rem',
    width: '10rem',
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#99BFED',
    },
  },
  settingsContainer: {
    border: '1px solid #EBEBEB',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '0.2rem',
    paddingBottom: '1.8rem',
    marginY: '1rem',
  },
  textfieldNumber: {
    width: '12.7rem',
    '& .MuiOutlinedInput-input': {
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      textAlign: 'right',
      fontSize: '0.8125rem',
      'WebkitBoxShadow': 'none',
    },
    '& .Mui-error': {
      fontSize: '11px',
      marginTop: '1px',
      marginX: '0',
    },
  },
  textfieldString: {
    width: '12.7rem',
    '& .MuiOutlinedInput-input': {
      fontSize: '0.8125rem',
      'WebkitBoxShadow': 'none',
    },
    '& .Mui-error': {
      fontSize: '11px',
      marginTop: '1px',
      marginX: '0',
    },
  },
  newTagBox: { 
    display: 'flex', 
    justifyContent: 'right', 
    width: '41px', 
    textDecoration: 'none' 
  },
};
export default styles;