import { FC } from 'react'
import { IClientReportsProps } from '..';
import ReportsTitle from '../../../../../components/reports-title';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import styles from './styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { formatCurrency } from '../../../../../utility/helper';
import IneligibleRows from '../../body/ineligible-view/row';
import IneligibleRowWithoutCustomers from '../../body/ineligible-view/row-without-customers';
import { IRfCalcSummary } from '../../../../../interfaces/rollForwardReportInterface';
import { IBorrowerReport, ICodeReport, ICollateralReport } from '../../../../../interfaces/arIneligibleReport';
import UpcGrandTotalRow from '../grand-total-row';

export interface IUpcIneligibleViewProps extends IClientReportsProps {

}

const UpcIneligibleView: FC<IUpcIneligibleViewProps> = (props) => {
  const { bbPeriodLabel, currencyCode, rfvDisabled, borrowerReport, rfSummaries, endDate } = props;
  
  return (
    <>
    {
      borrowerReport?.map((item: IBorrowerReport) => {
        const { borrowerId, borrowerName, borrowerTotal, collateralReport } = item;
        const clientRfvTotal: number = (rfSummaries ?? [])
          .filter((summary: IRfCalcSummary) => summary.borrowerId === borrowerId)
          .reduce((acc: number, summary: IRfCalcSummary) => {
            acc += summary.variance
            return acc;
          }, 0.0)

        return(
          <Box key={borrowerId}>
            <ReportsTitle
              clientName={borrowerName}
              reportName='AR Ineligible Report'
              bbPeriod={bbPeriodLabel}
            />
            {collateralReport.map((item: ICollateralReport) => {
              const { arCollateralId, arCollateralName, arCollateralTotal, ineligibleCodeReport } = item;
              const arCollateralRfSummary: IRfCalcSummary | undefined = (rfSummaries ?? [])
                .find((summary: IRfCalcSummary) => summary.arCollateralId === arCollateralId);
              const hasNoRfv: boolean = !(arCollateralRfSummary?.rfBeginningBalanceId);
              const arCollateralRfvTotal: number = arCollateralRfSummary?.variance ?? 0.0;

              return (
                <Accordion
                  key={arCollateralId}
                  sx={styles.accordion}
                  disableGutters
                  elevation={0}
                  square
                >
                  <AccordionSummary
                    aria-controls={arCollateralName}
                    id={arCollateralName}
                    expandIcon={
                      <ArrowDropDownIcon
                        aria-label='Expand icon'
                        sx={styles.accordionSummaryFontSize}
                      />
                    }
                    sx={styles.accordionSummary}
                  >
                    <Box sx={styles.accordionRow}>
                      <Typography fontSize='1rem' fontWeight='bold'>{arCollateralName}</Typography>
                      <Typography fontSize='0.875rem'>
                        {formatCurrency(arCollateralTotal + arCollateralRfvTotal, currencyCode)}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={styles.accordionDetails}>
                    {ineligibleCodeReport.map((item: ICodeReport) => {
                      const { ineligibleCode, ineligibleDescription, ineligibleTotal } = item;

                      return (
                        <IneligibleRows
                          {...props}
                          key={ineligibleCode}
                          ineligibleRecords={[]}
                          ineligibleDescription={ineligibleDescription}
                          ineligibleCode={ineligibleCode}
                          ineligibleTotal={ineligibleTotal}
                          exchangeRate={1}
                          borrowerId={borrowerId}
                          arCollateralId={arCollateralId}
                          endDate={endDate}
                        />
                      )
                    })}
                    {!rfvDisabled &&
                      <IneligibleRowWithoutCustomers
                        {...props}
                        key={'RFV'}
                        hasNoRfv={hasNoRfv}
                        ineligibleCode='RFV'
                        ineligibleDescription='Roll Forward Variance'
                        ineligibleAmt={arCollateralRfvTotal}
                        exchangeRate={1}
                      />
                    }
                    <Box sx={styles.totalAmount}>
                      <Typography tabIndex={0} fontSize='1rem' fontWeight='bold'>TOTAL AR INELIGIBLE</Typography>
                      <Typography tabIndex={0} fontSize='0.875rem' fontWeight='bold'>
                        {formatCurrency(arCollateralTotal + arCollateralRfvTotal, currencyCode)}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )
            })}
            <Box sx={{...styles.totalAmount, ...styles.clientTotalAmount}}>
              <Typography tabIndex={0} fontSize='1rem' fontWeight='bold'>CLIENT TOTAL</Typography>
              <Typography tabIndex={0} fontSize='0.875rem' fontWeight='bold'>
                {formatCurrency(borrowerTotal + clientRfvTotal, currencyCode)}
              </Typography>
            </Box>
          </Box>
        )
      })
    }
    <UpcGrandTotalRow {...props} />
    </>
  )
}

export default UpcIneligibleView;