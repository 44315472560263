import { Autocomplete, Box, Button, CircularProgress, Grid, IconButton, Modal, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Field, Formik, Form, FormikHelpers } from "formik";
import styles from './styles';
import addNewFieldSchema from "../../../../schemas/addNewFieldSchema";
import { IDataMapField, IDocumentAttribute } from "../../../../interfaces/dataMap";
import HelperTextComponent from "../../../common/helper-text-component";

export interface IAddNewFieldModalProps {
  isOpen: boolean;
  mapFields: IDataMapField[];
  addedFields: IDocumentAttribute[];
  handleClose: () => void;
  handleSave: (values: IFormikNewFieldFieldValues) => void;
}

export interface IFormikNewFieldFieldValues {
  fieldName: '',
  dataType: { value: string, label: string } 
}

const dataTypeOptions = [
  {
    value: 'string',
    label: 'Text',
  },
  {
    value: 'number',
    label: 'Number',
  },
  {
    value: 'date',
    label: 'Date',
  },
];

/**
 * Component that for adding new field modal in File Import's Data Mapping Modal.
 */
const AddNewFieldModal: React.FC<IAddNewFieldModalProps> = (props) => {
  const { isOpen, mapFields, addedFields, handleClose, handleSave } = props;

  /**
   * This function handles the submission of form values.
   * @param values The form values to be submitted.
   * @param action Formik action object providing helpers.
   */
  const handleSubmit = async (values: IFormikNewFieldFieldValues, action: FormikHelpers<IFormikNewFieldFieldValues>) => {
    const { setSubmitting } = action;
    setSubmitting(true);
    handleSave(values);
    setSubmitting(false);
    handleClose();
  };

  return (
    <Modal open={isOpen}>
      <Box>
        <Formik
          initialValues={{
            fieldName: '',
            dataType: {
              value: '',
              label: '',
            },
          }}
          onSubmit={handleSubmit}
          validationSchema={addNewFieldSchema(mapFields, addedFields)}
        >
          {(formik) => (
            <Form>
              <Grid container sx={styles.dialog}>
                <Grid item xs={12}>
                  <Grid container justifyContent='space-between' alignItems='flex-start' sx={styles.modalTitleContainer}>
                    <Grid item>
                      <Typography tabIndex={0} color='primary' id='dialog-title' variant='h6' component='h1'>New Field</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={handleClose} aria-label='Close New Field Modal'><Close fontSize='inherit' /></IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={styles.dialogBody}>
                  <Box sx={styles.dropdownContainer}>
                    <Typography tabIndex={0} htmlFor='fieldName' component='label' sx={styles.dropdownLabel}>
                      Field Name
                    </Typography>
                    <Field
                      id='fieldName'
                      as={TextField}
                      placeholder='Enter Field Name'
                      size='small'
                      sx={styles.dropdown}
                      name={'fieldName'}
                      error={formik.touched.fieldName && Boolean(formik.errors.fieldName)}
                      helperText={
                        formik.touched.fieldName && formik.errors.fieldName
                        ? <HelperTextComponent text={formik.errors.fieldName} />
                        : null
                      }
                    />
                  </Box>
                  <Box sx={styles.dropdownContainer}>
                    <Typography tabIndex={0} htmlFor='dataType' component='label' sx={styles.dropdownLabel}>
                      Data Type
                    </Typography>
                    <Autocomplete
                      id='dataType'
                      disablePortal
                      options={dataTypeOptions}
                      value={formik.values.dataType}
                      onChange={(_event, value) => {
                        formik.setFieldValue('dataType', value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select Data Type'}
                          name={'dataType'}
                          error={
                            formik.touched.dataType &&
                            Boolean(formik.errors.dataType)
                          }
                          helperText={
                            formik.touched.dataType && (formik.errors.dataType as string)
                            ? <HelperTextComponent text={formik.errors.dataType as string} />
                            : null
                          }
                          onBlur={formik.handleBlur}
                          InputLabelProps={{ shrink: false }}
                        />
                      )}
                      size='small'
                      componentsProps={{
                        popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
                        clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
                      }}
                      sx={styles.dropdown}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                  <Grid container justifyContent='flex-end' alignItems='center' gap={1}>
                    <Button
                      variant='outlined'
                      size='small'
                      disableElevation
                      sx={styles.cancelButton}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      size='small'
                      disableElevation
                      disabled={!(formik.isValid && formik.dirty)}
                      sx={styles.saveButton}
                      type='submit'
                    >
                      {formik.isSubmitting ? <CircularProgress size={15} /> : 'Save'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default AddNewFieldModal;