import yup from './index';

const ineligibleAdjustmentsSchema = yup.object({
  ineligibles: yup
    .array(
      yup.object({
        description: yup.string().trim().required('Description is required'),
        ineligibleAmount: yup
          .string()
          .required('Ineligible Amount is required'),
      })
    )
    .uniqueProperty('description', 'Description must be unique'),
});

export default ineligibleAdjustmentsSchema;
