const styles = {
  boxColor: {
    backgroundColor: '#F2F7F8',
  },
  cancelButton: {
    color          : 'red',
  },
  subtitleContainer: {
    display        : 'flex',
    justifyContent : 'space-between',
  },
  dropFileContainer: {
    border         : 'dashed',
    borderColor    : '#707070',
    borderRadius   : '20px',
    borderWidth    : 'medium'
  },
  importSuccess: {
    textAlign      : 'center',
    fontSize       : '24px',
    fontWeight     : 'bold',
    color          : '#010953',
    paddingBottom  : '1rem',
    position       : 'relative',
    bottom         : '5rem'
  },
  dropFileIcon: {
    fontSize       : '60px',
  },
  instructionContainer: {
    marginTop      : '3rem',
    backgroundColor: 'white',
    paddingY       : '9rem',
    placeContent   : 'space-evenly',
  },
  modalOpenButton: {
    py             : '2px',
    textTransform  : 'none',
  },
  pauseButton: {
    color          : '#010953',
  },
  progressBar: {
    alignSelf      : 'center',
  },
  progressContainer: {
    justifyContent : 'space-around',
  },
  progressLabel:{
    fontSize       : 'small',
    textAlign      : 'end'
  },
};

export default styles;
