import colors from '../../../utility/colors';

const styles = {
  comboBox: {
    backgroundColor: 'white',
    borderRadius: '4px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      fontSize: '14px',
      backgroundColor: colors.WHITE,
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
        '&::-webkit-inner-spin-button': {
          display: 'none',
        },
        '&::-webkit-outer-spin-button': {
          display: 'none',
        },
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      '&::after':{
        content:'" *"',
        color: '#AE2633',
      },
    },
    '& .MuiAutocomplete-input': {
      fontSize: '14px',
      // textAlign: 'center',
    },
    '& .Mui-disabled': {
      // backgroundColor: '#F7F7F7',
    },
  },
};

export default styles;