import { Box, Button, Divider } from "@mui/material"

import { FieldArrayRenderProps } from "formik"
import { IRuleGroup } from "../../../../../interfaces/collateralRuleInterface"
import styles from "../styles"

interface IProps {
  customRulesHelpers: FieldArrayRenderProps,
  initialRule:        IRuleGroup
}

/**
 * Component for the Add Override button of the Collateral Rule Modal.
 * @param param0 The component's props.
 * @returns A component for the Add Override button of the Collateral Rule Modal.
 */
const AddOverrideButton = ({customRulesHelpers, initialRule}: IProps) => {
  return (
    <Box sx={styles.addOverrideButtonContainer}>
      <Divider
        absolute
        sx={styles.addOverrideButtonDivider}
      />
      <Button
        variant='outlined'
        sx={{ ...styles.cancelButton, ...styles.addOverrideButton }}
        onClick={() => customRulesHelpers.push(initialRule)}
        data-testid='add-group-button'
      >+ Add Override
      </Button>
    </Box>
  )
}

export default AddOverrideButton;
