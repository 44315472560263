import React, { FC, useContext, useState, useEffect } from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import { Autocomplete, Box, TextField, Typography, Grid } from '@mui/material/';
import { API_DOMAIN, GET, PERMISSIONS } from '../../../utility/constants';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { InventoryWorksheetContext } from '../../../context/inventoryWorksheetContext';
import { NavigationWarningContext, INavigationWarningContext } from '../../../context/navigationWarningContext';
import { IClientInfo, IInventory, IInventoryWorksheetContext } from '../../../interfaces/inventoryWorksheetInterface'
import ConfirmModal from '../../modals/confirm-modal';
import styles from './styles';
import { IInventoryWorksheetProps } from '../../../pages/inventory-worksheet';
import { useIsFirstRender } from '../../../utility/hooks';
import { IInventoryCollateral } from '../../../interfaces';
import { checkUserPermissions, getLocalStorageItem } from '../../../utility/helper';
import { borrowerAPI } from '../../../service/api';
import axiosInstance from '../../../service/axiosInstance';
import axios, { AxiosResponse } from 'axios';

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const currencyFormat = (amountString: string) => {
  const amount = parseFloat(amountString);
  const formatted = formatter.format(amount);
  return formatted;
};

const InventoryWorksheetTopContainer: FC<IInventoryWorksheetProps> = (props) => {
  const {
    borrowerId: borrowerIdParam,
    invCollateralFk: collateralIdParam
  }                                                       = useParams()
  const [searchParams]                                    = useSearchParams();
  const borrowerId                                        = searchParams.get('clientId') ?? '0';
  const invCollateralId                                   = searchParams.get('invCollateralId') ?? '0';
  const navigate                                          = useNavigate();
  const {
    selectedClient: selectedClientContext,
    setSelectedClient: setSelectedClientContext
  }                                                       = useContext(SelectedClientContext);
  const {
    setClients, selectedClient, setSelectedClient,
    selectedInventory, setSelectedInventory,
    lastSelectedClient, setLastSelectedClient,
    totalInventoryWorksheetAmount,
    showPrompt, setShowPrompt
  }                                                       = useContext(InventoryWorksheetContext) as IInventoryWorksheetContext;
  const { isDirty, setIsDirty }                           = useContext(NavigationWarningContext) as INavigationWarningContext;
  const [inventories, setInventories]                     = useState<IInventory[]>([]);
  const [lastSelectedInventory, setLastSelectedInventory] = useState(selectedInventory);  // used for storing the last selected inventory for confirm navigation modal
  const isFirstRender                                     = useIsFirstRender();

  const getClients = async () => {
    let requestParams: any = { isVisible: true, isArchive: false, sortBy: 'borrowerName,ASC' };
    const canViewAssignedClientsOnly = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_ASSIGNED_CLIENT);
    if (canViewAssignedClientsOnly) {
      requestParams = {...requestParams, crmName: `${getLocalStorageItem('firstName')} ${getLocalStorageItem('lastName')}`}
    }
    axiosInstance.request({
      url: borrowerAPI.FIND_BY_CRITERIA,
      method: GET,
      params: requestParams
    })
    .then((response) => {
      let selectedClientIndex = 0;
      const mappedClients: Array<IClientInfo> = response.data.content.map((client: IClientInfo, index: number) => {
        const currentClient = { recordId: client.recordId, borrowerName: client.borrowerName, parentClient: client.parentClient, parentClientFk: client.parentClientFk };
        if (props.path === 'clients') {
          if (borrowerIdParam && client.recordId === parseInt(borrowerIdParam)){ selectedClientIndex = index }
        } else if (borrowerId && client.recordId === parseInt(borrowerId)) { selectedClientIndex = index }

        return currentClient;
      });
      setClients(mappedClients);
      const selectedMappedClient = mappedClients[selectedClientIndex];
      setSelectedClientContext(selectedMappedClient);
      setLastSelectedClient(selectedMappedClient);
    })
    .catch(error => console.log('INVENTORY WORKSHEET GET CLIENT ERROR: ', error));
  };

  const getInventories = () => {
    const currentBorrowerId = selectedClient.recordId === -1 ? borrowerIdParam : selectedClient.recordId;
    const getInputList = axiosInstance.get(`${API_DOMAIN}/inv/inputs/search/findIsArchived?pageNo=0&pageSize=9999&sortBy=recordId,ASC&borrowerId=${currentBorrowerId}&isArchived=false`);
    const getDefault = axiosInstance.get(`${API_DOMAIN}/inv/collaterals/search/findByBorrowerId?borrowerId=${currentBorrowerId}&pageNo=0&pageSize=9999&isArchived=true&isDefault=true`);

    axios.all([getInputList, getDefault])
      .then(axios.spread((response1, response2) => {

        if(response1.data.content === undefined){ return }
        
        if (props.path === 'reports'){ 
          handleReportsPage(response1);
          return;
        }
        
        if(response2.data.content === undefined){return;}
        handleInventoryPage(response1, response2);
      }))
      .catch(error => {
        setInventories([]);
        setSelectedInventory({ recordId: -1, invCollateralFk: -1, invCollateralName: '' });
        console.log('INVENTORY WORKSHEET GET INVENTORY ERROR: ', error);
      })
  };

  const handleReportsPage = (response1: AxiosResponse<any, any>) => {
    let selectedInventoryIndex = 0;
    const mappedInventories: Array<IInventory> = response1.data.content.map((inventory: IInventory, index: number) => {
      const currentInventory = { recordId: inventory.recordId, invCollateralFk: inventory.invCollateralFk, invCollateralName: inventory.invCollateralName };

      if (invCollateralId && inventory.invCollateralFk === parseInt(invCollateralId)){ selectedInventoryIndex = index; }
      return currentInventory;
    });

    setInventories(mappedInventories);
    const selectedMappedInventory = mappedInventories[selectedInventoryIndex] ?? { recordId: -1, invCollateralFk: -1, invCollateralName: '' };
    setSelectedInventory(selectedMappedInventory);
    setLastSelectedInventory(selectedMappedInventory);
  }

  const handleInventoryPage = (response1: AxiosResponse<any, any>, response2: AxiosResponse<any, any>) => {
    let selectedInventoryIndex = 0;
    const defaultCol = response2.data.content.find((response: IInventoryCollateral) => response.default && !response.archive)
    const mappedInventories: Array<IInventory> = response1.data.content.map((inventory: IInventory, index: number) => {
      const currentInventory = { recordId: inventory.recordId, invCollateralFk: inventory.invCollateralFk, invCollateralName: inventory.invCollateralName };
      if (invCollateralId && inventory.recordId === parseInt(invCollateralId)){ selectedInventoryIndex = index; }
      return currentInventory;
    });

    const defaultIndex = mappedInventories.findIndex(inv => inv.invCollateralFk === defaultCol.recordId)

    if (defaultIndex > -1) {
      const obj = mappedInventories.splice(defaultIndex, 1)[0]
      mappedInventories.unshift(obj)
    }

    setInventories(mappedInventories);
    const selectedMappedInventory = mappedInventories[selectedInventoryIndex] ?? { recordId: -1, invCollateralFk: -1, invCollateralName: '' };
    setSelectedInventory(selectedMappedInventory);
    setLastSelectedInventory(selectedMappedInventory);
  }

  const handleInventoryChange = (_event : any, newValue: IInventory | null) => {
    if (newValue === null) { return; }
    setLastSelectedInventory(newValue);
    if (isDirty) {
      setShowPrompt(true);
      return;
    }
    setSelectedInventory(newValue);
  };

  const handleDiscardChanges = () => {
    setSelectedClientContext(lastSelectedClient);
    setSelectedInventory(lastSelectedInventory);
    setIsDirty(false);
    setShowPrompt(false);
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    getInventories();
  }, [selectedClient]);

  useEffect(() => {
    if (isFirstRender) return;

    const isClientUninitialized = selectedClient.recordId === -1 || selectedClient.recordId === undefined;
    const isInventoryUninitialized = selectedInventory.invCollateralFk === -1 || selectedInventory.invCollateralFk === undefined;
    if(isClientUninitialized || isInventoryUninitialized){ return; }

    if (props.path === 'clients') {
      const isClientAlreadySetAsParam = borrowerIdParam === selectedClient.recordId.toString();
      const isInventoryAlreadySetAsParam = collateralIdParam === selectedInventory.invCollateralFk.toString();
      if (isClientAlreadySetAsParam && isInventoryAlreadySetAsParam) { return; }

      navigate(`/clients/${selectedClient.recordId}/settings/${selectedInventory.invCollateralFk}/inventory-worksheet`);
    } else {
      const isClientAlreadySetAsParam = borrowerId === selectedClient.recordId.toString();
      const isInventoryAlreadySetAsParam = invCollateralId === selectedInventory.invCollateralFk.toString();
      if (isClientAlreadySetAsParam && isInventoryAlreadySetAsParam) { return; }
    }
  }, [selectedClient, selectedInventory]);

  useEffect(() => {
    if (selectedClientContext === null || selectedClientContext === undefined || selectedClientContext.recordId === undefined || selectedClientContext.borrowerName === undefined) { return; }
    setSelectedClient({ recordId: selectedClientContext.recordId, borrowerName: selectedClientContext.borrowerName, parentClient: selectedClientContext.parentClient, parentClientFk: selectedClientContext.parentClientFk });
  }, [selectedClientContext]);

  return (
     <Box sx={styles.container} py={2} gap={2}>
      <Grid container sx={styles.gridSetting} spacing={2}>
        <Grid item xs={12} md={6} gap={1} sx={styles.dropdownGrid}>
          <Typography tabIndex={0} sx={styles.fieldText} component='label' htmlFor='combo-box-inventory'>
            Collateral Name
          </Typography>
          <Autocomplete
            id='combo-box-inventory'
            aria-label='Collateral Name Dropdown'
            disablePortal
            options={inventories}
            getOptionLabel={inventoryOption => inventoryOption.invCollateralName}
            renderOption={(prop, option: IInventory) => (
              <Box component='li' {...prop} key={option.recordId} sx={styles.dropdownText}>
                {option.invCollateralName}
              </Box>
            )}
            onChange={handleInventoryChange}
            renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, 'aria-label': 'Collateral Name Dropdown' }} />}
            size='small'
            sx={styles.dropdown}
            value={selectedInventory}
            componentsProps={{
              popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
              clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
            }
          />
        </Grid>
      </Grid>
      <Box sx={styles.totalAmountContainer}>
        <Typography tabIndex={0} sx={styles.labelTotalAmount}>
          Total Inventory Value:
        </Typography>
        <Typography tabIndex={0} sx={styles.valueTotalAmount}>
          {currencyFormat(totalInventoryWorksheetAmount.toString())}
        </Typography>
      </Box>
      <ConfirmModal
        open={showPrompt}
        onClose={() => { handleDiscardChanges(); }}
        onConfirm={() => { setShowPrompt(false); }}
        onButtonClose={() => { setShowPrompt(false); }}
        promptChecker
        title={`Confirm Navigation`}
        description={`You have unsaved changes. Are you sure you want to leave this page?`}
        yesButtonText={`Keep Editing`}
        noButtonText={`Discard Changes`}
        confirmOnly
      />
    </Box>
  );
};

export default InventoryWorksheetTopContainer;
