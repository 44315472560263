import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Theme, CSSObject, styled } from '@mui/material';
import { useState, useContext, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from './styles';
import { DrawerContext, DrawerContextInterface } from '../../context/drawerContext';
import { setWebOpen } from '../../actions/drawerActions';
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH, PERMISSIONS } from '../../utility/constants';
import { SelectedClientContext } from '../../context/selectedClientContext';
import { getPermissionsOfUser } from '../../utility/helper';
import { AuthContext } from '../../context/authContext';

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window; 
}

interface INavigation {
	name: string;
	url: string;
	icon: any;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: MINI_DRAWER_WIDTH,
  [theme.breakpoints.up('sm')]: {
    width: MINI_DRAWER_WIDTH,
  },
});

const WebDrawer = styled(Drawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open}) => ({
	flexShrink: 0,
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const unavailableForChildClients = ['Reports', 'Ineligible Settings'];

const ResponsiveDrawer = (props: Props) => {
	const { window } = props;
	const { state, dispatch } = useContext(DrawerContext);
	const navigate = useNavigate();
	const selectedClientContext = useContext(SelectedClientContext);
	const [ mobileOpen, setMobileOpen ] = useState(false);

	const MENUS: INavigation[] = [
		{
			name: 'Clients',
			icon: <PersonIcon color="primary" aria-label="Clients icon"/>,
			url: '/clients',
		},
		{
			name: 'Client Settings',
			icon: '',
			url: `/clients/${selectedClientContext?.selectedClient?.recordId}/settings`,
		},
		{
			name: 'File Import',
			icon: '',
			url: '/file-import',
		},
		{
			name: 'Ineligible Settings',
			icon: '',
			url: '/ineligible-settings',
		},
		{
			name: 'Reports',
			icon: '',
			url: '/reports',
		},
		{
			name: 'Lender Admin and Settings',
			icon: <SettingsIcon color="primary" aria-label="Lender Admin and Settings icon"/>,
			url: '/general-settings',
		},
		{
			name: 'Audit Trail',
			icon: '',
			url: '/audit-trail',
		}
	];

	//PERMISSION STATES
	const { canViewClients, setCanViewClients,
					canViewClientSettings, setCanViewClientSettings,
					canViewFileImport, setCanViewFileImport,
					canViewIneligibleSettings, setCanViewIneligibleSettings,
					canViewLenderSettings, setCanViewLenderSettings,
					canViewReports, setCanViewReports,
					canViewAssignedClients, setCanViewAssignedClients } = useContext<DrawerContextInterface>(DrawerContext);
	const authContext  = useContext(AuthContext);

	const handleMobileDrawerToggle = () => setMobileOpen(!mobileOpen);
	
	const location = useLocation();
	
	const isChildClient = useMemo(() => {
		return (selectedClientContext.selectedClient?.parentClientFk !== null && selectedClientContext.selectedClient?.parentClientFk !== undefined) 
	}, [selectedClientContext.selectedClient]);

	useEffect(() => {
		dispatch(setWebOpen(state.drawerHardOpen));
	}, [state.drawerHardOpen]);

	useEffect(() => {
		getPermissions();
	}, [location.pathname]);

	const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(authContext.state.uid, authContext.state.token);
		if (permissions[0] === 'error') {
			navigate('/')
		} else {
			setCanViewClients(permissions.includes(PERMISSIONS.VIEW_CLIENT));
			setCanViewAssignedClients(permissions.includes(PERMISSIONS.VIEW_ASSIGNED_CLIENT));
			setCanViewClientSettings(permissions.includes(PERMISSIONS.VIEW_CLIENT_SETTINGS));
			setCanViewIneligibleSettings(permissions.includes(PERMISSIONS.VIEW_INELIGIBLE_SETTINGS));
			setCanViewFileImport(permissions.includes(PERMISSIONS.VIEW_FILE_IMPORT));
			setCanViewReports(permissions.includes(PERMISSIONS.VIEW_REPORTS));
			setCanViewLenderSettings(permissions.includes(PERMISSIONS.VIEW_LENDER_SETTINGS));
		}	
  };

	const drawerOpen = state.drawerWebOpen || state.drawerMobileOpen;
	
	const isMenuActive = (menu: INavigation) => {
		const { pathname } = location;
		const { url, name } = menu;
		const isDrawerOpen = drawerOpen || false;
	
		const isExactPathMatch = pathname === url;
		const isMenuPathMatch = pathname.startsWith(url);
		const isSettingsPathMatch = pathname.includes('settings') && url.includes('settings');
		const isClientsPathMatch = pathname.includes('clients') && url.includes('clients');
		const isReportsPathMatch = pathname.includes('reports') && url.includes('reports');
		const isFileImportPathMatch = pathname.includes('file-import') && name === 'Clients';
	
		return (
			isExactPathMatch ||
			(!isDrawerOpen ? isMenuPathMatch : (isSettingsPathMatch && isClientsPathMatch)) ||
			(!isDrawerOpen ? (isReportsPathMatch && name === 'Clients') : isReportsPathMatch) ||
			(!isDrawerOpen && isFileImportPathMatch)
		);
	};

	const getDrawerPermissions = (canView: boolean, menu: INavigation, targetName: string) => {
		if (!canView && menu.name === targetName) return true;
		return false;
	}

	const getDrawer = () => (
		<Box 
			sx={styles.drawerFlexbox}>
			<List sx={styles.menu}
				{ ...!state.drawerHardOpen ? { 
					onMouseEnter: () => { dispatch(setWebOpen(true)) },
					onMouseLeave: () => { dispatch(setWebOpen(false)) },
				} : null}
			>
				{
					MENUS.map(menu => {
						if (
							getDrawerPermissions(canViewClients || canViewAssignedClients, menu, 'Clients') ||
							getDrawerPermissions(canViewClientSettings, menu, 'Client Settings') ||
							getDrawerPermissions(canViewFileImport, menu, 'File Import') ||
							getDrawerPermissions(canViewIneligibleSettings, menu, 'Ineligible Settings') ||
							getDrawerPermissions(canViewLenderSettings, menu, 'Lender Admin and Settings') ||
							getDrawerPermissions(canViewReports, menu, 'Reports')
						) {
							return null;
						}
						
						return (
						<>
							{
								<Link 
									to={isChildClient && unavailableForChildClients.includes(menu.name) ? '#' : menu.url} 
									key={menu.url} 
									style={styles.link} 
									aria-label={menu.name}
								>
									<ListItem 
										disabled={isChildClient && unavailableForChildClients.includes(menu.name)}
										sx={{
										...isMenuActive(menu) && styles.listItem,
										...((!drawerOpen && menu.icon === '') && {display: 'none'}),
										pointerEvents: (isChildClient && unavailableForChildClients.includes(menu.name)) ? 'none' : 'auto',
										opacity: (isChildClient && unavailableForChildClients.includes(menu.name)) ? 0.5 : 1
										}}
									>
										<ListItemIcon sx={{...isMenuActive(menu) && styles.listItemIcon}}>
											{menu.icon}
										</ListItemIcon>
										<ListItemText primary={menu.name} sx={{
											opacity: drawerOpen ? 1 : 0,
											...isMenuActive(menu) && styles.listItemText
										}}/>
									</ListItem>
								</Link>
							}
						</>
					)})
				}
			</List>
		</Box>
	);

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<Box
			component="nav"
			aria-label="mailbox folders"
		>
			<Drawer
				container={container}
				variant="temporary"
				open={state.drawerMobileOpen}
				onClose={handleMobileDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={styles.mobileDrawer}
			>
				{getDrawer()}
			</Drawer>
			
			<WebDrawer
				variant="permanent"
				sx={styles.webDrawer}
				open={state.drawerWebOpen}
			>
				{getDrawer()}
			</WebDrawer>
		</Box>
	);
}

export default ResponsiveDrawer;