import { ActionType, DrawerActions } from '../actions/drawerActions';

export interface DrawerState {
  drawerWebOpen: boolean,
  drawerMobileOpen: boolean,
  drawerHardOpen: boolean,
}

export const initialState: DrawerState = {
  drawerWebOpen: false,
  drawerHardOpen: false,
  drawerMobileOpen: false,
};

const drawerReducer = (state: DrawerState, action: DrawerActions): DrawerState => {
  switch (action.type) {
    case ActionType.TOGGLE_WEB_OPEN:
      return {
        ...state,
        drawerWebOpen: !state.drawerWebOpen,
      };
    case ActionType.SET_WEB_OPEN:
      return {
        ...state,
        drawerWebOpen: action.payload
      }
    case ActionType.TOGGLE_HARD_OPEN:
      return {
        ...state,
        drawerHardOpen: !state.drawerHardOpen,
      }
    case ActionType.TOGGLE_MOBILE_OPEN:
      return {
        ...state,
        drawerMobileOpen: !state.drawerMobileOpen,
      }
    default:
      return state;
  }
};

export default drawerReducer;