import { FC } from 'react';
import { IClientReportsProps } from '..';
import { ICustIneligible, IHeader } from '../..';
import ReportsTitle from '../../../../../components/reports-title';
import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import CustomerViewTable from '../../body/customer-view/table';
import styles from './styles';
import { formatCurrency } from '../../../../../utility/helper';
import UpcGrandTotalRow from '../grand-total-row';
import { IRfCalcSummary } from '../../../../../interfaces/rollForwardReportInterface';
import { IBorrowerCustReport, ICollateralCustReport, IGrandTotals } from '../../../../../interfaces/arIneligibleReport';

export interface IUpcCustViewProps extends IClientReportsProps {

}

export const nonIneligiblesHeader: (keyof ICustIneligible)[] = ['custName', 'custSrcId'];

const UpcCustView: FC<IUpcCustViewProps> = (props) => {
  const { borrowerCustReport, bbPeriodLabel, headers, currencyCode, rfvDisabled, rfSummaries, reportCodes } = props;

  /**
   * This function adds headers per each ineligible total. 
   * @returns A JSX component of headers row.
   */
  const getClientTotalHeaderRows = () => {
    return (
      <TableRow>
        <TableCell tabIndex={0} sx={styles.tableActionFooter}></TableCell>
        <TableCell tabIndex={0} sx={{...styles.tableTextFooter, ...styles.tableTextBodyLeft}}>
        </TableCell>
        {headers.map((header: IHeader) => {
          if (!nonIneligiblesHeader.includes(header.id)) {
            return (
              <TableCell
                tabIndex={0}
                key={header.id}
                sx={{
                  ...styles.tableTextFooter,
                  ...styles.tableTextBodyNumber,
                }}
              >
                {header.label}
              </TableCell>)
          }
        })}
      </TableRow>
    )
  }

  return (
    <>
    {borrowerCustReport?.map((item: IBorrowerCustReport) => {
      const { borrowerId, borrowerName, borrowerTotal, collateralReport } = item;
      const rfvRowAdded = borrowerTotal.find((item: IGrandTotals) => item.row === 'Roll Forward Variance');

      if (!rfvDisabled && !rfvRowAdded) {
        const clientRfvTotal: number = (rfSummaries ?? [])
          .filter((summary: IRfCalcSummary) => summary.borrowerId === borrowerId)
          .reduce((acc: number, summary: IRfCalcSummary) => {
            acc += summary.variance
            return acc;
          }, 0.0);
        
        const rfvTotalRow: IGrandTotals = {
          rowNum: 1,
          row: 'Roll Forward Variance',
          ineligibleAmount: clientRfvTotal,
        }

        borrowerTotal.unshift(rfvTotalRow);

        const clientTotalRow = borrowerTotal.find((item: IGrandTotals) => item.row === 'CLIENT TOTAL');
        if (clientTotalRow) {
          clientTotalRow.ineligibleAmount += clientRfvTotal;
          (clientTotalRow.eligibleAr as number) += clientRfvTotal;
        }
      }
      
      return (
        <Box key={borrowerId}>
          {/* Reports Title */}
          <ReportsTitle
            key={borrowerId}
            clientName={borrowerName}
            reportName='AR Ineligible Report'
            bbPeriod={bbPeriodLabel}
          />

          {/* AR Collateral Report */}
          {collateralReport.map((item: ICollateralCustReport) => {
            const { arCollateralId, arCollateralName, arCollateralTotal, custReport } = item;
            const rfvRowAdded = arCollateralTotal.find((item: IGrandTotals) => item.row === 'Roll Forward Variance');

            const rfCalcSummary = (rfSummaries ?? [])
              .find((summary: IRfCalcSummary) => summary.arCollateralId === arCollateralId);

            const hasNoRfv = !(rfCalcSummary?.rfBeginningBalanceId);

            if (!rfvDisabled && !rfvRowAdded) {
              const arCollateralRfvTotal: number = rfCalcSummary?.variance ?? 0.0;

              const rfvTotalRow: IGrandTotals = {
                rowNum: 1,
                row: 'Roll Forward Variance',
                ineligibleAmount: arCollateralRfvTotal,
              }

              arCollateralTotal.unshift(rfvTotalRow);

              const collateralTotalRow = arCollateralTotal.find((item: IGrandTotals) => item.row === 'Total');
              if (collateralTotalRow) {
                collateralTotalRow.ineligibleAmount += arCollateralRfvTotal;
                (collateralTotalRow.eligibleAr as number) += arCollateralRfvTotal;
              }
            }

            return (
              <Box key={arCollateralId}>
                <Typography tabIndex={0} fontSize='1rem' fontWeight='bold'>
                  {arCollateralName}
                </Typography>
                <CustomerViewTable
                  {...props}
                  custIneligibles={custReport}
                  exchangeRate={1}
                  grandTotal={null}
                  rfvIneligibleAmt={0} // To be handled by arCollateralTotal
                  hasNoRfv={hasNoRfv}
                  rfvDisabled={rfvDisabled}
                  arCollateralId={arCollateralId}
                  arCollateralTotal={arCollateralTotal}
                  reportCodes={reportCodes}
                />
              </Box>
            )
          })}
          
          {/* Client Total */}
          <TableContainer sx={styles.tableContainer}>
            <Table aria-label={`Client ${borrowerName} Totals`} size='small'>
              <TableBody>
                {getClientTotalHeaderRows()}
                {borrowerTotal.map((item: IGrandTotals) => {
                  const { rowNum, row } = item;

                  return (
                    <TableRow key={rowNum}>
                      <TableCell tabIndex={0} sx={styles.tableActionFooter}></TableCell>
                      <TableCell tabIndex={0} sx={{...styles.tableTextFooter, ...styles.tableTextBodyLeft}}>
                        <Box sx={{...styles.tableFooterLabelContainer}}>
                          <Box>{row}</Box>
                        </Box>
                      </TableCell>
                      {headers.map((header: IHeader) => {
                        let amount: any = 0.0;

                        if (!nonIneligiblesHeader.includes(header.id)) {
                          if (header.id === 'eligibleAR') amount = -(item.eligibleAr as number);
                          else if (header.id === 'Gross AR') amount = -item[header.id];
                          else amount = item[header.id];
                          
                          return (
                            <TableCell
                              tabIndex={0}
                              key={header.id}
                              sx={{
                                ...styles.tableTextFooter,
                                ...styles.tableTextBodyNumber,
                              }}
                            >
                              {(amount === 0 || Boolean(amount))
                                && formatCurrency(amount, currencyCode)}
                            </TableCell>
                          )
                        }
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )
    })}
    <Divider sx={styles.divider} />
    <UpcGrandTotalRow {...props} />
    </>
  )
}

export default UpcCustView;