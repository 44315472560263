import { circularProgressClasses } from "@mui/material";
import colors from "../../../../utility/colors";

const styles = {
  container: {
    position: 'fixed',
    bottom: '3vw',
    right: '2vw',
    zIndex: '9999',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: colors.WHITE,
    background: colors.PRIMARY,
    paddingY: '5px',
    paddingX: '15px',
    // '&.MuiAccordionSummary-root': {
    //   minHeight: '36px',
    // },
    // '.MuiAccordionSummary-content': {
    //   display: 'flex',
    //   flexDirection: 'row',
    //   alignItems: 'center',
    //   justifyContent: 'space-between',
    //   margin: '0 !important'
    // }
  },
  contentWrapper: {
    '&.Mui-expanded': {
      margin: 0
    }
  },
  contentHeader: {
    background: '#F7F7F7',
    justifyContent: 'space-between',
    paddingX: '15px',
    paddingY: '5px',
    alignItems: 'center'
  },
  content: {
    maxHeight: '8rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    width: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#B7B7B7',
    },
  },
  accordionDetails: {
    '&.MuiAccordionDetails-root': {
      padding: 0
    }
  },
  spinner: {
    color: colors.PRIMARY,
    animationDuration: '550ms',
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
    }
  },
  iconLeftSpacing: {
    marginLeft: '10px'
  },
  action: {
    padding: 0,
    marginRight: '5px'
  },
  smallFont: {
    fontSize: '12px'
  },
  accordionSummary: {
    '&.MuiAccordionSummary-root': {
      minHeight: '36px',
      padding: 0
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0 !important'
    }
  },
  reportLink: {
    px: 2,
    py: 1
  },
  hidden: {
    display: 'none'
  },
}

export default styles;