import colors from "../../../utility/colors"

const styles = {
  searchField: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
      borderRadius: "0px 4px 4px 0px",
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
    width          : '70%',
  },
  searchStyle: {
    display        : 'flex',
    alignSelf      : 'center',
    alignItems     : 'center',
    flexBasis      : 'auto',
  },
  filterStyle: {
    height: "36.6px",
    width: '30%',
    borderRadius: "4px 0px 0px 4px",
    backgroundColor: colors.WHITE
  }
}

export default styles