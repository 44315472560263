import colors from "../../../../../utility/colors";

const styles = {
  tabPanelBox: {
    padding: '8px 18px',
  },
  navigateButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  navigateButton: {
    textTransform: 'capitalize',
    paddingX: '2rem',
    height: '31px',
    marginRight: '10px',
    marginTop: '12px',
    boxShadow: 'none',
  },
  navigateButtonText: {
    fontSize: '15px',
  },
  buttonSubTabs: {
    margin: '16px 24px 0',
    paddingTop: '8px'
  },
  tabsContainer: {
    padding: '8px',
    '&.MuiBox-root': {
      padding: '0',
    }
  },
  tableContainerForVendor: {
    width: 'auto',
    overflowX: 'scroll',
    maxWidth: '100%',
  },
  table: {
    width: 'calc(100% - 20px)',
    margin: '0 10px',
  },
  tableHeadRow: {
    boxShadow: '0px 3px 6px #00000029',
  },
  tableHead: {
    backgroundColor: '#F7F7F7',
    color: 'inherit',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    border: 0,
    height: '2.5rem',
  },
  gridContainer: {
    boxShadow: '0px 3px 6px #00000029',
  },
  gridHead: {
    backgroundColor: '#F7F7F7',
    color: 'inherit',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '1.5rem',
    whiteSpace: 'nowrap',
    height: '3.5rem',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  tableSpacer: {
    height: '1rem',
  },
  tableRow: {
    backgroundColor: '#F7F7F7',
    '&:nth-of-type(2n)': {
      backgroundColor: 'inherit',
    },
  },
  tableCell: {
    fontSize: '0.9rem',
    border: 0,
    color: 'inherit',
    height: '2.5rem',
  },
  textContainer: {
    display: 'flex',
  },
  cellText: {
    display: 'block',
    fontSize: '14px',
    color: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '26ch',
  },
  iconDefaultSort: {
    color: '#767272',
    fontSize: '16px'
  },
  accordionSummary: {
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)'
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: '0px'
  },
  parentIdentifierChip: {
    marginLeft: '5px',
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"P"',
    },
    '& .MuiChip-label:hover:after': {
      content: '"Parent"',
    }
  },
  gridMainCustomerAccordionDetail: {
    backgroundColor: '#EAECEF',
    paddingY: '12px',
    paddingX: 0
  },
  gridChildCustomerAccordionDetail: {
    backgroundColor: '#F7F7F7',
    paddingY: '8px',
    paddingX: 0
  },
  gridVendorAccordionDetail: {
    backgroundColor: 'background.paper',
    paddingY: '8px',
    paddingX: 0
  },
  gridSubHeader: {
    fontSize: '14px',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  },
  paddingLeft: {
    pl: '1.55rem'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingY: '10px',
  }
};

export default styles;