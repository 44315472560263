const styles = {
  outmostContainer: {
    py: '17px',
    px: '17px',
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    border: '1px solid #E0E0E0',
  },

  tableContainer: {
    '&.MuiTableContainer-root': {
      px: '6px', // space offset for tableHeadRow's boxShadow
      width: 'auto',
    },
  },

  table: {
    '&.MuiTable-root': {
      minWidth: '1046px',
    },
  },

  tableBody: {
    '&.MuiTableBody-root::before': {
      display: 'block',
      content: '""',
      height: '8px',
    },
  },

  tableHeadRow: {
    '&.MuiTableRow-root': {
      backgroundColor: '#F7F7F7',
      boxShadow: '0px 3px 6px #00000029',
    },
  },

  tableHeadCell: {
    '&.MuiTableCell-head': {
      pl: '1rem',
      pr: '1.33rem', // tableCell padding + textField padding
      py: '1rem',
    },
  },

  tableHeadCellForDateCreated: {
    width: '20%'
  },

  tableHeadCellForDescription: {
    width: '50%'
  },

  tableHeadCellForIneligibleAmount: {
    width: '24%'
  },

  tableHeadCellForAction: {
    width: '6%'
  },

  tableHeaderText: {
    '&.MuiFormLabel-root': {
      fontWeight: 'bold',
      fontSize: '14px',
      color: 'black',
    },
  },

  asterisk:{
    color: '#AE2633'
  },

  tableRow: {
    '&.MuiTableRow-root': {
      verticalAlign: 'top',
      backgroundColor: '#FEFEFE',
    },
  },

  tableCell: {
    '&.MuiTableCell-root': {
      borderBottom: 'none',
      padding: '0.30rem 0.30rem 0 0rem',
    },
  },

  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      fontSize: '.9rem',
      backgroundColor: 'white',
      textAlign: 'right'
    },
    '& .MuiOutlinedInput-input': {
      padding: '0.5rem 1rem',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: 0,
      lineHeight: '14px',
      paddingTop: '4px',
    },
  },

  rightAlignedText: {
    textAlign: 'right',
  },

  centerAlignedText: {
    textAlign: 'center',
  },

  actionTableCell: {
    '&.MuiTableCell-root': {
      borderBottom: 'none',
      paddingTop: '0.30rem',
      paddingBottom: '0.30rem',
    },
  },

  actionButton: {
    paddingTop: '0.30rem',
    paddingBottom: '0.30rem',
  },

  buttonsContainer : {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '8px',
    marginTop: '10px',
 },

  loadingIcon: {
    '&.MuiCircularProgress-root': {
      color: 'inherit',
    }
  },

  buttonTabs: {
    paddingX: '6px', // space offset for tableHeadRow's boxShadow
    marginBottom: '10px',
    '.MuiTabs-flexContainer': {
      borderBottom: '1px solid #E5E2E2',
    },
  },

  hidden: {
    display: 'none'
  },
}

export default styles;
