const styles = {
  container: {
    border: '1px solid #AE2633',
    borderRadius: '4px',
    backgroundColor: '#FAF0F1',
    marginX: '8px',
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    padding: '20px 10px',
  },
  icon: {
    fontSize: '18px',
    color: '#AE2633',
    marginRight: '5px',
  },
  message: {
    fontSize: '13px',
  },
  invisible: {
    display: 'none',
  }
};

export default styles;
