import colors from "../../../../../../utility/colors";

const styles = {
  iconDefaultSort: {
    color: '#767272',
    fontSize: '16px'
  },
  tableContainer: {
    width: '100%',
    maxHeight: '30rem',
    paddingX: '3px',
    marginBottom: '2rem',
    paddingBottom: '0.5px',
  },
  tableHeader: {
    boxShadow: '0px 3px 6px #00000029',
  },
  tableTextHeader: {
    backgroundColor: '#EAEAEA',
    borderBottom: 0,
    fontSize: '15px',
    height: '2.5rem',
    '&:last-of-type': {
      paddingRight: '1.5rem'
    },
  },
  tableTextHeaderNumeric: {
    textAlign: 'right',
  },
  tableActionHeader: {
    backgroundColor: '#EAEAEA',
    borderBottom: 0,
    fontSize: '15px',
    height: '2.5rem',
    border: 0,
    paddingRight: '0.5rem',
    paddingLeft: 0,
  },
  tableActionFooter: {
    textAlign: 'center',
    backgroundColor: '#F2F7F8',
    color: '#000',
    paddingRight: '0.5rem',
    paddingLeft: 0,
    borderTop: `1px solid ${colors.NEUTRAL}`,
    borderBottom: `1px solid ${colors.NEUTRAL}`,
  },
  tableTextBodyLeft: {
    textAlign: 'left',
    minWidth: '15rem',
    maxWidth: '15rem',
  },
  tableTextBodyNumber: {
    paddingRight: '1.95rem'
  },
  tableTextFooter: {
    backgroundColor: '#F2F7F8',
    color: '#000',
    textAlign: 'right',
    borderTop: `1px solid ${colors.NEUTRAL}`,
    borderBottom: `1px solid ${colors.NEUTRAL}`,
    fontSize: '15px',
    fontWeight: 'bold',
    '&:last-of-type': {
      paddingRight: '1.5rem'
    },
  },
  tableRowBody: {
    '&:hover': {
      color: '#0075AD',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
  },
  tableRowBodyOdd: {
    backgroundColor: colors.WHITE,
  },
  tableFooterLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  tableRowVariance: {
    paddingBottom: '0.4rem'
  },
  tableRowCapDetails: {
    paddingBottom: '0.4rem',
  }
};
export default styles;