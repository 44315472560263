import { useContext } from 'react';
import { Autocomplete, Box, Chip, Grid, TextField, Typography } from '@mui/material';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { IneligibleAdjustmentsContext as IAContext, IIneligibleAdjustmentsContext as IAContextInterface } from '../../../context/ineligibleAdjustmentsContext';
import { NavigationWarningContext, INavigationWarningContext } from '../../../context/navigationWarningContext';
import { IClient } from '../../../interfaces/ineligibleAdjustmentsInterface';
import IneligibleAdjustmentsBreadCrumbs from '../breadcrumbs';
import styles from './styles';

const IneligibleAdjustmentsHeader: React.FC = () => {
  const { isDirty }                         = useContext(NavigationWarningContext) as INavigationWarningContext;
  const { setSelectedClient }               = useContext(SelectedClientContext);
  const {
    clients, selectedClient,
    setLastSelectedClient, setShowPrompt
  }                                         = useContext(IAContext) as IAContextInterface;

  const handleClientChange = (_event: any, newValue: IClient | null) => {
    if (newValue === null) { return; }
    setLastSelectedClient(newValue);
    if (isDirty) {
      setShowPrompt(true);
      return;
    }
    setSelectedClient(newValue);
  };

  /**
   * This function returns a boolean value depending on the parent status of the option.
   * @param option The name of the option
   * @returns True, if the option is a parent client, otherwise, false.
   */
  const isSelectedFieldNameParentFieldValue = (option: string) => {
    const parentClients = clients.filter(client => client.parentClient).map(client => client.borrowerName);
    return parentClients.includes(option);
  };

  return (
    <Box>
      <Box component='span' sx={styles.breadCrumbBox}>
        <Grid container sx={styles.headerContainer}>
          <Grid item xs={12} md={6} lg={8} xl={8.3}>
            <IneligibleAdjustmentsBreadCrumbs />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
            <Box sx={styles.clientBox}>
              <Typography tabIndex={0} variant='body2' sx={styles.labelStyle} component='label' htmlFor='combo-box-clients'>
                Client Name:
              </Typography>
              <Autocomplete
                id='combo-box-clients'
                aria-label='Client Name Dropdown'
                disablePortal
                options={clients.filter(client => client.parentClient !== true)}
                getOptionLabel={clientOption => clientOption.borrowerName}
                isOptionEqualToValue={(option:IClient, value:IClient) => option.recordId === value.recordId}
                renderOption={(prop, option) => {
                  return (
                    <Box component='li' {...prop} sx={{fontSize:'14px'}} key={option.recordId}>
                      <Box tabIndex={0} sx={styles.labelChipContainer}>{option.borrowerName}</Box>
                      <Box sx={{ ...styles.parentChipContainer, ...(!isSelectedFieldNameParentFieldValue(option.borrowerName) && styles.hidden) }}>
                        <Chip size='small' sx={styles.parentIdentifierChip} />
                      </Box>
                    </Box>
                  );
                }}
                onChange={handleClientChange}
                renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, 'aria-label': 'Client Name Dropdown' }} />}
                size='small'
                fullWidth
                sx={styles.clientComboBox}
                value={selectedClient}
                componentsProps={{
                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.titleContainer}>
        <Typography tabIndex={0} variant='h6' component='h1' sx={styles.title}>
          Ineligible Adjustments
        </Typography>
      </Box>
    </Box>
  );
};

export default IneligibleAdjustmentsHeader;
