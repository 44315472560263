import NavigationWarningProvider from '../../context/navigationWarningContext';
import LoanBalancesProvider, { LoanBalancesContext } from '../../context/loanBalancesContext';
import LoanBalanceTable from '../../components/loan-balances/table';
import LoanBalancesHeader from '../../components/loan-balances/header';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { ILoanBalancesContext } from '../../interfaces/loanBalancesInterface';
import styles from './styles';

const LoanBalancesPage = () => {
  const {viewLoanBalances} = useContext(LoanBalancesContext) as ILoanBalancesContext;
  return (
    <Box sx={{...(!viewLoanBalances && styles.hidden)}}>
      <LoanBalancesHeader />
      <LoanBalanceTable />
    </Box>
  );
};

const LoanBalances = () => {
  return (
    <NavigationWarningProvider>
      <LoanBalancesProvider>
        <LoanBalancesPage />
      </LoanBalancesProvider>
    </NavigationWarningProvider>
  )
}

export default LoanBalances;
