import { SyntheticEvent, useContext, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Field, FieldArrayRenderProps, FormikProps } from 'formik';
import { Autocomplete, Box, Button, Chip, Grid, IconButton, Paper, Switch, TextField, Typography, AccordionDetails, Accordion, AccordionSummary, createFilterOptions, Tooltip, FilterOptionsState, AutocompleteRenderInputParams, AutocompleteRenderGroupParams, AutocompleteRenderGetTagProps, CircularProgress } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { fieldTableList, fieldTypeList, fieldTypeListForUPC, NON_EXISTING, operationList } from '../../../../utility/constants';
import { FieldList, FieldTable, FieldValue, IFormikValuesForRuleAndOverridesPerSetting, IFormikValuesForUPCRuleAndOverridesPerSetting, IIneligibleCondition, IIneligibleRuleConditionGroup, IIneligibleRuleOverride, IIneligibleSettingsContext, IIneligibleSettingsPermissions, UserDefinedFieldList} from '../../../../interfaces/ineligibleSettingInterface';
import { IneligibleSettingsContext } from '../../../../context/ineligibleSettingsContext';
import { getDisplayedError } from '../../ineligible-setting-details';
import { RemoveItemModal } from '../../modals';
import UploadListModal from '../../modals/upload-list';
import styles from '../styles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { formatDate, getTextWidth } from '../../../../utility/helper';
import { SelectedClientContext } from '../../../../context/selectedClientContext';
import { IClient } from '../../../../interfaces';

interface IProps {
  formik:                 FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>;
  idx:                    number;
  idx2:                   number;
  idx3:                   number;
  override:               IIneligibleRuleOverride;
  conditionGroup:         IIneligibleRuleConditionGroup;
  condition:              IIneligibleCondition;
  conditionGroupHelpers:  FieldArrayRenderProps;
  conditionHelpers:       FieldArrayRenderProps;
  newGroup:               IIneligibleRuleConditionGroup;
}

interface MenuDropDownForValuesFieldProps extends React.HTMLAttributes<HTMLElement> {
  onMouseDownCallback: () => void;
}

const MenuDropdownForSingleValuesField: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = ({ children, ...other }) => (
  <Paper {...other} sx={styles.paperContainer}>
    {children}
  </Paper>
);

const MenuDropdownForMultipleValuesField: React.JSXElementConstructor<MenuDropDownForValuesFieldProps> = ({ children, onMouseDownCallback, ...other }) => (
  <Paper {...other} sx={styles.paperContainer}>
    <Button
      variant='contained'
      sx={styles.uploadListButton}
      onMouseDown={event => {
        event.preventDefault();
        onMouseDownCallback();
      }}
    >
      <Typography sx={styles.uploadListButtonText}>Upload List</Typography>
    </Button>
    {children}
  </Paper>
);

/**
 * Component for rendering condition row.
 * @param props The component props.
 * @returns The JSX element representing the condition row component.
 */
const ConditionRow: React.FC<IProps> = ({formik, idx, idx2, idx3, override, conditionGroup, condition, conditionGroupHelpers, conditionHelpers, newGroup}: IProps) => {
  const inputRefForSingleValuesField                                            = useRef<HTMLInputElement>(null);
  const inputRefForMultipleValuesField                                          = useRef<HTMLInputElement>(null);
  const observer                                                                = useRef<ResizeObserver | null>(null);

  const { selectedClient } = useContext(SelectedClientContext);
  const {
    fetchedIneligibleSettingDetails,
    selectedIneligibleIndex: ineligibleIndex,
    fieldTables, fieldList, userDefinedFieldList,
    fieldValues, parentCustomers,
    ineligibleListContainerRef,
    permissions,
  }                                                                             = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;
  const [isRemoveItemModalOpen, setIsRemoveItemModalOpen]                       = useState<boolean>(false);
  const [renderedValuesField, setRenderedValuesField]                           = useState<'single' | 'multiple'>('multiple');
  const [isUploadListModalOpen, setIsUploadListModalOpen]                       = useState<boolean>(false);
  const [expandedGroup, setExpandedGroup]                                       = useState<string>('');
  const [
    isTooltipForSingleValuesFieldOpen,
    setIsTooltipForSingleValuesFieldOpen
  ]                                                                             = useState<boolean>(false);
  const [uploadedValues, setUploadedValues]                                     = useState<string[]>([]);
  const [
    limitTagsForMultipleValuesField,
    setLimitTagsForMultipleValuesField
  ]                                                                             = useState<number>(1);

  const getFormikFieldNameForExcludedCustomersOnSelectedOverride = (idx: number) => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].excludedCustomers`;

  /**
   * This effect observes the resizing of the ineligible list container and updates limit tags for multiple values fields.
   * Also, triggers the update when field values change.
   */
  useEffect(() => {
    if (ineligibleListContainerRef === null || ineligibleListContainerRef.current === null) { return };
    observer.current = new ResizeObserver(updateLimitTagsForMultipleValuesField);
    observer.current.observe(ineligibleListContainerRef.current);
    observer.current.observe(window.document.body);
    return () => { observer.current?.disconnect(); };
  }, [ineligibleListContainerRef, condition.fieldValues]);

  /**
   *  This effect updates limit tags for multiple values fields when field values change.
   */
  useEffect(() => {
    updateLimitTagsForMultipleValuesField();
  }, [condition.fieldValues]);

  /**
   * This effect handles rendering and focus behavior for single and multiple values fields based on the operation and field values.
   */
  useEffect(() => {
    const isValuesFieldRerendering = isOperationSingleType(condition.operation) || isSelectedValuesForFieldByField();
    if (isValuesFieldRerendering) {
      inputRefForMultipleValuesField.current?.blur();
      renderedValuesField === 'multiple' && setRenderedValuesField('single');
      return;
    }
    inputRefForSingleValuesField.current?.blur();
    renderedValuesField === 'single' && setRenderedValuesField('multiple');
  }, [condition.operation, condition.fieldValues]);

  /**
   * This effect focuses on the appropriate input field based on operation and field values changes.
   */
  useEffect(() => {
    if (!isOperationSingleType(condition.operation) && isSelectedValuesForFieldByField() && hasFieldValuesChanged()) {
      inputRefForSingleValuesField.current?.focus();
    } else if (renderedValuesField === 'multiple' && hasFieldValuesChanged()) {
      inputRefForMultipleValuesField.current?.focus();
    }
  }, [renderedValuesField]);

  /**
   * This effect handles updating formik field values and errors when uploaded values change.
   */
  useEffect(() => {
    if (!uploadedValues.length) return;
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, uploadedValues);
    formik.setFieldError(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, uploadedValues.length > 0 ? undefined : 'Value is required');
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.isFieldByField`, false);
    formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
  }, [uploadedValues]);

  /**
   * This function generates the Formik field name for the selected condition.
   * @returns The Formik field name.
   */
  const getFormikFieldNameForSelectedCondition = () =>
    `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].ineligibleRuleConditionGroup[${idx2}].ineligibleRuleCondition[${idx3}]`;

  const isSelectedClientUPC = () => (selectedClient as IClient).parentClient;

  /**
   * This function combines and returns a list of all field types, including user-defined fields.
   * @returns An array of all field types.
   */
  const getAllFieldTypeList = useCallback(() => {
    if (isSelectedClientUPC()) { return [...fieldTypeListForUPC, ...(userDefinedFieldList as UserDefinedFieldList[])]; }
    return [...fieldTypeList, ...(userDefinedFieldList as UserDefinedFieldList[])];
  }, [userDefinedFieldList]);

  /**
   * Represents an array of field values based on the selected field and condition.
   * @returns An array of field values.
   */
  const currentFieldValues = useMemo(() => {
    const selectedFieldValues = fieldValues[condition.field];
    if (selectedFieldValues === undefined) { return []; }
    return [
      ...selectedFieldValues,
      ...(fieldList as FieldList[]) /* for field by field matching */
        .filter(field => {
          const currentFieldType = getAllFieldTypeList().find(fieldType => fieldType.fieldName === field.tableField);
          if (condition.fieldType === 'number') {
            return ['number'].includes(currentFieldType?.fieldType ?? '') && field.tableField !== condition.field;
          }
          return currentFieldType?.fieldType === condition.fieldType && field.tableField !== condition.field;
        })
        .map(field => {
          const currentFieldType = (isSelectedClientUPC() ? fieldTypeListForUPC : fieldTypeList).find(fieldType => fieldType.fieldName === field.tableField);
          if (currentFieldType === undefined) {
            return { field: field.description, fieldName: field.tableField, type: 'Field' };
          }
          return { field: currentFieldType.field, fieldName: currentFieldType.fieldName, type: 'Field' };
        })
        .sort((a, b) => a.field.localeCompare(b.field))
    ];
  }, [condition.field, condition.fieldType, fieldList, fieldValues, getAllFieldTypeList]);

  /**
   * This function generates a new ineligible rule condition for a given condition group.
   * @param irGroup The condition group to which the new condition will be added.
   * @returns The newly generated ineligible rule condition.
   */
  const generateIneligibleRuleCondition = (irGroup: IIneligibleRuleConditionGroup) => {
    const lastIrCondition = irGroup.ineligibleRuleCondition.slice(-1)[0]
    const newIrCondition: IIneligibleCondition = {
      ineligibleRuleConditionId: NON_EXISTING,
      ineligibleRuleConditionGroupId: lastIrCondition.ineligibleRuleConditionGroupId,
      order: lastIrCondition.order + 1,
      fieldTable: '',
      fieldType: '',
      field: '',
      operation: '',
      fieldValues: [],
      logicalOperator: irGroup.ineligibleRuleCondition[0].logicalOperator,
      isFieldByField: false,
    };
    return newIrCondition;
  };

  /**
   * This function checks if field values in a condition have changed compared to the original setting.
   * @returns `true` if field values have changed; otherwise, `false`.
   */
  const hasFieldValuesChanged = () => {
    const originalSetting = fetchedIneligibleSettingDetails.find(item => item.ineligibleSetting.ineligibleSettingId === formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.ineligibleSettingId);
    if (originalSetting === undefined) { return false; }
    const originalFieldValues = originalSetting.ineligibleRuleOverrides?.[idx]?.ineligibleRuleConditionGroup?.[idx2]?.ineligibleRuleCondition?.[idx3]?.fieldValues;
    return JSON.stringify(originalFieldValues) !== JSON.stringify(condition.fieldValues);
  };

  /**
   * This function determines whether the button for adding condition should be disabled based on the last condition in the group.
   * @returns `true` if the button should be disabled; otherwise, `false`.
   */
  const isAddConditionButtonDisabled = () => {
    const lastCondition = conditionGroup.ineligibleRuleCondition.slice(-1)[0];
    if (lastCondition.field !== '' || lastCondition.operation !== '' || lastCondition.fieldValues.length > 0) { return false; }
    return true;
  };

  /**
   * This function handles the removal of a condition or condition group and adds a new group if needed.
   */
  const handleConfirmRemoveCondition = () => {
    const isConditionGroupSingle = override.ineligibleRuleConditionGroup.length === 1;
    const isConditionSingle = conditionGroup.ineligibleRuleCondition.length === 1;
    formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
    if (isConditionGroupSingle && isConditionSingle) {
      conditionGroupHelpers.remove(idx2);
      conditionGroupHelpers.push(newGroup);
      return;
    }
    if (isConditionSingle) {
      conditionGroupHelpers.remove(idx2);
      return;
    }
    conditionHelpers.remove(idx3);
  };

  /**
   * This function groups field values by their type (e.g., 'Value' or 'Field').
   * @param option The field value or string to be categorized.
   * @returns The category type.
   */
  const groupFieldValuesByType = (option: FieldValue) => option.type;

  /**
   * This function retrieves the field type based on the field name.
   * @param fieldName The field name to search for.
   * @returns The matching field type or `undefined` if not found.
   */
  const getFieldTypeByFieldName = (fieldName: string) => {
    return getAllFieldTypeList().find(fieldType => fieldType.fieldName === fieldName);
  };

  /**
   * This function gets the label for values field options, considering field names and values.
   * @param option The field value or string to generate the label for.
   * @returns The label for the option.
   */
  const getOptionLabelForValuesField = (option: FieldValue | string) => {
    if (typeof option !== 'string') { return option.field; }
    const valueOnFieldList = getAllFieldTypeList().find(fieldType => fieldType.fieldName === option);
    if (valueOnFieldList !== undefined) { return valueOnFieldList.field; }
    return option;
  };

  /**
   * This function handles the opening and closing of an accordion panel.
   * @param panel The panel to be opened or closed.
   * @param event The triggering event object.
   * @param isExpanded Indicates whether the panel is expanded.
   */
  const handleOpen = (panel: string) => (_event: object, isExpanded: boolean) => setExpandedGroup(isExpanded ? panel : '');

  /**
   * This function checks if selected values for a field are of the "Field" type.
   * @returns `true` if values are "Field"; otherwise, `false`.
   */
  const isSelectedValuesForFieldByField = () => {
    if (condition.fieldValues.length === 0) { return false; }
    return getAllFieldTypeList().some(fieldType => fieldType.fieldName === condition.fieldValues[0]);
  };

  /**
   * This function determines whether a specific type should be hidden based on the operation and field values.
   * @param type The type to be checked (e.g., 'Value' or 'Field').
   * @returns `true` if the type should be hidden; otherwise, `false`.
   */
  const isTypeHidden = (type: string) => {
    if (type === 'Field' && !isOperationForFieldByField(condition.operation)) { return true; }
    if (isOperationSingleType(condition.operation)) { return false; }
    if (condition.fieldValues.length === 0) { return false; }
    if (isSelectedValuesForFieldByField()) { return type === 'Value'; }
    return type === 'Field';
  };

  /**
   * This function sets a tooltip for an input element if its content overflows.
   * @param event The triggering event.
   */
  const getTooltipForMultipleValuesField = (event: SyntheticEvent) => {
    if (event.currentTarget) {
      const target = event.currentTarget as HTMLInputElement;
      if (target.offsetWidth < target.scrollWidth) {
        target.setAttribute('title', target.value);
        return;
      }
      target.setAttribute('title', '');
    }
  };

  /**
   * This function updates the limit for displaying tags in a multiple values field.
   */
  const updateLimitTagsForMultipleValuesField = () => {
    if (inputRefForMultipleValuesField.current === null) { setLimitTagsForMultipleValuesField(1); return; }

    const container = inputRefForMultipleValuesField.current.parentElement;
    if (container === null) { setLimitTagsForMultipleValuesField(1); return; }
    const chipTexts = condition.fieldValues;
    if (chipTexts.length === 0) { setLimitTagsForMultipleValuesField(1); return; }

    const limitTagPlusMinimumNumberWidth = getTextWidth(`+${chipTexts.length > 1 ? chipTexts.length - 1 : 1}`, '100 14px Arial');
    const ALLOWANCE_WIDTH_FOR_NON_CHIP_CHILDREN = limitTagPlusMinimumNumberWidth + 50;
    const MAX_WIDTH_FOR_ALL_CHIPS = container.offsetWidth - ALLOWANCE_WIDTH_FOR_NON_CHIP_CHILDREN;

    const ALLOWANCE_PER_CHIP = 31.5; // other space occupied per chip that is not occupied by renderedChipText like padding, etc.
    const MINIMUM_FONT_SIZE = 13.2188;
    let sum = 0;
    let limitTags = 0;
    for (const chipText of chipTexts) {
      const isExactValue = !currentFieldValues.some(fieldValue => fieldValue.field === chipText);
      const renderedChipText = isExactValue ? `"${chipText}"` : chipText;
      const textWidth = getTextWidth(renderedChipText, '100 14px Arial'); // font style is not similar to rendered font style, only tweaked the values to produce textWidth as near (but not lesser) as the rendered width
      const chipTextWidth = textWidth > MINIMUM_FONT_SIZE ? textWidth : MINIMUM_FONT_SIZE;
      const chipWidth = chipTextWidth + ALLOWANCE_PER_CHIP;
      const currentSum = sum + chipWidth;
      if (currentSum > MAX_WIDTH_FOR_ALL_CHIPS) { setLimitTagsForMultipleValuesField(limitTags > 1 ? limitTags : 1); return; }
      limitTags += 1;
      sum = currentSum;
    }
    setLimitTagsForMultipleValuesField(limitTags);
  };

  /**
   * This function retrieves the field value based on the condition field name.
   * @returns The field value or null if not found.
   */
  const getValueForFieldName = () => (fieldList !== 'UNLOADED' ? fieldList: []).find(currentField => currentField.tableField === condition.field) ?? null;

  /**
   * This function checks if the operation is of a single type (e.g., '=', '<', '<=', '>', '>=').
   * @param operation The operation to be checked.
   * @returns `true` if the operation is a single type; otherwise, `false`.
   */
  const isOperationSingleType = (operation: string) => {
    switch (operation) {
      case '=':
      case '<>':
      case '<':
      case '<=':
      case '>':
      case '>=':
      case 'LIKE':
      case 'NOT LIKE':
        return true;
      default:
        return false;
    }
  };

  const isOperationForFieldByField = (operation: string) => {
    switch (operation) {
      case '=':
      case '<>':
      case '<':
      case '<=':
      case '>':
      case '>=':
        return true;
      default:
        return false;
    }
  };

  /**
   * This function checks if selected values are of the "Field" type for a specific field.
   * @param field The field to check for "Field" values.
   * @returns `true` if values are "Field by Field"; otherwise, `false`.
   */
  const isValuesOptionForFieldByField = (field: string) => {
    if (condition.fieldValues.length === 0) { return false; }
    const allFieldTypeList =  getAllFieldTypeList();
    const fieldType = allFieldTypeList.find(fieldType => fieldType.field === field);
    const isFieldOption = fieldType !== undefined && condition.fieldValues.includes(fieldType.fieldName);
    return isFieldOption;
  };

  /**
   * This function checks if a specific field is selected among the condition's field values.
   * @param field The field to check for selection.
   * @returns `true` if the field is selected; otherwise, `false`.
   */
  const isValuesOptionSelected = (field: string) => {
    return condition.fieldValues.includes(field) || isValuesOptionForFieldByField(field);
  };

  /**
   * This function checks if the selected values are related to parent fields.
   * @param option The option to be checked.
   * @returns `true` if values are related to parent fields; otherwise, `false`.
   */
  const isSelectedValuesOptionParentRelated = (option: FieldValue) => {
    if (option.type !== 'Value') { return false; };
    return ['parentCustSrcId', 'parentCustName', 'upcParentCustSrcId', 'upcParentCustName'].includes(condition.field);
  };

  /**
   * This function filters and creates options for field values.
   */
  const filterForValuesField = createFilterOptions<FieldValue>({ matchFrom: 'any', limit: 150, });

  /**
   * This function checks if a value is a valid number.
   * @param value The value to be checked.
   * @returns `true` if the value is a valid number; otherwise, `false`.
   */
  const isValueValidNumber = (value: string) => /^-?\d+(?:\.\d+)?$/.test(value);

  /**
   * This function checks if a value is a valid date.
   * @param value The value to be checked.
   * @returns `true` if the value is a valid date; otherwise, `false`.
   */
  const isValueValidDate = (value: string) => {
    const splitValues = value.split('-');
    if (splitValues.length !== 3) { return false; }
    return splitValues.reduce((isEachDatePieceValid, datePiece, index) => {
      const isValidNumber = /^\d+$/.test(datePiece);
      const datePieceToNumber= isValidNumber ? parseInt(datePiece) : NON_EXISTING;
      const isValidYear = datePiece.length === 4 && datePieceToNumber > 0 && datePieceToNumber <= 9999;
      const isValidMonth = datePiece.length === 2 && datePieceToNumber > 0 && datePieceToNumber <= 12;
      const isValidDay = datePiece.length === 2 && datePieceToNumber > 0 && datePieceToNumber <= 31;
      if (index === 0) { return isValidYear; }
      if (index === 1) { return isEachDatePieceValid && isValidMonth; }
      if (index === 2) { return isEachDatePieceValid && isValidDay; }
      return false;
    }, false);
  };

  /**
   * This function checks if a value is valid based on the field type.
   * @param value The value to be checked.
   * @returns `true` if the value is valid; otherwise, `false`.
   */
  const isValueValid = (value: string) => {
    const isValueForFieldByField = getAllFieldTypeList().some(fieldType => fieldType.field === value);
    if (isValueForFieldByField) { return value !== ''; }
    if (condition.fieldType === 'number') { return isValueValidNumber(value); }
    if (condition.fieldType === 'date') { return isValueValidDate(value); }
    return value !== '';
  };

  /**
   * This function handles the change for a single values field when Enter key is pressed.
   * @param value The value to be handled.
   */
  const handleEnterChangeForSingleValuesField = (value: string | FieldValue | null) => {
    const isValueForFieldByField = getAllFieldTypeList().some(fieldType =>
      value !== null && typeof value !== 'string' && fieldType.fieldName === value.fieldName
    );
    if (isValueForFieldByField) { handleChangeForSingleValuesField(value); return;  }
    if (value !== null && value !== '') { return; }
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, []);
    formik.setFieldError(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, 'Value is required');
    formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
  };

  /**
   * This function handles the change for a single values field.
   * @param value The value to be handled.
   */
  const handleChangeForSingleValuesField = (value: string | FieldValue | null) => {
    const isFieldValue = value !== null && typeof value !== 'string' && 'fieldName' in value;
    const isValidStringValue = typeof value === 'string' && isValueValid(value);
    const isValidValue = isFieldValue || isValidStringValue;
    if (value === null || !isValidValue) {
      formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, []);
      formik.setFieldError(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, 'Value is required');
      formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
      return;
    }
    formik.setFieldError(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, undefined);
    setFieldValuesForSingleValuesField(value);
  };

  /**
   * This function sets field values for a single values field.
   * @param value The value to be set.
   */
  const setFieldValuesForSingleValuesField = (value: string | FieldValue) => {
    const fieldValue = getAllFieldTypeList().find(fieldType => fieldType.field === value);
    formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
    if (typeof value === 'string' && fieldValue !== undefined) { /* for field by field if value is string */
      formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, [fieldValue.fieldName]);
      formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.isFieldByField`, true);
      return;
    }
    if (typeof value === 'string') { /* for exact & field values (string) */
      formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, [value]);
      formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.isFieldByField`, false);
      return;
    }
    const isValueForFieldByField = getAllFieldTypeList().some(fieldType => fieldType.fieldName === value.fieldName);
    if (isValueForFieldByField) { /* for field by field if value is FieldValue */
      formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, [value.fieldName]);
      formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.isFieldByField`, true);
      return;
    }
    /* for exact & field values if value is FieldValue */
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, [value.field]);
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.isFieldByField`, false);
  };

  /**
   * This function handles pasting text and processes the pasted values.
   * @param event The keyboard event.
   */
  const handlePasteTexts = async (event: React.KeyboardEvent) => {
    const pressedCopyAndPaste = (navigator.userAgent.indexOf('Mac') !== -1 ? event.metaKey : event.ctrlKey) && event.key === 'v';
    if (!pressedCopyAndPaste) { return; }
    event.preventDefault();
    const clipboardText = await navigator.clipboard.readText();
    const separatedPastedTexts = clipboardText.split('\r\n');
    const allValues = separatedPastedTexts.map(line => line.split(',').map(text => text.trim())).flat()
    const filteredPastedTexts = allValues.filter(text => text !== '');
    if (setUploadedValues) { setUploadedValues(filteredPastedTexts); }
  };

  /**
   * This function determines the input type for a single values text field based on the field type and selection.
   * @returns The input type (e.g., 'text', 'number', or 'date').
   */
  const getTypeForSingleValuesTextField = () => {
    if (condition.fieldType === 'date' && !isSelectedValuesForFieldByField()) return 'date';
    if ((condition.fieldType === 'number') && !isSelectedValuesForFieldByField()) return 'number';
    return 'text';
  };

  /**
   * This function generates a tooltip for the single values field if its content overflows.
   * @param children The child element to be wrapped with the tooltip.
   * @returns The wrapped child element with a tooltip.
   */
  const getTooltipForSingleValuesField = (children: JSX.Element) => {
    const hasSingleValuesFieldTextOverflowed = inputRefForSingleValuesField.current !== null && inputRefForSingleValuesField.current.scrollWidth > inputRefForSingleValuesField.current.clientWidth;
    const hasTooltipTitle = hasSingleValuesFieldTextOverflowed && isTooltipForSingleValuesFieldOpen;
    const tooltipTitle = hasTooltipTitle ? inputRefForSingleValuesField.current.value : '';
    return (
      <Tooltip
        title={tooltipTitle}
        open={isTooltipForSingleValuesFieldOpen}
        placement='right-start'
        onClose={() => setIsTooltipForSingleValuesFieldOpen(false)}
        PopperProps={{ disablePortal: true }}
      >
        { children }
      </Tooltip>
    );
  };

  /**
   * This function returns a component for a single value field using the Autocomplete component.
   * It provides an input field with suggestions for field values.
   */
  const getSingleValuesField = () => {
    const handleRenderInput = (params: AutocompleteRenderInputParams) => (
      getTooltipForSingleValuesField(
        <TextField
          {...params}
          inputRef={inputRefForSingleValuesField}
          id={`${getFormikFieldNameForSelectedCondition()}.fieldValues`}
          name={`${getFormikFieldNameForSelectedCondition()}.fieldValues`}
          label='Values'
          variant='outlined'
          type={getTypeForSingleValuesTextField()}
          multiline={false}
          sx={{ ...styles.autocompleteValuesField, ...(getTypeForSingleValuesTextField() === 'date' && styles.datePicker), ...styles.upDownButton}}
          InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
          InputProps={{
            ...params.InputProps,
            'aria-label': 'Value Field',
            endAdornment: (
              <>
                {isValuesFieldLoading() ? <CircularProgress color='inherit' size={15} sx={styles.loadingIconForFieldValues} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          {...getDisplayedError(formik, `${getFormikFieldNameForSelectedCondition()}.fieldValues`)}
          onBlur={(event) => { handleChangeForSingleValuesField(event.target.value); }}
          onWheel={(event) => event.target instanceof HTMLElement && event.target.blur()} /* disable changing value when scrolling on number inputs */
        />
      )
    );
    const handleRenderOption = (prop: React.HTMLAttributes<HTMLLIElement>, option: FieldValue) => {
      const formattedValue = typeof option !== 'string' && isValueValidDate(option.field) 
      ? formatDate(option.field,"MM/DD/YYYY") : option.field;
      return (
        <Box tabIndex={0} component='li' {...prop} key={`value-option-${option.field}`} value={option.field}
          sx={{ ...styles.option, ...(isValuesOptionSelected(option.field) && styles.selectedOption) }}
        >
          <Box tabIndex={0} sx={styles.labelChipContainer}>{option.label ?? formattedValue}</Box>
          <Box sx={{ ...styles.parentChipContainer, ...(!isSelectedValuesOptionParentRelated(option) && styles.hidden) }}>
            <Chip size='small' sx={styles.parentIdentifierChip} />
          </Box>
        </Box>
      )
    };
    const getPaperComponent = (props: React.HTMLAttributes<HTMLElement>) => MenuDropdownForSingleValuesField({ ...props });
    return (
      <Autocomplete
        freeSolo
        selectOnFocus
        value={condition.fieldValues[0] ?? ''} /* using null as fallback will not cause a rerender */
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateCriteriaAndSettings || !condition.field}
        loading={isValuesFieldLoading()}
        options={currentFieldValues}
        groupBy={groupFieldValuesByType}
        noOptionsText='No Values available'
        getOptionLabel={getOptionLabelForValuesField}
        filterOptions={handleFilterOptionsForValuesField}
        renderInput={handleRenderInput}
        renderOption={handleRenderOption}
        renderGroup={handleRenderGroupForValuesField}
        PaperComponent={getPaperComponent}
        onBlur={() => { setExpandedGroup(''); }}
        onChange={(_event, value) => handleEnterChangeForSingleValuesField(value)}
        onMouseOver={() => setIsTooltipForSingleValuesFieldOpen(true)}
        componentsProps={{
          popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
          clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
        }}
      />
    );
  };

  /**
   * This function returns a component for a multiple values field using the Autocomplete component.
   * It provides an input field with suggestions for multiple field values.
   */
  const getMultipleValuesField = () => {
    const getPaperComponent = (
      condition.fieldValues.length === 0
        ? (props: React.HTMLAttributes<HTMLElement>) => MenuDropdownForMultipleValuesField({onMouseDownCallback: () => setIsUploadListModalOpen(true), ...props})
        : undefined
    );
    return (
      <Autocomplete
        clearOnBlur
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateCriteriaAndSettings || !condition.field}
        disableCloseOnSelect
        loading={isValuesFieldLoading()}
        freeSolo
        limitTags={limitTagsForMultipleValuesField}
        multiple
        value={condition.fieldValues}
        options={currentFieldValues}
        groupBy={groupFieldValuesByType}
        noOptionsText='No Values available'
        getOptionLabel={getOptionLabelForValuesField}
        filterOptions={handleFilterOptionsForValuesField}
        renderInput={handleRenderInputForMultipleValuesField}
        renderOption={handleRenderOptionForMultipleValuesField}
        renderGroup={handleRenderGroupForValuesField}
        renderTags={handleRenderTagsForMultipleValuesField}
        PaperComponent={getPaperComponent}
        onChange={handleChangeForMultipleValuesField}
        onBlur={(_error) => setExpandedGroup('')}
        componentsProps={{
          popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
          clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0 },
        }}
      />
    );
  };

  /**
   * This function handles the filtering of options in the Autocomplete for field values.
   * It also suggests creating a new value if the input is not empty and valid.
   */
  const handleFilterOptionsForValuesField = (options: FieldValue[], params: FilterOptionsState<FieldValue>) => {
    const [optionsOnGroupValue, optionsOnGroupField] = options.reduce((separated, option) => {
      const [valueOptions, fieldOptions] = separated;
      if (option.type === 'Field') { return [[...valueOptions], [...fieldOptions, option]]; }
      return [[...valueOptions, option], [...fieldOptions]];
    }, [[], []] as FieldValue[][]);
    const filteredValues = [...filterForValuesField(optionsOnGroupValue, params), ...optionsOnGroupField]; /* not include field values on limit options */
    /* Suggest the creation of a new value */
    const { inputValue } = params;
    const isValueEmptySpace = inputValue === '';
    const isValueExistingOnOptions = options.some((option) => inputValue === option.field);
    const isValueExistingOnFieldList = getAllFieldTypeList().some(fieldType => fieldType.field === inputValue);
    const isValueValidToShow = !isValueExistingOnOptions || isValueExistingOnFieldList;
    if (!isValueEmptySpace && isValueValidToShow  && !isTypeHidden('Value')) {
      return [({ field: inputValue, type: 'none', label: `Add "${inputValue}"` }), ...filteredValues];
    }
    return filteredValues;
  };

  const isValuesFieldLoading = () => {
    if ([fieldTables, fieldList, userDefinedFieldList, parentCustomers].includes('UNLOADED')) { return false; }
    const selectedField = (fieldList as FieldList[]).find(currentField => currentField.tableField === condition.field) ?? null;
    if (selectedField === null) { return false; }
    return fieldValues[selectedField.tableField] === undefined;
  };

  /**
   * This function renders the input field for the multiple values field using Autocomplete.
   */
  const handleRenderInputForMultipleValuesField = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      inputRef={inputRefForMultipleValuesField}
      id={`${getFormikFieldNameForSelectedCondition()}.fieldValues`}
      name={`${getFormikFieldNameForSelectedCondition()}.fieldValues`}
      label='Values'
      variant='outlined'
      multiline={false}
      sx={{ ...styles.autocompleteValuesField }}
      InputProps={{
        ...params.InputProps,
        'aria-label': 'Value Field',
        endAdornment: (
          <>
            {isValuesFieldLoading() ? <CircularProgress color='inherit' size={15} sx={styles.loadingIconForFieldValues} /> : null}
            {params.InputProps.endAdornment}
          </>
        )
      }}
      onKeyDown={handlePasteTexts}
      {...getDisplayedError(formik, `${getFormikFieldNameForSelectedCondition()}.fieldValues`)}
    />
  );

  /**
   * This function renders each option in the Autocomplete dropdown for the multiple values field.
   */
  const handleRenderOptionForMultipleValuesField = (prop: any, option: FieldValue) => {
    const formattedValue = typeof option !== 'string' && isValueValidDate(option.field) 
    ? formatDate(option.field,"MM/DD/YYYY") : option.field;
    return (
      <Box component='li' {...prop} key={`multivalue-option-${option.field}`} value={option.field}
        sx={{...styles.option, ...(isValuesOptionSelected(option.field) && styles.selectedOption)}}
        onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
          if (!isValuesOptionSelected(option.field)) { prop?.onClick?.(event); return; } /* only proceeds with adding option (done by prop.onClick) if option.field is not selected */
          const filteredFieldValues = condition.fieldValues.filter(fieldValue => fieldValue !== option.field); /* otherwise remove option.field in list */
          formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, filteredFieldValues); /* and update fieldValues with updated list */
          formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.isFieldByField`, false);
          formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
        }}
      >
        <Box sx={styles.labelChipContainer}>{option.label ?? formattedValue}</Box>
        <Box sx={{ ...styles.parentChipContainer, ...(!isSelectedValuesOptionParentRelated(option) && styles.hidden)}}>
          <Chip size='small' sx={styles.parentIdentifierChip} />
        </Box>
      </Box>
    );
  };

  /**
   * This function renders a group of options in the Autocomplete dropdown for field values.
   */
  const handleRenderGroupForValuesField = (params: AutocompleteRenderGroupParams) => {
    const { key, children, group } = params;
    if (group === 'none' ) { return <Box key={`value-group-${key}`} sx={styles.exactValueSuggestionContainer}>{children}</Box>; }
    if (isTypeHidden(group)) { return <></>; }
    return (
      <Accordion
        key={`value-group-${key}`}
        expanded={expandedGroup === group}
        disableGutters
        onChange={handleOpen(group)}
        elevation={0}
        sx={styles.accordion}
      >
        <AccordionSummary expandIcon={<ExpandMore />} sx={styles.accordionSummary}>{group}</AccordionSummary>
        <AccordionDetails sx={styles.accordionDetails}>
          {children}
        </AccordionDetails>
      </Accordion>
    );
  };

  /**
   * This function renders tags for the multiple values field in the Autocomplete.
   */
  const handleRenderTagsForMultipleValuesField = (tags: FieldValue[], getTagProps: AutocompleteRenderGetTagProps) => {
    return tags.map((tag: FieldValue, index: number) => {
      /* Note: this is a special case in MUI wherein the tags here corresponds to the fieldName itself. This will not result to [Object object] when stringified. */
      const value = tag.toString(); /* NOSONAR */
      const isFieldByField = currentFieldValues.some(fieldValue => fieldValue.fieldName === value);
      if (isFieldByField) {
        const fieldType = getFieldTypeByFieldName(value);
        const formattedTag =  fieldType !== undefined ? fieldType.field : '';
        return (
          <Chip
            {...getTagProps({ index })}
            label={formattedTag}
            size='small'
            variant='outlined'
            sx={{ ...styles.autoCompleteChipTag }}
          />
        )
      }
      const isValueOnFieldList = getAllFieldTypeList().some(fieldType => fieldType.field === value);
      const isExactValue = !currentFieldValues.some(fieldValue => fieldValue.field === value) || isValueOnFieldList;
      const formattedTag = isExactValue ? `"${value}"` : value;
      const reformattedTag = isValueValidDate(formattedTag) ? formatDate(formattedTag,"MM/DD/YYYY") : formattedTag;
      return (
        <Tooltip key={`multivalue-tag-${value}`} title={formattedTag.length >= 18 ? (value ?? reformattedTag) : ''}>
          <Chip
            {...getTagProps({ index })}
            label={reformattedTag}
            size='small'
            variant='outlined'
            sx={{ ...styles.autoCompleteChipTag, }}
          />
        </Tooltip>
      );
    });
  };

  /**
   * This function handles the change in values for the multiple values field.
   */
  const handleChangeForMultipleValuesField = (_event: SyntheticEvent<Element, Event>, values: readonly (string | FieldValue)[]) => {
    const valuesForFormik: string[] = values
      .filter(value => typeof value === 'string' ? isValueValid(value) : isValueValid(value.field))
      .map(value => {
        if (typeof value === 'string') { return value; }
        return value.fieldName ? value.fieldName : value.field;
      })
      .filter((currentValue, currentIndex, originalValues) => {
        const hasSimilarExistingValue = originalValues.some((value, index) => currentValue === value && currentIndex !== index);
        return !hasSimilarExistingValue; /* to deselect values, filter out value if it is duplicated */
      });
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, valuesForFormik);
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.isFieldByField`, false);
    formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
    if (valuesForFormik.length === 0) {
      formik.setFieldError(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, 'Value is required');
      return;
    }
    formik.setFieldError(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, undefined);
  };

  /**
   * This function handles the change in the selected field and sets related form values.
   */
  const handleFieldChange = () => async (_event: SyntheticEvent<Element, Event>, value: FieldList | null) => {
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, []); /* clear field values */
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.isFieldByField`, false);
    formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
    const fieldType = value === null ? undefined : getFieldTypeByFieldName(value.tableField);
    if (value === null || fieldType === undefined) {
      formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.field`, '');
      formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldType`, '');
      formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldTable`, '');
      formik.setFieldError(`${getFormikFieldNameForSelectedCondition()}.field`, 'Field is required');
      return;
    }
    const field = getField(value);
    const fieldTable = getFieldTable(field);
    const tableName = fieldTableList.find(currentFieldTable => currentFieldTable.name === fieldTable.name) as NonNullable<{ name: string, fieldTable: string }>;
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldTable`, tableName.fieldTable);
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.field`, field.tableField);
    formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldType`, fieldType.fieldType);
    formik.setFieldError(`${getFormikFieldNameForSelectedCondition()}.field`, undefined);
  };

  /**
   * This function retrieves a field from the list based on the provided value.
   */
  const getField = (value: FieldList) => {
    return (fieldList as FieldList[]).find(currentField => currentField.description === value.description) as NonNullable<FieldList>;
  };

  /**
   * This function retrieves a field table based on the provided field.
   */
  const getFieldTable = (field: FieldList) => {
    return (fieldTables as FieldTable[]).find(fieldTable => fieldTable.recordId === field.documentTypeFk) as NonNullable<FieldTable>;
  };

  /**
   * This function renders the field name field using Autocomplete.
   */
  const renderFieldNameField = () => (
    <Autocomplete
      value={getValueForFieldName()}
      disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateCriteriaAndSettings}
      loading={[fieldTables, fieldList, userDefinedFieldList, parentCustomers].includes('UNLOADED')}
      options={fieldList !== 'UNLOADED' ? fieldList : []}
      getOptionLabel={option => option.description}
      noOptionsText='No Field Names available'
      renderInput={params => (
        <TextField
          {...params}
          id={`${getFormikFieldNameForSelectedCondition()}.field`}
          name={`${getFormikFieldNameForSelectedCondition()}.field`}
          label='Field Name'
          variant='outlined'
          sx={{ ...styles.autocompleteField }}
          {...getDisplayedError(formik, `${getFormikFieldNameForSelectedCondition()}.field`)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                { [fieldTables, fieldList, userDefinedFieldList, parentCustomers].includes('UNLOADED') ? <CircularProgress color='inherit' size={15} sx={styles.loadingIconForFieldName} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      renderOption={(prop, option) => (
        <Box component='li' {...prop} key={`field-option-${option.tableField}`} sx={styles.overrideField}>
          {option.description}
        </Box>
      )}
      ListboxProps={{ style: styles.conditionFieldListBox }}
      onChange={handleFieldChange()}
      componentsProps={{
        popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
        clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
      }}
    />
  );

  /**
   * This function renders the operation field using Autocomplete.
   */
  const renderOperationField = () => (
    <Autocomplete
      value={operationList.find(operation => operation.value === condition.operation) ?? null}
      disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateCriteriaAndSettings}
      options={operationList}
      getOptionLabel={option => option.display}
      noOptionsText='No Operations available'
      renderInput={params => (
        <TextField
          {...params}
          id={`${getFormikFieldNameForSelectedCondition()}.operation`}
          name={`${getFormikFieldNameForSelectedCondition()}.operation`}
          label='Operation'
          variant='outlined'
          sx={{ ...styles.autocompleteField }}
          inputProps={{ ...params.inputProps, styles: styles.textForAutoComplete, onMouseEnter: getTooltipForMultipleValuesField }}
          {...getDisplayedError(formik, `${getFormikFieldNameForSelectedCondition()}.operation`)}
        />
      )}
      renderOption={(prop, option) => (
        <Box component='li' {...prop} key={`operator-option-${option.display}`} sx={styles.overrideField}>
          {option.display}
        </Box>
      )}
      ListboxProps={{ style: styles.conditionFieldListBox }}
      onChange={(_event, operation) => {
        formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
        if (operation === null) {
          formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.operation`, operation);
          formik.setFieldError(`${getFormikFieldNameForSelectedCondition()}.operation`, 'Operation is required');
          return;
        }
        formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.operation`, operation.value);
        formik.setFieldError(`${getFormikFieldNameForSelectedCondition()}.operation`, undefined);
        if (!isOperationForFieldByField(operation.value) && isSelectedValuesForFieldByField()) {
          formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, []);
          formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.isFieldByField`, false);
          return;
        }
        if (isOperationSingleType(operation.value)) {
          formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.fieldValues`, condition.fieldValues.length > 0 ? [condition.fieldValues[0]] : []);
          formik.setFieldValue(`${getFormikFieldNameForSelectedCondition()}.isFieldByField`, isSelectedValuesForFieldByField());
        }
      }}
      componentsProps={{
        popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
        clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
      }}
    />
  );

  /**
   * This function renders the values field based on the rendering mode.
   */
  const renderValuesField = () => renderedValuesField === 'multiple' ? getMultipleValuesField() : getSingleValuesField();

  /**
   * This function renders the logical operator or add condition button.
   */
  const renderLogicalOperatorOrAddConditionButton = () => {
    const isLastCondition =  conditionGroup.ineligibleRuleCondition.length === idx3 + 1;
    if (conditionGroup.ineligibleRuleCondition.length === 0 || isLastCondition) {
      return (
        <Button
          data-testid='add-condition-button'
          variant='contained'
          disabled={isAddConditionButtonDisabled()}
          sx={{...styles.saveButton, ...styles.addConditionButton, ...(!(permissions as IIneligibleSettingsPermissions).canAddCriteriaAndSettings && styles.hidden) }}
          onClick={() => conditionHelpers.push(generateIneligibleRuleCondition(conditionGroup))}
        >
          +Add
        </Button>
      );
    }
    return (
      <Field
        data-testid='logical-connector-button'
        name={`${getFormikFieldNameForSelectedCondition()}.logicalOperator`}
        component={Switch}
        disabled={idx3 !== 0 || !(permissions as IIneligibleSettingsPermissions).canUpdateCriteriaAndSettings}
        value={conditionGroup.ineligibleRuleCondition?.[0]?.logicalOperator === 'AND'}
        checked={conditionGroup.ineligibleRuleCondition?.[0]?.logicalOperator === 'AND'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
          conditionGroup.ineligibleRuleCondition?.forEach((_, conditionIndex) => {
            formik.setFieldValue(
              `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].ineligibleRuleConditionGroup[${idx2}].ineligibleRuleCondition[${conditionIndex}].logicalOperator`,
              event.target.checked ? 'AND' : 'OR'
            );
          });
        }}
        sx={styles.customSwitch}
      />
    );
  };

  return (
    <>
      <Grid key={`condition-container-${idx}${idx2}${idx3}`} container sx={styles.conditionRowGrid}>
        <Box width={'28%'}> { renderFieldNameField() } </Box>
        <Box width={'20%'}> { renderOperationField() } </Box>
        <Box width={'33%'}> { renderValuesField() } </Box>
        <Box>
          <Grid container sx={styles.addConditionGrid}>
            { renderLogicalOperatorOrAddConditionButton() }
            <IconButton
              sx={{...styles.removeConditionIconButton, ...(!(permissions as IIneligibleSettingsPermissions).canDeleteCriteriaAndSettings && styles.hidden)}}
              onClick={() => setIsRemoveItemModalOpen(true)}
              aria-label='Remove icon'
              data-testid='remove-condition-button'
            >
              <HighlightOffIcon sx={styles.removeConditionIcon} />
            </IconButton>
          </Grid>
        </Box>
      </Grid>
      <RemoveItemModal
        isOpen={isRemoveItemModalOpen}
        handleClose={() => setIsRemoveItemModalOpen(false)}
        handleConfirm={() => handleConfirmRemoveCondition()}
      />
      <UploadListModal
        isOpen={isUploadListModalOpen}
        handleClose={() => setIsUploadListModalOpen(false)}
        handleConfirm={() => setIsUploadListModalOpen(true)}
        setUploadedValues={setUploadedValues}
        fieldList={(fieldList !== 'UNLOADED' ? fieldList : []).filter(currentField => currentField.tableField === condition.field)}
      />
    </>
  );
}

export default ConditionRow;
