import * as Yup from 'yup';

const inventoryValuationSchema = Yup.object({
    inventoryWorksheets: Yup.array(
      Yup.object({
        inventoryType: Yup.string().required('Inventory Type is required'),
        valuationAmount: Yup.string().required('Valuation Amount is required'),
        deductions: Yup.string().required('Deduction Amount is required'),
        valueRate: Yup.number().typeError('Value Rate must be a number').required('Value Rate is required')
      })
    )
    .uniquePropertyDisregardCase('inventoryType', 'Inventory Type must be unique')
  });

export default inventoryValuationSchema;