import colors from "../../../../utility/colors";

const styles = {
  modalBackground: {
    bgcolor: '#FFFFFF',
    borderRadius: '5px',
    left: '50%',
    position: 'absolute',
    boxShadow: 15,
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '550px',
    display: 'flex',
    flexDirection: 'column',
  },

  modalTitleContainer: {
    borderBottom: `1px solid ${colors.NEUTRAL}`,
    paddingBottom: '1rem',
    marginBottom: '1rem',
  },

  dialog: {
    backgroundColor: colors.WHITE,
    borderRadius: '0.5rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30rem',
    padding: '2rem',
  },
  
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginBottom: '0.5rem',
    borderBottom: `1px solid ${colors.NEUTRAL}`,
  },

  dropdown: {
    fontSize: '14px',
    backgroundColor: 'white',
    width: '17rem',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      fontSize: '14px',
      backgroundColor: 'white',
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: 0,
      lineHeight: '14px',
      paddingTop: '0.2rem',
    },
  },
  
  dropdownContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '1rem',
    width: '100%',
  },

  dropdownLabel: {
    whiteSpace: 'nowrap',
    marginRight: '1rem',
    marginTop: '0.45rem'
  },

  cancelButton: {
    textTransform: 'none',
    width: '5rem',
  },

  saveButton: {
    textTransform: 'none',
    width: '5rem',
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#99BFED',
    },
  },
};

export default styles;
