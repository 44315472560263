import colors from "../../../utility/colors"

const styles = {
  accordionSummary:{
    margin: '0px', 
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
    } 
  },
  overflowBox: {
    maxHeight: '80%', 
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#B7B7B7',
    },
  },
  sectionBox:{
    backgroundColor: colors.WHITE, 
    boxSizing: 'border-box',
    height: '38rem', 
    p: '1rem', 
    width: '48%', 
  },
  orphanHeader:{
    boxShadow: '0px 3px 6px #00000029',  
    backgroundColor: '#F7F7F7', 
    height: '3.2rem', 
    alignItems: 'center', 
    p: '6px 43.8px', 
    mt: '1rem'
  },
  sectionTableContainer: {
    maxHeight: '85%',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#B7B7B7',
    },
  },
  sectionTableHeaderRow: {
    boxShadow: '0px 3px 6px #00000029',  
    backgroundColor: '#F7F7F7',
  },
  orphanTableSelectColumn: {
    width: '5%',
    paddingY: '5px',
    paddingRight: 0,
    borderBottom: 'none',
  },
  orphanTableNameCell: {
    width: '35%',
    paddingY: '5px',
    paddingLeft: 0,
    borderBottom: 'none',
  },
  orphanTableHeaderCell: {
    width: '15%',
    paddingY: '5px',
    borderBottom: 'none',
  },
  orphanTableHeaderDateCreated: {
    width: '10%',
    paddingY: '5px',
    borderBottom: 'none',
  },
  sectionTableSelectCell: {
    width: '5%',
  },
  sectionTableHeaderText: {
    color:"inherit", 
    fontWeight:'bold', 
    fontSize:'0.875rem',
  },
  parentNameHeaderCell: {
    width: '25%',
    '&:last-child': {
      width: '5%',
    },
  },
  sectionTableHead: {
    marginBottom: '1rem',
    '& .MuiTableCell-root': {
      backgroundColor: '#F7F7F7',
    }
  },
  parentWithChildSectionHeader:{
    boxShadow: '0px 3px 6px #00000029',  
    backgroundColor: '#F7F7F7', 
    height: '3.2rem', 
    alignItems: 'center', 
    pl: '2.75rem',
    pr: '1rem', 
    mt: '1rem',
    justifyContent: 'space-between'
  },
  parentWithChildTitle:{
    color:"inherit", 
    fontWeight:'bold', 
    fontSize:'0.875rem', 
  },
  childrenHeaderSelectCell: {
    width: '2%',
    borderBottom: 'none',
    backgroundColor: '#EAECEF',
  },
  childrenHeaderNameCell: {
    width: '25%',
    textAlign: 'left',
    borderBottom: 'none',
    backgroundColor: '#EAECEF',
  },
  childrenHeaderCell: {
    width: '25%',
    borderBottom: 'none',
    backgroundColor: '#EAECEF',
    '& .MuiTypography-root': {
      textAlign: 'right',
    },
    '&:last-child': {
      width: '5%',
    },
  },
  childrenHeaderTypography: {
    fontWeight: 'normal',
    fontSize: '14px',
  },
  orphanTitle:{
    color:"inherit", 
    fontWeight:'bold', 
    fontSize:'0.875rem', 
    ml: '0.5rem',
    paddingLeft: '18px',
    boxSizing: "border-box", 
    pt: '0.65rem'
  },
  orphanTitleUpc:{
    color:"inherit", 
    fontWeight:'bold', 
    fontSize:'0.875rem', 
    ml: '0.5rem',
    paddingLeft: '3.5rem'
  },
  parentRow: {
    cursor: 'pointer',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    }
  },
  parentSelectCell: {
    width: '5%',
    paddingY: '12px',
    borderBottom: 'none',
    '& .MuiTypography': {
      textAlign: 'left'
    }
  },
  parentNameCell: {
    width: '25%',
    paddingY: '12px',
    borderBottom: 'none',
    '& .MuiTypography': {
      textAlign: 'left'
    }
  },
  parentTableCell: {
    paddingY: '12px',
    marginY: '1rem',
    borderBottom: 'none',
    textAlign: 'center',
    '&:last-child': {
      textAlign: 'right',
      width: '5%',
    },
  },
  tableDataNameCell: {
    paddingY: '5px',
    borderBottom: 'none',
    '& .MuiTypography': {
      textAlign: 'left'
    }
  },
  tableDataCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'center',
    '&:last-child': {
      textAlign: 'right',
    },
  },
  tableDataTypography: {
    fontSize: '14px',
    color:"inherit", 
  },
  searchField: {
    width: '100%',
    backgroundColor: colors.WHITE,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: colors.WHITE,
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  flexCenter: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  parentModal: {
    width: 1, py: '10px', 
    borderTop: '1px solid #E9ECEF', 
    borderBottom: '1px solid #E9ECEF'
  },
  circleIcon: {
    height: '15px', 
    color: colors.PRIMARY, 
    display: 'flex', 
    alignSelf: 'flex-end'
  },
  parentTitle: {
    width: '50%', 
    fontSize: '16x', 
    textAlign: 'center'
  },
  dropSection: {
    border: '2px dashed #707070', 
    borderRadius: '5px', 
    my: '10px', 
    display: 'flex', 
    justifyContent: 'center', 
    py: '10px'
  },
  dropSecionActive: {
    border: '2px dashed #154A8A',
    color:  '#154A8A'
  },
  button: {
    justifyContent: 'flex-end', 
    width: '100%', 
    pt: '5px'
  },
  renameParent: {
    '& .MuiTextField-root': {
     paddding: 0
    }
  },
  title: {
    fontWeight: 'bold',
    ml: '24px',
    fontSize: '18px', 
    color: colors.PRIMARY
  },
  saveBar: {
    display: 'flex', 
    justifyContent: 'space-between', 
    my: '1rem', 
    width: '100%'
  },
  maxWidth: {
    width: '100%'
  },
  saveCancelButton: {
    display: 'flex', 
    mr: '1rem', 
    columnGap: '10px'
  },
  gridSection: {
    justifyContent: 'space-between', 
    mb: '2.5rem'
  },
  selectionLabels: {
    py: '10px',
    justifyContent: 'space-between',
    bgcolor: 'background.paper',
  },
  customerLabel: {
    pl: '2.75rem'
  },
  customerLabelUPC: {
    pl: '1rem'
  },
  actionLabel: {
    pr: '0.8rem'
  },
  noPermissionBox: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    background: '#FFFFFF',
    padding: '15px'
  },
  autocompleteHeight: {
    maxHeight: 200,
    mr: '2px'
  },
  dropdownOptionsFontSize: {
    fontSize: '14px',
  },
  autocompleteField: {
    fontSize: '14px',
    '&&&& .MuiAutocomplete-input::placeholder': {
      color: '#707070',
      opacity: '100%'
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      backgroundColor: 'white',
      height: '36.6px',
      margin: '0px'
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center',
      position: 'absolute',
      top: '-4px',
      left: '-4px',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#949494',
    },
    '&&&& .MuiAutocomplete-endAdornment': {
      right: '7px'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: 0,
      lineHeight: '14px',
      paddingTop: '2px',
    },
  },
  textBoxSpace: {
    pr: '2px'
  },
  tableSpacer: {
    height: '1rem',
  },
  clearButton: {
    color: colors.PRIMARY_LIGHT,
    fontSize: '14px',
  },
  clearButtonBox: {
    height: '1rem',
    display: 'flex', 
    justifyContent: 'flex-end', 
    paddingBottom: '1rem',     
  }
}

export default styles