const styles = {
  buttonTabs: {
    '.MuiTabs-Box': {
      border: '1px solid #E0E0E0',
    },
    '.MuiTabs-scroller': {
      background: '#F7F7F7',
    },
    '.MuiButtonBase-root.MuiTab-root': {
      borderRadius: '3px 3px 0 0',
      textTransform: 'none',
      width: '220px',
      marginRight: '5px',
      padding: '8px',
      boxShadow: '0 -5px 30px -7px #E0E0E0',
      color: '#000000',
    },
    '.MuiButtonBase-root.MuiTab-root.Mui-selected': {
      background: 'white',
      color: '#000000',
    },
    '.MuiTabs-indicator': {
      display: 'none',
    },
    '.MuiTabs-flexContainer': {
      paddingTop: '10px',
    },
  },
  tabBox: {
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    borderRadius: '3px 3px 0 0',
  },
  visible: {
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
  verbiageContainer: {
    py: '17px',
    px: '17px',
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    border: '1px solid #E0E0E0',
  },
  verbiage: {
    textAlign: 'center'
  },
  tableSize: {
    minWidth: 500,
  },
};

export default styles;
