import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useBlocker } from '../../../utility/hooks';
import ConfirmModal from '../confirm-modal';
import ThreeOptionsModal from '../three-options-modal';

interface IPromptProps {
  isOpen?         : boolean;
  when            : boolean | undefined;
  isEditing       : Dispatch<SetStateAction<boolean>> | ((arg: boolean) => void);
  submitCallback? : () => void;
}

const Prompt: React.FC<IPromptProps> = ({ isOpen, when, isEditing, submitCallback }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(false)
  }, []);

  // handle blocking when user click on another route
  const handleBlockedNavigation = useCallback(
    (nextLocation: any) => {
      if (
        !confirmedNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation]
  );

  const confirmNavigation = useCallback(() => {
    isEditing(false);
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  const navigateAndSave = useCallback(() => {
    submitCallback?.();
    isEditing(false);
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  const onButtonCloseNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(false);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return (
    (submitCallback ? (
      <ThreeOptionsModal
        open={isOpen ?? showPrompt}
        onOption1={confirmNavigation}
        onOption2={navigateAndSave}
        onCancel={cancelNavigation}
        onButtonClose={onButtonCloseNavigation}
        title={`Confirm Navigation`}
        description={`You have unsaved changes. Are you sure you want to leave this page?`}
        option1Text={`Proceed without Saving`}
        option2Text={`Save and Proceed`}
        cancelButtonText={`Cancel`}
      />
      ) : (
      <ConfirmModal
        open={isOpen ?? showPrompt}
        onClose={confirmNavigation}
        onConfirm={cancelNavigation}
        onButtonClose={onButtonCloseNavigation}
        promptChecker={true}
        title={`Confirm Navigation`}
        description={`You have unsaved changes. Are you sure you want to leave this page?`}
        yesButtonText="Keep Editing"
        noButtonText="Discard Changes"
      />
    ))
  );
};

export default Prompt;
