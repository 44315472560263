import { API_DOMAIN } from "../utility/constants";

const api = {
  PUBLIC                : `api/public`,
  FORGOT_PASSWORD       : `api/public/forgot-password`,
  LOGIN                 : `auth/login`,
  NEW_PASSWORD          : `api/public/new-password`,
  USERS                 : `api/users`,
  USERS_CHANGE_PASSWORD : `api/users/changePassword`,
  USERS_BY_EMAIL        : `api/users/search/findByEmail`,
  USERS_BY_TOKEN        : `api/users/search/findByToken`,
  USER_BY_EMAIL         : `${API_DOMAIN}/auth/users/user/findByEmail`,
  USER_BY_UID           : `${API_DOMAIN}/auth/users/user`,
  ALL_USERS             : `${API_DOMAIN}/auth/users`,
};

export const authAPI = {
  RETRIEVE_TOKEN       : `${API_DOMAIN}/auth/retrieveToken`,
  CHECK_TOKEN_VALIDITY : `${API_DOMAIN}/auth/checkTokenValidity`,
}

export const arCollateralAPI = {
  FIND_BY_BORROWER_ID                 : `${API_DOMAIN}/ar/collaterals/search/findByBorrowerId`,
  FIND_IS_ARCHIVED                    : `${API_DOMAIN}/ar/collaterals/search/findIsArchived`,
  FIND_AR_SETTINGS_BY_AR_COLLATERAL_ID: `${API_DOMAIN}/ar/borrowerInputs/search/findByARCollateralId`,
  FIND_IS_ARCHIVED_BY_BORROWER_INPUT  : `${API_DOMAIN}/ar/borrowerInputs/search/findIsArchived`,
  FIND_CURRENCY_IDS                   : `${API_DOMAIN}/ar/borrowerInputs/upc/findUpcCurrencyIds`,
  FIND_CURRENCY_IDS_FOR_AP_AGING      : `${API_DOMAIN}/ar/borrowerInputs/upc/findUpcCurrencyIdsForAPAging`,
  FIND_CHILD_CLIENTS_AR               : `${API_DOMAIN}/ar/collaterals/search/findAllChildClientsActiveARCollaterals`
}

export const rolePermissionAPI = {
  GET_ALL_ROLES               : `${API_DOMAIN}/auth/roles`,
  GET_ALL_PERMISSIONS         : `${API_DOMAIN}/auth/roles/permissions`,
  GET_ROLES_OF_USER           : `${API_DOMAIN}/auth/users/roles`,
  CHECK_PERMISSIONS_OF_USER   : `${API_DOMAIN}/auth/users/checkPermission`,
}

export const arCustomersAPI = {
  MAIN_ENDPOINT                                        : `${API_DOMAIN}/ar/customers`,
  FIND_BY_AR_COLLATERAL_ID                             : `${API_DOMAIN}/ar/customers/search/findByARCollateralId`,
  FIND_BY_BORROWER_ID                                  : `${API_DOMAIN}/ar/customers/search/findByBorrowerId`,
  FIND_OVERRIDES_BY_CUSTOMER_ID                        : `${API_DOMAIN}/ar/customerOverrides/search/findByARCustomerId`,
  OVERRIDES_MAIN_ENDPT                                 : `${API_DOMAIN}/ar/customerOverrides`,
  FIND_BY_AR_COLLATERAL_ID_NO_PAGINATION               : `${API_DOMAIN}/ar/customers/search/findByARCollateralIdWithNoPagination`,
  FIND_BY_PARENT_BORROWER_ID_NO_PAGINATION             : `${API_DOMAIN}/ar/customers/search/findByParentBorrowerIdWithNoPagination`,
  FIND_PARENT_IDS_BY_AR_COLLATERAL_ID                  : `${API_DOMAIN}/ar/customers/search/findParentIdsByARCollateralId`,
  FIND_GRAND_CHILDREN_CUSTOMER_BY_CHILDREN_CLIENT_IDS  : `${API_DOMAIN}/ar/customers/search/findByChildrenClientIds`,
  FIND_CUSTOMER_BY_FIELD                               : `${API_DOMAIN}/ar/customers/search/findByFieldName`,
  FIND_CUSTOMER_BY_FIELD_BORROWER_ID                   : `${API_DOMAIN}/ar/customers/search/findByFieldNameBorrowerId`,
  FIND_CLIENT_CHILD_CUSTOMER_BY_FIELD                  : `${API_DOMAIN}/ar/customers/search/findUpcChildByFieldName`,
  FIND_PARENT_CUSTOMER_BY_PARENT_BORROWER_ID           : `${API_DOMAIN}/ar/customers/search/findParentCustomerByParentBorrowerId`,
  FIND_AR_AMOUNT_MAP_BY_AR_COLLATERAL_ID               : `${API_DOMAIN}/ar/transactions/search/findMapOfSumForARTransactionsOnARCollateralFk`,
  FIND_AP_AMOUNT_MAP_BY_AR_COLLATERAL_ID               : `${API_DOMAIN}/ar/apTransactions/search/findMapOfSumForAPTransactionsOnARCollateralFk`,
  FIND_AR_AMOUNT_MAP_BY_PARENT_BORROWER_ID             : `${API_DOMAIN}/ar/transactions/search/findMapOfSumForARTransactionsOnParentBorrowerFk`,
  FIND_AP_AMOUNT_MAP_BY_PARENT_BORROWER_ID             : `${API_DOMAIN}/ar/apTransactions/search/findMapOfSumForAPTransactionsOnParentBorrowerFk`,
  FIND_BY_CRITERIA                                     : `${API_DOMAIN}/ar/customers/search/findByCriteria`,
  CLEAR_ALL_NEW_CUSTOMERS_BY_CRITERIA                  : `${API_DOMAIN}/ar/customers/clearAllNewByCriteria`,
  CLEAR_NEW_CUSTOMERS_BY_IDS                           : `${API_DOMAIN}/ar/customers/clearNewByIds`,
  FIND_BY_CONTRAS                                      : `${API_DOMAIN}/ar/customers/search/findByContras`,
  DELETE_CUSTOMERS                                     : `${API_DOMAIN}/ar/customers/deleteCustomers`,
  ARCHIVE_CUSTOMERS                                    : `${API_DOMAIN}/ar/customers/archiveCustomers`,
}

export const arVendorsAPI = {
  MAIN_ENDPOINT                                     : `${API_DOMAIN}/ar/vendors`,
  FIND_BY_AR_COLLATERAL_ID                          : `${API_DOMAIN}/ar/vendors/search/findByARCollateralIdWithNoPagination`,
  FIND_BY_PARENT_BORROWER_ID                        : `${API_DOMAIN}/ar/vendors/search/findByParentBorrowerIdWithNoPagination`,
  FIND_BY_CRITERIA                                  : `${API_DOMAIN}/ar/vendors/search/findByCriteria`,
  FIND_UNLINKED_BY_AR_COLLATERAL_ID_NO_PAGINATION   : `${API_DOMAIN}/ar/vendors/search/findUnlinkedByARCollateralIdWithNoPagination`,
  FIND_UNLINKED_BY_AR_COLLATERAL_ID                 : `${API_DOMAIN}/ar/vendors/search/findUnlinkedByARCollateralId`,
  FIND_UNLINKED_BY_PARENT_BORROWER_ID_NO_PAGINATION : `${API_DOMAIN}/ar/vendors/search/findUnlinkedByBorrowerIdWithNoPagination`,
  FIND_UNLINKED_BY_PARENT_BORROWER_ID               : `${API_DOMAIN}/ar/vendors/search/findUnlinkedByBorrowerId`,
  CLEAR_ALL_NEW_VENDORS_BY_CRITERIA                 : `${API_DOMAIN}/ar/vendors/clearAllNewByCriteria`,
  CLEAR_NEW_VENDORS_BY_IDS                          : `${API_DOMAIN}/ar/vendors/clearNewByIds`,
  DELETE_VENDORS                                    : `${API_DOMAIN}/ar/vendors/deleteVendors`,
  ARCHIVE_VENDORS                                   : `${API_DOMAIN}/ar/vendors/archiveVendors`,
}

export const arTransactionAPI = {
  GET_BB_PERIODS_BY_BORROWER_ID       : `${API_DOMAIN}/ar/transactions/bbPeriods`,
  GET_BB_PERIODS_BY_AR_COLLATERAL_ID  : `${API_DOMAIN}/ar/transactions/bbPeriods/arCollateralId`,
  GET_AVAILABLE_UPC_DATES             : `${API_DOMAIN}/ar/transactions/upc/findUpcAsOfDates`,
}

export const glTransactionAPI = {
  MAIN_ENDPOINT       : `${API_DOMAIN}/gl/transactions`,
  LIST_MAIN_ENDPOINT  : `${API_DOMAIN}/gl/transactions/list`,
} 

export const bbPeriodAPI = {
  MAIN_ENDPOINT                           : `${API_DOMAIN}/bbPeriods`,
  FIND_BY_BORROWER_ID                     : `${API_DOMAIN}/bbPeriods/search/findByBorrowerId`,
  FIND_CALC_BBPERIOD_BY_BORROWER_ID       : `${API_DOMAIN}/ar/calculateIneligibles/bbPeriods`,
  FIND_CALC_BBPERIOD_BY_AR_COLLATERAL_ID  : `${API_DOMAIN}/ar/calculateIneligibles/bbPeriods/arCollateralId`
}

export const bbCalcDateAPI = {
  FIND_DATES_BY_BORROWER_ID : `${API_DOMAIN}/bbCalcDates/search/findDatesByBorrowerId`,
  ADD_BB_CALC_DATE          : `${API_DOMAIN}/bbCalcDates`
};

export const borrowerAPI = {
  FIND_BY_IS_VISIBLE    : `${API_DOMAIN}/borrowers/search/findByIsVisible`,
  FIND_BY_CRITERIA      : `${API_DOMAIN}/borrowers/search/findByCriteria`,
  FIND_BY_ID            : `${API_DOMAIN}/borrowers`,
  FIND_CHILD_CLIENTS    : `${API_DOMAIN}/borrowers/search/findChildClients`,
}

export const inventoryAPI = {
  FIND_IS_ARCHIVED          : `${API_DOMAIN}/inv/collaterals/search/findIsArchived`,
  FIND_IS_ARCHIVED_BY_INPUT : `${API_DOMAIN}/inv/inputs/search/findIsArchived`,
}

export const ineligiblesAPI = {
  FIND_CODE_BY_BORROWER_ID  : `${API_DOMAIN}/ineligibleCodes/search/findByBorrowerId`,
  FIND_CODE_BY_CRITERIA     : `${API_DOMAIN}/ineligibleCodes/search/findByCriteria`,
}

export const bbCalcSummaryCustomerAPI = {
  CALCULATE_INELIGIBLES: `${API_DOMAIN}/ar/calculateIneligibles/ineligibleSettings`,
};

export const ineligibleSettingAPI = {
  FIND_BY_BORROWER_ID_AND_AR_COLLATERAL_ID    : `${API_DOMAIN}/ineligibleSetting/search/findByBorrowerIdAndARCollateralId`,
  GET_APPLICABLE_CUSTOMERS                    : `${API_DOMAIN}/ineligibleSetting/override/applicableCustomers`,
  GET_APPLICABLE_CUSTOMERS_UPC                : `${API_DOMAIN}/ineligibleSetting/override/applicableCustomersUpc`,
  CHECK_IF_RFV_DISABLED                       : `${API_DOMAIN}/ineligibleSetting/checkRFV`,
  CHECK_IF_UPC_RFV_DISABLED                   : `${API_DOMAIN}/ineligibleSetting/upc/checkRFV`,
  HAS_UPC_SETTINGS_AND_RULE                   : `${API_DOMAIN}/ineligibleSetting/hasUpcSettingsAndRule`
}

export const ineligibleRuleAPI = {
  FIND_BY_INELIGIBLE_SETTING_ID: `${API_DOMAIN}/ineligibleRule/search/findByIneligibleSettingIdAndArCollateralId`
}

export const fileImportAPI = {
  document: {
    MAIN_ENDPOINT                       : `${API_DOMAIN}/document`,
    ARCHIVE_FILE                        : `${API_DOMAIN}/document/archive`,
    PROCESS_FILE                        : `${API_DOMAIN}/document/process`,
    SELECT_SHEETS                       : `${API_DOMAIN}/document/selectSheets`,
    FIND_EXCLUDED_ROW_SETTING_IND       : `${API_DOMAIN}/document/search/findLatestExcludedRow`,
    FIND_EXCLUDED_ROW_SETTING_UPC       : `${API_DOMAIN}/document/search/findLatestExcludedRowByBorrower`,
  },
  documentIssue: {
    MAIN_ENDPOINT                       : `${API_DOMAIN}/documentIssue`,
    FIND_BY_BORROWER_ID                 : `${API_DOMAIN}/documentIssue/search/findByBorrowerId`,
    FIND_BY_DOCUMENT_ID                 : `${API_DOMAIN}/documentIssue/search/findByDocumentId`,
    LIST_MAIN_ENDPOINT                  : `${API_DOMAIN}/documentIssue/list`,
  },
  dataMapping: {
    MAIN_ENDPOINT                       : `${API_DOMAIN}/dataMapping`,
    GET_DATA_MAPPING_BY_DOCUMENT_ID     : `${API_DOMAIN}/dataMapping/search/findByDocumentId`,
    APPLY_DATA_MAPPING                  : `${API_DOMAIN}/dataMapping/apply`,
    GET_EXISTING_MAPPINGS               : `${API_DOMAIN}/dataMapping/search/findExistingMappings`,
    GET_EXISTING_MAPPINGS_BY_BORROWER   :`${API_DOMAIN}/dataMapping/search/findExistingMappingsByBorrower`,
  },
  calculationMapping: {
    MAIN_ENDPOINT                       : `${API_DOMAIN}/calculationMapping`,
    LIST_MAIN_ENDPOINT                  : `${API_DOMAIN}/calculationMapping/list`,
    FIND_BY_DOCUMENT_ID                 : `${API_DOMAIN}/calculationMapping/search/findByDocumentId`,
    APPLY_CALCULATION_MAPPING           : `${API_DOMAIN}/calculationMapping/apply`,
    GET_INITIAL_CALCULATION_MAPPING     : `${API_DOMAIN}/calculationMapping/initial`,
  },
  rollForwardCategory: {
    MAIN_ENDPOINT                       : `${API_DOMAIN}/rollForwardCategory`,
  },
  uploadFile: {
    MAIN_ENDPOINT                       : `${API_DOMAIN}/fileimport`,
    UPLOAD_FILE                         : `${API_DOMAIN}/fileimport/upload`,
    DOWNLOAD_FILE                       : `${API_DOMAIN}/fileimport/download`,
    DOWNLOAD_EXPORTED_FILE              : `${API_DOMAIN}/fileimport/download/exported`,
  },
  uploadedFile: {
    MAIN_ENDPOINT                       : `${API_DOMAIN}/uploadedFile`,
    GET_PROCESSED_FILES_BY_BORROWER_ID  : `${API_DOMAIN}/uploadedFile/search/findByBorrowerId/processed`,
    GET_UNPROCESSED_FILES_BY_BORROWER_ID: `${API_DOMAIN}/uploadedFile/search/findByBorrowerId/datamap`,
    HAS_UNMAPPED_FILES                  : `${API_DOMAIN}/uploadedFile/hasUnmapped`,
  },
  transactions: {
    HAS_DUPLICATE_AR                    : `${API_DOMAIN}/ar/transactions/hasDuplicates`,
    HAS_DUPLICATE_AP                    : `${API_DOMAIN}/ar/apTransactions/hasDuplicates`,
    HAS_DUPLICATE_GL                    : `${API_DOMAIN}/gl/transactions/hasDuplicates`
  }
}

export const reportsAPI = {
  arIneligibleReport: {
    GET_INELIGIBLE_REPORT                   : `${API_DOMAIN}/ar/ineligibleReports`,
    GET_UPC_INELIGIBLE_REPORT               : `${API_DOMAIN}/ar/ineligibleReports/upc`,
    GET_INELIGIBLE_REPORT_CUST_VIEW         : `${API_DOMAIN}/ar/ineligibleReports/custView`,
    GET_AMOUNT_TYPES                        : `${API_DOMAIN}/ar/calculateIneligibles/amountTypes`,
    GET_INELIGIBLE_DETAILS                  : `${API_DOMAIN}/ar/ineligibleReports/details`,
    GET_UPC_INELIGIBLE_DETAILS              : `${API_DOMAIN}/ar/ineligibleReports/upc/details`,
    GET_CUSTOMERS_WITH_DETAILS              : `${API_DOMAIN}/ar/ineligibleReports/findCustomersWithDetails`,
    GET_UPC_CUSTOMERS_WITH_DETAILS          : `${API_DOMAIN}/ar/ineligibleReports/upc/findCustomersWithDetails`,
    GET_RFV_INELIGIBLE_AMOUNT               : `${API_DOMAIN}/ar/ineligibleReports/rfvAmount`,
    GET_AVAILABLE_UPC_DATES                 : `${API_DOMAIN}/ar/ineligibleReports/upc/findUpcAsOfDates`,
    GET_INELIGIBLE_CAP_DETAILS              : `${API_DOMAIN}/ar/ineligibleCapDetails/list`,
    GET_INELIGIBLE_UPC_CAP_DETAILS          : `${API_DOMAIN}/ar/ineligibleCapDetails/upc/list`,
    GET_REPORT_LEVEL                        : `${API_DOMAIN}/ar/ineligibleReports/upc/checkReportLevel`,
    GET_INELIGIBLE_VIEW_SUMMARY             : `${API_DOMAIN}/ar/ineligibleReports/upc/ineligibleView/summary`,
    GET_UPC_CUST_IDS_WITH_DETAILS           : `${API_DOMAIN}/ar/ineligibleReports/upc/ineligibleView/findCustIdsWithDetails`,
    GET_UPC_CUST_IDS_WITH_DETAILS_CUST_VIEW : `${API_DOMAIN}/ar/ineligibleReports/upc/custView/findCustIdsWithDetails`,
  },
  arAgingReport: {
    MAIN_ENDPOINT                   : `${API_DOMAIN}/ar/agingReports`,
    MAIN_UPC_ENDPOINT               : `${API_DOMAIN}/ar/agingReports/upc`,
    GET_BUCKETS                     : `${API_DOMAIN}/ar/agingReports/buckets`,
    GET_UPC_BUCKETS                 : `${API_DOMAIN}/ar/agingReports/bucketsUPC`,
    GET_TOTAL_BY_BUCKET             : `${API_DOMAIN}/ar/agingReports/total`,
    GET_DETAILS                     : `${API_DOMAIN}/ar/agingReports/details`,
    GET_UPC_DETAILS                 : `${API_DOMAIN}/ar/agingReports/detailsUPC`,
    EXPORT_EXCEL                    : `${API_DOMAIN}/ar/agingReports/export/excel`,
    EXPORT_UPC_EXCEL                : `${API_DOMAIN}/ar/agingReports/upc/export/excel`,
    EXPORT_PDF                      : `${API_DOMAIN}/ar/agingReports/export/pdf`,
    CALCULATE                       : `${API_DOMAIN}/ar/agingReports/calculate`,
    CALCULATE_UPC                   : `${API_DOMAIN}/ar/agingReports/calculateUPC`,
    GET_GRAND_TOTAL                 : `${API_DOMAIN}/ar/agingReports/grandTotal`,
  },
  apAgingReport: {
    MAIN_ENDPOINT                   : `${API_DOMAIN}/ar/apAgingReports`,
    MAIN_UPC_ENDPOINT               : `${API_DOMAIN}/ar/apAgingReports/upc`,
    GET_AVAILABLE_BB_PERIODS        : `${API_DOMAIN}/ar/apTransactions/bbPeriods`,
    GET_BUCKETS                     : `${API_DOMAIN}/ar/apAgingReports/buckets`,
    GET_UPC_BUCKETS                 : `${API_DOMAIN}/ar/apAgingReports/bucketsUPC`,
    GET_TOTAL_BY_BUCKET             : `${API_DOMAIN}/ar/apAgingReports/total`,
    GET_DETAILS                     : `${API_DOMAIN}/ar/apAgingReports/details`,
    GET_UPC_DETAILS                 : `${API_DOMAIN}/ar/apAgingReports/detailsUPC`,
    EXPORT_EXCEL                    : `${API_DOMAIN}/ar/apAgingReports/export/excel`,
    EXPORT_PDF                      : `${API_DOMAIN}/ar/apAgingReports/export/pdf`,
    CALCULATE                       : `${API_DOMAIN}/ar/apAgingReports/calculate`,
    CALCULATE_UPC                   : `${API_DOMAIN}/ar/apAgingReports/calculateUPC`,
    GET_GRAND_TOTAL                 : `${API_DOMAIN}/ar/apAgingReports/grandTotal`,
    GET_GRAND_TOTAL_WITHOUT_BUCKETS : `${API_DOMAIN}/ar/apAgingReports/grandTotalWithoutBuckets`
  },
  rollForwardReport: {
    MAIN_ENDPOINT                   : `${API_DOMAIN}/gl/rollForwardReport`,
    GET_AVAILABLE_BB_PERIODS        : `${API_DOMAIN}/gl/rollForwardReport/bbPeriods`,
    GET_DETAILS                     : `${API_DOMAIN}/gl/rollForwardReport/details`,
    GET_SUMMARY                     : `${API_DOMAIN}/gl/rollForwardReport/summary`,
    BEGINNING_BALANCE_ENDPOINT      : `${API_DOMAIN}/gl/rollForwardReport/beginningBalance`,
    MAIN_ENPOINT_UPC                : `${API_DOMAIN}/gl/rollForwardReport/upc`,
    GET_AVAILABLE_UPC_DATES         : `${API_DOMAIN}/gl/rollForwardReport/upc/bbPeriods`,
    GET_UPC_SUMMARY                 : `${API_DOMAIN}/gl/rollForwardReport/upc/summary`,
    GET_UPC_BEGINNING_BALANCE       : `${API_DOMAIN}/gl/rollForwardReport/upc/beginningBalance`,
    EXPORT_EXCEL                    : `${API_DOMAIN}/gl/rollForwardReport/export/excel`,
    EXPORT_EXCEL_UPC                : `${API_DOMAIN}/gl/rollForwardReport/upc/export/excel`,
    EXPORT_PDF                    : `${API_DOMAIN}/gl/rollForwardReport/export/pdf`,
    EXPORT_PDF_UPC                : `${API_DOMAIN}/gl/rollForwardReport/upc/export/pdf`,
  },
  arCompareReport: {
    EXPORT_EXCEL_UPC                : `${API_DOMAIN}/ar/ineligibleReports/compare/upc/export/excel`,
    EXPORT_PDF_UPC                  : `${API_DOMAIN}/ar/ineligibleReports/compare/upc/export/pdf`,
  }
}

export const processingAPIs = {
  'AR Aging' : {
    STAGE           : `${API_DOMAIN}/stagingARTransaction/stage`,
    STAGE_CSV       : `${API_DOMAIN}/stagingARTransaction/import`,
    PROCESS         : `${API_DOMAIN}/ar/transactions/process`,
    CLEAN           : `${API_DOMAIN}/stagingARTransaction/list`,
  },
  'Customer List' : {
    STAGE           : `${API_DOMAIN}/stagingARCustomer/stage`,
    PROCESS         : `${API_DOMAIN}/ar/customers/process`,
    CLEAN           : `${API_DOMAIN}/stagingARCustomer/list`,
  },
  'AP Aging' : {
    STAGE           : `${API_DOMAIN}/stagingAPTransaction/stage`,
    PROCESS         : `${API_DOMAIN}/ar/apTransactions/process`,
    CLEAN           : `${API_DOMAIN}/stagingAPTransaction/list`,
  },
  'Vendor List' : {
    STAGE           : `${API_DOMAIN}/stagingARVendor/stage`,
    PROCESS         : `${API_DOMAIN}/ar/vendors/process`,
    CLEAN           : `${API_DOMAIN}/stagingARVendor/list`,
  },
  'GL Transaction' : {
    STAGE           : `${API_DOMAIN}/stagingGLTransaction/stage`,
    PROCESS         : `${API_DOMAIN}/gl/transactions/process`,
    CLEAN           : `${API_DOMAIN}/stagingGLTransaction/list`,
  }
}

export const checkingIssueAPIs = {
  'AR Aging'      : `${API_DOMAIN}/stagingARTransaction/checkDocumentIssues`,
  'Customer List' : `${API_DOMAIN}/stagingARCustomer/checkDocumentIssues`,
  'AP Aging'      : `${API_DOMAIN}/stagingAPTransaction/checkDocumentIssues`,
  'Vendor List'   : `${API_DOMAIN}/stagingARVendor/checkDocumentIssues`,
  'GL Transaction': `${API_DOMAIN}/stagingGLTransaction/checkDocumentIssues`,
}

export const stagingAPIs = {
  'AR Aging' : {
    MAIN_ENDPOINT           : `${API_DOMAIN}/stagingARTransaction`,
    LIST_MAIN_ENDPOINT      : `${API_DOMAIN}/stagingARTransaction/list`,
    IS_EXISTING             : `${API_DOMAIN}/stagingARTransaction/exists`
  },
  'Customer List' : {
    MAIN_ENDPOINT           : `${API_DOMAIN}/stagingARCustomer`,
    LIST_MAIN_ENDPOINT      : `${API_DOMAIN}/stagingARCustomer/list`,
    IS_EXISTING             : `${API_DOMAIN}/stagingARCustomer/exists`
  },
  'AP Aging' : {
    MAIN_ENDPOINT           : `${API_DOMAIN}/stagingAPTransaction`,
    LIST_MAIN_ENDPOINT      : `${API_DOMAIN}/stagingAPTransaction/list`,
    IS_EXISTING             : `${API_DOMAIN}/stagingAPTransaction/exists`
  },
  'Vendor List' : {
    MAIN_ENDPOINT           : `${API_DOMAIN}/stagingARVendor`,
    LIST_MAIN_ENDPOINT      : `${API_DOMAIN}/stagingARVendor/list`,
    IS_EXISTING             : `${API_DOMAIN}/stagingARVendor/exists`
  },
  'GL Transaction' : {
    MAIN_ENDPOINT           : `${API_DOMAIN}/stagingGLTransaction`,
    LIST_MAIN_ENDPOINT      : `${API_DOMAIN}/stagingGLTransaction/list`,
    IS_EXISTING             : `${API_DOMAIN}/stagingGLTransaction/exists`
  },
}

export const ruleConditionFieldListAPI = {
  FIND_FIELD_LIST_BY_PARENT_ID: `${API_DOMAIN}/ineligibleSetting/condition/fieldListByParentId`,
  FIND_VALUE_LIST_BY_PARENT_ID: `${API_DOMAIN}/ineligibleSetting/condition/valueListByParentId`,
}

export const documentAttributeAPI = {
  FIND_BY_CRITERIA                  : `${API_DOMAIN}/documentAttribute/findByBorrowerIdAndDocumentTypeId`,
  FIND_BY_BORROWER_ID               : `${API_DOMAIN}/documentAttribute/findByBorrowerId`,
  FIND_BY_PARENT_ID                 : `${API_DOMAIN}/documentAttribute/findByParentId`,
  ADD_DOCUMENT_ATTRIBUTE            : `${API_DOMAIN}/documentAttribute`,
  DELETE_DOCUMENT_ATTRIBUTE_BY_ID   : `${API_DOMAIN}/documentAttribute`,
}

export const collateralRuleAPI = {
  GET_FIELD_LIST: `${API_DOMAIN}/collateralRule/fieldList`, 
}

export const borrowingBaseReportAPI = {
  GET_AR_BB_REPORT_LIST_TOTALS  : `${API_DOMAIN}/ar/bbReports/summary`,
  GET_INV_BB_LIST        : `${API_DOMAIN}/inv/bbReports/search/findByBorrowerId`,
}

export const multiCurrencyAPI = {
  GET_CURRENCY_BY_ID: `${API_DOMAIN}/currency`,
  GET_ALL_CURRENCIES: `${API_DOMAIN}/currency/getAllCurrency?sortBy=currencyName, ASC`,
  GET_ALL_RATES: `${API_DOMAIN}/rate/search/getRatesByBorrowerIdAndCurrencyId`,
  GET_ALL_RATES_BY_TO_CURRENCY_IDS: `${API_DOMAIN}/rate/search/getRatesByToCurrencyIds`,
  DELETE_RATE: `${API_DOMAIN}/rate`
}

export const auditTrailAPI = {
  GET_BY_DATE_AND_SEARCH_TERM: `${API_DOMAIN}/auditTrail/search/findAllByDateAndSearchTerm`,
  EXPORT_TO_EXCEL: `${API_DOMAIN}/auditTrail/export/excel`
}

export default api;