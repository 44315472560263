import { Box, Chip, CircularProgress, IconButton, TableCell, TableRow } from "@mui/material";
import styles from "../styles";
import { ICurrency, IRate } from "../../../../../../interfaces/multiCurrencyInterface";
import { useContext, useState } from "react";
import { getARAgingReportCustomerDetails, getUPCARAgingReportCustomerDetails } from "../../../../../../api/reports/ar-aging";
import { ARAgingReportContext } from "../../../../../../context/arAgingReportContext";
import { IARAgingReport, IARAgingReportContext, IARAgingReportCustomerDetail, IUPCARAgingReport } from "../../../../../../interfaces/arAgingReportInterface";
import { SelectedClientContext } from "../../../../../../context/selectedClientContext";
import { IARCollateral, IBBPeriod, IClient } from "../../../../../../interfaces";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import OverflowText from "../../../../../../components/common/overflow-text";
import { formatNumberBasedOnRate, isSelectedClientUPC } from "../..";
import { formatDate, getFallbackString } from "../../../../../../utility/helper";

export interface IARAgingReportTableRowProps {
  row: IARAgingReport | IUPCARAgingReport;
  lastRowElementRef: (node: any) => void;
  selectedExchangeRate: IRate | null;
};

const ARAgingReportTableRow: React.FC<IARAgingReportTableRowProps> = (props) => {
  const { row, selectedExchangeRate, lastRowElementRef } = props;
  const { selectedClient } = useContext(SelectedClientContext);
  const { selectedCollateral, selectedAsOfDate, selectedType, selectedCurrency, buckets, reportCustomerDetails, setReportCustomerDetails }  = useContext(ARAgingReportContext) as IARAgingReportContext;

  const [isRowDetailsOpen, setIsRowDetailsOpen] = useState<boolean>(false);
  const [isRowDetailLoading, setIsRowDetailLoading] = useState<boolean>(false);

  const getDetailID = (row: IARAgingReport | IUPCARAgingReport) => `COL: ${row.arCollateralId}, CUS: ${row.arCustomerId}`;

  const openCustomerRowDetails = async () => {
    if (isRowDetailLoading) { return; }
    if (isRowDetailsOpen) { setIsRowDetailsOpen(false); return; }
    if (reportCustomerDetails[getDetailID(row)] !== undefined) { setIsRowDetailsOpen(true); return; }
    const nonNullSelectedClient = selectedClient as Required<IClient>;
    const nonNullSelectedCollateral = selectedCollateral as IARCollateral;
    const nonNullSelectedAsOfDate = selectedAsOfDate as IBBPeriod;
    try {
      setIsRowDetailLoading(true);
      const loadedReportCustomerDetail = await (
        isSelectedClientUPC(selectedClient)
          ? getUPCARAgingReportCustomerDetails(nonNullSelectedClient, nonNullSelectedAsOfDate, row.arCollateralId, row.arCustomerId)
          : getARAgingReportCustomerDetails(nonNullSelectedClient, nonNullSelectedCollateral, nonNullSelectedAsOfDate, row.arCustomerId)
      );
      const updatedReportCustomerDetails = { ...reportCustomerDetails };
      updatedReportCustomerDetails[getDetailID(row)] = loadedReportCustomerDetail;
      setReportCustomerDetails(updatedReportCustomerDetails);
      setIsRowDetailsOpen(true);
    } catch (error) {
      console.log('GET AR AGING REPORT CUSTOMER DETAIL ERROR: ', error);
    } finally {
      setIsRowDetailLoading(false);
    }
  };

  const getActionIcon = () => {
    if (isRowDetailLoading) { return <CircularProgress size={20}/> }
    if (isRowDetailsOpen) { return <ArrowDropUpIcon fontSize='small' sx={styles.collapseIcon} /> }
    return <ArrowDropDownIcon fontSize='small' sx={styles.collapseIcon} />
  };

  const getMainRow = () => {
    return (
      <TableRow
        hover
        onClick={ openCustomerRowDetails }
        ref={lastRowElementRef}
        sx={styles.tableRow}
        tabIndex={-1}
      >
        <TableCell sx={{ ...styles.widthActionButton, ...styles.tableCell }}>
          <IconButton
            aria-label={isRowDetailsOpen ? 'Collapse Icon Button' : 'Expand Icon Button'}
            onClick={ openCustomerRowDetails }
            size='small'
            sx={styles.collapseButton}
          >
            { getActionIcon() }
          </IconButton>
        </TableCell>
        <TableCell tabIndex={0} component="th" scope="row"  sx={{ ...styles.widthCustomerId, ...styles.tableCell }}>
          <OverflowText> { row.arCustSrcId } </OverflowText>
        </TableCell>
        <TableCell component="th" scope="row"  sx={{ ...styles.widthCustomerName, ...styles.tableCell }}>
          <Box sx={{ ...styles.customerNameTextContainer }}>
            <Box tabIndex={0} sx={{ ...styles.customerNameText }}>
              <OverflowText> { row.arCustomerName } </OverflowText>
            </Box>
            <Box>
              { row.isParent && <Chip tabIndex={0} label='Parent' size='small' sx={styles.parentIdentifierChip} /> }
            </Box>
          </Box>
        </TableCell>
        <TableCell tabIndex={0} align="right" sx={{ ...styles.widthBuckets, ...styles.tableCell, ...styles.tableCellNumber }}>
          { formatNumberBasedOnRate(row.total, selectedCurrency as ICurrency, selectedExchangeRate) }
        </TableCell>
        {
          buckets.map(bucket => {
            return (
              <TableCell tabIndex={0} key={bucket} align="right" sx={{ ...styles.widthBuckets, ...styles.tableCell, ...styles.tableCellNumber }}>
                { row.calcAgingBucket[bucket] !== undefined ? formatNumberBasedOnRate(row.calcAgingBucket[bucket], selectedCurrency as ICurrency, selectedExchangeRate) : <></> }
              </TableCell>
            )
          })
        }
      </TableRow>
    );
  };

  const getDetailRow = () => {
    if (!isRowDetailsOpen) { return <></>; }

    const getAmountPerBucket = (detail: IARAgingReportCustomerDetail, bucket: string) => {
      if (selectedType === 'Invoice Date' && detail.calcAgingBucketInvoiceDate === bucket) {
        return formatNumberBasedOnRate(detail.arTrxRemAmt, selectedCurrency as ICurrency, selectedExchangeRate);
      }
      if (selectedType === 'Due Date' && detail.calcAgingBucketDueDate === bucket) {
        return formatNumberBasedOnRate(detail.arTrxRemAmt, selectedCurrency as ICurrency, selectedExchangeRate);
      }
      return '';
    };

    const getDetailHeaders = () => {
      const withDetailToShowTableCells = (
        <>
          <TableCell tabIndex={0} sx={{ ...styles.widthCustomerId, ...styles.tableCellCollapse }}>
            Document #
          </TableCell>
          <TableCell sx={{ ...styles.widthCustomerName, ...styles.tableCellCollapse}}>
            <TableCell tabIndex={0} sx={{ ...styles.tableCellCollapse, ...styles.widthDate }}>Invoice Date</TableCell>
            <TableCell tabIndex={0} sx={{ ...styles.tableCellCollapse, ...styles.widthDate }}>Due Date</TableCell>
          </TableCell>
        </>
      );
      const noDetailToShowTableCell = (
        <TableCell tabIndex={0} sx={{ ...styles.widthCustomerId, ...styles.tableCellCollapse }}>
          No Details to Show
        </TableCell>
      );
      return (
        <>
          { reportCustomerDetails[getDetailID(row)] === undefined ? noDetailToShowTableCell : withDetailToShowTableCells }
          <TableCell sx={{...styles.widthBuckets, ...styles.tableCellCollapse}}></TableCell>
          { buckets.map(bucket => <TableCell align='right' key={bucket} sx={{...styles.widthBuckets, ...styles.tableCellCollapse}}></TableCell>) }
        </>
      );
    };

    const getDetailBody = () => {
      if (reportCustomerDetails[getDetailID(row)] === undefined) { return <></>; }
      return (
        reportCustomerDetails[getDetailID(row)].map(detail => {
          return (
            <TableRow key={detail.recordId}>
              <TableCell sx={{...styles.widthActionButton, ...styles.tableCellCollapse}}></TableCell>
              <TableCell tabIndex={0} sx={{ ...styles.widthCustomerId, ...styles.tableCellCollapse,  }}>
                { getFallbackString(detail.arTrxNo) }
              </TableCell>
              <TableCell sx={{...styles.widthCustomerName, ...styles.tableCellCollapse}}>
                <TableCell tabIndex={0} sx={{ ...styles.tableCellCollapse, ...styles.widthDate }}>
                  { formatDate(detail.arTrxDate, 'MM/DD/YYYY') }
                </TableCell>
                <TableCell tabIndex={0} sx={{ ...styles.tableCellCollapse, ...styles.widthDate }}>
                  { detail.arTrxDueDate ? formatDate(detail.arTrxDueDate, 'MM/DD/YYYY') : <></> }
                </TableCell>
              </TableCell>
              <TableCell sx={{...styles.widthBuckets, ...styles.tableCellCollapse}}></TableCell>
              {
                buckets.map(bucket => {
                  return (
                    <TableCell tabIndex={0} align='right' key={bucket} sx={{ ...styles.widthBuckets, ...styles.tableCellCollapse, ...styles.tableCellNumber }}>
                      { getAmountPerBucket(detail, bucket) }
                    </TableCell>
                  )
                })
              }
            </TableRow>
          );
        })
      );
    };

    return (
      <>
        <TableRow>
          <TableCell colSpan={buckets.length + 4} sx={{...styles.tableCellCollapse, ...styles.rowSpacerTop }}></TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{...styles.widthActionButton, ...styles.tableCellCollapse}}></TableCell>
          { getDetailHeaders() }
        </TableRow>
        { getDetailBody() }
        <TableRow><TableCell colSpan={4 + buckets.length} sx={styles.rowSpacer}></TableCell></TableRow>
      </>
    );
  };

  return (
    <>
      { getMainRow() }
      { getDetailRow() }
    </>
  );
};

export default ARAgingReportTableRow;
