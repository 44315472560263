import { createContext, useMemo, useState } from 'react';
import { IClientName, IInventory, IInventoryIneligibleContext } from '../interfaces/inventoryIneligibleInterface';
import { LayoutProps } from './authContext';

export const InventoryIneligibleContext = createContext<IInventoryIneligibleContext | null>(null);

const InventoryIneligiblesProvider = (props: LayoutProps) => {
  const { children }                                                      = props;
  const [clients, setClients]                                             = useState<IClientName[]>([]);
  const [selectedClient, setSelectedClient]                               = useState<IClientName>({ recordId: -1, borrowerName: 'Loading...' });
  const [selectedInventory, setSelectedInventory]                         = useState<IInventory>({ recordId: -1, invCollateralFk: -1, invCollateralName: 'Loading...' });
  const [lastSelectedClient, setLastSelectedClient]                       = useState<IClientName | null>(null);  // used for storing the last selected client for confirm navigation modal
  const [totalAmount, setTotalAmount]                                     = useState(0);
  const [showPrompt, setShowPrompt]                                       = useState(false);
  const [canViewInventoryIneligibles, setCanViewInventoryIneligibles]     = useState<boolean>(false);
  const [canAddInventoryIneligibles, setCanAddInventoryIneligibles]       = useState<boolean>(false);
  const [canUpdateInventoryIneligibles, setCanUpdateInventoryIneligibles] = useState<boolean>(false);
  const [canDeleteInventoryIneligibles, setCanDeleteInventoryIneligibles] = useState<boolean>(false);

  const value = useMemo(() => ({
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    selectedInventory,
    setSelectedInventory,
    lastSelectedClient,
    setLastSelectedClient,
    totalAmount,
    setTotalAmount,
    showPrompt,
    setShowPrompt,
    canViewInventoryIneligibles,
    setCanViewInventoryIneligibles,
    canAddInventoryIneligibles,
    setCanAddInventoryIneligibles,
    canUpdateInventoryIneligibles,
    setCanUpdateInventoryIneligibles,
    canDeleteInventoryIneligibles,
    setCanDeleteInventoryIneligibles
  }), [
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    selectedInventory,
    setSelectedInventory,
    lastSelectedClient,
    setLastSelectedClient,
    totalAmount,
    setTotalAmount,
    showPrompt,
    setShowPrompt,
    canViewInventoryIneligibles,
    setCanViewInventoryIneligibles,
    canAddInventoryIneligibles,
    setCanAddInventoryIneligibles,
    canUpdateInventoryIneligibles,
    setCanUpdateInventoryIneligibles,
    canDeleteInventoryIneligibles,
    setCanDeleteInventoryIneligibles
  ])

  return (
    <InventoryIneligibleContext.Provider
      value={value}
    >
      {children}
    </InventoryIneligibleContext.Provider>
  );
};

export default InventoryIneligiblesProvider;
