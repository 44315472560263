import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from "dayjs";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { formatDate } from "../../utility/helper";

export interface DatePickerMultipleProps {
  defaultValue        : Date[],
  onSelectDate        : (selectedDates: Date[]) => void,
  isNotCalculatedDate : (date: Dayjs) => boolean;
  isNotCalculatedYear : (year: Dayjs) => boolean;
  setFirstLoad?       : Dispatch<SetStateAction<boolean>>;
  asOfDateCreator?    : boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "selected"
})(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark
    },
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%"
  })
}));

const DatePickerMultiple = (props: DatePickerMultipleProps) => {
  const { defaultValue, onSelectDate, isNotCalculatedDate, isNotCalculatedYear, setFirstLoad, asOfDateCreator } = props;

  const [values, setValues] = useState(defaultValue);

  useEffect(() => {
    onSelectDate(values)
  }, [values])

  const renderInput = useCallback((params: TextFieldProps) => <TextField {...params} />, []);

  const renderPickerDay = useCallback((date: any, selectedDates: any[], pickersDayProps: PickersDayProps<any>) => {
    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        selected={isDateSelected(date, values)}
      />
    );
  }, [values])

  const handleChange = useCallback((newValue: any) => {
    const selectedDates = [...values];
    const newDate = newValue.toDate();
    
    const index = findIndexDate(newDate, values)

    if (asOfDateCreator) {
      // This code allows the date picker to select only 1 date in order to act as a datepicker
      const date = new Date(newDate)
      onSelectDate([date]);
      setValues([date])
    } else {
      if (index >= 0) selectedDates.splice(index, 1);
      else            selectedDates.push(newDate);

      setValues(selectedDates);
    }

    if (setFirstLoad) {
      setFirstLoad(false);
    }
  }, [values])

  const findIndexDate = (date: Date, selectedDates: Date[]) => {
    const dateString = formatDate(date.toISOString(), "MM/DD/YYYY");
    return selectedDates.findIndex((item) => 
      dateString === formatDate(item.toISOString(), "MM/DD/YYYY")
    );
  };

  const isDateSelected = (date: Date, selectedDates: Date[]) => {
    if (selectedDates.length < 1) return false;
    return selectedDates.filter(selectedDate => 
        formatDate(selectedDate.toISOString(), "MM/DD/YYYY") === formatDate(date.toISOString(), "MM/DD/YYYY")
    ).length > 0
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        label="Multiple Date Picker"
        value={values}
        openTo='day'
        onChange={handleChange}
        renderDay={renderPickerDay}
        renderInput={renderInput}
        shouldDisableDate={isNotCalculatedDate}
        shouldDisableYear={isNotCalculatedYear}
        disableFuture={asOfDateCreator}
      />
    </LocalizationProvider>
  );
}
export default DatePickerMultiple;