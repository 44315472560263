import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Tabs, Tab, Button, TableContainer, Table, TableHead, TableRow, TableCell, FormLabel, TableBody, TextField, Tooltip, IconButton, MenuItem, AlertColor } from '@mui/material';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HistoryIcon from '@mui/icons-material/History';
import { getIn, FormikProps, Formik, Form, FieldArray, FormikErrors, FormikTouched, Field, FormikState } from 'formik';
import { GET, POST, PUT, API_DOMAIN, PERMISSIONS, NO_PERMISSION_MSG } from '../../../../../utility/constants';
import { checkUserPermissions, getPermissionsOfUser, isObjectsEqual, trimOnBlur } from '../../../../../utility/helper';
import { ClientSettingsPaginationContext, IClientSettingsPaginationContext } from '../../../../../context/clientSettingsPaginationContext';
import { ClientSettingsContext, IClientSettingsContext } from '../../../../../context/clientSettingsContext';
import request from '../../../../../service/request';
import requests from '../../../../../service/requests';
import Toaster from '../../../../toaster';
import otherLoanSettingsSchema from '../../../../../schemas/otherLoanSettingsSchema';
import styles from './styles';
import ConfirmModal from '../../../../modals/confirm-modal';
import { usePrompt } from '../../../../../utility/prompt';
import NumberFormat, { InputAttributes } from 'react-number-format';
import NoDataPlaceholder from '../../../../common/no-data-placeholder';
import { SelectedClientContext } from '../../../../../context/selectedClientContext';
import { SkeletonRow, SkeletonTableHead } from '../../../../skeleton';
import { AuthContext } from '../../../../../context/authContext';
import HelperTextComponent from '../../../../common/helper-text-component';
import DisabledComponentsContainer from '../../../../common/disabled-components-container';
import axiosInstance from '../../../../../service/axiosInstance';

interface IResponseContent {
  recordId        : number;
  borrowerFk      : number;
  collateralName  : string;
  loanType        : string;
  collateralAmount: number;
  deductAmount    : number;
  remainingAmount : number;
  valueRate       : number;
  value           : number;
  lineLimit       : number;
  current         : boolean;
  isArchived?     : boolean;
}

interface IOtherLoan {
  recordId              : string;
  borrowerFk            : string;
  collateralName        : string;
  type                  : string;
  sublimit              : string;
  collateralAmount      : string;
  ineligibleAmount      : string;
  remaining             : string;
  advanceRate           : string;
  collateralAvailability: string;
  isArchived?           : boolean;
}

interface IFormikValue {
  otherLoans: IOtherLoan[];
}

interface IOtherLoansSettingsTableProps {
  isLoading?      : boolean;
  settings?       : IOtherLoan;
  onSettingsChange: (settingsCount: number) => void;
}

interface INumberFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

type SetFieldValue = (field: string, value: any) => Promise<void | FormikErrors<IFormikValue>>;
type IResetFormForOtherLoans = { (nextState?: Partial<FormikState<IFormikValue>> | undefined): void; (arg0: { values: { otherLoans: never[]; }; }): void; };

export interface IArchiveModal {
  isOpen: boolean;
  archivedIndex: number;
  title: string;
  description: string;
  confirmationText: string;
  selectedOtherLoan: IOtherLoan | null;
}


const CurrencyFormat = forwardRef<NumberFormat<InputAttributes>, INumberFormatProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => onChange({ target: { name: other.name, value: values.value, }, })}
      thousandSeparator
      type='tel'
      decimalScale={2}
      fixedDecimalScale={true}
      prefix='$'
    />
  );
});

const PercentFormat = forwardRef<NumberFormat<InputAttributes>, INumberFormatProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => onChange({ target: { value: values.value, name: other.name, }, })}
      thousandSeparator
      type='tel'
      decimalScale={2}
      fixedDecimalScale={false}
      suffix='%'
    />
  );
});


const OtherLoansSettingsTable: React.FC<IOtherLoansSettingsTableProps> = (props) => {
  const TABS: { label: 'Active' | 'Archived'}[]     = [ { label: 'Active' }, { label: 'Archived' } ];
  const HEADERS                                     = ['Collateral', 'Type', 'Sublimit', 'Collateral Amount', 'Ineligible Amount', 'Remaining', 'Advance Rate', 'Collateral Availability', 'Action'];
  const OTHER_LOAN_TYPE_OPTIONS                     = ['Purchase Order', 'Secured Cash', 'Stock Portfolio Assignment'];

  const {
    isDirty, setIsDirty,
    hasClient, setHasClient,
    viewOtherLoan, setViewOtherLoan,
    addOtherLoan, setAddOtherLoan,
    updateOtherLoan, setUpdateOtherLoan,
    deleteArchiveOtherLoan, setDeleteArchiveOtherLoan
  }                                                 = useContext(ClientSettingsContext) as IClientSettingsContext;
  const { state }                                   = useContext(AuthContext);
  const { page, setCount, rowsPerPage }             = useContext(ClientSettingsPaginationContext) as IClientSettingsPaginationContext;
  const { selectedClient }                          = useContext(SelectedClientContext);

  const { borrowerId }                              = useParams();

  const [selectedTabIndex, setSelectedTabIndex]     = useState<number>(TABS.findIndex(tab => tab.label === 'Active'));
  const [tabIndexToNavigate, setTabIndexToNavigate] = useState<number>(TABS.findIndex(tab => tab.label === 'Active'));
  const [otherLoans, setOtherLoans]                 = useState<IOtherLoan[]>([]);
  const [isLoading, setIsLoading]                   = useState<boolean>(true);
  const [allowAddingRow, setAllowAddingRow]         = useState<boolean>(true);
  const [isToasterOpen, setIsToasterOpen]           = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]         = useState<string>('');
  const [toasterSeverity, setToasterSeverity]       = useState<AlertColor>('success');
  const [archiveModal, setArchiveModal]             = useState<IArchiveModal>({ isOpen: false, archivedIndex: -1, title: '', description: '', confirmationText: '', selectedOtherLoan: null });
  const [deleteModal, setDeleteModal]               = useState({ isOpen: false, isNew: false, deleteRecordId: '', deleteName: '', deleteIndex: -1 });
  const [
    isConfirmNavigationModalOpen,
    setIsConfirmNavigationModalOpen
  ]                                                 = useState<boolean>(false);
  const [formikValues, setFormikValues]             = useState<IFormikValue>({ otherLoans: [] });
  const [isFormikResetting, setIsFormikResetting]   = useState(false);

  useEffect(() => {
    getPermissions()
  },[])

  useEffect(() => {
    if (borrowerId === 'undefined' || selectedClient?.recordId === undefined) {
      setHasClient(false);
      setOtherLoans([]);
      return;
    }
    setHasClient(true);
  }, [borrowerId, selectedClient?.recordId]);

  useEffect(() => {
    fetchOtherLoans();
  }, [borrowerId, page, rowsPerPage, selectedTabIndex]);

  useEffect(() => {
    props.onSettingsChange(otherLoans.length);
  }, [otherLoans.length, props]);

  useEffect(() => {
    if (formikValues === null || formikValues.otherLoans === undefined) { return; }
    const { emptyFormikValues } = getSavedAndEmptyFormikValues(formikValues.otherLoans);
    emptyFormikValues.length > 0 ? setAllowAddingRow(false) : setAllowAddingRow(true);
  }, [formikValues]);

  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(state.uid, state.token);
    setViewOtherLoan(permissions.includes(PERMISSIONS.VIEW_OTHER_LOAN));
    setAddOtherLoan(permissions.includes(PERMISSIONS.ADD_OTHER_LOAN));
    setUpdateOtherLoan(permissions.includes(PERMISSIONS.UPDATE_OTHER_LOAN));
    setDeleteArchiveOtherLoan(permissions.includes(PERMISSIONS.DELETE_ARCHIVE_OTHER_LOAN))
  };

  const checkPermission = async (func: Function, permission: string, ...args: any[]) => {
    const isPermitted = await checkUserPermissions(state.uid, permission)
    if(isPermitted){
      func(...args)
      return
    }
    setToasterMessage(NO_PERMISSION_MSG);
    setToasterSeverity('error');
    setIsToasterOpen(true);
  }

  const getTwoDecimalFormat = (value?: number) => {
    return value ? value.toFixed(2) : '';
  }

  const fetchOtherLoans = async () => {
    !isFormikResetting && setIsFormikResetting(true);
    setIsLoading(true);
    const isArchived = selectedTabIndex === TABS.findIndex(tab => tab.label === 'Archived');
    const response = await axiosInstance.request({
      url:  `${API_DOMAIN}/ol/collaterals/search/findIsArchived`,
      method: GET,
      params: { borrowerId, pageNo: page, pageSize: rowsPerPage === -1 ? 9999 : rowsPerPage, sortBy: 'collateralName,ASC', isArchived }
    });
    const data = response.data.content.filter((item : any) => item.current === true);
    const otherLoans: IOtherLoan[] = data.map((row: IResponseContent) => {
      const fetchedOtherLoan = {
        recordId: row.recordId.toString(),
        borrowerFk: row.borrowerFk.toString(),
        collateralName: row.collateralName.toString(),
        type: row.loanType,
        sublimit: getTwoDecimalFormat(row.lineLimit),
        collateralAmount: getTwoDecimalFormat(row.collateralAmount),
        ineligibleAmount: getTwoDecimalFormat(row.deductAmount),
        remaining: getTwoDecimalFormat(row.remainingAmount),
        advanceRate: (row.valueRate * 100).toString(),
        collateralAvailability: getTwoDecimalFormat(row.value)
      };
      return fetchedOtherLoan;
    });
    setIsDirty(false);
    setOtherLoans(otherLoans);
    setCount(response.data.totalElements);
    setIsLoading(false);
  };

  const archiveOtherLoan = async (isFetchingAfter?: boolean) => {
    closeArchiveModal();
    const OTHER_LOAN_TO_ARCHIVE = archiveModal.selectedOtherLoan;
    if (OTHER_LOAN_TO_ARCHIVE === null) { return; }
    await axiosInstance.request({
      url: `${API_DOMAIN}/ol/collaterals/${OTHER_LOAN_TO_ARCHIVE.recordId}/archive`,
      method: PUT
    });
    setToasterMessage(`${OTHER_LOAN_TO_ARCHIVE.collateralName} has been archived.`);
    setToasterSeverity('success');
    if (isFetchingAfter) { await fetchOtherLoans(); }
    setIsToasterOpen(true);
  };

  const restoreOtherLoan = async () => {
    closeArchiveModal();
    const OTHER_LOAN_TO_RESTORE = archiveModal.selectedOtherLoan;
    if (OTHER_LOAN_TO_RESTORE === null) { return; }
    await axiosInstance.request({
      url: `${API_DOMAIN}/ol/collaterals/${OTHER_LOAN_TO_RESTORE.recordId}/archive`,
      method: PUT,
      params: { isArchived: false }
    });
    setToasterMessage(`${OTHER_LOAN_TO_RESTORE.collateralName} has been restored.`);
    setToasterSeverity('success');
    await fetchOtherLoans();
    setIsToasterOpen(true)
  };

  const handleTabsChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (isDirty) {
      setIsConfirmNavigationModalOpen(true);
      setTabIndexToNavigate(newValue);
      return;
    }
    setOtherLoans([]);
    setSelectedTabIndex(newValue);
  };

  const calculateFields = ({event, setFieldValue, formik, index} :{ event?: React.ChangeEvent<HTMLInputElement>, setFieldValue: SetFieldValue, formik: IFormikValue, index: number }) => {
    // replace formik value with target.value
    const name = event?.target.name ?? '';
    const value = event?.target.value ?? '';
    const collateralAmount = name.includes('collateralAmount') ? value : formik.otherLoans[index].collateralAmount;
    const ineligibleAmount = name.includes('ineligibleAmount') ? value : formik.otherLoans[index].ineligibleAmount;
    const advanceRate = name.includes('advanceRate') ? value : formik.otherLoans[index].advanceRate;

    const collateralAmountNum = (collateralAmount ? parseFloat(collateralAmount) : 0)
    const ineligibleAmountNum = ineligibleAmount ? parseFloat(ineligibleAmount) : 0;
    const advanceRateNum = advanceRate ? parseFloat(advanceRate) / 100 : 0;

    const remainingNum = collateralAmountNum - ineligibleAmountNum;
    const collateralAvailabilityNum = remainingNum * advanceRateNum;

    if (collateralAmount === '' || ineligibleAmount === '') { return; }
    if (advanceRate === '') { return; }
    const remaining = remainingNum.toString();
    const collateralAvailability = collateralAvailabilityNum.toString();

    setFieldValue(`otherLoans[${index}].remaining`, remaining);
    setFieldValue(`otherLoans[${index}].collateralAvailability`, collateralAvailability);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, handleChange: React.ChangeEventHandler<HTMLInputElement>, setFieldValue: SetFieldValue, formik: IFormikValue, index: number) => {
    handleChange(event);
    calculateFields({
      event, setFieldValue, formik, index,
    });
  };

  const handleBlur = (event: React.FocusEvent, formikProps: FormikProps<any>, setFieldValue: SetFieldValue, formikValues: IFormikValue, index: number, name: string, value?: string) => {
    trimOnBlur(event, formikProps, name, value);
  }

  const handleDelete = (values: IFormikValue, index: number) => {
    const isOtherLoanToDeleteNew = values.otherLoans[index].recordId === undefined;
    isOtherLoanToDeleteNew && 
      setDeleteModal({
        isOpen: true,
        isNew: isOtherLoanToDeleteNew,
        deleteRecordId: '',
        deleteName: values.otherLoans[index].collateralName,
        deleteIndex: index,
      });
  };

  const handleConfirmDelete = (formikValues: IOtherLoan[], remove: (index: number) => void) => {
    if (deleteModal.isNew) {
      remove(deleteModal.deleteIndex);
      const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
      setToasterMessage(`${itemName} has been deleted`);
      setToasterSeverity('success');
      setIsToasterOpen(true);
    }

    const { hasUnsavedChanges, presentFormikValues } = getPresentFormikValues(formikValues, 'delete');
    if (hasUnsavedChanges && deleteModal.isNew) {
      setFormikValues({ otherLoans: presentFormikValues });
      return;
    }
    fetchOtherLoans();
  };

  const handleArchive = (values: Array<IOtherLoan>, index: number) => {
    setArchiveModal({
      isOpen: true,
      archivedIndex: index,
      title: `Archive ${values[index].collateralName}`,
      description: 'Are you sure you want to archive this item?',
      confirmationText: 'Archive',
      selectedOtherLoan: values[index],
    });
  };

  const handleRestore = (values: Array<IOtherLoan>, index: number) => {
    setArchiveModal({
      isOpen: true,
      archivedIndex: index,
      title: `Restore ${values[index].collateralName}`,
      description: 'Are you sure you want to restore this item?',
      confirmationText: 'Restore',
      selectedOtherLoan: values[index],
    });
  };

  const handleConfirmArchive = (formikValues: IOtherLoan[], remove: (index: number) => void) => {
    remove(archiveModal.archivedIndex);
    const { hasUnsavedChanges } = getPresentFormikValues(formikValues, 'archive');
    if (hasUnsavedChanges) { archiveOtherLoan(); return; }
    archiveOtherLoan(true);
  };

  const handleConfirmArchiveOrRestore = async (formikValues: IOtherLoan[], remove: (index: number) => void) => {
    const isPermitted = await checkUserPermissions(state.uid, PERMISSIONS.UPDATE_OTHER_LOAN)
    if(!isPermitted){
      setToasterMessage(NO_PERMISSION_MSG);
      setToasterSeverity('error');
      setIsToasterOpen(true);
      return
    } 
      if (archiveModal.confirmationText === 'Archive') { return handleConfirmArchive(formikValues, remove);  }
      if (archiveModal.confirmationText === 'Restore') { return restoreOtherLoan(); }
  };

  const handleCancel = (resetForm: IResetFormForOtherLoans) => {
    if (formikValues === null) { return; }
    const { savedFormikValues } = getSavedAndEmptyFormikValues(formikValues.otherLoans);
    setIsDirty(false);
    resetForm({ values: { otherLoans: savedFormikValues } });
    setOtherLoans(savedFormikValues);
    setAllowAddingRow(true);
  };

  const handleSave = async (otherLoansToSave: IOtherLoan[], setSubmitting: (isSubmitting: boolean) => void) => {
    const isPermittedAdd = await checkUserPermissions(state.uid, PERMISSIONS.ADD_OTHER_LOAN)
    const isPermittedUpdate = await checkUserPermissions(state.uid, PERMISSIONS.UPDATE_OTHER_LOAN)
    if(!isPermittedAdd && !isPermittedUpdate){
      setToasterMessage(NO_PERMISSION_MSG);
      setToasterSeverity('error');
      setIsToasterOpen(true);
      return
    }
      setSubmitting(true);
  
      const otherLoansToEdit = otherLoansToSave
        .filter((otherLoan) => {
          const isOtherLoanNotNew = otherLoan.hasOwnProperty('recordId');
          return isOtherLoanNotNew;
        })
        .filter((otherLoan) => {
          const [originalOtherLoan] = otherLoans.filter(currentOtherLoan => currentOtherLoan.recordId === otherLoan.recordId);
          if (originalOtherLoan === undefined) { return false; }
          const isOtherLoanEdited = !isObjectsEqual(otherLoan, originalOtherLoan);
          return isOtherLoanEdited;
        });
  
      // 1st part of update request (hide values to update)
      const editRequestPutConfigs = otherLoansToEdit.map((otherLoan) => {
        return {
          url: `${API_DOMAIN}/ol/collaterals/${otherLoan.recordId}`,
          method: PUT,
          data: {
            recordId: parseInt(otherLoan.recordId),
            borrowerFk: parseInt(otherLoan.borrowerFk),
            collateralName: otherLoan.collateralName.trim(),
            loanType: otherLoan.type,
            collateralAmount: parseFloat(otherLoan.collateralAmount),
            deductAmount: parseFloat(otherLoan.ineligibleAmount),
            remainingAmount: parseFloat(otherLoan.remaining),
            valueRate: parseFloat(otherLoan.advanceRate) / 100,
            value: parseFloat(otherLoan.collateralAvailability),
            lineLimit: parseFloat(otherLoan.sublimit),
            current: false,
          },
        };
      });
  
      // part 2 of update request (set current to true for updated values)
      const editRequestPostConfigs = otherLoansToEdit.map((otherLoan) => {
        return {
          url: `${API_DOMAIN}/ol/collaterals`,
          method: POST,
          data: {
            borrowerFk: parseInt(otherLoan.borrowerFk),
            collateralName: otherLoan.collateralName.trim(),
            loanType: otherLoan.type,
            collateralAmount: parseFloat(otherLoan.collateralAmount),
            deductAmount: parseFloat(otherLoan.ineligibleAmount),
            remainingAmount: parseFloat(otherLoan.remaining),
            valueRate: parseFloat(otherLoan.advanceRate) / 100,
            value: parseFloat(otherLoan.collateralAvailability),
            lineLimit: parseFloat(otherLoan.sublimit),
            current: true,
          },
        };
      });
  
      // add new other loans
      const postRequestConfigs = otherLoansToSave
        .filter((otherLoan) => !otherLoan.hasOwnProperty('recordId'))
        .map((otherLoan) => {
          return {
            url: `${API_DOMAIN}/ol/collaterals`,
            method: POST,
            data: {
              borrowerFk: borrowerId,
              collateralName: otherLoan.collateralName.trim(),
              loanType: otherLoan.type,
              collateralAmount: parseFloat(otherLoan.collateralAmount),
              deductAmount: parseFloat(otherLoan.ineligibleAmount),
              remainingAmount: parseFloat(otherLoan.remaining),
              valueRate: parseFloat(otherLoan.advanceRate) / 100,
              value: parseFloat(otherLoan.collateralAvailability),
              lineLimit: parseFloat(otherLoan.sublimit),
              current: true,
            },
          };
        });

      const requestConfigs = [
        ...(isPermittedUpdate? editRequestPutConfigs : []), 
        ...(isPermittedUpdate? editRequestPostConfigs : []), 
        ...(isPermittedAdd? postRequestConfigs : [])];

      const updatedToasterMessage = getUpdateToasterMessage(isPermittedAdd ? postRequestConfigs : [], isPermittedUpdate ? editRequestPostConfigs : []);
      requests(requestConfigs.map((requestConfig) => request(requestConfig)))
        .then((_responses) => {
          setIsDirty(false);
          fetchOtherLoans();
          setToasterMessage(updatedToasterMessage);
          setToasterSeverity('success');
          setIsToasterOpen(true);
          setSubmitting(false);
        })
        .catch((error) => {
          console.log('other loans requests error', error);
        })
  };

  const handleDiscardChanges = () => {
    setIsDirty(false);
    setOtherLoans([]);
    setSelectedTabIndex(tabIndexToNavigate);
    setIsConfirmNavigationModalOpen(false);
  };

  const getTabProps = (index: number) => {
    return {
      id: `other-loans-tab-${index}`,
      'aria-controls': `other-loans-tabpanel-${index}`,
    };
  };

  const getActionIconButton = (formik: FormikProps<{otherLoans: IOtherLoan[]}>, index: number) => {
    if (isLoading && !deleteArchiveOtherLoan) {
      return (
        <IconButton
          sx={styles.actionButton}
          color='primary'
          aria-label='Archive icon'
          disabled
        >
          <Inventory2OutlinedIcon />
        </IconButton>
      );
    }

    const { savedFormikValues } = getSavedAndEmptyFormikValues(formikValues.otherLoans);
    const currentValue = formik.values.otherLoans[index];
    const isCurrentValueNew = !currentValue?.recordId || savedFormikValues.every(formikValue => formikValue.recordId !== currentValue.recordId);

    if(!deleteArchiveOtherLoan) { return <></> }

    if (isCurrentValueNew) {
      return (
        <Tooltip title='Delete the item'>
          <IconButton
            onClick={() => checkPermission(handleDelete, PERMISSIONS.DELETE_ARCHIVE_OTHER_LOAN,formik.values, index)}
            sx={styles.actionButton}
            color='primary'
            aria-label='Delete icon'
            data-testid='delete-icon'
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      );
    };

    if (selectedTabIndex === TABS.findIndex(tab => tab.label === 'Archived')) {
      return (
        <Tooltip title='Restore the item'>
          <IconButton
            onClick={() => checkPermission(handleRestore, PERMISSIONS.DELETE_ARCHIVE_OTHER_LOAN, formik.values.otherLoans, index)}
            sx={styles.actionButton}
            color='primary'
            aria-label='Restore icon'
          >
            <HistoryIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Tooltip title='Archive the item'>
        <IconButton
          onClick={() => checkPermission(handleArchive, PERMISSIONS.DELETE_ARCHIVE_OTHER_LOAN, formik.values.otherLoans, index)}
          sx={styles.actionButton}
          color='primary'
          aria-label='Archive icon'
        >
          <Inventory2OutlinedIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const getDisplayedError = (errors: FormikErrors<IFormikValue>, touched: FormikTouched<IFormikValue>, name: string, index: number) => {
    const fieldError = getIn(errors, `otherLoans[${index}].${name}`);
    const isFieldTouched = getIn(touched, `otherLoans[${index}].${name}`);

    if (fieldError && isFieldTouched) { return { error: true, helperText: <HelperTextComponent text={fieldError} /> }; }

    return null;
  };

  const addNewRow = (push: any) => {
    const newOtherLoan = {
      collateralName: '', type: '', sublimit: '', collateralAmount: '',
      ineligibleAmount: '', remaining: '', advanceRate: '', collateralAvailability: ''
    };
    push(newOtherLoan);
    setAllowAddingRow(false);
  };

  const closeArchiveModal = () => setArchiveModal(archiveModal => ({ ...archiveModal, isOpen: false }));

  const getUpdateToasterMessage = (addItems: any, updateItems: any) => {
    const addLength = addItems.length;
    const updateLength = updateItems.length;
    if (addLength > 0 && updateLength > 0) {
      return 'Changes in Other Loans have been saved';
    }
    if (addLength > 0 && updateLength <= 0) {
      const phrase = addLength > 1 ? 'Items have been' : `${addItems[0].data.collateralName} has been`;
      return `${phrase} added`;
    }
    if (addLength <= 0 && updateLength > 0) {
      const phrase = updateLength > 1 ? 'Items have been' : `${updateItems[0].data.collateralName} has been`;
      return `${phrase} updated`;
    }
    return '';
  };

  const getPresentFormikValues = (formikValues: IOtherLoan[], action: 'delete' | 'archive') => {
    let hasUnsavedChanges = false;
    const presentFormikValues = formikValues.filter((currentValue: IOtherLoan, currentIndex: number) => {
      const actionIndex = action === 'delete' ? deleteModal.deleteIndex : archiveModal.archivedIndex;
      const actionRecordId = action === 'delete' ? deleteModal.deleteRecordId : archiveModal.selectedOtherLoan?.recordId;
      if (currentIndex === actionIndex) { return false; }
      if (currentValue.recordId === actionRecordId) { return false; }

      const hasNewRecord = currentValue.recordId === undefined;
      const hasUpdatedRecord = otherLoans.some(otherLoan => {
        if (otherLoan.recordId !== currentValue.recordId) { return false; }
        return !isObjectsEqual(currentValue, otherLoan);
      });
      if (hasNewRecord || hasUpdatedRecord) { hasUnsavedChanges = true; }

      return true;
    });

    return { hasUnsavedChanges, presentFormikValues };
  };

  const getIsOtherLoanEmpty = (fields: string[]) => {
    return fields.every(field => field === undefined || field === '');
  }

  const getSavedAndEmptyFormikValues = (formikValuesForOtherLoans: IOtherLoan[]) => {
    const [savedFormikValues, emptyFormikValues] = formikValuesForOtherLoans.reduce((separatedFormikValues: IOtherLoan[][], otherLoan: IOtherLoan) => {
      let currentSavedFormikValues = [...separatedFormikValues[0]];
      let currentEmptyFormikValues = [...separatedFormikValues[1]];

      const isOtherLoanSaved = otherLoan.recordId !== undefined && otherLoan.recordId !== '';
      if (isOtherLoanSaved) {
        const [savedOtherLoan] = otherLoans.filter(originalOtherLoan => originalOtherLoan.recordId === otherLoan.recordId);
        savedOtherLoan !== undefined && currentSavedFormikValues.push(savedOtherLoan);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      const isOtherLoanEmpty = getIsOtherLoanEmpty([
        otherLoan.collateralName,
        otherLoan.type,
        otherLoan.sublimit,
        otherLoan.collateralAmount,
        otherLoan.ineligibleAmount,
        otherLoan.remaining,
        otherLoan.advanceRate,
        otherLoan.collateralAvailability
      ]);

      if (isOtherLoanEmpty) {
        currentEmptyFormikValues.push(otherLoan);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
    }, [[], []]);

    return { savedFormikValues, emptyFormikValues };
  };

  const formikRef = (node: FormikProps<IFormikValue>) => {
    if (node === null || isLoading) { return; }
    setFormikValues(node.values);
    !isObjectsEqual(formikValues, node.values) && setIsDirty(node.dirty);
    if (!isFormikResetting) { return; }
    setIsFormikResetting(false);
  };

  const getActionButtons = (formik: FormikProps<IFormikValue>) => {
    const hasAccess = (addOtherLoan || updateOtherLoan || deleteArchiveOtherLoan);
    const hasValues = formik.values.otherLoans.length;
    if (!hasAccess || !hasValues || TABS[selectedTabIndex].label === 'Archived') { return; }
    return (
      <Box sx={styles.buttonsContainer}>
        {formik.dirty ? (
          <Button
            onClick={() => handleCancel(formik.resetForm)}
            variant='outlined'
            sx={styles.cancelButton}
          >
            Cancel
          </Button>
        ) : null}
        <DisabledComponentsContainer isDisabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}>
          <Button
            disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}
            aria-label={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading ? 'Save button disabled' : 'Save'}
            variant='contained'
            sx={styles.saveButton}
            type='submit'
          >
            Save
          </Button>
        </DisabledComponentsContainer>
      </Box>
    );
  };

  const isDisabledField = (index: number) => {
    return selectedTabIndex === TABS.findIndex(tab => tab.label === 'Archived')
      || (formikValues?.otherLoans[index]?.recordId === undefined ? !addOtherLoan : !updateOtherLoan);
  };

  const getContent = (formik: FormikProps<IFormikValue>) => {
    if (isLoading) { return getLoadingContent(); }
    if (otherLoans.length === 0 && TABS[selectedTabIndex].label === 'Archived') {
      return (<NoDataPlaceholder messageText='There are no archives. Please add an archive to start.'/>)
    }
    return getTableContent(formik);
  };

  const getTabIndexByComponent = (component: 'field' | 'select', index: number) => {
    if (component === 'field') return isDisabledField(index) ? 0 : null;
    else return isDisabledField(index) ? 0 : undefined;
  };

  const getLoadingContent = () => (
    <Table sx={styles.table} data-testid='other-loans-loader'>
      <SkeletonTableHead />
      <TableBody>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </TableBody>
    </Table>
  );

  const getNewRowGenerator = (formik: FormikProps<IFormikValue>, push: (obj: any) => void) => {
    if (!addOtherLoan || !allowAddingRow || selectedTabIndex === TABS.findIndex(tab => tab.label === 'Archived')) { return; }
    return (
      <TableRow sx={styles.tableRow} key={formik.values.otherLoans.length} data-testid='other-loans-row-generator'>
        { HEADERS.map((header, index) => (
          <TableCell sx={styles.tableCell} key={header}>
            {index !== HEADERS.findIndex(header => header === 'Action') ? (
              <TextField
                sx={styles.textField}
                value=''
                onClick={() => addNewRow(push)}
                onKeyDown={(e) => { if (e.key === 'Enter') { addNewRow(push) } }}
                inputProps={{ 'aria-label': 'Press enter to add new entry' }}
                data-testid='other-loans-cell-row-generator'
              />
            ) : null}
          </TableCell>
        )) }
      </TableRow>
    );
  };

  const getTableContent = (formik: FormikProps<IFormikValue>) => {
    return (
      <TableContainer sx={styles.tableContainer} hidden={isLoading}>
        <Table sx={styles.table}>
          <TableHead>
            <TableRow sx={styles.tableHeadRow}>
              <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForCollateral }}>
                <FormLabel
                  tabIndex={0}
                  htmlFor='collateral-name'
                  sx={styles.tableHeaderText}
                >
                  Collateral<span style={styles.asterisk}> *</span>
                </FormLabel>
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForType }}>
                <FormLabel
                  tabIndex={0}
                  htmlFor='type'
                  sx={styles.tableHeaderText}
                >
                  Type<span style={styles.asterisk}> *</span>
                </FormLabel>
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForSublimit, ...styles.rightAlignedText }}>
                <FormLabel
                  tabIndex={0}
                  htmlFor='sublimit'
                  sx={styles.tableHeaderText}
                >
                  Sublimit
                </FormLabel>
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForCollateralAmount, ...styles.rightAlignedText }}>
                <FormLabel
                  tabIndex={0}
                  htmlFor='collateral-amount'
                  sx={styles.tableHeaderText}
                >
                  Collateral Amount
                </FormLabel>
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForIneligibleAmount, ...styles.rightAlignedText }}>
                <FormLabel 
                  tabIndex={0}
                  htmlFor='ineligible-amount'
                  sx={styles.tableHeaderText}
                >
                  Ineligible Amount
                </FormLabel>
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForRemaining, ...styles.rightAlignedText }}>
                <FormLabel
                  tabIndex={0}
                  htmlFor='remaining'
                  sx={styles.tableHeaderText}
                >
                  Remaining
                </FormLabel>
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForAdvanceRate, ...styles.rightAlignedText }}>
                <FormLabel
                  tabIndex={0}
                  htmlFor='advance-rate'
                  sx={styles.tableHeaderText}
                >
                  Advance Rate<span style={styles.asterisk}> *</span>
                </FormLabel>
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForCollateralAvailability, ...styles.rightAlignedText }}>
                <FormLabel
                  tabIndex={0}
                  htmlFor='collateral-availability'
                  sx={styles.tableHeaderText}
                >
                  Collateral Availability
                </FormLabel>
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForAction, ...styles.centerAlignedText }}>
                <FormLabel tabIndex={0} sx={styles.tableHeaderText}>
                  Action
                </FormLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <FieldArray name='otherLoans'>
            {({ push, remove }) => (
              <TableBody sx={styles.tableBody}>
                { formik.values.otherLoans.map((otherLoan, index) => {
                  return (
                    <TableRow sx={styles.tableRow} key={otherLoan?.recordId ? `saved-index${otherLoan.recordId}` : `new-index${index}` }>
                      <TableCell sx={styles.tableCell} tabIndex={-1}>
                        <Field
                          disabled={isDisabledField(index) }
                          tabIndex={getTabIndexByComponent('field', index)}
                          id='collateral-name'
                          aria-label={`Collateral ${formik.values.otherLoans[index].collateralName}`}
                          name={`otherLoans[${index}].collateralName`}
                          as={TextField}
                          sx={styles.textField}
                          variant='outlined'
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChange(event, formik.handleChange, formik.setFieldValue, formik.values, index); }}
                          onBlur={(event: React.FocusEvent) => {
                            handleBlur(event, formik, formik.setFieldValue, formik.values, index, `otherLoans[${index}].collateralName`, formik.values.otherLoans[index].collateralName);
                          }}
                          inputProps={{ 'aria-label': 'Collateral Name' }}
                          {...getDisplayedError(formik.errors, formik.touched, 'collateralName', index)}
                        />
                      </TableCell>
                      <TableCell sx={styles.tableCell} tabIndex={-1}>
                        <Field
                          disabled={isDisabledField(index)}
                          id='type'
                          aria-label={`Type ${formik.values.otherLoans[index].type}`}
                          name={`otherLoans[${index}].type`}
                          as={TextField}
                          select
                          sx={styles.dropdown}
                          variant='outlined'
                          onChange={(event: React.ChangeEvent) => { formik.handleChange(event); }}
                          SelectProps={{
                            inputProps: { 
                              'aria-label': `Type`, 
                              'aria-labelledby': 'type',
                              'tabIndex': 0,
                            },
                            id: 'type',
                          }}

                          {...getDisplayedError(formik.errors, formik.touched, 'type', index)}
                        >
                          {
                            OTHER_LOAN_TYPE_OPTIONS.map((option) =>  (
                              <MenuItem value={option} key={option}>
                                {option}
                              </MenuItem>
                            ))
                          }
                        </Field>
                      </TableCell>
                      <TableCell sx={styles.tableCell} tabIndex={-1}>
                        <Field
                          disabled={isDisabledField(index)}
                          tabIndex={getTabIndexByComponent('field', index)}
                          id='sublimit'
                          aria-label={`Sublimit ${formik.values.otherLoans[index].sublimit}`}
                          name={`otherLoans[${index}].sublimit`}
                          as={TextField}
                          sx={styles.textField}
                          variant='outlined'
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChange(event, formik.handleChange, formik.setFieldValue, formik.values, index); }}
                          onBlur={(event: React.FocusEvent) => {
                            handleBlur(event, formik, formik.setFieldValue, formik.values, index, `otherLoans[${index}].collateralName`, formik.values.otherLoans[index].collateralName);
                          }}
                          InputProps={{ inputComponent: CurrencyFormat as any }}
                          inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Sublimit' }}
                          {...getDisplayedError(formik.errors, formik.touched, 'sublimit', index)}
                        />
                      </TableCell>
                      <TableCell sx={styles.tableCell} tabIndex={-1}>
                        <Field
                          disabled={isDisabledField(index)}
                          tabIndex={getTabIndexByComponent('field', index)}
                          id='collateral-amount'
                          aria-label={`Collateral Amount ${formik.values.otherLoans[index].collateralAmount}`}
                          name={`otherLoans[${index}].collateralAmount`}
                          as={TextField}
                          sx={styles.textField}
                          variant='outlined'
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChange(event, formik.handleChange, formik.setFieldValue, formik.values, index); }}
                          onBlur={(event: React.FocusEvent) => {
                            handleBlur(event, formik, formik.setFieldValue, formik.values, index, `otherLoans[${index}].collateralName`, formik.values.otherLoans[index].collateralName);                        
                          }}
                          InputProps={{ inputComponent: CurrencyFormat as any }}
                          inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Collateral Amount' }}
                          {...getDisplayedError(formik.errors, formik.touched, 'collateralAmount', index)}
                        />
                      </TableCell>
                      <TableCell sx={styles.tableCell} tabIndex={-1}>
                        <Field
                          disabled={isDisabledField(index)}
                          tabIndex={getTabIndexByComponent('field', index)}
                          id='ineligible-amount'
                          aria-label={`Ineligible Amount ${formik.values.otherLoans[index].ineligibleAmount}`}
                          name={`otherLoans[${index}].ineligibleAmount`}
                          as={TextField}
                          sx={styles.textField}
                          variant='outlined'
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChange(event, formik.handleChange, formik.setFieldValue, formik.values, index); }}
                          onBlur={(event: React.FocusEvent) => {
                            handleBlur(event, formik, formik.setFieldValue, formik.values, index, `otherLoans[${index}].collateralName`, formik.values.otherLoans[index].collateralName);                       
                          }}
                          InputProps={{ inputComponent: CurrencyFormat as any }}
                          inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Ineligible Amount' }}
                          {...getDisplayedError(formik.errors, formik.touched, 'ineligibleAmount', index)}
                        />
                      </TableCell>
                      <TableCell sx={styles.tableCell} tabIndex={-1}>
                        <Field
                          disabled
                          tabIndex={0}
                          id='remaining'
                          aria-label={`Remaining ${formik.values.otherLoans[index].remaining}`}
                          name={`otherLoans[${index}].remaining`}
                          as={TextField}
                          sx={styles.textField}
                          variant='outlined'
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChange(event, formik.handleChange, formik.setFieldValue, formik.values, index); }}
                          InputProps={{ inputComponent: CurrencyFormat as any }}
                          inputProps={{ sx: styles.rightAlignedText, 'aria-label': `Remaining ${formik.values.otherLoans[index].remaining}` }}
                          {...getDisplayedError(formik.errors, formik.touched, 'remaining', index)}
                        />
                      </TableCell>
                      <TableCell sx={styles.tableCell} tabIndex={-1}>
                        <Field
                          disabled={isDisabledField(index)}
                          tabIndex={getTabIndexByComponent('field', index)}
                          id='advance-rate'
                          aria-label={`Advance Rate ${formik.values.otherLoans[index].advanceRate}%`}
                          name={`otherLoans[${index}].advanceRate`}
                          as={TextField}
                          sx={styles.textField}
                          variant='outlined'
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChange(event, formik.handleChange, formik.setFieldValue, formik.values, index); }}
                          onBlur={(event: React.FocusEvent) => {
                            handleBlur(event, formik, formik.setFieldValue, formik.values, index, `otherLoans[${index}].collateralName`, formik.values.otherLoans[index].collateralName);
                          }}
                          InputProps={{ inputComponent: PercentFormat as any }}
                          inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Advance Rate' }}
                          {...getDisplayedError(formik.errors, formik.touched, 'advanceRate', index)}
                        />
                      </TableCell>
                      <TableCell sx={styles.tableCell} tabIndex={-1}>
                        <Field
                          disabled
                          tabIndex={0}
                          id='collateral-availability'
                          aria-label={`Collateral Availability ${formik.values.otherLoans[index].collateralAvailability}`}
                          name={`otherLoans[${index}].collateralAvailability`}
                          as={TextField}
                          sx={styles.textField}
                          variant='outlined'
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChange(event, formik.handleChange, formik.setFieldValue, formik.values, index); }}
                          InputProps={{ inputComponent: CurrencyFormat as any }}
                          inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Collateral Availability' }}
                          {...getDisplayedError(formik.errors, formik.touched, 'collateralAvailability', index)}
                        />
                      </TableCell>
                      <TableCell sx={{ ...styles.actionTableCell, ...styles.centerAlignedText }}>{ getActionIconButton(formik, index) }</TableCell>
                    </TableRow>
                  );
                }) }
                { getNewRowGenerator(formik, push) }
                <ConfirmModal
                  open={deleteModal.isOpen}
                  onClose={() => { setDeleteModal({ ...deleteModal, isOpen: false }); }}
                  onConfirm={() => { handleConfirmDelete(formik.values.otherLoans, remove); }}
                  title={`Delete ${deleteModal.deleteName}`}
                  description='Are you sure you want to delete this item?'
                  errorButton
                  yesButtonText='Delete'
                  noButtonText='Cancel'
                />
                <ConfirmModal
                  title={archiveModal.title}
                  description={archiveModal.description}
                  open={archiveModal.isOpen}
                  alignment='left'
                  onClose={closeArchiveModal}
                  onConfirm={() => { handleConfirmArchiveOrRestore(formik.values.otherLoans, remove); }}
                  errorButton={false}
                  noButtonText={'Cancel'}
                  yesButtonText={archiveModal.confirmationText}
                />
              </TableBody>
            )}
          </FieldArray>
        </Table>
      </TableContainer>
    )
  }

  usePrompt('You have unsaved changes. Are you sure you want to leave this page?', isDirty);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{ otherLoans }}
      validationSchema={otherLoanSettingsSchema}
      onSubmit={(values, { setSubmitting }) => handleSave(values.otherLoans, setSubmitting)}
    >
      {
        formik => (
          <Form onSubmit={formik.handleSubmit}>
            {!hasClient && <NoDataPlaceholder messageContainerStyle={styles.outmostContainer} />}
            {!viewOtherLoan && <NoDataPlaceholder messageText='You do not have the permission to view this page.' />}
            <Box sx={{ ...styles.outmostContainer, ...((!hasClient || !viewOtherLoan) && styles.hidden) }}>
              <Box sx={styles.blockBox}>
                <Tabs
                  sx={styles.buttonTabs}
                  value={selectedTabIndex}
                  onChange={handleTabsChange}
                  aria-label='other loans tabs'
                >
                  { TABS.map((tab, index) => <Tab tabIndex={0} label={tab.label} key={tab.label} {...getTabProps(index)} /> ) }
                </Tabs>
              </Box>
              { getContent(formik) }
              { getActionButtons(formik) }
            </Box>
            <Toaster
              open={isToasterOpen}
              message={toasterMessage}
              severity={toasterSeverity}
              onCloseChange={() => setIsToasterOpen(false)}
            />
            <ConfirmModal
              open={isConfirmNavigationModalOpen}
              onConfirm={() => setIsConfirmNavigationModalOpen(false)}
              onClose={handleDiscardChanges}
              onButtonClose={() => setIsConfirmNavigationModalOpen(false)}
              promptChecker={true}
              title='Confirm Navigation'
              description='You have unsaved changes. Are you sure you want to leave this page?'
              yesButtonText='Keep Editing'
              noButtonText='Discard Changes'
              confirmOnly
            />
          </Form>
        )
      }
    </Formik>
  );
};

export default OtherLoansSettingsTable;
