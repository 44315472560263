import colors from '../../../utility/colors';

const styles = {
  paperStyle: {
    display         : 'flex',
    flexDirection   : 'row',
    justifyContent  : 'flex-start',
    margin          : '0.5rem',
    backgroundColor : '#D0DBE8',
  },
  hidden: {
    display: 'none',
  },
  titleTextStyle: {
    mx: '20px',
    mt: '1rem',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  settingsTextStyle: {
    mx: '10px',
    mt: '1rem',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  formControlCheckbox: {
    fontSize: '14px',
    color: colors.PRIMARY,
  },
  additionalSettings: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  defaultSettings: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  defaultSettingsContent: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  checkboxContainer: {
    justifyContent: 'flex-start',
    px: '1rem',
  },
  checkboxGrid: {
    paddingLeft: '1rem'
  },
  textField: {
    backgroundColor: 'white',
    borderRadius: '4px',
    justifySelf: 'center',
    maxWidth: '180px',
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      '&::-webkit-inner-spin-button': {
        display: 'none',
      },
      '&::-webkit-outer-spin-button': {
        display: 'none',
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      '&::after':{
        content:'" *"',
        color: '#AE2633',
      },
    },
    '& .MuiInputAdornment-root': {
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
  },
  textFieldForCriteriaBased: {
    backgroundColor: 'white',
    borderRadius: '4px',
    justifySelf: 'center',
    maxWidth: '195px',
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      borderLeft: '1px solid #E0E0E0',
      '&::-webkit-inner-spin-button': {
        display: 'none',
      },
      '&::-webkit-outer-spin-button': {
        display: 'none',
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      '&::after':{
        content:'" *"',
        color: '#AE2633',
      },
    },
    '& .MuiInputAdornment-root': {
      '& .MuiTypography-root': {
        fontSize: '14px',
        color: '#000000',
      },
    },
  },
  concentrationContainer: {
    padding: '4px 36px 16px 26px',
    display: 'flex',
    flexFlow: 'column wrap',
  },
  criteriaBasedContainer: {
    padding: '4px 36px 16px 26px',
    display: 'flex',
  },
  defaultSettingDropdownField: {
    marginTop: '9px',
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      width: 'auto',
      fontSize: '14px',
      pr: 0,
    },
  },
  defaultSettingDropdownFieldForCriteriaBased: {
    marginRight: '5px',
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      width: '90px',
      fontSize: '14px',
      pr: 0,
    },
  },
  lisboxForDefaultSettingDropdownField: {
    fontSize: '14px'
  },
  concentrationValueField: {
    flexShrink: 2,
    minWidth: '55px',
    '& .MuiOutlinedInput-root': {
      height: '100%',
      '& .MuiInputBase-inputAdornedEnd': {
        textAlign: 'center'
      }
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      paddingLeft: '5px',
    },
    '& .MuiInputAdornment-root': {
      margin: 0,
      textAlign: 'right',
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
  },
  textFieldInputLabel: {
    color: '#000000',
    opacity: '100%'
  },
  deliquentSettingsContainer: {
    padding: '16px 36px 16px 26px',
    display: 'flex',
    gap: '1rem',
  },
  crossAgeContainer: {
    padding: '16px 36px 16px 26px',
  },
  contrasContainer: {
    padding: '2px 36px 16px 26px',
  },
  creditLimitContainer: {
    padding: '16px 36px 16px 26px',
  },
  numberAlignment: {
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      textAlign: 'right',
    }
  },
  skeletonLoaderContainer: {
    marginY: '0.6rem',
  },
  skeletonLoader: {
    margin: '0.5rem',
    backgroundColor : '#D0DBE8',
    height: '80px',
    borderRadius: '4px',
  },
  shortSkeletonLoader: {
    marginX: '0.5rem',
    backgroundColor : '#D0DBE8',
    height: '10px',
    borderRadius: '4px',
  },
  infoPaperStyle: {
    display         : 'flex',
    flexDirection   : 'row',
    justifyContent  : 'flex-start',
    margin          : '0.5rem',
    backgroundColor : '#EAECEF',
    border          : `1px solid ${colors.PRIMARY}`,
    borderRadius    : '4px',
  },
  infoBoxStyle: {
    margin          : '0.5rem',
    display         : 'flex',
    alignItems      : 'center',
    justifyContent  : 'flex-start',
    flexDirection   : 'row',
  },
  infoTextStyle: {
    fontSize      : '14px',
    color         : colors.PRIMARY,
    marginRight   : '0.5rem',
  },
  emptyCheckboxesContainerSpace: {
    margin: 1,
  }
}

export default styles;