const styles = {
  btnDateRange: {
    height: '1.8rem'
  },
  btnGroupDateRange: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0.8rem'
  },
  btnOK: {
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#99BFED',
    },
    marginLeft: '0.5rem'
  },
  clearIcon: {
    fontSize: '1rem',
  },
  clearIconButton: {
    padding: '0.1rem',
  },
  filterIcon: {
    color: '#154A8A',
    fontSize: '1rem',
  },
  filterIconButton: {
    borderRadius: 0,
    marginLeft: '0.5rem',
    padding: '0.1rem',
  },
  menuContainer: {
    padding: '1rem',
  },
  menuList: {
    maxHeight: '9rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '14.6rem',
  },
  openedIcon: {
    backgroundColor: '#fff',
  },
  searchFieldText: {
    fontSize: '0.875rem',
    height: '1.75rem',
    width: '14.6rem',
  },
  searchIcon: {
    fontSize: '1.3rem',
    marginRight: '0.5rem',
  },
}
export default styles;