import { FC, ReactNode } from 'react';
import { Button, IconButton, Grid, Modal, Typography, Divider, Box, Tooltip} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HistoryIcon from '@mui/icons-material/History';
import styles from './styles';
import DisabledComponentsContainer from '../../common/disabled-components-container';

interface IPopUpModalProps {
  open                : boolean;
  onClose             : () => void;
  isComponentEditable : boolean; // set to true if the modal has editable elements
  isEditableTrue      : boolean; // set to true if the modal has no editable elements
  tabIndex?           : number;
  handleEditClick?    : () => void;
  handleArchiveClick? : () => void;
  handleDeleteClick?  : () => void;
  handleRestoreClick? : () => void;
  isBBReportsTrue?    : boolean;
  isNotDeletable?     : boolean;
  isNotFromTable?     : boolean;
  title1              : string;
  title2?             : string;
  width               : string;
  children?           : ReactNode | ReactNode[];
}

const PopUpModal: FC<IPopUpModalProps> = (props) => {
  const { open, onClose, isComponentEditable, isEditableTrue, tabIndex, handleEditClick, handleArchiveClick, handleDeleteClick, handleRestoreClick, isBBReportsTrue, isNotDeletable, isNotFromTable, title1, title2, width, children } = props;

  const getDeleteArchive = () => {
    if (isNotDeletable) {
      return null;
    }
    
    if (isBBReportsTrue && isComponentEditable) {
      return getArchiveOrRestoreButton();
    }

    if (isComponentEditable) {
      return getDeleteButton();
    }
  }

  const getArchiveOrRestoreButton = () => {
    if (tabIndex === 0) {
      return (
        <DisabledComponentsContainer isDisabled={isEditableTrue}>
          <Button
            startIcon={<Inventory2OutlinedIcon sx={{...styles.actionsIcon, ...(isEditableTrue && styles.disabledIcon),}} />}
            onClick={()=>{handleArchiveClick!()}}
            disabled={isEditableTrue}
            aria-label={isEditableTrue ? 'Archive icon button disabled' : 'Archive icon'}
            sx={styles.actionsIconButton}
          >
            <Typography sx={{...styles.buttonText,...(isEditableTrue && styles.disabledIcon)}}>
              Archive
            </Typography>
          </Button>
        </DisabledComponentsContainer>
      )
    } else {
      return (
        <DisabledComponentsContainer isDisabled={isEditableTrue}>
          <Button
            startIcon={<HistoryIcon sx={{...styles.actionsIcon, ...(isEditableTrue && styles.disabledIcon),}} />}
            onClick={()=>{handleRestoreClick!()}}
            disabled={isEditableTrue}
            aria-label={isEditableTrue ? 'Restore icon button disabled' : 'Restore icon'}
            sx={styles.actionsIconButton}
          >
            <Typography sx={{...styles.buttonText,...(isEditableTrue && styles.disabledIcon)}}>
              Restore
            </Typography>
          </Button>
        </DisabledComponentsContainer>
      )
    }
  }

  const getDeleteButton = () => {
    return (
      <DisabledComponentsContainer isDisabled={isEditableTrue}>
        <Button
          startIcon={<DeleteOutlineOutlinedIcon sx={{...styles.actionsIcon, ...(isEditableTrue && styles.disabledIcon),}}/>}
          onClick={()=>{handleDeleteClick!()}}
          disabled={isEditableTrue}
          aria-label={isEditableTrue ? 'Delete icon button disabled' : 'Delete icon'}
        >
          <Typography sx={{...styles.buttonText,...(isEditableTrue && styles.disabledIcon)}}>
            Delete
          </Typography>
        </Button>
      </DisabledComponentsContainer>
    )
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Grid container sx={{...styles.modal, maxWidth: width}}>
        <Grid item xs={12} sx={isNotFromTable ? styles.titleContainer : undefined}>
          <Grid
            container
            justifyContent='flex-end'
            alignItems='center'
            id='modal-title'
          >
            <Typography tabIndex={0} id='modal-modal-title' variant='h6' component='h2' sx={styles.title}>
              {isEditableTrue ? title1 : title2}
            </Typography>
            {isComponentEditable ?
            <Box sx={{display: 'flex'}}>
              <DisabledComponentsContainer isDisabled={isEditableTrue}>
                <Button
                  startIcon= {
                    <EditOutlinedIcon sx={{
                      ...styles.actionsIcon,
                      ...(isEditableTrue && styles.disabledIcon),
                      ...(tabIndex === 1 && styles.hidden)}} />
                  }
                  onClick={()=>{handleEditClick!()}}
                  disabled={isEditableTrue}
                  aria-label={isEditableTrue ? 'Edit icon button disabled' : 'Edit icon'}
                  sx={styles.actionsIconButton}
                >
                  <Typography sx={{...styles.buttonText,...(isEditableTrue && styles.disabledIcon), ...(tabIndex === 1 && styles.hidden)}}>
                      Edit
                  </Typography>
                </Button>
              </DisabledComponentsContainer>
              <Divider orientation='vertical' flexItem sx={{...styles.divider, ...(isEditableTrue && styles.disabledDivider), ...(tabIndex === 1 && styles.hidden)}}/>
            </Box> : null}
            {getDeleteArchive()}
            <Grid>
              <Tooltip title='Close the modal'>
                <IconButton onClick={onClose} aria-label='Close icon'>
                  <CloseIcon fontSize='inherit'/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {children}
      </Grid>
    </Modal>
  )
}
export default PopUpModal;