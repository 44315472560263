import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react";
import { ActionModalProps, ActionPanelProps, IHeadCell, IToasterProps } from "..";
import { Box, Checkbox, IconButton, Skeleton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { IARCustomer, IARVendor } from "../../../interfaces";
import PreviewIcon from '@mui/icons-material/Preview';
import styles from "./styles";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../context/customerSettingsContext";
import { StyledTableCell } from "../../../components/skeleton";
import CustomerModal from "../customer-modal";
import VendorModal from "../vendor-modal";
import { formatDate } from "../../../utility/helper";
import { SelectedClientContext } from "../../../context/selectedClientContext";
import NewTag from "../../../components/common/new-tag";
import { DeleteOutlined, HistoryOutlined, Inventory2Outlined } from "@mui/icons-material";
export interface IMainRowProps extends IToasterProps {
  index               : number;
  type                : string;
  dataInfo            : IARCustomer | IARVendor;
  dataList            : Array<IARCustomer | IARVendor>;
  setDataList         : Dispatch<Array<IARCustomer | IARVendor>>;
  tableLabel          : string;
  fetchData           : () => void;
  headers             : IHeadCell[];
  setActionModal      : Dispatch<SetStateAction<ActionModalProps>>;
  actionPanel         : ActionPanelProps;
  setActionPanel      : Dispatch<SetStateAction<ActionPanelProps>>;
  archive             : boolean;
}

/**
 * This component is used to display each customer or vendor on a row component.
 * 
 * @param props ICustSettingsRowProps
 * @returns A Row Component that display the customer's primary details.
 */
const MainRow: FC<IMainRowProps> = (props) => {
  const { 
    index,
    dataInfo, 
    dataList, 
    setDataList,
    type,
    archive,
    tableLabel,
    setActionModal,
    actionPanel,
    setActionPanel,
  }                                           = props;
  const {
    canViewCustomerInformation,
  }                                           = useContext(CustomerSettingsContext) as ICustomerSettingsContext;
  const { selectedClient }                    = useContext(SelectedClientContext);
  const [rowCells, setRowCells]               = useState<string[]>([]);
  const [open, setOpen]                       = useState<boolean>(false);


  /**
   * This useEffect is used to set the row value.
   */
  useEffect(() => {
    const rowCells = props.headers.map((header) => {
      if (header.type === 'string') {
        return dataInfo[header.id] ?? '-';
      }
      else if (header.type === 'date') {
        return dataInfo[header.id] ? formatDate(dataInfo[header.id], 'MM-DD-YYYY') : '-';
      }
      else if (header.type === 'number') {
        return dataInfo[header.id] ? dataInfo[header.id].toString(): '-';
      }
      else {
        return '-';
      }
    });
  
    setRowCells(rowCells);
  }, [dataInfo]);

  /**
   * This component generates a tooltip if the custName is truncated.
   * 
   * @param custName The name of the customer.
   * @returns A Typhography component, with tooltip if the custName is truncated.
   */
  const getCustName = (custName: string) => {
      if (custName.length >= 30) {
        return (
          <Tooltip title={`${custName}`}>
            <Typography sx={styles.custName}>{custName}</Typography>
          </Tooltip>
        );
      } else {
        return (<Typography sx={styles.custName}>{custName}</Typography>);
      }
  };

  /**
   * This function is used to determine if the customer is a parent
   */
  const CheckIfParent = () => {
    const field = selectedClient?.parentClient ? 'upcParentCustSrcId' : 'parentCustSrcId';
    const customerList = props.dataList as IARCustomer[];
    const custInfo = props.dataInfo as IARCustomer;
    return customerList.some(cust => cust[field] === custInfo.custSrcId)
  };

  const renderModal = (type: string) => (
    type === 'customer' ? (
      <CustomerModal 
        open={open} 
        setOpen={setOpen} 
        customerList={props.dataList as IARCustomer[]}
        customer={props.dataInfo as IARCustomer}
        handleSuccess={(values) => {
          props.setIsToasterOpen(true)
          props.setToasterMessage('Changes in Customer Settings have been saved.')
          props.setToasterSeverity('success')
          props.fetchData()
        }}
        handleFailed={(e) => {
          props.setIsToasterOpen(true)
          props.setToasterMessage('Failed to save changes, Please try again')
          props.setToasterSeverity('error')
        }}
        isParent={CheckIfParent()}
        onSettings
      />
    ) : (
      <VendorModal
        open={open} 
        setOpen={setOpen} 
        vendorList={props.dataList as IARVendor[]}
        vendor={props.dataInfo as IARVendor}
        handleSuccess={(_values) => {
          props.setIsToasterOpen(true)
          props.setToasterMessage('Changes in Customer Settings have been saved.')
          props.setToasterSeverity('success')
          props.fetchData()
        }}
        handleFailed={(_e) => {
          props.setIsToasterOpen(true)
          props.setToasterMessage('Failed to save changes, Please try again')
          props.setToasterSeverity('error')
        }}
        onSettings
      />
    )
  );

  const renderDeleteArchive = (canDelete: number, archive: boolean) => {
    if (archive) {
      return (
        <>
        <Tooltip title={actionPanel.open ? `Restore action is disabled when selecting ${type}/s` : `Restore ${tableLabel}`}>
          <span>
          <IconButton
            disabled={actionPanel.open}
            aria-label={`Restore ${tableLabel}`} 
            data-testid={`restore-${dataInfo.recordId}`}
            color='primary'
            size='small' 
            onClick={() => {
              setActionModal({
                recordIds: [dataInfo.recordId!],
                dataType: type,
                actionType: 'restore',
                open: true,
                title: `Restore ${tableLabel}`,
                description: `You are about to restore this ${type}. Are you sure?`,
                yesButtonText: 'Restore',
                noButtonText: 'Cancel',
                errorButton: false,
              });
            }}
          >
            <HistoryOutlined />
          </IconButton>
          </span>
        </Tooltip>
        </>
      )
    }

    return (
      <>
      <Tooltip title={`View ${tableLabel} Details`}>
        <IconButton aria-label={`View ${tableLabel} details icon`} color='primary' size='small' onClick={() => setOpen(true)}>
          <PreviewIcon />
        </IconButton>
      </Tooltip>
      {canDelete === 1 ? (
        <Tooltip title={actionPanel.open ? `Delete action is disabled when selecting ${type}/s` : `Delete ${tableLabel}`}>
          <span>
          <IconButton 
            disabled={actionPanel.open}
            aria-label={`Delete ${tableLabel}`}
            color='primary'
            size='small'
            data-testid={`delete-${dataInfo.recordId}`}
            onClick={() => {
              setActionModal({
                recordIds: [dataInfo.recordId!],
                dataType: type,
                actionType: 'delete',
                open: true,
                title: `Delete ${tableLabel}`,
                description: `You are about to delete this ${type}. Are you sure?`,
                yesButtonText: 'Delete',
                noButtonText: 'Cancel',
                errorButton: true,
              });
            }}
          >
            <DeleteOutlined />
          </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip title={actionPanel.open ? `Archive action is disabled when selecting ${type}/s` : `Archive ${tableLabel}`}>
          <span>
          <IconButton 
            disabled={actionPanel.open}
            aria-label={`Archive ${tableLabel}`} 
            data-testid={`archive-${dataInfo.recordId}`}
            color='primary'
            size='small' 
            onClick={() => {
              setActionModal({
                recordIds: [dataInfo.recordId!],
                dataType: type,
                actionType: 'archive',
                open: true,
                title: `Archive ${tableLabel}`,
                description: `You are about to archive this ${type}. Are you sure?`,
                yesButtonText: 'Archive',
                noButtonText: 'Cancel',
                errorButton: false,
              });
            }}
          >
            <Inventory2Outlined />
          </IconButton>
          </span>
        </Tooltip>
      )}
      </>
    )
  }

  return (
    <>
      {rowCells[0] === undefined ?
      <TableRow>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
        <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
      </TableRow>
      : <TableRow sx={styles.tableRow}>
        <TableCell sx={styles.tableCellNewTag}>
          <Box sx={styles.newTagBox}>
            { props.dataInfo?.isNew && <Typography><NewTag expanding /></Typography> }
          </Box>
        </TableCell>
        <TableCell sx={styles.tableCellNewTag}>
          <Box sx={styles.newTagBox}>
            <Checkbox 
              data-testid={`select-${dataInfo.recordId}`}
              checked={dataInfo.selected}
              onClick={() => {
                const newDataList = [
                  ...dataList
                ];

                const findIndex = newDataList.findIndex((data) => data.recordId === dataInfo.recordId);

                newDataList[findIndex].selected = !newDataList[findIndex].selected;
                setDataList(newDataList);

                const selectedList = newDataList
                  .filter((data) => data.selected);

                setActionPanel({
                  restore: archive,
                  open: selectedList.length > 0,
                });
              }}
            />
          </Box>
        </TableCell>
        {rowCells.map((rowHeader, index) =>
          <TableCell 
            width={'20%'} 
            tabIndex={0} 
            key={index} 
            onClick={() => setOpen(true)} 
            sx={{
              ...styles.tableCell,
            }}
            >
            {getCustName(rowHeader)}
          </TableCell>
        )}
        {
          canViewCustomerInformation && 
          <TableCell 
            align='left' 
            sx={{
              ...styles.tableCell,
              display: 'flex',
              flexDirection: 'row',
            }}
            width={'10%'}>
            { renderDeleteArchive(dataInfo.canDelete!, archive) }
          </TableCell>
        }
      </TableRow>
      }
      { renderModal(type) }      
    </>
  );
};
export default MainRow;