import { getUniqueKeys } from '../../../../../../../../../utility/helper';
import styles from '../styles';
import { IFieldMappingProps } from '..';
import { FC, useMemo } from 'react';
import { IDocumentAttribute } from '../../../../../../../../../interfaces/dataMap';
import { Box, Divider, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ClearIcon from '@mui/icons-material/Clear';

export interface IHeaderSelectProps extends IFieldMappingProps {
  index                       : number;
  header                      : string | null;
  headers                     : (string | null)[];
  addedFields                 : IDocumentAttribute[];
  headerOptions               : {name: string; value: string;}[];
  handleHeaderChange          : (newHeader: string | null, index: number) => void;
  handleClickDelete           : (deleteName: string, deleteRecordId: number) => void;
  setNewFieldModalOpenCallback: (newValue: boolean) => () => void
}

/**
 * Component for the Header Select of the Field Mapping Step.
 * @param props The props for the Header Select of the Field Mapping Step.
 * @returns A component for the Header Select of the Field Mapping Step.
 */
const HeaderSelect: FC<IHeaderSelectProps> = (props) => {
  const {
    index, header, headers, addedFields,
    headerOptions, handleHeaderChange, handleClickDelete,
    setNewFieldModalOpenCallback
  }                                                       = props;

  const mappedAddedFields: {name: string; value: string;}[] =  useMemo(() => addedFields.map(addedField => {
    const mapped: {name: string; value: string;} = {
      name: addedField.fieldName,
      value: addedField.value
    };
   return mapped;
   
   }), [addedFields])
  
   /**
   * This function returns an icon component for clearing a header mapping.
   * @param header The header value to be cleared.
   * @param index The index of the header.
   * @returns An object containing the IconComponent or undefined.
   */
  const getIconComponent = (header: string | null, index: number) => {
    const IconComponent = header ? () =>
      <IconButton
        aria-label='Clear icon'
        size='small'
        sx={styles.clearIconButton}
        onClick={() => handleHeaderChange(null, index)}
      >
        <ClearIcon sx={styles.clearIcon} />
      </IconButton>
      : undefined;

    return { IconComponent }
  };

  const getHeaderOptions = () => {
    const filteredHeaderOptions = headerOptions.filter(option => !headers.includes(option.value));
    return (
      filteredHeaderOptions.map((item) => (
      <MenuItem
        tabIndex={0}
        value={item.value}
        key={item.value}
        sx={styles.gridMenuItem}
      >
        {item.name}
      </MenuItem>))
    );
  };

  return (
    <Select
      value={header ?? undefined}
      key={getUniqueKeys(header)}
      sx={{ ...styles.gridField, ...(header === null && styles.dropdownDefault)}}
      displayEmpty
      id='dataMapField'
      aria-label='Data Map field'
      inputProps={{ 'aria-label': 'Select Column', 'aria-labelledby': 'dataMapField' }}
      renderValue={(selected) => (
        <Box>{[...headerOptions, ...mappedAddedFields].find((field) => field.value === selected || field.name === selected)?.name ?? 'Please Select'}</Box>
      )}
      onChange={(event: SelectChangeEvent<string>) => handleHeaderChange(event.target.value, index)}
      {...getIconComponent(header, index)}
    >
      <MenuItem value='' sx={{ ...styles.gridMenuItem, ...styles.hidden }} disabled>
        Please Select
      </MenuItem>
      {getHeaderOptions()}
      {addedFields.length > 0 && 
        (<Box sx={styles.addedFieldsContainer}>
          <Divider absolute sx={styles.addedFieldsDivider} />
          <Box sx={styles.addedFieldsText}>
            <Typography variant='body1' sx={styles.addedFieldsText}>Added Fields</Typography>
          </Box>
        </Box>)}
      {addedFields.map((item) => (
        <MenuItem
          value={item.value}
          key={item.value}
          sx={styles.gridMenuItem}
        >
          {item.fieldName}
          <IconButton
            data-testid='delete-added-fields-btn'
            sx={styles.deleteIcon}
            size='small'
            tabIndex={-1}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleClickDelete(item.fieldName, item.recordId);
            }}
          >
            <HighlightOffIcon color='secondary' />
          </IconButton>
        </MenuItem>))}
      <MenuItem
        onClick={(event) => {
          event.preventDefault();
          setNewFieldModalOpenCallback(true)();
        }} 
        sx={{...styles.gridMenuItem, ...styles.addNewFieldButton}}
      >
        + Add New Field
      </MenuItem>
    </Select>
  );
}

export default HeaderSelect;