import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { formatDate } from '../../../../utility/helper';
import { ReportsContext, IReportsContext } from '../../../../context/reportsContext';
import * as ReactRouter from 'react-router-dom';
import ReportsTitle from '../../../reports-title';
import styles from './styles';

const BorrowingBaseReportHeading = () => {
  const {
    selectedClient: client,
    selectedDatePeriod: datePeriod,
  } = useContext(ReportsContext) as IReportsContext;

  const [formattedDatePeriod, setFormattedDatePeriod] = useState('');
  const [calcDatePeriod, setCalcDatePeriod] = useState<string | null>(null);

  const [searchParams] = ReactRouter.useSearchParams();
  const calculatedDate = searchParams.get('calcDate');

  /**
   * This method processes the 'calculatedDate' and, if present, sets it in a formatted manner.
   * 
   * @returns 
   */
  const getCalculatedDate = () => {
    if (!calculatedDate) return;
    setCalcDatePeriod(formatDate(calculatedDate, 'MMMM DD, YYYY'));
  }

  /**
   * This useEffect hook is triggered by changes in `datePeriod`.
   * - Initially checks and processes the 'calculatedDate' if the component is possibly redirected from another modal.
   * - If `datePeriod` is null, it resets the formatted date period state.
   * - Otherwise, it sets the `datePeriod.endDate` in a formatted manner.
   */
  useEffect(() => {
    // check if redirected from calc bbase modal
    getCalculatedDate();

    if (datePeriod === null) {
      setFormattedDatePeriod('');
      return;
    }
    setFormattedDatePeriod(formatDate(datePeriod.endDate, 'MMMM DD, YYYY'));
  }, [datePeriod]);

  return (
    <Box sx={styles.titleContainer}>
      <ReportsTitle
        clientName={client?.borrowerName ?? ''}
        reportName='Borrowing Base Report'
        bbPeriod={calcDatePeriod ?? formattedDatePeriod}
      />
    </Box>
  );
};

export default BorrowingBaseReportHeading;
