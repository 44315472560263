import colors from "../../../../utility/colors";

const styles = {
  modalBackground: {
    bgcolor: '#FFFFFF',
    borderRadius: '5px',
    left: '50%',
    position: 'absolute',
    boxShadow: 15,
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '580px',
    display: 'flex',
    flexDirection: 'column',
  },

  headingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    m: 0,
    pb: '10px',
    pl: '21px',
    pr: '21px',
    pt: '24px',
  },

  headingTitle: {
    color: '#1B1E21',
    fontSize: '18px',
  },

  closeButton: {
    cursor: 'pointer',
    color: '#808080',
    stroke: '#808080',
    strokeWidth: 1,
    fontSize: 20,
    alignSelf: 'flex-start',
  },

  divider: {
    bgcolor: '##E9ECEF',
    borderBottomWidth: 2,
    opacity: '0.5',
  },

  firstMessage: {
    m: 0,
    pl: '21px',
    pr: '21px',
    pt: '24px',
    fontSize: '16px',
    color: '#000000',
  },

  secondMessage: {
    m: 0,
    pl: '21px',
    pr: '21px',
    fontSize: '16px',
    color: '#000000',
  },

  parentNameText: {
    m: 0,
    pb: '10px',
    pl: '21px',
    pr: '21px',
    pt: '24px',
    fontSize: '16px',
    color: '#000000',
  },

  rightButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: 'auto',
    my: '16px',
    mx: '20px',
  },

  clientsButton:{
    minWidth: '82px',
    height: '31px',
    ml: 1,
    textTransform: 'capitalize',
  },

  okayButton: {
    minWidth: '82px',
    height: '31px',
    ml: 1,
    textTransform: 'capitalize',
    backgroundColor: colors.PRIMARY,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: colors.PRIMARY_DARK,
    },
  },
};

export default styles;