import yup from './index';

const loanBalancesSchema = yup.object().shape({
  loanBalances: yup
    .array()
    .of(yup.object().shape({
        glAccountName: yup.string().trim(),
        glAccountNumber: yup.string().trim(),
        asOfDate: yup.string().required('As of Date is required'),
        amount: yup.string().required('Amount is required'),
    }))
    .uniqueProperty('glAccountName', 'GL Account Name must be unique')
    .uniqueProperty('glAccountNumber', 'GL Account Number must be unique')
});

export default loanBalancesSchema;
