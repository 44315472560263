import { FileImportProcessState } from "../reducer/fileImportProcessReducer";

export enum ActionType {
  FileImportProcess
}

export interface FileImportProcess {
  type: ActionType.FileImportProcess;
  payload: FileImportProcessState;
}

export const setFileImportProcessLoader = (payload: FileImportProcessState): FileImportProcess => {
  return {
      type: ActionType.FileImportProcess,
      payload
  }
}

export type FileImportProcessActions = FileImportProcess;