const styles = {
  amountTableHeader: {
    backgroundColor: '#F2F7F8',
    textAlign: 'right',
    paddingBottom: '0.5rem',
    fontSize: '0.938rem',
    whiteSpace: 'nowrap'
  },
  iconDefaultSort: {
    color: '#767272',
    fontSize: '16px'
  },
  colSpacer: {
    border: 'none'
  },
  tableContainer: {
    maxHeight: '25.5rem',
  },
  tableHeader: {
    backgroundColor: '#F2F7F8',
    paddingBottom: '0.5rem',
    fontSize: '0.938rem',
    whiteSpace: 'nowrap',
    zIndex: '100',
  },
}
export default styles;