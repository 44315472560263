import { FC, useContext, useEffect, useState } from 'react';
import { Card } from '@mui/material';
import SubscribingComponent from './subscribing-component';
import styles from './styles';
import { CalcIneligibleContext } from '../../../../context/calneligibleContext';
import { setCalcIneligibleLoader } from '../../../../actions/calcIneligibleActions';
import { CalcARStatus } from '../../../../interfaces/calcARStatus';
import { StompSessionProvider } from 'react-stomp-hooks';
import { NON_EXISTING, WEB_SOCKET_DOMAIN } from '../../../../utility/constants';
import { CalcIneligibleState } from '../../../../reducer/calcIneligibleReducer';

export interface CalculationLoaderProps {
  title: string,
  content: string,
  isLoading: boolean,
  link: string,
  show: boolean,
}

export interface IIneligibleSettingForCalculationLoading {
  code:        string,
  description: string,
  isLoading:   boolean,
}

const CalculationLoader: FC<CalculationLoaderProps> = (props) => {
  const { state, dispatch } = useContext(CalcIneligibleContext);
  const [calcARStatus, setCalcARStatus] = useState<CalcARStatus | null>(null);
  const [ineligibles, setIneligibles] = useState<IIneligibleSettingForCalculationLoading[]>([]);
  const [lastCalculatedIneligibleIndex, setLastCalculatedIneligibleIndex] = useState(NON_EXISTING);

  useEffect(() => { /* to reset status for next recalculation */
    if (state.isLoading) { return; }
    setCalcARStatus(null);
    setLastCalculatedIneligibleIndex(NON_EXISTING);
  }, [state.isLoading]);

  useEffect(() => {
    const lastCalculatedIneligibleIndex = getLastCalculatedIneligibleIndex(calcARStatus);
    const updatedIneligibles = state.ineligibleSettings
      .filter(ineligible => !ineligible.disabled)
      .sort((a, b) => a.order - b.order)
      .map((ineligible, index) => ({
        code: ineligible.code, description: ineligible.description,
        isLoading: lastCalculatedIneligibleIndex === NON_EXISTING || index > lastCalculatedIneligibleIndex
      }));
    setIneligibles(updatedIneligibles);

    if (!calcARStatus) return;

    const title = `${calcARStatus?.status.toLowerCase() === 'done' ? 'Calculated': calcARStatus?.status} ${calcARStatus?.message}`;
    const payload: CalcIneligibleState = { ...state, title };
    if (title === 'Calculation Finished') {
      dispatch(setCalcIneligibleLoader({ ...payload, isLoading: false }));
      return;
    } else if (title === 'Calculation Failed') {
      dispatch(setCalcIneligibleLoader({ ...payload,  isLoading: false, failed: true, link: '' }));
      return;
    }

    dispatch(setCalcIneligibleLoader({ ...payload, isLoading: true }));
  }, [calcARStatus, state.ineligibleSettings]);

  const getLastCalculatedIneligibleIndex = (calcARStatus: CalcARStatus | null) => {
    const calculatedIneligibleIndex = ineligibles.findIndex(obj => obj.description === calcARStatus?.message && calcARStatus.status === 'done');
    if (calculatedIneligibleIndex === NON_EXISTING) { return lastCalculatedIneligibleIndex; }
    setLastCalculatedIneligibleIndex(calculatedIneligibleIndex);
    return calculatedIneligibleIndex;
  };

  return (
    <Card variant="outlined" sx={styles.container}>
      <StompSessionProvider url={`${WEB_SOCKET_DOMAIN}/calc`}>
        <SubscribingComponent
          state={state}
          dispatch={dispatch}
          setCalcARStatus={setCalcARStatus}
          ineligibles={ineligibles}
          {...props}
        />
      </StompSessionProvider>
    </Card>
  )
}

export default CalculationLoader;