import colors from "../../../../../utility/colors";

const styles = {
  tableContainer: {
    width: '100%',
    maxHeight: '30rem',
    marginBottom: '1rem'
  },
  tableHeader: {
    boxShadow: '0px 3px 6px #00000029',
  },
  tableHeaderText: {
    backgroundColor: '#EAEAEA',
    borderBottom: 0,
    fontSize: '15px',
    height: '2.5rem',
    paddingX: '0.1rem',
  },
  tableRow: {
    '&:hover': {
      color: '#0075AD',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    backgroundColor: 'none'
  },
  tableCell: {
    color: 'inherit',
    fontSize: '15px',
    paddingTop: '1rem',
    backgroundColor: 'none',
    paddingX: '0.1rem'
  },
  widthActionButton: {
    minWidth: '1.5rem',
    maxWidth: '1.5rem',
  },
  widthVendorId: {
    minWidth: '10rem',
    maxWidth: '10rem',
  },
  widthVendorName: {
    minWidth: '10rem',
    maxWidth: '10rem',
  },
  widthBuckets: {
    minWidth: '5rem',
    maxWidth: '5rem',
  },
  tableTextHeaderNumeric: {
    textAlign: 'right',
  },
  defaultSortIcon: {
    color: '#767272',
    fontSize: '16px'
  },
  collapseButton: {
    padding: '0.1rem'
  },
  tableCellNumber: {
    paddingRight: '1rem',
  },
  tableCellCollapse: {
    paddingY: 0,
    paddingX: '0.1rem',
    border: 0
  },
  tableFooterText: {
    backgroundColor: '#F2F7F8',
    color: '#000',
    paddingTop: '1.4rem',
    paddingBottom: '0.8rem',
    fontSize: '15px',
    paddingX: '0.1rem',
    fontWeight: 'bold',
  },
  rowSpacerTop: {
    paddingTop: '1rem'
  },
  rowSpacer: {
    paddingBottom: '1rem',
  },
  widthDate: {
    minWidth: '6rem',
    maxWidth: '6rem',
  },
  collapseIcon: {
    color: '#154A8A'
  },
  vendorNameTextContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  vendorNameText: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
};

export default styles;
