import { FC, useContext } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { formatCurrency, formatDate, formatPercentage } from '../../../../utility/helper';
import { ReportsContext, IReportsContext } from '../../../../context/reportsContext';
import { AMERICAN_DATE_FORMAT } from '../../../../utility/constants';
import { IARBorrowingBase } from '../../../../interfaces/reportsInterface';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import TableWithLeftPinnedHeader from '../../../common/table-with-left-pinned-header';
import styles from '../grid-details/styles';

interface IARBBListTableProps {
  arBBList: IARBorrowingBase[];
}

const AccountsReceivableBBListTable: FC<IARBBListTableProps> = (props) => {
  const {selectedClient: client} = useContext(ReportsContext) as IReportsContext;
  
  return (
    (props.arBBList.length > 0) ? (
      <TableWithLeftPinnedHeader
        columns={[
          { field: 'collateralName', headerName: 'Collateral Name' },
          { field: 'date', headerName: '' },
          { field: 'grossAvailable', headerName: 'Gross Available - Borrowing Base Value' },
          { field: 'lessCollateralIneligible', headerName: 'Less Collateral Ineligible' },
          { field: 'eligibleCollateral', headerName: 'Eligible Collateral' },
          { field: 'advanceRatePercentage', headerName: 'Advance Rate Percentage' },
          { field: 'perBorrowingBase', headerName: 'Per Borrowing Base' },
          { field: 'effectiveAdvanceRate', headerName: 'Effective Advance Rate' },
          { field: 'arIneligibleReport', headerName: 'AR Ineligible Report' },
          { field: 'arAgingReport', headerName: 'AR Aging Report' },
          { field: 'apAgingReport', headerName: 'AP Aging Report' },
        ]}
        rows={
          props.arBBList.map((row, index) => ({
            collateralName: <div title={row.arCollateralName}>{row.arCollateralName}</div>,
            date: (<Typography sx={styles.labelDate}>{formatDate(row.endDate, AMERICAN_DATE_FORMAT)}</Typography>),
            grossAvailable: `${formatCurrency(row.grossAR)}`,
            lessCollateralIneligible: `${formatCurrency(row.ineligibleAR)}`,
            eligibleCollateral: `${formatCurrency(row.eligibleAR)}`,
            advanceRatePercentage: formatPercentage(row.rateOfAdvance, 2),
            perBorrowingBase: `${formatCurrency(row.availableAR)}`,
            effectiveAdvanceRate: formatPercentage(row.effectiveRateOfAdvance, 2),
            arIneligibleReport: (
              <Tooltip title='Go to AR Ineligible Report'>
                <IconButton tabIndex={-1} data-testid={`arBBList-arIneligible-redirect-${index}`} sx={styles.linkButton} onClick={() => {
                  window.open(`/reports/ar-ineligible?clientId=${client?.recordId}&bbPeriodId=${row.bbPeriodId}&arCollateralId=${row.arCollateralId}`);
                }}>
                  <OpenInNewOutlinedIcon sx={styles.linkIcon} />
                </IconButton>
              </Tooltip>
            ),
            arAgingReport: (
              <Tooltip title='Go to AR Aging Report'>
                <IconButton tabIndex={-1} data-testid={`arBBList-arAging-redirect-${index}`} sx={styles.linkButton} onClick={() => {
                  window.open(
                    `/reports/ar-aging?clientId=${client?.recordId}&bbPeriodId=${row.bbPeriodId}&arCollateralId=${row.arCollateralId}`
                  );
                }}>
                  <OpenInNewOutlinedIcon sx={styles.linkIcon} />
                </IconButton>
              </Tooltip>
            ),
            apAgingReport: (
              <Tooltip title='Go to AP Aging Report'>
                <IconButton tabIndex={-1} sx={styles.linkButton}
                onClick={() => {
                  window.open(
                    `/reports/ap-aging?clientId=${client?.recordId}&bbPeriodId=${row.bbPeriodId}&arCollateralId=${row.arCollateralId}`
                  );
                }}
                >
                  <OpenInNewOutlinedIcon sx={styles.linkIcon} />
                </IconButton>
              </Tooltip>
            ),
          }))
        }
        styles={{
          tableHeadCell: styles.tableHeadCell,
          tableCell: styles.tableCell,
          tableBody: styles.tableBody,
          tableHead: styles.tableHead,
        }}
      />
    ) : <></>
  );
};

export default AccountsReceivableBBListTable;
