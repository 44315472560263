const styles = {
  listItem: {
    bgcolor: 'background.paper',
    padding: 0,
    '& .Mui-selected': {
      color: '#154A8A',
    },
  },
  listItemWithError: {
    bgcolor: '#FAF0F1',
    color: '#AE2633',
    padding: 0,
    '& .Mui-selected': {
      bgcolor: '#FFE7EA',
    },
    '&:hover': {
      bgcolor: '#FFE7EA',
    }
  },
  listItemForDragging: {
    bgcolor: '#154A8A36',
    padding: 0,
    '& .Mui-selected': {
      color: '#154A8A',
    },
  },
  listItemForDraggingWithError: {
    bgcolor: '#FDD6DA',
    color: '#AE2633',
    padding: 0,
    '& .Mui-selected': {
      bgcolor: '#FFE7EA',
    },
    '&:hover': {
      bgcolor: '#FFE7EA',
    }
  },
  listItemButton: {
    pl: '12px',
    '&.MuiListItemButton-root.Mui-disabled': {
        cursor: 'pointer',
    },
    '&.Mui-disabled > :not(.MuiListItemButton-root):not(.MuiListItemText-root)': {
      pointerEvents: 'auto',
    },
  },
  listItemText: {
    ml: '28px',
  },
  nestedList: {
    py: '4px',
    ml: '4rem',
  },
  nestedListItem: {
    padding: 0,
  },
  nestedListItemText: {
    ml: '0rem',
    fontSize: '12px',
  },
  checkbox: {
    padding: 0,
    mr: '0.25rem',
  },
  skeletonRowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  skeletonRow: {
    backgroundColor: '#f0f7ff',
    width: '84%',
    height: '60px',
    '&: nth-of-type(odd)': {
      height: '20px',
    }
  }
}

export default styles;