import { BrowserRouter, Route, Routes } from 'react-router-dom';
import APAgingReport from '../pages/reports/ap-aging';
import ARAgingReport from '../pages/reports/ar-aging';
import ARIneligibleCompare from '../pages/reports/ar-compare';
import ArIneligibleReport from '../pages/reports/ar-ineligible';
import { AuthContext } from '../context/authContext';
import BorrowingBase from '../pages/borrowing-base';
import BorrowingBaseReport from '../pages/reports/borrowing-base';
import CalcIneligibleContextProvider from '../context/calneligibleContext';
import ClientSetting from '../pages/client-setting';
import Clients from '../pages/clients';
import CustomerSetting from '../pages/customer-setting';
import DrawerContextProvider from '../context/drawerContext';
import ErrorPage from '../pages/error';
import FileImport from '../pages/file-import/landing-page';
import FileUploadStepper from '../pages/file-import/stepper';
import IneligibleAdjustments from '../pages/ineligible-adjustments';
import IneligibleSettings from '../pages/ineligible-settings';
import InventoryIneligibles from '../pages/inventory-ineligibles';
import InventoryWorksheet from '../pages/inventory-worksheet';
import Layout from '../components/layout';
import LenderSettings from '../pages/lender-settings';
import LoanBalances from '../pages/loan-balances';
import Reports from '../pages/reports';
import Reserve from '../pages/reserve';
import RollForwardReport from '../pages/reports/roll-forward';
import { useContext, useEffect, useState } from 'react';
import FileImportProcessContextProvider from '../context/fileImportProcessContext';
import ExchangeRate from '../pages/client-setting/exchange-rate';
import FileImportProvider from '../context/fileImportContext';
import FileImportPermissionProvider from '../context/fileImportPermissionsContext';
import { setLocalStorageItem } from '../utility/helper';
import { IAccessResponseAPI } from '../interfaces/rolesPermissionInterface';
import { GET } from '../utility/constants';
import { authAPI } from '../service/api';
import axiosInstance from '../service/axiosInstance';
import NoToken from '../pages/no-token';
import SelectedClientProvider from '../context/selectedClientContext';
import ExportReportContextProvider from '../context/exportReportContext';
import AuditTrailPage from '../pages/audit-trail';

const Router = () => {
  const { state } = useContext(AuthContext);
  const { token } = state;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (token === undefined || token === null) {
      setLoading(true);
      retrieveToken();
    } else {
      setLoading(false);
    }
  }, [token])

  const retrieveToken = async () => {
    try {
      const response = await axiosInstance.request({
        url: authAPI.RETRIEVE_TOKEN,
        method: GET
      })
  
      const accessResponse : IAccessResponseAPI = response.data;
      setLocalStorageItem('token', accessResponse.loginToken.accessToken);
      setLocalStorageItem('uid', accessResponse.user.id);
      setLocalStorageItem('email', accessResponse.user.email);
      setLocalStorageItem('firstName', accessResponse.user.firstName);
      setLocalStorageItem('lastName', accessResponse.user.lastName);
    } catch (error) {
      console.error('Error fetching token:', error);
    } finally {
      setLoading(false);
    }
    
  }
  
  if (loading === true) {
    return <></>;
  } else {
    return (
      <BrowserRouter>
        <SelectedClientProvider>
          {/* {token ? ( */}
            <Routes>
              <Route path='/' element={
                <CalcIneligibleContextProvider>
                  <FileImportProcessContextProvider>
                    <DrawerContextProvider>
                      <ExportReportContextProvider>
                        <Layout /> 
                      </ExportReportContextProvider>
                    </DrawerContextProvider>
                  </FileImportProcessContextProvider>
                </CalcIneligibleContextProvider>
              }>
                <Route index element={<Clients />} />
                <Route path='/clients'>
                <Route index element={<Clients />} />
                </Route>
                {/* Start Clients */}
                <Route path='clients'>
                  <Route
                    index
                    element={<Clients />}
                  />
                  <Route
                    path=':borrowerId/settings'
                  >
                    <Route
                      index
                      element={<ClientSetting />}
                    />
                    <Route
                      path='exchangeRate'
                      element={<ExchangeRate/>} 
                    />
                  </Route>
                  <Route
                    path=':borrowerId/settings/:arCollateralId/customers'
                    element={<CustomerSetting />}
                  />
                  <Route
                    path=':borrowerId/settings/:arCollateralId/customers/edit'
                    element={<CustomerSetting />}
                  />
                  <Route
                    path=':borrowerId/settings/:arCollateralId/vendors/edit'
                    element={<CustomerSetting />}
                  />
                  <Route
                    path=':borrowerId/settings/ineligible-adjustments'
                    element={<IneligibleAdjustments />}
                  />
                  <Route
                    path=':borrowerId/settings/loan-balances'
                    element={<LoanBalances />}
                  />
                  <Route
                    path=':borrowerId/settings/reserves'
                    element={<Reserve />}
                  />
                  <Route
                    path=':borrowerId/settings/:invCollateralFk/inventory-worksheet'
                    element={<InventoryWorksheet path='clients' />}
                  />
                  <Route
                    path=':borrowerId/settings/:invCollateralId/ineligibles-inventory'
                    element={<InventoryIneligibles />}
                  />
                </Route>
                {/* End Clients */}
                <Route path='reports'>
                  <Route index element={<Reports />} />
                  <Route
                    path='borrowing-base'
                    element={<BorrowingBaseReport />}
                  ></Route>
                  <Route
                    path='ar-ineligible'
                    element={<ArIneligibleReport />}
                  />
                  <Route
                    path='ar-aging'
                    element={<ARAgingReport />}
                  ></Route>
                  <Route
                    path='inventory'
                    element={<InventoryWorksheet path='reports' />}
                  ></Route>
                  <Route
                    path='roll-forward'
                    element={<RollForwardReport />}
                  ></Route>
                  <Route
                    path='ar-compare'
                    element={<ARIneligibleCompare />}
                  />
                  <Route
                    path='ap-aging'
                    element={<APAgingReport />}
                  />
                </Route>
                <Route
                  path='/borrowing-base'
                  element={<BorrowingBase />}
                ></Route>
                <Route
                  path='/general-settings'
                  element={<LenderSettings />}
                ></Route>
                <Route
                  path='/audit-trail'
                  element={<AuditTrailPage />}
                ></Route>
                {/* users and roles */}
                <Route
                  path='/file-import'
                  element={
                    <FileImportPermissionProvider>
                      <FileImportProvider>
                        <FileImport/>
                      </FileImportProvider>
                    </FileImportPermissionProvider>
                  }
                >
                </Route>
                <Route path='/ineligible-settings' element={<IneligibleSettings/>}></Route>
                <Route
                  path='/upload-files'
                  element={
                    <FileImportProvider>
                      <FileUploadStepper />
                    </FileImportProvider>
                  }
                >
                </Route>
              </Route>
              <Route path='*' element={<ErrorPage />} />
            </Routes>
          {/* ) : (
            <Routes>
              <Route path='*' element={<NoToken />} />
            </Routes>
          )} */}
        </SelectedClientProvider>
      </BrowserRouter>
    );
  }
};

export default Router;
