import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, AutocompleteRenderGetTagProps, Box, Button, Chip, createFilterOptions, Grid, Paper, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { Field, FieldArrayRenderProps, FormikErrors, FormikProps, FormikTouched, getIn } from 'formik';
import { API_DOMAIN, collateralRuleOperationList, GET } from '../../../../../utility/constants';
import { Dispatch, FC, HTMLAttributes, SetStateAction, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { ICharacter, ICollateral, ICollateralRuleField, ICondition, IFormikValue, IRuleGroup, IValueList } from '../../../../../interfaces/collateralRuleInterface';
import axiosInstance from '../../../../../service/axiosInstance';
import UploadListModal from '../../../../ineligible-settings/modals/upload-list';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getCollateralRuleFieldList, getDocumentAttributesByCriteria } from '../../../../../utility/helper';
import styles from '../styles';
import dayjs from 'dayjs';
import { IDocumentAttributePayload } from '../../../../../interfaces/dataMap';
import { FileImportContext, IFileImportContext } from '../../../../../context/fileImportContext';
import { ICollateralRuleModalProps } from '..';
import { IDataMapping } from '../../../../../interfaces/fileimport';
import HelperTextComponent from '../../../../common/helper-text-component';

export interface IConditionRowProps extends ICollateralRuleModalProps {
  idx2                : number,
  idx                 : number,
  expression          : ICondition
  initialExpression   : ICondition
  expressionHelpers   : FieldArrayRenderProps,
  formik              : FormikProps<{customRules: IRuleGroup[]; defaultCollateral: ICollateral}>
  arCollateral        : ICharacter | null,
  rulesToBeDeleted    : ICondition[],
  setRulesToBeDeleted : (rulesToBeDeleted: ICondition[]) => void;
}

interface MenuDropDownForValueFieldProps extends HTMLAttributes<HTMLElement> {
  setIsUploadListModalOpen: Dispatch<SetStateAction<boolean>>;
}

const accordionTransition = {
  TransitionProps: {
    timeout: {
      exit: 0,
    }
  }
};

const singularOperators = [
  'Equals',
  'Does Not Equal',
  'Less Than',
  'Less Than or Equal to',
  'Greater Than',
  'Greater Than or Equal to'
]

const dateFields = [
  'Invoice Date',
  'Due Date'
]

/**
 * This functional component represents a dropdown menu for the value field, with an option to open an "Upload List" modal.
 * @param param0 The component's props.
 * @returns The rendered JSX element.
 */
const MenuDropdownForValueField: React.JSXElementConstructor<MenuDropDownForValueFieldProps> = ({ children, setIsUploadListModalOpen, ...other }) => (
  <Paper {...other}>
    <Button
      variant='text'
      sx={styles.uploadListButton}
      onMouseDown={event => {
        event.preventDefault()
      }}
      onClick={()=>{
        setIsUploadListModalOpen(true)
      }}
    >
      <Typography sx={styles.uploadListButtonText}>Upload List</Typography>
    </Button>
    {children}
  </Paper> 
);

/**
 * The component for the Condition Row of the Collateral Rule Modal.
 * @param props The props for the Condition Row of the Collateral Rule Modal.
 * @returns A component for the Condition Row of the Collateral Rule Modal.
 */
const ConditionRow: FC<IConditionRowProps> = (props) => {
  const {idx, idx2, formik, expression, expressionHelpers,
        initialExpression, rulesToBeDeleted,
        setRulesToBeDeleted, uploadedFile}                         = props;
  const { selectedClient }                                         = useContext(FileImportContext) as IFileImportContext;
  const [fieldNames, setFieldNames]                                = useState<ICharacter[]>([]);
  const [fieldList, setFieldList]                                  = useState<ICollateralRuleField[]>([]);
  const [valueList, setValueList]                                  = useState<IValueList[]>([]);
  const [selectedField, setSelectedField]                          = useState<ICollateralRuleField | null>(null);
  const [valueType, setValueType]                                  = useState<string>('')
  const [expanded, setExpanded]                                    = useState<string>('');
  const [isUploadListModalOpen, setIsUploadListModalOpen]          = useState<boolean>(false);
  const [uploadedValues, setUploadedValues]                        = useState<string[]>([]);
  const [resetAutocomplete, setResetAutocomplete]                  = useState<number>(0);

  /**
   * This useEffect hook is used to fetch field names when the component mounts.
   */
  useEffect(() => {
    getFieldNames();
  }, []);

  /**
   * This useEffect hook is used to fetch values when the selectedField changes.
   */
  useEffect(() => {
    selectedField && getValues();
  }, [selectedField]);

  /**
   * This useEffect hook monitors changes to the 'expression' variable.
   * If 'expression' exists and 'expression.fieldName' is not an empty string,
   * it calls the 'getFieldAndValue' function.
   */
  useEffect(() => {
    expression && expression.fieldName !== '' && getFieldAndValue();
  }, [expression])

  /**
   * This useEffect hook monitors changes to the 'uploadedValues' array.
   * When 'uploadedValues' is not empty, it sets the field value using 'formik.setFieldValue'
   * with the specified path.
   */
  useEffect(() => {
    uploadedValues.length && formik.setFieldValue(`customRules[${idx}].expression[${idx2}].value`, uploadedValues);
  }, [uploadedValues])

  /**
   * This function retrieves and formats the error message and helper text for a form field based on Formik errors and touched status.
   * @param errors The Formik errors object.
   * @param touched The Formik touched object.
   * @param name The name of the field to retrieve errors for.
   * @param index The index of the field in the customRules array.
   * @param index2 Optional. The index of the field within an expression if applicable.
   * @returns An object containing the error and helperText properties, or null if no error is found.
   */
  const getDisplayedError = (errors: FormikErrors<IFormikValue>, touched: FormikTouched<IFormikValue>, name: string, index: number, index2: number) => {

    const fieldError = getIn(errors, `customRules[${index}].expression.[${index2}].${name}`);
    const isFieldTouched = getIn(touched, `customRules[${index}].expression.[${index2}].${name}`);

    if (fieldError && isFieldTouched) { return { error: true, helperText: <HelperTextComponent text={fieldError} /> }}
    
    return null
  };

  /**
   * This function fetches and sets field names and related data based on specific criteria.
   */
  const getFieldNames = async () => {
    try {
      const docTypeId = uploadedFile.documentType === 'AR Aging' ? 1 : 2;
      const [collateralRuleFieldResponse, userDefinedFieldResponse] = await Promise.all([
        getCollateralRuleFieldList(uploadedFile.recordId ?? 0, docTypeId),
        getDocumentAttributesByCriteria(selectedClient?.recordId ?? 0, docTypeId)
      ]);

      const responseData = [
        ...collateralRuleFieldResponse.data, 
        ...userDefinedFieldResponse.data.map(parseDocumentAttribute),
      ];
      const fieldList = responseData.filter((field : ICollateralRuleField) => field.description !== undefined);
      const fieldNames = responseData.map((fieldName : ICollateralRuleField) => {
        return { label: fieldName.description, id: fieldName.recordId}
      });
      const filteredFieldNames = fieldNames.filter((fieldName : ICharacter) => fieldName.label !== undefined);
      setFieldList(fieldList);
      setFieldNames(filteredFieldNames);
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * This function parses a user-defined field into a standardized object format.
   * @param userDefinedField The user-defined field to parse.
   * @returns The parsed field object.
   */
  const parseDocumentAttribute = (userDefinedField: IDocumentAttributePayload) => {
    return {
      recordId: userDefinedField.documentAttributeId,
      documentTypeFk: userDefinedField.documentTypeId,
      tableField: userDefinedField.fieldName,
      description: userDefinedField.fieldName,
      fieldType: userDefinedField.fieldType,
    };
  }

  /**
   * Fetches data mapping information based on a document ID.
   * @returns The data mapping information or null if not found.
   */
  const getDataMapping = async () => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/dataMapping/search/findByDocumentId`,
        method: GET,
        params: {
          documentId: uploadedFile.recordId
        }
      })
      return response.data.content[0]
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Retrieves and processes values associated with a selected field from a data mapping.
   */
  const getValues = async () => {
    let cleanedValues : any[] = []
    const dataMapping : IDataMapping = await getDataMapping();
    const fieldIndex = dataMapping?.tableFields.findIndex(field => field === selectedField?.tableField)
    uploadedFile.values && (cleanedValues = uploadedFile.values.map((value : any[], index) => {
      if (index != 0) return value[fieldIndex];
    }));
    const uniqueValues = [...new Set<string>(cleanedValues)]
      .slice(1)
      .map((value : string)=> {return {label: value, type: 'Value'}});

    setValueList(uniqueValues);
  };

  /**
   * This function performs tasks related to field and value selection based on various criteria.
   */
  const getFieldAndValue = async () => {
    try {
      const docTypeId = uploadedFile.documentType === 'AR Aging' ? 1 : 2;
      const [collateralRuleFieldResponse, userDefinedFieldResponse] = await Promise.all([
        getCollateralRuleFieldList(uploadedFile.recordId ?? 0, docTypeId),
        getDocumentAttributesByCriteria(selectedClient?.recordId ?? 0, docTypeId)
      ]);

      const responseData = [
        ...collateralRuleFieldResponse.data, 
        ...userDefinedFieldResponse.data.map(parseDocumentAttribute),
      ];
      const fieldList = responseData.filter((field : ICollateralRuleField) => field.description !== undefined);
      const fieldNames = responseData.map((fieldName : ICollateralRuleField) => {
        return { label: fieldName.description, id: fieldName.recordId}
      });
      const filteredFieldNames = fieldNames.filter((fieldName : ICharacter) => fieldName.label !== undefined);
      setFieldList(fieldList);
      setFieldNames(filteredFieldNames);

      const expressionField = fieldList.filter(field => field.description === expression.fieldName);

      const dataMapping : IDataMapping = await getDataMapping();
      const fieldIndex = dataMapping.tableFields.findIndex(field => field === expressionField[0].tableField)
      let values : any[] = [];
      uploadedFile.values && (values = uploadedFile.values.map((value : any[], index) => {
        if (index != 0) return value[fieldIndex];
      }))

      const uniqueValues = ([...new Set(values)] as string[]).slice(1)
      const formattedValues = uniqueValues.map((value : string) => {
        return { label: value, type: 'Value' }
      });

      const filteredFields = fieldList.filter((value : ICollateralRuleField) => value.description !== 'Credit Limit' && value.description !== expressionField[0].description);
      const fields = filteredFields.map(field => {
        return {
          label: field.description as string,
          type: 'Field'
        }
      })
      setValueList([...formattedValues, ...fields]);

    } catch (e) {
      console.log(e)
    }
  }

  /**
   * This fucntion groups field values by their type.
   * @param option An option object with a 'type' property.
   * @returns The type of the field value.
   */
  const groupFieldValuesByType = (option: IValueList) => option.type;

  /**
   * This function handles opening and closing of panels.
   * @param panel The panel to open or close.
   * @returns A function to handle panel state changes.
   */
  const handleOpen = (panel: string) => (_event: object, isExpanded: boolean) => setExpanded(isExpanded ? panel : '');

  /**
   * This function retrieves the label and id of a field based on its description.
   * @param fieldName The description of the field.
   * @returns An object containing the label and id of the field.
   */
  const getFieldNameValue = (fieldName : string) => {
    const selectedField = fieldList.filter(field => field.description === fieldName); 
    return {
      label: fieldName,
      id: selectedField.length > 0 ? selectedField[0].recordId : -1
    }
  }

  /**
   * This function retrieves the operation object based on the operator label.
   * @param operator The operator label.
   * @returns The operation object or null if the operator label is empty.
   */
  const getOperationValue = (operator : string) => {
    if (operator === '') return null;
    const operation = collateralRuleOperationList.filter(operation => operation.label === operator); 
    return operation[0];
  }

  /**
   * This function returns a callback function to set the `isUploadListModalOpen` state.
   * @param newValue The new value to set for `isUploadListModalOpen`.
   * @returns A callback function to update the state. 
   */
  const setUploadListOpenCallback = (newValue: boolean) => {
    return () => {
      setIsUploadListModalOpen(newValue);
    };
  };

  /**
   * This function creates a filter options function for a specific type, `IValueList`.
   */
  const filter = createFilterOptions<IValueList>();

  /**
   * This function maps an item to a string value.
   * @param item The item to be mapped, which can be either a string or an object of type `IValueList`.
   * @returns The mapped string value.
   */
  const mapValues = (item : string | IValueList) => {
    if (typeof item !== 'string') {
      if (item.type === 'none') {
        const matched = RegExp(/"([^']+)"/).exec(item.label);

        if (matched) return matched[1];
        else return '';
      } else {
        return item.label;
      }
    } else {
      return item;
    }
  }

  /**
   * This function formats a date string.
   * @param value The date string to be formatted.
   * @returns The formatted date string in 'YYYY-MM-DD' format.
   */
  const formatDate = (value : string) => {
    return dayjs(value).format('YYYY-MM-DD')
  }

  /**
   * This function checks if the provided value is a valid date, and if so, it formats it in 'YYYY-MM-DD' format.
   * @param value The value to check and format.
   * @returns The formatted date string in 'YYYY-MM-DD' format if the value is a valid date; otherwise, returns the original value.
   */
  const getDateValue = (value : string) => {
    if (value !== undefined) {
      if (!dayjs(expression.value[0]).isValid()) {
        return value;
      } else {
        return formatDate(value);
      }
    } else {
      return null;
    }
  }

  /**
   * This function checks if a field is selected in the form.
   * @param field The field to check for selection.
   * @returns True if the field is selected; otherwise, false.
   */
  const isSelected = (field: string) => {
    return formik.values.customRules[idx].expression[idx2].value?.find(value => value === field)
  }

  /**
   * This function determines which dropdown component to render based on the operator and field type.
   * @returns The appropriate dropdown component.
   */
  const getValueField = () => {
    const isSingularOperator = singularOperators.includes(formik.values.customRules[idx].expression[idx2].operator);
    const isDateField = dateFields.includes(formik.values.customRules[idx].expression[idx2].fieldName);

    if (isSingularOperator && isDateField) {
      return getDateDropdown();
    } else if (isSingularOperator) {
      return getCommonDropdown();
    } else {
      return getMultipleDropdown();
    }
  }

  /**
   * This function generates the date dropdown component.
   * @returns The date dropdown component.
   */
  const getDateDropdown = () => {
    return (
      <Autocomplete
        ListboxProps={{ style: { maxHeight: 200 } }}
        id={`customRules[${idx}].expression[${idx2}].value`}
        value={getDateValue(formik.values.customRules[idx].expression[idx2].value[0])}
        groupBy={groupFieldValuesByType}
        options={valueList}
        noOptionsText={'No Values available'}
        freeSolo
        onChange={handleSingularDropdownChange}
        key={resetAutocomplete}
        filterOptions={(options, params) => {
          let filtered = filter(options, params);
  
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.label);
          (inputValue !== '' && !isExisting) && (filtered = [({field: inputValue, type: 'none', label: `Add "${inputValue}"`}), ...filtered])
  
          return filtered;
        }}
        renderInput={params => (
          <TextField
            {...params}
            {...getDisplayedError(formik.errors, formik.touched, 'value', idx, idx2)}
            inputProps={{ ...params.inputProps, 'aria-label': 'Value Field', 'data-testid': `value-field-${idx}${idx2}` }}
            placeholder={expression.value.length === 0 ? 'Value' : undefined}
            type={(dayjs(expression.value[0]).isValid()) ? 'date' : 'text'}
            variant='outlined'
            multiline={false}
            sx={{ ...styles.autocompleteValueField, ...(formik.values.customRules[idx].expression[idx2].value[0] === undefined && styles.datePicker) }}
          />
        )}
        renderGroup={(params) => {
            const { key, children, group } = params;
            if(group === 'none'){
              return(
                <Box
                  key={key}
                >
                  {children}
                </Box>
              )
            }
            return (
              (valueType === 'Value' || valueType === '') &&
              <Accordion
                key={key}
                expanded={expanded === group}
                onChange={handleOpen(group)}
                elevation={0}
                {...accordionTransition}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>{group}</AccordionSummary>
                <AccordionDetails sx={styles.accordionDetailsPadding}> {children}</AccordionDetails>
              </Accordion>
            );
          }}
        renderOption={(prop, option) => {
          return (
            <Box component='li' {...prop}>
              {option.label}
            </Box>
          );
        }}
        onBlur={(_event) => setExpanded('')}
      />
    )
  }

  /**
   * This function generates the common dropdown component.
   * @returns The common dropdown component.
   */
  const getCommonDropdown = () => {
    return (
      <Autocomplete
        ListboxProps={{ style: { maxHeight: 200 } }}
        id={`customRules[${idx}].expression[${idx2}].value`}
        value={formik.values.customRules[idx].expression[idx2].value[0] ?? ''}
        groupBy={groupFieldValuesByType}
        options={valueList}
        noOptionsText={'No Values available'}
        freeSolo
        onChange={handleSingularDropdownChange}
        key={resetAutocomplete}
        filterOptions={(options, params) => {
          let filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.label);

          (inputValue !== '' && !isExisting) && (filtered = [({field: inputValue, type: 'none', label: `Add"${inputValue}"`}), ...filtered])
      
          return filtered;
        }}
        renderInput={params => (
          <TextField
            {...params}
            {...getDisplayedError(formik.errors, formik.touched, 'value', idx, idx2)}
            inputProps={{ ...params.inputProps, 'aria-label': 'Value Field', 'data-testid': `value-field-${idx}${idx2}` }}
            name={`customRules[${idx}].expression[${idx2}].value`}
            placeholder={expression.value.length === 0 ? 'Value' : undefined}
            variant='outlined'
            multiline={false}
            onBlur={(event) => handleSingularDropdownChange(event,event.target.value)}
            sx={{ ...styles.autocompleteValueField }}
          />
        )}
        renderGroup={(params) => {
            const { key, children, group } = params;
            if(group === 'none'){
              return(
                <Box
                  key={key}
                >
                  {children}
                </Box>
              )
            }
            return (
              (valueType === 'Value' || valueType === '') &&
              <Accordion
                key={key}
                expanded={expanded === group}
                onChange={handleOpen(group)}
                elevation={0}
                {...accordionTransition}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>{group}</AccordionSummary>
                <AccordionDetails sx={styles.accordionDetailsPadding}>{children}</AccordionDetails>
              </Accordion>
            );
          }}
          renderOption={(prop, option: IValueList) => {
            return(
            <Box component='li' {...prop} key={option.field} value={option.field}>
              {option.label ?? option.field}
            </Box>
          )}}
        onBlur={(_event) => setExpanded('')}
      />
    )
  }

  /**
   * This function handles the change event for the singular dropdown component.
   * @param _e The event object.
   * @param value The selected value from the dropdown.
   */
  const handleSingularDropdownChange = (_e: SyntheticEvent<Element, Event>, value: string | IValueList | null) => {
    if (value !== null && value !== '') {
      const cleanedValue = mapValues(value);
      typeof value !== 'string' && setValueType(value.type);
      formik.setFieldValue(`customRules[${idx}].expression[${idx2}].value`, [cleanedValue]);
    } else {
      setValueType('');
      formik.setFieldValue(`customRules[${idx}].expression[${idx2}].value`, []);
    }
  }

  /**
   * This function returns a multiple selection dropdown component.
   */
  const getMultipleDropdown = () => {
    return (
      <Autocomplete
        data-testid='multiple-values-autocomplete'
        multiple
        limitTags={1}
        disableCloseOnSelect
        ChipProps={{ size: 'small', variant: 'outlined', sx: styles.chip }}
        ListboxProps={{ style: { maxHeight: 200 } }}
        id={`customRules[${idx}].expression[${idx2}].value`}
        value={formik.values.customRules[idx].expression[idx2].value}
        groupBy={groupFieldValuesByType}
        options={valueList}
        noOptionsText={'No Values available'}
        freeSolo
        key={resetAutocomplete}
        onChange={handleMultipleDropdownChange}
        filterOptions={(options, params) => {
          let filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.label);
          if (inputValue !== '' && !isExisting) {
            filtered = [({field: inputValue, type: 'none', label: `Add "${inputValue}"`}), ...filtered]
          }

          return filtered;
        }}
        renderInput={params => (
          <TextField
            {...params}
            {...getDisplayedError(formik.errors, formik.touched, 'value', idx, idx2)}
            inputProps={{ ...params.inputProps, 'aria-label': 'Value Field', 'data-testid': `value-field-${idx}${idx2}` }}
            placeholder={expression.value.length === 0 ? 'Value' : undefined}
            variant='outlined'
            multiline={false}
            name={`customRules[${idx}].expression[${idx2}].value`}
            sx={{ ...styles.autocompleteValueField }}
          />
        )}
        renderGroup={(params) => {
            const { key, children, group } = params;
            if(group === 'none'){
              return(
                <Box
                  key={key}
                >
                  {children}
                </Box>
              )
            }
            return (
              (valueType === 'Value' || valueType === '') &&
              <Accordion
                key={key}
                expanded={expanded === group}
                onChange={handleOpen(group)}
                elevation={0}
                {...accordionTransition}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>{group}</AccordionSummary>
                <AccordionDetails sx={styles.accordionDetailsPadding}> {children}</AccordionDetails>
              </Accordion>
            );
          }}
        renderOption={(prop, option) => {
          return (
            <Box 
              component='li' {...prop}
              sx={{bgcolor: isSelected(option.label) && '#EDF1F6'}}
              onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                if (!isSelected(option.label)) { prop?.onClick?.(event); return; } // only proceeds with adding option (done by prop.onClick) if option.field is not selected
                const values = formik.values.customRules[idx].expression[idx2].value.filter(value => value !== option.label); // oterwise remove option.field in list
                formik.setFieldValue(`customRules[${idx}].expression[${idx2}].value`, values); // and update fieldValues with updated list
              }}
            >
              {option.label}
            </Box>  
          );
        }}
        renderTags={getRenderTags}
        PaperComponent={getPaperComponent()}
        onBlur={(_event) => setExpanded('')}
      />
    )
  }

  /**
   * This function returns a Paper component based on the value type.
   * If the value type is 'Value' or empty, it returns a `MenuDropdownForValueField`.
   * Otherwise, it returns undefined.
   */
  const getPaperComponent = () => {
    if (valueType === 'Value' || valueType === '') {
      return (props: HTMLAttributes<HTMLElement>) => MenuDropdownForValueField({setIsUploadListModalOpen, ...props});
    } else {
      return undefined;
    }
  };

  /**
   * This function handles the change event for a multiple dropdown.
   * It maps the selected values, sets the value type based on the first selected item, and updates the formik field value.
   * @param _e The event object
   * @param value An array of selected values
   */
  const handleMultipleDropdownChange = (_e: SyntheticEvent<Element, Event>, value: readonly (string | IValueList)[]) => {
    const mutableValue = [...value];
    const values : string[] = mutableValue.map(item => mapValues(item));
    if (mutableValue.length > 0 && typeof mutableValue[0] !== 'string') setValueType(mutableValue[0].type);
    else setValueType('');
    formik.setFieldValue(`customRules[${idx}].expression[${idx2}].value`, values);
  };

  /**
   * This function renders tags for the Autocomplete component.
   * It maps the selected values to tags, displays tooltips for long labels, and applies styles.
   * @param values An array of selected values.
   * @param getTagProps Function to get tag properties.
   * @returns An array of rendered tag elements.
   */
  const getRenderTags = (values: IValueList[], getTagProps: AutocompleteRenderGetTagProps) =>
    values.map((option, index) => {
      const currentOption = typeof(option) === 'string' ? option : option.label;
      return currentOption.length >= 18 ?
        <Tooltip title={option.label ?? option}>
          <Chip
            label={option.label ?? option}
            {...getTagProps({ index })}
            size='small'
            variant='outlined'
            style={{height:"100%"}}
            sx={styles.autoCompleteChipTag}
          />
        </Tooltip>
        :
        <Chip
          label={option.label ?? option}
          {...getTagProps({ index })}
          size='small'
          variant='outlined'
          style={{height:"100%"}}
          sx={styles.autoCompleteChipTag}
        />
    })

  return (
    <Grid key={idx2} container sx={styles.expressionFieldBox}>
      <UploadListModal
        isOpen={isUploadListModalOpen}
        handleClose={setUploadListOpenCallback(false)}
        handleConfirm={setUploadListOpenCallback(true)}
        setUploadedValues={setUploadedValues}
      />
      {/* fieldName */}
      <Box width={'28%'} >
        <Autocomplete
          ListboxProps={{ style: { maxHeight: 200 } }}
          id={`customRules[${idx}].expression[${idx2}].fieldName`}
          value={getFieldNameValue(formik.values.customRules[idx].expression[idx2].fieldName)}
          blurOnSelect
          clearOnBlur
          onBlur={formik.handleBlur}
          onChange={(e, value) => {
            formik.setFieldValue(`customRules[${idx}].expression[${idx2}].fieldName`, value?.label ?? '')
            formik.setFieldValue(`customRules[${idx}].expression[${idx2}].value`, [])
            const selectedField = fieldList.filter(field => field.recordId === value?.id);
            if (selectedField.length > 0) {
              setSelectedField(selectedField[0]);
              setValueList([]);
            }
          }}
          options={fieldNames}
          noOptionsText={'No Field Names available'}
          renderOption={(prop, option) => (
            <Box component='li' {...prop} key={option.id} sx={styles.overrideConditionText}>
              {option.label}
            </Box>
          )}
          renderInput={params => (
            <TextField
              {...params}
              {...getDisplayedError(formik.errors, formik.touched, 'fieldName', idx, idx2)}
              inputProps={{ ...params.inputProps, 'aria-label': 'Field Name Dropdown', 'data-testid': `fieldName-field-${idx}${idx2}` }}
              placeholder='Field Name'
              variant='outlined'
              sx={{ ...styles.autocompleteField }}
            />
          )}
          componentsProps={{
            popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
            clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
          }}
        />
      </Box>
      {/* operator */}
      <Box width={'25%'}>
        <Autocomplete
          ListboxProps={{ style: { maxHeight: 200 } }}
          id={`customRules[${idx}].expression[${idx2}].operator`}
          value={getOperationValue(formik.values.customRules[idx].expression[idx2].operator)}
          blurOnSelect
          clearOnBlur
          onBlur={formik.handleBlur}
          onChange={(e, value) => {
            formik.setFieldValue(`customRules[${idx}].expression[${idx2}].operator`, value?.label ?? '');
            setResetAutocomplete(resetAutocomplete + 1);
            setValueType('');
            formik.setFieldValue(`customRules[${idx}].expression[${idx2}].value`, [])
          }}
          options={collateralRuleOperationList}
          noOptionsText={'No Operator available'}
          renderOption={(prop, option) => (
            <Box component='li' {...prop} key={option.id} sx={styles.overrideConditionText}>
              {option.label}
            </Box>
          )}
          renderInput={params => (
            <TextField
              {...params}
              {...getDisplayedError(formik.errors, formik.touched, 'operator', idx, idx2)}
              inputProps={{ ...params.inputProps, 'aria-label': 'Operator Dropdown', 'data-testid': `operator-field-${idx}${idx2}` }}
              placeholder='Operator'
              variant='outlined'
              sx={{ ...styles.autocompleteField }}
            />
          )}
          componentsProps={{
            popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
            clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
          }}
        />
      </Box>
      {/* value */}
      <Box width={'28%'}>
        {getValueField()}
      </Box>
      {/* button */}
      <Box width={'13.5%'}>
        {formik.values.customRules[idx].expression.length === 1 || formik.values.customRules[idx].expression.length === idx2 + 1 ?
          <Grid container sx={styles.overrideSwitchOperatorContainer}>
            <Button
              variant='contained'
              sx={styles.addButton}
              onClick={() => {
                expressionHelpers.push({...initialExpression, secondOperator: expression.secondOperator})
              }}
              data-testid={`add-row-button-${idx}`}
            >
            +Add
          </Button>
          {formik.values.customRules[idx].expression.length > 1 && (
            <HighlightOffIcon
              sx={styles.overrideRemoveIcon}
              onClick={() => {
                (formik.values.customRules[idx].expression[idx2].id !== -1) && setRulesToBeDeleted([...rulesToBeDeleted, formik.values.customRules[idx].expression[idx2]]);
                expressionHelpers.remove(idx2)
              }}
            />
          )}

        </Grid>
           :
          <Grid container sx={styles.overrideSwitchOperatorContainer}>
            <Field
              name={`customRules[${idx}].expression[${idx2}].secondOperator`}
              component={Switch}
              checked={formik.values.customRules[idx].expression[idx2].secondOperator ?? true}
              value={''}
             
              inputProps={{'data-testid' : `second-operator-${idx}${idx2}`}}
              onChange={() => {
                formik.values.customRules[idx].expression.forEach((_exp, index) => {
                  index < (formik.values.customRules[idx].expression.length) && formik.setFieldValue(`customRules[${idx}].expression[${index}].secondOperator`, !expression.secondOperator)
                })
              }}
              sx={styles.customSwitch}
            />
            <HighlightOffIcon
              sx={styles.overrideRemoveIcon}
              onClick={() => {
                (formik.values.customRules[idx].expression[idx2].id !== -1) && setRulesToBeDeleted([...rulesToBeDeleted, formik.values.customRules[idx].expression[idx2]]);
                expressionHelpers.remove(idx2)
              }}
            />
          </Grid>
        }
      </Box>
    </Grid>
  )
}

export default ConditionRow