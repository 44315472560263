const styles = {
  boxColor: {
    backgroundColor: '#F2F7F8',
  },
  subtitle: {
    marginTop: '19px',
    fontWeight: 'bold',
    color: '#154A8A',
    fontSize: '16px',
  },
  title: {
    fontWeight: 'bold',
    color: '#010953',
    fontSize: '18px',
    ml: '24px',
  },
  titleContainer: {
    backgroundColor: '#FEFEFE',
    py: 2.5
  },
};

export default styles;
