import colors from "../../../../../utility/colors";

const styles = {
  topContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    px: '6px', // space offset for tableHeadRow's boxShadow
    marginBottom: '10px',
    borderBottom: '1px solid #E5E2E2',
  },

  tabContainer: {
    marginRight: 'auto'
  },

  tabs: {
    marginRight: 'auto',
    '.MuiButtonBase-root.MuiTab-root': {
      textTransform: 'none',
    },
  },

  autocompleteHeight: {
    maxHeight: 200,
  },

  autocompleteField: {
    height: '30px',
    width: '202.95px',
    fontSize: '14px',
    '&&&& .MuiAutocomplete-input': {
      padding: '0px 0px 5px 4px'
    },
    '&&&& .MuiAutocomplete-input::placeholder': {
      color: '#707070',
      opacity: '100%'
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      backgroundColor: 'white',
      height: '30px',
      margin: '0px'
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center',
      position: 'absolute',
      top: '-4px',
      left: '-4px',
    },
    '& .MuiInputLabel-root.Mui-focused': { 
      color: '#707070',
    },
    '&&&& .MuiAutocomplete-endAdornment': {
      right: '7px'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: 0,
      lineHeight: '14px',
      paddingTop: '2px',
    },
  },

  button: {
    textTransform: 'capitalize',
    paddingX: '2rem',
    height: '31px',
    marginBottom: '17.5px',
    marginLeft: '10px',
    boxShadow: 'none',
  },

  navigationButtons: {
    backgroundColor: '#CFDAE7',
    color: '#010953',
    textTransform: 'capitalize',
    ':hover': {
      backgroundColor: '#0075AD',
      color: '#ffffff',
    }
  },

  skeletonLoaderForTopContainer: {
    height: 35,
    marginBottom: '20px',
  },

  tableContainer: {
    '&.MuiTableContainer-root': {
      px: '6px', // space offset for tableHeadRow's boxShadow
      width: 'auto',
    },
  },

  table: {
    '&.MuiTable-root': {
      minWidth: '1046px',
    },
  },

  tableHeadRow: {
    '&.MuiTableRow-root': {
      backgroundColor: '#F7F7F7',
      boxShadow: '0px 3px 6px #00000029',
    },
  },

  tableHeadCell: {
    '&.MuiTableCell-head': {
      fontWeight: 'bold',
      px: '1rem',
      py: '1rem',
    },
  },

  tableBody: {
    '&.MuiTableBody-root::before': {
      display: 'block',
      content: '""',
      height: '8px',
    },
  },

  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F7F7F7',
    },

    '&:nth-of-type(odd)': {
      backgroundColor: '#FEFEFE',
    },

    ':hover': {
      cursor: 'pointer',
    },

    ':hover .MuiTableCell-root:not(.emptyCell):not(.collapsibleRow)': {
      color: '#0075AD',
      textDecoration: 'underline',
    },
  },

  tableCell: {
    height: '48px',
    border: 'none',
    paddingY: '0',
  },

  modal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '730px',
    overflow: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '0.3rem',
    boxShadow: 24,
    p: '1.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '1070px',
  },

  modalHeaderText: {
    color: '#154A8A',
    fontWeight: 'bold',
    marginRight: 'auto',
  },

  actionIconButton: {
    '&.MuiButton-root': {
      padding: 2,
    }
  },

  viewActionIconButton: {
    marginRight: '10px',
    padding: '5px',
  },

  actionIcon: {
    fontSize: 25,
    color: '#154A8A',
    padding: '0',
    ':hover': {
      cursor: 'pointer',
    },
  },

  disabledIcon: {
    color: colors.DISABLED
  },

  disabledElement: {
    color: colors.DISABLED,
    padding: 0,
  },

  actionIconButtonText: {
    color: '#010953',
    fontSize: '14px',
    fontWeight: 'normal',
    marginLeft: '5px'
  },

  divider: {
    border: '0.5px solid #154A8A',
    marginY: 0.5,
    marginX: 0.5,
  },

  disabledDivider: {
    borderColor: '#A6A8AA',
    borderLeftWidth: '1px',
    marginY: 0.5,
    marginX: 0.5,
  },

  modalFieldsContainer:{
    border: '1px solid #EBEBEB'
  },

  modalFieldsContainerExpanded: {
    border: '1px solid #EBEBEB',
    paddingBottom: '2rem'
  },

  modalGridContainer: {
    alignItems: 'center',
    justifyContent: 'space-around',
  },

  formLabel: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    marginTop: '0.5rem',
    color: '#000',
  },

  asterisk:{
    color: '#AE2633'
  },

  modalField: {
    width: '202.95px',
    height: '30px',
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      width: '202.95px',
      height: '30px',
      backgroundColor: 'white',
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
    },
    '& .Mui-error': {
      fontSize: '14px',
    },
    '& .MuiFormHelperText-root':{
      fontSize: '11px',
      marginTop: '1px',
      marginX: '0'
    }
  },

  autoCompleteField: {
    width: '202.95px',
    height: '30px',
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      padding: 0,
      width: '202.95px',
      height: '30px',
      backgroundColor: 'white',
      fontSize: '14px',
      '& .MuiAutocomplete-input': {
        padding: '0px 10px'
      }
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
    },
    '& .Mui-error': {
      fontSize: '14px',
    },
    '& .MuiFormHelperText-root':{
      fontSize: '11px',
      marginTop: '1px',
      marginX: '0'
    }
  },

  bucketField: {
    width: '8.5rem',
    height: '1.875rem',
    fontSize: '0.875rem',
    '& .MuiOutlinedInput-root': {
      width: '8.5rem',
      height: '1.875rem',
      backgroundColor: 'white',
      fontSize: '0.875rem',
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '0.625rem',
      paddingY: '0.3125rem',
    },
    '& .Mui-error': {
      fontSize: '0.875rem',
    },
    '& .MuiFormHelperText-root':{
      fontSize: '0.6875rem',
      marginTop: '0.0625rem',
      marginX: '0'
    },
  },

  modalFieldSelect: {
    width: 'fit-content',
    '@media (max-width: 1535px)': {
      fontSize: '13px',
    },
    '@media (max-width: 1400px)': {
      fontSize: '11px',
    }
  },

  modalFieldSelectForLimit: {
    '& .MuiOutlinedInput-input': {
      width: '161.5px',
      paddingY: '0',
      textAlign: 'left',
    },
  },

  modalFieldSelectForAmount: {
    ml: '6.07px',
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
      width: '90px'
    },
  },

  modalFieldSelectForEmptyLimit: {
    '& .MuiOutlinedInput-input': {
      width: '161.5px',
      paddingY: '0',
      textAlign: 'left',
      color: '#707070'
    },
  },

  modalFieldSelectForEmptyAmount: {
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
      color: '#707070 ',
      width: '90px'
    },
  },

  modalFieldMenuItem: {
    fontSize: '14px',
  },

  modalBottomButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem'
  },

  cancelButton: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    width: '82px',
  },

  saveOrBackButton: {
    height: '31px',
    textTransform: 'capitalize',
    width: '82px',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },

  centerAlignedText: {
    textAlign: 'center',
  },

  rightAlignedText: {
    textAlign: 'right',
  },

  hidden: {
    display: 'none',
  },

  marginTop: {
    marginTop: '3px',
  },

  marginBottom: {
    marginBottom: '20px',
  },

  hideButton: {
    display: 'none'
  },

  invisible: {
    visibility: 'hidden',
  },

  sectionTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },

  sectionTitleContainer: {
    marginTop: '1rem',
    marginBottom: '0.5rem',
  },

  bucketFieldsContainer: {
    padding: '1rem',
    display: 'flex',
    width: '100%'
  },

  bucketPopper: {
    zIndex: 999,
    width: '8.5rem',
  },

  bucketPaper: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4rem',
      height: '0.4rem',
    },
    '::-webkit-scrollbar-track': {
      borderRadius: '0.4rem',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '0.4rem',
      background: '#939393',
    },
  },
  
  bucketMenuItems: {
    fontSize: '0.875rem',
  },

  dropdown: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      fontSize: '0.875rem',
    },
  },
  
  dropdownList: {
    fontSize: '0.875rem',
    width: '100%',
  },

  fieldAndIconContainer: {
    position: 'relative',
    width: '5.5rem',
  },

  expandIcon: {
    position: 'absolute',
    fontSize: '1rem',
    left: '75%',
    top: '25%'
  },

  removeCircleIconButton: {
    padding: '0.1rem',
    marginBottom: '0.1rem'
  },

  removeCircleIcon: {
    fontSize: '1.3rem',
    color: colors.PRIMARY_LIGHT,
  },

  bucketNumber: {
    fontSize: '0.875rem',
    color: colors.PRIMARY,
    fontWeight: 'bold'
  },

  bucketContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  addButton: {
    marginTop: '1.25rem',
    width: '2rem'
  },

  InputProps: {

  },

  inputProps: {
    '&::placeholder': {
      opacity: 1
    }, 
  }
};

export default styles;
