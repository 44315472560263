import { Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { Box, Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { ISortParams, IToasterProps, IHeadCell, ActionModalProps, ActionPanelProps } from '..';
import styles from './styles';
import MainRow from '../main-row';
import { IARCustomer, IARVendor } from '../../../interfaces';
import { IOption } from '../../../interfaces/comboBox';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { CustomerSettingsContext, ICustomerSettingsContext } from '../../../context/customerSettingsContext';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import images from '../../../theme/images';

export interface IMainTableProps extends IToasterProps {
  type                   : 'customer' | 'vendor';
  archive                : boolean;
  selectedARCollateral   : IOption | null;
  sortParams             : ISortParams;
  setSortParams          : Dispatch<SetStateAction<ISortParams>>;
  setActionModal         : Dispatch<SetStateAction<ActionModalProps>>;
  actionPanel            : ActionPanelProps;
  setActionPanel         : Dispatch<SetStateAction<ActionPanelProps>>;
  pageNo                 : number;
  dataList               : Array<IARCustomer | IARVendor>;
  setDataList            : Dispatch<Array<IARCustomer | IARVendor>>;
  fullList              ?: Array<IARCustomer | IARVendor>;
  fetchCustomers         : () => void;
  headers                : IHeadCell[];
  handleSelectAll        : () => void;
  selectAll              : boolean;
  setSelectAll           : Dispatch<boolean>;
}

/**
 * This component renders a table that display all of the ar customers.`
 * 
 * @param props ICustSettingsTableProps
 * @returns A table that display all of the ar customers.
 */
const MainTable: FC<IMainTableProps> = (props) => {
  const { 
    headers, 
    dataList,
    setDataList, 
    pageNo,
    sortParams, 
    setSortParams, 
    type, 
    archive,
    actionPanel,
    setActionPanel,
    handleSelectAll,
    selectAll,
    setSelectAll,
  }                                         = props;
  const { 
    canViewCustomerInformation,
  }                                         = useContext(CustomerSettingsContext) as ICustomerSettingsContext;
  const { selectedClient }                  = useContext(SelectedClientContext);
  const [displayPage, setDisplayPage]       = useState<Array<IARCustomer | IARVendor>>([]);
  
  const tableLabel = `${type === 'customer' ? 'Customer' : 'Vendor'}`;

  useEffect(() => {
    const newPage = [
      ...dataList.slice(pageNo * 10, (pageNo * 10) + 10)
    ]
    setDisplayPage(newPage);
  }, [dataList, pageNo]);

  /**
   * This useCallback return an Icon for the TableSortLabel component.
   */
  const handleIcon     = useCallback(() => <UnfoldMoreIcon sx={styles.iconDefaultSort} aria-label='Sort option icon'/>, []);

  /**
   * This function handle the sorting of column base on their header.
   * 
   * @param headerId The ID of the specified column header.
   */
  const handleSort = (headerId: string) => {
    if(sortParams.sortBy === headerId){
      setSortParams({
        sortBy: headerId,
        direction: sortParams.direction === 'DESC' ? 'ASC' : 'DESC'
      })
    }else{
      setSortParams({sortBy: headerId, direction: 'ASC'})
    }

  };

  /**
   * This function used to changed the icon of the sorted column.
   * 
   * @param headerId The ID of the specified column header.
   * @returns A sort Icon
   */
  const getIconComponent = (headerId: string) => {
    if (sortParams.sortBy === headerId) {
      return;
    } else {
      return { IconComponent: handleIcon };
    }
  };

  /**
   * This funtion dynamically sets the aria label for the sort Icon.
   * 
   * @param headerId This function used to changed the icon of the sorted column.
   * @returns A string aria label for the specified headerId.
   */
 
  return (
    <Grid container>
    {dataList && dataList.length > 0 ?
      <>
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table aria-label='customized table' size='small'>
          <TableHead>
            <TableRow sx={styles.tableHeadRow}> 
            <TableCell sx={styles.tableHead} />
            <TableCell sx={styles.tableHeadSelectTag}>
              <Checkbox 
                checked={selectAll}
                data-testid={`select-all-checkbox`}
                onClick={() => {
                  if (!selectAll) {
                    handleSelectAll();
                  } else {
                    setDataList(dataList.map((data) => ({
                      ...data,
                      selected: false,
                    })));
                    setActionPanel({
                      ...actionPanel,
                      open: false,
                    });
                  }
                  setSelectAll(!selectAll);
                }} 
              />
            </TableCell>
            {headers.map(header => 
               (<TableCell width={selectedClient?.parentClient ? '15%' : '25%'} key={header.id} sx={styles.tableHead}>  
                  <TableSortLabel
                      active={sortParams.sortBy === header.id}
                      direction={sortParams.direction === 'DESC' ? 'desc' : 'asc'}
                      onClick={() => handleSort(header.id)}
                      aria-label={`${header.name} sort`}
                      {...getIconComponent(header.id)}
                    >
                      {header.name}
                    </TableSortLabel>
                </TableCell>)
            )}
              <TableCell width={'10%'} sx={styles.tableHead}>
                {!canViewCustomerInformation ? 
                  null : <Box tabIndex={0} sx={{textAlign: 'end'}}>Action</Box>
                }
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={styles.tableSpacer}></TableRow>
          </TableBody>
          <TableBody>
            {displayPage.map((data: IARCustomer | IARVendor, index) => (
              <MainRow
                key={data.recordId}
                index={index}
                dataInfo={data}
                fetchData={props.fetchCustomers}
                tableLabel={tableLabel}
                {...props}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
      </Grid>
      </>
    : <TableContainer component={Paper} sx={styles.tableContainerEmpty}>
        <Box
          component={'img'}
          sx={{
            maxHeight: '250px',
          }}
          src={images.CustomerSettingsEmpty}
        />
        <Typography tabIndex={0} align='center'>No data available</Typography>
      </TableContainer>}
    </Grid>
  );
};
export default MainTable;