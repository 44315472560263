import { Chip } from '@mui/material'
import colors from '../../../utility/colors';

interface ParentTagProps {
  expanding?: boolean;
}

const ParentTag: React.FC<ParentTagProps> = (props) => {
  const { expanding } = props;
  return (
    <Chip
      size={'small'}
      sx={{
        marginLeft: '15px',
        backgroundColor: colors.PRIMARY,
        color: colors.WHITE,
        fontSize: '12px',
        '& .MuiChip-label:after': {
            content: '"P"',
        },
        ...(expanding ? ({
          '& .MuiChip-label:hover:after': {
            content: '"Parent"',
          },
        }) : ({
        })),
      }}
    />
  )
}

export default ParentTag