import { ChangeEvent, Dispatch, FC, HTMLAttributes, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, Button, Checkbox, CircularProgress, Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import styles from "./styles";
import { excludeRows, getDefaultMapping, getFileValuesByDocument, getTableRowsAfterPaging, getUniqueKeys } from "../../../../../../../../utility/helper";
import { GET, PROMPT } from "../../../../../../../../utility/constants";
import { IUploadedFile, ICalculationMapping, IDocumentMappings } from "../../../../../../../../interfaces/fileimport";
import ConfirmModal from "../../../../../../../../components/modals/confirm-modal";
import axiosInstance from "../../../../../../../../service/axiosInstance";
import { fileImportAPI } from "../../../../../../../../service/api";
import { FileImportContext, IFileImportContext } from "../../../../../../../../context/fileImportContext";
import { IMappingModalProps } from "..";
import { getLabelDisplayedRows, getLabelRowsPerPage } from "../../../../../../../../components/client-settings/tabs/tab-panel-contents/pagination";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

export interface IExcludeRowsProps extends IMappingModalProps {
  setActiveStep           : Dispatch<SetStateAction<number>>;
  setIsDirty              : Dispatch<SetStateAction<boolean>>;
  selectedSheet           : string | null;
  setSelectedSheet        : Dispatch<SetStateAction<string | null>>;
  excludedRowsEndRowNum   : number | undefined;
  setExcludedRowsEndRowNum: Dispatch<SetStateAction<number | undefined>>;
  setIsAutoMapped         : Dispatch<SetStateAction<boolean>>;
}

/**
 * Custom Paper component for rendering a dropdown list of sheet names.
 * @param props The props for the PaperComponent.
 * @returns The rendered Paper component.
 */
const PaperComponent = (props: HTMLAttributes<HTMLElement>) => (<Paper sx={styles.sheetNameDropdownList}>{props.children}</Paper>);

/**
 * Component for the Exclude Rows Step of the Mapping Modal.
 * @param props The props for the Exclude Rows Step of the Mapping Modal.
 * @returns A component for the Exclude Rows Step of the Mapping Modal.
 */
const ExcludeRows: FC<IExcludeRowsProps> = (props) => {
  const {
    handleClose, showToaster, setIsDirty,
    uploadedFile, setActiveStep,
    selectedSheet, setSelectedSheet,
    excludedRowsEndRowNum, setExcludedRowsEndRowNum,
    setSelectedFile,
    importedFiles, setImportedFiles,
    filteredRows, setFilteredRows, setIsAutoMapped,
    requiredFields
  }                                                       = props;
  const { uploadedFiles, setUploadedFiles,
          selectedClient }                                = useContext(FileImportContext) as IFileImportContext;
  const [sheetInput, setSheetInput]                       = useState<string>('');
  const [sheetValues, setSheetValues]                     = useState<string[][]>(uploadedFile.values ?? []);
  const [isParsing, setIsParsing]                         = useState<boolean>(false);
  const [isSaving, setIsSaving]                           = useState<boolean>(false);
  const [page, setPage]                                   = useState<number>(0);
  const [rowsPerPage, setRowsPerPage]                     = useState<number>(5);
  const [showPrompt, setShowPrompt]                       = useState<boolean>(false);
  const isUltimateParent                                  = useMemo(() => Boolean(selectedClient?.parentClient), [selectedClient]);

  /**
   * A memoized value that determines whether a certain action should be disabled.
   */
  const isDisabled: boolean = useMemo(() => {
    const noSelectedSheet = Boolean(uploadedFile.isExcelFile) && !selectedSheet;
    return noSelectedSheet
  }, [uploadedFile, selectedSheet]);

  /**
   * A memoized boolean value indicating whether there is no sheet change or excluded row change in the uploaded file.
   */
  const isDirty: boolean = useMemo(() => {
    const noSheetChange = !uploadedFile.isExcelFile || selectedSheet === uploadedFile.selectedSheet;
    const hasExcluded = uploadedFile.excludedRowsEndRowNum !== undefined || excludedRowsEndRowNum !== undefined;
    const noExcludedRowChange = !hasExcluded || excludedRowsEndRowNum === uploadedFile.excludedRowsEndRowNum;
    return !(noSheetChange && noExcludedRowChange)
  }, [uploadedFile, selectedSheet, excludedRowsEndRowNum])

  /**
   * This useEffect hook updates the `isDirty` state of the whole modal based on changes in excluded row dirtiness.
   */
  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  /**
   * Callback function to handle a page change event.
   */
  const handleChangePage = useCallback((_event: unknown, newPage: number) => setPage(newPage), []);

  /**
   * A callback function for handling a change in the number of rows per page in a paginated data view.
   */
  const handleChangeRowsPerPage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  /**
   * This function handles changes in the selected sheet value.
   * @param _ Unused parameter but required for the MUI Component
   * @param newValue The new selected sheet value.
   */
  const handleOnSheetChange = async (_: any, newValue: string | null) => {
    setSelectedSheet(newValue);
    resetPages();

    try {
      setIsParsing(true);
      if (uploadedFile.selectedSheet === newValue) {
        setExcludedRowsEndRowNum(uploadedFile.excludedRowsEndRowNum);
        setSheetValues(uploadedFile.values as string[][]);
      } else if (newValue !== selectedSheet && newValue !== null) {
        const values = await getFileValuesByDocument({...uploadedFile, selectedSheet: newValue});

        if (values) {
          setExcludedRowsEndRowNum(undefined);
          setSheetValues(values);
        } else {
          setSelectedSheet(uploadedFile.selectedSheet as string);
          setExcludedRowsEndRowNum(uploadedFile.excludedRowsEndRowNum);
          setSheetValues(uploadedFile.values as string[][]);
          throw new Error();
        }
      } else {
        return
      }
    } catch (error) {
      showToaster('error', 'Error in parsing selected sheet.')
      console.log('SHEET SELECTION ERROR: ', error)
    } finally {
      setIsParsing(false);
    }
  };

  /**
   * This function resets pagination parameters to their initial values.
   */
  const resetPages = () => {
    setPage(0);
    setRowsPerPage(5);
  };

  /**
   * This function handles the change in checkbox value of the excluding of rows.
   * Rows above the checked row number will be checked too.
   * @param rowNum The row number to exclude the rows up to.
   * @returns A callback function for handling changes of checkbox values.
   */
  const handleChange = (rowNum: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setExcludedRowsEndRowNum(rowNum);
    } else {
      setExcludedRowsEndRowNum(undefined);
    }
  };

  /**
   * This function handles the process of saving the excluded rows,
   * changes in selected sheet, auto-mapping, staging the values, and pre-determining document issues.
   * @param uploadedFile The uploaded file object.
   */
  const handleNext = async (
    uploadedFiles: IUploadedFile[],
    uploadedFile: IUploadedFile,
    sheetValues: string[][],
    excludedRowsEndRowNum: number | undefined,
    isDirty: boolean,
    isUltimateParent: boolean
  ) => {
    setIsSaving(true);

    // Copy of uploadedFile
    const uploaded: IUploadedFile = {...uploadedFile};

    // Cleaning the values of the uploadedFile and excluding the rows
    uploaded.values = sheetValues;
    const values: string[][] = excludeRows(sheetValues, excludedRowsEndRowNum);

    // Getting the document mappings
    const documentMappings: IDocumentMappings = await getMapping(uploaded, values, isDirty, isUltimateParent);
    uploaded.defaultMapping = documentMappings.fieldMappings;
    uploaded.dataMappingChangeAmt2Sign = documentMappings.changeAmt2Sign;

    if (uploaded.documentType === 'GL Transaction') {
      const calculationMappings: ICalculationMapping[] | undefined = await getCalculationMapping(uploaded);
      if (!calculationMappings) {
        setIsSaving(false);
        showToaster('error', 'Error in fetching calculation mappings.');
        return;
      };
      uploaded.calculationMappings = calculationMappings;
    } else {
      uploaded.calculationMappings = [];
    }

    checkAutoMapping(uploaded);
    setIsSaving(false);
    updateUploadedFiles(uploadedFiles, importedFiles ?? [], filteredRows ?? [], uploaded);
    setActiveStep(1);
  };

  /**
   * Updates the array of uploaded files by replacing the file with the matching recordId.
   * @param uploadedFiles - The array of uploaded files to update.
   * @param uploadedFile - The uploaded file object used to update the array.
   * @returns
   */
  const updateUploadedFiles = (uploadedFiles: IUploadedFile[], importedFiles: IUploadedFile[], filteredRows: IUploadedFile[], uploadedFile: IUploadedFile) => {
    setSelectedFile && setSelectedFile(uploadedFile);
    setUploadedFiles(uploadedFiles.map(uploaded => mapUpdatedFile(uploaded, uploadedFile)));
    setFilteredRows && setFilteredRows(filteredRows.map(uploaded => mapUpdatedFile(uploaded, uploadedFile)));
    setImportedFiles && setImportedFiles(importedFiles.map(uploaded => mapUpdatedFile(uploaded, uploadedFile)));
  };

  /**
   * Checks if the uploaded file and the current item of the array has the same record id.
   * @param uploaded - The current file in the array.
   * @param uploadedFile - The uploaded file object used to update the array.
   * @returns A file whether the new or the current depending on the matched record id.
   */
  const mapUpdatedFile = (uploaded: IUploadedFile, uploadedFile: IUploadedFile) => {
    if (uploaded.recordId === uploadedFile.recordId) return uploadedFile;
    else return uploaded;
  };

  /**
   * This function asynchronously retrieves the default mapping for an uploaded file based on its values.
   * @param uploaded The uploaded file for which to retrieve the default mapping.
   * @param values The values extracted from the uploaded file.
   * @returns A promise that resolves to an array of default mapping values.
   */
  const getMapping = async (uploaded: IUploadedFile, values: string[][], isDirty: boolean, isUltimateParent: boolean) => {
    if (values.length > 0) {
      return await getDefaultMapping(uploaded, values, isDirty, isUltimateParent);
    } else {
      const final: IDocumentMappings = {
        fieldMappings: [],
        changeAmt2Sign: false
      }
      return final;
    }
  };

  /**
   * This function asynchronously retrieves calculation mappings for an uploaded file based on its document ID.
   * @param uploaded The uploaded file for which to retrieve calculation mappings.
   * @returns A promise that resolves to an array of calculation mappings or `undefined`.
   */
  const getCalculationMapping = async (uploaded: IUploadedFile) => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.calculationMapping.FIND_BY_DOCUMENT_ID,
        method: GET,
        params: { documentId: uploaded.recordId, sortBy: 'glTrxType,ASC' }
      });
      const mappings: ICalculationMapping[] = response.data.content;
      return mappings;
    } catch (error) {
      console.log('GET CALCULATION MAPPING ERROR: ', error);
      uploaded.values = undefined;
      uploaded.defaultMapping = undefined;
    }
  };

  /**
   * This function checks if there are any document issues of type 'error' and if a data mapping is not yet assigned to the document.
   * If there are no errors and no data mapping is assigned, it adds a data mapping and updates the document.
   * @param uploaded The uploaded document to check and perform auto-mapping on.
   * @returns A promise that resolves to the updated uploaded document or `undefined` if an error occurs.
   */
  const checkAutoMapping = (uploaded: IUploadedFile) => {
    const hasError: boolean | undefined = uploaded.documentIssues?.some(issue => issue.issueType === 'error');
    const filled = requiredFields.every((field: string) => uploaded.defaultMapping?.includes(field));
    if (!hasError && !uploaded.dataMappingFk && filled) {
      setIsAutoMapped(true);
    } else {
      setIsAutoMapped(false);
    }
  };

  /**
   * This function generates a table body JSX element based on the current state and data.
   * @returns The JSX element representing the table body or null if no data is available.
   */
  const getTableBody = () => {
    if (isParsing) {
      return (
        <Table size='small'>
          <TableBody>
            {[...Array(5)].map((row) => (
              <TableRow key={getUniqueKeys(row)} sx={styles.tableRow}>
                {[...Array(7)].map((cell) => (<TableCell key={getUniqueKeys(cell)} sx={styles.tableCell}><Skeleton /></TableCell>))}
              </TableRow>))}
          </TableBody>
        </Table>
      )
    } else {
      return (
        <Table stickyHeader aria-label='customized table' size='small'>
          <TableBody>
            {getTableRowsAfterPaging(page, rowsPerPage, sheetValues).map((values: string[], index: number) => {
              const originalIndex = page * rowsPerPage + index;

              return (
                <TableRow key={getUniqueKeys(values[0])} sx={styles.tableRow}>
                  <TableCell sx={{...styles.tableCell, ...styles.checkBoxCell}}>
                    <Checkbox
                      size='small'
                      checked={excludedRowsEndRowNum === undefined ? false : originalIndex <= excludedRowsEndRowNum}
                      onChange={handleChange(originalIndex)}
                      checkedIcon={<DisabledByDefaultIcon />}
                      sx={styles.excludeCheckBox}
                    />
                  </TableCell>
                  {values.map((value: string) =>
                    <TableCell tabIndex={0} key={getUniqueKeys(value)} component='th' scope='row' sx={{...styles.tableCell, ...styles.leftAlignedText}}>
                      {value.length >= 15 ? <Tooltip title={value}><Box>{`${value.slice(0, 15)}...`}</Box></Tooltip> : value}
                    </TableCell>)}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )
    }
  };
  
  return (
    <>
      {sheetValues.length ?
        <Box sx={{ width: '100%' }}>
          <Grid item xs={12} xl={12}>
            <Box sx={styles.bodyContainer}>
              <Box sx={styles.noteContainer}>
                <Typography tabIndex={0} style={{...styles.fontNote}}>
                  <b style={{...styles.note}}>Note: </b> Crossing the box will result in the exclusion of rows. Additionally, the first row of the remaining data should be the column header.
                </Typography>
                {uploadedFile.isExcelFile &&
                  <Autocomplete
                    value={selectedSheet}
                    onChange={handleOnSheetChange}
                    inputValue={sheetInput}
                    onInputChange={(_, newInputValue: string) => setSheetInput(newInputValue)}
                    id='sheet-names-dropdown'
                    options={uploadedFile.sheetNames ?? []}
                    sx={styles.sheetNameDropdown}
                    size='small'
                    PaperComponent={PaperComponent}
                    renderInput={(params) => (
                      <TextField {...params}
                        label='Sheet Name'
                        placeholder='Select Sheet Name'
                        size='small'
                      />
                    )}
                    componentsProps={{
                      popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
                      clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
                    }}
                  />}
              </Box>
              <Typography style={{...styles.excludeTitle}}>
                Exclude
              </Typography>
              <TableContainer sx={styles.valuesTableContainer}>
                {getTableBody()}
              </TableContainer>
              <Box sx={styles.tablePaginationContainer}>
                <TablePagination
                  component='div'
                  count={sheetValues.length}
                  rowsPerPageOptions={[5, 10, 25]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={getLabelDisplayedRows()}
                  labelRowsPerPage={getLabelRowsPerPage()}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} xl={12}>
            <Box sx={styles.comboBoxStyle}>
              <Grid container sx={styles.gridContainer}>
                <Box sx={styles.nextAndCancelButtons}>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      if (isDirty) setShowPrompt(true);
                      else handleClose();
                    }}
                    sx={styles.cancelButton}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid='next-button-exclude-rows'
                    variant='contained'
                    disabled={isDisabled || isSaving || isParsing}
                    onClick={async () => {
                      await handleNext(
                        uploadedFiles,
                        uploadedFile,
                        sheetValues,
                        excludedRowsEndRowNum,
                        isDirty,
                        isUltimateParent
                      )
                    }}
                    sx={styles.nextButton}
                  >
                    {isSaving ? <CircularProgress size={15} /> : 'Next'}
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Box>
        :
        <Box sx={styles.emptyContainer}>
          <Typography tabIndex={0} sx={styles.verbiage}>
            The document is empty.
          </Typography>
        </Box>}
      <ConfirmModal
        open={showPrompt}
        onClose={() => {
          setShowPrompt(false);
          handleClose();
        }}
        onConfirm={() => setShowPrompt(false)}
        onButtonClose={() => setShowPrompt(false)}
        promptChecker
        title={PROMPT.NAV_PROMPT.title}
        description={`${PROMPT.NAV_PROMPT.description}`}
        yesButtonText={PROMPT.NAV_PROMPT.keepEditing}
        noButtonText={PROMPT.NAV_PROMPT.discardChanges}
        confirmOnly
      />
    </>
  )
}
export default ExcludeRows;