import { Box, CircularProgress, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import styles from "./styles";
import { CloseSharp, ExpandLess, ExpandMore } from "@mui/icons-material";
import colors from "../../../../utility/colors";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import ErrorIcon from '@mui/icons-material/Error';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IExportReport, IExportReportWithPayload } from "../../../../interfaces/exportReportInterface";
import { generateDownloadLink, getTooltip, getUniqueKeys, triggerExportReport } from "../../../../utility/helper";
import { useStompClient } from "react-stomp-hooks";
import axiosInstance from "../../../../service/axiosInstance";
import { fileImportAPI } from "../../../../service/api";
import { POST } from "../../../../utility/constants";

export interface SubscribingComponentProps {
  exports: IExportReportWithPayload[];
  setExports: Dispatch<SetStateAction<IExportReportWithPayload[]>>;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const SubscribingComponent: FC<SubscribingComponentProps> = (props) => {
  const { exports, setExports, setShow } = props;

  const [reports, setReports]            = useState<IExportReportWithPayload[]>([]);
  const [started, setStarted]            = useState<IExportReport[]>([]);
  const [expanded, setExpanded]          = useState<boolean>(true);
  const [lastMessage, setLastMessage]    = useState<IExportReport | undefined>();
  const [isSubscribed, setIsSubscribed]  = useState<boolean>(false);
  const stompClient                      = useStompClient();

  const loadingFiles = useMemo(() => {
    return reports
      .filter(report => report.status === 'loading')
      .reverse();
  }, [reports])

  useEffect(() => {
    return () => {
      setExports([]);
    }
  }, [])

  useEffect(() => {
    const existingFiles = reports.map(report => report.filename);
    const newFiles = exports.filter(report => !existingFiles.includes(report.filename));
    if (newFiles.length > 0) setReports([...reports, ...newFiles]);
  }, [reports, exports])

  useEffect(() => {
    const subscription = stompClient?.subscribe('/topic/export', (message) => {
      const body = message?.body;
      if (body) {
        const parsedBody: IExportReport = JSON.parse(body);
        setLastMessage(parsedBody);
      }
    });

    if (!isSubscribed && subscription?.id) setIsSubscribed(true);
    return () => subscription?.unsubscribe();
  }, [stompClient])

  useEffect(() => {
    if (isSubscribed) {
      const startedFiles = started.map(report => report.filename);
      const newFilesToExport = reports
        .filter(report => !startedFiles.includes(report.filename))
        .map(report => {
          report.id = getUniqueKeys(report.filename);
          return report;
        });
      if (newFilesToExport.length) {
        exportReportAsync(newFilesToExport);
        setStarted([...started, ...newFilesToExport]);
      }
    }
  }, [isSubscribed, started, reports])

  useEffect(() => {
    checkLastMessage(lastMessage, reports)
  }, [lastMessage, reports])

  const exportReportAsync = async (newFilesToExport: IExportReportWithPayload[]) => {
    for (const report of newFilesToExport) {
      const { setExporting, apiURL, payloadData, id } = report;

      await triggerExportReport(
        setExporting,
        apiURL,
        {...payloadData, id }
      )
    }
  }

  const checkLastMessage = async (lastMessage: IExportReport | undefined, reports: IExportReportWithPayload[]) => {
    if (!lastMessage) return;
    const { id, filename, status } = lastMessage;
    setLastMessage(undefined);

    const updatedExports = reports.map(report => {
      if (report.filename !== filename && report.id !== id) return report;
      report.status = status;
      return report;
    });

    setReports(updatedExports);
  }

  const hideLoader = () => {
    setShow(false);
  }

  const getStatusIcon = (item: IExportReportWithPayload) => {
    if (item.status === 'loading') {
      return (
        <CircularProgress
          variant='indeterminate'
          sx={styles.spinner}
          size={20}
          thickness={5}
        />
      );
    } else if (item.status === 'success') {
      return (
        <IconButton
          aria-label='Download icon'
          onClick={() => {
            downloadExportedFile(item)
          }}
          sx={styles.actionsIconButton}
        >
          <FileDownloadOutlinedIcon
            sx={styles.actionsIcon}
          />
        </IconButton>
      );
    } else {
      return (
        <ErrorIcon
          htmlColor={colors.ERROR}
          sx={styles.errorIcon}
        />
      );
    }
  }

  const downloadExportedFile = async (item: IExportReportWithPayload) => {
    try {
      const params = {
        id: item.id,
        borrowerId: item.payloadData.borrowerId,
        endDate: item.payloadData.endDate
      };

      await generateDownloadLink(
        fileImportAPI.uploadFile.DOWNLOAD_EXPORTED_FILE,
        params,
        undefined,
        item.filename
      )
    } catch (error) {
      console.log('DOWNLOAD ERROR: ', error);
    }
  }

  const getHeaderTitle = (loadingFiles: IExportReportWithPayload[]) => {
    if (loadingFiles.length) {
      const lastLoadingFile = loadingFiles[0];
      const urlOfLastLoading = lastLoadingFile.apiURL;
      const reportName = getReportNameUsingUrl(urlOfLastLoading);
      return `Exporting ${reportName} Report`;
    } else {
      return 'Export Completed';
    }
  }

  const getReportNameUsingUrl = (urlOfLastLoading: string) => {
    const lowered = urlOfLastLoading.toLowerCase();

    if (lowered.includes('ineligiblereport')) {
      return 'AR Ineligible';
    } else if (lowered.includes('rollforwardreport')) {
      return 'Roll Forward Report';
    } else {
      return '';
    }
  }
  
  return (
    <Box>
      <Box sx={styles.header}>
        <Box>
          <Typography tabIndex={-1} sx={styles.headerFont}>
            {getHeaderTitle(loadingFiles)}
          </Typography>
        </Box>
        <Box sx={styles.actionsContainer}>
          <IconButton
            aria-label='Expand icon'
            sx={styles.action}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {
            !expanded ?
            <ExpandMore htmlColor={colors.WHITE} /> :
            <ExpandLess htmlColor={colors.WHITE} />
            }
          </IconButton>
          <IconButton
            aria-label='Close icon'
            sx={styles.action}
            onClick={(event) => {
              event.stopPropagation();
              hideLoader();
            }}
          >
            <CloseSharp htmlColor={colors.WHITE} />
          </IconButton>
        </Box>
      </Box>
      <Box sx={styles.content}>
        <List dense={true} sx={styles.list}>
        {expanded &&
          reports.map((item) => 
            <ListItem
              key={item.filename}
              secondaryAction={getStatusIcon(item)}
            >
              <ListItemText
                tabIndex={0}
              >
                <Box
                  onMouseEnter={getTooltip}
                  sx={styles.listItemText}
                >
                  {item.filename}
                </Box>
              </ListItemText>
            </ListItem>
          ) 
          }
        </List>
      </Box>
    </Box>
  )
};

export default SubscribingComponent;