import { Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material'
import { Dispatch, FC, SetStateAction, MouseEvent, useCallback, useState, useRef, useMemo, useEffect } from 'react'
import { formatCurrency, getComparator, getFallbackString, getHeaderTooltip, stableSort } from '../../../../../../utility/helper';
import { IIneligibleDetailsProps } from '../..';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { visuallyHidden } from '@mui/utils';
import { ICapDetails, ICustIneligible, IHeader } from '../../..';
import FilterSearch from '../../../../../../components/common/filter-search';
import CustomerRow from './row';
import styles from './styles';
import { IGrandTotals } from '../../../../../../interfaces/arIneligibleReport';

export interface ICustomerViewTableProps extends IIneligibleDetailsProps {
  arCollateralId?: number;
  arCollateralTotal?: IGrandTotals[];
  reportCodes?: IHeader[];
}

export interface IEnhancedTableProps extends IIneligibleDetailsProps {
  onRequestSort         : (event: MouseEvent<unknown>, property: keyof ICustIneligible) => void;
  setSearched           : Dispatch<SetStateAction<boolean>>;
  closeSearch           : boolean;
  setCloseSearch        : Dispatch<SetStateAction<boolean>>;
  handleCustRowFiltered : (filtered: ICustIneligible[]) => void
}

/**
 * This function gets the amount to be displayed.
 * @param amount The amount to be formatted.
 * @returns A formatted amount or '-' if amount is undefined.
 */
export const getEligibleAmount = (amount: any, currencyCode?: string, exchangeRate?: number) => {
  if (amount !== undefined) return formatCurrency(amount, currencyCode, exchangeRate);
  else return '-';
}

/**
 * This function helps to generate unique keys for mapping.
 * @param id The string or num id to be used as key.
 * @returns A string key.
 */
export const getRowKey = (id?: string | number) => {
  if (typeof id === 'number') {
    return `${id}`
  } else {
    return `${getFallbackString(id)}` ;
  }
}

const getVarianceBasedOnHasNoRfv = (hasNoRfv: boolean, rfvTotal: number, currencyCode?: string, exchangeRate?: number) => {
  return hasNoRfv ? '--' : formatCurrency(rfvTotal, currencyCode, exchangeRate);
};

/**
 * Component for showing the enhanced table head
 * @param props Props for the EnhancedTableHead.
 * @returns Rendered EnhancedTableHead component.

 */
const EnhancedTableHead: FC<IEnhancedTableProps> = (props) => {
  const {
    custIneligibles,
    handleCustRowFiltered,
    setSearched,
    closeSearch,
    setCloseSearch,
    onRequestSort,
    headers,
    custViewOrder,
    setCustViewOrder,
    custViewOrderBy,
    setCustViewOrderBy
  }                                              = props;

  /**
   * This callback function returns null, representing no icon.
   */
  const handleNullIcon = useCallback(() => null, []);

  /**
   * This callback function returns the default icon for sorting.
   */
  const handleIcon     = useCallback(() => <UnfoldMoreIcon sx={styles.iconDefaultSort}/>, []);
  
  /**
   * This function handles the search feature. It filters out rows based on the provided
   * search input and a specific column id.
   */
  const onSearch = useCallback((searchInput: string, id: keyof ICustIneligible) => {
    const custRegEx = new RegExp(searchInput, 'gi');

    if (searchInput !== '') {
      const filteredList: ICustIneligible[] = custIneligibles.filter((row: ICustIneligible) => {
        const position = row[id].search(custRegEx);

        if (position !== -1) {
          return row;
        }
      })
      setSearched(true);
      handleCustRowFiltered(filteredList);
      setCloseSearch(!closeSearch);
    } else {
      setSearched(false);
      handleCustRowFiltered([]);
      setCloseSearch(!closeSearch);
    }
  }, [custIneligibles, closeSearch])

  /**
   * This function creates an event handler for sorting.
   */
  const createSortHandler = (property: keyof ICustIneligible) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  /**
   * This function determines which icon to use based on the current column and sort order.
   */
  const getIconComponent = (orderBy: keyof ICustIneligible, columnId: keyof ICustIneligible) => {
    if (columnId === 'custName' || columnId === 'custSrcId') {
      return { IconComponent: handleNullIcon };
    } else if (orderBy === columnId) {
      return;
    } else {
      return { IconComponent: handleIcon };
    }
  };

  return (
    <TableHead sx={styles.tableHeader}>
      <TableRow>
        <TableCell aria-label='Action Button Column' component='td' sx={styles.tableActionHeader}></TableCell>
        {headers.map((header: IHeader) => (
        <TableCell
          key={header.id}
          sortDirection={custViewOrderBy === header.id ? custViewOrder : false}
          sx={header.numeric ? {...styles.tableTextHeader, ...styles.tableTextHeaderNumeric} : styles.tableTextHeader}
        >
          <Tooltip title={getHeaderTooltip(custViewOrderBy, custViewOrder, header)}>
            <TableSortLabel
              active={custViewOrderBy === header.id}
              direction={custViewOrderBy === header.id ? custViewOrder : 'asc'}
              onClick={createSortHandler(header.id)}
              hideSortIcon={header.id === 'custName' ? true : undefined}
              aria-label={`${header.label} sort`}
              {...getIconComponent(custViewOrderBy, header.id)}
            >
              {header.label}
              {custViewOrderBy === header.id && 
                <Box component="span" sx={visuallyHidden}>
                  {custViewOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>}
            </TableSortLabel>
          </Tooltip>
          {(header.id === 'custName' || header.id === 'custSrcId') && 
          <FilterSearch<ICustIneligible>
            id={header.id}
            defaultOrder='asc'
            setOrder={setCustViewOrder}
            setOrderBy={setCustViewOrderBy}
            onSearch={onSearch}
          />}
        </TableCell>
        ))}
      </TableRow>
    </TableHead>
)}

const CustomerViewTable: FC<ICustomerViewTableProps> = (props) => {
  const {
    custViewOrderBy,
    setCustViewOrderBy,
    custViewOrder,
    setCustViewOrder,
    isLoading,
    custIneligibles,
    headers,
    grandTotal,
    currencyCode,
    exchangeRate,
    isUltimateParent,
    rfvIneligibleAmt,
    hasNoRfv,
    rfvDisabled,
    ineligibleCapDetails,
    arCollateralTotal,
  }                                           = props;
  const [searched, setSearched]               = useState<boolean>(false);
  const [closeSearch, setCloseSearch]         = useState<boolean>(false);
  const [hasMore, setHasMore]                 = useState<boolean>(false);
  const [page, setPage]                       = useState<number>(0);
  const [custRowFiltered, setCustRowFiltered] = useState<ICustIneligible[]>([]);
  const rowsPerPage                           = useMemo(() => 10, []);

  /**
   * This callback function handles sorting of columns.
   */
  const handleRequestSort = useCallback((event: MouseEvent<unknown>, property: keyof ICustIneligible) => {
    if (property === 'custName') {
      const isAsc = custViewOrderBy === property && custViewOrder === 'asc';
      setCustViewOrder(isAsc ? 'desc' : 'asc');
    } else {
      const isDesc = custViewOrderBy === property && custViewOrder === 'desc';
      setCustViewOrder(isDesc ? 'asc' : 'desc');
    }
    setCustViewOrderBy(property);
  }, [custViewOrderBy, custViewOrder]);

   
  // Intersection Observer for infinite scrolling/load more functionality.
  const observer                      = useRef<any>();
  const lastRowElementRef             = useCallback((node: any) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) setPage((prevValue) => prevValue + 1);
    })

    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  // Calculating total and current displayed rows for load-more logic.
  const totalLength = useMemo(() => custIneligibles.length, []);
  const currentLength = useMemo(() => custIneligibles.slice(0, page * rowsPerPage + rowsPerPage).length, [page, rowsPerPage]);
  
  /**
   * Determines if the table will show cap details.
   */
  const hasIneligibleCapDetails = useMemo(() => {
    const capDetailsValues: ICapDetails[] = Object.values(ineligibleCapDetails);
    return capDetailsValues.length > 0;
  }, [ineligibleCapDetails]);

  /**
   * The total add backs of the all current cap details.
   */
  const capTotalAddBacks = useMemo(() => {
    const allCapDetails: ICapDetails[] = Object.values(ineligibleCapDetails);
    return allCapDetails.reduce((acc: number, currentValue: ICapDetails) => {
      return acc + currentValue.capAddBack;
    }, 0);
  }, [ineligibleCapDetails])

  /**
   * This useEffect hook checks and updates if there are more rows to be loaded.
   */
  useEffect(() => {
    if (currentLength >= totalLength) setHasMore(false);
    else setHasMore(true);
  }, [page])

  /**
   * Retrieves the grand total value for a specific header from a given grand total object.
   * @param header The header object representing the column for which the grand total is retrieved.
   * @param rfvIneligibleAmt The Roll Forward Variance amount to be added to the ineligible amount.
   * @param capTotalAddBacks The total add backs for all the cap details of the given collateral.
   * @returns The grand total value for the specified header. Returns 0 if grandTotal is undefined.
   */
  const getGrandTotal = (header: IHeader, rfvIneligibleAmt: number, capTotalAddBacks: number) => {
    if (grandTotal) {      
      const headerTotal = grandTotal[header.id];
      const ineligibleAddBack = ineligibleCapDetails[header.id]?.capAddBack ?? 0;

      if (header.id === 'ineligibleAmount') {
        return headerTotal + rfvIneligibleAmt + capTotalAddBacks;
      }

      if (header.id === 'eligibleAR') {
        return headerTotal - rfvIneligibleAmt - capTotalAddBacks;
      }

      return headerTotal + ineligibleAddBack;
    } else {
      return 0;
    }
  }

  /**
   * This function handles the setting of filtered customer ineligible rows.
   * @param filtered The filtered customer ineligible rows.
   */
  const handleCustRowFiltered = (filtered: ICustIneligible[]) => {
    setCustRowFiltered(filtered);
  }

  /**
   * Generates rows for displaying ineligible cap details if applicable.
   * @param hasIneligibleCapDetails Indicates whether there are ineligible cap details to display.
   * @param capDetails Optional parameter representing cap details.
   * @returns JSX elements representing the rows of ineligible cap details, or null if there are no ineligible cap details to display.
   */
  const getIneligibleCapDetailsRows = (hasIneligibleCapDetails: boolean, capDetails?: ICapDetails) => {
    if (hasIneligibleCapDetails) {
      if (capDetails) {
        return (
          <>
            <Box sx={styles.tableRowCapDetails}>
              {getEligibleAmount(capDetails.totalIneligible, currencyCode, exchangeRate)}
            </Box>
            <Box sx={styles.tableRowCapDetails}>
              {getEligibleAmount(capDetails.capAddBack, currencyCode, exchangeRate)}
            </Box>
          </>
        )
      } else {
        return (
          <>
            <Box sx={{...styles.tableRowCapDetails, height: '1.32rem'}}></Box>
            <Box sx={{...styles.tableRowCapDetails, height: '1.32rem'}}></Box>
          </>
        )
      }
    }
  };

  const getIndependentClientTotalRow = () => {
    return (
      <TableRow>
        <TableCell tabIndex={0} sx={styles.tableActionFooter}>
        </TableCell>
        <TableCell colSpan={2} tabIndex={0} sx={{...styles.tableTextFooter, ...styles.tableTextBodyLeft}}>
          <Box sx={{...styles.tableFooterLabelContainer}}>
            {hasIneligibleCapDetails &&
            <>
              <Box sx={styles.tableRowCapDetails}>Sub Total</Box>
              <Box sx={styles.tableRowCapDetails}>Ineligible Cap Add Back</Box>
            </>}
            {!rfvDisabled && <Box sx={styles.tableRowVariance}>Roll Forward Variance</Box>}
            <Box>Total</Box>
          </Box>
        </TableCell>
        {headers.map((header: IHeader) => {
          if (header.id !== 'custName' && header.id !== 'custSrcId' && header.id !== 'ineligibleAmount') {
            const capDetails: ICapDetails | undefined = ineligibleCapDetails[header.id];

            return (
              <TableCell tabIndex={0} key={header.id} sx={{...styles.tableTextFooter, ...styles.tableTextBodyNumber}}>
                {getIneligibleCapDetailsRows(hasIneligibleCapDetails, capDetails)}
                {!rfvDisabled && <Box sx={{...styles.tableRowVariance, height: '1.32rem'}}></Box>}
                <Box>
                {getEligibleAmount(getGrandTotal(header, rfvIneligibleAmt ?? 0, capTotalAddBacks), currencyCode, exchangeRate)}
                </Box>
              </TableCell>
            )
          }

          if (header.id === 'ineligibleAmount') {
            return (
              <TableCell tabIndex={0} key={header.id} sx={{...styles.tableTextFooter, ...styles.tableTextBodyNumber}}>
                {getIneligibleCapDetailsRows(hasIneligibleCapDetails)}
                {!rfvDisabled && 
                <Box sx={styles.tableRowVariance}>
                  {getVarianceBasedOnHasNoRfv(Boolean(hasNoRfv), rfvIneligibleAmt ?? 0, currencyCode, exchangeRate)}
                </Box>}
                <Box>
                {getEligibleAmount(getGrandTotal(header, rfvIneligibleAmt ?? 0, capTotalAddBacks), currencyCode, exchangeRate)}
                </Box>
              </TableCell>
            )
          }
        })}
      </TableRow>
    )
  }

  const getUpcTotalRow = () => {
    const rfvTotalRow = arCollateralTotal?.find((item: IGrandTotals) => item.row === 'Roll Forward Variance');
    const collateralTotalRow = arCollateralTotal?.find((item: IGrandTotals) => item.row === 'Total');

    return (
      <TableRow>
        <TableCell tabIndex={0} sx={styles.tableActionFooter}>
        </TableCell>
        <TableCell colSpan={2} tabIndex={0} sx={{...styles.tableTextFooter, ...styles.tableTextBodyLeft}}>
          <Box sx={{...styles.tableFooterLabelContainer}}>
            {rfvTotalRow && <Box sx={styles.tableRowVariance}>Roll Forward Variance</Box>}
            <Box>Total</Box>
          </Box>
        </TableCell>
        {headers.map((header: IHeader) => {
          if (header.id !== 'custName' && header.id !== 'custSrcId' && header.id !== 'ineligibleAmount') {
            let amount: any = 0.0;
            if (header.id === 'eligibleAR') amount = -(collateralTotalRow?.eligibleAr as number);
            else if (header.id === 'Gross AR') amount = -collateralTotalRow?.[header.id];
            else amount = collateralTotalRow?.[header.id];

            return (
              <TableCell tabIndex={0} key={header.id} sx={{...styles.tableTextFooter, ...styles.tableTextBodyNumber}}>
                {rfvTotalRow && <Box sx={{...styles.tableRowVariance, height: '1.32rem'}}></Box>}
                <Box>
                {(amount === 0 || Boolean(amount))
                    && getEligibleAmount(amount, currencyCode)}
                </Box>
              </TableCell>
            )
          }

          if (header.id === 'ineligibleAmount') {
            return (
              <TableCell tabIndex={0} key={header.id} sx={{...styles.tableTextFooter, ...styles.tableTextBodyNumber}}>
                {rfvTotalRow && 
                <Box sx={styles.tableRowVariance}>
                  {(rfvTotalRow.ineligibleAmount === 0 || Boolean(rfvTotalRow.ineligibleAmount))
                    && getVarianceBasedOnHasNoRfv(Boolean(hasNoRfv), rfvTotalRow.ineligibleAmount, currencyCode)}
                </Box>}
                <Box>
                  {(collateralTotalRow?.ineligibleAmount === 0 || Boolean(collateralTotalRow?.ineligibleAmount))
                    && getEligibleAmount(collateralTotalRow?.ineligibleAmount, currencyCode)}
                </Box>
              </TableCell>
            )
          }
        })}
      </TableRow>
    )
  }

  return (
    <TableContainer sx={styles.tableContainer}>
      <Table stickyHeader aria-label='Ineligible Report by Customer Table' size='small'>
        <EnhancedTableHead
          onRequestSort={handleRequestSort}
          setSearched={setSearched}
          closeSearch={closeSearch}
          setCloseSearch={setCloseSearch}
          handleCustRowFiltered={handleCustRowFiltered}
          {...props}
        />
        <TableBody>
        {stableSort(searched ? custRowFiltered : custIneligibles, getComparator(custViewOrder, custViewOrderBy, 'arIneligibleCustView'))
          .slice(0, page * rowsPerPage + rowsPerPage)
          .map((row, index, array) => (
            <CustomerRow
              searched={searched}
              closeSearch={closeSearch}
              index={index}
              row={row}
              array={array}
              lastRowElementRef={lastRowElementRef}
              key={`${getRowKey(row.arCustomerId)} - ${getRowKey(row.custName)}`}
              tableRowStyle={
                index % 2 === 0 ?
                styles.tableRowBody :
                {...styles.tableRowBody, ...styles.tableRowBodyOdd}
              }
              {...props}
            />
          ))}
        </TableBody>
        {!searched &&
        <TableFooter>
        {isUltimateParent ? getUpcTotalRow() : getIndependentClientTotalRow()}
        </TableFooter>}
      </Table>
    </TableContainer>
  )
}

export default CustomerViewTable