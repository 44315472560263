import { StompSessionProvider, useStompClient } from "react-stomp-hooks"
import { WEB_SOCKET_DOMAIN } from "../../../utility/constants";
import SubscribingComponent from "./subscribing-component";
import { Card } from "@mui/material";
import styles from "./styles";
import { useContext, useMemo } from "react";
import { ExportReportContext } from "../../../context/exportReportContext";

const ExportLoader = () => {
  const { exports, setExports, setShow } = useContext(ExportReportContext);

  const stompUrl = useMemo(() => `${WEB_SOCKET_DOMAIN}/calc`, []);

  return (
    <Card variant="outlined" sx={styles.container}>
      <StompSessionProvider url={stompUrl}>
          <SubscribingComponent
            exports={exports}
            setExports={setExports}
            setShow={setShow}
          />
      </StompSessionProvider>
    </Card>
  )
}

export default ExportLoader;