import colors from '../../../../utility/colors';

const styles = {
  paperStyle: {
    display         : 'flex',
    justifyContent  : 'space-between',
    margin          : '0.5rem 0.5rem 0 0.5rem',
    backgroundColor : colors.WHITE,
  },
  textStyle: {
    m               : '1rem',
    fontSize        : '16px',
    verticalAlign   : 'center',
  },
  labelStyle: {
    my              : '1.4rem',
    mx              : '1rem',
    fontSize        : '16px',
    verticalAlign   : 'center',
    minWidth        : 'fit-content',
  },
  buttonStyle: {
    margin: '0.5rem',
  },
  calculateButton: {
    backgroundColor: '#CFDAE7',
    color: '#010953',
    textTransform: 'capitalize',
    height: '31px',
    width: '180px',
    minWidth: 'fit-content',
    margin: '0.5rem',
    padding: '1rem',
    ':hover': {
      backgroundColor: '#0075AD',
      color: '#ffffff',
    }
  },
  saveButton: {
    height: '31px',
    width: '82px',
    margin: '0.5rem',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },
  cancelButton: {
    height: '31px',
    width: '82px',
    margin: '0.5rem',
    textTransform: 'capitalize',
  },
  comboBox: {
    backgroundColor: 'white',
    margin: '1rem',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      fontSize: '14px',
      width: '278.25px',
      backgroundColor: colors.WHITE,
      '& .MuiOutlinedInput-input': {
        // width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  modalContainer: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30rem',
    bgcolor: '#fff',
    borderRadius: '0.3rem',
    boxShadow: '0 0 24px -8px rgba(0,0,0,0.75)',
    padding: '1.5rem',
  },
  rowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    mr: '1rem',
  },
  settingsTextStyle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  checkboxField: {
    marginLeft: '-6px',
  },
  checkbox: {
    padding: '4px',
  },
  formControlCheckbox: {
    fontSize: '14px',
    color: colors.PRIMARY,
  },
}

export default styles;