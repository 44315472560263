import { IconButton, InputAdornment, Menu, MenuItem, MenuList, TextField, Tooltip } from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { IARAgingReportContext, IARAgingTableHeadCell } from "../../../../../../interfaces/arAgingReportInterface";
import { useContext, useEffect, useMemo, useState } from "react";
import styles from "./styles";
import { ARAgingReportContext } from "../../../../../../context/arAgingReportContext";

export interface IARAgingSearchModalProps {
  tableHeadCell: IARAgingTableHeadCell,
  onSearch: (filterText: string) => void;
}

const sortOptions = ['Sort A → Z', 'Sort Z → A'];

const ARAgingSearchModal: React.FC<IARAgingSearchModalProps> = (props) => {
  const { tableHeadCell, onSearch } = props;
  const { sortParams, setSortParams }  = useContext(ARAgingReportContext) as IARAgingReportContext;

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [filterText, setFilterText] = useState<string>('');

  const isModalOpen = useMemo(() => Boolean(anchorElement), [anchorElement]);

  useEffect(() => {
    const isClearingUnselectedSearchModal = filterText === '' && tableHeadCell.id !== sortParams.orderBy;
    if (isClearingUnselectedSearchModal) { return; }
    onSearch(filterText);
  }, [filterText]);

  const closeModal = () => {
    setAnchorElement(null);
  };

  const isMenuItemSelected = (option: string) => {
    if (sortParams.orderBy !== tableHeadCell.id) { return false; }
    if (option === 'Sort A → Z' && sortParams.order === 'asc') { return true; }
    return option === 'Sort Z → A' && sortParams.order === 'desc';
  };

  const getEndAdornmentForSearchField = () => {
    if (filterText === '') { return; }
    return {
      endAdornment: (
        <IconButton onClick={() => setFilterText('')} sx={styles.clearIconButton}>
            <ClearIcon sx={styles.clearIcon} />
        </IconButton>
      ),
    };
  };

  return (
    <>
      <Tooltip title='Filter this column'>
        <IconButton
          aria-controls={isModalOpen ? 'basic-menu' : undefined}
          aria-expanded={isModalOpen ? 'true' : undefined}
          aria-label='Filter Icon Button'
          data-testid={`filter-button-for-${tableHeadCell.id}`}
          onClick={event => setAnchorElement(event.currentTarget)}
          sx={isModalOpen ? { ...styles.openedIcon, ...styles.filterIconButton } : styles.filterIconButton}
        >
          {sortParams.orderBy === tableHeadCell.id ? <FilterAltIcon sx={styles.selectedFilterIcon}  /> : <FilterListIcon sx={styles.filterIcon} />}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElement}
        open={isModalOpen}
        onClose={closeModal}
        MenuListProps={{ sx: { ...styles.menuContainer } }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' tabIndex={0} aria-label='Search icon'>
                <SearchIcon sx={styles.searchIcon} />
              </InputAdornment>
            ),
            sx: styles.searchFieldText,
            ...getEndAdornmentForSearchField()
          }}
          inputProps={{ 'aria-label': 'Filter Items', 'data-testid' : 'filter-textfield' }}
          onChange={event => setFilterText(event.target.value)}
          onKeyDown={event => event.stopPropagation()}
          placeholder='Search'
          size='small'
          value={filterText}
        />
        <MenuList dense sx={styles.menuList}>
          {
            sortOptions.map((option: string, index: number) =>
              <MenuItem
                disabled={isMenuItemSelected(option)}
                key={option}
                onClick={() => setSortParams({ orderBy: tableHeadCell.id, order: option === 'Sort A → Z' ? 'asc' : 'desc' })}
                sx={styles.menuItem}
                value={index}
              >
                { <> { option } { isMenuItemSelected(option) && <Check sx={styles.checkIcon} /> } </> }
              </MenuItem>
            )
          }
        </MenuList>
      </Menu>
    </>
  )
};

export default ARAgingSearchModal;
