import { Box } from "@mui/material";
import styles from "./styles";

export interface TabPanelProps {
  children?           : React.ReactNode;
  index               : number;
  selectedTabIndex    : number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, selectedTabIndex, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={selectedTabIndex !== index}
      id={`client-settings-tabpanel-${index}`}
      aria-labelledby={`client-settings-tab-${index}`}
      {...other}
    >
      {selectedTabIndex === index && <Box sx={styles.tabPanelBox}>{children}</Box>}
    </div>
  );
};

export default TabPanel;