import { Box, Chip, TableCell, TableRow, Tooltip } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { formatCurrency, formatDate, formatNumber, getARIneligibleDetailsRequest, getFallbackString, getNumber, getUpcARIneligibleDetailsRequest, getTooltip } from "../../../../../../../../utility/helper";
import { IARIneligible } from "../../../../..";
import { IARIneligibleTableProps } from "..";
import styles from "./styles";
import { IARTransaction } from "../../../../../../../../interfaces";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AMERICAN_DATE_FORMAT, customerLevelCodes } from "../../../../../../../../utility/constants";

export interface ARIneligibleTableRowProps extends IARIneligibleTableProps {
  row               : any;
  lastRow           : boolean;
  lastRowElementRef : (node: any) => void;
  triggerSort       : boolean;
}

/**
 * Component for showing the AR Ineligible Table Row
 * @param props - Props for ARIneligibleTableRow.
 * @returns - The rendered ARIneligibleTableRow component.
 */
const ARIneligibleTableRow: FC<ARIneligibleTableRowProps> = (props) => {
  const [details, setDetails] = useState<IARTransaction[]>([]);
  const [isOpen, setIsOpen]   = useState<boolean>(false);
  const [page, setPage]       = useState<number>(0);
  const rowsPerPage           = useMemo(() => 10, []);

  /**
   * This useMemo computes if there are more rows left to be fetched for infinite scrolling.
   */
  const hasMore = useMemo(() => {
    return details.slice(0, page * rowsPerPage + rowsPerPage).length < details.length;
  }, [details, page, rowsPerPage]);


  /**
   * This useMemo checks if the current row has supplementary details.
   */
  const hasDetails = useMemo(() => {
    return props.row.hasDetails === 1;
  }, [props.row.hasDetails])

  /**
   * This useEffect hook ensures the row details are closed when any sorting action is performed.
   */
  useEffect(() => {
    setIsOpen(false)
  }, [props.triggerSort])

  const observer = useRef<any>();
  const lastRowElementRef = useCallback((node: any) => {
    if (props.isLoading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      (entries[0].isIntersecting && hasMore) && setPage((prevValue) => prevValue + 1);
    })

    if (node) observer.current.observe(node);
  }, [props.isLoading, hasMore]);

  /**
   * This function fetches additional details for the row.
   */
  const getDetails = async (row: IARIneligible) => {
    try {
      const response = props.isUltimateParent ? 
        await getUpcARIneligibleDetailsRequest(row) :
        await getARIneligibleDetailsRequest(row)
      setDetails(response.data);
    } catch (error) {
      console.log('INELIGIBLE DETAILS ERROR: ', error);
      setDetails([]);
    } finally {
      setIsOpen(true);
    }
  }

  /**
   * This function manages row clicks. It fetches and displays details if the row is closed, 
   * and closes it if it's open.
   */
  const handleClick = () => {
    if (isOpen) {
      setIsOpen(false);
    } else if (details.length) {
      setIsOpen(true);
    } else {
      getDetails(props.row);
    }
  };

  const getParentChip = () => {
    if (props.isUltimateParent) {
      return (props.row.isUpcParentCustomer === 1 &&
        <Chip tabIndex={0} label='Parent' size='small' sx={styles.parentIdentifierChip} />)
    } else {
      return (props.parentIds.includes(getNumber(props.row.arCustomerId)) &&
        <Chip tabIndex={0} label='Parent' size='small' sx={styles.parentIdentifierChip} />)
    }
  }

  return (
    <>
      <TableRow
        key={`: ${getFallbackString(props.row.custSrcId)} - ${getFallbackString(props.row.custName)} at ${props.amountType}`}
        ref={props.lastRow ? props.lastRowElementRef : undefined}
      >
        <TableCell sx={styles.leftColSpacer}></TableCell>
        <TableCell 
          tabIndex={0}
          aria-label={'expand icon button'}
          align='left' sx={{ ...styles.tableCell, ...styles.arrowCell }} 
          onClick={hasDetails ? handleClick : undefined}
          onKeyDown={(e) => {
            if (hasDetails && e.key === 'Enter') {
              handleClick();
            }
          }}
        >
          {hasDetails ?  <ArrowDropDownIcon  sx={isOpen ? { ...styles.arrow, ...styles.arrowUp } : { ...styles.arrow }} /> : null}
        </TableCell>
        <TableCell tabIndex={0} align='left' sx={{ ...styles.tableCell, ...styles.custIdTableCell }} onClick={hasDetails ? handleClick : undefined}>
          {getFallbackString(props.row.custSrcId).length > 15 ?
            <Tooltip title={props.row.custSrcId}><Box>{`${props.row.custSrcId.slice(0, 15)}...`}</Box></Tooltip>
            : getFallbackString(props.row.custSrcId)}
        </TableCell>
        <TableCell align='left' sx={{ ...styles.tableCell, ...styles.custNameTableCell }} onClick={hasDetails ? handleClick : undefined}>
          <Box sx={styles.custNameWithParent}>
            <Box tabIndex={0} sx={styles.custNameContainer}>
              {props.row.custName.length > 25 ?
                <Tooltip title={props.row.custName}><Box>{`${props.row.custName.slice(0, 25)}...`}</Box></Tooltip>
                : props.row.custName}
            </Box>
            {getParentChip()}
          </Box>
        </TableCell>
        <TableCell tabIndex={0} align='right' sx={{ ...styles.tableCell, ...styles.amountTableCell }} onClick={hasDetails ? handleClick : undefined}>
          {formatNumber(
            { style: 'currency', currency: props.currencyCode ?? 'USD', currencySign: 'accounting' },
            props.row.amount,
            props.exchangeRate
          )}
        </TableCell>
        <TableCell sx={styles.rightColSpacer}></TableCell>
      </TableRow>
      {hasDetails && isOpen &&
      <>
      <TableRow key={`: ${getFallbackString(props.row.custSrcId)} - ${getFallbackString(props.row.custName)} at ${props.amountType} - details`}>
        <TableCell sx={styles.leftColSpacer}></TableCell>
        <TableCell tabIndex={0} sx={{ ...styles.tableCell, ...styles.arrowCell, ...styles.detailsTableBody }}></TableCell>
        <TableCell tabIndex={0} align='left' sx={{ ...styles.tableCell, ...styles.custIdTableCell, ...styles.detailsTableBody }}>
          Document #
        </TableCell>
        <TableCell align='left' sx={{ ...styles.tableCell, ...styles.custNameTableCell, ...styles.detailsTableBody }}>
          <Box sx={styles.detailsDateContainer}>
            <TableCell
              onMouseEnter={getTooltip}
              sx={styles.detailsTableDates}
            >
              Invoice Date
            </TableCell>
            <TableCell
              onMouseEnter={getTooltip}
              sx={styles.detailsTableDates}
            >
              DPID
            </TableCell>
            <TableCell
              onMouseEnter={getTooltip}
              sx={styles.detailsTableDates}
            >
              Due Date
            </TableCell>
            <TableCell
              onMouseEnter={getTooltip}
              sx={styles.detailsTableDates}
            >
              DPDD
            </TableCell>
          </Box>
        </TableCell>
        <TableCell tabIndex={0} align='right' sx={{ ...styles.tableCell, ...styles.amountTableCell, ...styles.detailsTableBody }}>
          Amount
        </TableCell>
        <TableCell sx={styles.rightColSpacer}></TableCell>
      </TableRow>
      {details
        .slice(0, page * rowsPerPage + rowsPerPage)
        .map((detail, index, array) => {
          const lastRow = array.length === index + 1;
          return (
            <TableRow key={detail.recordId} ref={lastRow ? lastRowElementRef : undefined}>
              <TableCell sx={styles.leftColSpacer}></TableCell>
              <TableCell tabIndex={0} sx={{ ...styles.tableCell, ...styles.arrowCell, ...styles.detailsTableBody }}></TableCell>
              <TableCell tabIndex={0} align='left' sx={{ ...styles.tableCell, ...styles.custIdTableCell, ...styles.detailsTableBody }} onMouseEnter={getTooltip}>
                {getFallbackString(detail.arTrxNo)}
              </TableCell>
              <TableCell align='left' sx={{ ...styles.tableCell, ...styles.custNameTableCell, ...styles.detailsTableBody }}>
                <Box sx={styles.detailsDateContainer}>
                  <TableCell
                    onMouseEnter={getTooltip}
                    sx={styles.detailsTableDates}
                  >
                    {formatDate(detail.arTrxDate, AMERICAN_DATE_FORMAT)}
                  </TableCell>
                  <TableCell
                    onMouseEnter={getTooltip}
                    sx={styles.detailsTableDates}
                  >
                    {detail.calcDaysAgedInvoiceDate}
                  </TableCell>
                  <TableCell
                    onMouseEnter={getTooltip}
                    sx={styles.detailsTableDates}
                  >
                    {detail.arTrxDueDate ? formatDate(detail.arTrxDueDate, AMERICAN_DATE_FORMAT) : null}
                  </TableCell>
                  <TableCell
                    onMouseEnter={getTooltip}
                    sx={styles.detailsTableDates}
                  >
                    {detail.arTrxDueDate ? detail.calcDaysAgedDueDate : null}
                  </TableCell>
                </Box>
              </TableCell>
              <TableCell tabIndex={0} align='right' sx={{ ...styles.tableCell, ...styles.amountTableCell, ...styles.detailsTableBody }}>
                {formatCurrency(detail.arTrxAmt, props.currencyCode, props.exchangeRate)}
              </TableCell>
              <TableCell sx={styles.rightColSpacer}></TableCell>
            </TableRow>
          )
      })}
      <TableRow key={`: ${getFallbackString(props.row.custSrcId)} - ${getFallbackString(props.row.custName)} at ${props.amountType} - bottom spacer`}>
        <TableCell sx={styles.leftColSpacer}></TableCell>
        <TableCell colSpan={4} sx={styles.bottomSpacer}></TableCell>
        <TableCell sx={styles.rightColSpacer}></TableCell>
      </TableRow>
      </>}
    </>
  )
}
export default ARIneligibleTableRow;