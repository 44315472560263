import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import styles from "./styles";
import MenuButton from "../../../../components/common/menu-button";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reportsAPI } from "../../../../service/api";
import { formatDate, getFileName, toCamelCase } from "../../../../utility/helper";
import { APAgingReportContext } from "../../../../context/apAgingReportContext";
import { IAPAgingReportContext, IBucketType } from "../../../../interfaces/apAgingReportInterface";
import axiosInstance from "../../../../service/axiosInstance";
import { BATCH_SIZE, POST } from "../../../../utility/constants";
import { SelectedClientContext } from "../../../../context/selectedClientContext";
import { IARCollateral, IBBPeriod, IClient } from "../../../../interfaces";
import { ICurrency, IRate } from "../../../../interfaces/multiCurrencyInterface";
import { isSelectedClientUPC } from "../main-container";

export interface IAPAgingReportPageHeaderProps {
  selectedExchangeRate: IRate | null;
}

const APAgingReportPageHeader: React.FC<IAPAgingReportPageHeaderProps> = (props) => {
  const { selectedExchangeRate } = props;
  const { selectedClient } = useContext(SelectedClientContext);
  const { selectedCollateral, selectedAsOfDate, selectedType, selectedCurrency, isLoading, hasGeneratedReport, sortParams, permissions } = useContext(APAgingReportContext) as IAPAgingReportContext;

  const [isExporting, setIsExporting] = useState<boolean>(false);

  const navigate                   = useNavigate();
  const theme                      = useTheme();
  const handleNavigate             = useCallback(() => navigate('/reports'), []);
  const belowMediumBreakpoint      = useMediaQuery(theme.breakpoints.down('md'));

  const getPayloadData = () => {
    const sortableHeaders = ['arVendorSrcId', 'arVendorName', 'total'];
    const sortProperties = sortableHeaders.includes(sortParams.orderBy)
      ? { order: sortParams.order.toUpperCase(), orderBy: sortParams.orderBy }
      : { order: 'ASC', orderBy: 'arVendorName' };
    return {
      borrowerId: (selectedClient as IClient).recordId,
      arCollateralId: (selectedCollateral as IARCollateral).recordId,
      bbPeriodId: (selectedAsOfDate as IBBPeriod).recordId,
      bucketType: toCamelCase(selectedType as IBucketType),
      ...sortProperties,
      batchSize: BATCH_SIZE,
      currencyId: (selectedCurrency as ICurrency).recordId,
      ...(selectedExchangeRate !== null && { rateId: selectedExchangeRate.recordId } ),
    };
  };

  const handleSelect = async (filetype: 'Excel' | 'PDF') => {
    try {
      setIsExporting(true);
      const exportResponse = await axiosInstance.request({
        url: reportsAPI.apAgingReport[`EXPORT_${filetype.toUpperCase()}`],
        method: POST,
        responseType: 'blob',
        data: getPayloadData(),
        headers: { 'X-Timezone' : Intl.DateTimeFormat().resolvedOptions().timeZone }
      })
      let exportData = exportResponse.data;
      const url = window.URL.createObjectURL(new Blob([exportData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        getFileName(
          filetype, 'APAging',
          formatDate((selectedAsOfDate as IBBPeriod).endDate ?? '', 'MM/DD/YYYY'),
          (selectedClient as IClient).borrowerName,
          (selectedCollateral as IARCollateral).arCollateralName
        )
      );
      document.body.appendChild(link);
      link.click();
    }
    catch (error) {
      console.log('EXPORT ERROR : ', error);
    } finally {
      setIsExporting(false);
    }
  };

  const getExportButton = () => {
    if (!permissions?.exportAPAgingReport || isSelectedClientUPC(selectedClient)) { return <></>; }
    return (
      <MenuButton
        label="Export"
        options={[
          {label: 'Excel', handleSelect: () => handleSelect('Excel')},
          {label: 'PDF', handleSelect: () => handleSelect('PDF')}
        ]}
        buttonProps={{
          endIcon: isExporting ? <CircularProgress size={15} /> : <FileDownloadOutlinedIcon />,
          size: 'medium',
          variant: 'outlined',
          disabled: !hasGeneratedReport || isLoading || isExporting,
          'aria-label': 'Export icon',
          sx: styles.headerButtons
        }}
      />
    );
  };

  return (
    <Box sx={styles.titleContainer}>
      <Typography tabIndex={0} variant='h6' component='h3' sx={styles.title}>
        AP Aging Report
      </Typography>
      <Box sx={styles.headerActionWrapper}>
        { getExportButton() }
        <Button
          size='medium'
          variant='outlined'
          aria-label='Go back icon'
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={handleNavigate}
          sx={styles.headerButtons}
        >
          { belowMediumBreakpoint ? null : 'Go back' }
        </Button>
      </Box>
    </Box>
  )
};

export default APAgingReportPageHeader;
