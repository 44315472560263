import { useContext } from 'react';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import IneligibleSettingsARFormContainer from './ar-form-container';
import IneligibleSettingsUPCFormContainer from './upc-form-container';
import IneligibleSettingsBreadcrumbs from '../breadcrumbs';
import { IIneligibleCondition, IIneligibleRuleConditionGroup, IIneligibleRuleOverride, IIneligibleSettingDetail, IUPCIneligibleSettingDetail, IneligibleRuleAPI, IneligibleRuleOverrideAPI, UPCIneligibleRuleAPI } from '../../../interfaces/ineligibleSettingInterface';
import { NON_EXISTING, NON_EXISTING_PERCENT } from '../../../utility/constants';
import { putIneligibleRuleConditionGroup, postIneligibleRuleCondition, putIneligibleRuleCondition, deleteRuleCondition, deleteRuleConditionGroup, deleteIneligibleRuleOverride, postIneligibleOverride, postIneligibleRuleConditionGroup } from '../../../api/ineligible-settings';
import { isObjectsEqual } from '../../../utility/helper';

export const initialCondition: IIneligibleCondition = {
  ineligibleRuleConditionId: NON_EXISTING,
  ineligibleRuleConditionGroupId: NON_EXISTING,
  order: 1,
  fieldTable: '',
  fieldType: '',
  field: '',
  operation: '',
  fieldValues: [],
  logicalOperator: 'AND',
  isFieldByField: false,
};

export const initialGroup: IIneligibleRuleConditionGroup[] = [{
  irConditionGroupId: NON_EXISTING,
  irOverrideId: NON_EXISTING,
  order: 1,
  logicalOperator: 'AND',
  ineligibleRuleCondition: [initialCondition]
}];

/**
 * This function creates an initial override object with default values for various properties.
 * @param hasPaymentTerms A boolean indicating whether payment terms exist.
 * @returns An initial override object conforming to the IIneligibleRuleOverride interface.
 */
export const getInitialOverride = (hasPaymentTerms: boolean) => {
  return {
    ineligibleRuleOverrideId: NON_EXISTING,
    ineligibleRuleId: NON_EXISTING,
    order: 1,
    dpid: NON_EXISTING,
    dpdd: hasPaymentTerms ? NON_EXISTING : 0,
    crossAgePct: NON_EXISTING_PERCENT,
    concentrationPct: NON_EXISTING_PERCENT,
    creditLimit: NON_EXISTING,
    excludedCustomers: [],
    ineligibleRuleConditionGroup: initialGroup
  } as IIneligibleRuleOverride;
};

export const handleCreateOverrideAndConnections = async (rule: IneligibleRuleAPI | UPCIneligibleRuleAPI, override: IIneligibleRuleOverride) => {
  const savedOverride = await postIneligibleOverride(override, rule.ineligibleRuleId);
  const savedConditionGroups = await Promise.all(override.ineligibleRuleConditionGroup.map(conditionGroup => handleCreateConditionGroupAndConnections(savedOverride, conditionGroup)));
  return { ...savedOverride, ineligibleRuleConditionGroup: savedConditionGroups } as IIneligibleRuleOverride;
};

export const handleCreateConditionGroupAndConnections = async (override: IneligibleRuleOverrideAPI, conditionGroup: IIneligibleRuleConditionGroup) => {
  const savedConditionGroup = await postIneligibleRuleConditionGroup(conditionGroup, override);
  const savedConditions = await Promise.all(conditionGroup.ineligibleRuleCondition.map(condition => postIneligibleRuleCondition(condition, savedConditionGroup)));
  return { ...savedConditionGroup, ineligibleRuleCondition: savedConditions } as IIneligibleRuleConditionGroup;
};

/**
 * This function handles updates for ineligible rule condition groups.
 * @param overrideFormData The data representing the ineligible rule override.
 * @returns An array of update promises.
 */
export const handleUpdatesForIneligibleRuleConditionGroups = (overrideFormData: IIneligibleRuleOverride, fetchedOverride: IIneligibleRuleOverride) => {
  return overrideFormData.ineligibleRuleConditionGroup.map(async conditionGroupFormData => {
    const IS_CONDITION_GROUP_NON_EXISTING = conditionGroupFormData.irConditionGroupId === NON_EXISTING;
    if (IS_CONDITION_GROUP_NON_EXISTING) { return handleCreateConditionGroupAndConnections(fetchedOverride, conditionGroupFormData); }

    const fetchedConditionGroup = fetchedOverride.ineligibleRuleConditionGroup.find(group => group.irConditionGroupId === conditionGroupFormData.irConditionGroupId) as IIneligibleRuleConditionGroup;
    const [ savedConditionGroup, ...savedConditions ] = await Promise.all([
      !isObjectsEqual(conditionGroupFormData, fetchedConditionGroup) ? ( await putIneligibleRuleConditionGroup(conditionGroupFormData) ) : conditionGroupFormData,
      ...handleUpdatesForIneligibleRuleCondition(conditionGroupFormData, fetchedConditionGroup)
    ])
    return { ...savedConditionGroup, ineligibleRuleCondition: savedConditions };
  });
};

export const handleUpdatesForIneligibleRuleCondition = (conditionGroupFormData: IIneligibleRuleConditionGroup, fetchedConditionGroup: IIneligibleRuleConditionGroup) => {
  return conditionGroupFormData.ineligibleRuleCondition.map(async conditionFormData => {
    const IS_CONDITION_NON_EXISTING = conditionFormData.ineligibleRuleConditionId === NON_EXISTING;
    if (IS_CONDITION_NON_EXISTING) { return postIneligibleRuleCondition(conditionFormData, conditionGroupFormData); }

    const fetchedCondition = fetchedConditionGroup.ineligibleRuleCondition.find(condition => condition.ineligibleRuleConditionId === conditionFormData.ineligibleRuleConditionId);
    return !isObjectsEqual(conditionFormData, fetchedCondition)
      ? putIneligibleRuleCondition(conditionFormData)
      : conditionFormData;
  });
};

/**
 * This function handles deleting an ineligible rule override and its associated condition groups and conditions.
 * @param override The ineligible rule override to delete.
 * @param formikSetting The Formik setting.
 * @returns A promise indicating the result of the delete operation.
 */
export const handleDeleteOverride = async (override: IIneligibleRuleOverride, formikSetting: IIneligibleSettingDetail | IUPCIneligibleSettingDetail) => {
  const loadedOverridesOnFormik = formikSetting.ineligibleRuleOverrides as IIneligibleRuleOverride[];
  const isOverrideNonExisting = override.ineligibleRuleOverrideId === NON_EXISTING;
  const isOverrideInFormik = loadedOverridesOnFormik.some(formikOverride => formikOverride.ineligibleRuleOverrideId === override.ineligibleRuleOverrideId);
  if (isOverrideNonExisting || isOverrideInFormik) { return Promise.resolve('NON DELETED OVERRIDE'); }
  await Promise.all(override.ineligibleRuleConditionGroup.map(async conditionGroup => {
    await Promise.all(conditionGroup.ineligibleRuleCondition.map(async condition => deleteRuleCondition(condition)));
    return deleteRuleConditionGroup(conditionGroup);
  }));
  return deleteIneligibleRuleOverride(override);
};

/**
 * This function handles deleting a condition group in the context of an ineligible rule override.
 * @param override The ineligible rule override.
 * @param conditionGroup The condition group to delete.
 * @param formikSetting The Formik setting.
 * @returns A promise indicating the result of the delete operation.
 */
export const handleDeleteConditionGroup = async (override: IIneligibleRuleOverride, conditionGroup: IIneligibleRuleConditionGroup, formikSetting: IIneligibleSettingDetail | IUPCIneligibleSettingDetail) => {
  const loadedOverridesOnFormik = formikSetting.ineligibleRuleOverrides as IIneligibleRuleOverride[];
  const isConditionGroupNonExisting = conditionGroup.irConditionGroupId === NON_EXISTING;
  if (isConditionGroupNonExisting) { return Promise.resolve('NON EXISTING GROUP');; }
  const formikOverrides = loadedOverridesOnFormik?.find(formikOverride => formikOverride.ineligibleRuleOverrideId === override.ineligibleRuleOverrideId);
  if (formikOverrides === undefined) { return Promise.resolve('UNDEFINED FORMIK OVERRIDE'); }
  const isConditionGroupInFormik = formikOverrides.ineligibleRuleConditionGroup.some(formikConditionGroup => formikConditionGroup.irConditionGroupId === conditionGroup.irConditionGroupId);
  if (isConditionGroupInFormik) { return Promise.resolve('GROUP IN FORMIK'); }
  await Promise.all(conditionGroup.ineligibleRuleCondition.map(async condition => deleteRuleCondition(condition)));
  return deleteRuleConditionGroup(conditionGroup);
};

/**
 * This function handles deleting a condition in the context of an ineligible rule override.
 * @param override The ineligible rule override.
 * @param conditionGroup The condition group.
 * @param condition The condition to delete.
 * @param formikSetting The Formik setting.
 * @returns A promise indicating the result of the delete operation.
 */
export const handleDeleteCondition = (override: IIneligibleRuleOverride, conditionGroup: IIneligibleRuleConditionGroup, condition: IIneligibleCondition, formikSetting: IIneligibleSettingDetail | IUPCIneligibleSettingDetail) => {
  const loadedOverridesOnFormik = formikSetting.ineligibleRuleOverrides as IIneligibleRuleOverride[];
  const isConditionNonExisting = condition.ineligibleRuleConditionId === NON_EXISTING;
  if (isConditionNonExisting) { return Promise.resolve('NON EXISTING CONDITION'); }
  const formikOverrides = loadedOverridesOnFormik?.find(formikOverride => formikOverride.ineligibleRuleOverrideId === override.ineligibleRuleOverrideId);
  if (formikOverrides === undefined) { return Promise.resolve('UNDEFINED FORMIK OVERRIDE'); }
  const formikGroup = formikOverrides.ineligibleRuleConditionGroup?.find(formikGroup => formikGroup.irConditionGroupId === conditionGroup.irConditionGroupId);
  if(formikGroup === undefined) { return Promise.resolve('UNDEFINED FORMIK GROUP'); }
  const isConditionInFormik = formikGroup.ineligibleRuleCondition.some(formikCondition => formikCondition.ineligibleRuleConditionId === condition.ineligibleRuleConditionId);
  if (isConditionInFormik) { return Promise.resolve('CONDITION IN FORMIK'); }
  return deleteRuleCondition(condition)
};

const IneligibleSettingsFormContainer: React.FC = () => {
  const { selectedClient } = useContext(SelectedClientContext);

  const getFormContent = () => {
    if (selectedClient?.parentClient) { return <IneligibleSettingsUPCFormContainer />; }
    return <IneligibleSettingsARFormContainer />
  };

  return (
    <>
      <IneligibleSettingsBreadcrumbs />
      { getFormContent() }
    </>
  );
};

export default IneligibleSettingsFormContainer;
