import { Box, Button, IconButton, InputAdornment, Menu, MenuItem, MenuList, TextField, Tooltip } from "@mui/material";
import { useState, MouseEvent, Dispatch, SetStateAction, useCallback } from "react";
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import styles from "./styles";
import { Order } from "../../../utility/helper";

interface IFilterSearch<T> {
  id                  : keyof T,
  defaultOrder        : Order,
  setOrder            : Dispatch<SetStateAction<Order>>;
  setOrderBy          : Dispatch<SetStateAction<keyof T>>;
  onSearch            : (searchInput: string, id: 'global' | keyof T) => void;
}

const sortOptions = ['Sort A → Z', 'Sort Z → A'];

const FilterSearch = <T, >(props: IFilterSearch<T>) => {
  const [anchorEl, setAnchorEl]       = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [prevSearch, setPrevSearch]   = useState<string>('');

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget), []);
  const handleClear = useCallback(() => setSearchValue(''), []);

  const handleItemClick = useCallback((event: any) => {
    setAnchorEl(null);

    const order: number = event.target.value;

    if (order === 0) {
      props.setOrder('asc');
    } else {
      props.setOrder('desc');
    }
    props.setOrderBy(props.id);
  }, [props.id]);

  const handleMenuClose = useCallback(() => {
    setSearchValue(prevSearch);
    setAnchorEl(null);
  }, [prevSearch]);

  const handleChange = useCallback((e: any) => setSearchValue(e.target.value), []);

  const handleOK = useCallback(() => {
    props.onSearch(searchValue, props.id);
    setPrevSearch(searchValue);
    setAnchorEl(null);
  }, [searchValue, props])
  
  const isNotBlank = () => {
    if (searchValue !== '') {
      return {
        endAdornment: (<IconButton onClick={handleClear} sx={styles.clearIconButton}><ClearIcon sx={styles.clearIcon} /></IconButton>),
      }
    }
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title='Filter this column'>
        <IconButton
          id={`filter-${String(props.id)}-button`}
          data-testid='filter-button'
          aria-label='Filter icon'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={open ? { ...styles.openedIcon, ...styles.filterIconButton } : styles.filterIconButton}
        >
          <FilterListIcon sx={styles.filterIcon} />
        </IconButton>
      </Tooltip>
      <Menu
        id='filter-customer-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{sx: { ...styles.menuContainer }}}
      >
        <TextField
          id='input-with-sx'
          InputProps={{
            startAdornment: 
            <InputAdornment position='start' tabIndex={0} aria-label='Search icon'>
              <SearchIcon sx={styles.searchIcon} />
            </InputAdornment>,
            sx: styles.searchFieldText,
            ...isNotBlank()
          }}
          inputProps={{'aria-label': 'Filter Items', 'data-testid' : 'filter-textfield'}}
          onChange={handleChange}
          onKeyDown={(e) => {
            e.key === 'Enter' && handleOK()
          }}
          value={searchValue}
          placeholder='Search'
          size='small'
        />
        <Box>
          <MenuList dense sx={styles.menuList}>
            {sortOptions.map((option: string, index: number) => 
              <MenuItem key={option} value={index} onClick={handleItemClick}>{option}</MenuItem>
            )}
          </MenuList>
        </Box>
        <Box sx={styles.btnGroupDateRange}>
          <Button variant='outlined' disableElevation onClick={handleMenuClose} sx={styles.btnDateRange}>
            Cancel
          </Button>
          <Button variant='contained' disableElevation onClick={handleOK} sx={{...styles.btnDateRange, ...styles.btnOK}} data-testid='confirm-filter'>
            OK
          </Button>
        </Box>
      </Menu>
    </>
  )
}

export default FilterSearch;