import { FC } from 'react';
import APAgingReportProvider from '../../../context/apAgingReportContext';
import APAgingReportNavigationHeader from './navigation-header';
import APAgingMainContainer from './main-container';

const APAgingReport: FC = () => {
  return (
    <APAgingReportProvider>
      <APAgingReportNavigationHeader />
      <APAgingMainContainer />
    </APAgingReportProvider>
  );
};

export default APAgingReport;
