import React, { useEffect, useState, useContext } from 'react';
import { Formik, Form, Field, FieldArray, getIn, FormikErrors, FormikTouched, FormikProps } from 'formik';
import NumberFormat, { InputAttributes } from 'react-number-format';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Box, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, TextField, IconButton, FormLabel, Tabs, Tab, AlertColor, Tooltip, } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HistoryIcon from '@mui/icons-material/History';
import { GET, POST, PUT, API_DOMAIN, PERMISSIONS, NO_PERMISSION_MSG } from '../../../../../utility/constants';
import { isObjectsEqual, formatDate, trimOnBlur, getPermissionsOfUser, checkUserPermissions } from '../../../../../utility/helper';
import { usePrompt } from '../../../../../utility/prompt';
import ineligibleAdjustmentsSchema from '../../../../../schemas/ineligibleAdjustmentsSchema';
import { IneligibleAdjustmentsContext as IAContext, IIneligibleAdjustmentsContext as IAContextInterface, } from '../../../../../context/ineligibleAdjustmentsContext';
import { NavigationWarningContext, INavigationWarningContext, } from '../../../../../context/navigationWarningContext';
import { IResponseContent, IIneligible, IFormikValue, INumberFormatProps, IResetFormForIneligibles } from '../../../../../interfaces/ineligibleAdjustmentsInterface';
import Toaster from '../../../../toaster';
import ConfirmModal from '../../../../modals/confirm-modal';
import styles from './styles';
import NoDataPlaceholder from '../../../../common/no-data-placeholder';
import { SkeletonRow, SkeletonTableHead } from '../../../../skeleton';
import { AuthContext } from '../../../../../context/authContext';
import HelperTextComponent from '../../../../common/helper-text-component';
import DisabledComponentsContainer from '../../../../common/disabled-components-container';
import axiosInstance from '../../../../../service/axiosInstance';
import request from '../../../../../service/request';
import requests from '../../../../../service/requests';

// dayjs config, maybe put this in services?
dayjs.extend(customParseFormat);

const CurrencyFormat = React.forwardRef<NumberFormat<InputAttributes>, INumberFormatProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      type='tel'
      decimalScale={2}
      fixedDecimalScale={true}
      prefix='$'
    />
  );
});

const IneligibleAdjustmentsTable = (props: { ineligibleType: string }) => {
  const TABS: { label: 'Active' | 'Archived'}[]      = [ { label: 'Active' }, { label: 'Archived' } ];
  const headers                                                 = ['Date Created', 'Description', 'Ineligible Amount', 'Action'];
  const { isDirty, setIsDirty }                                 = useContext(NavigationWarningContext) as INavigationWarningContext;
  const { selectedClient, selectedCollateral, setTotalAmount, 
          setViewIA, updateIA, setUpdateIA,
          addIA, setAddIA, deleteArchiveIA, setDeleteArchiveIA} = useContext(IAContext) as IAContextInterface;
  const {state}                                                 = useContext(AuthContext)
  const [ineligibles, setIneligibles]                           = useState<IIneligible[]>([]);
  const [isLoading, setIsLoading]                               = useState<boolean>(false);
  const [allowAddingRow, setAllowAddingRow]                     = useState<boolean>(true);
  const [isToasterOpen, setIsToasterOpen]                       = useState<boolean>(false);
  const [selectedTabIndex, setSelectedTabIndex]                 = useState<number>(TABS.findIndex(tab => tab.label === 'Active'));
  const [tempTabIndex, setTempTabIndex]                         = useState<number>(TABS.findIndex(tab => tab.label === 'Active'));
  const [toasterMessage, setToasterMessage]                     = useState<string>('');
  const [toasterSeverity, setToasterSeverity]                   = useState<AlertColor>('success');
  const [deleteModal, setDeleteModal]                           = useState({ isOpen: false, isNew: false, deleteRecordId: '', deleteName: '', deleteIndex: -1, });
  const [formikValues, setFormikValues]                         = useState<IFormikValue | null>(null);
  const [isFormikResetting, setIsFormikResetting]               = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen]                           = useState<boolean>(false);
  const [isDeleteModal, setIsDeleteModal]                       = useState<boolean>(false);
  const [modalTitle, setModalTitle]                             = useState<string>('');
  const [modalDescription, setModalDescription]                 = useState<string>('');
  const [modalConfirmText, setModalConfirmText]                 = useState<string>('');
  const [selectedIneligible, setSelectedIneligible]             = useState<IIneligible | null>(null);
  const [showPrompt, setShowPrompt]                             = useState<boolean>(false);

  const tabData = [
    {label: 'Active'}, 
    {label: 'Archived'}
  ];

  useEffect(() => {
    getPermissions()
    if (isLoading) { return; }
    !isFormikResetting && setIsFormikResetting(true);
    fetchIneligibles();
  }, [selectedCollateral, selectedTabIndex]);

  useEffect(() => {
    if (formikValues === null || formikValues.ineligibles === undefined) { return; }
    const { savedFormikValues, emptyFormikValues } = getSavedAndEmptyFormikValues(formikValues.ineligibles);
    calcTotalAmount(savedFormikValues);
    emptyFormikValues.length > 0 ? setAllowAddingRow(false) : setAllowAddingRow(true);
  }, [formikValues]);

  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(state.uid)
    setViewIA(permissions.includes(PERMISSIONS.VIEW_IA))
    setAddIA(permissions.includes(PERMISSIONS.ADD_IA))
    setUpdateIA(permissions.includes(PERMISSIONS.UPDATE_IA))
    setDeleteArchiveIA(permissions.includes(PERMISSIONS.DELETE_ARCHIVE_IA))
  }

  const checkPermission = async (func: Function, permission: string, ...args: any[]) => {
    const isPermitted = await checkUserPermissions(state.uid, permission)
    if(isPermitted){
      func(...args)
      return
    }
    setToasterMessage(NO_PERMISSION_MSG)
    setToasterSeverity('error')
    setIsToasterOpen(true)
  }

  const tabProps = (index: number) => {
    return {
      id: `ineligible-adjustments-tab-${index}`,
      'aria-controls': `ineligible-adjustments-tabpanel-${index}`,
    };
  };

  const handleDiscardChanges = () => {
    setIsDirty(false);
    setIneligibles([]);
    setSelectedTabIndex(tempTabIndex);
    setShowPrompt(false);
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (isDirty) {
      setShowPrompt(true);
      setTempTabIndex(newValue);
    } else {
      setIneligibles([]);
      setSelectedTabIndex(newValue);
    }
  };

  const fetchIneligibles = () => {
    if (selectedClient.recordId === undefined || selectedClient.recordId === -1) {
      setIneligibles([]);
      return;
    }
    if (selectedCollateral.recordId === undefined || selectedCollateral.recordId === -1) {
      setIneligibles([]);
      return;
    }
    setIsLoading(true);
    const isArchived = selectedTabIndex === TABS.findIndex(tab => tab.label === 'Archived');
    request({
      url: `${API_DOMAIN}/bb/ineligibleAdjustments/search/findIsArchived?borrowerId=${selectedClient.recordId}&pageNo=0&pageSize=9999&sortBy=recordId,ASC&isArchived=${isArchived}`,
      method: GET,
    })
      .then((response) => {
        const data = response.data.content.filter((item : any) => item.isCurrent === true);
        const ineligibleData: IIneligible[] = data
          .filter((row: IResponseContent) => {
            // filter by fks and ineligible type
            if (row.ineligibleType !== props.ineligibleType) { return false; }
            if (props.ineligibleType === 'AR' && row.arCollateralFk !== selectedCollateral.recordId) { return false; }
            if (props.ineligibleType === 'Inventory' && row.invCollateralFk !== selectedCollateral.recordId) { return false; }
            return true; // include in array if fks match
          })
          .map((row: IResponseContent) => {
            // map response data to new Row
            const fetchedIneligible = {
              recordId: row.recordId.toString(),
              dateCreated: dayjs(row.appCreatedDate, 'DD/MM/YY').format('MM/DD/YYYY'),
              description: row.description,
              ineligibleAmount: row.amount.toFixed(2),
              canDelete: row.canDelete === 1
            };
            return fetchedIneligible;
          });

        setIsDirty(false);
        setIneligibles(_ => ineligibleData);
      })
      .catch((error) => console.log('INELIGIBLE ADJUSTMENTS GET ERROR: ', error))
      .finally(() => setIsLoading(false));
  };

  const deleteIneligible = (recordId: string, isFetchingAfter?: boolean) => {
    const deletedIneligible = ineligibles.filter(ineligible => ineligible.recordId === recordId)[0];
    const deleteConfig = {
      url: `${API_DOMAIN}/bb/ineligibleAdjustments/${deletedIneligible.recordId}`,
      method: PUT,
      data: {
        recordId: parseInt(deletedIneligible.recordId),
        borrowerFk: selectedClient.recordId,
        ...getCollateralFk(selectedCollateral.recordId, props.ineligibleType),
        description: deletedIneligible.description,
        amount: parseFloat(deletedIneligible.ineligibleAmount),
        appCreatedDate: dayjs(
          deletedIneligible.dateCreated,
          'MM/DD/YYYY'
        ).format('DD/MM/YY'),
        ineligibleType: props.ineligibleType,
        isCurrent: false,
      },
    };

    request(deleteConfig)
      .then((_response) => {
        const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
        setToasterMessage(`${itemName} has been deleted`);
        setToasterSeverity('success');
        setIsToasterOpen(true); // display delete toaster
        if (isFetchingAfter) { fetchIneligibles(); }
      })
      .catch((error) => console.log('INELIGIBLE ADJUSTMENTS DELETE ERROR: ', error));
  };

  const archiveIneligible = () => {
    axiosInstance.request({
      url: `${API_DOMAIN}/bb/ineligibleAdjustments/${selectedIneligible?.recordId}/archive`,
      method: PUT
    })
    .then((response) => {
      setToasterMessage(`${selectedIneligible?.description} has been archived.`);
      setToasterSeverity('success');
      setIsToasterOpen(true);
      fetchIneligibles();
    })
    .catch((error) => {
      console.log(error);
      setToasterMessage(`Failed to archive ${selectedIneligible?.description}. Please check your inputs!`);
      setToasterSeverity('error');
      setIsToasterOpen(true);
    });
    closeModal();
  };

  const restoreIneligible = () => {
    axiosInstance.request({
      url: `${API_DOMAIN}/bb/ineligibleAdjustments/${selectedIneligible?.recordId}/archive?isArchived=false`,
      method: PUT
    })
    .then((response) => {
      setToasterMessage(`${selectedIneligible?.description} has been restored.`);
      setToasterSeverity('success');
      setIsToasterOpen(true);
      fetchIneligibles();
    })
    .catch((error) => {
      console.log(error);
      setToasterMessage(`Failed to restore ${selectedIneligible?.description}. Please check your inputs!`);
      setToasterSeverity('error');
      setIsToasterOpen(true);
    });
    closeModal();
  };

  const calcTotalAmount = (ineligiblesArr: IIneligible[]) => {
    let total = 0;
    for (const item of ineligiblesArr) {
      total += parseFloat(item.ineligibleAmount);
    }
    setTotalAmount(total);
  };

  const handleDelete = (values: IFormikValue, index: number) => {
    const isIneligibleToDeleteNew = values.ineligibles[index].recordId === undefined;
    if (isIneligibleToDeleteNew) {
      setDeleteModal({
        isOpen: true,
        isNew: isIneligibleToDeleteNew,
        deleteRecordId: '',
        deleteName: values.ineligibles[index].description,
        deleteIndex: index,
      });
      return;
    }
    setDeleteModal({
      isOpen: true,
      isNew: isIneligibleToDeleteNew,
      deleteRecordId: values.ineligibles[index].recordId,
      deleteName: values.ineligibles[index].description,
      deleteIndex: index,
    });
  };

  const handleConfirmDelete = (formikValues: IIneligible[], remove: (index: number) => void) => {
    if (deleteModal.isNew) {
      remove(deleteModal.deleteIndex);
      const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
      setToasterMessage(`${itemName} has been deleted`);
      setToasterSeverity('success');
      setIsToasterOpen(true);
    }

    const { hasUnsavedChanges, presentFormikValues } = getPresentFormikValues(formikValues);
    if (hasUnsavedChanges && deleteModal.isNew) {
      setFormikValues({ ineligibles: presentFormikValues });
      return;
    }
    if (hasUnsavedChanges && !deleteModal.isNew) {
      deleteIneligible(deleteModal.deleteRecordId);
      setFormikValues({ ineligibles: presentFormikValues });;
      return;
    }
    if (!hasUnsavedChanges && !deleteModal.isNew) {
      deleteIneligible(deleteModal.deleteRecordId, true);
      setFormikValues({ ineligibles: presentFormikValues });
      return;
    }
    setIneligibles(presentFormikValues);
  };

  const handleCancel = (resetForm: IResetFormForIneligibles) => {
    if (formikValues === null) { return; }
    const { savedFormikValues } = getSavedAndEmptyFormikValues(formikValues.ineligibles);
    setIsDirty(false);
    resetForm({ values: { ineligibles: savedFormikValues } });
    setIneligibles(savedFormikValues);
    setAllowAddingRow(true);
  };

  const handleSave = async (ineligiblesToSave: IIneligible[], setSubmitting: (isSubmitting: boolean) => void) => {
    const isPermittedAdd = await checkUserPermissions(state.uid, PERMISSIONS.ADD_IA)
    const isPermittedUpdate = await checkUserPermissions(state.uid, PERMISSIONS.UPDATE_IA)

    if(!isPermittedAdd && !isPermittedUpdate){
      setToasterMessage(NO_PERMISSION_MSG)
      setToasterSeverity('error')
      setIsToasterOpen(true)
      return
    }

    setSubmitting(true);
    const ineligiblesToEdit = ineligiblesToSave
      .filter((ineligible) => {
        const isIneligibleNew = ineligible.hasOwnProperty('recordId');
        return isIneligibleNew;
      })
      .filter((ineligible) => {
        const [originalIneligible] = ineligibles.filter(currentIneligible => currentIneligible.recordId === ineligible.recordId);
        if (originalIneligible === undefined) { return false; }
        const isIneligibleNotEdited = !isObjectsEqual(ineligible, originalIneligible);
        return isIneligibleNotEdited;
      });

    // 1st part of update request (hide values to update)
    const editRequestPutConfigs = ineligiblesToEdit.map((ineligible) => {
      // set isCurrent to false
      return {
        url: `${API_DOMAIN}/bb/ineligibleAdjustments/${ineligible.recordId}`,
        method: PUT,
        data: {
          recordId: parseInt(ineligible.recordId),
          borrowerFk: selectedClient.recordId,
          ...getCollateralFk(selectedCollateral.recordId, props.ineligibleType),
          description: ineligible.description,
          amount: parseFloat(ineligible.ineligibleAmount),
          appCreatedDate: dayjs(ineligible.dateCreated, 'MM/DD/YYYY').format('DD/MM/YY'),
          ineligibleType: props.ineligibleType,
          isCurrent: false,
        },
      };
    });

    // part 2 of update request (set isCurrent to true for updated values)
    const editRequestPostConfigs = ineligiblesToEdit.map((ineligible) => {
      return {
        url: `${API_DOMAIN}/bb/ineligibleAdjustments`,
        method: POST,
        data: {
          borrowerFk: selectedClient.recordId,
          ...getCollateralFk(selectedCollateral.recordId, props.ineligibleType),
          description: ineligible.description,
          amount: parseFloat(ineligible.ineligibleAmount),
          appCreatedDate: dayjs(ineligible.dateCreated, 'MM/DD/YYYY').format('DD/MM/YY'),
          ineligibleType: props.ineligibleType,
          isCurrent: true,
        },
      };
    });

    // add new ineligible
    const postRequestConfigs = ineligiblesToSave
      .filter((ineligible) => {
        return !ineligible.hasOwnProperty('recordId');
      })
      .map((ineligible) => {
        return {
          url: `${API_DOMAIN}/bb/ineligibleAdjustments`,
          method: POST,
          data: {
            borrowerFk: selectedClient.recordId,
            ...getCollateralFk(selectedCollateral.recordId, props.ineligibleType),
            description: ineligible.description,
            amount: parseFloat(ineligible.ineligibleAmount),
            appCreatedDate: dayjs(ineligible.dateCreated, 'MM/DD/YYYY').format('DD/MM/YY'),
            ineligibleType: props.ineligibleType,
            isCurrent: true,
          },
        };
      });

    const requestsConfigs = [
      ...(isPermittedUpdate ? editRequestPutConfigs : []), 
      ...(isPermittedUpdate ? editRequestPostConfigs : []), 
      ...(isPermittedAdd ? postRequestConfigs : [])
    ];
    const updatedToasterMessage = getUpdateToasterMessage(isPermittedAdd ? postRequestConfigs : [],  isPermittedUpdate ? editRequestPostConfigs : []);
    requests(requestsConfigs.map((requestConfig) => request(requestConfig)))
      .then((_responses) => {
        setIsDirty(false);
        fetchIneligibles();
        setToasterMessage(updatedToasterMessage);
        setToasterSeverity('success');
        setIsToasterOpen(true);
        setSubmitting(false);
      })
      .catch((error) => {
        console.log('ineligible adjustments requests error', error);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onArchiveClick = (values: IIneligible[], index: number) => {
    setSelectedIneligible(values[index]);
    setModalTitle(`Archive ${values[index].description}`);
    setModalDescription('Are you sure you want to archive this item?');
    setModalConfirmText('Archive')
    setIsDeleteModal(false)
    setIsModalOpen(true)
  }

  const onRestoreClick = (values: IIneligible[], index: number) => {
    setSelectedIneligible(values[index]);
    setModalTitle(`Restore ${values[index].description}`);
    setModalDescription('Are you sure you want to restore this item?');
    setModalConfirmText('Restore')
    setIsDeleteModal(false)
    setIsModalOpen(true)
  }

  const handleConfirm = () => {
    if (modalConfirmText === 'Archive') {
      return archiveIneligible();
    } else {
      return restoreIneligible();
    }
  }

  // get different key depending on ineligible Type
  const getCollateralFk = (collateralId: number, ineligibleType: string) => {
    const collateralFk = {
      'AR': { arCollateralFk: collateralId },
      'Inventory': { invCollateralFk: collateralId }
    };
    
    return collateralFk[ineligibleType];
  };

  const getDisplayedError = (errors: FormikErrors<IFormikValue>, touched: FormikTouched<IFormikValue>, name: string, index: number) => {
    const fieldError = getIn(errors, `ineligibles[${index}].${name}`);
    const isFieldTouched = getIn(touched, `ineligibles[${index}].${name}`);

    if (fieldError && isFieldTouched) { return { error: true, helperText: <HelperTextComponent text={fieldError} /> }; }

    return null;
  };

  const addNewRow = (push: any) => {
    const newIneligible = { dateCreated: dayjs().format('MM/DD/YYYY'), description: '', ineligibleAmount: '' }; // defaults to today as date of creation
    push(newIneligible);
    setAllowAddingRow(false);
  };

  const getUpdateToasterMessage = (addItems: any, updateItems: any) => {
    const addLength = addItems.length;
    const updateLength = updateItems.length;
    if (addLength > 0 && updateLength > 0) {
      return 'Changes in Inventory Ineligibles have been saved';
    } else if (addLength > 0 && updateLength <= 0) {
      const phrase = addLength > 1 ? 'Items have been' : `${addItems[0].data.description} has been`;
      return `${phrase} added`;
    } else {
      const phrase = updateLength > 1 ? 'Items have been' : `${updateItems[0].data.description} has been`;
      return `${phrase} updated`;
    }
  };

  const getPresentFormikValues = (formikValues: IIneligible[]) => {
    let hasUnsavedChanges = false;
    const presentFormikValues = formikValues.filter((currentValue: IIneligible, currentIndex: number) => {
      if (currentIndex === deleteModal.deleteIndex) { return false; }
      if (currentValue.recordId === deleteModal.deleteRecordId) { return false; }

      const hasNewRecord = currentValue.recordId === undefined;
      const hasUpdatedRecord = ineligibles.some(ineligible => {
        if (ineligible.recordId !== currentValue.recordId) { return false; }
        return !isObjectsEqual(currentValue, ineligible);
      });
      if (hasNewRecord || hasUpdatedRecord) { hasUnsavedChanges = true; }

      return true;
    });

    return { hasUnsavedChanges, presentFormikValues };
  };

  const getSavedAndEmptyFormikValues = (formikValuesForIneligibles: IIneligible[]) => {
    const [savedFormikValues, emptyFormikValues] = formikValuesForIneligibles.reduce((separatedFormikValues: IIneligible[][], ineligible: IIneligible) => {
      let currentSavedFormikValues = [...separatedFormikValues[0]];
      let currentEmptyFormikValues = [...separatedFormikValues[1]];

      const isIneligibleSaved = ineligible.recordId !== undefined && ineligible.recordId !== '';
      if (isIneligibleSaved) {
        const [savedIneligible] = ineligibles.filter(originalIneligible => originalIneligible.recordId === ineligible.recordId);
        savedIneligible !== undefined && currentSavedFormikValues.push(savedIneligible);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      const isIneligibleEmpty = (
        (ineligible.description === undefined || ineligible.description === '') &&
        (ineligible.ineligibleAmount === undefined || ineligible.ineligibleAmount === '')
      );
      if (isIneligibleEmpty) {
        currentEmptyFormikValues.push(ineligible);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
    }, [[], []]);

    return { savedFormikValues, emptyFormikValues };
  };

  const hasSelectedClient = () => selectedClient && selectedClient.borrowerName !== '';

  const hasSelectedCollateral = () => selectedCollateral && selectedCollateral.collateralName !== '';

  const hasArchivedCollaterals = () => selectedTabIndex === TABS.findIndex(tab => tab.label === 'Archived') ? ineligibles.length > 0 : true;

  const formikRef = (node: FormikProps<IFormikValue>) => {
    if (node === null || isLoading) { return; }
    setFormikValues(node.values);
    !isObjectsEqual(formikValues, node.values) && setIsDirty(node.dirty);
    if (!isFormikResetting) { return; }
    node.resetForm();
    setIsFormikResetting(false);
  };

  const getAction = (ineligible: IIneligible, formik: FormikProps<{ineligibles: IIneligible[]}>, index: number) => {
    if (deleteArchiveIA) {
      if (selectedTabIndex === 0) {
        if (ineligible.canDelete !== false) {
          return (
            <Tooltip title='Delete the item'>
              <IconButton
                onClick={() => checkPermission(handleDelete, PERMISSIONS.DELETE_ARCHIVE_IA, formik.values, index)}
                sx={styles.actionButton}
                color='primary'
                aria-label='Delete icon'
              >
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title='Archive the item'>
              <IconButton
                onClick={() => checkPermission(onArchiveClick, PERMISSIONS.DELETE_ARCHIVE_IA, formik.values.ineligibles, index)}
                sx={styles.actionButton}
                color='primary'
                aria-label='Archive icon'
              >
                <Inventory2OutlinedIcon />
              </IconButton>
            </Tooltip>
          );
        }
      } else {
        return (
          <Tooltip title='Restore the item'>
            <IconButton
              onClick={() => checkPermission(onRestoreClick, PERMISSIONS.DELETE_ARCHIVE_IA, formik.values.ineligibles, index)}
              sx={styles.actionButton}
              color='primary'
              aria-label='Restore icon'
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        );
      }
    }
  }

  const getFieldTabIndex = (index: number) => {
    return isFieldDisabled(index) ? 0 : null;
  }

  const isFieldDisabled = (index: number) => {
    return selectedTabIndex === TABS.findIndex(tab => tab.label === 'Archived') || (formikValues?.ineligibles[index]?.recordId === undefined ? !addIA : !updateIA);
  }

  const getActionButtons = (formik: FormikProps<IFormikValue>) => {
    const hasPermissions = addIA || updateIA || deleteArchiveIA;

    if (hasPermissions) {
      return (
        <Box sx={{ ...styles.buttonsContainer, ...(!(hasSelectedClient() && hasSelectedCollateral() && hasArchivedCollaterals()) && styles.hidden) }} >
          {formik.dirty ? (
            <Button onClick={() => handleCancel(formik.resetForm)} variant='outlined' >
              <Typography variant='body2' component='p'>
                Cancel
              </Typography>
            </Button>
          ) : null}
          <DisabledComponentsContainer isDisabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}>
            <Button
              disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}
              aria-label={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading ? 'Save button disabled' : 'Save'}
              variant='contained'
              type='submit'
            >
              <Typography variant='body2' component='p'>
                Save
              </Typography>
            </Button>
          </DisabledComponentsContainer>
        </Box>
      )
    }            
  };

  const getRowAdder = (formik: FormikProps<{ineligibles: IIneligible[];}>, push: (obj: any) => void) => {
    return (
      <TableRow sx={styles.tableRow} key={formik.values.ineligibles.length} data-testid='row-adder'>
        {headers.map((header, index) => (
          <TableCell sx={styles.tableCell} key={header}>
            {index < headers.length - 1 && (
              <TextField
                sx={styles.textField}
                value=''
                onClick={() => addNewRow(push)}
                onKeyDown={(e) => { if (e.key === 'Enter') { addNewRow(push) } }}
                inputProps={{
                  sx: {...(header === 'Date Created' && styles.centerAlignedText)},
                  'aria-label': 'Press enter to add new entry'
                }}
                placeholder={header === 'Date Created' ? `${formatDate('','MM/DD/YYYY')}` : ''}
                disabled={header === 'Date Created'}
                tabIndex={header === 'Date Created' ? 0 : 1} 
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  usePrompt('You have unsaved changes. Are you sure you want to leave this page?', isDirty);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{ ineligibles }}
      validationSchema={ineligibleAdjustmentsSchema}
      onSubmit={(values, { setSubmitting }) => handleSave(values.ineligibles, setSubmitting)}
    >
      {
        formik => (
          <Form onSubmit={formik.handleSubmit}>
            {(!hasSelectedClient() || !hasSelectedCollateral()) && <NoDataPlaceholder messageContainerStyle={styles.outmostContainer}messageText='There are no AR Collaterals added yet.'  />}
            <Box sx={{ ...styles.outmostContainer, ...((!hasSelectedClient() || !hasSelectedCollateral()) && styles.hidden) }}>
              <Tabs
                aria-label='loan balances tab'
                value={selectedTabIndex}
                onChange={handleChange}
                sx={styles.buttonTabs}
              >
                {tabData.map((tab, index) => <Tab tabIndex={0} label={tab.label} key={tab.label} {...tabProps(index)} />)}
              </Tabs>
              {(!hasArchivedCollaterals() && !isLoading) && <NoDataPlaceholder messageText='There are no archives. Please add an archive to start.' />}
              {isLoading && (
                <Table data-testid='ineligible-adjustment-loader' sx={styles.table}>
                  <SkeletonTableHead />
                  <TableBody>  
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                  </TableBody>
                </Table>
              )}
              <TableContainer sx={styles.tableContainer} hidden={!hasArchivedCollaterals() || isLoading }>
                <Table sx={styles.table}>
                  {}
                  <TableHead>
                    <TableRow sx={styles.tableHeadRow}>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForDateCreated, ...styles.centerAlignedText }}>
                        <FormLabel tabIndex={0} htmlFor='date-created-box' sx={{...styles.tableHeaderText}}>
                          Date Created
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={styles.tableHeadCell}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='description-box'
                          sx={{ ...styles.tableHeaderText, ...styles.tableHeadCellForDescription }}
                        >
                          Description<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForIneligibleAmount, ...styles.rightAlignedText }}>
                        <FormLabel tabIndex={0} htmlFor='ineligible-amount-box' sx={styles.tableHeaderText}>
                          Ineligible Amount<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForAction, ...styles.centerAlignedText }}>
                        <FormLabel tabIndex={0} sx={styles.tableHeaderText}>
                          Action
                        </FormLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <FieldArray name='ineligibles'>
                    {({ push, remove }) => (
                      <TableBody sx={styles.tableBody}>
                        {formik.values.ineligibles.map((ineligible, index) => {
                          return (
                            <TableRow sx={styles.tableRow} key={ineligible.recordId} aria-label={`${ineligible.description}`}>
                              <TableCell sx={styles.tableCell}>
                                <Field
                                  tabIndex={0}
                                  id='date-created-box'
                                  aria-label={`${formik.values.ineligibles[index].dateCreated}`}
                                  name={`ineligibles[${index}].dateCreated`}
                                  as={TextField}
                                  sx={styles.textField}
                                  disabled
                                  variant='outlined'
                                  inputProps={{ sx: styles.centerAlignedText, 'aria-label': `${formik.values.ineligibles[index].dateCreated}`, }}
                                  {...getDisplayedError(formik.errors, formik.touched, 'dateCreated', index)}
                                />
                              </TableCell>
                              <TableCell sx={styles.tableCell}>
                                <Field
                                  disabled={isFieldDisabled(index)}
                                  tabIndex={getFieldTabIndex(index)}
                                  id='description-box'
                                  aria-label={'Description Field'}
                                  name={`ineligibles[${index}].description`}
                                  as={TextField}
                                  sx={styles.textField}
                                  variant='outlined'
                                  onChange={(event: any) => { formik.handleChange(event); }}
                                  inputProps={{ 'aria-label': 'Description' }}
                                  {...getDisplayedError(formik.errors, formik.touched, 'description', index)}
                                  onBlur={(event: any) => trimOnBlur(event, formik, `ineligibles[${index}].description`, formik.values.ineligibles[index].description)}
                                />
                              </TableCell>
                              <TableCell sx={styles.tableCell}>
                                <Field
                                  disabled={isFieldDisabled(index)}
                                  tabIndex={getFieldTabIndex(index)}
                                  id='ineligible-amount-box'
                                  aria-label={'Ineligible Amount Field'}
                                  name={`ineligibles[${index}].ineligibleAmount`}
                                  as={TextField}
                                  sx={styles.textField}
                                  variant='outlined'
                                  onChange={(event: any) => { formik.handleChange(event); }}
                                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => formik.handleBlur(event)}
                                  InputProps={{ inputComponent: CurrencyFormat as any }}
                                  inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Ineligible Amount' }}
                                  {...getDisplayedError(formik.errors, formik.touched, 'ineligibleAmount', index)}
                                />
                              </TableCell>
                              <TableCell align='center' sx={styles.actionTableCell}>
                              {getAction(ineligible, formik, index)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {/* rows that adds new form rows */}
                        {(allowAddingRow && selectedTabIndex === TABS.findIndex(tab => tab.label === 'Active') && addIA ) &&
                        (getRowAdder(formik, push))}
                        <ConfirmModal
                          open={deleteModal.isOpen}
                          onClose={() => { setDeleteModal({ ...deleteModal, isOpen: false }); }}
                          onConfirm={() => { handleConfirmDelete(formik.values.ineligibles, remove); }}
                          title={`Delete ${deleteModal.deleteName}`}
                          description='Are you sure you want to delete this item?'
                          errorButton
                          yesButtonText='Delete'
                          noButtonText='Cancel'
                        />
                      </TableBody>
                    )}
                  </FieldArray>
                </Table>
              </TableContainer>
            </Box>
            {getActionButtons(formik)}
            <Toaster
              open={isToasterOpen}
              message={toasterMessage}
              severity={toasterSeverity}
              onCloseChange={() => setIsToasterOpen(false)}
            />
            <ConfirmModal
              open={showPrompt}
              onConfirm={() => setShowPrompt(false)}
              onClose={handleDiscardChanges}
              onButtonClose={() => setShowPrompt(false)}
              promptChecker={true}
              title={`Confirm Navigation`}
              description={`You have unsaved changes. Are you sure you want to leave this page?`}
              yesButtonText="Keep Editing"
              noButtonText="Discard Changes"
              confirmOnly
            />
            <ConfirmModal
              title={modalTitle}
              description={modalDescription}
              open={isModalOpen}
              alignment='left'
              onClose={closeModal}
              onConfirm={handleConfirm}
              errorButton={isDeleteModal}
              noButtonText={'Cancel'}
              yesButtonText={modalConfirmText}
            />
          </Form>
        )
      }
    </Formik >
  );
};

export default IneligibleAdjustmentsTable;
