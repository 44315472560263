import React, { createContext, Dispatch, useMemo, useReducer, useState } from "react";
import { DrawerActions } from "../actions/drawerActions";
import drawerReducer, { DrawerState, initialState } from '../reducer/drawerReducer';

export interface DrawerContextInterface {
  state     : DrawerState;
  dispatch  : Dispatch<DrawerActions>;

  canViewClients: boolean,
  setCanViewClients: (value : boolean) => void,

  canViewAssignedClients: boolean,
  setCanViewAssignedClients: (value : boolean) => void,

  canViewClientSettings: boolean,
  setCanViewClientSettings: (value : boolean) => void,

  canViewIneligibleSettings: boolean,
  setCanViewIneligibleSettings: (value : boolean) => void,

  canViewFileImport: boolean,
  setCanViewFileImport: (value : boolean) => void,

  canViewReports: boolean,
  setCanViewReports: (value : boolean) => void,

  canViewLenderSettings: boolean,
  setCanViewLenderSettings: (value : boolean) => void,

  canViewUserRoles: boolean,
  setCanViewUserRoles: (value : boolean) => void,
}

export const DrawerContext = createContext<DrawerContextInterface>({
  state     : initialState,
  dispatch  : () => undefined,
  canViewClients: false,
  setCanViewClients: (canViewClients) => undefined,
  canViewAssignedClients: false,
  setCanViewAssignedClients: (canViewAssignedClients) => undefined,
  canViewClientSettings: false,
  setCanViewClientSettings: (canViewClientSettings) => undefined,
  canViewIneligibleSettings: false,
  setCanViewIneligibleSettings: (canViewIneligibleSettings) => undefined,
  canViewFileImport: false,
  setCanViewFileImport: (canViewFileImport) => undefined,
  canViewReports: false,
  setCanViewReports: (canViewReports) => undefined,
  canViewLenderSettings: false,
  setCanViewLenderSettings: (canViewLenderSettings) => undefined,
  canViewUserRoles: false,
  setCanViewUserRoles: (canViewUserRoles) => undefined
});

export interface LayoutProps  { 
  children: React.ReactNode;
}

const DrawerContextProvider = (props: LayoutProps) => {
  const children = props.children;

  const [state, dispatch] = useReducer(drawerReducer, initialState);
  const [ canViewClients, setCanViewClients ] = useState<boolean>(false);
	const [ canViewClientSettings, setCanViewClientSettings ] = useState<boolean>(false);
	const [ canViewIneligibleSettings, setCanViewIneligibleSettings ] = useState<boolean>(false);
	const [ canViewFileImport, setCanViewFileImport ] = useState<boolean>(false);
	const [ canViewReports, setCanViewReports ] = useState<boolean>(false);
	const [ canViewLenderSettings, setCanViewLenderSettings ] = useState<boolean>(false);
	const [ canViewUserRoles, setCanViewUserRoles ] = useState<boolean>(false);
  const [ canViewAssignedClients, setCanViewAssignedClients ] = useState<boolean>(false);

  const value = useMemo(() => ({
    state, 
    dispatch,
    canViewClients,
    setCanViewClients,
    canViewAssignedClients,
    setCanViewAssignedClients,
    canViewClientSettings,
    setCanViewClientSettings,
    canViewFileImport,
    setCanViewFileImport,
    canViewIneligibleSettings,
    setCanViewIneligibleSettings,
    canViewLenderSettings,
    setCanViewLenderSettings,
    canViewReports,
    setCanViewReports,
    canViewUserRoles,
    setCanViewUserRoles}), [
      state, 
      dispatch,
      canViewClients,
      setCanViewClients,
      canViewAssignedClients,
      setCanViewAssignedClients,
      canViewClientSettings,
      setCanViewClientSettings,
      canViewFileImport,
      setCanViewFileImport,
      canViewIneligibleSettings,
      setCanViewIneligibleSettings,
      canViewLenderSettings,
      setCanViewLenderSettings,
      canViewReports,
      setCanViewReports,
      canViewUserRoles,
      setCanViewUserRoles
    ])

  return (
    <DrawerContext.Provider value={value}>
      {
        children
      }
    </DrawerContext.Provider>
  )
}

export default DrawerContextProvider;