import colors from "../../../../../../../../utility/colors";

const styles = {
  leftColSpacer: {
    width: '10%',
    border: 'none',
    padding: 0,
  },
  rightColSpacer: {
    width: '26%',
    border: 'none',
    padding: 0,
  },
  tableCell: {
    border: 0,
    paddingX: 0,
    paddingY: '0.2rem',
    width: '16%',
    backgroundColor: colors.NEUTRAL,
    paddingRight: '5rem'
  },
  tableCellNewIneligibleAmount: {
    paddingRight: '42px'
  },
  tableCellNewIneligibleAmountWithScroll: {
    paddingRight: '50px'
  }
};

export default styles;