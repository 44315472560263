import { createContext, useMemo, useRef, useState, useEffect } from 'react';
import { IARCollateral, IARCustomer, IBBPeriod, IClient } from '../interfaces';
import { IIneligibleSettingsContext, IIneligibleSettingDetail, FieldList, FieldTable, FieldValue, UserDefinedFieldList, UNLOADED, IUPCIneligibleSettingDetail, IIneligibleSettingsPermissions } from '../interfaces/ineligibleSettingInterface';
import { LayoutProps } from './authContext';
import { AlertColor } from '@mui/material';
import { NON_EXISTING } from '../utility/constants';
import { ICurrency } from '../interfaces/multiCurrencyInterface';

export const IneligibleSettingsContext = createContext<IIneligibleSettingsContext | null >(null);

const IneligibleSettingsProvider = (props: LayoutProps) => {
  const { children }                                                          = props;

  /** SELECTED STATE VALUES */
  const [lastSelectedClient, setLastSelectedClient]                           = useState<IClient | null>(null);
  const [selectedCollateral, setSelectedCollateral]                           = useState<IARCollateral | null>(null);
  const [selectedAsOfDate, setSelectedAsOfDate]                               = useState<IBBPeriod | null>(null);
  const [selectedIneligibleIndex, setSelectedIneligibleIndex]                 = useState<number>(NON_EXISTING);

  /** INITIAL FORMIK VALUES */
  const [ineligibleSettingDetails, setIneligibleSettingDetails]               = useState<IIneligibleSettingDetail[] | IUPCIneligibleSettingDetail[]>([]);
  /** FETCHED VALUES */
  const [fetchedIneligibleSettingDetails, setFetchedIneligibleSettingDetails] = useState<IIneligibleSettingDetail[]| IUPCIneligibleSettingDetail[]>([]);

  /** MISCELLANEOUS */
  const [asOfDates, setAsOfDates]                                             = useState<IBBPeriod[] | UNLOADED>('UNLOADED');
  const [mostRecentlyUpdatedAsOfDate, setMostRecentlyUpdatedAsOfDate]         = useState<IBBPeriod | null | UNLOADED>('UNLOADED');
  const [hasPaymentTerms, setHasPaymentTerms]                                 = useState<boolean | UNLOADED>('UNLOADED');
  const [currency, setCurrency]                                               = useState<ICurrency | UNLOADED>('UNLOADED');
  const [fieldTables, setFieldTables]                                         = useState<FieldTable[] | UNLOADED>('UNLOADED');
  const [fieldList, setFieldList]                                             = useState<FieldList[] | UNLOADED>('UNLOADED');
  const [fieldValues, setFieldValues]                                         = useState<{ [key: string]: FieldValue[] }>({});
  const [userDefinedFieldList, setUserDefinedFieldList]                       = useState<UserDefinedFieldList[] | UNLOADED>('UNLOADED');
  const [parentCustomers, setParentCustomers]                                 = useState<IARCustomer[] | UNLOADED>('UNLOADED');

  const [isHidingInactiveIneligibles, setIsHidingInactiveIneligibles]         = useState<boolean>(true);
  const [hideInactiveIneligiblesToggle, setHideInactiveIneligiblesToggle]     = useState<boolean>(true);
  const [toaster, setToaster]                                                 = useState<{isOpen: boolean; message: string; severity: AlertColor}>({ isOpen: false, message: '', severity: 'success' });
  const [isFormikDirty, setIsFormikDirty]                                     = useState(false);
  const [isConfirmNavigationModalOpen, setIsConfirmNavigationModalOpen]       = useState(false);
  const [
    isInvalidIneligibleSettingsMessageModalOpen,
    setIsInvalidIneligibleSettingsMessageModalOpen
  ]                                                                           = useState(false);
  const [isIneligibleDetailsLoading, setIsIneligibleDetailsLoading]           = useState(true);
  const ineligibleListContainerRef                                            = useRef<HTMLInputElement>(null);

  /**  PERMISSIONS */
  const [permissions, setPermissions]                                         = useState<IIneligibleSettingsPermissions | UNLOADED>('UNLOADED');

  const value = useMemo(() => ({
    lastSelectedClient, setLastSelectedClient,
    selectedCollateral, setSelectedCollateral,
    selectedAsOfDate, setSelectedAsOfDate,
    selectedIneligibleIndex, setSelectedIneligibleIndex,
    ineligibleSettingDetails, setIneligibleSettingDetails,
    fetchedIneligibleSettingDetails, setFetchedIneligibleSettingDetails,
    asOfDates, setAsOfDates,
    mostRecentlyUpdatedAsOfDate, setMostRecentlyUpdatedAsOfDate,
    hasPaymentTerms, setHasPaymentTerms,
    currency, setCurrency,
    fieldTables, setFieldTables,
    fieldList, setFieldList,
    fieldValues, setFieldValues,
    userDefinedFieldList, setUserDefinedFieldList,
    parentCustomers, setParentCustomers,
    isHidingInactiveIneligibles, setIsHidingInactiveIneligibles,
    hideInactiveIneligiblesToggle, setHideInactiveIneligiblesToggle,
    toaster, setToaster,
    isFormikDirty, setIsFormikDirty,
    isConfirmNavigationModalOpen, setIsConfirmNavigationModalOpen,
    isInvalidIneligibleSettingsMessageModalOpen, setIsInvalidIneligibleSettingsMessageModalOpen,
    isIneligibleDetailsLoading, setIsIneligibleDetailsLoading,
    ineligibleListContainerRef,
    permissions, setPermissions
  }), [
    lastSelectedClient, setLastSelectedClient,
    selectedCollateral, setSelectedCollateral,
    selectedAsOfDate, setSelectedAsOfDate,
    selectedIneligibleIndex, setSelectedIneligibleIndex,
    ineligibleSettingDetails, setIneligibleSettingDetails,
    fetchedIneligibleSettingDetails, setFetchedIneligibleSettingDetails,
    asOfDates, setAsOfDates,
    mostRecentlyUpdatedAsOfDate, setMostRecentlyUpdatedAsOfDate,
    hasPaymentTerms, setHasPaymentTerms,
    currency, setCurrency,
    fieldTables, setFieldTables,
    fieldList, setFieldList,
    fieldValues, setFieldValues,
    userDefinedFieldList, setUserDefinedFieldList,
    parentCustomers, setParentCustomers,
    isHidingInactiveIneligibles, setIsHidingInactiveIneligibles,
    hideInactiveIneligiblesToggle, setHideInactiveIneligiblesToggle,
    toaster, setToaster,
    isFormikDirty, setIsFormikDirty,
    isConfirmNavigationModalOpen, setIsConfirmNavigationModalOpen,
    isInvalidIneligibleSettingsMessageModalOpen, setIsInvalidIneligibleSettingsMessageModalOpen,
    isIneligibleDetailsLoading, setIsIneligibleDetailsLoading,
    ineligibleListContainerRef,
    permissions, setPermissions
  ])

  useEffect(() => {
    setIsHidingInactiveIneligibles(hideInactiveIneligiblesToggle);
  }, [hideInactiveIneligiblesToggle])

  return (
    <IneligibleSettingsContext.Provider
      value={value}
    >
      {children}
    </IneligibleSettingsContext.Provider>
  );
};

export default IneligibleSettingsProvider;
