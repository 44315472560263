import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from "react";
import styles from "./styles";
import { IModalProps, IParentWithChild } from "../../../../interfaces/parentChildSetupInterface";

interface IProps {
  parent: IParentWithChild
  modalProps: IModalProps
  setModalProps: (modalProps: IModalProps) => void
  setFocusedItemId: React.Dispatch<React.SetStateAction<number | undefined>>
  setFocusedItemTempId: React.Dispatch<React.SetStateAction<number | undefined>>
}

/**
 * This component renders a sub menu on each parent.
 * 
 * @param param IProps
 */
const ParentMenu = ({parent, modalProps, setModalProps, setFocusedItemId, setFocusedItemTempId}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const [isClicked, setIsClicked] = useState<boolean>(false)

  /**
   * This useEffect sets the props values from props. It triggers after the achor for the sub menu was set.
   */
  useEffect(() => {
    if(!anchorEl && isClicked){
      setFocusedItemId(parent.id)
      setFocusedItemTempId(parent.tempId)
      setIsClicked(false)
    }
  }, [anchorEl, isClicked])

  return (
    <>
      <IconButton
        aria-label="Actions icon"
        aria-controls="long-menu"
        aria-haspopup="true"
        data-testId={`parent-menu-button-${parent.id}`}
        onClick={(event) => {
          event.stopPropagation() 
          setAnchorEl(event.currentTarget)}}
        sx={styles.iconButtonProperties}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        data-testid={`parent-menu-${parent.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem 
          data-testid={`parent-menu-item-rename`} 
          onClick={(e) => {
            e.stopPropagation()
            setAnchorEl(null)
            setIsClicked(true)
          }}>Rename</MenuItem>
        {parent.isUnlinked ? (
          <MenuItem 
            data-testid={`parent-menu-item-delete`}
            onClick={(e) => {
            e.stopPropagation()
            setModalProps({
              ...modalProps,
              tempId: parent.tempId,
              isParent: true,
              isOpen: true,
              parentId: parent.id,
              parentName: parent.name,
              children: parent.children
            })
            setAnchorEl(null)
          }}>Delete</MenuItem>
        ) : (
          <Tooltip title={'Cannot delete Parent Customer with associated Vendor/s'}>
            <span>
              <MenuItem 
                data-testid={`parent-menu-item-delete`}
                disabled
              >
                Delete
              </MenuItem>
            </span>
          </Tooltip>
        )}
      </Menu>
    </>
  )
}

export default ParentMenu