import React, { createContext, Dispatch, useEffect, useMemo, useReducer } from "react";
import { setLocalStorageItem } from "../utility/helper";
import bankLogoReducer, { BankLogoState, initialState } from "../reducer/bankLogoReducer";
import { BankLogoActions } from "../actions/bankLogoActions";

export interface BankLogoContextInterface {
  state     : BankLogoState;
  dispatch  : Dispatch<BankLogoActions>;
}

export const BankLogoContext = createContext<BankLogoContextInterface>({
  state     : initialState,
  dispatch  : () => undefined
});

export interface LayoutProps  { 
  children: React.ReactNode;
}

const BankLogoContextProvider = (props: LayoutProps) => {
  const children = props.children;

  const [state, dispatch] = useReducer(bankLogoReducer, initialState);

  useEffect(() => {
    if (state.logoDataUrl) {      
      setLocalStorageItem('logoDataUrl', state.logoDataUrl);
    } else {
      localStorage.removeItem('logoDataUrl');    
    }
  }, [state.logoDataUrl]);

  const value = useMemo(() => ({state, dispatch}), [state, dispatch]);

  return (
    <BankLogoContext.Provider value={value}>
      {
        children
      }
    </BankLogoContext.Provider>
  )
}

export default BankLogoContextProvider;