import { Box, Typography, Container } from '@mui/material';
import styles from './styles';
import { FC } from 'react';

/**
 * Component for showing the contents of the header in the clients page.
 */
const ClientsHeader: FC = () => {
  return (
    <>
      <Box sx={styles.titleContainer}>
        <Typography data-testid='clientsHeader' variant='h6' component='h3' sx={styles.title} tabIndex={0}>
          Clients
        </Typography>
      </Box>

      <Container maxWidth='xl'>
        <Typography variant='h6' component='h5' sx={styles.subtitle} tabIndex={0}>
          Portfolio Summary
        </Typography>
      </Container>
    </>
  );
};

export default ClientsHeader;
