import ReactDOM from 'react-dom';
import { useContext, useEffect, useCallback } from 'react';
import type { Blocker, History } from 'history';
import {
  UNSAFE_NavigationContext as NavigationContext,
  Navigator,
} from 'react-router-dom';
import ConfirmModal from '../components/modals/confirm-modal';
 
export function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as Navigator & Pick<History, 'block'>).block(
      tx => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            unblock();
            tx.retry();
          },
        };

        blocker(autoUnblockingTx);
      }
    );

    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message: string, when = true) {
  const blocker = useCallback(
    (tx : any) => {
      const element = document.createElement('div');
      element.setAttribute('id', 'prompt-dialog-container');
      element.setAttribute('aria-hidden', 'true');

      const closePrompt = (state: boolean) => {
        if (element) {
          ReactDOM.unmountComponentAtNode(element);
        }
        if (!state) {
          document.body.removeChild(element);
        } else {
          tx.retry();
        }
      };

      document.body.appendChild(element);
      ReactDOM.render(
        <ConfirmModal
          open={!!message}
          onClose={() => closePrompt(true)}
          onConfirm={() => closePrompt(false)}
          onButtonClose={() => closePrompt(false)}
          title={'Confirm Navigation'}
          description={message}
          yesButtonText='Keep Editing'
          noButtonText='Discard Changes'
          promptChecker={true}
          confirmOnly
        />,
        element
      );
    },
    [message]
  );

  useBlocker(blocker, when);
}
 