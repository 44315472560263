import { forwardRef, useContext, useEffect, useMemo } from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { Form, FormikErrors, FormikProps, getIn } from 'formik';
import { Box } from '@mui/material';
import { IARCollateral, IClient, ICustomProps } from '../../../interfaces';
import { IFormikValuesForRuleAndOverridesPerSetting, IIneligibleCondition, IIneligibleRuleConditionGroup, IIneligibleRuleOverride, IIneligibleSettingsContext, IIneligibleSettingDetail, IFormikValuesForUPCRuleAndOverridesPerSetting, IneligibleRuleOverrideAPI, IneligibleRuleConditionGroupAPI, IIneligibleSettingAPI, IUPCIneligibleSettingDetail  } from '../../../interfaces/ineligibleSettingInterface';
import { IneligibleSettingsContext } from '../../../context/ineligibleSettingsContext';
import NoDataPlaceholder from '../../common/no-data-placeholder';
import IneligibleSettingsTopErrorMessage from '../top-error-message';
import IneligibleRule from '../ineligible-rule';
import IneligibleRuleOverride from '../ineligible-rule-override';
import styles from './styles';
import { NON_EXISTING } from '../../../utility/constants';
import { checkHasPaymentTerms, checkHasPaymentTermsByParentClient, getIneligibleRule, getIneligibleRuleCondition, getIneligibleRuleConditionGroups, getIneligibleRuleOverrides, getUPCIneligibleRule } from '../../../api/ineligible-settings';
import { initialCondition, initialGroup } from '../form-container';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { getDefaultIneligibleRule as getDefaultARIneligibleRule } from '../form-container/ar-form-container';
import { getDefaultIneligibleRule as getDefaultUPCIneligibleRule } from '../form-container/upc-form-container';

interface IIneligibleSettingsDetailsProps {
  formik: FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>;
}

/**
 * This function customizes the rendering of an input field for percentage values.
 * @param props The component's props.
 * @returns A custom NumberFormat component for percentage values.
 */
export const PercentFormat = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({ target: { value: values.value, name: other.name, }, });
        }}
        thousandSeparator
        type='tel'
        decimalScale={2}
        fixedDecimalScale={false}
        allowNegative
        suffix='%'
        isAllowed={values => values.floatValue === undefined || (values.floatValue >= -100.00 && values.floatValue <= 100.00)}
      />
    );
  }
);

/**
 * This function customizes the rendering of an input field for decimal values with a currency symbol.
 * @param props The component's props.
 * @returns A custom NumberFormat component for decimal values with a currency symbol.
 */
export const DecimalFormat = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({ target: { value: values.value, name: other.name, }, });
        }}
        thousandSeparator
        type='tel'
        decimalScale={2}
        fixedDecimalScale={false}
        allowNegative={false}
        prefix='$'
        isAllowed={values => values.floatValue === undefined || values.floatValue <= 9999999999}
      />
    );
  }
);

export const DecimalFormatWithNoPrefix = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({ target: { value: values.value, name: other.name, }, });
        }}
        thousandSeparator
        type='tel'
        decimalScale={2}
        fixedDecimalScale={false}
        allowNegative={false}
        isAllowed={values => values.floatValue === undefined || values.floatValue <= 9999999999}
      />
    );
  }
);

/**
 * This function fetches ineligible rule override details.
 * @param ineligibleRuleOverrides An array of ineligible rule overrides.
 * @returns An array of ineligible rule overrides with additional details.
 */
export const getIneligibleRuleOverrideDetails = async (ineligibleRuleOverrides: IneligibleRuleOverrideAPI[]) => {
  return await Promise.all(ineligibleRuleOverrides.map(async ineligibleRuleOverride => {
    const ineligibleRuleConditionGroups = await getIneligibleRuleConditionGroups(ineligibleRuleOverride);
    if (ineligibleRuleConditionGroups.length === 0) {
      return { ...ineligibleRuleOverride, ineligibleRuleConditionGroup: initialGroup };
    }
    const ineligibleRuleConditionGroupDetails = await getIneligibleRuleConditionGroupDetails(ineligibleRuleConditionGroups);
    return { ...ineligibleRuleOverride, ineligibleRuleConditionGroup: ineligibleRuleConditionGroupDetails };
  })) as IIneligibleRuleOverride[];
};

/**
 * This function fetches ineligible rule condition group details.
 * @param ineligibleRuleConditionGroups An array of ineligible rule condition groups.
 * @returns An array of ineligible rule condition groups with additional details.
 */
const getIneligibleRuleConditionGroupDetails = (ineligibleRuleConditionGroups: IneligibleRuleConditionGroupAPI[]) => {
  return Promise.all(ineligibleRuleConditionGroups.map(async conditionGroup => {
    const ineligibleRuleCondition = await getIneligibleRuleCondition(conditionGroup);
    if (ineligibleRuleCondition.length === 0) { 
      return { ...conditionGroup, ineligibleRuleCondition: [initialCondition] }; 
    }
    return { ...conditionGroup, ineligibleRuleCondition: ineligibleRuleCondition };
  }));
};

/**
 * This function gets the displayed error status for a specific field.
 * @param formik The Formik props.
 * @param fieldName The field name to check for errors.
 * @returns An object indicating an error status or null.
 */
export const getDisplayedError = (formik: FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>, fieldName: string) => {
  const fieldError = getIn(formik.errors, fieldName);
  if (fieldError) { return { error: true }; }
  return null;
};

/**
 * This function checks if there are override errors.
 * @param formikErrorForIneligibleSettingDetail Formik errors for an ineligible setting detail.
 * @returns True if there are override errors, false otherwise.
 */
export const checkOverrideErrors = (formikErrorForIneligibleSettingDetail: FormikErrors<IIneligibleSettingDetail>) => {
  let hasIneligibleRuleOverrideErrors = false;
  if (formikErrorForIneligibleSettingDetail?.ineligibleRuleOverrides !== undefined && formikErrorForIneligibleSettingDetail.ineligibleRuleOverrides.length > 0) {
    const overrideErrors = formikErrorForIneligibleSettingDetail.ineligibleRuleOverrides as unknown as FormikErrors<IIneligibleRuleOverride>[];
    hasIneligibleRuleOverrideErrors = overrideErrors.some(overrideError => {
      const filteredOverrideKeys = Object.keys(overrideError ?? {}).filter(key => key !== 'ineligibleRuleConditionGroup');
      if (filteredOverrideKeys.length > 0) { return true; }
      const conditionGroupErrors = overrideError?.ineligibleRuleConditionGroup as FormikErrors<IIneligibleRuleConditionGroup>[] ?? [];
      return conditionGroupErrors.some(conditionGroupError => {
        const conditionErrors = conditionGroupError?.ineligibleRuleCondition as FormikErrors<IIneligibleCondition>[] ?? [];
        return conditionErrors.some(conditionError => Object.keys(conditionError ?? {}).length > 0);
      });
    });
  }

  return hasIneligibleRuleOverrideErrors;
};

/**
 * Component displays main content below collateral header of ineligible settings.
 * @param props The component's props.
 * @returns The IneligibleSettingsDetails component.
 */
const IneligibleSettingsDetails: React.FC<IIneligibleSettingsDetailsProps> = (props) => {
  const { selectedClient } = useContext(SelectedClientContext);
  const {
    selectedCollateral,
    selectedIneligibleIndex,
    fetchedIneligibleSettingDetails,
    setFetchedIneligibleSettingDetails,
    ineligibleListContainerRef,
    isIneligibleDetailsLoading,
    hasPaymentTerms,
    setHasPaymentTerms,
  }                                       = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const selectedIneligible = useMemo(() => {
    if (isIneligibleDetailsLoading || selectedIneligibleIndex === NON_EXISTING) { return null; }
    return props.formik.values.ineligibleSettingDetails[selectedIneligibleIndex].ineligibleSetting ;
  }, [isIneligibleDetailsLoading, props.formik.values.ineligibleSettingDetails, selectedIneligibleIndex]);

  const isSelectedClientUPC = () => (selectedClient as IClient).parentClient;

  useEffect(() => {
    loadDependenciesForSelectedIneligible();
  }, [isIneligibleDetailsLoading, selectedIneligible]);

  const loadDependenciesForSelectedIneligible = async () => {
    if (isIneligibleDetailsLoading || selectedIneligible === null) { return; }

    const isDelinquentSelected = selectedIneligible.code === 'DLQ';
    if (isDelinquentSelected && hasPaymentTerms === 'UNLOADED') {
      const hasPaymentTermsFromAPI = isSelectedClientUPC()
        ? await checkHasPaymentTermsByParentClient((selectedClient as IClient).recordId as number)
        : await checkHasPaymentTerms(selectedCollateral as IARCollateral);
      setHasPaymentTerms(hasPaymentTermsFromAPI);
      await loadRuleAndOverrideForSelectedIneligible(hasPaymentTermsFromAPI);
      await props.formik.validateForm();
      return;
    }
    await loadRuleAndOverrideForSelectedIneligible();
    await props.formik.validateForm();
  };

  const loadRuleAndOverrideForSelectedIneligible = async (hasPaymentTerms: boolean = true) => {
    const fetchedIneligibleSettingDetailIndex = fetchedIneligibleSettingDetails.findIndex(detail => detail.ineligibleSetting.code === (selectedIneligible as IIneligibleSettingAPI).code);
    const { ineligibleSetting, ineligibleRule, ineligibleRuleOverrides } = fetchedIneligibleSettingDetails[fetchedIneligibleSettingDetailIndex] as IIneligibleSettingDetail | IUPCIneligibleSettingDetail;
    const isIneligibleDevoidOfOverrides = ['AC', 'CONTRA', 'RFV'].includes(ineligibleSetting.code);
    const isRuleUnloadedOnFormik = props.formik.values.ineligibleSettingDetails[selectedIneligibleIndex].ineligibleRule === undefined;
    const isOverrideUnloadedOnFormik = props.formik.values.ineligibleSettingDetails[selectedIneligibleIndex].ineligibleRuleOverrides === undefined;

    const fetchedIneligibleRule = ineligibleRule ?? await (
      isSelectedClientUPC()
        ? getUPCIneligibleRule(selectedClient as IClient, ineligibleSetting)
        : getIneligibleRule(selectedCollateral as IARCollateral, ineligibleSetting as IIneligibleSettingAPI)
    );

    if (fetchedIneligibleRule === null) {
      const defaultIneligibleRule = isSelectedClientUPC()
        ? getDefaultUPCIneligibleRule(ineligibleSetting, hasPaymentTerms)
        : getDefaultARIneligibleRule(selectedCollateral as IARCollateral, ineligibleSetting as IIneligibleSettingAPI, hasPaymentTerms);
      const updatedIneligibleSettingDetails = fetchedIneligibleSettingDetails.map((detail) => {
        if (detail.ineligibleSetting.code !== ineligibleSetting.code) { return detail; }
        return { ineligibleSetting, ineligibleRule: defaultIneligibleRule, ...(!isIneligibleDevoidOfOverrides && { ineligibleRuleOverrides: [] }) };
      });

      setFetchedIneligibleSettingDetails(updatedIneligibleSettingDetails);
      isRuleUnloadedOnFormik && props.formik.setFieldValue(`ineligibleSettingDetails[${selectedIneligibleIndex}].ineligibleRule`, defaultIneligibleRule);
      (!isIneligibleDevoidOfOverrides && isOverrideUnloadedOnFormik) && props.formik.setFieldValue(`ineligibleSettingDetails[${selectedIneligibleIndex}].ineligibleRuleOverrides`, []);
      return;
    }

    const fetchedIneligibleRuleOverrides = !isIneligibleDevoidOfOverrides ? (ineligibleRuleOverrides ?? await getIneligibleRuleOverrides(fetchedIneligibleRule)) : [];
    const ruleOverrideDetails = !isIneligibleDevoidOfOverrides ? (ineligibleRuleOverrides ?? await getIneligibleRuleOverrideDetails(fetchedIneligibleRuleOverrides)) : [];

    const updatedIneligibleSettingDetails = fetchedIneligibleSettingDetails.map((detail) => {
      if (detail.ineligibleSetting.code !== ineligibleSetting.code) { return detail; }
      return { ineligibleSetting, ineligibleRule: fetchedIneligibleRule, ...(!isIneligibleDevoidOfOverrides && { ineligibleRuleOverrides: ruleOverrideDetails }) };
    });

    setFetchedIneligibleSettingDetails(updatedIneligibleSettingDetails);
    isRuleUnloadedOnFormik && props.formik.setFieldValue(`ineligibleSettingDetails[${selectedIneligibleIndex}].ineligibleRule`, fetchedIneligibleRule);
    (!isIneligibleDevoidOfOverrides && isOverrideUnloadedOnFormik) && props.formik.setFieldValue(`ineligibleSettingDetails[${selectedIneligibleIndex}].ineligibleRuleOverrides`, ruleOverrideDetails);
  };

   /**
   * This function checks if the top error message should be displayed.
   * @returns True if the top error message should be displayed, otherwise false.
   */
  const isTopErrorMessageDisplayed = () => {
    if (props.formik.errors.ineligibleSettingDetails === undefined || props.formik.errors.ineligibleSettingDetails.length === 0) { return false; }
    const formikErrors = props.formik.errors.ineligibleSettingDetails as FormikErrors<IIneligibleSettingDetail>[];
    if (selectedIneligible === null) { return false; }
    const originalSettingIndex = props.formik.values.ineligibleSettingDetails.findIndex(item => item.ineligibleSetting.code === selectedIneligible.code);
    const selectedFormikSettingError = formikErrors[originalSettingIndex];
    const hasIneligibleRuleErrors = checkRuleErrors(selectedFormikSettingError);
    const hasIneligibleRuleOverrideErrors = checkOverrideErrors(selectedFormikSettingError);
    if (selectedFormikSettingError === undefined || Object.keys(selectedFormikSettingError).length === 0) { return false; }
    return hasIneligibleRuleErrors || hasIneligibleRuleOverrideErrors;
  };

  /**
   * This function checks if there are errors related to ineligible rules.
   * @param selectedFormikSettingError The Formik error object.
   * @returns True if there are errors related to ineligible rules, otherwise false.
   */
  const checkRuleErrors = (selectedFormikSettingError: FormikErrors<IIneligibleSettingDetail>) => {
    return selectedFormikSettingError?.ineligibleRule !== undefined && Object.keys(selectedFormikSettingError.ineligibleRule).length > 0;
  };

  /**
   * This function gets the length of the ineligible list container.
   * @returns The height value for the container.
   */
  const getLengthOfIneligibleList = () => {
    if(ineligibleListContainerRef.current === null) {return '75vh'}
    return ineligibleListContainerRef.current.clientHeight + 17;
  };

  /**
   * This function checks if the ineligible setting detail is empty.
   * @returns True if the ineligible setting detail is empty, otherwise false.
   */
  const isIneligibleSettingDetailEmpty = () => {
    if (isIneligibleDetailsLoading) { return false; } /* do not show empty state while loading */
    return selectedIneligibleIndex === NON_EXISTING;
  };

  return (
    <Form style={styles.form}>
      <Box sx={styles.detailsBox}>
        <Box sx={{ maxHeight:getLengthOfIneligibleList() }}>
          <IneligibleSettingsTopErrorMessage isDisplayed={isTopErrorMessageDisplayed()} />
          {(isIneligibleSettingDetailEmpty()) ?
            <NoDataPlaceholder messageContainerStyle={styles.noDataPlaceHolderContainer} messageTextStyle={styles.noDataPlaceHolderText} /> :
            (
              <>
                <IneligibleRule formik={props.formik} />
                <IneligibleRuleOverride formik={props.formik} />
              </>
            )
          }
        </Box>
      </Box>
    </Form>
  );
};

export default IneligibleSettingsDetails;
