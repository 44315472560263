import { useContext, useEffect, useRef } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import type { History, Blocker, Transition } from 'history';
import { useMediaQuery, useTheme } from '@mui/material';

export const useBlocker = (blocker: Blocker, when = true): void => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

export const useIsFirstRender = () => {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
};

export const useCalendarFieldBreakpoints = () => {
  const theme = useTheme();
  const lg    = useMediaQuery(theme.breakpoints.between('w1199', 'w1379'));
  const md    = useMediaQuery(theme.breakpoints.between('w599', 'w1058'));
  const sm    = useMediaQuery(theme.breakpoints.down('w340'));
  return { lg, md, sm };
};