import * as Yup from 'yup';

const noWhiteSpaceStartAndEnd = /^\S+(\s\S+)*$/;

const documentTypeSchema = Yup.object({
  documentType: Yup.string()
    .matches(
      noWhiteSpaceStartAndEnd,
      'Document Type must not start and/or end with a whitespace'
    ),
});
export default documentTypeSchema;