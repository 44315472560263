import { useEffect, useState, FC, useContext } from 'react';
import { Box, FormLabel, Table, TableCell, TableContainer, TableHead, TableRow, Button, Typography, AlertColor } from '@mui/material';
import styles from './styles';
import ineligibleSettingsForLenderSettingsSchema from '../../../schemas/ineligibleSettingsForLenderSettingsSchema';
import { API_DOMAIN, GET, NO_PERMISSION_MSG, PERMISSIONS, POST, PUT } from '../../../utility/constants';
import requests from '../../../service/requests';
import request from '../../../service/request';
import Toaster from '../../../components/toaster';
import { FieldArrayRenderProps, Form, Formik, FormikProps } from 'formik';
import { checkUserPermissions, getLocalStorageItem, getPermissionsOfUser, isObjectsEqual, parseBoolean } from '../../../utility/helper';
import { usePrompt } from '../../../utility/prompt';
import { IneligibleCodePageProps as Props, IIneligibleCode, IFormikValue, IResetFormForIneligibleCodes, DeleteModalState } from '../../../interfaces/ineligibleCodeInterface';
import LoadingBackdrop from '../../../components/common/loading-backdrop';
import DraggableForm from '../draggable-form';
import { LenderSettingsContext } from '../../../context/lenderSettingsContext';
import DisabledComponentsContainer from '../../../components/common/disabled-components-container';

/**
 * Component that represents the Lender Ineligible Codes Table.
 * It displays a table of ineligible codes with various actions.
 * It also handles form submission and displays notifications to the user.
 * @returns {JSX.Element} JSX element representing the LenderIneligibleCodesTable component.
 */
const LenderIneligibleCodesTable: FC<Props> = (props: Props) => {
  const { dirty, setDirty }                                             = props;
  const {
    setUsedIneligibleCodes,
    setCanAddIneligible,
    setCanUpdateIneligible,
    setCanDeleteIneligible,
    setCanUpdateIneligiblePriority
  }                                                                     = useContext(LenderSettingsContext);
  const [ineligibleCodes, setIneligibleCodes]                           = useState<IIneligibleCode[]>([]);
  const [isLoading, setIsLoading]                                       = useState(false);
  const [allowAddingRow, setAllowAddingRow]                             = useState(true);
  const [isToasterOpen, setIsToasterOpen]                               = useState(false);
  const [toasterMessage, setToasterMessage]                             = useState('');
  const [toasterSeverity, setToasterSeverity ]                          = useState<AlertColor>('success');
  const [deleteModal, setDeleteModal]                                   = useState<DeleteModalState>({ isOpen: false, isNew: false, deleteRecordId: '', deleteName: '', deleteIndex: -1 });
  const [formikValues, setFormikValues]                                 = useState<IFormikValue | null>(null);

  /**
   * This useEffect hook fetches ineligible codes and user permissions when the component mounts.
   */
  useEffect(() => {
    getIneligibleCodes();
    getPermissions();
  }, []);

  /**
   * This useEffect hook updates the state to allow or disallow adding rows based on form values.
   */
  useEffect(() => {
    if (formikValues === null || formikValues.ineligibleCodes === undefined) { return; }
    const { emptyFormikValues } = getSavedAndEmptyFormikValues(formikValues.ineligibleCodes);
    emptyFormikValues.length > 0 ? setAllowAddingRow(false) : setAllowAddingRow(true);
  }, [formikValues]);

  /**
   * This function gets user permissions and updates relevant state variables.
   */
  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(getLocalStorageItem('uid'));
    permissions.includes(PERMISSIONS.ADD_INELIGIBLE) ? setCanAddIneligible(true) : setCanAddIneligible(false);
    permissions.includes(PERMISSIONS.UPDATE_INELIGIBLE) ? setCanUpdateIneligible(true) : setCanUpdateIneligible(false);
    permissions.includes(PERMISSIONS.DELETE_INELIGIBLE) ? setCanDeleteIneligible(true) : setCanDeleteIneligible(false);
    permissions.includes(PERMISSIONS.UPDATE_INELIGIBLE_PRIORITY) ? setCanUpdateIneligiblePriority(true) : setCanUpdateIneligiblePriority(false);
  };

  /**
   * This function fetches editable ineligible codes.
   */
  const getEditableIneligibleCodes = async () => {
    const editableIneligibleCodes = (
      await request({
        url: `${API_DOMAIN}/ineligibleSetting/search/findEditableIneligibleCodes`,
        method: GET
      })
    ).data as string[];
    setUsedIneligibleCodes(editableIneligibleCodes);
  };

  /**
   * This function fetches ineligible codes and updates the component's state.
   */
  const getIneligibleCodes = async () => {
    try {
      setIsLoading(true);
      const { data } = await request({
        url: `${API_DOMAIN}/ineligibleCodes/search/findAllCurrent`,
        method: GET,
      });
      const ineligibleCodes: IIneligibleCode[] = data
        .map((row: IIneligibleCode) => ({
          recordId: row.recordId,
          code: row.code,
          ineligibleDescription: row.ineligibleDescription,
          sortOrder: row.sortOrder,
          isCurrent: row.isCurrent,
          visible: row.visible.toString(),
        }))
        .sort((a, b) => a.sortOrder - b.sortOrder)
      setDirty(false);
      setIneligibleCodes(_ => ineligibleCodes);
      await getEditableIneligibleCodes();
    } catch (error) { console.log('INELIGIBLE CODES GET ERROR: ', error); }
    finally { setIsLoading(false); }
  };

  /**
   * This function handles the deletion of an ineligible code.
   * @param recordId The ID of the record to be deleted.
   * @param isFetchingAfter Indicates if data should be fetched after deletion.
   */
  const deleteIneligibleCode = async (recordId: string, isFetchingAfter?: boolean) => {
    try {
      setIsLoading(true);
      const [deletedIneligibleSetting] = ineligibleCodes.filter(ineligibleCode => ineligibleCode.recordId.toString() === recordId);
      const deleteConfig = {
        url: `${API_DOMAIN}/ineligibleCodes`,
        method: PUT,
        data: [{
          recordId: deletedIneligibleSetting.recordId,
          code: deletedIneligibleSetting.code,
          ineligibleDescription: deletedIneligibleSetting.ineligibleDescription,
          sortOrder: deletedIneligibleSetting.sortOrder,
          isCurrent: 0,
          visible: parseBoolean(deletedIneligibleSetting.visible),
        }]
      };

      await request(deleteConfig);
      const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
      setToasterMessage(`${itemName} has been deleted`);
      setToasterSeverity('success');
      setIsToasterOpen(true);
      setIsLoading(false);
      isFetchingAfter && getIneligibleCodes();
      
    }
    catch (error) {
      console.log('INELIGIBLE CODES DELETE ERROR: ', error);
      setIsLoading(false);
    }
  };

  /**
   * This function handles the click event of the delete button.
   * @param values The form values.
   * @param index The index of the item to be deleted.
   */
  const handleDelete = (values: IFormikValue, index: number) => {
    const isIneligibleCodeToDeleteNew = values.ineligibleCodes[index].recordId === undefined;
    if (isIneligibleCodeToDeleteNew) {
      setDeleteModal({
        isOpen: true,
        isNew: isIneligibleCodeToDeleteNew,
        deleteRecordId: '',
        deleteName: values.ineligibleCodes[index].ineligibleDescription,
        deleteIndex: index,
      });
      return;
    }
    setDeleteModal({
      isOpen: true,
      isNew: isIneligibleCodeToDeleteNew,
      deleteRecordId: values.ineligibleCodes[index].recordId.toString(),
      deleteName: values.ineligibleCodes[index].ineligibleDescription,
      deleteIndex: index,
    });
  };

  /**
   * This function handles the confirmation of the delete action.
   * @param formikValues Formik values.
   * @param setFormikValues Function to set Formik values.
   * @param remove Function to remove an item.
   */
  const handleConfirmDelete = (formikValues: IIneligibleCode[], remove: (index: number) => void) => {
    if (deleteModal.isNew) {
      remove(deleteModal.deleteIndex);
      const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
      setToasterMessage(`${itemName} has been deleted`);
      setToasterSeverity('success');
      setIsToasterOpen(true);
    }

    const { hasUnsavedChanges, presentFormikValues } = getPresentFormikValues(formikValues);
    if (hasUnsavedChanges && deleteModal.isNew) {
      setFormikValues({ ineligibleCodes: presentFormikValues });
      return;
    }
    if (hasUnsavedChanges && !deleteModal.isNew) {
      deleteIneligibleCode(deleteModal.deleteRecordId);
      setFormikValues({ ineligibleCodes: presentFormikValues });;
      return;
    }
    if (!hasUnsavedChanges && !deleteModal.isNew) {
      deleteIneligibleCode(deleteModal.deleteRecordId, true);
      setFormikValues({ ineligibleCodes: presentFormikValues });
      return;
    }
    setIneligibleCodes(presentFormikValues);
  };

  /**
   * This function handles the cancel action.
   * @param resetForm Function to reset the form.
   */
  const handleCancel = (resetForm: IResetFormForIneligibleCodes) => {
    if (formikValues === null) { return; }
    const { savedFormikValues } = getSavedAndEmptyFormikValues(formikValues.ineligibleCodes);
    savedFormikValues.sort((a, b) => parseInt(a.sortOrder.toString()) - parseInt(b.sortOrder.toString()));
    setDirty(false);
    resetForm({ values: { ineligibleCodes: savedFormikValues } });
    setIneligibleCodes(savedFormikValues);
    setAllowAddingRow(true);
  };

  /**
   * This function handles the form submission.
   * @param ineligibleCodesToSave Ineligible codes to be saved.
   * @param setSubmitting Function to set the submitting state.
   */
  const handleSave = async (ineligibleCodesToSave : IIneligibleCode[], setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      setIsLoading(true);
      setSubmitting(true);

      const reorderedIneligibleCodes = ineligibleCodesToSave
        .map((ineligibleCode, index) => ({ ...ineligibleCode, sortOrder: index + 1, }));

      const ineligibleCodesToEdit = reorderedIneligibleCodes
        .filter((ineligibleCode) => {
          const isIneligibleCodeNotNew = ineligibleCode.hasOwnProperty('recordId');
          return isIneligibleCodeNotNew;
        })
        .filter((ineligibleCode) => {
          const [originalIneligibleCode] = ineligibleCodes.filter(currentIneligibleCode => currentIneligibleCode.recordId === ineligibleCode.recordId);
          if (originalIneligibleCode === undefined) { return false; }
          const isIneligibleCodeEdited = !isObjectsEqual(ineligibleCode, originalIneligibleCode);
          return isIneligibleCodeEdited;
        });

      /* update ineligible codes */
      const editRequestPutConfigs = {
        url: `${API_DOMAIN}/ineligibleCodes`,
        method: PUT,
        data: ineligibleCodesToEdit.map((ineligibleCode) => {
          return {
            recordId: ineligibleCode.recordId,
            code: ineligibleCode.code,
            ineligibleDescription: ineligibleCode.ineligibleDescription,
            sortOrder: ineligibleCode.sortOrder,
            isCurrent: ineligibleCode.isCurrent,
            visible: parseBoolean(ineligibleCode.visible),
          };
        }),
      };

      /* add new ineligible codes */
      const postRequestConfigs = reorderedIneligibleCodes
        .filter(ineligibleCode => !ineligibleCode.hasOwnProperty('recordId'))
        .map(newIneligibleCode => {
          return {
            url: `${API_DOMAIN}/ineligibleCodes`,
            method: POST,
            data: {
              code: newIneligibleCode.code,
              ineligibleDescription: newIneligibleCode.ineligibleDescription,
              sortOrder: newIneligibleCode.sortOrder,
              isCurrent: 1,
              visible: parseBoolean(newIneligibleCode.visible),
            },
          };
        });

      const canUpdate = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.UPDATE_INELIGIBLE);
      const canAdd = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.ADD_INELIGIBLE);
      if ((!canUpdate && ineligibleCodesToEdit.length > 0) || (!canAdd && postRequestConfigs.length > 0)) {
        setToasterMessage(NO_PERMISSION_MSG);
        setToasterSeverity('error');
        setIsToasterOpen(true);
      } else {
        const updateToasterMessage = getUpdateToasterMessage(postRequestConfigs, editRequestPutConfigs);
        await request(editRequestPutConfigs); /* waits for updating info before adding, to avoid conflict when updating ineligible code */
        await requests(postRequestConfigs.map(requestConfig => request(requestConfig)));
        setDirty(false);
        await getIneligibleCodes();
        setToasterMessage(updateToasterMessage);
        setToasterSeverity('success');
        setIsToasterOpen(true);
        setSubmitting(false);
      }
    }
    catch (error) {
      console.log('INELIGIBLE CODE UPDATE ERROR: ', error);
      setIsLoading(false);
    }
  };

  /**
   * This function adds a new row to the form.
   * @param fieldArray FieldArrayRenderProps instance.
   * @param values Formik values.
   */
  const addNewRow = (fieldArray: FieldArrayRenderProps, values: IFormikValue) => {
    const latestSortOrder = values.ineligibleCodes.length;
    const newIneligibleCode = { sortOrder: latestSortOrder + 1, code: '', ineligibleDescription: '', visible: '' };
    fieldArray.push(newIneligibleCode);
    values.ineligibleCodes.forEach((ineligibleCode, index) => fieldArray.replace(index, { ...ineligibleCode, sortOrder: index + 1}));
    setAllowAddingRow(false);
  };

  /**
   * This function generates a toaster message based on the update actions performed.
   * @param addItems Array of items to be added.
   * @param updateItems Array of items to be updated.
   * @returns The toaster message.
   */
  const getUpdateToasterMessage = (addItems: any, updateItems: any) => {
    const addLength = addItems.length;
    const updateLength = updateItems.data.length;
    if (addLength > 0 && updateLength > 0) {
      return 'Changes in Ineligible Settings have been saved';
    } else if (addLength > 0 && updateLength <= 0) {
      const phrase = addLength > 1 ? 'Items have been' : `${addItems[0].data.ineligibleDescription} has been`;
      return `${phrase} added`;
    } else {
      const phrase = updateLength > 1 ? 'Items have been' : `${updateItems.data[0].ineligibleDescription} has been`;
      return `${phrase} updated`;
    }
  };

  /**
   * This function retrieves present Formik values with unsaved changes.
   * @param formikValues Formik values.
   * @returns An object with information about unsaved changes.
   */
  const getPresentFormikValues = (formikValues: IIneligibleCode[]) => {
    let hasUnsavedChanges = false;
    const presentFormikValues = formikValues.filter((currentValue: IIneligibleCode, currentIndex: number) => {
      if (currentIndex === deleteModal.deleteIndex) { return false; }
      if (currentValue.recordId === parseInt(deleteModal.deleteRecordId)) { return false; }

      const hasNewRecord = currentValue.recordId === undefined;
      const hasUpdatedRecord = ineligibleCodes.some(ineligibleCode => {
        if (ineligibleCode.recordId !== currentValue.recordId) { return false; }
        return !isObjectsEqual(currentValue, ineligibleCode);
      });
      if (hasNewRecord || hasUpdatedRecord) { hasUnsavedChanges = true; }

      return true;
    });

    return { hasUnsavedChanges, presentFormikValues };
  };

  /**
   * This function separates saved and empty Formik values.
   * @param formikValuesForIneligibleCodes Formik values for ineligible codes.
   * @returns An object with saved and empty Formik values.
   */
  const getSavedAndEmptyFormikValues = (formikValuesForIneligibleCodes: IIneligibleCode[]) => {
    const [savedFormikValues, emptyFormikValues] = formikValuesForIneligibleCodes.reduce((separatedFormikValues: IIneligibleCode[][], ineligibleCode: IIneligibleCode) => {
      let currentSavedFormikValues = [...separatedFormikValues[0]];
      let currentEmptyFormikValues = [...separatedFormikValues[1]];

      const isIneligibleCodeSaved = ineligibleCode.recordId !== undefined;
      if (isIneligibleCodeSaved) {
        const [savedIneligibleCode] = ineligibleCodes.filter(originalIneligibleCode => originalIneligibleCode.recordId === ineligibleCode.recordId);
        savedIneligibleCode !== undefined && currentSavedFormikValues.push(savedIneligibleCode);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      const isIneligibleCodeEmpty = (
        (ineligibleCode.code === undefined || ineligibleCode.code === '') &&
        (ineligibleCode.ineligibleDescription === undefined || ineligibleCode.ineligibleDescription === '')
      );
      if (isIneligibleCodeEmpty) {
        currentEmptyFormikValues.push(ineligibleCode);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
    }, [[], []]);

    return { savedFormikValues, emptyFormikValues };
  };

  /**
   * This function sets Formik values and handles the dirty state.
   * @param node FormikProps instance.
   */
  const formikRef = (node: FormikProps<IFormikValue>) => {
    if (node === null || isLoading) { return; }
    setFormikValues(node.values);
    !isObjectsEqual(formikValues, node.values) && setDirty(node.dirty);
  };

  /**
   * This custom hook displays a confirmation prompt for unsaved changes when leaving the page.
   */
  usePrompt('You have unsaved changes. Are you sure you want to leave this page?', dirty);

  return(
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{ ineligibleCodes }}
      validationSchema={ineligibleSettingsForLenderSettingsSchema}
      onSubmit={(values, { setSubmitting }) => handleSave(values.ineligibleCodes, setSubmitting)}
    >
      {
        formik => (
          <Form onSubmit={formik.handleSubmit}>
            <Box sx={styles.outmostContainer}>
              <TableContainer sx={styles.tableContainer}>
                <Table sx={styles.table}>
                  <TableHead>
                    <TableRow sx={styles.tableHeadRow}>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForPriority, ...styles.centerAlignedText }}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='priority'
                          sx={styles.tableHeaderText}
                        >
                          Priority
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForIneligibleCode }}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='ineligible-code'
                          sx={styles.tableHeaderText}
                        >
                          Ineligible Code<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForDescription }}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='description'
                          sx={styles.tableHeaderText}
                        >
                          Description<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForStatus }}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='status'
                          sx={styles.tableHeaderText}
                        >
                          Status<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForAction, ...styles.centerAlignedText }}>
                        <FormLabel tabIndex={0} sx={styles.tableHeaderText}>
                          Action
                        </FormLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <DraggableForm
                    initialIneligibles={ineligibleCodes} 
                    formik={formik}
                    allowAddingRow={allowAddingRow}
                    addNewRow={addNewRow}
                    handleDelete={handleDelete}
                    handleConfirmDelete={handleConfirmDelete}
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                  />
                </Table>
              </TableContainer>
              <Box sx={styles.buttonsContainer}>
                {formik.dirty ? (
                  <Button
                    onClick={() => handleCancel(formik.resetForm)}
                    variant='outlined'
                    sx={styles.cancelButton}
                    data-testid='cancel-button'
                  >
                    <Typography variant='body2' component='p'>
                      Cancel
                    </Typography>
                  </Button>
                ) : null}
                <DisabledComponentsContainer isDisabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}>
                  <Button
                    disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}
                    aria-label={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading ? 'Save button disabled' : 'Save button'}
                    variant='contained'
                    data-testid='save-button'
                    sx={styles.saveButton}
                    type='submit'
                  >
                    <Typography variant='body2' component='p'>
                      Save
                    </Typography>
                  </Button>
                </DisabledComponentsContainer>
              </Box>
            </Box>
            <Toaster
              open={isToasterOpen}
              message={toasterMessage}
              severity={toasterSeverity}
              onCloseChange={() => setIsToasterOpen(false)}
            />
            <LoadingBackdrop isLoading={isLoading} />
          </Form>
        )
      }
    </Formik>
  )
};

export default LenderIneligibleCodesTable;