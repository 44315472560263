import { Dispatch, FC, MouseEvent, SetStateAction, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Grid, Paper, Typography, Container, useTheme, useMediaQuery, LinearProgress, ToggleButtonGroup, ToggleButton } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { API_DOMAIN, GET, POST, PERMISSIONS, customerLevelCodes, AMERICAN_DATE_FORMAT } from '../../../utility/constants';
import { checkRate, checkRatesByToCurrencyIds, checkUserPermissions, formatCurrency, formatDate, getARCollateralRequest, getBBPeriodRequest, getCurrencies, getLocalStorageItem, getOptions, getParamsByType, getUpcToCurrencyIds, Order, prependElement } from '../../../utility/helper';
import ReportHeader from './header';
import ReportsTitle from '../../../components/reports-title';
import styles from './styles';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { IComboBoxIds, IComboBoxSetStates, IOption } from '../../../interfaces/comboBox';
import ComboBox from '../../../components/common/combo-box';
import GeneralBreadcrumbs from '../../../components/breadcrumb';
import ARIneligibleDetails from './body';
import { arCollateralAPI, arCustomersAPI, ineligibleSettingAPI, multiCurrencyAPI, reportsAPI } from '../../../service/api';
import axiosInstance from '../../../service/axiosInstance';
import DisabledComponentsContainer from '../../../components/common/disabled-components-container';
import { ICurrency, IRate } from '../../../interfaces/multiCurrencyInterface';
import WarningModal from '../../../components/file-import/modals/warning-modal';
import ClientReports from './upc-level-body';
import { IRfCalcSummary } from '../../../interfaces/rollForwardReportInterface';
import { IClient } from '../../../interfaces';
import { IBorrowerCustReport, IBorrowerReport, ICodeReport, ICollateralCustReport, ICollateralReport, ICustReport, IGrandTotalResponse, IGrandTotals } from '../../../interfaces/arIneligibleReport';

export interface IARIneligible {
  id                      : number;
  parentClientId          : number;
  borrowerId              : number;
  borrowerName            : string;
  isDefaultBorrower       : boolean;
  bbPeriodId              : number;
  arCollateralId          : number;
  arCollateralName        : string;
  isDefaultCollateral     : boolean;
  currencyId              : string;
  upcParentCustomerId?    : number;
  upcParentCustomerSrcId? : string;
  upcParentCustName?      : string;
  isUpcParentCustomer     : boolean;
  arCustomerId            : number;
  parentArCustomerId?     : number;
  custSrcId               : string;
  custName                : string;
  hasDetails?             : boolean;
  calcOrder               : number;
  amountType              : string;
  amount                  : number;
  defaultAmount           : number;
  comments?               : string;
  startDate?              : string;
  endDate                 : string;
  ineligibleCode          : string;
  eligibleBalance         : number;
}

export interface IARIneligibles {
  [key: string]: IARIneligible[];
}

export interface IARCollateralWithIneligibles {
  [key: string]: IARIneligibles;
}

export interface IClientWithIneligibles {
  [key: string]: IARCollateralWithIneligibles;
}

export interface ICustIneligible {
  arCustomerId        : number;
  custSrcId           : string;
  custName            : string;
  ineligibleAmount    : number;
  eligibleAR          : number;
  [key: string]       : any;
}

export interface IARCollateralWithCustIneligibles {
  [key: string]: ICustIneligible[];
}

export interface IClientWithCustIneligibles {
  [key: string]: IARCollateralWithCustIneligibles;
}

export interface IARCollateralWithRfv {
  [key: string]: IRfCalcSummary
}

export interface IClientWithRfv {
  [key: string]: IARCollateralWithRfv
}

export interface IHeader {
  id      : keyof ICustIneligible;
  label   : string;
  numeric : boolean;
}

export interface IGrandTotal {
  [key: string] : number;
}

export type IReportView = 'Ineligible' | 'Customer';

export interface ISortingProps {
  order             : Order;
  setOrder          : Dispatch<SetStateAction<Order>>;
  orderBy           : keyof IARIneligible;
  setOrderBy        : Dispatch<SetStateAction<keyof IARIneligible>>;
  custViewOrder     : Order;
  setCustViewOrder  : Dispatch<SetStateAction<Order>>;
  custViewOrderBy   : keyof ICustIneligible;
  setCustViewOrderBy: Dispatch<SetStateAction<keyof ICustIneligible>>;
}

export interface IAmountTypes {
  ineligibleView: string[];
  custView      : string[];
}

export interface ICapDetails {
  recordId            : number;
  borrowerId          : number;
  borrowerName        : string;
  arCollateralId      : number;
  arCollateralName    : string;
  bbPeriodId          : number;
  endDate             : string;
  currencyId          : number;
  amountType          : string;
  ineligibleCode      : string;
  capAmount           : number;
  totalIneligible     : number;
  capAddBack          : number;
  newIneligibleAmount : number;
}

export interface IIneligibleCapDetails {
  [key: string]: ICapDetails;
}

export interface IARCollateralCapDetails {
  [key: string]: IIneligibleCapDetails;
}

export interface IClientCapDetails {
  [key: string]: IARCollateralCapDetails 
}

/**
 * This method retrieves the first key from the provided object.
 * If the object doesn't have any keys or is null, it returns `undefined`.
 *
 * @param object - The object from which the first key should be retrieved.
 * @returns - The first key of the provided object, or `undefined` if not present.
 */
export const getObjectFirstKey = (object: Object) => {
  return Object.keys(object)?.[0];
}

/**
 * This function calculates the total sum of 'amount' properties for an array of IARIneligible items. It aggregates the amounts associated with ineligible types.
 * If the array is empty or doesn't contain any ineligible amounts, it defaults
 * to returning 0.
 *
 * @param arIneligible - An array containing IARIneligible items.
 * @returns The total sum of all ineligible amounts in the provided array.
 */
export const ineligibleTypeTotal = (arIneligible: IARIneligible[]) => {
  if (!arIneligible?.[0]) return 0;
  return arIneligible.reduce((total, ar) => total + ar.amount, 0);
}

/**
 * This method computes a grand total for multiple amount types including 'ineligibleAmount' and 'eligibleAR'.
 * 
 * @param custIneligibles - An array of ICustIneligible items.
 * @param amountTypes - The string keys representing different amount types.
 * @returns  An object detailing each amount type's total.
 */
export const getGrandTotal = (custIneligibles: ICustIneligible[], amountTypes: string[]) => {
  const total: IGrandTotal = {};

  amountTypes.forEach((amountType: string) => {
    const subtotal = getSubtotal(custIneligibles, amountType);
    total[amountType] = subtotal;
  })

  total["ineligibleAmount"] = getSubtotal(custIneligibles, "ineligibleAmount");
  total["eligibleAR"] = getSubtotal(custIneligibles, "eligibleAR");

  return total;
}

/**
 * This function calculates the subtotal for a specific property key across an ICustIneligible array. It assists in aggregating values for a specific property
 * from an array of ICustIneligible items.

 * @param custIneligibles - An array of ICustIneligible items.
 * @param key - The property key for which the subtotal should be computed.
 * @returns The computed subtotal for the specified key.
 */
export const getSubtotal = (custIneligibles: ICustIneligible[], key: keyof ICustIneligible) => {
  const subtotal = custIneligibles.reduce((prevValue: number, currValue: ICustIneligible) => {
    if (currValue[key]) return prevValue + currValue[key];
    else return prevValue + 0;
  }, 0);

  return subtotal;
};

const ArIneligibleReport: FC = () => {
  const navigate                        = useNavigate();
  const theme                           = useTheme();
  const belowLargeBreakpoint            = useMediaQuery(theme.breakpoints.down('md'));
  const selectedClientContext           = useContext(SelectedClientContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const borrowerId                      = searchParams.get('clientId') ?? '0';
  const bbPeriodId                      = searchParams.get('bbPeriodId') ?? '0';
  const arCollateralId                  = searchParams.get('arCollateralId') ?? '0';
  const endDate                         = searchParams.get('endDate') ?? '0';
  const fromSettings                    = searchParams.get('fromSettings');
  const currencyId                      = searchParams.get('currencyId') ?? '0';

  const [clients, setClients]                             = useState<IOption[]>([]);
  const [currencies, setCurrencies]                       = useState<IOption[]>([]);
  const [bbPeriods, setBBPeriods]                         = useState<IOption[]>([]);
  const [arCollaterals, setARCollaterals]                 = useState<IOption[]>([]);
  const [selectedClient, setSelectedClient]               = useState<IOption | null>(null);
  const [selectedARCollateral, setSelectedARCollateral]   = useState<IOption | null>(null);
  const [selectedBBPeriod, setSelectedBBPeriod]           = useState<IOption | null>(null);
  const [selectedCurrency, setSelectedCurrency]           = useState<IOption | null>(null);
  const [clientInput, setClientInput]                     = useState<string>('');
  const [arCollateralInput, setARCollateralInput]         = useState<string>('');
  const [bbPeriodInput, setBBPeriodInput]                 = useState<string>('');
  const [currencyInput, setCurrencyInput]                 = useState<string>('');
  const [amountTypes, setAmountTypes]                     = useState<IAmountTypes>({ineligibleView: [], custView: []});
  const [arIneligibles, setARIneligibles]                 = useState<IARIneligibles[]>([]);
  const [exporting, setExporting]                         = useState<boolean>(false);
  const [generate, setGenerate]                           = useState<boolean>(false);
  const [displayNoTable, setDisplayNoTable]               = useState<boolean>(false);
  const [expanded, setExpanded]                           = useState<string | false>('');
  const [disabled, setDisabled]                           = useState<boolean>(true);
  const [firstLoad, setFirstLoad]                         = useState<boolean>(true);
  const [isLoading, setIsLoading]                         = useState<boolean>(false);
  const [order, setOrder]                                 = useState<Order>('asc');
  const [orderBy, setOrderBy]                             = useState<keyof IARIneligible>('custName');
  const [custViewOrder, setCustViewOrder]                 = useState<Order>('asc');
  const [custViewOrderBy, setCustViewOrderBy]             = useState<keyof ICustIneligible>('custName');
  const [viewBy, setViewBy]                               = useState<IReportView>('Ineligible');
  const [custIneligibles, setCustIneligibles]             = useState<ICustIneligible[]>([]);
  const [totalAR, setTotalAR]                             = useState<number | null>(0);
  const [grandTotal, setGrandTotal]                       = useState<IGrandTotal | null>(null);
  const [headers, setHeaders]                             = useState<IHeader[]>([]);
  const [parentIds, setParentIds]                         = useState<number[]>([]);
  const [exchangeRate, setExchangeRate]                   = useState<number>();
  const [rateId, setRateId]                               = useState<number>();
  const [allExchangeRates, setAllExchangeRates]           = useState<IRate[]>([]);
  const [rateModalOpen, setRateModalOpen]                 = useState<boolean>(false);
  const [canViewReport, setCanViewReport]                 = useState<boolean>(false);
  const [rfvDisabled, setRfvDisabled]                     = useState<boolean>(true);
  const [rfvIneligibleAmt, setRfvIneligibleAmt]           = useState<number>(0.0);
  const [hasNoRfv, setHasNoRfv]                           = useState<boolean>(false);
  const [ineligibleCodes, setIneligibleCodes]             = useState<{[key: string]: string}[]>([]);
  const [custIdsWithDetails, setCustIdsWithDetails]       = useState<{[key: string]: number[]}>({});
  const [ineligibleCapDetails, setIneligibleCapDetails]   = useState<IIneligibleCapDetails>({});
  const [toCurrencyIds, setToCurrencyIds]                 = useState<number[]>([]);
  const [ineligibleViewReport, setIneligibleViewReport]   = useState<IBorrowerReport[]>([]);
  const [custViewReport, setCustViewReport]               = useState<IBorrowerCustReport[]>([]);
  const [isChildCustomerOnly, setIsChildCustomerOnly]     = useState<boolean>(false);
  const [grandTotals, setGrandTotals]                     = useState<IGrandTotals[]>([]);
  const [rfSummaries, setRfSummaries]                     = useState<IRfCalcSummary[]>([]);
  const [reportCodes, setReportCodes]                     = useState<IHeader[]>([]);

  const filteredClients = useMemo(() =>
    clients.filter(client => client.parentClientFk === undefined)
  , [clients]);

  const isUltimateParent = useMemo(() =>
    selectedClientContext.selectedClient?.parentClient
  , [selectedClientContext.selectedClient]);

  const parsedBorrowerId = useMemo(() => parseInt(borrowerId), [borrowerId]);

  const params = { borrowerId, invCollateralId: '0', bbPeriodId, arCollateralId, customerId: '0', currencyId };

  /**
   * This useEffect hook initializes options for clients and fetches permissions.
   */
  useEffect(() => {
    getPermission();
    const clientOpts = getClientOptions(selectedClientContext.clients);
    selectClientOnLoad(parsedBorrowerId, clientOpts, selectedClientContext.clients);
  }, [selectedClientContext.clients])

  /**
   * Transforms an array of `IClient` objects into an array of `IOption` objects and sets the transformed options.
   * 
   * @param clients An array of `IClient` objects.
   * @returns An array of `IOption` objects.
   */
  const getClientOptions = (clients: IClient[]) => {
    const clientOpts: IOption[] = clients.map(client => {
      const mapped: IOption = {
        recordId: client.recordId as number,
        label: client.borrowerName as string,
        default: Boolean(client.default),
        parentClient: client.parentClient,
        parentClientFk: client.parentClientFk,
        currencyId: client.currencyId
      }
      return mapped;
    })
    setClients(clientOpts);
    return clientOpts;
  };

  /**
   * Selects a client based on the provided borrower ID and updates the relevant states and contexts.
   *
   * @param borrowerId The ID of the borrower used to find and select the client.
   * @param clientOpts An array of option objects representing available clients.
   * @param clients An array of client objects from which the selected client is determined.
   */
  const selectClientOnLoad = (borrowerId: number, clientOpts: IOption[], clients: IClient[]) => {
    const selectedContext = clients.find(client => client.recordId === borrowerId);
    selectedClientContext?.setSelectedClient(selectedContext ?? null);
    
    const selected = clientOpts.find(client => client.recordId === borrowerId);
    setSelectedClient(selected ?? null);
    setClientInput(selected?.label ?? '')
  };

  /**
   * Effect hook that triggers fetching UPC to currency IDs based on the selected client and other dependencies.
   *
   * @param isUltimateParent Indicates if the current context is an ultimate parent.
   * @param selectedClient The currently selected client object.
   * @param toCurrencyIds An array of currency IDs to determine if fetching is needed.
   */
  useEffect(() => {
    const upcSelected = Boolean(isUltimateParent && selectedClient);
    const hasEndDate = endDate !== 'undefined' && endDate !== '0';

    if (upcSelected && toCurrencyIds.length === 0 && hasEndDate) {
      fetchUpcToCurrencyIdsByBorrowerId(selectedClient?.recordId as number, endDate);
    }
  }, [selectedClient, isUltimateParent, toCurrencyIds])

  /**
   * Effect hook that triggers fetching UPC as of dates based on the ultimate parent status and other dependencies.
   *
   * @param parsedBorrowerId The ID of the borrower used for fetching UPC as of dates.
   * @param isUltimateParent Indicates if the current context is an ultimate parent.
   * @param bbPeriods An array of periods related to the borrower.
   */
  useEffect(() => {
    if (isUltimateParent && bbPeriods.length === 0) {
      fetchUpcAsOfDatesByBorrowerId(parsedBorrowerId);
    }
  }, [parsedBorrowerId, isUltimateParent, bbPeriods])

  /**
   * Effect hook that triggers fetching client currency based on the selected client and other dependencies.
   *
   * @param selectedClient The currently selected client object.
   * @param isUltimateParent Indicates if the current context is an ultimate parent.
   * @param selectedCurrency The currently selected currency, or null if no currency is selected.
   */
  useEffect(() => {
    if (isUltimateParent && selectedClient?.currencyId && selectedCurrency === null) {
      fetchClientCurrencyByBorrowerCurrencyId(parseInt(selectedClient.currencyId));
    }
  }, [selectedClient, isUltimateParent, selectedCurrency])

  /**
   * Fetches UPC to currency IDs for a given borrower ID and updates the state if the data is retrieved.
   *
   * @param borrowerId The ID of the borrower for whom UPC to currency IDs are fetched.
   */
  const fetchUpcToCurrencyIdsByBorrowerId = async (borrowerId: number, asOfDate: string) => {
    const toCurrencyIds: number[] | undefined = await getUpcToCurrencyIds(borrowerId, asOfDate);
    if (toCurrencyIds) {
      setToCurrencyIds(toCurrencyIds)
    }
  };

  /**
   * Fetches UPC as of dates for a given borrower ID and updates the state and search parameters if data is retrieved.
   *
   * @param borrowerId The ID of the borrower for whom UPC as of dates are fetched.
   */
  const fetchUpcAsOfDatesByBorrowerId = async (borrowerId: number) => {
    const bbPeriodOpts: IOption[] | undefined = await getAvailableUpcDatesForReport(borrowerId);
    if (bbPeriodOpts) {
      setBBPeriods(bbPeriodOpts);
      searchParams.delete('bbPeriodId');

      if (parseInt(endDate) !== 0) {
        selectUpcAsOfDateOnLoad(bbPeriodOpts);
      }
    }
  };

  /**
   * Selects a UPC as of date from the given options based on the end date and updates the selected period.
   *
   * @param bbPeriodOpts An array of option objects representing available UPC as of dates.
   */
  const selectUpcAsOfDateOnLoad = (bbPeriodOpts: IOption[]) => {
    const selected = bbPeriodOpts.find(bbPeriod => formatDate(bbPeriod.label, 'YYYYMMDD') === endDate);
    setSelectedBBPeriod(selected ?? null);
  };

  /**
   * Fetches the client currency for a given currency ID and updates the selected client currency.
   *
   * @param currencyId The ID of the currency for which client currency is fetched.
   */
  const fetchClientCurrencyByBorrowerCurrencyId = async (currencyId: number) => {
    const clientCurrency = await getCurrencyById(currencyId);
    selectClientCurrencyOnLoad(clientCurrency);
  };

  /**
   * Updates the selected currency based on the provided client currency.
   *
   * @param clientCurrency The client currency object or undefined if no currency is provided.
   */
  const selectClientCurrencyOnLoad = (clientCurrency: ICurrency | undefined) => {
    if (clientCurrency) {
      const clientCurrencyOpt: IOption = {
        recordId: clientCurrency.recordId,
        label: `${clientCurrency.currencyCode} - ${clientCurrency.currencyName}`,
        default: true
      };
      setSelectedCurrency(clientCurrencyOpt);
    } else {
      setSelectedCurrency(null);
    }
  };

  useEffect(() => {
    if (isUltimateParent && selectedClient?.currencyId && selectedBBPeriod && toCurrencyIds.length && allExchangeRates.length === 0) {
      fetchUpcExchangeRates(selectedClient, selectedBBPeriod, toCurrencyIds);
    }
  }, [selectedClient, selectedBBPeriod, toCurrencyIds, isUltimateParent])

  /**
   * Effect hook that triggers fetching UPC exchange rates based on the selected client, period, and other dependencies.
   *
   * @param selectedClient The currently selected client object.
   * @param selectedBBPeriod The selected business period object.
   * @param toCurrencyIds An array of currency IDs to fetch exchange rates for.
   * @param isUltimateParent Indicates if the current context is an ultimate parent.
   */
  const fetchUpcExchangeRates = async (selectedClient: IOption, selectedBBPeriod: IOption, toCurrencyIds: number[]) => {
    const rate: IRate[] | undefined = await checkRatesByToCurrencyIds(
      selectedClient.recordId,
      parseInt(selectedClient.currencyId as string),
      toCurrencyIds,
      formatDate(selectedBBPeriod.label, 'YYYYMMDD')
    );
    setAllExchangeRates(rate ?? []);
    if (!rate) setRateModalOpen(true);
  };

  /**
   * Creates a memoized map of collateral rates from the array of exchange rates.
   * The map uses `toCurrencyId` as the key and `currencyRate` as the value.
   */
  const collateralRatesMap: {[key: number]: number} = useMemo(() =>
    allExchangeRates.reduce((acc, exchangeRate) => {

      if (!acc[exchangeRate.toCurrencyId]) {
        acc[exchangeRate.toCurrencyId] = exchangeRate.currencyRate
      }

      return acc
    }, {})
  , [allExchangeRates]);

  /**
   * Effect hook that triggers fetching an ineligible view report based on certain conditions and dependencies.
   *
   * @param isUltimateParent Indicates if the current context is an ultimate parent.
   * @param firstLoad Indicates if this is the first load of the component or data.
   * @param ineligibleViewReport An array containing the ineligible view report data.
   * @param collateralRatesMap A map of collateral rates where the key is the currency ID and the value is the rate.
   */
  useEffect(() => {
    if (isUltimateParent && firstLoad && Object.keys(collateralRatesMap).length > 0 && ineligibleViewReport.length === 0) {
      loadIneligibleViewReport();
    };
  }, [isUltimateParent, firstLoad, ineligibleViewReport, collateralRatesMap])

  const loadIneligibleViewReport = async () => {
    setIsLoading(true);
    await getIneligibleViewReport(parsedBorrowerId, endDate, collateralRatesMap);
    setIsLoading(false);
  }

  /**
   * Fetches the ineligible view report and updates the loading state.
   *
   * @param borrowerId The ID of the borrower for whom the report is fetched.
   * @param endDate The end date for the report.
   * @param collateralRatesMap A map of collateral rates where the key is the currency ID and the value is the rate.
   */
  const getIneligibleViewReport = async (borrowerId: number, endDate: string, collateralRatesMap: {[key: number]: number;}) => {
    const isChildCustomerOnly: boolean | undefined = await fetchReportLevel(borrowerId);
    if (isChildCustomerOnly !== undefined) {
      await getIneligibleViewSummary(borrowerId, endDate, isChildCustomerOnly, collateralRatesMap);
    }
  };

  /**
   * Fetches the report level to determine if the customer is a child customer only.
   *
   * @param parentClientId The ID of the parent client for which the report level is fetched.
   * @returns A promise that resolves to a boolean indicating if the customer is a child customer only.
   */
  const fetchReportLevel = async (parentClientId: number) => {
    try {
      const response = await axiosInstance.request({
        url: `${reportsAPI.arIneligibleReport.GET_REPORT_LEVEL}`,
        method: GET,
        params: { parentClientId }
      });
      const isChildCustomerOnly = Boolean(response.data);
      setIsChildCustomerOnly(isChildCustomerOnly); 
      return isChildCustomerOnly;
    } catch (error) {
      console.log('FETCHING REPORT LEVEL ERROR : ', error);
      setDisplayNoTable(true);
    }
  };

  /**
   * Fetches and processes the ineligible view summary for a given borrower, generating grand totals and summaries.
   *
   * @param borrowerId The ID of the borrower for whom the summary is fetched.
   * @param endDate The end date for the summary report.
   * @param isChildCustomerOnly Indicates if report is child level only.
   * @param collateralRatesMap A map of collateral rates where the key is the currency ID and the value is the rate.
   */
  const getIneligibleViewSummary = async (borrowerId: number, endDate: string, isChildCustomerOnly: boolean, collateralRatesMap: {[key: number]: number;}) => {
    const summary: IGrandTotalResponse[] | undefined = await fetchIneligibleViewSummary(borrowerId, endDate, isChildCustomerOnly);
    if (summary) {
      const sortedByCalcOrder = sortByCalcOrder(summary);
      const availableCodes: (keyof ICustIneligible)[] = getAvailableCodes(sortedByCalcOrder);

      if (availableCodes.length) {
        const grouped = groupSummaryByClient(sortedByCalcOrder, collateralRatesMap);
        setIneligibleViewReport(grouped);
  
        const {
          subtotalMap,
          addBackTotalMap
        } = getGrandTotalsMap(sortedByCalcOrder);
  
        const grandTotals: IGrandTotals[] = [
          { rowNum: 1, row: 'Subtotal', ...subtotalMap },
        ]

        // Summing up add back total
        const addBackTotal = Object.entries(addBackTotalMap)
          .reduce((acc: number, item) => {
            if (availableCodes.includes(item[0])) {
              acc += item[1] ?? 0.0;
            }
            return acc;
          }, 0.00)
        addBackTotalMap.ineligibleAmount = addBackTotal;
        addBackTotalMap.eligibleAr = addBackTotal;
        
        grandTotals.push({ rowNum: 2, row: 'Ineligible Cap Add Back', ...addBackTotalMap })

        // Summing up grand totals
        const grandTotalMap = Object.entries(subtotalMap)
          .reduce((acc: IGrandTotals, item) => {
            const key = item[0];

            if (key === 'rowNum' || key === 'row' || key === 'eligibleAr') return acc;

            if (!acc[key]) acc[key] = 0.0;
            acc[key] += (item[1] + (addBackTotalMap[key] ?? 0.0))
            
            return acc;
          }, { rowNum: 4, row: 'GRAND TOTAL', ineligibleAmount: 0.0 })
        grandTotalMap.eligibleAr = subtotalMap.eligibleAr + addBackTotalMap.eligibleAr;
  
        const disabledRfv: boolean = await checkUpcRfvDisabled(borrowerId);
        if (!disabledRfv) {
          const rfSummaries: IRfCalcSummary[] | undefined = await fetchUpcRfSummary(borrowerId, endDate);
          if (rfSummaries) {
            const appliedRates = rfSummaries
              .map(item => {
                const shallow = {...item};
                const appliedVariance = Math.round(shallow.variance) > 0 ? shallow.variance : 0;
                shallow.variance = appliedVariance / (collateralRatesMap[shallow.currencyId] ?? 1)
                return shallow;
              });
            setRfSummaries(appliedRates);
  
            const rfvGrandTotal: number = appliedRates.reduce((acc: number, summary: IRfCalcSummary) => {
              acc += summary.variance;
              return acc;
            }, 0.0)
            grandTotals.push({ rowNum: 3, row: 'Roll Forward Variance', ineligibleAmount: rfvGrandTotal, eligibleAr: rfvGrandTotal })
            grandTotalMap.ineligibleAmount += rfvGrandTotal;
            grandTotalMap.eligibleAr += rfvGrandTotal;
          }
        }
  
        grandTotals.push(grandTotalMap);
        setGrandTotals(grandTotals);
      }
    }
  };

  /**
   * Fetches the ineligible view summary for a given parent client, date, and customer type.
   *
   * @param parentClientId The ID of the parent client for whom the summary is fetched.
   * @param asOfDate The date for which the summary is requested.
   * @param isChildCustomerOnly Indicates if the report is child level only.
   * @returns A promise that resolves to the summary data or `undefined` if an error occurs.
   */
  const fetchIneligibleViewSummary = async (parentClientId: number, asOfDate: string, isChildCustomerOnly: boolean) => {
    try {
      const response = await axiosInstance.request({
        url: `${reportsAPI.arIneligibleReport.GET_INELIGIBLE_VIEW_SUMMARY}`,
        method: GET,
        params: { parentClientId, asOfDate, isChildCustomerOnly }
      });
      return response.data;
    } catch (error) {
      console.log('GET INELIGIBLE VIEW SUMMARY ERROR : ', error);
      setDisplayNoTable(true);
    }
  };

  /**
   * Sorts an array of grand total responses by borrower name, collateral name, and calculation order.
   *
   * @param summary The array of grand total responses to be sorted.
   * @returns The sorted array of grand total responses.
   */
  const sortByCalcOrder = (summary: IGrandTotalResponse[]) => {
    return [...summary].sort((a, b) => {
      // First, sort by borrowerName
      const borrowerComparison = a.borrowerName.localeCompare(b.borrowerName);
      if (borrowerComparison !== 0) {
        return borrowerComparison;
      }

      // Then, sort by arCollateralName
      const arCollateralComparison = a.arCollateralName.localeCompare(b.arCollateralName);
      if (arCollateralComparison !== 0) {
        return arCollateralComparison;
      }

      // Finally, sort by calcOrder
      return a.calcOrder - b.calcOrder;
    });
  };

  /**
   * Groups and processes an array of grand total responses by client, adjusting amounts based on collateral rates.
   *
   * @param summary The array of grand total responses to be grouped.
   * @param collateralRatesMap A map of collateral rates where the key is the currency ID and the value is the rate.
   * @returns The grouped summary by client, with amounts adjusted.
   */
  const groupSummaryByClient = (summary: IGrandTotalResponse[], collateralRatesMap: {[key: number]: number;}) => {
    return summary
      .map(item => {
        item.amount /= (collateralRatesMap[item.currencyId] ?? 1);
        return item;
      })
      .reduce(createInitialBorrowerReport, [])
  };

  /**
   * Creates an initial report for each borrower by grouping and summarizing the grand total responses.
   *
   * @param acc The accumulator array of borrower reports.
   * @param item A single grand total response item to be added to the report.
   * @returns The updated accumulator array with the new report data.
   */

  const createInitialBorrowerReport = (acc: IBorrowerReport[], item: IGrandTotalResponse) => {
    // Check if code is Gross AR
    if (item.ineligibleCode === 'Gross AR') return acc;

    // Check if existing borrower
    const existingBorrower = acc.find(existing => existing.borrowerId === item.borrowerId);

    if (existingBorrower) {
      const existingCollateralReport = existingBorrower.collateralReport
        .find(existing => existing.arCollateralId === item.arCollateralId);
      
      if (existingCollateralReport) {
        // INVARIANCE: There will be only one code report per collateral
        const ineligibleCodeReport: ICodeReport = {
          calcOrder: item.calcOrder,
          ineligibleCode: item.ineligibleCode,
          ineligibleDescription: item.amountType,
          ineligibleTotal: item.amount,
        }

        existingCollateralReport.ineligibleCodeReport.push(ineligibleCodeReport);
        existingCollateralReport.arCollateralTotal += item.amount;
      } else {
        // INVARIANCE: Ineligible Code Report is not existing yet too
        const ineligibleCodeReport: ICodeReport = {
          calcOrder: item.calcOrder,
          ineligibleCode: item.ineligibleCode,
          ineligibleDescription: item.amountType,
          ineligibleTotal: item.amount,
        }

        const collateralReport: ICollateralReport = {
          arCollateralId: item.arCollateralId,
          arCollateralName: item.arCollateralName ,
          arCollateralCurrencyId: item.currencyId,
          arCollateralTotal: item.amount,
          ineligibleCodeReport: [ineligibleCodeReport]
        }

        existingBorrower.collateralReport.push(collateralReport);
      }

      existingBorrower.borrowerTotal += item.amount;
    } else {
      // INVARIANCE: Collateral Report & Ineligible Code Report is not existing yet too
      const ineligibleCodeReport: ICodeReport = {
        calcOrder: item.calcOrder,
        ineligibleCode: item.ineligibleCode,
        ineligibleDescription: item.amountType,
        ineligibleTotal: item.amount,
      }

      const collateralReport: ICollateralReport = {
        arCollateralId: item.arCollateralId,
        arCollateralName: item.arCollateralName,
        arCollateralCurrencyId: item.currencyId,
        arCollateralTotal: item.amount,
        ineligibleCodeReport: [ineligibleCodeReport],
      }

      const borrowerReport: IBorrowerReport = {
        borrowerId: item.borrowerId,
        borrowerName: item.borrowerName,
        borrowerTotal: item.amount,
        collateralReport: [collateralReport],
      }

      acc.push(borrowerReport);
    }
    return acc;
  };

  /**
   * Extracts and returns a list of unique ineligible codes from the summary, creating headers for them.
   *
   * @param summary The array of grand total responses used to extract codes.
   * @returns The list of unique ineligible codes.
   */
  const getAvailableCodes = (summary: IGrandTotalResponse[]) => {
    const codes: IHeader[] = [...summary]
      .reduce((acc: IHeader[], item: IGrandTotalResponse) => {
        const existing = acc.find(val => val.id === item.ineligibleCode);
        if (existing) return acc;

        const header: IHeader = {
          id: item.ineligibleCode,
          label: item.amountType, // ineligibleDescription
          numeric: true
        };
        acc.push(header);
        return acc;
      }, []);

    // INVARIANCE: MINIMUM OF 1 CODE WILL BE 'GROSS AR' AND THE OTHER IS AN INELIGIBLE
    if (codes.length > 1) {
      setReportCodes(codes);
      return codes.map(code => code.id);
    } else {
      setDisplayNoTable(true);
      return [];
    }
  };

  /**
   * Calculates and returns the subtotal and add-back total maps from a list of grand total responses.
   *
   * @param grandTotalResponse The array of grand total responses used to calculate totals.
   * @returns An object containing two maps: `subtotalMap` and `addBackTotalMap`.
   * @returns returns.subtotalMap An object mapping ineligible codes to their subtotal amounts, including total ineligible amounts and eligible AR.
   * @returns returns.addBackTotalMap An object mapping ineligible codes to their add-back total amounts.
   */
  const getGrandTotalsMap = (grandTotalResponse: IGrandTotalResponse[]) => {
    return grandTotalResponse
      .reduce((acc, res: IGrandTotalResponse) => {
        // Subtotal
        if (!acc.subtotalMap[res.ineligibleCode]) acc.subtotalMap[res.ineligibleCode] = 0.0;
        acc.subtotalMap[res.ineligibleCode] += res.amount;
        if (res.ineligibleCode !== 'Gross AR') acc.subtotalMap.ineligibleAmount += res.amount;
        acc.subtotalMap.eligibleAr += res.amount;

        // Add back total
        if (!acc.addBackTotalMap[res.ineligibleCode]) acc.addBackTotalMap[res.ineligibleCode] = 0.0;
        acc.addBackTotalMap[res.ineligibleCode] = res.capAddBack;

        return acc;
      }, {
        subtotalMap: { ineligibleAmount: 0.0, eligibleAr: 0.0 },
        addBackTotalMap: { ineligibleAmount: 0.0, eligibleAr: 0.0 }
      })
  };

  /**
   * This useEffect hook triggers the fetching of UPC reports on first load.
   * Waits for the collateralRatesMap to be populated before fetching.
   */
  useEffect(() => {
    if (isUltimateParent && firstLoad && Object.keys(collateralRatesMap).length > 0 && viewBy === 'Customer' && custViewReport.length === 0) {
      loadCustViewReport();
    }
  }, [collateralRatesMap, viewBy, isUltimateParent, custViewReport])

  const loadCustViewReport = async () => {
    setIsLoading(true);
    await getCustViewReport(parseInt(borrowerId), endDate);
    setIsLoading(false);
  }

  /**
   * Asynchronously fetches the full UPC report for a borrower within a specified time frame.
   * @param borrowerId The ID of the borrower.
   * @param endDate The date for which the report is requested.
   * @returns A promise that resolves when the report is fetched and processed.
   */
  const getCustViewReport = async (borrowerId: number, endDate: string) => {
    const ineligibles: IARIneligible[] | undefined = await fetchUpcReport(borrowerId, endDate);
    if (ineligibles) {
      const sortedByCalcOrder = sortCustByCalcOrder(ineligibles);
      const availableCustCodes = getAvailableCustCodes(sortedByCalcOrder);

      if (availableCustCodes.length) {
        const headers: IHeader[] = getCustomerHeaders(availableCustCodes);
        setHeaders(headers);

        const report = groupCustomerByClient(sortedByCalcOrder, collateralRatesMap);
        setCustViewReport(report);
      }
    }
  };

  const fetchUpcReport = async (parentClientId: number, asOfDate: string) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_UPC_INELIGIBLE_REPORT,
        method: GET,
        params: { parentClientId, asOfDate }
      });

      const report: IARIneligible[] = response.data;
      if (report.length) return report;
      else setDisplayNoTable(true);
    } catch (error) {
      console.log('GET UPC REPORT ERROR: ', error);
      setDisplayNoTable(true);
    }
  }

  const getAvailableCustCodes = (ineligibles: IARIneligible[]) => {
    const codes: IHeader[] = [...ineligibles]
      .reduce((acc: IHeader[], item: IARIneligible) => {
        const existing = acc.find(val => val.id === item.ineligibleCode);
        if (existing) return acc;

        const header: IHeader = {
          id: item.ineligibleCode,
          label: item.amountType, // ineligibleDescription
          numeric: true
        };
        acc.push(header);
        return acc;
      }, []);

    // INVARIANCE: MINIMUM OF 1 CODE WILL BE 'GROSS AR' AND THE OTHER IS AN INELIGIBLE
    if (codes.length > 1) {
      return codes;
    } else {
      setDisplayNoTable(true);
      return [];
    }
  }

  /**
   * Retrieves headers for a table based on the properties of an array of AR (Accounts Receivable) ineligibility objects.
   * @param ineligibles An array of AR ineligibility objects.
   * @returns An array of table headers with ID, label, and numeric properties.
   */
  const getCustomerHeaders = (codes: IHeader[]) => {
    const headers = [...codes];

    headers.unshift(
      {id: 'custSrcId', label: 'Customer ID', numeric: false},
      {id: 'custName', label: 'Customer', numeric: false},
    );

    headers.push(
      {id: 'ineligibleAmount', label: 'Ineligible Amount', numeric: true},
      {id: 'eligibleAR', label: 'Eligible AR', numeric: true}
    );

    return headers;
  }

  /**
   * Sorts an array of AR Ineligible responses by borrower name, collateral name, and calculation order.
   *
   * @param summary The array of AR Ineligible responses to be sorted.
   * @returns The sorted array of AR Ineligible responses.
   */
  const sortCustByCalcOrder = (report: IARIneligible[]) => {
    return [...report].sort((a, b) => {
      // First, sort by borrowerName
      const borrowerComparison = a.borrowerName.localeCompare(b.borrowerName);
      if (borrowerComparison !== 0) {
        return borrowerComparison;
      }

      // Then, sort by arCollateralName
      const arCollateralComparison = a.arCollateralName.localeCompare(b.arCollateralName);
      if (arCollateralComparison !== 0) {
        return arCollateralComparison;
      }

      // Finally, sort by calcOrder
      return a.calcOrder - b.calcOrder;
    });
  };

  const groupCustomerByClient = (report: IARIneligible[], collateralRatesMap: {[key: number]: number;}) => {
    return report
      .map(item => {
        item.amount /= (collateralRatesMap[item.currencyId] ?? 1);
        item.defaultAmount /= (collateralRatesMap[item.currencyId] ?? 1);
        return item;
      })
      .reduce(createInitialBorrowerCustReport, []);
  }

  const createInitialBorrowerCustReport = (acc: IBorrowerCustReport[], item: IARIneligible) => {
    // Check if existing borrower
    const existingBorrower = acc.find(existing => existing.borrowerId === item.borrowerId);

    if (existingBorrower) {
      const existingCollateralReport = existingBorrower.collateralReport
        .find(existing => existing.arCollateralId === item.arCollateralId);

      if (existingCollateralReport) {
        const existingCustReport = existingCollateralReport.custReport
          .find(existing => existing.arCustomerId === item.arCustomerId);

        if (existingCustReport) {
          getCustAmounts(item, existingCustReport);
        } else {
          const custReport: ICustReport = createCustReport(item);
          existingCollateralReport.custReport.push(custReport);
        }

        // Add amount to collateral total
        getCustTotals(item, existingCollateralReport.arCollateralTotal, 'Total');
      } else {
        // INVARIANCE: Customer Report is not existing yet too
        const custReport: ICustReport = createCustReport(item);
        const collateralReport: ICollateralCustReport = createCollateralCustReport(item, custReport);
        existingBorrower.collateralReport.push(collateralReport);
      }

      // Add amount to borrower total
      getCustTotals(item, existingBorrower.borrowerTotal, 'CLIENT TOTAL');
    } else {
      // INVARIANCE: Collateral Report & Customer Report is not existing yet too
      const custReport: ICustReport = createCustReport(item);
      const collateralReport: ICollateralCustReport = createCollateralCustReport(item, custReport);
      const borrowerReport: IBorrowerCustReport = createBorrowerCustReport(item, collateralReport);
      acc.push(borrowerReport);
    }

    return acc;
  }

  const createCustReport = (item: IARIneligible) => {
    const custReport: ICustReport = {
      id: item.id,
      arCustomerId: item.arCustomerId,
      custSrcId: item.custSrcId,
      custName: item.custName,
      isParentCustomer: Boolean(item.isUpcParentCustomer),
      hasDetails: Boolean(item.hasDetails),
      ineligibleAmount: item.ineligibleCode === 'Gross AR' ? 0.0 : item.amount,
      eligibleAR: item.amount,
      [item.ineligibleCode]: item.amount,
    }
    return custReport;
  }

  const createCollateralCustReport = (item: IARIneligible, custReport: ICustReport) => {
    const arCollateralTotal: IGrandTotals = {
      rowNum: 2,
      row: 'Total',
      ineligibleAmount: item.ineligibleCode === 'Gross AR' ? 0.0 : item.amount,
      eligibleAr: item.amount,
      [item.ineligibleCode]: item.amount,
    }

    const collateralReport: ICollateralCustReport = {
      arCollateralId: item.arCollateralId,
      arCollateralName: item.arCollateralName,
      arCollateralCurrencyId: parseInt(item.currencyId),
      arCollateralTotal: [arCollateralTotal],
      custReport: [custReport],
    }

    return collateralReport;
  }

  const createBorrowerCustReport = (item: IARIneligible, collateralReport: ICollateralCustReport) => {
    const borrowerTotal: IGrandTotals = {
      rowNum: 2,
      row: 'CLIENT TOTAL',
      ineligibleAmount: item.ineligibleCode === 'Gross AR' ? 0.0 : item.amount,
      eligibleAr: item.amount,
      [item.ineligibleCode]: item.amount,
    }

    const borrowerReport: IBorrowerCustReport = {
      borrowerId: item.borrowerId,
      borrowerName: item.borrowerName,
      borrowerTotal: [borrowerTotal],
      collateralReport: [collateralReport],
    }

    return borrowerReport;
  }

  const getCustTotals = (item: IARIneligible, totals: IGrandTotals[], row: string) => {
    const total = totals.find(total => total.row === row);
    if (total) {
      total[item.ineligibleCode] = (total[item.ineligibleCode] ?? 0.0) + item.amount;
      (total.eligibleAr as number) += item.amount; // Gross AR Included

      if (item.ineligibleCode !== 'Gross AR') {
        total.ineligibleAmount += item.amount;
      }
    }
  }

  const getCustAmounts = (item: IARIneligible, report: ICustReport) => {
    report[item.ineligibleCode] = item.amount;
    report.eligibleAr += item.amount; // Gross AR Included

    if (item.ineligibleCode !== 'Gross AR') {
      report.ineligibleAmount += item.amount;
    }
  }

  useEffect(() => {
    const isUpcAndCustView = isUltimateParent && viewBy === 'Customer';
    const availableCodes = reportCodes.filter(code => code.id !== 'Gross AR');
    if (Object.keys(custIdsWithDetails).length === 0 && isUpcAndCustView && availableCodes.length > 0) {
      fetchCustIdsWithDetails(parsedBorrowerId, endDate, isChildCustomerOnly);
    }
  }, [reportCodes, custIdsWithDetails, viewBy])

  /**
   * Fetches customer IDs with details for each ineligible code and updates the state with the results.
   *
   * @returns A promise that resolves when all customer IDs with details are fetched and state is updated.
   */
  const fetchCustIdsWithDetails = async (parentClientId: number, asOfDate: string, isChildCustomerOnly: boolean) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_UPC_CUST_IDS_WITH_DETAILS_CUST_VIEW,
        method: GET,
        params: { parentClientId, asOfDate, isChildCustomerOnly }
      });
      setCustIdsWithDetails(response.data);
    } catch (error) {
      console.log('FETCH CUST IDS WITH DETAILS - CUST VIEW ERROR: ', error);
    }
  }

  /**
   * This useEffect hook retrieves Accounts Receivable (AR) Collateral options based on the borrower ID.
   */
  useEffect(() => {
    (async () => {
      const parsedBorrowerId = parseInt(borrowerId);

      if (parsedBorrowerId) {
        if (!isUltimateParent) {
          getOptions({ type: 'arCollateral', params, getSetStatesByType, setSelectedByType, mainRequest: getARCollateralRequest(parsedBorrowerId), requestForDefault: true});
        }
      }
    })();
  }, [borrowerId, isUltimateParent]);

  /**
   * This useEffect hook retrieves the options for the bbPeriod based on borrower ID and AR Collateral ID.
   */
  useEffect(() => {
    const parsedBorrowerId = parseInt(borrowerId);
    const parsedARCollateralId = parseInt(arCollateralId);

    if (parsedBorrowerId && parsedARCollateralId && !isUltimateParent) {
      getOptions({ type: 'bbPeriod', params, getSetStatesByType, setSelectedByType, mainRequest: getBBPeriodRequest(parsedBorrowerId), requestForDefault: false, filter: 'ineligible'});
      searchParams.delete('endDate');
    }
  }, [borrowerId, arCollateralId, isUltimateParent])

  /**
   * This useEffect hook triggers AR amount type fetching, and the RFV Ineligible Setting checking
   * when brrowerId, bbPeriodId, and arCollateralId is existing.
   * When `generate` value changes and necessary conditions are met, it fetches AR amount types.
   */
  useEffect(() => {
    if (isUltimateParent && !firstLoad) {
      loadBothReports();
    }

    if (!isUltimateParent && !firstLoad) {
      checkRFVandGetAmountTypes();
    }
  }, [generate])

  const loadBothReports = async () => {
    setIsLoading(true);
    await getIneligibleViewReport(parsedBorrowerId, endDate, collateralRatesMap);
    await getCustViewReport(parseInt(borrowerId), endDate);
    setIsLoading(false);
  }
  
  useEffect(() => {
    if (selectedClientContext.selectedClient && !isUltimateParent && firstLoad) {
      checkRFVandGetAmountTypes();
    }
  }, [isUltimateParent])

  /**
   * This useEffect hook handles the display of the table based on `amountTypes`.
   * If there rfvDisabled is not true, it starts fetching the Roll Forward Variance Ineligible Amount
   * If there are any `ineligibleView` types within `amountTypes`, it starts a series
   * of fetch operations, otherwise sets to display no table.
   */
  useEffect(() => {
    if (!isUltimateParent && amountTypes.ineligibleView.length > 0 && arIneligibles.length === 0) {
      getNonUpcReport();
    }
  }, [arIneligibles, amountTypes, isUltimateParent])

  /**
   * This useMemo returns a memoized value of the customer level codes existing in the AR Ineligible Report.
   */
  const existingCustLevelCodes = useMemo(() =>
    ineligibleCodes.filter(code => customerLevelCodes.includes(Object.values(code)[0]))
  , [ineligibleCodes, customerLevelCodes]);

  /**
   * This useEffect gets the customer ids with details if there are existing customer level codes in the AR Ineligible Report.
   */
  useEffect(() => {
    if (Object.keys(custIdsWithDetails).length === 0 && !isUltimateParent && Object.keys(existingCustLevelCodes).length > 0) {
      getCustomerIdsWithDetails();
    }
  }, [existingCustLevelCodes, custIdsWithDetails])

  /**
   * Fetches customer IDs with details for each ineligible code and updates the state with the results.
   *
   * @returns A promise that resolves when all customer IDs with details are fetched and state is updated.
   */
  const getCustomerIdsWithDetails = async () => {
    const promises = existingCustLevelCodes.map(async (ineligibleCode) => {
      const code = Object.values(ineligibleCode)[0];
      const ids =
        await getCustIdsWithDetails(
          parseInt(borrowerId),
          parseInt(bbPeriodId),
          parseInt(arCollateralId),
          code
        );
    
      return { code, ids };
    });

    Promise.all(promises)
      .then((results) => {
        const idsWithDetailsPerIneligibleCode: { [key: string]: number[] } = {};
        results.forEach(({ code, ids }) => {
          idsWithDetailsPerIneligibleCode[code] = ids;
        });

        setCustIdsWithDetails(idsWithDetailsPerIneligibleCode);
      });
  }

  /**
   * This useEffect hook manages the 'disabled' state based on dropdown selections.
   * It enables or disables certain functionalities based on the selection status of different dropdowns.
   */
  useEffect(() => {
    if (isUltimateParent) {
      const isNotEmptyDropdowns = selectedClient && selectedBBPeriod;
      setDisabled(!(isNotEmptyDropdowns && !firstLoad && allExchangeRates.length > 0));
    } else {
      const isNotEmptyDropdowns = selectedClient && selectedBBPeriod && selectedARCollateral && selectedCurrency;
      setDisabled(!(isNotEmptyDropdowns && !firstLoad));
    }
  }, [firstLoad, selectedClient, selectedBBPeriod, selectedARCollateral, selectedCurrency, isUltimateParent, allExchangeRates]);

  /**
   * This useEffect hook calculates ineligible totals.
   * When `arIneligibles` changes, this hook recalculates the total ineligible amounts.
   */
  useEffect(() => {
    ineligibleTotal();
  }, [arIneligibles, ineligibleCapDetails])

  /**
   * This useEffect hook initiates currency fetching based on dropdown selections.
   */
  useEffect(() => {
    if (selectedClient && selectedClientContext && selectedARCollateral && selectedBBPeriod && !isUltimateParent) {
      fetchCurrency()
    }
  },[selectedARCollateral, selectedClient, selectedClientContext, selectedBBPeriod, isUltimateParent])

  /**
   * This useEffect hook fetches exchange rate based on selected currency.
   */
  useEffect(() => {
    if (selectedCurrency && !isUltimateParent) {
      fetchExchangeRate()
    }
  }, [selectedCurrency, isUltimateParent])

  /**
   * This useMemo returns a memoized object containing the total Ineligible Amount
   * including non-customer level ineligibles.
   */
  const totalIneligibleAmt = useMemo(() => {
    const addRfv = isUltimateParent ? 0 : rfvIneligibleAmt;
    return (totalAR ?? 0) + addRfv;
  }, [totalAR, rfvIneligibleAmt, isUltimateParent]);

  /**
   * This function gets the available as of dates for reports.
   * @param borrowerId The record id of the Ultimate Parent Client.
   */
  const getAvailableUpcDatesForReport = async (borrowerId: number) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_AVAILABLE_UPC_DATES,
        method: GET,
        params: { parentClientId: borrowerId }
      });

      const endDates: string[] = response.data;
      const bbPeriodOpts: IOption[] = endDates.map((endDate, index) => ({
        recordId: parseInt(formatDate(endDate, 'YYYYMMDD')),
        label: formatDate(endDate, AMERICAN_DATE_FORMAT),
        default: index === 0
      }))
      return bbPeriodOpts;
    } catch (error) {
      console.log('GET AVAILABLE UPC AS OF DATES FOR REPORTS ERROR: ', error);
    }
  };

  /**
   * This function fetches and sets the exchange rate based on selected currency.
   * It collects the currency IDs for AR and client. If the selected currency matches the
   * primary currency in the `currencies` list, the exchange rate is set to 1 and the
   * function returns early.
   *
   * @returns returns early if the selected currency matches the primary currency, otherwise nothing is explicitly returned.
   */
  const fetchExchangeRate = async () => {
    const arCurrencyId = await getARCurrencyId()
    const clientCurrencyId  = await getClientCurrencyId()
    const completeIds = arCurrencyId && clientCurrencyId && selectedCurrency;

    if (completeIds && selectedCurrency?.recordId === currencies[0].recordId) {
      // Foreign (Client Currency) is selected
      const exchangeRate = await checkRate( selectedARCollateral?.recordId ?? 0, selectedClient?.recordId ?? 0 , selectedBBPeriod?.label ?? '',)

      if (exchangeRate){
        setExchangeRate(exchangeRate.currencyRate)
        setRateId(exchangeRate.recordId)
      }
    } else {
      // Local (Collateral Currency) Selected
      setExchangeRate(1);
      setRateId(undefined);
    }
  }

  /**
   * This function retrieves a list of available currencies and filters it
   * based on AR and client currency IDs. The filtered currencies are then
   * transformed into options for a dropdown. Selected currency state is also updated.
   */
  const fetchCurrency = async () => {
    const currencies        = await getCurrencies()
    const arCurrencyId      = await getARCurrencyId()
    const clientCurrencyId  = await getClientCurrencyId()
    const availableRate     = await checkRate(
      selectedARCollateral?.recordId as number,
      selectedClient?.recordId as number,
      selectedBBPeriod?.label as string
    )

    if(currencies && arCurrencyId && clientCurrencyId && availableRate){
      const currencyOptions:IOption[] = currencies.currencies
      .filter(currency => {
        const hasClientCurrency = currency.recordId.toString() === clientCurrencyId;
        const hasArCurrency = currency.recordId.toString() === arCurrencyId;
        const hasRate = currency.recordId === availableRate.toCurrencyId;
        return hasClientCurrency || (hasArCurrency && hasRate);
      })
      .map(filtered => ({
        recordId: filtered.recordId,
        label   : `${filtered.currencyCode} - ${filtered.currencyName}`,
        default : filtered.recordId === clientCurrencyId
      }))

      setCurrencies(prependElement(currencyOptions, 'recordId', clientCurrencyId.toString()))
      const selected = currencyOptions?.find(currency => currency.recordId === parseInt(clientCurrencyId)) ?? null;
      setSelectedCurrency(selected)
      updateSearchParams('currencyId', `${selected?.currencyId ?? 0}`);
    }
  }

  /**
   * Fetches currency information by ID from the multi-currency API.
   * @param currencyId The ID of the currency to retrieve.
   * @returns A Promise that resolves to the currency data.
   */
  const getCurrencyById = async (currencyId: number) => {
    try {
      const response = await axiosInstance.request({
        url: `${multiCurrencyAPI.GET_CURRENCY_BY_ID}/${currencyId}`,
        method: GET,
      });
      return response.data as ICurrency;
    } catch (error) {
      console.log('GET CURRENCY BY ID ERROR: ', error);
    }
  }
  
  /**
   * This function gets the Currency ID associated with a particular AR collateral.
   *
   * @returns - The AR Currency ID associated with the AR collateral ID or 0 if not found.
   */
  const getARCurrencyId = async () => {
    try {
      const response = await axiosInstance.request({
        url: arCollateralAPI.FIND_AR_SETTINGS_BY_AR_COLLATERAL_ID,
        method: GET,
        params: {arCollateralId: arCollateralId, isCurrent: true}
      })
      return response.data.content[0]?.currency ?? 0;

    } catch (error) {
      console.log('GET AR CURRENCY ID : ', error);
    }
  }

  /**
   * This function fetches and returns the currency ID for a selected client.
   *
   * @returns {number} - The currency ID of the selected client.
   */
  const getClientCurrencyId = async () => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/borrowers/${selectedClient?.recordId}`,
        method: GET,
      });
      return response.data.currencyId;

    } catch (error) {
      console.log('GET CLIENT CURRENCY ID : ', error);
    }
  }

  /**
   * This function verifies if the currently logged in user has the permission
   * to view the ineligible report. If so, it updates the state to allow the user
   * to view the report.
   */
  const getPermission = async () => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_INELIGIBLE_REPORT)
    setCanViewReport(isPermitted ?? false);
  }

  /**
   * This function handles the generation process of the component. It toggles the generate
   * state, resets the first load flag, disables certain functionality and collapses any
   * expanded sections.
   */
  const handleGenerate = useCallback(() => {
    setGenerate(!generate);
    setFirstLoad(false);
    setDisabled(true);
    setExpanded(false);
  }, [generate]);

  /**
   * This function checks if the Roll Forward Variance Setting for a Parent Client is disabled.
   * @param parentClientId The ID of the parent client.
   * @returns A boolean value if Roll Forward Variance is disabled.
   */
  const checkUpcRfvDisabled = async (parentClientId: number) => {
    try {
      const response = await axiosInstance.request({
        url: ineligibleSettingAPI.CHECK_IF_UPC_RFV_DISABLED,
        method: GET,
        params: { parentClientId }
      });

      const disabled: boolean = response.data;
      setRfvDisabled(disabled);
      return disabled;
    } catch (error) {
      console.log('CHECK UPC ROLL FORWARD VARIANCE DISABLED: ', error);
      setRfvDisabled(true);
      return true;
    }
  };

  const fetchUpcRfSummary = async (parentClientId: number, endDate: string) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.rollForwardReport.GET_UPC_SUMMARY,
        method: GET,
        params: { parentClientId, endDate },
      });

      const summaries: IRfCalcSummary[] = response.data;
      return summaries;
    } catch (error) {
      console.log('GET UPC ROLL FORWARD VARIANCES ERROR: ', error);
      setRfvDisabled(true);
    }
  };

  /**
   * Checks if the necessary identifiers (borrowerId, bbPeriodId, arCollateralId) are present,
   * then checks Roll Forward Variance status and retrieves AR amount types accordingly.
   */
  const checkRFVandGetAmountTypes = async () => {
    if (borrowerId && bbPeriodId && arCollateralId) {
      await checkRFVDisabled();
      await getARAmountTypes();
    }
  }

  /**
   * Retrieves a non-UPC AR Ineligible report by performing a series of asynchronous operations.
   */
  const getNonUpcReport = async () => {
    setIsLoading(true);

    if (!rfvDisabled) {
      await getRFVIneligibleAmount();
    }

    await getParentIds(parseInt(arCollateralId));
    getAllARIneligibles();
    await getIneligibleCapDetails(parseInt(borrowerId), parseInt(arCollateralId), parseInt(bbPeriodId), amountTypes);
    await getCustIneligibles();
    getHeaders(amountTypes);

    setIsLoading(false);
  }

  /**
   * This function fetches and sets the AR amount types in state. 
   * It retrieves the amount types associated with the provided borrower ID, 
   * AR collateral ID, and BB period ID.
   */
  const getARAmountTypes = async () => {
    try {
      const amountTypesRes = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_AMOUNT_TYPES,
        method: POST,
        data: { borrowerId, arCollateralId, bbPeriodId },
      });

      const amountTypes: string[] = amountTypesRes.data;
      const filtered = amountTypes.filter((amountType: string) => amountType !== 'Gross AR');
      if (filtered.length) {
        setAmountTypes({ ineligibleView: filtered, custView: amountTypes});
      } else {
        setAmountTypes({ineligibleView: [], custView: []});
        setDisplayNoTable(true);
      }
    } catch {
      setIsLoading(false);
      setAmountTypes({ineligibleView: [], custView: []});
      setDisplayNoTable(true);
    }
  };

  /**
   * This function fetches and sets the rfvDisabled in state. 
   * It checks if the RFV Ineligible Setting associated with the provided borrower ID and
   * AR collateral ID is disabled.
   */
  const checkRFVDisabled = async () => {
    try {
      const response = await axiosInstance.request({
        url: ineligibleSettingAPI.CHECK_IF_RFV_DISABLED,
        method: GET,
        params: { borrowerId, arCollateralId }
      });

      const disabled: boolean = response.data;
      setRfvDisabled(disabled);
    } catch (error) {
      setIsLoading(false);
      setRfvDisabled(true);
      console.log('ERROR CHECKING RFV DISABLED: ', error);
    }
  };

  /**
   * This function fetches all AR ineligible reports based on their respective amount types
   * and updates the state. It constructs a list of promises to fetch AR ineligible reports
   * for each amount type specified in `amountTypes.ineligibleView`. The results from these
   * promises are aggregated into an ineligible list and stored in the state. If no data is
   * found, the display state is updated to show a 'no table' view.
   */
  const getAllARIneligibles = () => {
    const ineligibleRequests: Array<Promise<AxiosResponse | null>> = [];
    amountTypes.ineligibleView.forEach((type) => {
      ineligibleRequests.push(getARIneligibleByAmountType(type));
    });

    (ineligibleRequests.length) && 
      axios.all(ineligibleRequests).then((response) => {
        const ineligibleList: IARIneligibles[] = [];
        const ineligibleCodes: {[key: string]: string}[] = [];
        amountTypes.ineligibleView.forEach((type, index) => {
          ineligibleList.push({[type]: response[index]?.data});

          const code: string = response[index]?.data[0].ineligibleCode;
          ineligibleCodes.push({[type]: code});
        });
        setARIneligibles(ineligibleList);
        setIneligibleCodes(ineligibleCodes);
        setDisplayNoTable(false);
      })
      .catch(err => console.log("INELIGIBLE LIST ERROR : ", err))
  };

  /**
   * This function fetches an AR ineligible report for a specified amount type.
   * It sends a GET request to the specified API endpoint to retrieve an AR ineligible
   * report for the provided amount type.
   *
   * @param amountType - The type of amount for which the ineligible report is required.
   * @return - A promise containing the AxiosResponse if successful, or null on error.
   */
  const getARIneligibleByAmountType = (amountType: string) => {
    return axiosInstance.request({
      url: reportsAPI.arIneligibleReport.GET_INELIGIBLE_REPORT,
      method: GET,
      params: { borrowerId, bbPeriodId, arCollateralId, amountType }
    });
  };

  /**
   * Retrieves ineligible capital details for a borrower, AR collateral, and BB period,
   * and transforms ICapDetails[] to IIneligibleCapDetails
   * @param borrowerId - The ID of the borrower.
   * @param arCollateralId - The ID of the AR collateral.
   * @param bbPeriodId - The ID of the BB period.
   */
  const getIneligibleCapDetails = async (borrowerId: number, arCollateralId: number, bbPeriodId: number, amountTypes: IAmountTypes) => {
    const capDetails: ICapDetails[] | undefined = await getCapDetails(borrowerId, bbPeriodId, arCollateralId);
    if (capDetails) {
      const ineligibleCapDetails: IIneligibleCapDetails = capDetails
        .filter((value: ICapDetails) => amountTypes.ineligibleView.includes(value.amountType))
        .reduce((acc: IIneligibleCapDetails, currentValue: ICapDetails) => {
          const amountType: string = currentValue.amountType;
          acc[amountType] = currentValue;
          return acc;
        }, {});

      setIneligibleCapDetails(ineligibleCapDetails);
    }
  };

    /**
   * Retrieves cap details for a borrower, AR collateral, and BB period.
   * @param borrowerId The ID of the borrower.
   * @param arCollateralId The ID of the AR collateral.
   * @param bbPeriodId The ID of the BB period.
   * @returns A Promise that resolves with an array of cap details or undefined.
   */
  const getCapDetails = async (borrowerId: number, bbPeriodId: number, arCollateralId: number) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_INELIGIBLE_CAP_DETAILS,
        method: GET,
        params: { borrowerId, bbPeriodId, arCollateralId }
      });

      return response.data as ICapDetails[];
    } catch (error) {
      console.log('GET INELIGIBLE VIEW CAP DETAILS ERROR: ', error);
    }
  };

  /**
   * This function fetches AR ineligible reports from a 'customer view' perspective and
   * updates the state.
   *
   * It sends a POST request to fetch AR ineligible reports. If results are obtained, the
   * function updates the relevant state values, including setting the grand total. If no
   * data is retrieved, the display state is set to show a 'no table' view.
   */
  const getCustIneligibles = async () => {
    setCustIneligibles([]);
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_INELIGIBLE_REPORT_CUST_VIEW,
        method: POST,
        data: { borrowerId, bbPeriodId, arCollateralId }
      })
      const custIneligibleList: ICustIneligible[] = response.data;

      if (custIneligibleList.length) {
        setCustIneligibles(custIneligibleList);
        setDisplayNoTable(false);

        const grandTotal = getGrandTotal(custIneligibleList, amountTypes.custView);
        setGrandTotal(grandTotal);
      } else {
        setDisplayNoTable(true);
      }
    } catch (error) {
      console.log('GET CUST INELIGIBLES ERROR: ', error);
    }
  };

  /**
   * This function calculates the total amount of all ineligible types and updates the
   * state. It iterates through the list of AR ineligibles, summing up the total for each
   * type, and then updates the total AR ineligible amount in the state.
   */
  const ineligibleTotal = () => {
    let total = 0;
    arIneligibles.forEach(arIneligible => {
      if (!arIneligible[getObjectFirstKey(arIneligible)]?.[0]) return;
      const ineligibleDescription = getObjectFirstKey(arIneligible);
      total += getIneligibleTypeTotal(ineligibleDescription, ineligibleCapDetails, arIneligible);
    })
    setTotalAR(total);
  };

  /**
   * Calculates the ineligible amount based on the provided cap amount details or ineligible records.
   * @param ineligibleCapDetails The cap amount details for each ineligible code.
   * @param arIneligible The ineligible report for each ineligible code.
   * @returns The amount for the ineligible.
   */
  const getIneligibleTypeTotal = (ineligibleDescription: string, ineligibleCapDetails: IIneligibleCapDetails, arIneligible: IARIneligibles) => {
    const currentIneligibleTypeTotal = ineligibleTypeTotal(arIneligible[ineligibleDescription]);
    const capDetails: ICapDetails | undefined = ineligibleCapDetails[ineligibleDescription];
    
    if (capDetails) return currentIneligibleTypeTotal + capDetails.capAddBack;
    else return currentIneligibleTypeTotal;
  };

  /**
   * This function generates and sets headers for a data table based on the provided amount
   * types. It takes in a list of amount types and maps it to a header structure suitable
   * for table representation.
   *
   * @param amountTypes - The structure containing types of amounts for report generation.
   */
  const getHeaders = (amountTypes: IAmountTypes) => {
    const headers: IHeader[] = amountTypes.custView.map((amountType: string) => {
      const amtTypeHeader: IHeader = {id: amountType, label: amountType, numeric: true};
      return amtTypeHeader;
    });
    
    headers.unshift({id: 'custName', label: 'Customer', numeric: false});
    headers.unshift({id: 'custSrcId', label: 'Customer ID', numeric: false});
    headers.push({id: 'ineligibleAmount', label: 'Ineligible Amount', numeric: true},
                  {id: 'eligibleAR', label: 'Eligible AR', numeric: true});

    setHeaders(headers);
  };

  /**
   * This function gets the customer ids with details of the customer level ineligible codes.
   * @param borrowerId The Borrower ID of the AR Ineligible Report.
   * @param bbPeriodId The BB Period ID of the AR Ineligible Report.
   * @param arCollateralId The AR Collateral ID of the AR Ineligible Report.
   * @param ineligibleCode The Ineligible Code (Customer Level) to be used.
   * @returns An array of customer ids with details.
   */
  const getCustIdsWithDetails = async (borrowerId: number, bbPeriodId: number, arCollateralId: number, ineligibleCode: string) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_CUSTOMERS_WITH_DETAILS,
        method: GET,
        params: { borrowerId, bbPeriodId, arCollateralId, ineligibleCode }
      });
      return response.data as number[];
    } catch (error) {
      console.log('GET CUST IDS WITH DETAILS ERROR: ', error);
      return [];
    }
  };

  /**
   * This function fetches and sets the rfvIneligibleAmt in state. 
   * It retrieves the Roll Forward Ineligible Amount associated with the provided borrower ID,
   * AR collateral ID, and BB period ID.
   */
  const getRFVIneligibleAmount = async () => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_RFV_INELIGIBLE_AMOUNT,
        method: GET,
        params: { borrowerId,  bbPeriodId, arCollateralId }
      });

      const ineligibleAmt: number = response.data;
      setRfvIneligibleAmt(ineligibleAmt);
      setHasNoRfv(false);
    } catch (error: any) {
      if (error.response.status === 404) {
        setHasNoRfv(true);
      } else {
        setIsLoading(false);
        setHasNoRfv(true);
        console.log('ERROR GETTING RFV INELIGIBLE AMOUNT: ', error);
      }
    }
  };

  /**
   * This function fetches the parent IDs associated with a specific AR collateral ID and
   * updates the state.
   *
   * @param arCollateralId - The AR collateral ID used to fetch associated parent IDs.
   */
  const getParentIds = async (arCollateralId: number) => {
    setParentIds([]);

    try {
      const response = await axiosInstance.request({
        url: arCustomersAPI.FIND_PARENT_IDS_BY_AR_COLLATERAL_ID,
        method: GET,
        params: { arCollateralId }
      })
      const parentIds: number[] = response.data;
      setParentIds(parentIds);
    } catch (error) {
      console.log('GET PARENT IDS ERROR: ', error);
    }
  };

  /**
   * This function determines the set states based on the provided type.
   * It maps a type (like 'client' or 'currency') to corresponding state setters.
   *
   * @param type - The type of combo box for which the state setters are needed.
   * @returns - The state setters associated with the specified type.
   */
  const getSetStatesByType = (type: IComboBoxIds) => {
    switch (type) {
      case 'client': return { setOptions: setClients, setSelected: setSelectedClient, setInput: setClientInput };
      case 'arCollateral': return { setOptions: setARCollaterals, setSelected: setSelectedARCollateral, setInput: setARCollateralInput };
      case 'currency': return { setOptions: setCurrencies, setSelected: setSelectedCurrency, setInput: setCurrencyInput }
      default: return { setOptions: setBBPeriods, setSelected: setSelectedBBPeriod, setInput: setBBPeriodInput };
    }
  };

  /**
   * This function sets the selected option based on the specified type and updates the
   * search parameters.
   *
   * @param selected - The selected option.
   * @param type - The type of combo box for which the selection is made.
   * @param setStates - The state setters associated with the specified type.
   */
  const setSelectedByType = (selected: IOption, type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    setStates.setSelected(selected);
    setStates.setInput(selected.label);

    const key = getParamsByType(type, params).key;
    updateSearchParams(key, `${selected.recordId}`);
  };

  /**
   * This function handles the change event for combo boxes, updating the search
   * parameters and resetting states if necessary.
   *
   * @param _event - The triggered synthetic event.
   * @param newValue - The new value chosen in the combo box.
   * @param type - The type of combo box where the change occurred.
   * @param setSelected - The function to set the new selected value.
   */
  const onChange = (_event: SyntheticEvent<Element, Event>, newValue: IOption | null, type: IComboBoxIds, setSelected: Dispatch<SetStateAction<IOption | null>>) => {
    if (isUltimateParent) {
      searchParams.delete('bbPeriodId');
    } else {
      searchParams.delete('endDate');
    }

    if (type === 'client') {
      updateSearchParams('endDate', 'undefined');
      resetStates('bbPeriod', getSetStatesByType('bbPeriod'));
      resetStates('arCollateral', getSetStatesByType('arCollateral'));
      resetStates('currency', getSetStatesByType('currency'));
      const selected = selectedClientContext.clients.find(client => client.recordId === newValue?.recordId);
      selectedClientContext?.setSelectedClient(selected ?? null);
      setToCurrencyIds([]);
    }

    if (type === 'arCollateral') {
      resetStates('bbPeriod', getSetStatesByType('bbPeriod'));
      resetStates('currency', getSetStatesByType('currency'));
    }

    if (type === 'bbPeriod') {
      if (isUltimateParent) {
        updateSearchParams('endDate', `${newValue?.recordId}`);
        setSelected(newValue);
        setToCurrencyIds([]);
        resetTable();
        return;
      } else {
        resetStates('currency', getSetStatesByType('currency'));
      }
    }
    updateSearchParams(getParamsByType(type, params).key, `${newValue?.recordId ?? 0}`);
    setSelected(newValue);
    resetTable();
  };

  /**
   * This function updates the search parameters when the input in a combo box changes.
   *
   * @param _event - The triggered synthetic event.
   * @param newInputValue - The new input value in the combo box.
   * @param type - The type of combo box where the input changed.
   * @param setInput - The function to set the new input value.
   */
  const onInputChange = (_event: SyntheticEvent<Element, Event>, newInputValue: string, type: IComboBoxIds, setInput: Dispatch<SetStateAction<string>>) => {
    setInput(newInputValue);
  };
  
  /**
   * This function resets the states of a specified type and updates the search parameters
   * accordingly.
   *
   * @param type - The type of combo box states to reset.
   * @param setStates - The state setters associated with the specified type.
   */
  const resetStates = (type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    setStates.setOptions([]);
    setStates.setSelected(null);
    setStates.setInput('');
    updateSearchParams(getParamsByType(type, params).key, '0');
  };

  /**
   * This function resets the table state values.
   */
  const resetTable = () => {
    setHeaders([]);
    setARIneligibles([]);
    setCustIneligibles([]);
    setFirstLoad(false);
    setDisplayNoTable(false);
    setRfvDisabled(true);
    setRfvIneligibleAmt(0.0);
    setHasNoRfv(false);
    setIneligibleCapDetails({});
    setRfvIneligibleAmt(0.0);
    setIneligibleViewReport([]);
    setCustViewReport([]);
    setIsChildCustomerOnly(false);
    setGrandTotals([]);
    setRfSummaries([]);
    setReportCodes([]);
    setAllExchangeRates([]);
    setCustIdsWithDetails({});
    setIneligibleCodes([]);
    setAmountTypes({ineligibleView: [], custView: []});
  };

  /**
   * This function updates the search parameters state with a given key-value pair.
   * @param key - The search parameter's key.
   * @param value - The value associated with the key.
   */
  const updateSearchParams = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  /**
   * This function determines which table to render based on the length of `arIneligibles`. 
   * It returns the AR Ineligible Details component if there are AR ineligibles. If
   * `displayNoTable` is true, it shows a message indicating no data. Otherwise, it returns
   * null.
   *
   * @returns - The table component or message to render.
   */
  const getTable = () => {
    const commonProps = {
      arIneligibles,
      totalIneligibleAmt,
      expanded,
      setExpanded,
      isLoading,
      setIsLoading,
      amountTypes,
      viewBy,
      custIneligibles,
      grandTotal,
      headers,
      parentIds,
      currencyCode: selectedCurrency?.label.substring(0, 3),
      exchangeRate,
      rfvIneligibleAmt: !rfvDisabled ? rfvIneligibleAmt : undefined,
      hasNoRfv,
      custIdsWithDetails,
      ineligibleCodes,
      queryParams: {
        borrowerId: parseInt(borrowerId),
        bbPeriodId: parseInt(bbPeriodId),
        arCollateralId: parseInt(arCollateralId),
        endDate,
      },
      isUltimateParent: Boolean(isUltimateParent),
      rfvDisabled,
      ineligibleCapDetails,
      ...sortingProps
    }
    
    if (isLoading) {
      return (<LinearProgress />);
    } else if (displayNoTable) {
      return (
        <Paper sx={styles.paperContainer}>
          <Typography tabIndex={0} align='center'>No data available</Typography>
        </Paper>
      );
    } else if (isUltimateParent && ((ineligibleViewReport.length && viewBy === 'Ineligible') || (custViewReport.length && viewBy === 'Customer'))) {
      return (
        <ClientReports
          {...commonProps}
          bbPeriodLabel={selectedBBPeriod?.label ?? ''}
          collateralRatesMap={collateralRatesMap}
          borrowerReport={ineligibleViewReport}
          borrowerCustReport={custViewReport}
          grandTotals={grandTotals}
          rfSummaries={rfSummaries}
          reportCodes={reportCodes}
          isChildCustomerOnly={isChildCustomerOnly}
          endDate={endDate}
        />
      )
    } else if (arIneligibles.length) {
      return (
        <ARIneligibleDetails {...commonProps} />
      );
    } else return null;
  }

  /**
   * This function returns an object containing handlers for change and input change events
   * that are associated with a specific combo box type.
   *
   * @param type - The type of combo box.
   * @returns - The handlers for change and input change events.
   */
  const getHandleChangeByType = (type: IComboBoxIds) => {
    return {
      onChange: (e: SyntheticEvent<Element, Event>, newValue: IOption | null) => onChange(e, newValue, type, getSetStatesByType(type).setSelected),
      onInputChange: (e: SyntheticEvent<Element, Event>, newInputValue: string) => onInputChange(e, newInputValue, type, getSetStatesByType(type).setInput)
    }
  };

  /**
   * This constant contains props related to sorting, which are spread across various
   * components.
   */
  const sortingProps: ISortingProps = { order, setOrder, orderBy, setOrderBy, custViewOrder, setCustViewOrder, custViewOrderBy, setCustViewOrderBy };

  /**
   * Retrieves collateral options and selected value based on the ultimate parent status.
   * @returns an object with 'options' array and 'value' representing selected collateral.
   */
  const getArCollateralOptionsAndValue = () => {
    const allOpt = {recordId: 0, label: 'All', default: true};

    if (!isUltimateParent) {
      return {
        options: arCollaterals,
        value: selectedARCollateral,
      }
    } else {
      return {
        options: [allOpt],
        value: allOpt,
      }
    }
  };

  const getReportDisplay = () => {
    if (isUltimateParent) {
      return getTable();
    } else {
      return (
        <>
        {viewBy === 'Customer' && Boolean(custIneligibles.length) &&
          <Box sx={styles.totalAmountContainer}>
            <Typography tabIndex={0} sx={styles.totalAmountText} component='label' htmlFor='totalAmount'>{'Total Ineligible Amount'}</Typography>
            <Typography tabIndex={0} sx={styles.totalAmount} component='label' id='totalAmount'>
              {formatCurrency(totalIneligibleAmt, selectedCurrency?.label.substring(0, 3), exchangeRate)}
            </Typography>
          </Box>
        }

        <ReportsTitle clientName={selectedClient?.label ?? ''} reportName='AR Ineligible Report' bbPeriod={selectedBBPeriod?.label ?? ''} />
        <Grid 
          container 
          justifyContent={'end'}
          width={'100%'}
        >
          { exchangeRate !== 1 && selectedCurrency && disabled && !isUltimateParent &&
          <Typography>
            <Box component="span" fontWeight='bold' fontSize={14}>Exchange Rate </Box>
            <Box component="span" fontSize={14}>{`${exchangeRate} ${currencies[1]?.label.substring(0, 3)} = 1 ${currencies[0]?.label.substring(0, 3)}`}</Box>
          </Typography>
          }
        </Grid>
        {getTable()}
        </>
      )
    }
  }

  /**
   * Checks if the export functionality is disabled based on the current view and data availability.
   *
   * @returns `true` if the export is disabled, otherwise `false`.
   */

  const checkIfExportIsDisabled = () => {
    if (isUltimateParent) {
      if (viewBy === 'Ineligible') return ineligibleViewReport.length === 0;
      else return custViewReport.length === 0;
    } else {
      return arIneligibles.length === 0;
    }
  }

  return (
    <>
    {canViewReport ?
      <Box>
      <Box component="span" sx={styles.breadCrumbBox}>
        <Grid container sx={styles.headerContainer}>
          <Grid item xs={12} md={6} lg={8} xl={8.3}>
            <Box sx={{...(fromSettings && {visibility: 'hidden'})}}>
              <GeneralBreadcrumbs selectedText='AR Ineligible' breadcrumbLinks={[{ linkText: 'Reports', route: '/reports' }]} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
            <Box sx={styles.clientBox}>
              <ComboBox
                id='client'
                options={filteredClients}
                value={selectedClient}
                inputValue={clientInput}
                {...getHandleChangeByType('client')}
              />
            </Box>
          </Grid>
        </Grid>   
      </Box>
      
      <ReportHeader
        setExporting={setExporting}
        exportDisabled={checkIfExportIsDisabled()}
        isLoading={exporting}
        selectedClient={selectedClient}
        selectedBBPeriod={selectedBBPeriod}
        selectedARCollateral={selectedARCollateral}
        viewBy={viewBy}
        rateId={rateId}
        currencyId={selectedCurrency?.recordId ?? 0}
        isUltimateParent={isUltimateParent}
        allExchangeRates={allExchangeRates}
        rfvDisabled={rfvDisabled}
        isChildCustomerOnly={isChildCustomerOnly}
        {...sortingProps}
      />

      <Container maxWidth='xl'>
        <Box component='div' sx={styles.filterBox}>
          <Grid container columnSpacing={2} rowSpacing={2} sx={styles.filterGridContainer}>
            <Grid item xs={12} md={3} lg={2}>
              <Box sx={styles.viewByContainer}>
                <Typography tabIndex={0} component='label' htmlFor='viewBy' sx={styles.labelCommon}>
                  View by
                </Typography>
                <ToggleButtonGroup
                  color='primary'
                  value={viewBy}
                  exclusive
                  onChange={(_event: MouseEvent<HTMLElement>, newViewBy: IReportView) => {
                    (newViewBy !== null) && setViewBy(newViewBy)
                  }}
                  aria-label='View Report By'
                  size='small'
                  fullWidth
                  sx={styles.toggleButtonGroup}
                  disabled={isLoading || displayNoTable}
                >
                  <ToggleButton value='Ineligible' sx={styles.toggleButton} data-testid='ineligible-toggle'>Ineligible</ToggleButton>
                  <ToggleButton value='Customer' sx={styles.toggleButton} data-testid='customer-toggle'>Customer</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <ComboBox
                id='arCollateral'
                {...getArCollateralOptionsAndValue()}
                inputValue={arCollateralInput}
                disabled={isUltimateParent}
                {...getHandleChangeByType('arCollateral')}
              />
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <ComboBox
                id='bbPeriod'
                options={bbPeriods}
                value={selectedBBPeriod}
                inputValue={bbPeriodInput}
                {...getHandleChangeByType('bbPeriod')}
              />
            </Grid>

            <Grid item xs={12} md={3} lg={2}>
              <ComboBox
                id='currency'
                options={currencies}
                value={selectedCurrency}
                inputValue={currencyInput}
                disabled={isUltimateParent}
                {...getHandleChangeByType('currency')}
              />
            </Grid>

            <Grid item xs={12} md={3} lg={2}>
              <Grid container direction='row' spacing={0} justifyContent={belowLargeBreakpoint ? 'center' : 'flex-end'} alignItems='flex-end' sx={styles.generateReportBtnContainer}>
                <DisabledComponentsContainer isDisabled={disabled}>
                  <Button
                    variant='contained'
                    onClick={handleGenerate}
                    disabled={disabled}
                    aria-label={disabled ? 'Generate Report button disabled' : 'Generate Report'}
                    sx={styles.generateReportBtn}
                    data-testid='generate-report-button'
                  >
                    <Typography fontSize='0.875rem'>Generate Report</Typography>
                  </Button>
                </DisabledComponentsContainer>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {getReportDisplay()}
        
        <WarningModal
          open={rateModalOpen}
          onClose={() => setRateModalOpen(false)}
          onConfirm={() => navigate(`/clients/${selectedClient?.recordId}/settings/exchangeRate`)}
          noDataIncluded
          issueType='error'
          issueMessages={['There is no exchange rate yet for the selected \'As of Date\' and collateral currency. Click Proceed to visit Exchange Rate page']}
        />
      </Container>
    </Box> : <Box />}
    </>
  );
};

export default ArIneligibleReport;