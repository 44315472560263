import colors from "../../../utility/colors";

const styles = {
  title: {
    fontWeight: 'bold',
    color: '#010953',
    fontSize: '18px',
    ml: '24px',
  },
  titleContainer: {
    backgroundColor: '#FEFEFE',
    py: 2.5
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clientComboBox: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  labelStyle: {
    display: 'block',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '16px'
  },
  dropdownGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  breadCrumbBox: {
    paddingRight: '24px',
    borderRadius: '0.3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  clientDropdown: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  clientBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clientDropdownText: {
    fontSize: '14px'
  },
  labelChipContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  parentChipContainer: {
    width: '100px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  parentIdentifierChip: {
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"P"',
    },
    '& .MuiChip-label:hover:after': {
      content: '"Parent"',
    }
  },
  hidden: {
    display: 'none',
  }
};

export default styles;
