const styles = {
  mainContainer: {
    width: '350px',
    height: '100vh',
    padding: '32px 20px 32px 28px',
    fontSize: '1px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '20px',
  },

  heading: {
    fontSize: '20px',
    color: '#154A8A',
  },

  closeIcon: {
    fontSize: '20px',
    color: '#898383',
  },

  groupLabel: {
    fontSize: '14px',
    color: '#212529',
  },

  label: {
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: '#212529',
    }
  },

  radioGroup: {
    marginBottom: '18px',
  },

  radioButton: {
    '&.MuiButtonBase-root.MuiRadio-root': {
      padding: '10px',
    },

    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },

  checkboxContainer: {
    marginBottom: '5px',
  },

  checkbox: {
    '&.MuiButtonBase-root.MuiCheckbox-root': {
      padding: '10px',
    },

    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },

  buttonsContainer: {
    justifySelf: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
  },

  button: {

  },

  clearSelectionButton: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  saveButton: {
    marginLeft: '8px',
  }
};

export default styles;
