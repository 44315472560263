import colors from "../../../utility/colors"

const styles = {
  accordionSummary:{
    margin: '0px', 
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
    } 
  },
  overflowBox: {
    maxHeight: '80%', 
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#B7B7B7',
    },
  },
  sectionBox:{
    backgroundColor: colors.WHITE, 
    boxSizing: 'border-box',
    height: '38rem', 
    p: '1rem', 
    width: '48%', 
  },
  sectionTableContainer: {
    maxHeight: '85%',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#B7B7B7',
    },
  },
  sectionTableHeaderRow: {
    boxShadow: '0px 3px 6px #00000029',  
    backgroundColor: '#F7F7F7',
  },
  sectionTableSelectCell: {
    width: '5%',
  },
  sectionTableHeaderText: {
    color:"inherit", 
    fontWeight:'bold', 
    fontSize:'0.875rem',
  },
  sectionTableHead: {
    marginBottom: '1rem',
    '& .MuiTableCell-root': {
      backgroundColor: '#F7F7F7',
    }
  },
  orphanHeader:{
    boxShadow: '0px 3px 6px #00000029',  
    backgroundColor: '#F7F7F7', 
    height: '3.2rem', 
    alignItems: 'center', 
    p: '6px 43.8px', 
    mt: '1rem'
  },
  unlinkedTableContainer: {
    maxHeight: '85%',
  },
  unlinkedTableHeaderRow: {
    boxShadow: '0px 3px 6px #00000029',  
    backgroundColor: '#F7F7F7',
  },
  unlinkedTableSelectColumn: {
    width: '5%',
    paddingY: '5px',
    borderBottom: 'none',
  },
  unlinkedTableNameCell: {
    width: '35%',
    paddingY: '5px',
    paddingLeft: 0,
    borderBottom: 'none',
  },
  unlinkedTableHeaderDateCreated: {
    width: '10%',
    paddingY: '5px',
    borderBottom: 'none',
  },
  unlinkedTableHeaderCell: {
    width: '25%',
    paddingY: '5px',
    borderBottom: 'none',
  },
  unlinkedTableHeaderText: {
    color:"inherit", 
    fontWeight:'bold', 
    fontSize:'0.875rem',
  },
  unlinkedTableHead: {
    marginBottom: '1rem',
    
    '& .MuiTableCell-root': {
      backgroundColor: '#F7F7F7', 
    }
  },
  parentWithChildSectionHeader:{
    boxShadow: '0px 3px 6px #00000029',  
    backgroundColor: '#F7F7F7', 
    height: '3.2rem', 
    alignItems: 'center', 
    pl: '2.75rem',
    pr: '1rem', 
    mt: '1rem',
    justifyContent: 'space-between'
  },
  parentWithChildTitle:{
    color:"inherit", 
    fontWeight:'bold', 
    fontSize:'0.875rem', 
  },
  parentNameHeaderCell: {
    width: '25%',
    '&:last-child': {
      width: '5%',
    },
  },
  parentRow: {
    cursor: 'pointer',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    }
  },
  parentSelectCell: {
    width: '2%',
    paddingX: '0px',
    paddingY: '12px',
      borderBottom: 'none',
      '& .MuiTypography': {
      textAlign: 'left'
    }
  },
  parentNameCell: {
    width: '25%',
    paddingY: '12px',
    borderBottom: 'none',
    '& .MuiTypography': {
      textAlign: 'left'
    }
  },
  parentTableCell: {
    paddingY: '12px',
    marginY: '1rem',
    borderBottom: 'none',
    textAlign: 'center',
    '&:last-child': {
      textAlign: 'right',
      width: '5%',
    },
  },
  childrenHeaderSelectCell: {
    width: '2%',
    borderBottom: 'none',
    backgroundColor: '#EAECEF',
  },
  childrenHeaderNameCell: {
    width: '25%',
    textAlign: 'left',
    borderBottom: 'none',
    backgroundColor: '#EAECEF',
  },
  childrenHeaderCell: {
    width: '25%',
    borderBottom: 'none',
    backgroundColor: '#EAECEF',
    '& .MuiTypography-root': {
      textAlign: 'right',
    },
    '&:last-child': {
      width: '5%',
    },
  },
  childrenHeaderTypography: {
    fontWeight: 'normal',
    fontSize: '14px',
  },
  orphanTitle:{
    color:"inherit", 
    fontWeight:'bold', 
    fontSize:'0.875rem', 
    ml: '0.5rem',
    paddingLeft: '18px'
  },
  searchField: {
    backgroundColor: colors.WHITE,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: colors.WHITE,
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
    width          : '100%',
  },
  flexCenter: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  parentModal: {
    width: 1, py: '10px', 
    borderTop: '1px solid #E9ECEF', 
    borderBottom: '1px solid #E9ECEF'
  },
  circleIcon: {
    height: '15px', 
    color: colors.PRIMARY, 
    display: 'flex', 
    alignSelf: 'flex-end'
  },
  parentTitle: {
    width: '50%', 
    fontSize: '16x', 
    textAlign: 'center'
  },
  dropSection: {
    border: '2px dashed #707070', 
    borderRadius: '5px', 
    my: '10px', 
    display: 'flex', 
    justifyContent: 'center', 
    py: '10px'
  },
  dropSecionActive: {
    border: '2px dashed #154A8A',
    color:  '#154A8A'
  },
  button: {
    justifyContent: 'flex-end', 
    width: '100%', 
    pt: '5px'
  },
  renameParent: {
    '& .MuiTextField-root': {
     paddding: 0
    }
  },
  title: {
    fontWeight: 'bold',
    ml: '24px',
    fontSize: '18px', 
    color: colors.PRIMARY
  },
  saveBar: {
    display: 'flex', 
    justifyContent: 'space-between', 
    my: '1rem', 
    width: '100%'
  },
  maxWidth: {
    width: '100%'
  },
  saveCancelButton: {
    display: 'flex', 
    mr: '1rem', 
    columnGap: '10px'
  },
  gridSection: {
    justifyContent: 'space-between', 
    mb: '2.5rem'
  },
  selectionLabels: {
    py: '10px',
    justifyContent: 'space-between',
    bgcolor: '#EAECEF',
  },
  customerLabel: {
    pl: '2.75rem'
  },
  actionLabel: {
    pr: '0.8rem'
  },
  noPermissionBox: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    background: '#FFFFFF',
    padding: '15px'
  },
  parentIdentifierChip: {
    marginLeft: '15px',
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"P"',
    },
    '& .MuiChip-label:hover:after': {
      content: '"Parent"',
    }
  },
  tableSpacer: {
    height: '1rem',
  },
  newTagBox: { 
    display: 'flex', 
    justifyContent: 'right', 
    width: '41px', 
    textDecoration: 'none' 
  },
  customerRowNameCellBox: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
  },
  customerRowNameIdBox: { 
    display: 'flex', 
    flexDirection: 'column' 
  },
  clearButton: {
    color: colors.PRIMARY_LIGHT,
    fontSize: '14px',
  },
  clearButtonBox: {
    height: '1rem',
    display: 'flex', 
    justifyContent: 'flex-end', 
    paddingBottom: '1rem',     
  }
}

export default styles