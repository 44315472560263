import { createContext, useMemo, useState } from 'react';
import { IClient, ICollateral } from '../interfaces/ineligibleAdjustmentsInterface';
import { LayoutProps } from './authContext';

const loadingClient: IClient = {
  recordId: -1,
  borrowerName: 'Loading...',
};

const loadingCollateral: ICollateral = {
  recordId: -1,
  collateralName: 'Loading...',
};

export interface IIneligibleAdjustmentsContext {
  clients: Array<IClient>;
  setClients: (clients: Array<IClient>) => void;
  selectedClient: IClient;
  setSelectedClient: (selectedClient: IClient) => void;
  selectedCollateral: ICollateral;
  setSelectedCollateral: (collateralName: ICollateral) => void;
  currentArCollateral: ICollateral;
  setCurrentArCollateral: (collateralName: ICollateral) => void;
  currentInvCollateral: ICollateral;
  setCurrentInvCollateral: (collateralName: ICollateral) => void;
  lastSelectedClient: IClient | null;
  setLastSelectedClient: (lastSelectedClient: IClient | null) => void;
  totalAmount: number;
  setTotalAmount: (amount: number) => void;
  showPrompt: boolean;
  setShowPrompt: (showPrompt: boolean) => void;

  viewIA: boolean;
  setViewIA: (value: boolean) => void;
  addIA: boolean;
  setAddIA: (value: boolean) => void;
  updateIA: boolean;
  setUpdateIA: (value: boolean) => void;
  deleteArchiveIA: boolean;
  setDeleteArchiveIA: (value: boolean) => void;
}

export const IneligibleAdjustmentsContext = createContext<IIneligibleAdjustmentsContext | null>(null);

const IneligibleAdjustmentsProvider = (props: LayoutProps) => {
  const { children }                                      = props;
  const [clients, setClients]                             = useState<IClient[]>([]);
  const [selectedClient, setSelectedClient]               = useState<IClient>(loadingClient);
  const [selectedCollateral, setSelectedCollateral]       = useState<ICollateral>(loadingCollateral);
  const [currentArCollateral, setCurrentArCollateral]     = useState<ICollateral>(selectedCollateral);
  const [currentInvCollateral, setCurrentInvCollateral]   = useState<ICollateral>(selectedCollateral);
  const [lastSelectedClient, setLastSelectedClient]       = useState<IClient | null>(null); // for storing last selected client for confirm navigation modal
  const [totalAmount, setTotalAmount]                     = useState(0);
  const [showPrompt, setShowPrompt]                       = useState(false); // confirmation modal for manual navigation warning

  const [viewIA, setViewIA]                               = useState<boolean>(false); 
  const [addIA, setAddIA]                                 = useState<boolean>(false); 
  const [updateIA, setUpdateIA]                          = useState<boolean>(false); 
  const [deleteArchiveIA, setDeleteArchiveIA]             = useState<boolean>(false);

  const value = useMemo(() => ({
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    selectedCollateral,
    setSelectedCollateral,
    currentArCollateral,
    setCurrentArCollateral,
    currentInvCollateral,
    setCurrentInvCollateral,
    lastSelectedClient,
    setLastSelectedClient,
    totalAmount,
    setTotalAmount,
    showPrompt,
    setShowPrompt,
    viewIA,
    setViewIA,
    addIA,
    setAddIA,
    updateIA,
    setUpdateIA,
    deleteArchiveIA,
    setDeleteArchiveIA
  }), [
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    selectedCollateral,
    setSelectedCollateral,
    currentArCollateral,
    setCurrentArCollateral,
    currentInvCollateral,
    setCurrentInvCollateral,
    lastSelectedClient,
    setLastSelectedClient,
    totalAmount,
    setTotalAmount,
    showPrompt,
    setShowPrompt,
    viewIA,
    setViewIA,
    addIA,
    setAddIA,
    updateIA,
    setUpdateIA,
    deleteArchiveIA,
    setDeleteArchiveIA
  ])

  return (
    <IneligibleAdjustmentsContext.Provider
      value={value}
    >
      {children}
    </IneligibleAdjustmentsContext.Provider>
  );
};

export default IneligibleAdjustmentsProvider;
