import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import styles from "./styles";
import { IHeader } from "../..";
import { IClientReportsProps } from "..";
import { FC, useMemo } from "react";
import { IGrandTotals } from "../../../../../interfaces/arIneligibleReport";
import { formatCurrency } from "../../../../../utility/helper";

export interface IUpcGrandTotalRowProps extends IClientReportsProps {

}

const UpcGrandTotalRow: FC<IUpcGrandTotalRowProps> = (props) => {
  const { currencyCode, viewBy, rfvDisabled, grandTotals, reportCodes } = props;

  /**
   * This memoized value of styles is used for determining the style based on whether
   * the report is in ineligible or customer view.
   */
  const viewBasedStyles = useMemo(() => {
    if (viewBy === 'Customer') {
      return ({
        tableContainer: styles.tableContainerCustView,
        tableActionFooter: styles.tableActionFooterCustView,
        tableTextFooter: styles.tableTextFooterCustView,
        tableTextBodyLeft: styles.tableTextBodyLeftCustView,
        tableTextBodyNumber: styles.tableTextBodyNumberCustView,
        tableTextBodyIneligibleAmt: styles.tableTextBodyIneligibleAmtCustView,
      })
    } else {
      return ({
        tableContainer: styles.tableContainerIneligibleView,
        tableActionFooter: styles.tableActionFooterIneligibleView,
        tableTextFooter: styles.tableTextFooterIneligibleView,
        tableTextBodyLeft: styles.tableTextBodyLeftIneligibleView,
        tableTextBodyNumber: styles.tableTextBodyNumberIneligibleView,
        tableTextBodyIneligibleAmt: styles.tableTextBodyIneligibleAmtIneligibleView,
      })
    }
  }, [viewBy])

  return (
    <TableContainer sx={viewBasedStyles.tableContainer}>
      <Table aria-label={`Grand Totals`} size='small'>
        <TableBody>
          <TableRow>
            <TableCell tabIndex={0} sx={{...viewBasedStyles.tableActionFooter, ...styles.borderedTop}}></TableCell>
            <TableCell tabIndex={0} sx={{...viewBasedStyles.tableTextFooter, ...viewBasedStyles.tableTextBodyLeft, ...styles.borderedTop}}>
            </TableCell>
            {reportCodes?.map((header: IHeader) => {
              return (
                <TableCell
                  tabIndex={0}
                  key={header.id}
                  sx={{
                    ...viewBasedStyles.tableTextFooter,
                    ...viewBasedStyles.tableTextBodyNumber,
                    ...styles.borderedTop
                  }}
                >
                  {header.label}
                </TableCell>)
            })}
            <TableCell
              tabIndex={0}
              sx={
                {
                  ...viewBasedStyles.tableTextFooter,
                  ...viewBasedStyles.tableTextBodyNumber,
                  ...viewBasedStyles.tableTextBodyIneligibleAmt,
                  ...styles.borderedTop
                }
              }
            >
              Ineligible Amount
            </TableCell>
            <TableCell
              tabIndex={0}
              sx={
                {
                  ...viewBasedStyles.tableTextFooter,
                  ...viewBasedStyles.tableTextBodyNumber,
                  ...styles.borderedTop
                }
              }
            >
              Eligible AR
            </TableCell>
          </TableRow>

          {grandTotals?.map((grandTotal: IGrandTotals) => {
            if (grandTotal.row === 'Roll Forward Variance' && rfvDisabled) {
              return null;
            } else {
              return (
                <TableRow key={grandTotal.rowNum}>
                  <TableCell tabIndex={0} sx={{...viewBasedStyles.tableActionFooter, ...styles.tablePadding}}></TableCell>
                  <TableCell tabIndex={0} sx={{...viewBasedStyles.tableTextFooter, ...viewBasedStyles.tableTextBodyLeft, ...styles.tablePadding}}>
                    {grandTotal.row}
                  </TableCell>
                  {reportCodes?.map((header: IHeader) => {
                      const amount = header.id === 'Gross AR' ? -grandTotal[header.id] : grandTotal[header.id];
  
                      return (
                        <TableCell
                          tabIndex={0}
                          key={header.id}
                          sx={{
                            ...viewBasedStyles.tableTextFooter,
                            ...viewBasedStyles.tableTextBodyNumber,
                            ...styles.tablePadding
                          }}
                        >
                          {(amount === 0 || Boolean(amount))
                            && formatCurrency(amount, currencyCode)}
                        </TableCell>)
                  })}
                  <TableCell
                    tabIndex={0}
                    sx={{
                      ...viewBasedStyles.tableTextFooter,
                      ...viewBasedStyles.tableTextBodyNumber,
                      ...styles.tablePadding
                    }}
                  >
                    {(grandTotal['ineligibleAmount'] === 0 || Boolean(grandTotal['ineligibleAmount']))
                      && formatCurrency(grandTotal['ineligibleAmount'], currencyCode)}
                  </TableCell>
                  <TableCell
                    tabIndex={0}
                    sx={{
                      ...viewBasedStyles.tableTextFooter,
                      ...viewBasedStyles.tableTextBodyNumber,
                      ...styles.tablePadding
                    }}
                  >
                    {(grandTotal['eligibleAr'] === 0 || Boolean(grandTotal['eligibleAr']))
                      && formatCurrency(-(grandTotal['eligibleAr'] as number), currencyCode)}
                  </TableCell>
                </TableRow>
              )
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UpcGrandTotalRow;