import React from 'react';
import {
  Breadcrumbs,
  Link,
  Typography,
} from '@mui/material/';
import { Link as RouterLink } from 'react-router-dom';
import styles from './styles';

interface IBreadCrumbLink {
  linkText: string;
  route: string;
}

interface IGeneralBreadcrumbsProps {
  selectedText: string;
  breadcrumbLinks?: Array<IBreadCrumbLink>;
}

const GeneralBreadcrumbs: React.FC<IGeneralBreadcrumbsProps> = (props) => {
  return (
    <div>
      <Breadcrumbs aria-label='breadcrumb' sx={styles.breadcrumb}>
        {
          props.breadcrumbLinks?.map(breadcrumLink => (
            <Link key={`${breadcrumLink.linkText}-${breadcrumLink.route}`} component={RouterLink} to={breadcrumLink.route} underline='none'>
              <Typography sx={styles.linkText}>{breadcrumLink.linkText}</Typography>
            </Link>
          ))
        }
        <Typography tabIndex={0} sx={styles.selectedText}>{props.selectedText}</Typography>
      </Breadcrumbs>
    </div>
  );
};

export default GeneralBreadcrumbs;
