const styles = {
  modal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '650px',
    overflow: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '0.3rem',
    boxShadow: 24,
    p: '1.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalTitleCursor: {
    cursor: 'move',
  },

  buttonText: {
    color: '#010953',
    fontSize: '14px',
    fontWeight: 'normal',
    marginLeft: '5px'
  },

  iconColor: {
    color: '#154A8A',
    height: '20px'
  },

  divider: {
    border: '0.5px solid #154A8A',
    marginY: 0.5,
    marginX: 0.5,
  },

  disabledDivider: {
    borderColor: '#A6A8AA',
    borderLeftWidth: '1px',
    marginY: 0.5,
    marginX: 0.5,
  },

  title: {
    color: '#154A8A',
    fontWeight: 'bold',
    marginRight: 'auto',
  },

  titleContainer: {
    borderBottom: '1px solid #E9ECEF',
    marginBottom: '20px',
  },

  actionsIcon: {
    fontSize: 25,
    color: '#154A8A',
    padding: '0',
    ':hover': {
      cursor: 'pointer',
    },
  },

  actionsIconButton: {
    '&.MuiButton-root': {
      padding: 2,
    }
  },

  disabledIcon: {
    color: '#A6A8AA',
    padding: 0,
  },

  hidden: {
    display: 'none'
  }
}

export default styles