const styles ={
  bulletIcon: {
    fontSize: '0.5rem',
    marginTop: '0.4rem',
    marginRight: '1rem'
  },
  confirmMessage: {
    whiteSpace: 'pre-line'
  },
  confirmMessageContainer: {
    display: 'flex',
    placeContent: 'flex-start',
    marginLeft: '1.5rem',
    marginTop: '-1.5rem',
    marginBottom: '1.5rem'
  },
  breadcrumbsContainer: {
    marginLeft: '-1.5rem'
  },
  clientContainer:{
    display        : 'flex',
    justifyContent : 'flex-end',
    paddingRight   : '1.5rem'
  },
  clientDropdownBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  comboBox: {
    fontSize: '14px',
    backgroundColor: 'white',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  dropdownTitle: {
    alignSelf      : 'center',
    fontSize       : '14px',
    marginRight    : '1rem',
    minWidth       : 'max-content',
  },
  pageStyle: {
		backgroundColor     : '#F2F7F8',
		minHeight           : 'calc(100vh - 4rem)',
	},
  pageMargin: {
    my                  : '20px',
    mx                  : '40px'
  },
  stepperMargin: {
    mx                  : '120px'
  },
  titleContainer: {
		backgroundColor     : '#FEFEFE',
		py                  : 2.5
	},
  title: {
		fontWeight          : 'bold',
		color               : '#010953',
		fontSize            : '18px',
		ml                  : '24px',
	},
  stepperCircle : {
    '.css-ascpo7-MuiStepLabel-root' : {
      display           : 'block'
    },
    '.css-1jufgy6-MuiSvgIcon-root-MuiStepIcon-root': {
      color             : '#bdbdbd',
      borderStyle       : 'solid',
      borderColor       : '#bdbdbd',
      borderRadius      : '50px',
      borderWidth       : 'thin',
      height            : '35px',
      width             : '35px'
    },
    '.css-1jufgy6-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
      color             : '#154A8A',
      borderStyle       : 'solid',
      borderColor       : '#154A8A',
      borderRadius      : '50px',
      borderWidth       : 'thin',
    },
    '.css-1jufgy6-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed' : {
      color             : '#154A8A',
      borderStyle       : 'none',
    },
    '.css-110v6rl-MuiStepLabel-label' : {
      color             : '#154A8A',
    },
    '.css-vnkopk-MuiStepLabel-iconContainer' : {
      justifyContent    : 'center'
    },
    '.css-117w1su-MuiStepIcon-text': {
      fill              : 'white'
    },
    '.css-z7uhs0-MuiStepConnector-line' :{
      borderColor       : '#bdbdbd',
      borderTopStyle    : 'solid',
      borderTopWidth    : '3px',
      marginBottom      : '1rem'
    },
  },
  stepperButtonContainer: {
    display             : 'flex', 
    flexDirection       : 'row',
    justifyContent      : 'flex-end',
    pt: 2,
  },
  primaryButton:{
    height              : '31px',
    textTransform       : 'capitalize',
    width               : '82px',
  },
  secondaryButton: {
    height              : '31px',
    mr                  : 1,
    textTransform       : 'capitalize',
    width               : '82px',
  },
  hidden: {
    display             : 'none'
  },
}
export default styles