import { useCallback, useContext, useMemo } from "react";
import { IBBPeriod, IClient } from "../../../../../interfaces";
import ARAgingReportHeader from "../report-header";
import { ARAgingReportContext } from "../../../../../context/arAgingReportContext";
import { IARAgingReportContext } from "../../../../../interfaces/arAgingReportInterface";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { convertNumberBasedOnRate, formatNumberBasedOnCurrency } from "..";
import { ICurrency } from "../../../../../interfaces/multiCurrencyInterface";
import styles from "./styles";
import ARAgingCollateralDetailContainer from "../collateral-detail-container";
import { formatDate } from "../../../../../utility/helper";
import { AMERICAN_DATE_FORMAT } from "../../../../../utility/constants";
import { IAccountsReceivableSetting } from "../../../../../components/client-settings/tabs/tab-panel-contents/accounts-receivable";

export interface IARAgingChildClientDetailContainerProps {
  childClient: IClient
}

const ARAgingChildClientDetailContainer: React.FC<IARAgingChildClientDetailContainerProps> = (props)  => {
  const { childClient } = props;
  const {
    selectedAsOfDate, selectedCurrency, arCollaterals, exchangeRates, reports, buckets, filterText
  }  = useContext(ARAgingReportContext) as IARAgingReportContext;

  const currentARSettings = useMemo(() =>
    arCollaterals.filter(setting => setting.arCollateral.borrowerId === childClient.recordId),
  [childClient, arCollaterals]);

  const getExchangeRateForCollateral = useCallback((arSetting: IAccountsReceivableSetting) => {
    if (selectedAsOfDate === null) { return null; }
    const exchangeRate = exchangeRates.find(exchangeRate =>
      exchangeRate.toCurrencyId.toString() === arSetting.arBorrowerInput.currency &&
      formatDate(exchangeRate.asOfDate as string, AMERICAN_DATE_FORMAT) === formatDate((selectedAsOfDate as IBBPeriod).endDate, AMERICAN_DATE_FORMAT)
    );
    return exchangeRate ?? null;
  }, [exchangeRates, selectedAsOfDate]);

  const getChildClientTotalPerBucket = useCallback(() => {
    const reportsForChildClient = reports.filter(report => report.borrowerId === childClient.recordId);
    const formattedReportsByCollateralRate = reportsForChildClient.map(report => {
      const arSetting = arCollaterals.find(arSetting => arSetting.arCollateral.recordId === report.arCollateralId);
      if (arSetting === undefined) { return report; }
      const exchangeRate = getExchangeRateForCollateral(arSetting);
      const convertedCalcAgingBucket = Object.fromEntries(
        Object.entries(report.calcAgingBucket).map(([bucket, amount]) => [bucket, convertNumberBasedOnRate(amount, exchangeRate) ])
      );
      return ({
        ...report,
        total: convertNumberBasedOnRate(report.total, exchangeRate),
        calcAgingBucket: convertedCalcAgingBucket,
      });
    });
    return formattedReportsByCollateralRate.reduce((totalAmountByBucket, currentReport) => {
      const totalAmountByBucketToUpdate = { ...totalAmountByBucket };
      Object.entries(currentReport.calcAgingBucket).forEach(([bucket, amount]) => {
        if (totalAmountByBucketToUpdate[bucket] === undefined) { totalAmountByBucketToUpdate[bucket] = amount }
        else { totalAmountByBucketToUpdate[bucket] = totalAmountByBucketToUpdate[bucket] + amount; }
      });
      return totalAmountByBucketToUpdate;
    }, {} as {[bucket: string]: number});
  }, [arCollaterals, childClient.recordId, getExchangeRateForCollateral, reports]);

  const getContentForClientTotal = () => {
    if (filterText !== '') { return <></>; }
    const totalPerBucket = getChildClientTotalPerBucket();
    const childClientTotal = Object.values(totalPerBucket).reduce((childClientTotal, bucketTotal) => childClientTotal + bucketTotal, 0);
    const formattedChildClientTotal = formatNumberBasedOnCurrency(childClientTotal, selectedCurrency as ICurrency);
    return (
      <Table stickyHeader size='small' sx={styles.tableForClientTotal}>
        <TableBody>
          <TableRow>
            <TableCell sx={{ ...styles.tableFooterText, ...styles.widthActionButton }}></TableCell>
            <TableCell tabIndex={0} sx={{ ...styles.tableFooterText, ...styles.widthCustomerId }}> Client Total </TableCell>
            <TableCell sx={{...styles.tableFooterText, ...styles.widthCustomerName }}></TableCell>
            <TableCell tabIndex={0} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }} align='right'>
              { formattedChildClientTotal !== 0 ? formattedChildClientTotal : <></> }
            </TableCell>
            {
              buckets.map(bucket => {
                const formattedBucketTotal = formatNumberBasedOnCurrency(totalPerBucket[bucket] ?? 0, selectedCurrency as ICurrency);
                return (
                  <TableCell tabIndex={0} align='right' key={bucket} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }}>
                    { formattedBucketTotal !== 0 ? formattedBucketTotal : <></> }
                  </TableCell>
                );
              })
            }
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const getMainContent = () => {
    const reportsForChildClient = reports.filter(report => report.borrowerId === childClient.recordId);
    if (reportsForChildClient.length === 0) { return <></>; }
    return (
      <>
        <ARAgingReportHeader selectedExchangeRate={null} childClient={childClient} />
        { currentARSettings.map(arSetting => <ARAgingCollateralDetailContainer arSetting={arSetting} /> ) }
        { getContentForClientTotal() }
      </>
    );
  };

  return getMainContent();
};

export default ARAgingChildClientDetailContainer;