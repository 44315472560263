import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import FileImportTable from './table';
import { Box, Grid, Typography } from '@mui/material';
import styles from './styles';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { IComboBoxIds, IComboBoxSetStates, IOption } from '../../../interfaces/comboBox';
import { checkUserPermissions, getClientRequest, getLocalStorageItem, getOptions, getPermissionsOfUser } from '../../../utility/helper';
import GeneralBreadcrumbs from '../../../components/breadcrumb';
import ComboBox from '../../../components/common/combo-box';
import { AuthContext } from '../../../context/authContext';
import { PERMISSIONS } from '../../../utility/constants';
import { FileImportPermissionsContext, IFileImportPermissionsContext } from '../../../context/fileImportPermissionsContext';

/**
 * Component for the File Import Page.
 * @returns A component for the File Import Page.
 */
const FileImport = () => {
  const selectedClientContext               = useContext(SelectedClientContext);
  const {state}                             = useContext(AuthContext);
  const {
    setViewFileImport,
    setImportFile,
    setDownloadFile,
    setArchiveFile
  }                                         = useContext(FileImportPermissionsContext) as IFileImportPermissionsContext
  const [clients, setClients]               = useState<IOption[]>([]);
  const [selectedClient, setSelectedClient] = useState<IOption | null>(null);
  const [clientInput, setClientInput]       = useState<string>('');
  const [canViewPage, setCanViewPage]       = useState<boolean>(false);

  /**
   * This useEffect hook fetches file import permissions based on roles.
   */
   useEffect(() => {
    (async () => {
      await getPermissions();
      const isPermitted: boolean | undefined = await checkPermissions();

      if (isPermitted) {
        const params = { borrowerId: getLocalStorageItem('selectedClientRecordId'), invCollateralId: '0', bbPeriodId: '0', arCollateralId: '0', customerId: '0' };
        if (params.borrowerId) {
          await getOptions({ type: 'client', params, getSetStatesByType, setSelectedByType, mainRequest: getClientRequest()});
        }
      }
    })();
  },[]);

  /**
   * This function asynchronously verifies if the currently logged in user has the permission
   * to view the File Import page. If so, it updates the state to allow the user
   * to view the File Import page.
   */
  const checkPermissions = async () => {
    const isPermitted = await checkUserPermissions(state.uid, PERMISSIONS.VIEW_FILE_IMPORT);
    setCanViewPage(Boolean(isPermitted));
    return isPermitted;
  }

  /**
   * This function asynchronously fetches the permissions of the current user:
   * - View the File Import page.
   * - Import a File.
   * - Download a File.
   * - Archive a File.
   */
  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(state.uid)
    setViewFileImport(permissions.includes(PERMISSIONS.VIEW_FILE_IMPORT));
    setImportFile(permissions.includes(PERMISSIONS.IMPORT_FILE));
    setDownloadFile(permissions.includes(PERMISSIONS.DOWNLOAD_FILE));
    setArchiveFile(permissions.includes(PERMISSIONS.ARCHIVE_FILE))
  }

  /**
   * This function determines the set states based on the provided type.
   * It maps a type (like 'client' or 'currency') to corresponding state setters.
   *
   * @param type The type of combo box for which the state setters are needed.
   * @returns The state setters associated with the specified type.
   */
  const getSetStatesByType = (_type: IComboBoxIds) => {
    return { setOptions: setClients, setSelected: setSelectedClient, setInput: setClientInput };
  };

  /**
   * This function sets the selected option based on the specified type and updates the
   * search parameters.
   *
   * @param selected The selected option.
   * @param type The type of combo box for which the selection is made.
   * @param setStates The state setters associated with the specified type.
   */
  const setSelectedByType = (selected: IOption, _type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    selectedClientContext?.setSelectedClient(selected);
    setStates.setSelected(selected);
    setStates.setInput(selected.label);
  };

  /**
   * This function returns the onChange function for the change event for combo boxes
   * and the onInputChange function for the change event on when the input in a combo box changes.
   */
  const getHandleChange = () => {
    return {
      onChange: (_e: SyntheticEvent<Element, Event>, newValue: IOption | null) => {
        const mapped = { recordId: newValue?.recordId, borrowerName: newValue?.label, parentClient: newValue?.parentClient, parentClientFk: newValue?.parentClientFk }
        selectedClientContext?.setSelectedClient(mapped);
        setSelectedClient(newValue);
      },
      onInputChange: (_e: SyntheticEvent<Element, Event>, newInputValue: string) => setClientInput(newInputValue)
    }
  };

  return (
    <>
    {canViewPage &&
      <Box sx={styles.pageStyle}>
        <Box sx={styles.breadcrumbsBox}>
          <Grid container sx={styles.headerContainer}>
            <Grid item xs={12} md={6} lg={8} xl={8.3}>
              <GeneralBreadcrumbs selectedText='File Import' breadcrumbLinks={[{ linkText: 'Clients', route: '/clients' }]} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
              <Box sx={styles.clientBox}>
                <ComboBox
                  id='client'
                  options={clients}
                  value={selectedClient}
                  inputValue={clientInput}
                  {...getHandleChange()}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={styles.titleContainer}>
          <Typography tabIndex={0} variant='h6' component='h3' sx={styles.title}>File Import</Typography>
        </Box>
          <FileImportTable client={selectedClient}/>
      </Box>}
    </>
  );
};

export default FileImport;