import { createContext, useState, useEffect, useContext, useMemo } from 'react';
import ConfirmModal from '../components/modals/confirm-modal';
import { LayoutProps } from './authContext';
import { ClientSettingsContext } from './clientSettingsContext';

type IPages = (number | { label: string; value: number; })[];

export interface IClientSettingsPaginationContext {
  pages: IPages;
  page: number;
  setPage: (page: number) => void;
  count: number;
  setCount: (count: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  changePage: (event: unknown, newPage: number) => void;
  changeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const ClientSettingsPaginationContext = createContext<IClientSettingsPaginationContext | null>(null);

const ClientSettingsPaginationProvider = (props: LayoutProps) => {
  const { children } = props;

  const pages = [10, 15, 20, 100 ];
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  /*Prompt State Values*/
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [tempPage, setTempPage] = useState<number>(0);
  const [tempRowsPerPage, setTempRowsPerPage] = useState(10);

  const clientSettingsContext = useContext(ClientSettingsContext);

  const changePage = (_event: unknown, newPage: number) => {
    if (clientSettingsContext?.isDirty) {
      setTempPage(newPage);
      setShowPrompt(true);
    } else {
      setPage(newPage);
      setTempPage(newPage);
    }
  };

  const changeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (clientSettingsContext?.isDirty) {
      setTempRowsPerPage(parseInt(event.target.value, 10));
      setShowPrompt(true);
    } else {
      setRowsPerPage(parseInt(event.target.value, 10));
      setTempRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [count, rowsPerPage]);

  const value = useMemo(() => ({
    pages,
    page,
    setPage,
    count,
    setCount,
    rowsPerPage,
    setRowsPerPage,
    changePage,
    changeRowsPerPage,
  }), [
    pages,
    page,
    setPage,
    count,
    setCount,
    rowsPerPage,
    setRowsPerPage,
    changePage,
    changeRowsPerPage
  ])

  return (
    <ClientSettingsPaginationContext.Provider
      value={value}
    >
      <ConfirmModal
        open={showPrompt}
        onConfirm={() => setShowPrompt(false)}
        onClose={() => {
          setPage(tempPage);
          setRowsPerPage(tempRowsPerPage);
          setShowPrompt(false);
          clientSettingsContext?.setIsDirty(false);
        }}
        onButtonClose={() => setShowPrompt(false)}
        promptChecker={true}
        title={`Confirm Navigation`}
        description={`You have unsaved changes. Are you sure you want to leave this page?`}
        yesButtonText="Keep Editing"
        noButtonText="Discard Changes"
        confirmOnly
      />
      {children}
    </ClientSettingsPaginationContext.Provider>
  );
};

export default ClientSettingsPaginationProvider;
