import { AlertColor, Box, Grid, Typography } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import styles from './styles'
import { FC, useContext, useEffect } from 'react';
import { FileImportProcessContext } from '../../../../../context/fileImportProcessContext';
import { setFileImportProcessLoader } from '../../../../../actions/fileImportProcessActions';
import { FileImportContext, IFileImportContext } from '../../../../../context/fileImportContext';
import { IUploadedFile } from '../../../../../interfaces/fileimport';
import { IDocumentAttribute } from '../../../../../interfaces/dataMap';

interface IImportTabProps {
  showToaster     : (severity: AlertColor, message: string) => void
}

export interface IProcessingAPIs {
  STAGE           : string;
  STAGE_CSV?      : string;
  PROCESS         : string;
  CLEAN           : string;
}

export interface IProcessingRequests {
  url   : string
  method: string;
  params: {
    batchSize       : number;
    documentId?     : number;
    arCollateralId? : number;
    bbPeriodId?     : number;
  }
}

/**
 * Component for the Import Step of the Upload Files.
 * @param props The props for the Import Step of the Upload Files.
 * @returns A component for the Import Step of the Upload Files.
 */
const ImportTab: FC<IImportTabProps> = () => {
  const { 
    selectedClient,
    uploadedFiles, setUploadedFiles,
    userDefinedFields
  }                                          = useContext(FileImportContext) as IFileImportContext;
  const { dispatch }                         = useContext(FileImportProcessContext);

  useEffect(() => {
    const toImport: IUploadedFile[] = [...uploadedFiles]
      .filter(document => document.mapped)
      .sort((a, b) => (a.recordId ?? 0) - (b.recordId ?? 0));

    const forLoader = getLoadingDocuments(toImport);

    dispatch(setFileImportProcessLoader(
      getFileImportProcessActionPayload(true, forLoader, setUploadedFiles, userDefinedFields)
    ));
  }, [])

  /**
   * This function prepare a list of documents with loading status.
   * @param toImport The list of documents to be processed.
   * @returns An array of documents with loading status.
   */
  const getLoadingDocuments = (toImport: IUploadedFile[]) => {
    return toImport.map(document => {
      const load: IUploadedFile = {
        recordId: document.recordId,
        borrowerFk: document.borrowerFk,
        documentTypeFk: document.documentTypeFk,
        documentType: document.documentType,
        arCollateralFk: document.arCollateralFk,
        bbPeriodFk: document.bbPeriodFk,
        filename: document.filename,
        dataMappingFk: document.dataMappingFk,
        processed: document.processed,
        mapped: document.mapped,
        archive: document.archive,
        shouldReplace: document.shouldReplace,
        hasPrevious: document.hasPrevious,
        isLoading: true,
      }
      return load;
    });
  };

  /**
   * This function generates a payload object for a file import process action.
   * @param isLoading Indicates whether the action is in progress (true) or not (false).
   * @param updatedDocuments An array of updated uploaded documents.
   * @param content Additional content or message for the action payload.
   * @returns A payload object for the file import process action.
   */
  const getFileImportProcessActionPayload = (
    isLoading: boolean,
    updatedDocuments: IUploadedFile[],
    setUploadedFiles: React.Dispatch<React.SetStateAction<IUploadedFile[]>>,
    userDefinedFields: IDocumentAttribute[],
    content: string = ''
  ) => {
    return {
        content,
        isLoading: isLoading,
        show: true,
        link: '/',
        cancelled: false,
        selectedClientId: selectedClient?.recordId ?? null,
        documents: updatedDocuments,
        setUploadedFiles,
        userDefinedFields
    }
  };

  return(
    <Grid container sx={styles.instructionContainer} >
      <Grid item>
        <Box sx={styles.importSuccess}>
          <InsertDriveFileIcon tabIndex={0} sx={styles.dropFileIcon} />
        </Box>
        <Typography tabIndex={0} sx={styles.importSuccess}>
          {'Your files are being imported.'}
        </Typography>
        <Typography tabIndex={0} sx={styles.importSuccess}>
          {'We will navigate you to the file import page shortly.'}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ImportTab;