const styles = {
  topContainer: {
    mt: '28.15px',
    px: '23.85px',
  },

  dropdownTextLabels: {
    display: 'block',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    mb: '8px'
  },

  clientDropdownTextLabels: {
    display: 'block',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    mr: '16px'
  },

  dropdown: {
    fontSize: '14px',
    backgroundColor: 'white',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },

  generateReportButton: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '10rem',
    height: '36.6px',
    ':hover': {
      backgroundColor: '#0069D9',
    },
  },

  clientDropdown: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  clientBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  breadCrumbBox: {
    paddingRight: '24px',
    borderRadius: '0.3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
};

export default styles;
