const styles = {
    boxContainer1: {
      backgroundColor: '#fff',
      paddingY: '24.2px',
      marginY: '1rem',
      paddingX: '32.62px',
    },
    title: {
      fontSize: '18px',
      fontWeight: "bold",
      color: "#010953"
    },
    boxContainer2: {
      border: '1px solid #E0E0E0',
      m: '1rem',
      borderRadius: '5px'
    },
    childTabHeader: {
      backgroundColor: "#FEFEFE"
    },
    buttonTabs: {
      '.MuiTabs-Box': {
        border: '1px solid #E0E0E0',
      },
      '.MuiTabs-scroller': {
        background: '#F7F7F7',
      },
      '.MuiButtonBase-root.MuiTab-root': {
        borderRadius: '3px 3px 0 0',
        textTransform: 'none',
        width: '196px',
        marginRight: '5px',
        boxShadow: '0 -5px 30px -7px #E0E0E0',
        color: '#000000',
      },
      '.MuiButtonBase-root.MuiTab-root.Mui-selected': {
        background: 'white',
        color: '#000000',
      },
      '.MuiTabs-indicator': {
        display: 'none',
      },
      '.MuiTabs-flexContainer': {
        paddingTop: '10px',
      },
    },
    hidden: {
      display: 'none'
    }
  };
  
  export default styles;