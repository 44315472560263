import { useEffect, useState, forwardRef, FC, Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { AlertColor, Box, Button, CircularProgress, Divider, FormLabel, Grid, TextField } from '@mui/material';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import NumberFormat, { InputAttributes } from 'react-number-format';
import bankProfileSchema from '../../../schemas/lenderProfileSchema';
import { API_DOMAIN, CONTAINS_CHARACTER_REGEX, GET, NO_PERMISSION_MSG, PERMISSIONS, PUT } from '../../../utility/constants';
import LenderAvatarUpload from '../../../components/common/LenderAvatarUpload';
import Toaster from '../../../components/toaster';
import styles from './styles';
import Prompt from '../../../components/modals/prompt';
import axiosInstance from '../../../service/axiosInstance';
import { BankLogoContext } from '../../../context/bankLogoContext';
import { blobToBase64, checkUserPermissions, getLocalStorageItem } from '../../../utility/helper';
import { setNewLogoDataUrl } from '../../../actions/bankLogoActions';
import { LenderSettingsContext } from '../../../context/lenderSettingsContext';
import HelperTextComponent from '../../../components/common/helper-text-component';
import DisabledComponentsContainer from '../../../components/common/disabled-components-container';
import JPMCLogo from '../../../assets/images/jpmc-logo.png';

export interface IBankProfile {
  bankDetailId      : number;
  bankName          : string;
  bankPhone         : string;
  bankCountry       : string;
  bankAddress1      : string;
  bankAddress2      : string;
  bankCity          : string;
  bankState         : string;
  bankPostalCode    : string;
  bankImageFileName?: string;
}

interface Props {
  setDirty: Dispatch<SetStateAction<boolean>>;
}

interface ICustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name    : string;
}

const PhoneFormat = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      type='tel'
      format='+1 (###) ###-####'
      allowEmptyFormatting
    />
  );
});

/**
 * Component for showing Lender information Tab
 * @param props Props
 */
const LenderProfile: FC<Props> = (props: Props) => {
  const { setDirty }                            = props;
  const { canUpdateCompanyInformation }         = useContext(LenderSettingsContext);
  const { state, dispatch }                     = useContext(BankLogoContext);
  const [ isToasterOpen, setIsToasterOpen ]     = useState<boolean>(false);
  const [ toasterMessage, setToasterMessage ]   = useState<string>('');
  const [ toasterSeverity, setToasterSeverity ] = useState<AlertColor>('success');
  const [ bankProfile, setBankProfile ]         = useState<IBankProfile>({
    bankDetailId      : 0,
    bankName      		: '',
    bankPhone    		  : '',
    bankCountry   		: '',
    bankAddress1  		: '',
    bankAddress2  		: '',
    bankCity      		: '',
    bankState     		: '',
    bankPostalCode		: '',
    bankImageFileName	: ''
  });
  const bankDetailId                            = useMemo(() => 1, []);

  /**
   * This useEffect triggers the function fetchLenderProfile() once.
   */
  useEffect(() => {
    fetchLenderProfile();
  }, []);

  /**
   * This function checks if the form detects any changes.
   * @param node formik props data.
   */
  const formikRef = (node : FormikProps<IBankProfile>) => {
    if (node !== null) {
      setDirty(node.dirty);
    }
  };

  /**
   * This function set the loading state to true while fetching bank details on the backend side.
   */
  const fetchLenderProfile = async () => {
    try {
      const imageFile = await fetch(JPMCLogo);
      const logoDataUrl = await blobToBase64(await imageFile.blob());
      dispatch(setNewLogoDataUrl({logoDataUrl}));

      const response = await axiosInstance.request({
          url: `${API_DOMAIN}/bankDetails/${bankDetailId}`,
          method: GET,
      });
      setBankProfile(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * This funtion handles updating bank information details.
   * @param values Object for bankprofile.
   * @param action Helper for handling formik states.
   */
  const onBankDetailSubmit = async (values: IBankProfile, action: FormikHelpers<IBankProfile>) => {
    const payload: IBankProfile = {
      bankDetailId: bankDetailId,
      bankName: values.bankName,
      bankAddress1: values.bankAddress1,
      bankAddress2: values.bankAddress2,
      bankCity: values.bankCity,
      bankState: values.bankState,
      bankPostalCode: values.bankPostalCode,
      bankCountry: values.bankCountry,
      bankPhone: values.bankPhone
    }
    if (values.bankImageFileName) payload.bankImageFileName = values.bankImageFileName;
    
	axiosInstance
	.request({
	  url: `${API_DOMAIN}/bankDetails/${bankDetailId}`,
	  method: PUT,
	  data: payload,
	})
	.then((response) => {
	  const updatedProfile: IBankProfile = response?.data;
	  if (updatedProfile) {
      if (
        updatedProfile.bankDetailId !== bankProfile.bankDetailId ||
        updatedProfile.bankName !== bankProfile.bankName||
        updatedProfile.bankPhone !== bankProfile.bankPhone ||
        updatedProfile.bankImageFileName !== bankProfile.bankImageFileName)
      {
        setToasterSeverity('success');
        setToasterMessage('Changes in Company Information have been saved');
      } 
      else if (
        updatedProfile.bankAddress1 !== bankProfile.bankAddress1 ||
        updatedProfile.bankAddress2 !== bankProfile.bankAddress2||
        updatedProfile.bankCity !== bankProfile.bankCity||
        updatedProfile.bankState !== bankProfile.bankState ||
        updatedProfile.bankPostalCode !== bankProfile.bankPostalCode||
        updatedProfile.bankCountry!== bankProfile.bankCountry)
      {
        setToasterSeverity('success');
        setToasterMessage('Changes in Company Address have been saved');
      }
  
      setIsToasterOpen(true);
      setBankProfile(updatedProfile);

        action.resetForm({
        values: {
        bankDetailId: updatedProfile?.bankDetailId,
        bankName: updatedProfile.bankName,
        bankAddress1: updatedProfile.bankAddress1,
        bankAddress2: updatedProfile.bankAddress2,
        bankCity: updatedProfile.bankCity,
        bankState: updatedProfile.bankState,
        bankPostalCode: updatedProfile.bankPostalCode,
        bankCountry: updatedProfile.bankCountry,
        bankPhone: updatedProfile.bankPhone,
        bankImageFileName: updatedProfile?.bankImageFileName
        }
      });
		};
			
		  })
		  .finally(() => {
			action.setSubmitting(false);
		  });
	}

  /**
   * This function check if the user has the permission to do specific app functionality.
   * @param func Function the user want to access.
   * @param permission Permission need to access specific funtionality of the page.
   * @param args Argument need by the 'func' param, if needed.
   */
  const checkPermission = async (func: Function, permission: string, args: any[]) => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), permission);
    if(isPermitted){
      func(...args)
      return
    }
    setToasterMessage(NO_PERMISSION_MSG)
    setToasterSeverity('error')
    setIsToasterOpen(true)
  }

  /**
   * This function prevent the user on inputing invalid characters.
   * @param e keyboardEvent
   * @returns boolean
   */
  const preventInvalidCharacter = (e: React.KeyboardEvent<HTMLInputElement>) => ['e', 'E', '+', '-','.'].includes(e.key) && e.preventDefault();
  
  /**
   * This function gets the helper text of a formik field based on touched and errors.
   * @param formik The formik props of IBankProfile.
   * @param name The name of the field.
   * @returns A component for the helper text of the field.
   */
  const getHelperText = (formik: FormikProps<IBankProfile>, name: string) => {
    if (formik.touched[name] && formik.errors[name]) {
      return (<HelperTextComponent text={formik.errors[name]} />);
    } else {
      return null;
    }
  };

  /**
   * This function checks if the save button is disabled.
   * @param formik The formik props of IBankProfile.
   * @returns A boolean value for the disabled state of the Save Button.
   */
  const isSaveDisabled = (formik: FormikProps<IBankProfile>) => {
    return (!(formik.dirty && formik.isValid)) || formik.isSubmitting
  }

  return (
    <>
      <Grid container columnSpacing={6} rowSpacing={1} sx={styles.gridContainer}>
        <Grid item xl={4} lg={5} md={4} xs={12}>
          <Box sx={styles.formLabel}>
            <FormLabel tabIndex={0}>Company Logo</FormLabel>
            <Divider sx={styles.divider}></Divider>
          </Box>
          <LenderAvatarUpload
            logo={state.logoDataUrl}
            aria-label='Lender Logo'
          ></LenderAvatarUpload>
        </Grid>
        <Grid item xl={5} lg={6} md={8} xs={12}>
          <Formik
            innerRef={formikRef}
            enableReinitialize
            validateOnChange={true}
            validateOnBlur={true}
            initialValues={bankProfile}
            validationSchema={bankProfileSchema}
            onSubmit={(values, action) => {
              checkPermission(onBankDetailSubmit, PERMISSIONS.UPDATE_COMPANY_INFORMATION, [values, action]);
            }}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Prompt when={(formik.dirty)} isEditing={setDirty}/>
                <Box sx={styles.formLabel}>
                  <FormLabel tabIndex={0}>Company Information</FormLabel>
                  <Divider sx={styles.divider}></Divider>
                </Box>
                <Box sx={styles.labelAndTextField}>
                  <FormLabel tabIndex={0} sx={{...styles.requiredText, ...styles.fieldPosition}}>
                    Bank Name<span style={styles.asterisk}> *</span>
                  </FormLabel>
                  <TextField
                    id='bank-name'
                    variant='outlined'
                    size='small'
                    sx={{...styles.fieldWidth, ...styles.fieldPosition}}
                    name='bankName'
                    disabled={!canUpdateCompanyInformation}
                    value={formik.values.bankName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.bankName &&
                      Boolean(formik.errors.bankName)
                    }
                    helperText={getHelperText(formik, 'bankName')}
                    onBlur={e => {
                      formik.setFieldValue('bankName', formik.values.bankName.trim())
                      formik.handleBlur(e);
                    }}
                    inputProps={{ 'data-testid': 'bank-name',
                                  'aria-label':'Bank Name'}}
                  />
                </Box>
                <Box sx={styles.labelAndTextField}>
                  <FormLabel tabIndex={0} sx={styles.requiredText}>
                    Primary Contact<span style={styles.asterisk}> *</span>
                  </FormLabel>
                  <TextField
                    id='primary-contact'
                    variant='outlined'
                    size='small'
                    sx={styles.numberField}
                    name='bankPhone'
                    type='number'
                    disabled={!canUpdateCompanyInformation}
                    value={formik.values.bankPhone}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.bankPhone &&
                      Boolean(formik.errors.bankPhone)
                    }
                    helperText={getHelperText(formik, 'bankPhone')}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      inputComponent: PhoneFormat as any,
                    }}
                    inputProps={{ 'data-testid': 'primary-contact',
                                  'aria-label':'Primary Contact'}}
                  />
                </Box>
                <Box sx={styles.formLabel}>
                  <FormLabel tabIndex={0}>Company Address</FormLabel>
                  <Divider sx={styles.divider}></Divider>
                </Box>
                <Box sx={styles.labelAndTextField}>
                  <FormLabel tabIndex={0} sx={{...styles.requiredText, ...styles.fieldPosition}}>
                    Country<span style={styles.asterisk}> *</span>
                  </FormLabel>
                  <TextField
                    id='country'
                    variant='outlined'
                    size='small'
                    sx={styles.fieldWidth}
                    name='bankCountry'
                    disabled={!canUpdateCompanyInformation}
                    value={formik.values.bankCountry}
                    onChange={(event) => {
                      const isCharacter = CONTAINS_CHARACTER_REGEX.test(event?.target?.value) || event?.target?.value === '';
                      if (isCharacter) {
                        formik.handleChange(event);
                      }
                    }}
                    error={formik.touched.bankCountry && Boolean(formik.errors.bankCountry)}
                    helperText={getHelperText(formik, 'bankCountry')}
                    onBlur={e => {
                      formik.setFieldValue('bankCountry', formik.values.bankCountry.trim())
                      formik.handleBlur(e);
                    }}
                    inputProps={{ 'data-testid': 'country',
                                  'aria-label':'Country'}}
                  />
                </Box>
                <Box sx={styles.labelAndTextField}>
                  <FormLabel tabIndex={0} sx={{...styles.requiredText, ...styles.fieldPosition}}>
                    Address 1<span style={styles.asterisk}> *</span>
                  </FormLabel>
                  <TextField
                    id='address1'
                    variant='outlined'
                    size='small'
                    sx={styles.fieldWidth}
                    name='bankAddress1'
                    disabled={!canUpdateCompanyInformation}
                    value={formik.values.bankAddress1}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.bankAddress1 &&
                      Boolean(formik.errors.bankAddress1)
                    }
                    helperText={getHelperText(formik, 'bankAddress1')}
                    onBlur={e => {
                      formik.setFieldValue('bankAddress1', formik.values.bankAddress1.trim())
                      formik.handleBlur(e);
                    }}
                    inputProps={{ 'data-testid': 'address1',
                                  'aria-label': 'Address 1'}}
                  />
                </Box>
                <Box sx={styles.labelAndTextField}>
                  <FormLabel tabIndex={0} sx={{...styles.requiredText, ...styles.fieldPosition}}>Address 2</FormLabel>
                  <TextField
                    id='address2'
                    variant='outlined'
                    size='small'
                    sx={styles.fieldWidth}
                    name='bankAddress2'
                    disabled={!canUpdateCompanyInformation}
                    value={formik.values.bankAddress2}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.bankAddress2 &&
                      Boolean(formik.errors.bankAddress2)
                    }
                    helperText={getHelperText(formik, 'bankAddress2')}
                    onBlur={e => {
                      formik.setFieldValue('bankAddress2', formik.values.bankAddress2.trim())
                      formik.handleBlur(e);
                    }}
                    inputProps={{ 'data-testid': 'address2',
                                  'aria-label': 'Address 2'}}
                  />
                </Box>
                <Box sx={styles.labelAndTextField}>
                  <FormLabel tabIndex={0} sx={{...styles.requiredText, ...styles.fieldPosition}}>
                    City<span style={styles.asterisk}> *</span>
                  </FormLabel>
                  <TextField
                    id='city'
                    variant='outlined'
                    size='small'
                    sx={styles.fieldWidth}
                    name='bankCity'
                    disabled={!canUpdateCompanyInformation}
                    value={formik.values.bankCity}
                    onChange={(event) => {
                      const isCharacter = CONTAINS_CHARACTER_REGEX.test(event?.target?.value) || event?.target?.value === '';
                      if (isCharacter) {
                        formik.handleChange(event);
                      }
                    }}
                    error={
                      formik.touched.bankCity &&
                      Boolean(formik.errors.bankCity)
                    }
                    helperText={getHelperText(formik, 'bankCity')}
                    onBlur={e => {
                      formik.setFieldValue('bankCity', formik.values.bankCity.trim())
                      formik.handleBlur(e);
                    }}
                    inputProps={{ 'data-testid': 'city',
                                  'aria-label': 'City'}}
                  />
                </Box>
                <Box sx={styles.stateAndZipContainer}>
                  <Box sx={styles.stateContainer}>
                    <FormLabel tabIndex={0} sx={styles.requiredText}>
                      State<span style={styles.asterisk}> *</span>
                    </FormLabel>
                    <TextField
                      id='state'
                      variant='outlined'
                      size='small'
                      sx={styles.smallField}
                      name='bankState'
                      disabled={!canUpdateCompanyInformation}
                      value={formik.values.bankState}
                      onChange={(event) => {
                        const isCharacter = CONTAINS_CHARACTER_REGEX.test(event?.target?.value) || event?.target?.value === '';
                        if (isCharacter) {
                          formik.handleChange(event);
                        }
                      }}
                      error={
                        formik.touched.bankState &&
                        Boolean(formik.errors.bankState)
                      }
                      helperText={getHelperText(formik, 'bankState')}
                      onBlur={e => {
                        formik.setFieldValue('bankState', formik.values.bankState.trim())
                        formik.handleBlur(e);
                      }}
                      inputProps={{ maxLength: 2,  'data-testid': 'state',
                                    'aria-label': 'State Textfield' }}
                    />
                  </Box>
                  <Box sx={styles.zipContainer}>
                    <FormLabel tabIndex={0} sx={styles.requiredText}>
                      Zip Code<span style={styles.asterisk}> *</span>
                    </FormLabel>
                    <TextField
                      id='zipCode'
                      variant='outlined'
                      size='small'
                      sx={styles.halfWidth}
                      name='bankPostalCode'
                      type='number'
                      disabled={!canUpdateCompanyInformation}
                      value={formik.values.bankPostalCode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.bankPostalCode &&
                        Boolean(formik.errors.bankPostalCode)
                      }
                      helperText={getHelperText(formik, 'bankPostalCode')}
                      onKeyDown={preventInvalidCharacter}
                      onBlur={formik.handleBlur}
                      inputProps={{ 'data-testid': 'zip-code',
                                    'aria-label': 'Zip Code'}}
                    />
                  </Box>
                </Box>
                <Box display='flex' justifyContent='flex-end' sx={styles.buttonGroup}>
                  { formik.dirty ? 
                    <Button
                      aria-label='cancel-button'
                      variant='outlined'
                      sx={styles.cancelButton}
                      onClick={() => formik.resetForm()}
                    >
                      Cancel
                    </Button>
                  : null }
                  <DisabledComponentsContainer isDisabled={isSaveDisabled(formik)}>
                    <Button
                      aria-label={formik.isSubmitting ? 'Save' : 'Save button disabled'}
                      disabled={isSaveDisabled(formik)}
                      variant='contained'
                      color='primary'
                      type='submit'
                      sx={{...styles.saveButton, ...(!canUpdateCompanyInformation && styles.hidden)}}
                    >
                      {formik.isSubmitting ? (
                        <CircularProgress color='inherit' size={24} />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </DisabledComponentsContainer>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Toaster
        open={isToasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setIsToasterOpen(false)}
      />
    </>
  );
};

export default LenderProfile;
