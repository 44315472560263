import { useContext, useMemo } from "react";
import { IBBPeriod, IClient } from "../../../../../interfaces";
import { ARAgingReportContext } from "../../../../../context/arAgingReportContext";
import { IARAgingReportContext } from "../../../../../interfaces/arAgingReportInterface";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { IAccountsReceivableSetting } from "../../../../../components/client-settings/tabs/tab-panel-contents/accounts-receivable";
import ARAgingReportTable from "../report-table";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styles from "./styles";
import { SelectedClientContext } from "../../../../../context/selectedClientContext";
import { formatDate } from "../../../../../utility/helper";
import { AMERICAN_DATE_FORMAT } from "../../../../../utility/constants";
import { ICurrency } from "../../../../../interfaces/multiCurrencyInterface";

export interface IARAgingCollateralDetailContainerProps {
  arSetting: IAccountsReceivableSetting
}

const ARAgingCollateralDetailContainer: React.FC<IARAgingCollateralDetailContainerProps> = (props)  => {
  const { selectedClient: parentClient } = useContext(SelectedClientContext);
  const { arSetting } = props;
  const {
    selectedAsOfDate, selectedCurrency, exchangeRates, reports
  }  = useContext(ARAgingReportContext) as IARAgingReportContext;

  const selectedExchangeRate = useMemo(() => {
    if ([parentClient, selectedAsOfDate, selectedCurrency].some(field => field === null)) { return null; }
    const nonNullSelectedParentClient = parentClient as Required<IClient>;
    const nonNullSelectedAsOfDate = selectedAsOfDate as IBBPeriod;
    const nonNullSelectedCurrency = selectedCurrency as ICurrency;
    const selectedARBorrowerInput = arSetting.arBorrowerInput;
    if (nonNullSelectedParentClient.currencyId === selectedARBorrowerInput.currency) { return null; }
    if (nonNullSelectedCurrency.recordId.toString() === selectedARBorrowerInput.currency) { return null; }
    const exchangeRate = exchangeRates.find(exchangeRate =>
      exchangeRate.toCurrencyId.toString() === selectedARBorrowerInput.currency &&
      formatDate(exchangeRate.asOfDate as string, AMERICAN_DATE_FORMAT) === formatDate(nonNullSelectedAsOfDate.endDate, AMERICAN_DATE_FORMAT)
    );
    return exchangeRate ?? null;
  }, [parentClient, arSetting, selectedAsOfDate, selectedCurrency, exchangeRates]);

  const getMainContent = () => {
    const reportsForCollateral = reports.filter(report => report.arCollateralId === arSetting.arCollateral.recordId);
    if (reportsForCollateral.length === 0) { return <></>; }
    return (
      <>
        <Accordion
          key={arSetting.arCollateral.recordId}
          sx={styles.accordion}
          disableGutters
          elevation={0}
          square
        >
          <AccordionSummary
            aria-controls={arSetting.arCollateral.recordId.toString()}
            id={arSetting.arCollateral.recordId.toString()}
            expandIcon={
              <ArrowDropDownIcon
                aria-label='Expand icon'
                sx={styles.accordionSummaryFontSize}
              />
            }
            sx={styles.accordionSummary}
          >
            <Box sx={styles.accordionRow}>
              <Typography fontSize='1rem' fontWeight='bold'>{ arSetting.arCollateral.arCollateralName }</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={styles.accordionDetails}>
            <ARAgingReportTable selectedExchangeRate={selectedExchangeRate} arSetting={arSetting} />
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  return getMainContent();
};

export default ARAgingCollateralDetailContainer;