import { Circle, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Box, Paper, Typography, LinearProgress, ListItem, ListItemIcon, ListItemText, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, IconButton, Collapse, TextField } from "@mui/material"
import DraggableTable from "../../draggable-table"
import { DraggableListItem } from "../../../../interfaces/draggableList"
import SearchParentChild from "../../search-parent-child"
import ParentMenu from "../parent-menu"
import styles from "../styles"
import { useContext, useEffect, useRef, useState } from "react"
import { IModalProps, IConfirmUpdateModalProps, IPaging, IParentChildSetupContext, IParentWithChild } from "../../../../interfaces/parentChildSetupInterface"
import { GET, POST, SEARCH_FILTER } from "../../../../utility/constants"
import { ParentChildSetupContext } from "../../../../context/parentChildSetupContext"
import { IOption } from "../../../../interfaces/comboBox"
import axiosInstance from "../../../../service/axiosInstance"
import { SelectedClientContext } from "../../../../context/selectedClientContext"
import { IARCustomer } from "../../../../interfaces"
import { arCustomersAPI } from "../../../../service/api"
import CustomerModal, { IARCustomerFormValues } from "../../customer-modal"
import ConfirmModal from "../../../../components/modals/confirm-modal"
import { formatCurrency } from "../.."

const filters = ['All', 'Parent', 'Child']

interface IProps {
  selectedARCollateral: IOption | null
  modalProps: IModalProps,
  setModalProps: React.Dispatch<React.SetStateAction<IModalProps>>
  isDragging: boolean
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * This function put the primaryCustomer to the first index of the list.
 * 
 * @param children A list of ARCustomers in form of a DraggableListItem under a same parent.
 * @param defaulChild The ID of the primaryCustomer/Default child
 * @returns A formatted lsit where the primaryCustomer comes first.
 */
export const handleDefaultFirst = (children: DraggableListItem[], defaulChild: string) => {
  const index = children.findIndex((child) => child.recordId.toString() === defaulChild)
  if (index === -1) {
    return children
  }
  const child = children[index]
  const sorted = [...children.slice(0, index), ...children.slice(index + 1)].sort((a, b) => a.name.localeCompare(b.name))
  return [child, ...sorted]
}

/**
 * This component renders the Parent section of the parent view page.
 * 
 * @param props IProps
 */
const ParentSection = (props: IProps) => {
  const {
    parentsWithChildrenList,
    setParentsWithChildrenList,
    setSelectedOrphans,
    orphansList, setOrphansList,
    newParentsWithChildrenList,
    setNewParentsWithChildrenList,
    newOrphansList, setNewOrphansList,
    selectedOrphans,
    deletedParentIds,
    setDeletedParentIds,
    parentPage, setParentPage,
  } = useContext(ParentChildSetupContext) as IParentChildSetupContext;
  const {selectedClient}                                      = useContext(SelectedClientContext);
  const selectedARCollateralId                                = props.selectedARCollateral?.recordId
  const [showParentModal, setShowParentModal]                 = useState<boolean>(false);
  const [focusedItemId, setFocusedItemId]                     = useState<number>();
  const [focusedItemTempId, setFocusedItemTempId]             = useState<number>();
  const [newParentId, setNewParentId]                         = useState<number>(0);
  const [parentDisplay, setParentDisplay]                     = useState<IParentWithChild[]>([]);
  const [isValidName, setIsValidName]                         = useState<boolean>(true);
  const [isWhiteSpace, setIsWhiteSpace]                       = useState<boolean>(false);
  const [confirmUpdateModal, setConfirmUpdateModal]           = useState<IConfirmUpdateModalProps>({isExistingParent: false, isOpen: false, parentId: 0, parentName: '', children: [], hasVendors: [], from: 0, to: 0});
  const parentObserver                                        = useRef<any>();
  
  // HOOKS

  /**
   * This useEffect triggers the function that generates which Parents should be display in the list.
   */
  useEffect(() => {
    getParentDisplay()
},[parentPage.searchKey, parentPage.searchFilter, parentPage.pageNo, parentsWithChildrenList])

  // NON API FUNTIONS

  /**
   * This function handles the update on a parent's name on local state variables.
   * 
   * @param val The updated name of a parent.
   */
  const handleUpdateName = (val: string) => {
    const newList = parentsWithChildrenList.map(parent => {
      if(parent.id === focusedItemId && parent.tempId === focusedItemTempId){
        return {
          ...parent,
          name: val
        }
      }
      return parent
    })
    const isFound = newParentsWithChildrenList.find(parent => parent.id === focusedItemId && parent.tempId === focusedItemTempId)
    if(isFound){
      const newParentList = newParentsWithChildrenList.map(parent => {
        if(parent.tempId === focusedItemTempId){
          return {
            ...parent,
            name: val
          }
        }
        return parent
      })
      setNewParentsWithChildrenList(newParentList)
    }else{
      const fromOrigin = parentsWithChildrenList.find(parent => parent.id === focusedItemId)
      setNewParentsWithChildrenList([...newParentsWithChildrenList, ({...fromOrigin!, name: val})])
    }
    setParentsWithChildrenList(newList)
    setFocusedItemId(undefined)
    setFocusedItemTempId(undefined)
  }

  /**
   * This function dynamically handles the update on the relationship of a Parent ARCustomer and an ARCustomer
   * 
   * @param list A list of ARCustomer formatted as DraggableListItem | Children
   * @param removeFromId The ID where the dragged ARCustomer came from.
   * @param insertToId The ID where the dragged ARCustomer was drop to.
   * @param tempId The temporary ID of a parent, if any.
   * @returns Null if the ARCustomer was dragged and droped on a same parent.
   */
  const handleUpdate = (list: DraggableListItem[], removeFromId?: number, insertToId?: number, tempId?: number) => {
    if(removeFromId === insertToId) return;

    const children: DraggableListItem[] = list.map(item => ({...item, checked: false, listName: insertToId?.toString()}))

    const updateList = getUpdateList(children, removeFromId, insertToId);
    let newList: any = updateList.newList;
    const updatedNewParent = updateList.updatedNewParent;
    const updatedOldParent = updateList.updatedOldParent;

    // delete old parent with no child
    if (updatedOldParent.children.length < 1) {
      setDeletedParentIds([...deletedParentIds, updatedOldParent.recordId ?? updatedOldParent.tempId ?? -1]);
      newList = updateList.newList.filter((parent => parent.children.length > 0));
      const tempNewParentList = newParentsWithChildrenList
        .filter(parent => parent.children.length > 0)
        .filter((newParent) => {
          parentsWithChildrenList.some((parent) => parent.id === newParent.id || parent.tempId === newParent.tempId)
        });
      setNewParentsWithChildrenList(tempNewParentList);
    }

    handleNanId(children, removeFromId);

    const isFoundNew = newParentsWithChildrenList.find(parent => parent.id === insertToId && parent.tempId === tempId)
    const isFoundOld = newParentsWithChildrenList.find(parent => parent.id === removeFromId && parent.tempId === tempId)
    if(isFoundNew || isFoundOld){
      handleExistingCustomers(updatedNewParent, updatedOldParent, removeFromId, insertToId, tempId);
    } else{
      handleNewCustomers(children, removeFromId, insertToId, tempId, updatedOldParent);
   }

    setParentsWithChildrenList(newList)
    setSelectedOrphans([])
  }

  /**
   * This function updates the list when there is a change on the ParentList.
   * 
   * @param children A list of ARCustomer formatted as DraggableListItem | Children
   * @param removeFromId The ID where the dragged ARCustomer came from.
   * @param insertToId The ID where the dragged ARCustomer was drop to.
   * @returns The updated ParentList
   */
  const getUpdateList = (children: DraggableListItem[], removeFromId?: number, insertToId?: number) => {
    const initialValue: IParentWithChild = {
      children: [],
      id: 0,
      name: "",
      defaultChildId: "",
      isExpanded: false
    };

    let updatedNewParent: IParentWithChild = initialValue;
    let updatedOldParent: IParentWithChild = initialValue;
   
    const newList = parentsWithChildrenList.map(parent => {
      //insert to parent
      if((insertToId === parent.id && parent.id !== removeFromId) || (insertToId === parent.tempId) && parent.tempId !== removeFromId){
        const defChildId = parent.children.length < 1 ? children[0].recordId.toString() : parent.defaultChildId
        updatedNewParent = {
          ...parent,
          isExpanded: true,
          defaultChildId: defChildId,
          children: handleDefaultFirst([...parent.children, ...children], defChildId)
        }
        return updatedNewParent
      }
      //remove from parent
      else if((parent.id === removeFromId && parent.id !== insertToId) || (parent.tempId === removeFromId && parent.tempId !== insertToId)){
        const filteredChildren = parent.children.filter((originalObj) =>
        !children.some((filterObj) => filterObj.recordId === originalObj.recordId));
        updatedOldParent = {
          ...parent,
          defaultChildId: checkIfIdExists(children, parent.defaultChildId) ? filteredChildren[0].recordId.toString() : parent.defaultChildId,
          children: filteredChildren
        }
        return updatedOldParent
      }

      return parent
    })

    return { updatedNewParent, updatedOldParent, newList }
  }

  /**
   * This function removes an ARCustomer from the orphan.
   * 
   * @param children A list of ARCustomer formatted as DraggableListItem | Orphans
   * @param removeFromId The ID of the list where the dragged ARCustomer came from, Nan if its from the orphansList.
   */
  const handleNanId = (children: DraggableListItem[], removeFromId?: number) => {
    if(isNaN(removeFromId!)){
      const filteredOrphan = orphansList.filter((originalObj) =>
      !children.some((filterObj) => filterObj.recordId === originalObj.recordId));
      setOrphansList(filteredOrphan)
      setNewOrphansList([...newOrphansList, ...children])
    }
  };

  /**
   * This function triggers when the ARCustomer was dragged from and drop on different but both exisitng (saved) Parent ARCustomer.
   * 
   * @param updatedNewParent The updated parent object in which the dragged ARCustomer was droped to.
   * @param updatedOldParent The updated parent object in which the dragged ARCustomer came from.
   * @param removeFromId The ID of the old Parent.
   * @param insertToId The ID of the new Parent.
   * @param tempId The Temporary ID of the parent if its not saved yet.
   */
  const handleExistingCustomers = (updatedNewParent: IParentWithChild, updatedOldParent: IParentWithChild, removeFromId?: number, insertToId?: number, tempId?: number) => {
    const newParentList = newParentsWithChildrenList.map(parent => {
      if((parent.id === insertToId && parent.id !== removeFromId) && parent.tempId === tempId){
        return updatedNewParent
      }else if((parent.id === removeFromId && parent.id !== insertToId) && parent.tempId === tempId){
        return updatedOldParent
      }
      return parent
    })
    const filteredUpdatedList = newParentList
      .filter(parent => !(updatedOldParent.children.length === 0 && ((parent.id && updatedOldParent.id === parent.id) || (parent.tempId && updatedOldParent.tempId === parent.tempId))));
    setNewParentsWithChildrenList(filteredUpdatedList)
  };

  /**
   * This function handles the updates when one of the involving parent is an unsave parent.
   * 
   * @param children A list of ARCustomer formatted as DraggableListItem
   * @param removeFromId The ID of the old Parent.
   * @param insertToId The ID of the new Parent.
   * @param tempId The temporary ID of an unsave parent.
   */
  const handleNewCustomers = (children: DraggableListItem[], removeFromId?: number, insertToId?: number, tempId?: number, updatedOldParent?: IParentWithChild) => {
    const newParent = parentsWithChildrenList.find(parent => parent.id === insertToId && parent.tempId === tempId)
    const oldParent = parentsWithChildrenList.find(parent => parent.id === removeFromId && parent.tempId === tempId)
    const updatedNewList = newParentsWithChildrenList

    if(newParent){
      const defChildId = newParent.children.length < 1 ? children[0].recordId.toString() : newParent.defaultChildId;
      const newParentWithChild = {
        ...newParent,
        defaultChildId: defChildId,
        children: handleDefaultFirst([...newParent.children, ...children], defChildId)
      }
      updatedNewList.push(newParentWithChild)
    }

    if(oldParent){
      const filteredChildren = oldParent.children.filter((originalObj) =>
      !children.some((filterObj) => filterObj.recordId === originalObj.recordId));
      const newParentWithChild = {
        ...oldParent,
        defaultChildId: checkIfIdExists(children, oldParent.defaultChildId) ? filteredChildren[0].recordId.toString() : oldParent.defaultChildId,
        children: filteredChildren
      }
      updatedNewList.push(newParentWithChild)
    }

    let filteredNewList = updatedNewList.filter(parent => parent.children.length > 0);

    if(updatedOldParent) {
      filteredNewList = filteredNewList
        .filter(parent => !(updatedOldParent.children.length === 0 && ((parent.id && updatedOldParent.id === parent.id) || (parent.tempId && updatedOldParent.tempId === parent.tempId))));
    }

    setNewParentsWithChildrenList([...filteredNewList]);
  };

  /**
   * This function is used to determine if there is an existing primaryCustomer for a specific parent children.
   * 
   * @param list A list of ARCustomer formatted as DraggableListItem.
   * @param idToCheck The the parent's defaultChildId.
   * @returns A boolean value that ditermine if there is an exisitng primaryCustomer
   */
  const checkIfIdExists = (list: DraggableListItem[], idToCheck: string) => {
    return list.some(item => item.recordId.toString() === idToCheck);
  };
   
  /**
   * This function set the state of a specific parent to expanded.
   * 
   * @param parentId The ID of the parent to expand.
   * @param tempId The temporary ID of the parent to expand.
   */
  const handleExpand = (parentId: number, tempId?: number) => {
    setParentDisplay(
      parentDisplay.map(parent => 
        parentId === parent.id && tempId === parent.tempId? {...parent, isExpanded: !parent.isExpanded} : parent
      ))
    }
    
  /**
   * This function checks if the component should have a dark or light background color.
   * 
   * @param index The index of the component
   * @returns A boolean that determine if the component's index is odd or not.
   */
  const isOdd = (index: number) => index%2!==0

  const getParentDisplay = () => {
    const filteredList:IParentWithChild[] = []

    parentsWithChildrenList.forEach(parent => {
      if((parentPage.searchFilter === 'Parent' || parentPage.searchFilter === 'All') && parent.name.toLowerCase().includes(parentPage.searchKey.toLowerCase())){
        filteredList.push({
          ...parent,
          isExpanded: Boolean(parentPage.searchKey) && parent.children.some(child => child.name.toLowerCase().includes(parentPage.searchKey.toLowerCase()))
        })
      }else if((parentPage.searchFilter === 'Child') && parent.children.some(child => child.name.toLowerCase().includes(parentPage.searchKey.toLowerCase()))){
        filteredList.push({
          ...parent,
          isExpanded: Boolean(parentPage.searchKey),
          children: parent.children.filter(child => child.name.toLowerCase().includes(parentPage.searchKey.toLowerCase()))
        })
      }
    })

    const displayList = filteredList.slice(0, parentPage.pageNo * 10)

    setParentDisplay(filteredList)
    setParentPage(prevState => ({...prevState, isLastPage: filteredList.length === displayList.length}))
  }

  // EVENT LISTENER - FOR INFINITE SCROLL

  /**
   * This function handle the infinite scrolling of the parent section.
   * 
   * @param node A component in which this function is attached as ref.
   */
  const lastRowParentElementRef = (node: any) => {
    if (parentPage.isLoading) return
    if (parentObserver.current) parentObserver.current.disconnect()
    
    parentObserver.current = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting && !parentPage.isLastPage) {
        setParentPage((prevState) => ({
          ...parentPage, 
          isLoading: true,
          pageNo: prevState.pageNo+1
        }))
      }
    })

    if (node) parentObserver.current.observe(node)
  }
  
  /**
   * This function checks the uniqueness of the user input when updating a parent name.
   * 
   * @param value The input value from a parent's text field.
   * @param currentName The current name of the parent to be updated.
   * @returns A boolean that determine if the input value is a unique parent name.
   */
  const checkUnique = (value: string, currentName: string): boolean => {
      const parentVal = parentsWithChildrenList.some(parent => parent.name.toLowerCase() === value?.toLowerCase() && parent.name.toLowerCase() !== currentName.toLowerCase())
      setIsValidName(!parentVal)
      return parentVal
  }

  /**
   * This function returns a filtered list of DraggableListItem that has vendors
   * 
   * @param customers The array of DraggableListItem to be filtered
   * @returns A filtered list of DraggableListItem
   */
  const findHasVendors = (customers: DraggableListItem[]) => customers.filter((customer) => !customer.deletable);

  /**
   * This function generate a proper inline error for the parent text field
   */
  const getHelperText = () => {
    if(isWhiteSpace){
      return 'Parent name should not be empty'
    } else if(!isValidName){
      return 'Parent name already exist'
    } else {
      return undefined
    }
  }

  /**
   * This function returns the proper text description for modal
   * 
   */
  const getConfirmModalDescription = () => {
    return `One or more customers have associated vendors. These vendor associations will be with the parent customer when you create a parent-child relationship. Are you sure you want to proceed?`
  }

  /**
   * This function handles the key down event of the parent field.
   * @param e The event for the key down.
   * @returns Early return if input is not a valid name.
   */
  const handleParentFieldKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const val = (e.target as HTMLInputElement).value
    const defVal = (e.target as HTMLInputElement).defaultValue

    if ( e.key === 'Enter' && (isWhiteSpace || val.trim() === defVal)) return

    if (e.key === 'Enter' && val && isValidName) handleUpdateName(val)
  }

  /**
   * This function creates a new Parent AR Customer and add it on the parentList locally.
   * 
   * @param values Form Values
   */
    const handleSave = (values: IARCustomerFormValues) => {
      try {
          const newChildren = selectedOrphans.map(customer => ({
            ...customer,
            checked: false,
            listName: (newParentId+1).toString(),
          }));
          const filteredOrphan = orphansList.filter((originalObj) =>
          !selectedOrphans.some((filterObj) => filterObj.recordId === originalObj.recordId));
          const newParent: IParentWithChild = ({
            tempId          : newParentId+1,
            id              : 0,
            name            : values?.parentCustName?.trimEnd() ?? '',
            defaultChildId  : '',
            isExpanded      : false,
            children        : newChildren,
            custName        : values?.parentCustName?.trimEnd(),
            custSrcId       : values?.parentCustSrcId,
            dbRating        : values?.dbRating,
            creditLimit     : values?.creditLimit,
            custCountry     : values?.country,
            custAddress1    : values?.address1,
            custAddress2    : values?.address2,
            custCity        : values?.city,
            custState       : values?.state,
            custPostalCode  : values?.zipCode,
            custPhone       : values?.phoneNumber,
            custDescription : values?.description,
            isUnlinked      : true,
          })
          const filteredNewOrphans = newOrphansList.filter(originalObj => !selectedOrphans.some((filterObj) => filterObj.recordId === originalObj.recordId))
          setParentsWithChildrenList([newParent, ...parentsWithChildrenList].sort((a, b) => a.name.localeCompare(b.name)))
          setNewParentsWithChildrenList([...newParentsWithChildrenList, newParent])
          setNewOrphansList(filteredNewOrphans)
          setOrphansList(filteredOrphan)
          setNewParentId(newParentId+1)
          setSelectedOrphans([])
      } catch (error) {
        console.log('CREATE PARENT AR CUSTOMER: ', error)
      }
    }

  return (
    <>
      <Box component={Paper} sx={styles.sectionBox}>
        <Box sx={styles.clearButtonBox} />
        <Box sx={{ marginBottom: '1rem' }}>
          <SearchParentChild
            filters={filters}
            onChange={value => {
              setParentPage(prevState => ({
                ...prevState,
                pageNo: 1,
                searchKey: value,
                isLoading: true
              }))
            }}
            onSelect={selected => {
              setParentPage(prevState => ({
                ...prevState,
                pageNo: 1,
                searchFilter: selected,
                isLoading: true
              }))
            }}
          />
        </Box>
        <TableContainer sx={styles.sectionTableContainer}>
          <Table stickyHeader>
            <TableHead sx={styles.sectionTableHead}>
              <TableRow sx={styles.sectionTableHeaderRow}>
                <TableCell sx={styles.sectionTableSelectCell} />
                <TableCell sx={styles.parentNameHeaderCell}>
                  <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'left' }}>
                    Parent Name
                  </Typography>
                </TableCell>
                <TableCell sx={styles.parentNameHeaderCell}>
                  <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'right' }}>
                    Amount
                  </Typography>
                </TableCell>
                <TableCell sx={styles.parentNameHeaderCell}>
                  <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'right',}}>
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={styles.tableSpacer}></TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell colSpan={100} sx={{ padding: 0, borderBottom: 0 }}>
                  <Box
                    tabIndex={0}
                    data-testId={`parent-section-drop-area`}
                    sx={{...styles.dropSection, ...(props.isDragging ? styles.dropSecionActive : {})}}
                    onDragOver={(event) => { 
                      event.preventDefault() 
                    }}
                    onDrop={(event) => {
                      const eventList = event.dataTransfer.getData("text/plain");
                      const formattedList: DraggableListItem[] = JSON.parse(eventList);
                      const hasVendors = findHasVendors(formattedList);
                      if (hasVendors.length > 0) {
                        setConfirmUpdateModal({
                          ...confirmUpdateModal,
                          tempId: -1,
                          isOpen: true,
                          isExistingParent: false,
                          parentId: -1,
                          parentName: '',
                          children: formattedList,
                          hasVendors: hasVendors,
                        });
                        return;
                      }
                      props.setIsDragging(false);
                      setShowParentModal(true);
                      setSelectedOrphans(formattedList);
                    }}
                  >
                    <Typography>Drag and drop selected customer to add new parent</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              { parentDisplay.map((parent, idx) => (
                <>
                <TableRow
                  data-testId={`parent-row-${idx}`}
                  sx={{...styles.parentRow, bgcolor: isOdd(idx) ? 'background.paper' : '#F7F7F7'}}
                  onClick={() => handleExpand(parent.id, parent.tempId)}
                  onDrop={(event) => {
                    const eventList = event.dataTransfer.getData("text/plain")
                    const formattedList = JSON.parse(eventList)
                    const hasVendors = findHasVendors(formattedList);
                    if (hasVendors.length > 0) {
                      setConfirmUpdateModal({
                        ...confirmUpdateModal,
                        tempId: parent.tempId,
                        isOpen: true,
                        isExistingParent: true,
                        parentId: parent.id,
                        parentName: parent.name,
                        children: formattedList,
                        hasVendors: hasVendors,
                      });
                      return;
                    }
                    handleUpdate(formattedList, Number(formattedList[0].listName), parent.id, parent.tempId)
                  }}
                  onDragOver={(event) => { event.preventDefault() }}
                >
                  <TableCell sx={styles.parentSelectCell}>
                    <Box sx={{ display: 'flex' }}>
                      { parent.isExpanded ? <KeyboardArrowUp sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> : <KeyboardArrowDown sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />}
                    </Box>
                  </TableCell>
                  <TableCell sx={styles.parentNameCell}>
                    { focusedItemId === parent.id ? (
                      <TextField
                        autoFocus
                        inputProps={{ 
                          'aria-label': 'parent-name-textfield',
                          'data-testid': `parent-name-textfield-${parent.id}`
                        }}
                        defaultValue={parent.name}
                        placeholder='Parent Name'
                        size='small'
                        onChange={(e) => {
                          const val = e.target.value

                          if (!val || val.trim() === '') {
                            setIsWhiteSpace(true)
                            return
                          }

                          checkUnique(val, parent.name)
                          setIsWhiteSpace(false)
                        }}
                        onKeyDown={handleParentFieldKeyDown}
                        onBlur={(e) => {
                          if (isWhiteSpace || e.target.value.trim() === e.target.defaultValue) {
                            setIsWhiteSpace(false)
                            setFocusedItemId(undefined)
                            setFocusedItemTempId(undefined)
                            return
                          }
                            handleUpdateName(e.target.value.trim())
                        }}
                        sx={styles.renameParent}
                        error={!isValidName || isWhiteSpace}
                        helperText={getHelperText()}
                      />
                    ): (
                      <Typography
                        data-testId={`parent-name-typography-${parent.id}`}
                        onDoubleClick={(e) => {
                          e.stopPropagation()
                          setFocusedItemId(parent.id)
                          setFocusedItemTempId(parent.tempId)
                        }}
                      >
                        {`${parent.name} (${parent.children.length})`}
                      </Typography>
                    )}
                    
                  </TableCell>
                  <TableCell sx={{...styles.parentTableCell, textAlign: 'right' }}>
                    <Typography
                      sx={{ pl: '0.25rem'}}
                    >
                      {`${parent.arAmount ? formatCurrency(parent.arAmount) : `-`}`}
                    </Typography>
                  </TableCell>
                  <TableCell sx={styles.parentTableCell}>
                    <ParentMenu
                      parent={parent}
                      modalProps={props.modalProps}
                      setModalProps={props.setModalProps}
                      setFocusedItemId={setFocusedItemId}
                      setFocusedItemTempId={setFocusedItemTempId}
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  onDrop={(event) => {
                    const eventList = event.dataTransfer.getData("text/plain")
                    const formattedList = JSON.parse(eventList)
                    const hasVendors = findHasVendors(formattedList);
                    if (hasVendors.length > 0) {
                      setConfirmUpdateModal({
                        ...confirmUpdateModal,
                        tempId: parent.tempId,
                        isOpen: true,
                        isExistingParent: true,
                        parentId: parent.id,
                        parentName: parent.name,
                        children: formattedList,
                        hasVendors: hasVendors,
                      });
                      return;
                    }
                    handleUpdate(formattedList, Number(formattedList[0].listName), parent.id, parent.tempId)
                  }}
                  onDragOver={(event) => { event.preventDefault() }}
                >
                  <TableCell colSpan={100} sx={{ p: 0, borderBottom: 'none' }} data-testId={`parent-row-children-list-${idx}`}>
                    <Collapse in={parent.isExpanded}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={styles.childrenHeaderSelectCell}></TableCell>
                            <TableCell sx={styles.childrenHeaderNameCell}>
                              <Typography sx={styles.childrenHeaderTypography}>
                                Accounts Name
                              </Typography>
                            </TableCell>
                            <TableCell sx={styles.childrenHeaderCell}>
                              <Typography sx={styles.childrenHeaderTypography}>
                                Accounts Amount
                              </Typography>
                            </TableCell>
                            <TableCell sx={styles.childrenHeaderCell}>
                              <Typography sx={styles.childrenHeaderTypography}>
                                Action
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <DraggableTable
                          list={parent.children}
                          multiple={true}
                          inParent={true}
                          name={`${parent.id}`}
                          tempId={parent.tempId}
                          onItemRemove={(list, fromList, toList) => {
                            props.setModalProps({
                              ...props.modalProps,
                              tempId: parent.tempId,
                              isLastChild: parent.children.length < 2,
                              isParent: false,
                              isOpen: true,
                              parentId: parent.id,
                              children: list,
                              from: Number(fromList),
                              to: Number(toList)
                            })
                          }}
                          onItemDragOver={(list) => { }}
                          onItemDragStart={() => {
                            props.setModalProps({
                              ...props.modalProps,
                              tempId: parent.tempId
                            })
                          }}
                          onItemDrop={(list, from, to, tempId) => {
                            const hasVendors = findHasVendors(list);
                            if (hasVendors.length > 0) {
                              setConfirmUpdateModal({
                                ...confirmUpdateModal,
                                tempId: parent.tempId,
                                isOpen: true,
                                isExistingParent: true,
                                parentId: parent.id,
                                children: list,
                                hasVendors: hasVendors,
                              });
                              return;
                            }
                            handleUpdate(list, Number(from), Number(to), tempId)
                          }}  
                        />
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
                </>
              ))}
            </TableBody>
            {/* Linear Progress */}
            {!parentPage.isLastPage ?
              <TableBody>
                <TableRow>
                  <TableCell colSpan={100}>
                      <LinearProgress ref={lastRowParentElementRef} />
                  </TableCell>
                </TableRow>
              </TableBody>: <></>
            }
          </Table>
        </TableContainer>
      </Box>
      {/* Create Parent Modal */}
      <CustomerModal 
        open={showParentModal}
        setOpen={setShowParentModal} 
        newParentId={newParentId} 
        setNewParentId={setNewParentId}
        customerList={parentsWithChildrenList}
        onSave={handleSave}
        isParent
      />
      <ConfirmModal
        title={`Child Customers Have Associated Vendor/s`}
        description={getConfirmModalDescription()}
        open={confirmUpdateModal.isOpen} 
        onClose={() => {
          setConfirmUpdateModal({
            ...confirmUpdateModal,
            isOpen: false
          })
        }} 
        onConfirm={() => {
          if (confirmUpdateModal.isExistingParent) {
            const { children, parentId, tempId } = confirmUpdateModal;
            handleUpdate(children, Number(children[0].listName), parentId, tempId);
          }
          else {
            props.setIsDragging(false);
            setShowParentModal(true);
            setSelectedOrphans(confirmUpdateModal.children);  
          }
        }}        
        yesButtonText={'Proceed'}
        noButtonText={'Cancel'}
        errorButton={false}
      >{
        <Box sx={{...styles.overflowBox, maxHeight: '10rem'}}>
            {confirmUpdateModal.hasVendors.map((child) =>
              <ListItem 
                key={child.recordId} 
                dense 
                disablePadding
              >
                <ListItemIcon sx={{...styles.flexCenter, justifyContent: 'end'}}>
                  <Circle sx={styles.circleIcon}/>
                </ListItemIcon>
                <ListItemText>{child.name}</ListItemText>
              </ListItem>            
            )}
        </Box>
      }
      </ConfirmModal>
    </>
  )
}

export default ParentSection