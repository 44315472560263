import { API_DOMAIN, GET, NO_PERMISSION_MSG, PATCH, PERMISSIONS, SEARCH_FILTER } from '../../../utility/constants';
import { Box, Button, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { DraggableListItem } from '../../../interfaces/draggableList';
import { IOption } from '../../../interfaces/comboBox';
import Prompt from '../../../components/modals/prompt';
import styles from './styles';
import CustomerSection from './customer-section/customerSection';
import ConfirmModal from '../../../components/modals/confirm-modal';
import CircleIcon from '@mui/icons-material/Circle';
import UnlinkedVendorSection from './unlinked-vendor-section/unlinkedVendorSection';
import axiosInstance from '../../../service/axiosInstance';
import { CustomerSettingsContext, ICustomerSettingsContext } from '../../../context/customerSettingsContext';
import { checkUserPermissions, clearNewCustomersByIds, clearNewVendorsByIds, getLocalStorageItem } from '../../../utility/helper';
import { IClearModalProps, IToasterProps } from '..';
import DisabledComponentsContainer from '../../../components/common/disabled-components-container';
import { EditVendorCustomerSetupContext } from '../../../context/EditVendorCustomerSetupProvider';
import { IEditVendorCustomerSetupContext, IModalProps, IParentWithChild } from '../../../interfaces/editVendorCustomerSetupProvider';
import { AxiosRequestConfig } from 'axios';
import { IARCustomer, IARVendor } from '../../../interfaces';
import { arCustomersAPI, arVendorsAPI } from '../../../service/api';
import { SelectedClientContext } from '../../../context/selectedClientContext';

export interface ITempData {
  recordId?         : number
  arCollateralId    : number,
  borrowerId        : string,
  custName?         : string,
  custSrcId?        : string,
  dbRating?         : string,
  creditLimit?      : number,
  custCountry?      : string,
  custAddress1?     : string,
  custAddress2?     : string,
  custCity?         : string,
  custState?        : string,
  custPostalCode?   : string,
  custPhone?        : string,
  custDescription?  : string  
}
interface IProps extends IToasterProps {
  selectedARCollateral: IOption | null
  borrowerId?: string,
  setIsEditVendorView: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

/**
 * This component renders a page for parent view.
 * 
 * @param props IProps
 */
const EditVendorViewClientSetting: FC<IProps> = (props) => {
  const {
    parentsWithChildrenList,
    setParentsWithChildrenList,
    unlinkedVendorsList,
    setUnlinkedVendorsList,
    newUnlinkedVendorsList,
    setNewUnlinkedVendorsList,
    newParentsWithChildrenList,
    setNewParentsWithChildrenList,
    deletedParentIds,
    setDeletedParentIds,
    setParentPage,
    orphansPage,
    setOrphansPage,
    setApAmountMap,
  }                                                           = useContext(EditVendorCustomerSetupContext) as IEditVendorCustomerSetupContext;
  const {selectedClient}                                      = useContext(SelectedClientContext);
  const {canEditParentChildRelationship, setToaster}          = useContext(CustomerSettingsContext) as ICustomerSettingsContext;
  const [isDirty, setIsDirty]                                 = useState<boolean>(false)
  const [modalProps, setModalProps]                           = useState<IModalProps>({isParent: false, isOpen: false, parentId: 0, parentName: '', children: [], from: 0, to: 0})
  const [showConfirmModal, setShowConfirmModal]               = useState<boolean>(false)
  const [clearModal, setClearModal]                           = useState<IClearModalProps>({ isOpen: false, type: 'customer' });
  const [isDragging, setIsDragging]                           = useState<boolean>(false)

  /**
   * This useEffect is used to check if there's a changed on orphanList or parentList. If so, it will set the isDirty state to true
   */
  useEffect(() => {
    if (newParentsWithChildrenList.length || newUnlinkedVendorsList.length) {
      setIsDirty(true)
    }
  }, [newUnlinkedVendorsList, newParentsWithChildrenList])

  useEffect(() => {
    if (props.selectedARCollateral?.recordId) {
      fetchVendorsAndCustomers();
    }
  }, [props.selectedARCollateral?.recordId]);

  const fetchVendorsAndCustomers = async () => {
    try {
      props.setIsLoading(true);
      await Promise.all([
        axiosInstance.request({
          url: selectedClient?.parentClient ? arCustomersAPI.FIND_BY_PARENT_BORROWER_ID_NO_PAGINATION : arCustomersAPI.FIND_BY_AR_COLLATERAL_ID,
          method: GET,
          params: selectedClient?.parentClient ? 
          {parentBorrowerId: selectedClient?.recordId}
          : {arCollateralId: props.selectedARCollateral?.recordId}
        }),
        axiosInstance.request({
          url: selectedClient?.parentClient ? arVendorsAPI.FIND_BY_PARENT_BORROWER_ID : arVendorsAPI.FIND_BY_AR_COLLATERAL_ID,
          method: GET,
          params: selectedClient?.parentClient ? 
          {parentBorrowerId: selectedClient?.recordId}
          : {arCollateralId: props.selectedARCollateral?.recordId}
        }),
        axiosInstance.request({
          url: selectedClient?.parentClient ? arCustomersAPI.FIND_AR_AMOUNT_MAP_BY_PARENT_BORROWER_ID : arCustomersAPI.FIND_AR_AMOUNT_MAP_BY_AR_COLLATERAL_ID,
          method: GET,
          params: selectedClient?.parentClient ? 
          {parentBorrowerId: selectedClient?.recordId}
          : {arCollateralId: props.selectedARCollateral?.recordId}
        }),
        axiosInstance.request({
          url: selectedClient?.parentClient ? arCustomersAPI.FIND_AP_AMOUNT_MAP_BY_PARENT_BORROWER_ID : arCustomersAPI.FIND_AP_AMOUNT_MAP_BY_AR_COLLATERAL_ID,
          method: GET,
          params: selectedClient?.parentClient ? 
          {parentBorrowerId: selectedClient?.recordId}
          : {arCollateralId: props.selectedARCollateral?.recordId}
        })
      ])
      .then(([customersResponse, vendorsResponse, arAmountResponse, apAmountResponse]) => {
        const arAmountMap = arAmountResponse.data;
        const apAmountMap = apAmountResponse.data;
        
        const tempCustomerList: IARCustomer[] = (selectedClient?.parentClient ? customersResponse.data : customersResponse.data.content).map((cust) => ({
          ...cust,
          arAmount: arAmountMap[cust.recordId as number],
        }));
        const tempVendorList: IARVendor[] = vendorsResponse.data.map((vendor) => ({
          ...vendor,
          apAmount: apAmountMap[vendor.recordId as number],
        }));
        
        handleRelationship([...tempCustomerList, ...tempVendorList]);

        setOrphansPage({pageNo: 0, isLoading: false, isLastPage: false, searchKey: ''})
        setApAmountMap(apAmountMap);
      })
      .catch((error) => {
        console.log('GET ORPHANS : ', error)
      })
    } catch (error) {
      console.log('GET ALL VENDORS AND CUSTOMERS EDIT PAGE: ', error);
    } finally {
      props.setIsLoading(false);
    }
  }

  /**
   * This function is used to arrange the children of a parent customer default first
   * 
   * @param customerList A lsit of IARCustopmer from API call
   * @param parentCustSrcId The custSrcId of a Parent customer
   * @returns The defaultChildId and children fields need for IParentWithChild object list  
   */
    const findChildren = (customerList: (IARCustomer | IARVendor)[], parentARCustomerId: number) => {
      const custChildren: DraggableListItem[] = customerList.filter((child: IARCustomer) =>
        selectedClient?.parentClient
          ? (child?.upcParentCustId !== undefined && child.upcParentCustId === parentARCustomerId)
          : (child?.parentARCustomerId !== undefined && child.parentARCustomerId === parentARCustomerId)
        )
        .map((child: IARCustomer) => ({
          recordId: child.recordId as number,
          value: (child.recordId as number).toString(),
          name: child.custName as string,
          checked: false,
          disabled: false,
          listName: parentARCustomerId.toString(),
          srcId: child.custSrcId!,
          parentSrcId: parentARCustomerId.toString(),
          amount: child.arAmount,
          isNew: child.isNew,
        }));
      const vendors = customerList.filter(vendor=> (vendor as IARVendor)?.arCustomerId !== undefined && (vendor as IARVendor).arCustomerId === parentARCustomerId) as IARVendor[];
      const custVendors: DraggableListItem[] = vendors
        .map((child: IARVendor) => ({
          recordId: child.recordId as number,
          value: (child.recordId as number).toString(),
          name: child.vendorName as string,
          checked: false,
          disabled: false,
          listName: parentARCustomerId.toString(),
          srcId: child.vendorSrcId!,
          parentSrcId: parentARCustomerId.toString(),
          isVendor: true,
          amount: child.apAmount,
          isNew: child.isNew,
        }));
      return {
        defaultChildId: '',
        children: [...custChildren, ...custVendors]
      }
    };

  /**
   * This function is used to handle the relation between parent and their child customer
   * @param customerList A list of IARcustomer from API call
   */
    const handleRelationship = (customerList: (IARCustomer | IARVendor)[]) => {
      const parentIds = [
        ...new Set(
            customerList
              .filter((cust) => (cust as IARCustomer)?.custSrcId !== undefined &&
              (
                ((cust as IARCustomer)?.parentARCustomerId === undefined && !selectedClient?.parentClient) ||
                ((cust as IARCustomer)?.upcParentCustId === undefined && selectedClient?.parentClient)
              ))
              .map((filteredCust: IARCustomer) => filteredCust.recordId as number)
          )
        ];
  
      const parentObjects: IParentWithChild[] = customerList
        .filter((cust: IARCustomer) => parentIds.includes(cust.recordId as number))
        .map((filteredCust: IARCustomer)  => ({
          ...filteredCust,
          id: filteredCust.recordId!,
          name: filteredCust.custName!,
          isExpanded: false,
          ...findChildren(customerList, filteredCust.recordId as number)
        })).sort((a, b) => a.name.localeCompare(b.name));
  
      setParentsWithChildrenList([...parentObjects])
      setParentPage({pageNo: 1, isLoading: false, isLastPage: false, searchKey: '', searchFilter: SEARCH_FILTER.ALL})
    }

  /**
   * This function discard all changes on both parentList and orphanList
   */
  const handleReset = () => {
    setNewUnlinkedVendorsList([])
    setNewParentsWithChildrenList([])
    setIsDirty(false)
  }

  /**
   * This function redirect the user to customer settings page if there are no changes made.
   * If isDirty state is true, it will show the confirm prompt modal.
   */
  const handleCancel = () => {
    if(isDirty){
      setShowConfirmModal(true)
      return
    }
    handleReset()
    props.setIsEditVendorView(false)
  }

  /**
   * This function removed a parent on the parentLists.
   * 
   * @param parentId The ID of the parent to be remove.
   * @param tempId The temporary ID of an unsave parent.
   */
  const handleRemoveParent = (parentId: number, tempId?: number) => {
    const removedParent = parentsWithChildrenList.find(parent => (parent.id === parentId && parent.tempId === tempId))
    const newList = parentsWithChildrenList.filter(parent => (parent.id !== parentId && (tempId === undefined || parent.tempId !== tempId)))
    if(removedParent){
      const newOrphans = removedParent.children.map(child => {
        return {...child, checked: false}
      })
      setUnlinkedVendorsList([...unlinkedVendorsList, ...newOrphans].sort((a, b) => a.name.localeCompare(b.name)))
      setNewUnlinkedVendorsList([...newUnlinkedVendorsList, ...(removedParent?.children ?? [])]) 
      if(!removedParent.tempId){
        setDeletedParentIds([...deletedParentIds, removedParent.id])
      }else{
        console.log(tempId, 'onRemove')
        console.log(newParentsWithChildrenList)
       const tempList = newParentsWithChildrenList.filter(o => o.tempId !== tempId)
       console.log(tempList)
       setNewParentsWithChildrenList(tempList)
      }
    }
    setParentsWithChildrenList(newList)
  }

  /**
   * This function remove a child of a parent on parentLists and put it on the orphansList.
   * 
   * @param childId The ID of the child to be removed.
   * @param parentId The ID of the child's parent to be removed.
   * @param tempId The temporary ID of the child's parent it it's an unsave parent.
   */
  const handleRemoveChild = (childId: number, parentId: number, tempId?: number) => {
    let removedChild: DraggableListItem[] = []
    let newChildren: DraggableListItem[] = []
    let updatedParent: IParentWithChild | undefined = undefined

    const newList = parentsWithChildrenList.map(parent => {
      if (parent.id === parentId && parent.tempId === tempId) {
        removedChild = parent.children.filter(child => child.recordId === childId).map(child2 => ({...child2, checked: false, listName: 'orphans', parentSrcId: undefined }))
        newChildren = parent.children.filter(child => child.recordId !== childId)
        const newParent = {
          ...parent,
          defaultChildId: removedChild[0].default ? newChildren[0].recordId.toString() : parent.defaultChildId,
          children: newChildren
        }
        updatedParent = newParent
        return newParent
      }
      return parent
    })

    const updatedNewList = newParentsWithChildrenList.filter(parent => parent.id !== parentId && parent.tempId !== tempId)
    setNewParentsWithChildrenList([...updatedNewList, ...(updatedParent ? [updatedParent] : [])])
    setParentsWithChildrenList(newList)

    setUnlinkedVendorsList([...unlinkedVendorsList, ...removedChild].sort((a, b) => a.name.localeCompare(b.name)))
    setNewUnlinkedVendorsList([...newUnlinkedVendorsList, ...removedChild])
  }

  /**
   * This function checks the current user's permission to do a specific functionality.
   * 
   * @param func The function that the user want's to use.
   * @param permission The permission need to use the specified functionality.
   */
  const checkPermission = async (func: Function, permission: string) => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), permission)
    if(isPermitted){
      func()
      return
    }
    props.setIsToasterOpen(true)
    props.setToasterMessage(NO_PERMISSION_MSG)
    props.setToasterSeverity('error')
  }

  /**
   * This function handles the posting, updating, and deleting of AR Customer relationship on the database.
   */
  const handleSave = async () => {
    try {
      props.setIsLoading(true)
      // save unlinked vendors request
      await Promise.all(newUnlinkedVendorsList
        .filter((element) => !newParentsWithChildrenList.some(parent => parent.children.some(child => child.recordId === element.recordId)))
        .map((element) => axiosInstance.request({
          url: `${API_DOMAIN}/ar/vendors/updateARCustomerId/${element.recordId}`,
          method: PATCH,
          params: {
            arCustomerId: null,
          }
        })));

      const saveConfigs: AxiosRequestConfig[] = [];
      
      //save new/updated vendors
      for(const element of newParentsWithChildrenList){
        if (element.children.length === 0) { continue; }
        for(const child of element.children){
          if (!child.isVendor) { continue; }
          const config = {
            url: `${API_DOMAIN}/ar/vendors/updateARCustomerId/${child.recordId}`,
            method: PATCH,
            params: {
              arCustomerId: element.recordId,
            }
          };
          saveConfigs.push(config);
        }
      }
      // save new/updated vendors request
      await Promise.all(saveConfigs.map((config) => axiosInstance.request(config)));

      props.setIsLoading(false)
      props.setIsToasterOpen(true)
      props.setToasterMessage('Changes saved')
      props.setToasterSeverity('success')
      handleReset()
      props.setIsEditVendorView(false)
    }
    catch (error) {
      console.log('ERROR SAVING CHANGES : ', error)
      props.setIsToasterOpen(true)
      props.setToasterMessage('Request failed. Please try again.')
      props.setToasterSeverity('error')
    }
  }

  /**
   * This function clears all new unlinked vendors
   */

  const handleClearUnlinkedVendors = async () => {
    // manually clear orphans without refetching the customers
    
    try {
      const unlinkedIds = unlinkedVendorsList.map(orphan => orphan.recordId);
      await clearNewVendorsByIds(unlinkedIds);
      const clearedUnlinkedVendors = unlinkedVendorsList.map((unlinkedVendor: DraggableListItem) => ({
        ...unlinkedVendor,
        isNew: false,
      }));
      
      setUnlinkedVendorsList(clearedUnlinkedVendors);
      setToaster({ open: true, message: 'Successfully cleared all vendors!', severity: 'success' });
    } catch (error) {
      console.log('CLEAR ALL UNLINKED VENDORS ', error)
    }
  }

  /**
   * This function clears all new linked vendors
   */

  const handleClearCustomerSection = async () => {
    // manually clear customers without refetching the customers
    
    try {
      const childCustomers = parentsWithChildrenList.flatMap(parent => parent.children.filter(child => !child.isVendor).map(customer => ({ ...customer, isNew: false })));
      const parentCustomers = parentsWithChildrenList.filter(parent => parent.recordId).map(filteredParent => ({ ...filteredParent, isNew: false }));

      const customers: IARCustomer[] = [...parentCustomers, ...childCustomers]
      const customerIds = customers.map(customer => customer.recordId!);
      await Promise.all([
        clearNewCustomersByIds(customerIds),
      ]);

      const clearedParentsWithChildrenList = parentsWithChildrenList.map(parent => {
        const clearedParent = {
          ...parent,
          children: parent.children.map(child => ({
            ...child,
            isNew: child.isVendor ? child.isNew : false,
          })),
          isNew: false,
        };
        
        return clearedParent;
      });

      setParentsWithChildrenList(clearedParentsWithChildrenList);
      setToaster({ open: true, message: 'Successfully cleared all customers!', severity: 'success' });
    } catch (error) {
      console.log('CLEAR ALL IN CUSTOMER SECTION ', error)
    }
  }

  /**
   * This function generate a spcific message for the warning modal based on the modalProps values.
   * 
   * @returns A dynamic message specified with modalProps values.
   */
  const getConfirmModalDescription = () => {
    if (modalProps.isParent) {
      return 'The relationship between this parent and its children will be removed. Are you sure you want to delete this parent?';
    } else {
      return `You are about to remove ${modalProps.children.length > 1 ? 'these children' : 'this child'}. Are you sure?`
    }
  }
 
  return <>
    {canEditParentChildRelationship ? 
      <>   
        <Box sx={styles.saveBar}>
          <Box width={styles.maxWidth}>
            <Typography tabIndex={0} sx={styles.title}>
              Edit Vendor and Customer Relationship
            </Typography>
          </Box>
          <Box sx={styles.saveCancelButton}>
            <Button
              data-testid={`parent-view-cancel-button`}
              onClick={() => handleCancel()}
              variant='outlined'
              >
                Cancel
            </Button>
            <DisabledComponentsContainer isDisabled={!isDirty}>
              <Button
                data-testid={`parent-view-save-button`}
                onClick={() => checkPermission(handleSave, PERMISSIONS.EDIT_PARENT_CHILD_RELATIONSHIP)}
                variant='contained'
                disabled={!isDirty}
                aria-label={!isDirty ? 'Save button disabled' : 'Save'}
                >
                  Save
              </Button>
            </DisabledComponentsContainer>
          </Box>
        </Box>
        <Grid container sx={styles.gridSection}>
          {/* PARENT CHILD SECTION */}
          <CustomerSection
            borrowerId={props.borrowerId}
            selectedARCollateral={props.selectedARCollateral}
            isDirty={isDirty}
            setIsDirty={setIsDirty}
            modalProps={modalProps}
            setModalProps={setModalProps}
            setShowConfirmModal={setShowConfirmModal}
            setClearModal={setClearModal}
            setIsDragging={setIsDragging}
            isDragging={isDragging}
          />
          {/* ORPHANS SECTION */}
          <UnlinkedVendorSection
            borrowerId={props.borrowerId}
            selectedARCollateral={props.selectedARCollateral}
            modalProps={modalProps}
            setModalProps={setModalProps}
            setClearModal={setClearModal}
            setIsDragging={setIsDragging}
          />

          <ConfirmModal
            title={modalProps.isParent ? `Delete ${modalProps.parentName}` : 'Remove'}
            description={getConfirmModalDescription()} 
            open={modalProps.isOpen} 
            onClose={() => {
              setModalProps({
                ...modalProps,
                isOpen: false
              })
            }} 
            onConfirm={() => {
              if(modalProps.isParent){
                handleRemoveParent(modalProps.parentId, modalProps.tempId)
              }else{
                handleRemoveChild(modalProps.children[0].recordId, modalProps.parentId, modalProps.tempId)
              }
            }}        
            yesButtonText={'Remove'}
            noButtonText={'Cancel'}
            errorButton={modalProps.isParent}
          >{modalProps.isParent && 
              <Box sx={{...styles.overflowBox, maxHeight: '10rem'}}>
                  {modalProps.children.map((child, idx) =>
                    <ListItem 
                      key={child.recordId} 
                      dense 
                      disablePadding
                    >
                      <ListItemIcon sx={{...styles.flexCenter, justifyContent: 'end'}}>
                        <CircleIcon sx={styles.circleIcon}/>
                      </ListItemIcon>
                      <ListItemText>{child.name}</ListItemText>
                    </ListItem>            
                  )}
              </Box>
          }
          </ConfirmModal>
          <Prompt when={isDirty} isEditing={() => {}}/>
          <ConfirmModal
            open={showConfirmModal}
            onButtonClose={() => setShowConfirmModal(false)}
            onClose={() => {
              setShowConfirmModal(false)
              props.setIsEditVendorView(false);
            }}
            onConfirm={() => setShowConfirmModal(false)}
            promptChecker
            title={`Confirm Navigation`}
            description={`You have unsaved changes. Are you sure you want to leave this page?`}
            yesButtonText={`Keep Editing`}
            noButtonText={`Discard Changes`}
            confirmOnly
            />
          <ConfirmModal
            title={`Clear All New ${clearModal.type === 'vendor' ? 'Vendors' : 'Customers'}`}
            description={`Are you sure you want to clear all new added ${clearModal.type === 'vendor' ? 'vendors' : 'customers'}?`} 
            open={clearModal.isOpen} 
            onClose={() => {
              setClearModal({
                ...clearModal,
                isOpen: false
              });
            }} 
            onConfirm={() => {
              if (clearModal.type === 'customer') {
                handleClearCustomerSection();
              }
              else if (clearModal.type === 'vendor') {
                handleClearUnlinkedVendors();
              }
            }}        
            yesButtonText={'Clear'}
            noButtonText={'Cancel'}
          />
        </Grid>
      </>  :
      <Box sx={styles.noPermissionBox}>
        You do not have the permissions to view this page.
      </Box>
    }
  </>
}

export default EditVendorViewClientSetting