import { FC } from 'react';
import ARAgingReportProvider from '../../../context/arAgingReportContext';
import ARAgingReportNavigationHeader from './navigation-header';
import ARAgingMainContainer from './main-container';

const ARAgingReport: FC = () => {
  return (
    <ARAgingReportProvider>
      <ARAgingReportNavigationHeader />
      <ARAgingMainContainer />
    </ARAgingReportProvider>
  );
};

export default ARAgingReport;
