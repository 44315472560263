import { createContext, useMemo, useState } from 'react';
import { LayoutProps } from './authContext';
import { ToasterState } from '../components/toaster';
import { IARCustomer, IARVendor } from '../interfaces';

export interface ICustomerSettingsContext {
  canViewCustomerList: boolean,
  setCanViewCustomerList:(value: boolean) => void,
  canViewCustomerInformation: boolean,
  setCanViewCustomerInformation:(value: boolean) => void,
  canUpdateCustomerInformation: boolean,
  setCanUpdateCustomerInformation:(value: boolean) => void,
  canViewParentList: boolean,
  setCanViewParentList:(value: boolean) => void,
  canViewParentInformation: boolean,
  setCanViewParentInformation:(value: boolean) => void,
  canUpdateParentInformation: boolean,
  setCanUpdateParentInformation:(value: boolean) => void,
  canEditParentChildRelationship: boolean,
  setCanEditParentChildRelationship:(value: boolean) => void,
  toaster: ToasterState,
  setToaster: (value: ToasterState) => void,
}

export const CustomerSettingsContext = createContext<ICustomerSettingsContext | null>(null);

const CustomerSettingsProvider = (props: LayoutProps) => { 
  const { children } = props;
  
  const [ canViewCustomerList, setCanViewCustomerList] = useState<boolean>(false);
  const [ canViewCustomerInformation, setCanViewCustomerInformation] = useState<boolean>(false);
  const [ canUpdateCustomerInformation, setCanUpdateCustomerInformation] = useState<boolean>(false);
  const [ canViewParentList, setCanViewParentList] = useState<boolean>(false);
  const [ canViewParentInformation, setCanViewParentInformation] = useState<boolean>(false);
  const [ canUpdateParentInformation, setCanUpdateParentInformation] = useState<boolean>(true);
  const [ canEditParentChildRelationship, setCanEditParentChildRelationship] = useState<boolean>(false);
  const [ toaster, setToaster] = useState<ToasterState>({ open: false, message: '', severity: 'success' });

  const value = useMemo(() => ({ 
    canViewCustomerList,
    setCanViewCustomerList,
    canViewCustomerInformation,
    setCanViewCustomerInformation,
    canUpdateCustomerInformation,
    setCanUpdateCustomerInformation,
    canViewParentList,
    setCanViewParentList,
    canViewParentInformation,
    setCanViewParentInformation,
    canUpdateParentInformation,
    setCanUpdateParentInformation,
    canEditParentChildRelationship,
    setCanEditParentChildRelationship,
    toaster,
    setToaster,
   }), [
    canViewCustomerList,
    setCanViewCustomerList,
    canViewCustomerInformation,
    setCanViewCustomerInformation,
    canUpdateCustomerInformation,
    setCanUpdateCustomerInformation,
    canViewParentList,
    setCanViewParentList,
    canViewParentInformation,
    setCanViewParentInformation,
    canUpdateParentInformation,
    setCanUpdateParentInformation,
    canEditParentChildRelationship,
    setCanEditParentChildRelationship,
    toaster,
    setToaster,
   ])

  return (
    <CustomerSettingsContext.Provider
      value={value}
    >
      {children}
    </CustomerSettingsContext.Provider>
  );
};

export default CustomerSettingsProvider;