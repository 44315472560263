import { createContext, useMemo, useState } from "react";
import { LayoutProps } from "./authContext";
import { IParentWithChild } from "../interfaces/parentChildSetupInterface";
import { DraggableListItem } from "../interfaces/draggableList";
import { IAccountsMap, IEditVendorCustomerSetupContext, IPaging } from "../interfaces/editVendorCustomerSetupProvider";
import { SEARCH_FILTER } from "../utility/constants";

export const EditVendorCustomerSetupContext = createContext<IEditVendorCustomerSetupContext | null>(null);

const EditVendorCustomerSetupProvider = (props: LayoutProps) => {
  const { children } = props;
  const [unlinkedVendorsList, setUnlinkedVendorsList]               = useState<DraggableListItem[]>([])
  const [newUnlinkedVendorsList, setNewUnlinkedVendorsList]         = useState<DraggableListItem[]>([])
  const [selectedUnlinkedVendors, setSelectedUnlinkedVendors]       = useState<DraggableListItem[]>([])
  const [parentsWithChildrenList, setParentsWithChildrenList]       = useState<IParentWithChild[]>([])
  const [newParentsWithChildrenList, setNewParentsWithChildrenList] = useState<IParentWithChild[]>([])
  const [removedUnlinkedVendorIds, setRemovedUnlinkedVendorIds]     = useState<number[]>([])
  const [deletedParentIds, setDeletedParentIds]                     = useState<number[]>([])
  const [parentPage, setParentPage]                                 = useState<IPaging>({pageNo: 1, isLoading: false, isLastPage: false, searchKey: '', searchFilter: SEARCH_FILTER.ALL})
  const [orphansPage, setOrphansPage]                               = useState<IPaging>({pageNo: 0, isLoading: false, isLastPage: false, searchKey: ''});
  const [apAmountMap, setApAmountMap]                               = useState<Map<string, number>>(new Map<string, number>());
  

  const value = useMemo(() => ({
    unlinkedVendorsList,
    setUnlinkedVendorsList,
    newUnlinkedVendorsList,
    setNewUnlinkedVendorsList,
    selectedUnlinkedVendors,
    setSelectedUnlinkedVendors,
    parentsWithChildrenList,
    setParentsWithChildrenList,
    newParentsWithChildrenList,
    setNewParentsWithChildrenList,
    removedUnlinkedVendorIds,
    setRemovedUnlinkedVendorIds,
    deletedParentIds,
    setDeletedParentIds,
    parentPage, 
    setParentPage,
    orphansPage, 
    setOrphansPage,
    apAmountMap,
    setApAmountMap,
  }), [
    unlinkedVendorsList,
    setUnlinkedVendorsList,
    newUnlinkedVendorsList,
    setNewUnlinkedVendorsList,
    selectedUnlinkedVendors,
    setSelectedUnlinkedVendors,
    parentsWithChildrenList,
    setParentsWithChildrenList,
    newParentsWithChildrenList,
    setNewParentsWithChildrenList,
    removedUnlinkedVendorIds,
    setRemovedUnlinkedVendorIds,
    deletedParentIds,
    setDeletedParentIds,
    parentPage, 
    setParentPage,
    orphansPage, 
    setOrphansPage,
    apAmountMap,
    setApAmountMap,
  ])

  return (
    <EditVendorCustomerSetupContext.Provider
      value={value}>
      {children}
    </EditVendorCustomerSetupContext.Provider>
  );
};

export default EditVendorCustomerSetupProvider;