import { ActionType, BankLogoActions } from '../actions/bankLogoActions';
import { getLocalStorageItem } from '../utility/helper';

export interface BankLogoState {
  logoDataUrl: string;
}

export const initialState: BankLogoState = {
  logoDataUrl: getLocalStorageItem('logoDataUrl')
};

const bankLogoReducer = (state: BankLogoState, action: BankLogoActions): BankLogoState => {
  // Turned into an if statement since it only has 2 cases as of the moment
  if (action.type === ActionType.UPDATE_LOGO) {
    return {
      ...state,
      logoDataUrl: action.payload
    }
  } else {
    return state;
  }
};

export default bankLogoReducer;