const styles = {
  pageStyle: {
    backgroundColor: '#F2F7F8',
    minHeight: 'calc(100vh - 4rem)',
  },
  searchField: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
    width: '100%'
  },
  comboBoxStyle: {
    display: 'flex',
  },
  tableStyle: {
    display: 'flex',
  },
  linkStyle: {
    marginX: 6,
    marginY: 5,
  },
  searchStyle: {
    alignSelf: 'center',
    marginTop: '13px',
    flexBasis: 'auto',
  },
  addClientStyle: {
    alignSelf: 'center',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    width: 'max-content',
  },
  breadcrumbsBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    px: '24px',
    py: '12.75px'
  },
  clientComboBoxContainer: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};

export default styles;
