import colors from "../../utility/colors";

const styles = {
  breadCrumbBox: {
    paddingRight: '24px',
    borderRadius: '0.3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  container: {
    backgroundColor: '#F2F7F8',
    minHeight: '100vh',
  },
  customerSettingsHeader: {
    backgroundColor: '#fff',
    marginY: '1rem',
    paddingX: '1rem',
    paddingY: '2rem',
  },
  title: {
    fontWeight: 'bold',
    color: '#010953',
    fontSize: '18px',
  },
  titleContainer: {
    backgroundColor: '#FEFEFE',
    py: 2.5
  },
  clientDropdown: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  clientBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navButtonBase: {
    height: '36.6px',
    borderColor: '#E0E0E0',
    color: '#212529'
  },
  selectedBase: {
    height: '36.6px',
    borderColor: '#154A8A',
    color: '#154A8A'
  },
  rightBorder: {
    borderRadius: '0 4px 4px 0', 
  },
  leftBorder: {
    borderRadius: '4px 0 0 4px', 
  },
  navBox: {
    display: 'flex', 
    justifyContent: 'space-between',
    alignItems: 'center', 
    mb: '1rem'
  },
  customerListText: {
    fontSize: '16px', 
    color: '#154A8A',
  },
  buttonText: {
    fontSize: '14px',
    color: '#fff',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  buttonTabs: {
    '.MuiTabs-Box': {
      border: '1px solid #E0E0E0',
    },
    '.MuiTabs-scroller': {
      background: '#F7F7F7',
    },
    '.MuiButtonBase-root.MuiTab-root': {
      borderRadius: '3px 3px 0 0',
      textTransform: 'none',
      width: '220px',
      marginRight: '5px',
      padding: '8px',
      boxShadow: '0 -5px 30px -7px #E0E0E0',
      color: '#000000',
    },
    '.MuiButtonBase-root.MuiTab-root.Mui-selected': {
      background: 'white',
      color: '#000000',
    },
    '.MuiTabs-indicator': {
      display: 'none',
    },
    '.MuiTabs-flexContainer': {
      paddingTop: '10px',
    },
  },
  buttonSubTabs: {
    marginLeft: '24px',
  },
  boxSubTabs: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '24px',
  },
  boxActionPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginX: '24px',
    height: '48px',
    backgroundColor: colors.BACKGROUND_COLOR,
  },
  tabBox: {
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    borderRadius: '3px 3px 0 0',
  },
  noPermissionBox: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center'
  },
  hidden: {
    display: 'none'
  },
  invisible: {
    visibility: 'hidden'
  },
  MarginLeft: {
    marginLeft: '24px'
  },
  clearButton: {
    color: colors.PRIMARY_LIGHT,
    fontSize: '14px',
  }
};

export default styles;
