import { Dispatch, FC, SetStateAction, useEffect, useState, useContext, SyntheticEvent, ChangeEvent, MouseEvent, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AlertColor, Box, Button, Container, Grid, IconButton, Link, Tab, TablePagination, Tabs, Typography } from '@mui/material';
import styles from './styles';
import { GET, PERMISSIONS, } from '../../utility/constants';
import { IARSettings, IARCustomer, IARCustomerOverride, IIneligibleItems, IARVendor } from '../../interfaces';
import { clearAllNewCustomers, clearAllNewVendors, formatNumber, getARCollateralRequest, getClientRequest, getLocalStorageItem, getOptions, getParamsByType, getPermissionsOfUser} from '../../utility/helper';
import Toaster from '../../components/toaster';
import { SelectedClientContext } from '../../context/selectedClientContext';
import { IComboBoxIds, IComboBoxSetStates, IOption } from '../../interfaces/comboBox';
import ComboBox from '../../components/common/combo-box';
import GeneralBreadcrumbs from '../../components/breadcrumb';
import { arCollateralAPI, arCustomersAPI, arVendorsAPI, borrowerAPI, ineligiblesAPI } from '../../service/api';
import LoadingBackdrop from '../../components/common/loading-backdrop';
import SearchParentChild from './search-parent-child';
import ParentViewClientSetting from './parent-view';
import ParentChildSetupProvider from '../../context/parentChildSetupContext';
import ParentList from './parent-list';
import ViewByCustomerVendorList from './relationship-table';
import CustomerSettingsProvider, { CustomerSettingsContext, ICustomerSettingsContext } from '../../context/customerSettingsContext';
import axiosInstance from '../../service/axiosInstance';
import { getLabelDisplayedRows, getLabelRowsPerPage } from '../../components/client-settings/tabs/tab-panel-contents/pagination';
import TabPanel from '../../components/common/tab-panel';
import MainTable from './main-table';
import EditVendorViewClientSetting from './edit-vendor-view';
import EditVendorCustomerSetupProvider from '../../context/EditVendorCustomerSetupProvider';
import ConfirmModal from '../../components/modals/confirm-modal';
import { archiveCustomers, archiveVendors, deleteCustomers, deleteVendors } from '../../api/customer-settings';
import { CloseOutlined, DeleteOutlined, HistoryOutlined } from '@mui/icons-material';
import WarningModal from '../../components/file-import/modals/warning-modal';

export interface ICustOverrideCollections {
  custInfo?     : IARCustomer;
  custSettings? : IARCustomerOverride;
}

export interface IParentCustomer extends IARCustomer {
  isExpanded: boolean,
  children?: IARCustomer[],
  vendors?: IARVendor[],
}

export interface IToasterProps {
  isToasterOpen     : boolean;
  setIsToasterOpen  : Dispatch<SetStateAction<boolean>>;
  toasterMessage    : string;
  setToasterMessage : Dispatch<SetStateAction<string>>;
  toasterSeverity   : AlertColor;
  setToasterSeverity: Dispatch<SetStateAction<AlertColor>>;
}

export interface ISortParams {
  sortBy: string;
  direction: string;
}

interface IParams {
  arCollateralId?: any,
  borrowerId?: any,
  pageNo?: any, 
  sortBy?: any,
  pageSize?: any,
  fieldName?: any,
  fieldValue?: any,
  vendorName?: any,
  vendorSrcId?: any,
  custName?: any,
  custSrcId?: any,
  parentCustName?: any,
  parentCustSrcId?: any,
  isNew?: any,
  archive?: any,
}

export interface IHeadCell {
  id      : string;
  name    : string;
  type    : string;
}

export interface ActionModalProps {
  recordIds: number[];              // individual delete/archive uses batch endpoint
  dataType: string;
  actionType: 'delete' | 'archive' | 'restore';
  title: string;
  description: string;
  open: boolean;
  yesButtonText: string;
  noButtonText: string;
  errorButton: boolean;
}

export interface WarningModalProps {
  deleteRecordIds: number[];
  archiveRecordIds: number[];
  dataType: 'customer' | 'vendor';
  description: string;
  open: boolean;
}

export interface IClearModalProps {
  isOpen: boolean;
  type: 'customer' | 'vendor' | 'all';
}

export interface ActionPanelProps {
  open: boolean;
  restore: boolean;
}

const defCustHeaders: IHeadCell[] = [
  { id: 'custName',             name: 'Customer Name',  type: 'string' },
  { id: 'custSrcId',            name: 'Customer ID',    type: 'string' },
  { id: 'createdAt',            name: 'Date Created',   type: 'date'   },
  { id: 'parentCustName',       name: 'Parent Name',    type: 'string' },
  { id: 'parentCustSrcId',      name: 'Parent ID',      type: 'string' },
];

const defUpcCustHeaders: IHeadCell[] = [
  { id: 'custName',             name: 'Customer Name',  type: 'string' },
  { id: 'custSrcId',            name: 'Customer ID',    type: 'string' },
  { id: 'createdAt',            name: 'Date Created',   type: 'date'   },
  { id: 'upcParentCustName',    name: 'Parent Name',    type: 'string' },
  { id: 'upcParentCustSrcId',   name: 'Parent ID',      type: 'string' },
];

const defVendorHeaders: IHeadCell[] = [
  { id: 'vendorName',           name: 'Vendor Name',    type: 'string' },
  { id: 'vendorSrcId',          name: 'Vendor ID',      type: 'string' },
  { id: 'createdAt',            name: 'Date Created',   type: 'date'   },
  { id: 'custName',             name: 'Customer Name',  type: 'string' },
  { id: 'custSrcId',            name: 'Customer ID',    type: 'string' },
];

export const currencyFormatting = {
  style       : 'currency',
  currency    : 'USD',
  currencySign: 'accounting'
}

/**
 * This function checks if a number is zero and returns an empty string or the formatted number.
 * @param number The number to check.
 * @param currencyCode The currency code.
 * @param exchangeRate The exchange rate.
 * @returns An empty string or the formatted number.
 */
export const isZero = (number: number, currencyCode?: string, exchangeRate?: number) => {
  if (number === 0) {
    return '';
  } else {
    return formatNumber({...currencyFormatting as Intl.NumberFormatOptions, currency: currencyCode ?? 'USD'}, number, exchangeRate);
  }
}

/**
 * This function formats a numeric string as currency.
 * @param numString The numeric string to format.
 * @param currencyCode The currency code.
 * @param exchangeRate The exchange rate.
 * @returns The formatted currency value.
 */
export const formatCurrency = (numString: number | string, currencyCode?: string, exchangeRate?: number) => {
  let number: number = 0;

  if (typeof numString === 'string') {
    number = parseFloat(numString);
  } else {
    number = numString;
  }

  return isZero(number, currencyCode, exchangeRate);
}

/**
 * This function is used to ensure that the value is not undefined.
 * 
 * @param param The string value.
 * @returns The string value, 0 if the param is undefined.
 */
const cleanParams = (param?: string) => {
  if (param && param !== 'undefined') return param;
  else return '0';
};

const customerFilters = ['All', 'New', 'Customer Name', 'Customer ID', 'Parent Name', 'Parent ID'];

const vendorFilters = ['All', 'New', 'Vendor Name', 'Vendor ID', 'Customer Name', 'Customer ID'];

/**
 * This Page displays all of the Customer Settings functionality
 */
export const CustomerSettingsPage: FC = () => {
  const navigate                                                            = useNavigate();
  const { arCollateralId, borrowerId }                                      = useParams();
  const [searchParams]                                                      = useSearchParams();
  const customerId                                                          = searchParams.get('customerId') ?? '0';
  const selectedClientContext                                               = useContext(SelectedClientContext);
  const {canViewCustomerList, setCanViewCustomerList,
         setCanViewParentInformation,
         setCanViewCustomerInformation,
         setCanUpdateCustomerInformation,
         setCanUpdateParentInformation,
         canViewParentList, setCanViewParentList,
         canEditParentChildRelationship, setCanEditParentChildRelationship,
         toaster, setToaster,
                                                                          } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;
  const [ , setARSettings]                                         = useState<IARSettings | null>(null);
  const [clients, setClients]                                               = useState<IOption[]>([]);
  const [selectedClient, setSelectedClient]                                 = useState<IOption | null>(null);
  const [clientInput, setClientInput]                                       = useState<string>('');
  const [arCollaterals, setARCollaterals]                                   = useState<IOption[]>([]);
  const [selectedARCollateral, setSelectedARCollateral]                     = useState<IOption | null>(null);
  const [arCollateralInput, setARCollateralInput]                           = useState<string>(''); 
  const [ineligibleItems, setIneligibleItems]                               = useState<IIneligibleItems[]>([]);
  const [page, setPage]                                                     = useState<number>(0);
  const [loadedPage, setLoadedPage]                                         = useState<number>(0);
  const [rowsPerPage, setRowsPerPage]                                       = useState<number>(10);
  const [totalElements, setTotalElements]                                   = useState<number>(0);
  const [isToasterOpen, setIsToasterOpen]                                   = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]                                 = useState<string>('Changes in Customer Settings have been saved.');
  const [toasterSeverity, setToasterSeverity]                               = useState<AlertColor>('success');
  const [isParentView, setIsParentView]                                     = useState<boolean>(false);
  const [isEditVendorView, setIsEditVendorView]                             = useState<boolean>(false);
  const [isLoading, setIsLoading]                                           = useState<boolean>(true);
  const [contraMappingData, setContraMappingData]                           = useState<Array<IARCustomer | IARVendor>>([]);
  const [custSortParams, setCustSortParams]                                 = useState<ISortParams>({sortBy: 'custName', direction: 'ASC'});
  const [vendorSortParams, setVendorSortParams]                             = useState<ISortParams>({sortBy: 'vendorName', direction: 'ASC'});
  const [searchKey, setSearchKey]                                           = useState<string>();
  const [filters, setFilters]                                               = useState<string[]>(customerFilters);
  const [selectedFilter, setSelectedFilter]                                 = useState<string>('All')
  const [tabIndex, setTabIndex]                                             = useState<number>(0);
  const [subTabIndex, setSubTabIndex]                                       = useState<number>(0);
  const [actionModal, setActionModal]                                       = useState<ActionModalProps>({ 
    recordIds: [],
    dataType: 'customer',
    actionType: 'delete',
    title: '', 
    description: '', 
    open: false, 
    yesButtonText: 'Proceed', 
    noButtonText: 'Cancel', 
    errorButton: false, 
  });
  const [warningModal, setWarningModal]                                     = useState<WarningModalProps>({
    deleteRecordIds: [],
    archiveRecordIds: [],
    dataType: 'customer',
    description: '', 
    open: false,
  });
  const [clearModal, setClearModal]                                         = useState<IClearModalProps>({ isOpen: false, type: 'all' });
  const [clearButtonClicked, setClearButtonClicked]                         = useState<boolean>(false);
  const [actionPanel, setActionPanel]                                       = useState<ActionPanelProps>({ open: false, restore: false, });
  const [selectAll, setSelectAll]                                           = useState<boolean>(false);
  const path                                                                = useLocation();

  const linksCustomerSettings = [
    { linkText: 'Clients', route: '/clients' },
    { linkText: 'Client Settings', route: `/clients/${borrowerId}/settings` },
  ]
 
  const linksEditParentChild = [
    { linkText: 'Clients', route: '/clients' },
    { linkText: 'Client Settings', route: `/clients/${borrowerId}/settings` },
    { linkText: 'Customer Settings', route: `/clients/${borrowerId}/settings/${selectedARCollateral?.recordId}/customers` },
  ]

  const params = { customerId,
                    borrowerId: cleanParams(borrowerId),
                    arCollateralId: cleanParams(arCollateralId),
                    invCollateralId: '0',
                    bbPeriodId: '0' };

  /**
   * This useEffect determine if the user should redirect to edit parent/child page.
   */
  useEffect(() => {
    if(path.pathname.includes('/customers/edit')){
      setIsParentView(true);
      setIsEditVendorView(false);
      return;
    }
    if(path.pathname.includes('/vendors/edit')){
      setIsParentView(false);
      setIsEditVendorView(true);
      return;
    }
    setIsParentView(false);
    setIsEditVendorView(false);
  }, [path])

  /**
   * This useEffect fetch the options for Client Name field and also trigger the function to get the Ineligible items once.
   */
  useEffect(() => {
    (async () => {
      await getOptions({ type: 'client', params, getSetStatesByType, setSelectedByType, mainRequest: getClientRequest()});
    })();
    getIneligibleItems();
  },[])

  /**
   * This hook sets the filters available for the page.
   */
  useEffect(() => {
    // If Tab is Vendor List - View By Vendor change filters
    if (tabIndex === 1 && subTabIndex === 0) {
      setFilters(vendorFilters);
      return;
    }
    setFilters(customerFilters);
  }, [tabIndex, subTabIndex]);
  
  /**
   * This function triggers when the selected client was changed, it sets the neccessary state variables and context for the selectedClient.
   */
  useEffect(() => {
    if(selectedClient?.parentClient){
      setSelectedARCollateral({
        recordId   : -1,
        label      : 'All', 
        borrowerId : selectedClient.recordId ?? 0,
        default    : true,
      })
    } else if (parseInt(params.borrowerId)) {
      (async () => {
        await getOptions({ type: 'arCollateral', params, getSetStatesByType, setSelectedByType, mainRequest: getARCollateralRequest(getParsedIds(params.borrowerId)), requestForDefault: true});
      })();
    }
  },[selectedClient])

  /**
   * This useEffect trigger a function to get the the selected collateral's AR Settings, also triggers when selected collateral was changed.
   */
  useEffect(() => {
    getARSettings();
  },[arCollateralId]);

  /**
   * This useEffect triggers the function that gets all the customer when pagination or tab changes.
   */
  useEffect(() => {
    // guard clause for when user exits edit view
    if (isEditVendorView || isParentView) return;

    setContraMappingData([]);
    setSelectAll(false);
    setActionPanel({
      ...actionPanel,
      open: false,
    });

    if(subTabIndex === 0 && selectedClient){
      if (tabIndex === 0 ) getAllCustomers();
      if (tabIndex === 1) getAllVendors();
    }
    if(subTabIndex === 2 && selectedClient){
      if (tabIndex === 0 ) getAllArchivedCustomers();
      if (tabIndex === 1) getAllArchivedVendors();
    }

  },[rowsPerPage, custSortParams, vendorSortParams, selectedFilter, searchKey, tabIndex, subTabIndex, selectedClient, isParentView, isEditVendorView]);

  useEffect(() => {
    // guard clause for when user exits edit view
    if (isEditVendorView || isParentView) return;

    const changePage = loadedPage != page;
    const data = contraMappingData;

    if(subTabIndex === 0 && selectedClient){
      if (tabIndex === 0 ) getAllCustomers({ next: changePage });
      if (tabIndex === 1) getAllVendors({ next: changePage });
    }
    if(subTabIndex === 2 && selectedClient){
      if (tabIndex === 0 ) getAllArchivedCustomers({ next: changePage });
      if (tabIndex === 1) getAllArchivedVendors({ next: changePage });
    }

  },[page]);
  /**
   * This useEffect resets the table and trigger the function that gets all customers when selected collateral was changed.
   */
  useEffect(() => {
    if(arCollaterals.length && subTabIndex !== 1 && (!isParentView || !isEditVendorView)){
      resetTable();
      if (tabIndex === 0 ) {
        if (subTabIndex === 0)
          getAllCustomers();
        else 
          getAllArchivedCustomers();
      };
      if (tabIndex === 1) {
        if (subTabIndex === 0)
          getAllVendors();
        else {
          getAllArchivedVendors();
        }
      }
    }
  },[arCollaterals, selectedARCollateral, isParentView, isEditVendorView])

  /**
   * This useEffect triggers once to get the permissions of the current user.
   */
  useEffect(() => {
    getPermissions()
  }, [])

  /**
   * This useEffect sets the loading state to false if the contraMappingData is not empty.
   */
  useEffect(() => {
    if(contraMappingData && !searchKey){
      setIsLoading(false)
    }
  },[contraMappingData])


  /**
   * This function gets and set the current user's permissions.
   */
  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(getLocalStorageItem('uid'));
    setCanViewCustomerList(permissions.includes(PERMISSIONS.VIEW_CUSTOMER_LIST));
    setCanViewCustomerInformation(permissions.includes(PERMISSIONS.VIEW_CUSTOMER_INFORMATION));
    setCanUpdateCustomerInformation(permissions.includes(PERMISSIONS.UPDATE_CUSTOMER_INFORMATION));
    setCanViewParentList(permissions.includes(PERMISSIONS.VIEW_PARENT_LIST));
    setCanViewParentInformation(permissions.includes(PERMISSIONS.VIEW_PARENT_INFORMATION));
    setCanUpdateParentInformation(permissions.includes(PERMISSIONS.UPDATE_PARENT_INFORMATION));
    setCanEditParentChildRelationship(permissions.includes(PERMISSIONS.EDIT_PARENT_CHILD_RELATIONSHIP));
  };

  /**
   * This useCallback is used to close the toaster component.
   */
  const handleToasterClose = useCallback(() => setIsToasterOpen(false), []);

  /**
   * This useCallback is used to close the toaster component controlled by CustomerSettingsContext.
   */
  const handleCloseToaster = useCallback(() => setToaster({ open: false, message: '', severity: 'success' }), []);

  /**
   * This useCallback is used to handle changes on pagination page.
   */
  const handleChangePage   = useCallback((_event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  }, []);

  /**
   * This useCallback is used to handle the changes on the default size of the pagination.
   */
  const handleChangeRowsPerPage = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  /**
   * This function parse a string to int if not null.
   * 
   * @param id The string to be parsed.
   * @returns return the parsed id, 0 if the id is undefined.
   */
  const getParsedIds = (id?: string) => {
      return parseInt(id ?? '0')
  };

  /**
   * This function dynamically handles the changes on the textfields.
   * 
   * @param type TextFied type.
   * @returns A list of props based on the given text field type.
   */
  const getSetStatesByType = (type: IComboBoxIds) => {
    if(type === 'client'){
      return { setOptions: setClients, setSelected: setSelectedClient, setInput: setClientInput };
    }else{
      return { setOptions: setARCollaterals, setSelected: setSelectedARCollateral, setInput: setARCollateralInput };
    }
  };

  /**
   * This function is used to dynamically set the state variables value of a specific text field.
   * 
   * @param selected The selected value of a comboboc (texfield with options)
   * @param type The combobox type
   * @param setStates The state variables related to the specified text field.
   */
  const setSelectedByType = (selected: IOption, type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    setStates.setSelected(selected);
    setStates.setInput(selected.label);

    if (type === 'client') {
      selectedClientContext?.setSelectedClient(selected);
    }
  };

  const getClearModalTitle = (type: string) => {
    if (type === 'customer') {
      return 'Clear All New Customers';
    }
    else if (type === 'vendor') {
      return 'Clear All New Vendor';
    }
    
    return 'Clear All New Accounts';
  }

  const getClearModalDescription = (type: string) => {
    if (type === 'customer') {
      return 'Are you sure you want to clear all new added customers?';
    }
    else if (type === 'vendor') {
      return 'Are you sure you want to clear all new added vendors?';
    }
    return '';
  }

  const getActionPanelText = (dataType: 'customer' | 'vendor') => {
    const itemsLength = contraMappingData
      .filter((data) => data.selected)
      .length;
    // count selected items
    const typeLabel = dataType === 'customer' ? 'Customer' : 'Vendor';

    return `${itemsLength} ${typeLabel}/s on this page are selected`;
  };

  const handleClearAllNewCustomers = async () => {
    try {
      await clearAllNewCustomers(selectedClient?.parentClient ? {
        borrowerId: selectedClient?.recordId,
      } : {
        arCollateralId: selectedARCollateral?.recordId,
      });

      setToaster({ open: true, message: 'Successfully cleared all customers!', severity: 'success' })
    } catch (error) {
      console.log('CLEAR ALL VENDORS ', error);
    }
    finally {
      // trigger fetch of vendors and customers in view by customer
      setClearButtonClicked(prev => !prev);
      resetPages();
    }
  }

  const handleClearAllNewVendors = async () => {
    try {
      await clearAllNewVendors(selectedClient?.parentClient ? {
        borrowerId: selectedClient?.recordId,
      } : {
        arCollateralId: selectedARCollateral?.recordId,
      });

      setToaster({ open: true, message: 'Successfully cleared all customers!', severity: 'success' })
    } catch (error) {
      console.log('CLEAR ALL VENDORS ', error);
    }
    finally {
      // trigger fetch of vendors and customers in view by customer
      setClearButtonClicked(prev => !prev);
      resetPages();
    }
  }

  const handleDeleteCustomers = async (arCustomerIds: number[]) => {
    try {
      await deleteCustomers(arCustomerIds);
      setToaster({
        open: true,
        message: `Successfully deleted ${arCustomerIds.length} customer/s`,
        severity: 'success',
      });
    } catch (error) {
      console.log('DELETE CUSTOMERS ', error);
    } finally {
      setActionPanel({ 
        ...actionPanel,
        open: false,
      });
      if (page !== 0) {
        resetPages();
        return;
      }
      getAllCustomers();
    }
  }

  const handleArchiveCustomers = async (arCustomerIds: number[], archive: boolean) => {
    try {
      await archiveCustomers(arCustomerIds, archive);
      setToaster({
        open: true,
        message: `Successfully ${!archive ? 'restored' : 'archived'} ${arCustomerIds.length} customer/s`,
        severity: 'success',
      });
    } catch (error) {
      console.log('DELETE CUSTOMERS ', error);
    } finally {
      setActionPanel({ 
        ...actionPanel,
        open: false,
      });
      if (page !== 0) {
        resetPages();
        return;
      }
      if (archive) {
        getAllCustomers();
      }
      else {
        getAllArchivedCustomers();
      }
    }
  }

  const handleDeleteVendors = async (arVendorIds: number[]) => {
    try {
      await deleteVendors(arVendorIds);
      setToaster({
        open: true,
        message: `Successfully deleted ${arVendorIds.length} vendor/s`,
        severity: 'success',
      });
    } catch (error) {
      console.log('DELETE VENDORS ', error);
    } finally {
      setActionPanel({ 
        ...actionPanel,
        open: false,
      });
      if (page !== 0) {
        resetPages();
        return;
      }
      getAllVendors();
    }
  }

  const handleArchiveVendors = async (arVendorIds: number[], archive: boolean) => {
    try {
      await archiveVendors(arVendorIds, archive);
      setToaster({
        open: true,
        message: `Successfully ${!archive ? 'restored' : 'archived'} ${arVendorIds.length} vendor/s`,
        severity: 'success',
      });
    } catch (error) {
      console.log('DELETE VENDORS ', error);
    } finally {
      setActionPanel({ 
        ...actionPanel,
        open: false,
      });
      if (page !== 0) {
        resetPages();
        return;
      }
      if (archive) {
        getAllVendors();
        return;
      }
      getAllArchivedVendors();
    }
  }

  const handleMultipleAction = (actionPanel: ActionPanelProps, dataType: 'customer' | 'vendor') => {
    const dataTypeLabel = dataType === 'customer' ? 'Customer' : 'Vendor';

    const actionData = contraMappingData.filter((data) => data.selected);

    // handle restore
    if (actionPanel.restore) {
      const restoreData = actionData
        .map((data) => (data.recordId!));

      setActionModal({
        recordIds: restoreData,
        dataType: dataType,
        actionType: 'restore',
        open: true,
        title: `Restore ${dataTypeLabel}s`,
        description: `You are about to restore ${dataType}s. Are you sure?`,
        yesButtonText: `Restore`,
        noButtonText: 'Cancel',
        errorButton: false,
      });
      return;
    }

    const deleteData = actionData
      .filter((data) => data.canDelete)
      .map((data) => (data.recordId!));

    const archiveData = actionData
      .filter((data) => !data.canDelete)
      .map((data) => (data.recordId!));

    if (deleteData.length > 0 && archiveData.length > 0) {
      setWarningModal({
        ...warningModal,
        deleteRecordIds: deleteData,
        archiveRecordIds: archiveData,
        dataType: dataType,
        open: true,
        description: `You are about to delete ${deleteData.length} ${dataType}s and archive the remaining ${archiveData.length} ${dataType}s since they are part of existing calculations. Are you sure you want to proceed?`
      });

      return;
    }

    // check if action is pure delete or archive
    const actionType = deleteData.length > 0 ? 'delete' : 'archive'
    const actionTypeLabel = actionType === 'delete' ? 'Delete' : 'Archive';

    setActionModal({
      recordIds: actionType === 'delete' ? deleteData : archiveData,
      dataType: dataType,
      actionType: actionType,
      open: true,
      title: `${actionTypeLabel} ${dataTypeLabel}s`,
      description: `You are about to ${actionType} ${dataType}s. Are you sure?`,
      yesButtonText: `${actionTypeLabel}`,
      noButtonText: 'Cancel',
      errorButton: actionType === 'delete',
    })
  }

  const handleDeleteAndArchiveCustomers = async (deleteCustomerIds: number[], archiveCustomerIds: number[]) => {
    try {
      await Promise.all([deleteCustomers(deleteCustomerIds), archiveCustomers(archiveCustomerIds, true)]);
      setToaster({
        open: true,
        message: `Successfully deleted ${deleteCustomerIds.length} customers and archived ${archiveCustomerIds.length} customers.`,
        severity: 'success',
      });
    } catch (error) {
      console.log('DELETE ARCHIVE CUSTOMERS ', error);
    } finally {
      setActionPanel({ 
        ...actionPanel,
        open: false,
      });
      if (page !== 0) {
        resetPages();
        return;
      }
      getAllCustomers();
    }
  }

  const handleDeleteAndArchiveVendors = async (deleteVendorIds: number[], archiveVendorIds: number[]) => {
    try {
      await Promise.all([deleteVendors(deleteVendorIds), archiveVendors(archiveVendorIds, true)]);
      setToaster({
        open: true,
        message: `Successfully deleted ${deleteVendorIds.length} vendors and archived ${archiveVendorIds.length} vendors.`,
        severity: 'success',
      });
    } catch (error) {
      console.log('DELETE ARCHIVE VENDORS ', error);
    } finally {
      setActionPanel({ 
        ...actionPanel,
        open: false,
      });
      if (page !== 0) {
        resetPages();
        return;
      }
      getAllVendors();
    }
  }

  /**
   * This function is used to get the ineligible items base on the selectedClient.
   */
  const getIneligibleItems = async () => {
    try {
      const ineligibleItemsRes = await axiosInstance.request({
        url: ineligiblesAPI.FIND_CODE_BY_BORROWER_ID,
        method: GET,
        params: {borrowerId: 35, sortBy: 'sortOrder, ASC', pageSize: 99999},
      })
      const ineligibleItemsResData = ineligibleItemsRes.data.content;

      if (ineligibleItemsResData.length > 0) {
        setIneligibleItems({ ...ineligibleItemsResData, ineligibleCode: ineligibleItemsResData.code });
      }
    } catch (error) {
      console.log('INELIGIBLE ITEMS', error);
    }
  }

  /**
   * This function is used to get the data of the selected collateral.
   */
  const getARSettings = async () => {
    try {
      const arSettingsRes = await axiosInstance.request({
        url: arCollateralAPI.FIND_AR_SETTINGS_BY_AR_COLLATERAL_ID,
        method: GET,
        params: {arCollateralId: parseInt(params.arCollateralId), pageNo: 0, pageSize: 99999, sortBy: 'recordId,DESC', isCurrent: true},
      })
      const arSettingsResData = arSettingsRes.data.content;
      setARSettings(arSettingsResData[0]);
    } catch (error) {
      console.log('ARSettings', error);
    }
  }

  const getCustomerParams = (options?: { archive?: boolean, all?: boolean }) => {
    // return params for either search customer or get customer by collateral Id
    const parameter: IParams = {
      borrowerId: selectedClient?.parentClient ? selectedClient?.recordId : undefined,
      arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
      custName: selectedFilter === 'Customer Name' || selectedFilter === 'New' || selectedFilter === 'All' ? searchKey : undefined,
      custSrcId: selectedFilter === 'Customer ID' ? searchKey : undefined,
      parentCustName: selectedFilter === 'Parent Name' ? searchKey : undefined,
      parentCustSrcId: selectedFilter === 'Parent ID' ? searchKey : undefined,
      isNew: selectedFilter === 'New' ? true : undefined,
      pageNo: page, 
      sortBy: `${custSortParams.sortBy},${custSortParams.direction}`,
    };

    if(rowsPerPage !== -1){
      parameter.pageSize = rowsPerPage
    }

    if (options?.archive) {
      parameter.archive = options.archive;
    }

    if (options?.all) {
      parameter.pageSize = 9999999;
      parameter.pageNo = 0;
    }
    return parameter;
  }

  /**
   * This function is used to get all of the customer under the selected collateral, it also has a dependency on the pagination.
   */
  const getAllCustomers = async (options?: {next?: boolean}) => {
    setIsLoading(true);
    try {

      const allCustRes = await axiosInstance.request({
        url: arCustomersAPI.FIND_BY_CRITERIA,
        method: GET,
        params: getCustomerParams()
      });

      const customerList: IARCustomer[] = allCustRes.data.content.map((o:any) => ({
        recordId             : o.recordId,
        borrowerId           : o.borrowerId,
        arCollateralId       : o.arCollateralId,
        parentARCustomerId   : o.parentARCustomerId,
        custSrcId            : o.custSrcId,
        custName             : o.custName,
        custAddress1         : o.custAddress1,
        custAddress2         : o.custAddress2,
        custCountry          : o.custCountry,
        custCity             : o.custCity,
        custState            : o.custState,
        custPostalCode       : o.custPostalCode,
        custPhone            : o.custPhone,
        creditLimit          : o.creditLimit,
        asOfDate             : o.asOfDate,
        custDescription      : o.custDescription,
        parentCustSrcId      : o.parentCustSrcId,
        parentCustName       : o.parentCustName,
        concentrationPct     : o.concentrationPct,
        crossAgingPct        : o.crossAgingPct,
        dbRating             : o.dbRating,
        isPrimary            : o.primaryDebtor,
        isUpcParent          : o.isUpcParent,
        isCustParent         : o.isCustParent,
        createdAt            : o.createdAt,
        upcParentCustSrcId   : o.upcParentCustSrcId,
        upcParentCustName    : o.upcParentCustName,
        upcParentCustId      : o.upcParentCustId,
        attributesJson       : o.attributesJson,
        isNew                : o.isNew,
        canDelete            : o.canDelete,
        selected             : false,
      }))
      
      if (options?.next) {
        if (loadedPage < allCustRes.data.number) {
          setContraMappingData((prevState) => ([
            ...prevState,
            ...customerList,
          ]));
          setLoadedPage((prevState) => (allCustRes.data.number > prevState ? allCustRes.data.number : prevState));
        }
      }
      else {
        setContraMappingData(customerList);
        setLoadedPage(0);
      }
      setTotalElements(allCustRes.data.totalElements)
    } catch (error) {
      console.log('ALL CUSTOMERS', error);
    } finally{
      setIsLoading(false)
    }
  };

  const fetchAllCustomersPage = async () => {
    setIsLoading(true);
    try {
      const allCustRes = await axiosInstance.request({
        url: arCustomersAPI.FIND_BY_CRITERIA,
        method: GET,
        params: getCustomerParams({ all: true }),
      });

      const customerList: IARCustomer[] = allCustRes.data.content.map((o:any) => ({
        recordId             : o.recordId,
        borrowerId           : o.borrowerId,
        arCollateralId       : o.arCollateralId,
        parentARCustomerId   : o.parentARCustomerId,
        custSrcId            : o.custSrcId,
        custName             : o.custName,
        custAddress1         : o.custAddress1,
        custAddress2         : o.custAddress2,
        custCountry          : o.custCountry,
        custCity             : o.custCity,
        custState            : o.custState,
        custPostalCode       : o.custPostalCode,
        custPhone            : o.custPhone,
        creditLimit          : o.creditLimit,
        asOfDate             : o.asOfDate,
        custDescription      : o.custDescription,
        parentCustSrcId      : o.parentCustSrcId,
        parentCustName       : o.parentCustName,
        concentrationPct     : o.concentrationPct,
        crossAgingPct        : o.crossAgingPct,
        dbRating             : o.dbRating,
        isPrimary            : o.primaryDebtor,
        isUpcParent          : o.isUpcParent,
        isCustParent         : o.isCustParent,
        createdAt            : o.createdAt,
        upcParentCustSrcId   : o.upcParentCustSrcId,
        upcParentCustName    : o.upcParentCustName,
        upcParentCustId      : o.upcParentCustId,
        attributesJson       : o.attributesJson,
        isNew                : o.isNew,
        canDelete            : o.canDelete,
        selected             : true,
      }));

      setContraMappingData(customerList);
      setLoadedPage(Number.MAX_SAFE_INTEGER);
      setActionPanel(({
        ...actionPanel,
        open: true,
      }));
      setSelectAll(true);

    } catch (error) {
      console.log('ALL CUSTOMERS', error);
    } finally {
      setIsLoading(false);
    }
  }

  const getAllArchivedCustomers = async (options?: {next?: boolean}) => {
    setIsLoading(true);
    try {

      const allCustRes = await axiosInstance.request({
        url: arCustomersAPI.FIND_BY_CRITERIA,
        method: GET,
        params: getCustomerParams({ archive: true }),
      });

      const customerList: IARCustomer[] = allCustRes.data.content.map((o:any) => ({
        recordId             : o.recordId,
        borrowerId           : o.borrowerId,
        arCollateralId       : o.arCollateralId,
        parentARCustomerId   : o.parentARCustomerId,
        custSrcId            : o.custSrcId,
        custName             : o.custName,
        custAddress1         : o.custAddress1,
        custAddress2         : o.custAddress2,
        custCountry          : o.custCountry,
        custCity             : o.custCity,
        custState            : o.custState,
        custPostalCode       : o.custPostalCode,
        custPhone            : o.custPhone,
        creditLimit          : o.creditLimit,
        asOfDate             : o.asOfDate,
        custDescription      : o.custDescription,
        parentCustSrcId      : o.parentCustSrcId,
        parentCustName       : o.parentCustName,
        concentrationPct     : o.concentrationPct,
        crossAgingPct        : o.crossAgingPct,
        dbRating             : o.dbRating,
        isPrimary            : o.primaryDebtor,
        isUpcParent          : o.isUpcParent,
        isCustParent         : o.isCustParent,
        createdAt            : o.createdAt,
        upcParentCustSrcId   : o.upcParentCustSrcId,
        upcParentCustName    : o.upcParentCustName,
        upcParentCustId      : o.upcParentCustId,
        attributesJson       : o.attributesJson,
        isNew                : o.isNew,
        canDelete            : o.canDelete,
        selected             : false,
      }))
      
      if (options?.next) {
        if (loadedPage < allCustRes.data.number) {
          setContraMappingData((prevState) => ([
            ...prevState,
            ...customerList,
          ]));
          setLoadedPage((prevState) => (allCustRes.data.number > prevState ? allCustRes.data.number : prevState));
        }
      }
      else {
        setContraMappingData(customerList);
        setLoadedPage(0);
      }
      setTotalElements(allCustRes.data.totalElements);
    } catch (error) {
      console.log('ARCHIVED CUSTOMERS', error);
    } finally{
      setIsLoading(false)
    }
  };

  const fetchAllArchivedCustomersPage = async () => {
    setIsLoading(true);
    try {
      const allCustRes = await axiosInstance.request({
        url: arCustomersAPI.FIND_BY_CRITERIA,
        method: GET,
        params: getCustomerParams({ archive: true, all: true }),
      });

      const customerList: IARCustomer[] = allCustRes.data.content.map((o:any) => ({
        recordId             : o.recordId,
        borrowerId           : o.borrowerId,
        arCollateralId       : o.arCollateralId,
        parentARCustomerId   : o.parentARCustomerId,
        custSrcId            : o.custSrcId,
        custName             : o.custName,
        custAddress1         : o.custAddress1,
        custAddress2         : o.custAddress2,
        custCountry          : o.custCountry,
        custCity             : o.custCity,
        custState            : o.custState,
        custPostalCode       : o.custPostalCode,
        custPhone            : o.custPhone,
        creditLimit          : o.creditLimit,
        asOfDate             : o.asOfDate,
        custDescription      : o.custDescription,
        parentCustSrcId      : o.parentCustSrcId,
        parentCustName       : o.parentCustName,
        concentrationPct     : o.concentrationPct,
        crossAgingPct        : o.crossAgingPct,
        dbRating             : o.dbRating,
        isPrimary            : o.primaryDebtor,
        isUpcParent          : o.isUpcParent,
        isCustParent         : o.isCustParent,
        createdAt            : o.createdAt,
        upcParentCustSrcId   : o.upcParentCustSrcId,
        upcParentCustName    : o.upcParentCustName,
        upcParentCustId      : o.upcParentCustId,
        attributesJson       : o.attributesJson,
        isNew                : o.isNew,
        canDelete            : o.canDelete,
        selected             : true,
      }));

      setContraMappingData(customerList);
      setLoadedPage(Number.MAX_SAFE_INTEGER);
      setActionPanel(({
        restore: true,
        open: true,
      }));
      setSelectAll(true);

    } catch (error) {
      console.log('ALL CUSTOMERS', error);
    } finally {
      setIsLoading(false);
    }
  }

    const getVendorsParams = (options?: { archive?: boolean, all?: boolean }) => {
      const parameter: IParams = {
        borrowerId: selectedClient?.parentClient ? selectedClient?.recordId : undefined,
        arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
        vendorName: selectedFilter === 'Vendor Name' || selectedFilter === 'New' || selectedFilter === 'All' ? searchKey : undefined,
        vendorSrcId: selectedFilter === 'Vendor ID' ? searchKey : undefined,
        custName: selectedFilter === 'Customer Name' ? searchKey : undefined,
        custSrcId: selectedFilter === 'Customer ID' ? searchKey : undefined,
        isNew: selectedFilter === 'New' ? true : undefined,
        pageNo: page, 
        sortBy: `${vendorSortParams.sortBy},${vendorSortParams.direction}`,
      };

      if(rowsPerPage !== -1){
        parameter.pageSize = rowsPerPage
      }

      if(['custName', 'custSrcId'].includes(vendorSortParams.sortBy)) {
        parameter.sortBy = `arCustomer.${vendorSortParams.sortBy},${vendorSortParams.direction}`
      }

      if (options?.archive) {
        parameter.archive = options.archive;
      }
      if (options?.all) {
        parameter.pageSize = 9999999;
        parameter.pageNo = 0;
      }

      return parameter;
    }

    /**
   * This function is used to get all of the customer under the selected collateral, it also has a dependency on the pagination.
   */
    const getAllVendors = async (options?: { next?: boolean }) => {
      setIsLoading(true);
      try {
        const allCustRes = await axiosInstance.request({
          url: selectedClient?.parentClient ? arCustomersAPI.FIND_BY_BORROWER_ID : arCustomersAPI.FIND_BY_AR_COLLATERAL_ID,
          method: GET,
          params: selectedClient?.parentClient ? {borrowerId: selectedClient?.recordId} : {arCollateralId: selectedARCollateral?.recordId}
        });

        const allVendorRes = await axiosInstance.request({
          url: arVendorsAPI.FIND_BY_CRITERIA,
          method: GET,
          params: {
            ...getVendorsParams(),
          }
        });
        
        // Find Customer associated with Vendor
        const allCustList: IARCustomer[] = allCustRes.data.content;
        const vendorList: IARVendor[] = allVendorRes.data.content.map((vendor) => {
          const vendorCustomer = allCustList.find(cust => cust.recordId === vendor.arCustomerId);
          return {
            ...vendor,
            custName: vendorCustomer?.custName,
            custSrcId: vendorCustomer?.custSrcId,
            selected: false,
          };
        });
        if (options?.next) {
          if (loadedPage < allVendorRes.data.number) {
            setContraMappingData((prevState) => ([
              ...prevState,
              ...vendorList,
            ]));
            setLoadedPage((prevState) => (allVendorRes.data.number > prevState ? allVendorRes.data.number : prevState));
          }
        }
        else {
          setContraMappingData(vendorList);
          setLoadedPage(0);
        }
        setTotalElements(allVendorRes.data.totalElements);
      } catch (error) {
        console.log('ALL VENDORS', error);
      } finally{
        setIsLoading(false)
      }
    };

    const fetchAllVendorsPage = async () => {
      setIsLoading(true);
      try {
        const allCustRes = await axiosInstance.request({
          url: arCustomersAPI.FIND_BY_AR_COLLATERAL_ID,
          method: GET,
          params: {arCollateralId: selectedARCollateral?.recordId}
        });

        const allVendorRes = await axiosInstance.request({
          url: arVendorsAPI.FIND_BY_CRITERIA,
          method: GET,
          params: {
            ...getVendorsParams({ all: true, }),
          }
        });

        // Find Customer associated with Vendor
        const allCustList: IARCustomer[] = allCustRes.data.content;
        const vendorList: IARVendor[] = allVendorRes.data.content.map((vendor) => {
          const vendorCustomer = allCustList.find(cust => cust.recordId === vendor.arCustomerId);
          return {
            ...vendor,
            custName: vendorCustomer?.custName,
            custSrcId: vendorCustomer?.custSrcId,
            selected: true,
          };
        });

        setContraMappingData(vendorList);
        setLoadedPage(Number.MAX_SAFE_INTEGER);
        setActionPanel(({
          ...actionPanel,
          open: true,
        }));
        setSelectAll(true);
      } catch (error) {
        console.log('ALL VENDORS', error);
      } finally{
        setIsLoading(false)
      }
    }

    const getAllArchivedVendors = async (options?: { next?: boolean }) => {
      setIsLoading(true);
      try {
        const allCustRes = await axiosInstance.request({
          url: selectedClient?.parentClient ? arCustomersAPI.FIND_BY_BORROWER_ID : arCustomersAPI.FIND_BY_AR_COLLATERAL_ID,
          method: GET,
          params: selectedClient?.parentClient ? {borrowerId: selectedClient?.recordId} : {arCollateralId: selectedARCollateral?.recordId}
        });

        const allVendorRes = await axiosInstance.request({
          url: arVendorsAPI.FIND_BY_CRITERIA,
          method: GET,
          params: {
            ...getVendorsParams({ archive: true }),
          }
        });

        // Find Customer associated with Vendor
        const allCustList: IARCustomer[] = allCustRes.data.content;
        const vendorList: IARVendor[] = allVendorRes.data.content.map((vendor) => {
          const vendorCustomer = allCustList.find(cust => cust.recordId === vendor.arCustomerId);
          return {
            ...vendor,
            custName: vendorCustomer?.custName,
            custSrcId: vendorCustomer?.custSrcId,
            selected: false,
          };
        });
        if (options?.next) {
          if (loadedPage < allVendorRes.data.number) {
            setContraMappingData((prevState) => ([
              ...prevState,
              ...vendorList,
            ]));
            setLoadedPage((prevState) => (allVendorRes.data.number > prevState ? allVendorRes.data.number : prevState));
          }
        }
        else {
          setContraMappingData(vendorList);
          setLoadedPage(0);
        }
      } catch (error) {
        console.log('ARCHIVED VENDORS', error);
      } finally{
        setIsLoading(false)
      }
    };

    const fetchAllArchivedVendorsPage = async () => {
      setIsLoading(true);
      try {
        const allCustRes = await axiosInstance.request({
          url: arCustomersAPI.FIND_BY_AR_COLLATERAL_ID,
          method: GET,
          params: {arCollateralId: selectedARCollateral?.recordId}
        });

        const allVendorRes = await axiosInstance.request({
          url: arVendorsAPI.FIND_BY_CRITERIA,
          method: GET,
          params: {
            ...getVendorsParams({ archive: true, all: true, }),
          }
        });

        // Find Customer associated with Vendor
        const allCustList: IARCustomer[] = allCustRes.data.content;
        const vendorList: IARVendor[] = allVendorRes.data.content.map((vendor) => {
          const vendorCustomer = allCustList.find(cust => cust.recordId === vendor.arCustomerId);
          return {
            ...vendor,
            custName: vendorCustomer?.custName,
            custSrcId: vendorCustomer?.custSrcId,
            selected: true,
          };
        });

        setContraMappingData(vendorList);
        setLoadedPage(Number.MAX_SAFE_INTEGER);
        setActionPanel(({
          restore: true,
          open: true,
        }));
        setSelectAll(true);
      } catch (error) {
        console.log('ALL VENDORS', error);
      } finally{
        setIsLoading(false)
      }
    }
  
  /**
   * This function proccessed the value of a SyntheticEvent:change and set that value to a text field dynamically.
   * 
   * @param _event SyntheticEvent: onChange event.
   * @param newValue The selected option on change.
   * @param type Combobox Id.
   * @param setSelected The state variable function that sets the value.
   */
  const onChange = (_event: SyntheticEvent<Element, Event>, newValue: IOption | null, type: IComboBoxIds, setSelected: Dispatch<SetStateAction<IOption | null>>) => {
    if (newValue?.recordId !== getParsedIds(getParamsByType(type, params).params)) {
      if(type === 'client'){
        if(newValue?.parentClientFk){
          navigate(`/clients/${newValue?.recordId}/settings`);
          selectedClientContext?.setSelectedClient(newValue);
          return
        }
        navigate(`/clients/${newValue?.recordId}/settings/0/customers`);
        selectedClientContext?.setSelectedClient(newValue);
      } else {
        navigate(`/clients/${cleanParams(borrowerId)}/settings/${newValue?.recordId}/customers?customerId=0`);
      }
      setSelected(newValue);
      resetTable();
    }
  };

  /**
   * This function proccessed the value of a SyntheticEvent:inputChange and set that value to a text field dynamically.
   * 
   * @param _event SyntheticEvent: onInputChange: event.
   * @param newInputValue The string value input.
   * @param type Comboboc id.
   * @param setInput The state variable function that sets the value.
   */
  const onInputChange = (_event: SyntheticEvent<Element, Event>, newInputValue: string, type: IComboBoxIds, setInput: Dispatch<SetStateAction<string>>) => {
    setInput(newInputValue);
  };

  /**
   * This function set the loading state to true and reset the table for the AR Customers.
   */
  const resetTable = () => {
    setIsLoading(true);
    setContraMappingData([]);
    if (tabIndex === 0 ) getAllCustomers();
    if (tabIndex === 1) getAllVendors();
  };

  const resetPages = () => {
    setLoadedPage(0);
    setPage(0);
  }

  const handleUpdateData = () => {
    if (page !== 0) {
      resetPages();
      return;
    }
    resetTable();
  }

  /**
   * This function returns a textfield props for the specified text field
   * 
   * @param type ComboboxId
   * @returns A text field props
   */
  const getHandleChangeByType = (type: IComboBoxIds) => {
    return {
      onChange: (e: SyntheticEvent<Element, Event>, newValue: IOption | null) => onChange(e, newValue, type, getSetStatesByType(type).setSelected),
      onInputChange: (e: SyntheticEvent<Element, Event>, newInputValue: string) => onInputChange(e, newInputValue, type, getSetStatesByType(type).setInput)
    }
  };

  const renderBreadcrumbs = () => {
    if (isParentView) {
      return (
        <GeneralBreadcrumbs
          selectedText= {'Edit Parent Child Relationship'}
          breadcrumbLinks={linksEditParentChild}
        />
      );
    }
    if (isEditVendorView) {
      return (
        <GeneralBreadcrumbs
          selectedText= {'Edit Vendor and Customer Relationship'}
          breadcrumbLinks={linksEditParentChild}
        />
      );
    }
    return (
      <GeneralBreadcrumbs
        selectedText= 'Customer Settings'
        breadcrumbLinks={linksCustomerSettings}
      />
    );
  };

  const renderEditButtonForSubPageNavigation = () => {
    const isEditParentChild = tabIndex === 0;
    return (
      <Button
        onClick={() => {
          setSearchKey('')
          setSelectedFilter('All')
          isEditParentChild
            ? navigate(`/clients/${borrowerId}/settings/${selectedARCollateral?.recordId}/customers/edit`)
            : navigate(`/clients/${borrowerId}/settings/${selectedARCollateral?.recordId}/vendors/edit`);
        }}
        variant='contained'
        sx={{...styles.title, ...styles.buttonText, ...(!canEditParentChildRelationship && styles.invisible)}}
        disabled={!selectedARCollateral?.recordId}
        >
          { isEditParentChild ? 'Edit Parent/Child' : 'Edit Vendor/Customer' }
      </Button>
    );
  };

  const renderMainViewContent = () => {
    return (
      <>
        <Box sx={styles.navBox}>
          { renderEditButtonForSubPageNavigation() }
          { (!isParentView && !isEditVendorView) &&
            <SearchParentChild
              width={'40%'}
              filters={filters}
              onChange={(value) => {
                setSearchKey(value)
                setPage(0)
              }}
              onSelect={(selected) => {
                setSelectedFilter(selected)
                setPage(0)
              }}
            />
          }
        </Box>
        <Box sx={styles.tabBox}>
          <Box>
            <Tabs
              sx={styles.buttonTabs}
              value={tabIndex}
              onChange={(_e, value) => {
                setTabIndex(value)
                setPage(0)
              }}
            >
              <Tab
                tabIndex={0}
                label='Customer List'
                id='0'
                aria-controls='customer-list-view'
              />
              <Tab
                tabIndex={0}
                label='Vendor List'
                id='1'
                aria-controls='vendor-list-view'
              />
            </Tabs>
          </Box>
          <TabPanel selectedTabIndex={tabIndex} index={0}>
            { actionPanel.open ? (
              <Box sx={styles.boxActionPanel}>
                <Typography sx={{ marginLeft: '24px' }}>
                  { getActionPanelText('customer') }
                </Typography>
                <Box>
                  <Button 
                    data-testid={`delete-archive-multiple-button`}
                    startIcon={subTabIndex === 0 ? <DeleteOutlined /> : <HistoryOutlined />}
                    onClick={() => {
                      handleMultipleAction(actionPanel, 'customer');
                    }}
                  >
                      { subTabIndex === 0 ? 'Delete' : 'Restore'}
                  </Button>
                  <IconButton onClick={() => {
                    setSelectAll(false);
                    setActionPanel({
                      ...actionPanel,
                      open: false,
                    });
                    setContraMappingData((prevState) => prevState.map((data) => ({
                      ...data,
                      selected: false,
                    })));
                  }}>
                    <CloseOutlined />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box sx={styles.boxSubTabs}>
                <Tabs
                  sx={styles.buttonSubTabs}
                  value={subTabIndex}
                  onChange={(_e, value) => {
                    setSubTabIndex(value)
                    setPage(0)
                  }}
                >
                  <Tab
                    tabIndex={0}
                    label='View By Customer'
                    id='0'
                    aria-controls='customer-list-view'
                  />
                  <Tab
                    tabIndex={0}
                    label='View By Parent'
                    id='1'
                    aria-controls='vendor-list-view'
                  />
                  <Tab
                    tabIndex={0}
                    label='Archived Customer'
                    id='2'
                    aria-controls='archive-customer-list-view'
                  />
                </Tabs>
                <Link 
                  component={'button'} 
                  sx={styles.clearButton}
                  onClick={() => {
                    setClearModal(prevState => ({
                      ...prevState,
                      isOpen: true,
                      type: 'customer',
                    }));
                  }}
                >
                  Clear All New Customers
                </Link>
              </Box>
            )}
            <TabPanel selectedTabIndex={subTabIndex} index={0}>
              {
                canViewCustomerList ? (
                  <MainTable
                    type={'customer'}
                    archive={false}
                    selectedARCollateral={selectedARCollateral}
                    pageNo={page}
                    dataList={contraMappingData}
                    setDataList={setContraMappingData}
                    isToasterOpen={isToasterOpen}
                    setIsToasterOpen={setIsToasterOpen}
                    toasterMessage={toasterMessage}
                    setToasterMessage={setToasterMessage}
                    toasterSeverity={toasterSeverity}
                    setToasterSeverity={setToasterSeverity}
                    sortParams={custSortParams}
                    setSortParams={setCustSortParams}
                    fetchCustomers={handleUpdateData}
                    handleSelectAll={fetchAllCustomersPage} 
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    headers={selectedClient?.parentClient ? defUpcCustHeaders : defCustHeaders}
                    setActionModal={setActionModal}
                    actionPanel={actionPanel}
                    setActionPanel={setActionPanel}
                  /> 
                ) : (
                  <Box tabIndex={0} sx={styles.noPermissionBox}>
                    You do not have the permissions to view this tab.
                  </Box>
                )
              }
            </TabPanel>
            <TabPanel selectedTabIndex={subTabIndex} index={1}>
              {
                canViewParentList ? (
                  <ParentList
                    selectedARCollateralId={selectedARCollateral?.recordId ?? 0}
                    isParentView={isParentView}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    arCollateralId={selectedARCollateral?.recordId ?? parseInt(params.arCollateralId)}
                    selectedClient={selectedClient}
                    arCollaterals={arCollaterals}
                    totalElements={totalElements}
                    setTotalElements={setTotalElements} 
                    searchKey={searchKey} 
                    setSearchKey={setSearchKey} 
                    filter={selectedFilter} 
                    setFilter={setSelectedFilter} 
                    tabIndex={subTabIndex}
                    clearButtonClicked={clearButtonClicked}
                  />
                ) : (
                  <Box tabIndex={0} sx={styles.noPermissionBox}>
                    You do not have the permissions to view this tab.
                  </Box>
                )
              }
            </TabPanel>
            <TabPanel selectedTabIndex={subTabIndex} index={2}>
              {
                canViewCustomerList ? (
                  <MainTable
                    type={'customer'}
                    archive={true}
                    selectedARCollateral={selectedARCollateral}
                    pageNo={page}
                    dataList={contraMappingData}
                    setDataList={setContraMappingData}
                    isToasterOpen={isToasterOpen}
                    setIsToasterOpen={setIsToasterOpen}
                    toasterMessage={toasterMessage}
                    setToasterMessage={setToasterMessage}
                    toasterSeverity={toasterSeverity}
                    setToasterSeverity={setToasterSeverity}
                    sortParams={custSortParams}
                    setSortParams={setCustSortParams}
                    fetchCustomers={handleUpdateData}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    handleSelectAll={fetchAllArchivedCustomersPage} 
                    headers={selectedClient?.parentClient ? defUpcCustHeaders : defCustHeaders}
                    setActionModal={setActionModal}
                    actionPanel={actionPanel}
                    setActionPanel={setActionPanel}
                  /> 
                ) : (
                  <Box tabIndex={0} sx={styles.noPermissionBox}>
                    You do not have the permissions to view this tab.
                  </Box>
                )
              }
            </TabPanel>
          </TabPanel>
          <TabPanel selectedTabIndex={tabIndex} index={1}>
            { actionPanel.open ? (
              <Box sx={styles.boxActionPanel}>
                <Typography sx={{ marginLeft: '24px' }}>
                  { getActionPanelText('vendor') }
                </Typography>
                <Box>
                  <Button 
                    data-testid={`delete-archive-multiple-button`}
                    startIcon={subTabIndex === 0 ? <DeleteOutlined /> : <HistoryOutlined />}
                    onClick={() => {
                      handleMultipleAction(actionPanel, 'vendor');
                    }}
                  >
                      { subTabIndex === 0 ? 'Delete' : 'Restore'}
                  </Button>
                  <IconButton onClick={() => {
                    setSelectAll(false);
                    setActionPanel({
                      ...actionPanel,
                      open: false,
                    });
                  }}>
                    <CloseOutlined />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box sx={styles.boxSubTabs}>
                <Tabs
                  sx={styles.buttonSubTabs}
                  value={subTabIndex}
                  onChange={(_e, value) => {
                    setSubTabIndex(value)
                    setPage(0)
                  }}
                >
                  <Tab
                    tabIndex={0}
                    label='View By Vendor'
                    id='0'
                    aria-controls='vendor-list-view'
                  />
                  <Tab
                    tabIndex={0}
                    label='View By Customer'
                    id='1'
                    aria-controls='vendor-by-customer-list-view'
                  />
                  <Tab
                    tabIndex={0}
                    label='Archived Vendor'
                    id='2'
                    aria-controls='vendor-list-view'
                  />
                </Tabs>
                <Link 
                  component={'button'} 
                  sx={styles.clearButton}
                  onClick={() => {
                    setClearModal(prevState => ({
                      ...prevState,
                      isOpen: true,
                      type: 'vendor',
                    }));
                  }}
                >
                  Clear All New Vendors
                </Link>
              </Box>
            )}
            <TabPanel selectedTabIndex={subTabIndex} index={0}>
              {
                canViewCustomerList ? (
                  <MainTable
                    type={'vendor'}
                    archive={false}
                    selectedARCollateral={selectedARCollateral}
                    pageNo={page}
                    dataList={contraMappingData}
                    setDataList={setContraMappingData}
                    isToasterOpen={isToasterOpen}
                    setIsToasterOpen={setIsToasterOpen}
                    toasterMessage={toasterMessage}
                    setToasterMessage={setToasterMessage}
                    toasterSeverity={toasterSeverity}
                    setToasterSeverity={setToasterSeverity}
                    sortParams={vendorSortParams}
                    setSortParams={setVendorSortParams}
                    fetchCustomers={handleUpdateData}
                    handleSelectAll={fetchAllVendorsPage} 
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    headers={defVendorHeaders}
                    setActionModal={setActionModal}
                    actionPanel={actionPanel}
                    setActionPanel={setActionPanel}
                  /> 
                ) : (
                  <Box tabIndex={0} sx={styles.noPermissionBox}>
                    You do not have the permissions to view this tab.
                  </Box>
                )
              }
            </TabPanel>
            <TabPanel selectedTabIndex={subTabIndex} index={1}>
              {
                canViewParentList ? (
                  <ViewByCustomerVendorList
                    selectedARCollateralId={selectedARCollateral?.recordId ?? 0}
                    isParentView={isParentView}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    arCollateralId={selectedARCollateral?.recordId ?? parseInt(params.arCollateralId)}
                    selectedClient={selectedClient}
                    arCollaterals={arCollaterals}
                    totalElements={totalElements}
                    setTotalElements={setTotalElements} 
                    searchKey={searchKey} 
                    setSearchKey={setSearchKey} 
                    filter={selectedFilter} 
                    setFilter={setSelectedFilter} 
                    tabIndex={subTabIndex}
                    clearButtonClicked={clearButtonClicked}
                  />
                ) : (
                  <Box tabIndex={0} sx={styles.noPermissionBox}>
                    You do not have the permissions to view this tab.
                  </Box>
                )
              }
            </TabPanel>
            <TabPanel selectedTabIndex={subTabIndex} index={2}>
              {
                canViewCustomerList ? (
                  <MainTable
                    type={'vendor'}
                    archive={true}
                    selectedARCollateral={selectedARCollateral}
                    pageNo={page}
                    dataList={contraMappingData}
                    setDataList={setContraMappingData}
                    isToasterOpen={isToasterOpen}
                    setIsToasterOpen={setIsToasterOpen}
                    toasterMessage={toasterMessage}
                    setToasterMessage={setToasterMessage}
                    toasterSeverity={toasterSeverity}
                    setToasterSeverity={setToasterSeverity}
                    sortParams={vendorSortParams}
                    setSortParams={setVendorSortParams}
                    fetchCustomers={handleUpdateData}
                    handleSelectAll={fetchAllArchivedVendorsPage} 
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    headers={defVendorHeaders}
                    setActionModal={setActionModal}
                    actionPanel={actionPanel}
                    setActionPanel={setActionPanel}
                  /> 
                ) : (
                  <Box tabIndex={0} sx={styles.noPermissionBox}>
                    You do not have the permissions to view this tab.
                  </Box>
                )
              }
            </TabPanel>
          </TabPanel>
        </Box>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          component='div'
          colSpan={7}
          count={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={getLabelDisplayedRows()}
          labelRowsPerPage={getLabelRowsPerPage()}
          backIconButtonProps={{'aria-label':'Previous page icon'}}
          nextIconButtonProps={{'aria-label':'Next page icon'}}
          SelectProps={{ 
            inputProps: {
              'aria-label': 'Expand below icon',
              'aria-labelledby': 'Expand below icon',
            },
            id:'expandBelowIcon'
            }}
        />
      </>
    );
  };

  const renderMainContent = () => {
    if (isParentView) {
      return (
        <ParentChildSetupProvider>
          <ParentViewClientSetting
            setIsLoading={setIsLoading}
            selectedARCollateral={selectedARCollateral}
            borrowerId={borrowerId}
            setIsParentView={setIsParentView} 
            isToasterOpen={isToasterOpen} 
            setIsToasterOpen={setIsToasterOpen} 
            toasterMessage={toasterMessage} 
            setToasterMessage={setToasterMessage} 
            toasterSeverity={'success'} 
            setToasterSeverity={setToasterSeverity}
          /> 
        </ParentChildSetupProvider> 
      )
    }
    if (isEditVendorView) {
      return (
        <EditVendorCustomerSetupProvider>
          <EditVendorViewClientSetting
            setIsLoading={setIsLoading}
            selectedARCollateral={selectedARCollateral}
            borrowerId={borrowerId}
            setIsEditVendorView={setIsEditVendorView} 
            isToasterOpen={isToasterOpen} 
            setIsToasterOpen={setIsToasterOpen} 
            toasterMessage={toasterMessage} 
            setToasterMessage={setToasterMessage} 
            toasterSeverity={'success'} 
            setToasterSeverity={setToasterSeverity}
          /> 
        </EditVendorCustomerSetupProvider> 
      )
    }
    return renderMainViewContent();
  }

  return (
    <Box sx={styles.container}>
      <Box component="span" sx={styles.breadCrumbBox}>
        <Grid container sx={styles.headerContainer}>
          <Grid item xs={12} md={6} lg={8} xl={8.3}>
            { renderBreadcrumbs() }
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
            <Box sx={styles.clientBox}>
              <ComboBox
                id='client'
                options={clients}
                value={selectedClient}
                inputValue={clientInput}
                {...getHandleChangeByType('client')}
              />
            </Box>
          </Grid>
        </Grid>   
      </Box>
      <Box sx={styles.titleContainer}>
        <Typography tabIndex={0} variant='h6' component='h3' sx={{...styles.title, ...styles.MarginLeft}}>
          Customer Settings
        </Typography>
      </Box>

      <Container maxWidth='xl'>
        <Box sx={styles.customerSettingsHeader}>
          {selectedClient && (
            <Grid container item xs={12} md={4} lg={3}>
              <ComboBox
                id='arCollateral'
                options={arCollaterals}
                value={selectedARCollateral}
                inputValue={arCollateralInput}
                disabled={selectedClient.parentClient}
                {...getHandleChangeByType('arCollateral')}
              />
            </Grid>
          )}
        </Box> 
        { renderMainContent() }
      </Container>
      <ConfirmModal
        title={getClearModalTitle(clearModal.type)}
        description={getClearModalDescription(clearModal.type)} 
        open={clearModal.isOpen} 
        onClose={() => {
          setClearModal({
            ...clearModal,
            isOpen: false
          });
        }} 
        onConfirm={() => {
          if(clearModal.type === 'customer') {
            handleClearAllNewCustomers();
          }else{
            handleClearAllNewVendors();
          }
        }}        
        yesButtonText={'Clear'}
        noButtonText={'Cancel'}
      />
      <ConfirmModal 
        title={actionModal.title}
        description={actionModal.description}
        open={actionModal.open}
        onClose={() => {
          setActionModal({
            ...actionModal,
            open: false,
          });
        }}
        onConfirm={async () => {
          if (actionModal.actionType === 'restore') {
            if (actionModal.dataType === 'customer') {
              handleArchiveCustomers(actionModal.recordIds, false);
            }
            else {
              handleArchiveVendors(actionModal.recordIds, false);
            }
          }

          if (actionModal.actionType === 'delete') {
            if (actionModal.dataType === 'customer') {
              handleDeleteCustomers(actionModal.recordIds);
            }
            else {
              handleDeleteVendors(actionModal.recordIds);
            }
          }
          else if (actionModal.actionType === 'archive') {
            if (actionModal.dataType === 'customer') {
              handleArchiveCustomers(actionModal.recordIds, true);
            }
            else {
              handleArchiveVendors(actionModal.recordIds, true);
            }
          }
          // add restore
        }}
        yesButtonText={actionModal.yesButtonText}
        noButtonText={actionModal.noButtonText}
        errorButton={actionModal.errorButton}
      />
      <WarningModal
        open={warningModal.open}
        onClose={() => {
          setWarningModal({
            ...warningModal,
            open: false,
          });
        }}
        onConfirm={() => {
          if (warningModal.dataType === 'customer') {
            handleDeleteAndArchiveCustomers(warningModal.deleteRecordIds, warningModal.archiveRecordIds);
            return;
          }

          handleDeleteAndArchiveVendors(warningModal.deleteRecordIds, warningModal.archiveRecordIds);

        }}
        noDataIncluded
        issueType='warning'
        issueMessages={[warningModal.description]}
      />
      {/* Redundant Toasters. Need to refactor. */}
      <Toaster
        open={isToasterOpen}
        severity={toasterSeverity}
        message={toasterMessage}
        onCloseChange={handleToasterClose}
      />
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onCloseChange={handleCloseToaster}
      />
      <LoadingBackdrop
        isLoading={isLoading}
      />
    </Box>
  );
};

const CustomerSettings : FC = () => {
  return (
    <CustomerSettingsProvider>
      <CustomerSettingsPage />
    </CustomerSettingsProvider>
  )
}

export default CustomerSettings;