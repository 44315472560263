import { createContext, useMemo, useState } from 'react';
import { IClient, ICreateReservesContext } from '../interfaces/reservesInterface';
import { LayoutProps } from './authContext';

export const CreateReservesContext = createContext<ICreateReservesContext | null >(null);

const CreateReservesProvider = (props: LayoutProps) => {
  const { children } = props;
  const [clients, setClients] = useState<IClient[]>([]);
  const [selectedClient, setSelectedClient] = useState<IClient>({ recordId: -1, borrowerName: 'Loading...' });
  const [lastSelectedClient, setLastSelectedClient] = useState<IClient | null>(null);  // for storing last selected client for confirm navigation modal
  const [totalAmount, setTotalAmount] = useState(0);
  const [showPrompt, setShowPrompt] = useState(false); // confirmation modal for manual navigation warning

  const [viewReserves, setViewReserves] = useState<boolean>(false); 
  const [addReserves, setAddReserves] = useState<boolean>(false); 
  const [updateReserves, setUpdateReserves] = useState<boolean>(false); 
  const [deleteArchiveReserves, setDeleteArchiveReserves] = useState<boolean>(false); 

  const value = useMemo(() => ({
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    lastSelectedClient,
    setLastSelectedClient,
    totalAmount,
    setTotalAmount,
    showPrompt,
    setShowPrompt,

    viewReserves,
    setViewReserves,
    addReserves,
    setAddReserves,
    updateReserves,
    setUpdateReserves,
    deleteArchiveReserves,
    setDeleteArchiveReserves
  }), [
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    lastSelectedClient,
    setLastSelectedClient,
    totalAmount,
    setTotalAmount,
    showPrompt,
    setShowPrompt,

    viewReserves,
    setViewReserves,
    addReserves,
    setAddReserves,
    updateReserves,
    setUpdateReserves,
    deleteArchiveReserves,
    setDeleteArchiveReserves
  ])

  return (
    <CreateReservesContext.Provider
      value={value}
    >
      {children}
    </CreateReservesContext.Provider>
  );
};

export default CreateReservesProvider;
