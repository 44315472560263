const styles = {
  errorMessage: {
    color: '#D1062E',
    marginLeft: '0px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '0.75rem',
    lineHeight: '1.66'
  },
}

export default styles;