import yup from './index';

const ineligibleSettingsForLenderSettingsSchema = yup.object().shape({
	ineligibleCodes: yup
		.array()
		.of(yup.object().shape({
			sortOrder: yup.number()
				.typeError('Priority must be a number')
				.required('Priority is required'),
			code: yup.string()
				.required('Ineligible Code is required')
				.trim(),
			ineligibleDescription: yup.string()
				.required('Description is required')
				.trim(),
			visible: yup.string()
				.required('Status is required')
		}))
		.uniquePropertyDisregardCase('code', 'Ineligible Code must be unique')
		.uniqueProperty('sortOrder', 'Priority must be unique')
		.uniquePropertyDisregardCase('ineligibleDescription', 'Description must be unique')
		
});

export default ineligibleSettingsForLenderSettingsSchema;