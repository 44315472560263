import { createContext, useMemo, useState } from 'react';
import { LayoutProps } from './authContext';

export interface INavigationWarningContext {
  isDirty: boolean;
  setIsDirty: (newState: boolean) => void;
}

export const NavigationWarningContext =
  createContext<INavigationWarningContext | null>(null);

const NavigationWarningProvider = (props: LayoutProps) => {
  const { children } = props;

  const [isDirty, setIsDirty] = useState<boolean>(false);

  const value = useMemo(() => ({
    isDirty,
    setIsDirty
  }), [isDirty,setIsDirty])

  return (
    <NavigationWarningContext.Provider
      value={value}
    >
      {children}
    </NavigationWarningContext.Provider>
  );
};

export default NavigationWarningProvider;
