import colors from "../../../../../../utility/colors";

const styles = {
  bodyContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '30rem',
    overflowY: 'scroll',
    marginX: '1rem',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '0.25rem',
    paddingX: '1rem',
    paddingBottom: '1rem'
  },
  buttonCancel: {

  },
  buttonSave: {
    marginLeft: '0.5rem',
    width: '5rem'
  },
  closeIcon: {
    fontSize: '1rem',
    fontWeight: 600
  },
  divider: {
    marginY: '0.5rem',
    marginX: '1rem'
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingX: '1.5rem',
    paddingTop: '0.8rem',
  },
  headingTitle: {
    color: `${colors.PRIMARY}`,
    fontSize: '1.25rem',
    fontWeight: 600
  },
	modalContainer: {
    bgcolor: `${colors.WHITE}`,
    borderRadius: '0.2rem',
    position: 'absolute',
    left: '50%',
    top: '50%',
    boxShadow: 15,
    transform: 'translate(-50%, -50%)',
    width: '30rem',
    display: 'flex',
    flexDirection: 'column',
  },
  sheetSelectionsContainer: {
    paddingLeft: '1rem',
    paddingRight: '0.5rem'
  },
  fileLabelContainer: {
    display: 'flex'
  },
  fileLabel: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    marginRight: '0.3rem'
  },
  fileName: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    marginRight: '1rem'
  },
  dropdown: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      fontSize: '0.875rem',
    },
  },
  dropdownList: {
    fontSize: '0.875rem',
    width: '100%',
  },
  dropdownContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem'
  }
};
export default styles;