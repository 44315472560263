const styles = {
  tableTextBody: {
    fontSize: '14px',
    border: 0,
    paddingY: '0.1rem'
  },
  tableCollapseBody: {
    border: 0,
    padding: 0,
  },
  tableTextBodyLeftAction: {
    textAlign: 'right',
    paddingRight: 0
  },
  tableTextBodyCategory: {
    textAlign: 'left',
    fontWeight: 'bold',
    paddingLeft: 0
  },
  tableTextBodyAmount: {
    textAlign: 'right',
    paddingLeft: '10rem',
    paddingRight: 0
  },
  tableTextBodyRightAction: {
    paddingLeft: '0.1rem'
  },
  amtTextFieldInput: {
    fontSize: '14px',
    textAlign: 'right',
    paddingY: '0.5rem'
  },
  addButton: {
    padding: '0.2rem',
    marginBottom: '0.2rem'
  },
  addIcon: {
    fontSize: '1rem'
  },
  tableCollapseTextHeader: {
    border: 0,
    paddingY: 0
  },
  tableCollapseTextHeaderLeftActionButton: {
    textAlign: 'right',
    paddingRight: 0,
    width: '20%'
  },
  tableCollapseTextHeaderType: {
    textAlign: 'left',
    paddingLeft: 0,
    width: '30%',
  },
  tableCollapseTextHeaderRightActionButton: {
    textAlign: 'left',
    width: '20%',
  },
  detailsHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '0.5rem'
  },
  tableCollapseTextBody: {
    border: 0,
    paddingLeft: 0,
  },
}
export default styles;