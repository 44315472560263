import { Grid, IconButton, Button } from '@mui/material';
import { FC, SetStateAction } from 'react'
import { createOrdinalStrings } from '../../../../../../utility/helper';
import BucketField from './bucket-field';
import styles from '../styles';
import { FormikProps, FormikValues } from 'formik';
import { IDeleteProps, IFormikForIAccountsReceivableSetting } from '../modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export interface BucketSpreadProps {
  bucketType: 'invoiceDateBucket' | 'dueDateBucket';
  formik: FormikProps<IFormikForIAccountsReceivableSetting>;
  hasBucketErrors: (formik: FormikProps<FormikValues & IFormikForIAccountsReceivableSetting>, bucketType: 'invoiceDateBucket' | 'dueDateBucket') => boolean;
  isDetailsEnabled: boolean;
  getFormikValues: (formik: FormikProps<FormikValues & IFormikForIAccountsReceivableSetting>, name: string) => {
    name: string;
    id: string;
    value: any;
    error: any;
    helperText: any;
  };
  setDeleteDetails: (value: SetStateAction<IDeleteProps>) => void;
  setShowDeletePrompt: (value: SetStateAction<boolean>) => void;
  handleAdd: (formik: FormikProps<IFormikForIAccountsReceivableSetting>, bucketType: 'invoiceDateBucket' | 'dueDateBucket') => void;
}

/**
 * Grid component for DateBuckets
 * @param props BucketSpreadProps
 * @returns a rendered component for DateBuckets
 */
const BucketSpread: FC<BucketSpreadProps> = (props) => {
  const { bucketType, formik, hasBucketErrors, isDetailsEnabled, setDeleteDetails, setShowDeletePrompt, handleAdd } = props;

  return (
    <Grid container sx={hasBucketErrors(formik, bucketType) ? styles.modalFieldsContainerExpanded : styles.modalFieldsContainer}>
      <Grid item xs={12}>
        <Grid container sx={styles.bucketFieldsContainer}>
          {formik.values[`${bucketType}s`].map((bucketfieldName, index) => (
            <Grid item xs={2} key={bucketfieldName} display='flex' justifyContent='flex-start' alignItems='flex-end'>
              <BucketField
                name={`arBorrowerInput.${bucketfieldName}`}
                bucketNumber={index + 1}
                type={bucketType}
                {...props}
              />
              {index > 0 && isDetailsEnabled &&
              <IconButton
                aria-label='Remove icon'
                onClick={() => {
                  setDeleteDetails({title: `Remove ${createOrdinalStrings(index)} bucket?`, index, bucketFieldName: bucketfieldName, bucketType})
                  setShowDeletePrompt(true);
                }}
                sx={styles.removeCircleIconButton}
              >
                <HighlightOffIcon sx={styles.removeCircleIcon} />
              </IconButton>}
            </Grid>
          ))}
          {formik.values[`${bucketType}s`].length < 6 && isDetailsEnabled &&
            <Button
              variant='contained'
              onClick={() => handleAdd(formik, bucketType)}
              aria-label={`Add ${bucketType}`}
              sx={styles.addButton}
            >
              +Add
            </Button>}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BucketSpread;